import React, { Reducer, useCallback, useReducer } from 'react';
import { Button } from '@mui/material';
import reducer, {
  ExpenseReportAction,
  ExpenseReportActionType,
  ExpenseReportState,
  initialState,
} from 'components/expense/ExpenseReportPage/ExpenseReportReducer';
import Modal from 'components/shared/Modal';
import { useSnackbar } from 'notistack';
import ExpenseService from 'services/api/ExpenseService';
import { ModalProps } from 'store/types/ComponentProps';
import { ExpenseItemSaveRequest } from 'store/types/Expense';
import { defaultSnackbarErrorProps } from 'util/Layout';

import commonStyles from 'styles/common.module.scss';

interface DeleteExpenseModalProps extends ModalProps {
  expenseItem?: ExpenseItemSaveRequest | null;
  onSubmit: () => void;
}

const DeleteExpenseModal: React.FunctionComponent<DeleteExpenseModalProps> = ({
  expenseItem,
  open,
  onClose,
  onSubmit,
}) => {
  const [{ loading }, dispatch] = useReducer<Reducer<ExpenseReportState, ExpenseReportAction>>(reducer, initialState);

  const { enqueueSnackbar } = useSnackbar();

  const handleDelete = useCallback(() => {
    if (expenseItem) {
      dispatch({
        type: ExpenseReportActionType.SetLoading,
        payload: { loading: true },
      });
      ExpenseService.deleteExpenseItem(expenseItem.id, expenseItem?.expense.id)
        .then(() => {
          dispatch({
            type: ExpenseReportActionType.SetLoading,
            payload: { loading: false },
          });
          enqueueSnackbar('Expense item successfully deleted', { variant: 'success' });
          onSubmit();
        })
        .catch((errorMessage: string) => {
          dispatch({
            type: ExpenseReportActionType.SetLoading,
            payload: { loading: false },
          });
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        });
    }
  }, [enqueueSnackbar, expenseItem, onSubmit]);

  return (
    <Modal
      title={'Delete Expense Item'}
      open={open}
      maxWidth={'sm'}
      loading={loading}
      actions={
        <>
          <Button color={'secondary'} variant={'outlined'} onClick={onClose} disabled={loading}>
            {'Cancel'}
          </Button>
          <Button
            type={'submit'}
            variant={'contained'}
            className={commonStyles.dangerButtonContained}
            disabled={loading}
            onClick={handleDelete}
          >
            {'Delete'}
          </Button>
        </>
      }
    >
      <p className={commonStyles.text}>You are about to delete an expense item.</p>
    </Modal>
  );
};
export default DeleteExpenseModal;
