import React from 'react';
import { getFormattedDate, NUMBER_DATE_FORMAT } from 'util/Format';
import Announcement from 'store/types/Announcement';
import ShowMoreText from 'components/shared/ShowMoreText';

import styles from './AnnouncementListItem.module.scss';

interface AnnouncementListItemProps {
  announcement: Announcement;
  truncateText?: boolean;
}

const AnnouncementListItem: React.FunctionComponent<AnnouncementListItemProps> = ({
  announcement,
  truncateText = false,
}) => {
  const { details, title, date } = announcement;

  return (
    <div data-testid={'announcement-list-item'}>
      <span className={styles.date} data-testid={'item-date'}>
        {getFormattedDate(date, NUMBER_DATE_FORMAT)}
      </span>
      <h4 className={styles.title} data-testid={'item-title'}>
        {title}
      </h4>
      {truncateText ? (
        <p data-testid={'item-truncate-text'}>
          <ShowMoreText isHTML={true} className={styles.descriptionText}>
            {details}
          </ShowMoreText>
        </p>
      ) : (
        <p
          className={styles.descriptionText}
          dangerouslySetInnerHTML={{ __html: details }}
          data-testid={'item-default-text'}
        />
      )}
    </div>
  );
};
export default AnnouncementListItem;
