import Modal from 'components/shared/Modal';
import React, { useContext } from 'react';
import { ModalProps } from 'store/types/ComponentProps';
import { ConfigContext } from 'components/ConfigGuard';

import styles from './DonationTermsModal.module.scss';

const DonationTermsModal: React.FunctionComponent<ModalProps> = ({ open, onClose }) => {
  const { isCompassTheme } = useContext(ConfigContext);

  return (
    <Modal
      open={open}
      headerClassName={styles.header}
      onClose={onClose}
      title={<div className={styles.title}>Terms And Conditions</div>}
    >
      <p
        dangerouslySetInnerHTML={{
          __html: isCompassTheme
            ? '<span>All gifts are tax deductible to the full extent allowed by law. Contributions are solicited with the understanding that Compass Charitable Partners has complete control over the use of all donated funds. Our board-approved policy is that all gifts designated for a specific project be applied to that project, with up to 10 percent used for administrative and fundraising expenses. Occasionally we receive more contributions for a given project than can be wisely applied to that project. When that happens, we use these funds to meet a similar pressing need.</span>'
            : '<span>IN ACCORDANCE WITH IRS GUIDELINES, NO GOODS OR SERVICES WERE PROVIDED IN EXCHANGE FOR YOUR DONATION. Donations are not refundable.</span>',
        }}
      />
    </Modal>
  );
};
export default DonationTermsModal;
