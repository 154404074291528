import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Button, Grid, Tab, Tabs } from '@mui/material';
import { useHistory, useLocation } from 'react-router';
import routes from 'store/configs/Routes';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import OrderHistoryPageView from 'components/payments/OrderHistoryPageView';
import MakePaymentPageView from 'components/payments/MakePaymentPageView';
import { MembershipContext } from 'components/MembershipGuard';
import Spinner from 'components/shared/Spinner';
import { UserContext } from 'components/UserGuard';
import { getActiveTabRoute, getActiveTabValue } from 'util/Route';
import { ConfigContext } from 'components/ConfigGuard';
import { defaultGridContainerProps, defaultGridItemProps, getPageTitle } from 'util/Layout';
import PaymentService from 'services/api/PaymentService';
import { OrderPayment } from 'store/types/Payment';
import useRequest from 'hooks/useRequest';
import SiteModule from 'store/enums/SiteModule';
import DownloadStatementModal from 'components/payments/DownloadStatementModal';
import { CompanyPaymentDetails } from 'store/types/Company';
import ApplyCreditsToggle from 'components/payments/ApplyCreditsToggle';
import CompanyService from 'services/api/CompanyService';
import { ParametersContext } from 'components/ParametersGuard';

import commonStyles from 'styles/common.module.scss';

const tabRoutesConfig: string[] = [
  routes.personalPayments,
  routes.makePersonalPayment,
  routes.makePersonalSalesPayment,
];

const PaymentsPage: React.FunctionComponent = () => {
  const { membershipLoading = false } = useContext(MembershipContext);
  const {
    isNccerTheme,
    isSmacnaTheme,
    sidebar,
    modulesSettings: {
      personalPayments: { enablePayInvoices, enablePaySalesOrders },
    },
  } = useContext(ConfigContext);
  const { userLoading = false, id } = useContext(UserContext);
  const { personalPayments } = useContext(ParametersContext);
  const location = useLocation();
  const [downloadStatementModalOpen, setDownloadStatementModalOpen] = useState<boolean>(false);
  const history = useHistory();
  const ordersRequestData = useRequest<OrderPayment[]>(PaymentService.getOrdersList);
  const activeTab: string = useMemo(() => getActiveTabValue(location.pathname, tabRoutesConfig), [location.pathname]);
  const companyDetailsRequest = useCallback(() => CompanyService.getCompanyPaymentDetails(id), [id]);
  const companyDetailsRequestData = useRequest<CompanyPaymentDetails>(companyDetailsRequest);

  const handleDownloadStatementModalOpen = useCallback(() => {
    setDownloadStatementModalOpen(true);
  }, []);

  const handleDownloadStatementModalClose = useCallback(() => {
    setDownloadStatementModalOpen(false);
  }, []);

  const handleTabChange = useCallback(
    (_: any, value = '1') => {
      history.replace(getActiveTabRoute(value, tabRoutesConfig));
    },
    [history]
  );

  const generalStatementButton = useMemo(
    () => (
      <Grid {...defaultGridItemProps} xs={'auto'}>
        <Button variant={'contained'} color={'secondary'} onClick={handleDownloadStatementModalOpen}>
          {'Generate Statement'}
        </Button>
      </Grid>
    ),
    [handleDownloadStatementModalOpen]
  );

  const applyCreditsToggle = useMemo(
    () => (
      <Grid {...defaultGridItemProps} xs={'auto'}>
        <ApplyCreditsToggle id={id} {...companyDetailsRequestData} />
      </Grid>
    ),
    [companyDetailsRequestData, id]
  );

  return (
    <>
      {enablePayInvoices && downloadStatementModalOpen && (
        <DownloadStatementModal
          {...companyDetailsRequestData}
          open={downloadStatementModalOpen}
          onClose={handleDownloadStatementModalClose}
          id={id}
        />
      )}
      <h1 className={commonStyles.pageTitle}>
        {getPageTitle(sidebar, SiteModule.Payments, SiteModule.PersonalPayments, !isSmacnaTheme) ||
          'Billing & Invoices'}
      </h1>
      <TabContext value={activeTab}>
        {enablePayInvoices && (
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            indicatorColor={'primary'}
            textColor={'primary'}
            variant={'scrollable'}
          >
            <Tab value={'1'} label={'Billing History'} className={commonStyles.tab} />
            {enablePayInvoices && <Tab value={'2'} label={'Pay My Invoices'} className={commonStyles.tab} />}
            {enablePaySalesOrders && <Tab value={'3'} label={'Pay My Sales Orders'} className={commonStyles.tab} />}
          </Tabs>
        )}
        <TabPanel className={commonStyles.tabPanel} value={'1'}>
          <Spinner loading={userLoading || membershipLoading} transparent={false}>
            <Grid {...defaultGridContainerProps} justifyContent={'flex-end'}>
              {enablePayInvoices && (
                <>
                  {personalPayments.billingHistoryTabHeader && (
                    <Grid {...defaultGridItemProps}>
                      <p dangerouslySetInnerHTML={{ __html: personalPayments.billingHistoryTabHeader }} />
                    </Grid>
                  )}
                  {applyCreditsToggle}
                  {generalStatementButton}
                </>
              )}
              <Grid {...defaultGridItemProps}>
                <OrderHistoryPageView
                  {...ordersRequestData}
                  companyColumnEnabled={isNccerTheme}
                  openOrdersEnabled={!!enablePayInvoices}
                />
              </Grid>
            </Grid>
          </Spinner>
        </TabPanel>
        <TabPanel className={commonStyles.tabPanel} value={'2'}>
          <Spinner loading={userLoading} transparent={false}>
            <Grid {...defaultGridContainerProps} justifyContent={'flex-end'} direction={'row'}>
              {
                <Grid {...defaultGridItemProps}>
                  {personalPayments.payMyInvoicesTabHeader && (
                    <p dangerouslySetInnerHTML={{ __html: personalPayments.payMyInvoicesTabHeader }} />
                  )}
                </Grid>
              }
              {generalStatementButton}
              <Grid {...defaultGridItemProps}>
                <MakePaymentPageView {...ordersRequestData} />
              </Grid>
            </Grid>
          </Spinner>
        </TabPanel>
        <TabPanel className={commonStyles.tabPanel} value={'3'}>
          <Spinner loading={userLoading} transparent={false}>
            <Grid {...defaultGridContainerProps} justifyContent={'flex-end'} direction={'row'}>
              {
                <Grid {...defaultGridItemProps}>
                  {personalPayments.payMySalesOrdersTabHeader && (
                    <p dangerouslySetInnerHTML={{ __html: personalPayments.payMySalesOrdersTabHeader }} />
                  )}
                </Grid>
              }
              {generalStatementButton}
              <Grid {...defaultGridItemProps}>
                <MakePaymentPageView {...ordersRequestData} salesOrders={true} />
              </Grid>
            </Grid>
          </Spinner>
        </TabPanel>
      </TabContext>
    </>
  );
};
export default PaymentsPage;
