import { Alert, Button } from '@mui/material';
import Modal from 'components/shared/Modal';
import useRequest from 'hooks/useRequest';
import React, { useCallback, useMemo } from 'react';
import MemberDirectoryService from 'services/api/MemberDirectoryService';
import { ModalProps } from 'store/types/ComponentProps';
import { CustomerCompanyDetails, MemberDirectoryCustomer } from 'store/types/MemberDirectory';
import CompanyDetailsPageView from 'components/directory/CompanyDetailsPageView';

import commonStyles from 'styles/common.module.scss';

interface IndividualDetailsModalProps extends ModalProps {
  member?: MemberDirectoryCustomer;
  isChapter?: boolean;
}

const CompanyDetailsModal: React.FunctionComponent<IndividualDetailsModalProps> = ({
  member,
  isChapter,
  open,
  onClose,
}) => {
  const companyDetails = useMemo(
    () => (member?.id ? () => MemberDirectoryService.getCompanyDetails(member.id) : undefined),
    [member?.id]
  );
  const { data, loading, error } = useRequest<CustomerCompanyDetails>(companyDetails);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      maxWidth={'lg'}
      title={'Member Details'}
      loading={loading}
      actions={
        <>
          <Button color={'secondary'} variant={'outlined'} onClick={handleClose} disabled={loading}>
            {'Close'}
          </Button>
        </>
      }
    >
      {error ? (
        <Alert severity={'error'} className={commonStyles.alert}>
          {error}
        </Alert>
      ) : (
        data && <CompanyDetailsPageView data={data} isChapter={isChapter} />
      )}
    </Modal>
  );
};
export default CompanyDetailsModal;
