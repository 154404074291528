import { Divider, Grid } from '@mui/material';
import Alert from '@mui/material/Alert';
import SubscriptionDonationSection from 'components/subscriptions/SubscriptionDonationSection';
import PaymentFormSection from 'components/payments/PaymentFormSection';
import FormFooter from 'components/shared/FormFooter';
import Spinner from 'components/shared/Spinner';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import PaymentService, { BasePaymentData, MembershipPaymentData } from 'services/api/PaymentService';
import CollectJSResponse from 'store/types/CollectJSResponse';
import { PaymentItemsFormValues, PaymentFormValues } from 'store/types/FormValues';
import { SubscriptionPlan } from 'store/types/Subscription';
import MembershipPlansSection from 'components/subscriptions/MembershipPlansSection';
import {
  defaultPaymentFormValues,
  donationsAmountSelected,
  getPaymentData,
  paymentFormInvalid,
  shouldValidatePaymentMethod,
  getSummaryTotalPrice,
} from 'util/Payment';
import Payment from 'store/types/Payment';
import { getDonationsPaymentData, getMembershipSummaryList } from 'util/Membership';
import { MembershipContext } from 'components/MembershipGuard';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import MembershipService from 'services/api/MembershipService';
import Donation from 'store/types/Donation';
import useRequest from 'hooks/useRequest';
import { PaymentMethodsContext } from 'components/PaymentMethodsContextWrapper';
import { DEFAULT_ERROR_MESSAGE } from 'util/Form';
import { SiteConfigView } from 'store/types/SiteConfig';
import { ConfigContext } from 'components/ConfigGuard';

import commonStyles from 'styles/common.module.scss';

interface RenewMembershipPageViewProps {
  onPaymentCompleted: (payment: Payment) => void;
}

const getSubmitButtonDisabled = (
  paymentForm: PaymentFormValues,
  donations: PaymentItemsFormValues,
  membershipPlan?: SubscriptionPlan
): boolean => {
  return !membershipPlan || !donationsAmountSelected(donations) || paymentFormInvalid(paymentForm);
};

const RenewMembershipPageView: React.FunctionComponent<RenewMembershipPageViewProps> = ({ onPaymentCompleted }) => {
  const {
    modulesSettings: {
      payments: { invoices },
    },
  }: SiteConfigView = useContext(ConfigContext);
  const { enqueueSnackbar } = useSnackbar();
  const { refetch: refetchPaymentMethods } = useContext(PaymentMethodsContext);
  const { refetchMembership, renewalPlan, membershipLoading } = useContext(MembershipContext);
  const donationsRequest = useRequest<Donation[]>(MembershipService.getDonations);
  const [donations, setDonations] = useState<PaymentItemsFormValues>({});
  const [paymentForm, setPaymentForm] = useState<PaymentFormValues>({
    ...defaultPaymentFormValues,
    autoRenewal: true,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const submitButtonDisabled = getSubmitButtonDisabled(paymentForm, donations, renewalPlan);
  const { summaryList } = paymentForm;

  useEffect(() => {
    setPaymentForm((prevState) => ({
      ...prevState,
      totalPrice: getSummaryTotalPrice(summaryList),
    }));
  }, [summaryList]);

  useEffect(() => {
    const newSummaryList = getMembershipSummaryList(donations, renewalPlan);

    setPaymentForm((prevState) => ({
      ...prevState,
      summaryList: newSummaryList,
    }));
  }, [donations, renewalPlan]);

  const handleDonationSelected = useCallback((newDonations: PaymentItemsFormValues) => {
    setDonations(newDonations);
  }, []);

  const handlePaymentChange = useCallback((formValues: Partial<PaymentFormValues>) => {
    setPaymentForm((prevForm) => ({ ...prevForm, ...formValues }));
  }, []);

  const handleSubmit = () => {
    setLoading(true);
    if (shouldValidatePaymentMethod(paymentForm)) {
      (window as any).CollectJS.startPaymentRequest();
    } else {
      renewMembership();
    }
  };

  const handlePaymentComplete = (response: CollectJSResponse) => {
    renewMembership(response);
  };

  const renewMembership = useCallback(
    (collectJSResponse?: CollectJSResponse) => {
      if (renewalPlan) {
        const donationsData = getDonationsPaymentData(donations);
        const basePaymentData: BasePaymentData = getPaymentData(paymentForm, collectJSResponse);
        const data: MembershipPaymentData = {
          ...basePaymentData,
          planId: renewalPlan.id.toString(),
          donations: donationsData,
          autoRenewal: paymentForm.autoRenewal || false,
          renew: true,
        };

        PaymentService.makeMembershipPayment(data)
          .then((newPayment: Payment) => {
            refetchMembership();
            refetchPaymentMethods();
            enqueueSnackbar('Subscription successfully updated', { variant: 'success' });
            onPaymentCompleted(newPayment);
            setLoading(false);
          })
          .catch((error: string) => {
            setLoading(false);
            enqueueSnackbar(error, defaultSnackbarErrorProps);
          });
      } else {
        enqueueSnackbar(DEFAULT_ERROR_MESSAGE, defaultSnackbarErrorProps);
      }
    },
    [paymentForm, donations, renewalPlan, enqueueSnackbar, refetchMembership, onPaymentCompleted, refetchPaymentMethods]
  );

  return (
    <Spinner loading={membershipLoading} transparent={false}>
      {!renewalPlan ? (
        <Alert severity={'error'} className={commonStyles.alert}>
          {'Membership renewal is not enabled.'}
        </Alert>
      ) : (
        <Spinner loading={loading}>
          <form>
            <Grid {...defaultGridContainerProps}>
              <Grid {...defaultGridItemProps}>
                <MembershipPlansSection value={renewalPlan} />
              </Grid>
              <Grid {...defaultGridItemProps}>
                <SubscriptionDonationSection
                  {...donationsRequest}
                  value={donations}
                  onChange={handleDonationSelected}
                />
                <Divider className={commonStyles.gridItemDivider} />
              </Grid>
              <Grid {...defaultGridItemProps}>
                <PaymentFormSection
                  form={paymentForm}
                  onChange={handlePaymentChange}
                  onPaymentComplete={handlePaymentComplete}
                  autoRenewalVisible={true}
                  onlySavedAddresses={true}
                  paymentConfig={invoices}
                  // TODO: apply after BE is ready
                  // discountEnabled={true}
                />
              </Grid>
            </Grid>
            <FormFooter
              onSubmit={handleSubmit}
              submitButtonName={'Complete Payment'}
              submitButtonDisabled={submitButtonDisabled}
            />
          </form>
        </Spinner>
      )}
    </Spinner>
  );
};
export default RenewMembershipPageView;
