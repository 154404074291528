import { SortConfig } from 'store/types/Table';
import { sorter } from 'util/Table';
import FileInfo from 'store/types/FileInfo';

export enum CommunityFilesSectionActionType {
  SetInitialList = 'SetInitialList',
  UpdateSort = 'UpdateSort',
  SetSubmitLoading = 'SetSubmitLoading',
  OpenConfirmationModal = 'OpenConfirmationModal',
  CloseConfirmationModal = 'CloseConfirmationModal',
}

export interface CommunityFilesSectionState {
  initialList: FileInfo[];
  list: FileInfo[];
  sort: SortConfig<FileInfo>;
  selectedItem?: FileInfo;
  submitLoading?: boolean;
}

export interface CommunityFilesSectionAction {
  type: CommunityFilesSectionActionType;
  payload: Partial<CommunityFilesSectionState>;
}

const getSortedList = (list: FileInfo[] = [], sort: SortConfig<FileInfo>): FileInfo[] =>
  list.length ? [...list].sort(sorter<FileInfo>(sort)) : [...list];

export const initialState: CommunityFilesSectionState = {
  initialList: [],
  list: [],
  sort: { direction: 'desc', column: 'created' },
};

const reducer = (
  state: CommunityFilesSectionState,
  { type, payload }: CommunityFilesSectionAction
): CommunityFilesSectionState => {
  if (type === CommunityFilesSectionActionType.SetInitialList) {
    const { initialList = [] } = payload;

    return { ...state, initialList, list: getSortedList(initialList, state.sort) };
  }

  if (type === CommunityFilesSectionActionType.UpdateSort) {
    const { sort = {} } = payload;

    return {
      ...state,
      sort,
      list: getSortedList(state.initialList, sort),
    };
  }

  if (type === CommunityFilesSectionActionType.SetSubmitLoading) {
    const { submitLoading = false } = payload;

    return { ...state, submitLoading };
  }

  if (type === CommunityFilesSectionActionType.OpenConfirmationModal) {
    const { selectedItem } = payload;

    return { ...state, selectedItem };
  }

  if (type === CommunityFilesSectionActionType.CloseConfirmationModal) {
    return { ...state, selectedItem: undefined, submitLoading: false };
  }

  return state;
};

export default reducer;
