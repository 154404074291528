import React from 'react';
import { Card, Button, CardContent, Divider, Grid, GridProps } from '@mui/material';
import { SubscriptionPlan } from 'store/types/Subscription';
import classNames from 'classnames';
import ComponentProps from 'store/types/ComponentProps';
import ShowMoreText from 'components/shared/ShowMoreText';
import { defaultGridItemProps } from 'util/Layout';

import styles from './MembershipPlanCard.module.scss';

interface MembershipTypeCardProps extends ComponentProps {
  data: SubscriptionPlan;
  onClick?: (membership: SubscriptionPlan) => void;
  selected?: boolean;
  customSelectedLabel?: string;
}

const gridContainerProps: GridProps = { container: true, spacing: 2 };

const MembershipPlanCard: React.FunctionComponent<MembershipTypeCardProps> = ({
  data,
  onClick,
  selected,
  customSelectedLabel,
  className = '',
}) => {
  const { name, description, price } = data;

  const handleClick = () => {
    if (onClick) {
      onClick(data);
    }
  };

  return (
    <Card className={classNames(styles.card, { [styles.selected]: selected }, className)}>
      {selected && <span className={styles.selectedLabel}>{customSelectedLabel || 'Selected'} </span>}
      <CardContent className={classNames(styles.content, { [styles.nonEditable]: !onClick })}>
        <Grid
          container={true}
          spacing={0}
          direction={'column'}
          justifyContent={'space-between'}
          alignItems={'center'}
          className={styles.info}
        >
          <Grid {...defaultGridItemProps} xs={true} className={styles.textSection}>
            <Grid {...gridContainerProps}>
              <Grid {...defaultGridItemProps}>
                <Grid
                  {...gridContainerProps}
                  justifyContent={onClick ? 'center' : 'space-between'}
                  alignItems={'baseline'}
                >
                  <Grid {...defaultGridItemProps} xs={onClick ? 12 : true}>
                    <h4 className={styles.title}>{name}</h4>
                  </Grid>
                  <Grid {...defaultGridItemProps} xs={'auto'}>
                    <span className={styles.price}>
                      <span>{price ? `$${price}` : 'Free'}</span>
                      {!!price && <span className={styles.period}>{'/year'}</span>}
                    </span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid {...defaultGridItemProps}>
                <Divider className={styles.divider} />
              </Grid>
              <Grid {...defaultGridItemProps}>
                <p className={styles.description}>
                  <ShowMoreText>{description}</ShowMoreText>
                </p>
              </Grid>
            </Grid>
          </Grid>
          {onClick && (
            <Grid {...defaultGridItemProps} xs={'auto'}>
              <Button color={'secondary'} variant={'contained'} onClick={handleClick} className={styles.button}>
                {'Select'}
              </Button>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};
export default MembershipPlanCard;
