import React, { useCallback, useEffect, useState } from 'react';
import CurrentEventCart from 'store/types/events/CurrentEventCart';
import useRequest from 'hooks/useRequest';
import Spinner from 'components/shared/Spinner';
import EventService from 'services/api/EventService';
import EventsCartItem from 'store/types/events/EventsCartItem';
import { useLocation } from 'react-router';
import routes from 'store/configs/Routes';

export const EventCartContext = React.createContext<CurrentEventCart>({} as CurrentEventCart);

const EventCartGuard: React.FunctionComponent = ({ children }) => {
  const { pathname } = useLocation();
  const { data, loading, refetch, firstLoading } = useRequest<EventsCartItem[]>(
    pathname.includes(routes.redirect) ? undefined : EventService.getEventsCart
  );
  const [cart, setCart] = useState<EventsCartItem[] | undefined>(undefined);

  useEffect(() => {
    if (data) {
      setCart(data || []);
    }
  }, [data]);

  const refetchCart = useCallback(() => {
    refetch();
  }, [refetch]);

  return firstLoading ? (
    <Spinner loading={true} fullPage={true} transparent={false} />
  ) : (
    <EventCartContext.Provider value={{ cart: cart || [], refetchCart, cartLoading: loading }}>
      {children}
    </EventCartContext.Provider>
  );
};
export default EventCartGuard;
