import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, FormHelperText, Grid } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormProps } from 'util/Form';
import { TableColumn } from 'store/types/Table';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import Table from 'components/shared/Table';
import Add from '@mui/icons-material/Add';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import AddSublistItemModal from './AddSublistItemModal';
import QuestionType from 'store/enums/QuestionType';
import classNames from 'classnames';
import { getFormattedDate, NUMBER_DATE_FORMAT } from 'util/Format';
import { getPrice } from 'util/Payment';
import { PreferenceQuestionOption, PreferenceQuestionView } from 'store/types/PreferenceQuestion';
import { ParametersContext } from 'components/ParametersGuard';

import commonStyles from 'styles/common.module.scss';

interface SublistQuestionFormItemProps extends FormProps {
  question: PreferenceQuestionView;
}

interface SublistAnswerView {
  [key: string]: string;
  referenceKey: string;
}

const SublistQuestionFormItem: React.FunctionComponent<SublistQuestionFormItemProps> = ({ question, disabled }) => {
  const { sublistQuestions, fieldName } = question;
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();
  const errorMessage = errors[fieldName]?.message;
  const {
    profile: { addNewSubListItemButtonName },
  } = useContext(ParametersContext);
  const [list, setList] = useState<SublistAnswerView[]>([]);
  const [displayResultRow, setDisplayResultRow] = useState<boolean>(false);
  const [addItemModalOpen, setAddItemModalOpen] = useState<boolean>(false);
  const sublistAnswersView: Array<SublistAnswerView[]> = useWatch({ control, name: fieldName });
  const dropdownQuestionOptions: PreferenceQuestionOption[] | undefined = useMemo(
    () => sublistQuestions?.find((item) => item.type === QuestionType.Dropdown)?.options,
    [sublistQuestions]
  );

  useEffect(() => {
    setDisplayResultRow(
      !!sublistQuestions?.some(({ type }) => type === QuestionType.Currency && sublistAnswersView.length)
    );
  }, [sublistAnswersView, sublistQuestions]);

  useEffect(() => {
    const newList: SublistAnswerView[] = [];

    sublistAnswersView?.forEach((item) =>
      item.forEach(({ answer, referenceKey, content, type }) => {
        const dropdownQuestionOptions = sublistQuestions?.find(
          (item) => content === item.content && item.type === QuestionType.Dropdown
        )?.options;
        const value = newList.find((item) => item.referenceKey === referenceKey);
        if (value) {
          value[content] =
            type === QuestionType.Date
              ? getFormattedDate(answer, NUMBER_DATE_FORMAT)
              : type === QuestionType.Dropdown
              ? dropdownQuestionOptions?.find((item) => item.value === answer)?.name || ''
              : answer;
        } else {
          newList.push({
            referenceKey,
            [content]:
              type === QuestionType.Dropdown
                ? dropdownQuestionOptions?.find((item) => item.value === answer)?.name || ''
                : type === QuestionType.Date
                ? getFormattedDate(answer, NUMBER_DATE_FORMAT)
                : answer,
            type,
          });
        }
      })
    );

    const result: { [key: string]: any } = {};
    sublistAnswersView?.forEach((item) =>
      item.forEach(({ content, type, answer }) => {
        if (type === QuestionType.Currency) {
          result[content] = (result[content] || 0) + Number(answer);
        }
      })
    );

    displayResultRow &&
      newList.push({
        ['result']: 'SubTotal',
        ['referenceKey']: '',
        ...result,
      });

    setList(newList);
  }, [displayResultRow, dropdownQuestionOptions, sublistAnswersView, sublistQuestions]);

  const handleDeleteItem = useCallback(
    ({ referenceKey }: SublistAnswerView) =>
      () => {
        const newValues = fieldName
          .split('.')
          .reduce((nextObject: any, key: string) => nextObject[key], getValues())
          .filter((item: SublistAnswerView[]) => item[0].referenceKey !== referenceKey);
        setValue(fieldName, newValues);
      },
    [fieldName, getValues, setValue]
  );

  const columns: Array<TableColumn<any>> = useMemo(() => {
    const result: Array<TableColumn<any>> = sublistQuestions?.length
      ? sublistQuestions.map(({ content, type }) => ({
          label: content,
          key: content,
          dataIndex: content,
          render: (_: any, record: SublistAnswerView) =>
            type === QuestionType.Currency ? getPrice(Number(record[content])) : record[content],
        }))
      : [];

    result.push({
      label: 'Action',
      key: 'action',
      render: (_: any, record: SublistAnswerView) => (
        <Button
          size={'small'}
          variant={'outlined'}
          className={classNames(commonStyles.dangerButtonOutlined, { [commonStyles.hidden]: !!record['result'] })}
          onClick={handleDeleteItem(record)}
          disabled={disabled}
        >
          <DeleteOutlined />
        </Button>
      ),
    });
    {
      displayResultRow &&
        result.unshift({
          label: '',
          key: 'result',
          dataIndex: 'result',
          render: (_: any, record: SublistAnswerView) => <b>{record['result']}</b>,
        });
    }

    return result;
  }, [disabled, displayResultRow, handleDeleteItem, sublistQuestions]);

  const handleAddItemModalOpen = useCallback(() => {
    setAddItemModalOpen(true);
  }, []);

  const handleAddItemModalClose = useCallback(() => {
    setAddItemModalOpen(false);
  }, []);

  return (
    <>
      <Grid {...defaultGridContainerProps} spacing={2}>
        <Grid {...defaultGridItemProps}>
          {errorMessage && <FormHelperText error={true}>{errorMessage}</FormHelperText>}
        </Grid>
        <Grid {...defaultGridItemProps}>
          <Button
            color={'secondary'}
            variant={'contained'}
            startIcon={<Add />}
            className={commonStyles.uppercase}
            onClick={handleAddItemModalOpen}
            disabled={disabled}
          >
            {addNewSubListItemButtonName}
          </Button>
        </Grid>
        <Grid {...defaultGridItemProps}>
          <Table columns={columns} list={list} />
        </Grid>
      </Grid>
      <AddSublistItemModal
        onClose={handleAddItemModalClose}
        open={addItemModalOpen}
        questions={sublistQuestions || []}
        fieldName={fieldName}
      />
    </>
  );
};
export default SublistQuestionFormItem;
