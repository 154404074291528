import { Link } from '@mui/material';
import React, { useCallback, useState } from 'react';
import AttachFile from '@mui/icons-material/AttachFile';
import EventService from 'services/api/EventService';
import { useSnackbar } from 'notistack';
import Spinner from 'components/shared/Spinner';
import { MainEvent } from 'store/types/events/Event';
import { defaultSnackbarErrorProps } from 'util/Layout';

import commonStyles from 'styles/common.module.scss';
import styles from './EventFilesSection.module.scss';

interface EventFilesSectionProps {
  data: Pick<MainEvent, 'material' | 'id'>;
}

const EventFilesSection: React.FunctionComponent<EventFilesSectionProps> = ({ data: { id, material } }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const handleDownloadFile = useCallback(() => {
    if (material) {
      setLoading(true);
      EventService.getEventFile(id, material)
        .then(() => {
          setLoading(false);
        })
        .catch((errorMessage: string) => {
          setLoading(false);
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        });
    }
  }, [enqueueSnackbar, id, material]);

  return (
    <Spinner loading={loading}>
      <ul>
        <li key={`event-file-${material?.id}`} className={styles.file}>
          <AttachFile className={styles.fileIcon} />
          <Link className={commonStyles.link} onClick={handleDownloadFile}>
            {material?.name}
          </Link>
        </li>
      </ul>
    </Spinner>
  );
};
export default EventFilesSection;
