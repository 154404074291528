import { Button, ButtonGroup, Grid, MenuItem, TextField } from '@mui/material';
import { Alert } from '@mui/material';
import React, { ChangeEvent, Reducer, useCallback, useContext, useEffect, useReducer } from 'react';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import useRequest from 'hooks/useRequest';
import Spinner from 'components/shared/Spinner';
import { EventRegistrationViewStatus } from 'store/enums/events/EventRegistrationStatus';
import SearchInput from 'components/shared/SearchInput';
import EventRegistrationCard from './EventRegistrationCard';
import { getPagesCount } from 'util/Table';
import { ALL_EVENT_MODES_VALUE } from 'util/Event';
import reducer, {
  initialState,
  ITEMS_PER_PAGE,
  EventRegistrationsPageViewAction,
  EventRegistrationsPageViewActionType,
  EventRegistrationsPageViewState,
} from './EventRegistrationsPageViewReducer';
import EventService from 'services/api/EventService';
import Pagination from '@mui/material/Pagination';
import { useHistory, useLocation } from 'react-router';
import { EventsCategories } from 'store/types/events/EventFilter';
import EventRegistrationService from 'services/api/EventRegistrationService';
import { ParametersContext } from 'components/ParametersGuard';
import { MainEventRegistration, MainEventRegistrationView } from 'store/types/events/EventRegistration';

import commonStyles from 'styles/common.module.scss';
import styles from './EventRegistrationsPageView.module.scss';

const EventRegistrationsPageView: React.FunctionComponent = () => {
  const [{ filter, list = [], pageList = [], page }, dispatch] = useReducer<
    Reducer<EventRegistrationsPageViewState, EventRegistrationsPageViewAction>
  >(reducer, initialState);
  const history = useHistory();
  const { pathname, search } = useLocation();
  const {
    myEvents: { upcomingEventsTabHeader, completedEventsTabHeader },
  } = useContext(ParametersContext);
  const pagesCount = getPagesCount(list.length, ITEMS_PER_PAGE);
  const eventRegistrationsRequest = useCallback(
    () => EventRegistrationService.getEventRegistrations(filter.viewStatus, search),
    [filter, search]
  );
  const { data, loading, error } = useRequest<MainEventRegistration[]>(eventRegistrationsRequest);
  const {
    data: eventCategories,
    loading: eventCategoriesLoading,
    error: eventCategoriesError,
  } = useRequest<EventsCategories[]>(EventService.getEventsCategories);

  useEffect(() => {
    const params: URLSearchParams = new URLSearchParams(search);
    const mode: string = params.get('mode') || ALL_EVENT_MODES_VALUE;

    dispatch({
      type: EventRegistrationsPageViewActionType.UpdateFilter,
      payload: { filter: { mode } },
    });
  }, [search]);

  useEffect(() => {
    dispatch({
      type: EventRegistrationsPageViewActionType.SetInitialList,
      payload: { initialList: data || [] },
    });
  }, [data]);

  const getStatusButton = useCallback(
    (viewStatus: EventRegistrationViewStatus) => {
      const isButtonSelected = filter.viewStatus === viewStatus;
      const handleStatusSelected = () => {
        if (!isButtonSelected) {
          dispatch({
            type: EventRegistrationsPageViewActionType.UpdateFilter,
            payload: { filter: { viewStatus } },
          });
        }
      };

      return (
        <Button
          fullWidth={true}
          color={'primary'}
          variant={isButtonSelected ? 'contained' : 'outlined'}
          onClick={handleStatusSelected}
        >
          <span className={styles.statusButtonLabel}>{viewStatus}</span>
        </Button>
      );
    },
    [filter]
  );

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: EventRegistrationsPageViewActionType.UpdateFilter,
      payload: { filter: { search: e.target.value } },
    });
  }, []);

  const handleTypeFilterChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value: string = e.target.value;
      const newParams: URLSearchParams = new URLSearchParams();
      if (value) {
        value === ALL_EVENT_MODES_VALUE ? newParams.delete('mode') : newParams.set('mode', value);
      }
      history.replace({ pathname, search: `?${newParams.toString()}` });
    },
    [history, pathname]
  );

  const handlePageChange = useCallback((_: any, newPage: number) => {
    window.scrollTo(0, 0);
    dispatch({
      type: EventRegistrationsPageViewActionType.UpdatePage,
      payload: { page: newPage },
    });
  }, []);

  return error || eventCategoriesError ? (
    <Alert severity={'error'} className={commonStyles.alert}>
      {error || eventCategoriesError}
    </Alert>
  ) : (
    <Spinner loading={loading || eventCategoriesLoading}>
      <Grid {...defaultGridContainerProps} justifyContent={'space-between'} alignItems={'center'}>
        <Grid {...defaultGridItemProps} sm={true}>
          <ButtonGroup color={'primary'}>
            {getStatusButton(EventRegistrationViewStatus.Upcoming)}
            {getStatusButton(EventRegistrationViewStatus.Completed)}
          </ButtonGroup>
        </Grid>
        <Grid {...defaultGridItemProps} sm={'auto'}>
          <SearchInput
            value={filter.search}
            onChange={handleSearchChange}
            loading={loading}
            placeholder={'Search by Name'}
          />
        </Grid>
        <Grid {...defaultGridItemProps} xs={12}>
          {(upcomingEventsTabHeader || completedEventsTabHeader) && (
            <p
              dangerouslySetInnerHTML={{
                __html:
                  filter.viewStatus === EventRegistrationViewStatus.Upcoming
                    ? upcomingEventsTabHeader
                    : completedEventsTabHeader,
              }}
            />
          )}
        </Grid>
        <Grid {...defaultGridItemProps}>
          <div className={styles.select}>
            <span className={styles.selectLabel}>Show</span>
            <TextField
              select={true}
              variant={'outlined'}
              size={'small'}
              disabled={loading}
              value={filter.mode}
              onChange={handleTypeFilterChange}
            >
              <MenuItem value={ALL_EVENT_MODES_VALUE}>{'All Events'}</MenuItem>
              {eventCategories?.map(({ name, id }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </Grid>
        {pageList.map((item: MainEventRegistrationView) => (
          <Grid {...defaultGridItemProps} key={`event-registration-${item.registrant.id}-${item.eventRegistrationId}`}>
            <EventRegistrationCard data={item} />
            {item.guests?.map((item: MainEventRegistrationView) => (
              <EventRegistrationCard
                data={item}
                key={`guest-registration-${item.registrant.id}-${item.eventRegistrationId}`}
              />
            ))}
          </Grid>
        ))}
        {!pageList.length && (
          <Grid {...defaultGridItemProps} className={styles.notFoundSection}>
            <p>{'No registrations found'}</p>
          </Grid>
        )}
        <Grid {...defaultGridItemProps} className={styles.paginationWrapper}>
          <Pagination
            disabled={loading}
            showFirstButton={true}
            showLastButton={true}
            count={pagesCount}
            page={page}
            onChange={handlePageChange}
          />
        </Grid>
      </Grid>
    </Spinner>
  );
};
export default EventRegistrationsPageView;
