import Payment, { OrderPayment } from 'store/types/Payment';
import { PaymentFormValues } from 'store/types/FormValues';
import { defaultPaymentFormValues } from 'util/Payment';
import PaymentUser from 'store/types/PaymentUser';

export enum MakeGroupPaymentPageViewActionType {
  UpdatePaymentForm = 'UpdatePaymentForm',
  SetOrdersLoading = 'SetOrdersLoading',
  SetSubmitLoading = 'SetSubmitLoading',
  SetStep = 'SetStep',
  SetOrdersList = 'SetOrdersList',
  SetUsersList = 'SetUsersList',
  UpdateSelectedUsersList = 'UpdateSelectedUsersList',
  UpdateSelectedOrdersList = 'UpdateSelectedOrdersList',
  OpenPaymentConfirmation = 'OpenPaymentConfirmation',
}

export interface MakeGroupPaymentPageViewState {
  step: number;
  ordersList: OrderPayment[];
  usersList: PaymentUser[];
  paymentForm: PaymentFormValues;
  selectedUsers: Array<PaymentUser['profileId']>;
  selectedOrders: OrderPayment[];
  submitLoading?: boolean;
  ordersLoading?: boolean;
  payment?: Payment;
}

export interface MakeGroupPaymentPageViewAction {
  type: MakeGroupPaymentPageViewActionType;
  payload: Partial<Omit<MakeGroupPaymentPageViewState, 'paymentForm'>> & {
    paymentForm?: Partial<PaymentFormValues>;
  };
}

export const initialState: MakeGroupPaymentPageViewState = {
  step: 0,
  usersList: [],
  ordersList: [],
  paymentForm: defaultPaymentFormValues,
  selectedOrders: [],
  selectedUsers: [],
  payment: undefined,
};

const reducer = (
  state: MakeGroupPaymentPageViewState,
  { type, payload }: MakeGroupPaymentPageViewAction
): MakeGroupPaymentPageViewState => {
  if (type === MakeGroupPaymentPageViewActionType.SetSubmitLoading) {
    const { submitLoading = false } = payload;

    return { ...state, submitLoading };
  }
  if (type === MakeGroupPaymentPageViewActionType.SetOrdersLoading) {
    const { ordersLoading = false } = payload;

    return { ...state, ordersLoading };
  }

  if (type === MakeGroupPaymentPageViewActionType.SetStep) {
    const { step = 0 } = payload;

    return { ...state, step };
  }

  if (type === MakeGroupPaymentPageViewActionType.SetOrdersList) {
    const { ordersList = [] } = payload;

    return {
      ...state,
      ordersLoading: false,
      ordersList,
      selectedOrders: [],
    };
  }

  if (type === MakeGroupPaymentPageViewActionType.SetUsersList) {
    const { usersList = [] } = payload;

    return { ...state, usersList, selectedUsers: [] };
  }

  if (type === MakeGroupPaymentPageViewActionType.UpdatePaymentForm) {
    const { paymentForm = {} } = payload;

    return { ...state, paymentForm: { ...state.paymentForm, ...paymentForm } };
  }

  if (type === MakeGroupPaymentPageViewActionType.UpdateSelectedOrdersList) {
    const { selectedOrders = [] } = payload;

    return { ...state, selectedOrders };
  }

  if (type === MakeGroupPaymentPageViewActionType.UpdateSelectedUsersList) {
    const { selectedUsers = [] } = payload;

    return { ...state, selectedUsers, selectedOrders: [] };
  }

  if (type === MakeGroupPaymentPageViewActionType.OpenPaymentConfirmation) {
    const { payment } = payload;

    return { ...state, submitLoading: false, payment };
  }

  return state;
};

export default reducer;
