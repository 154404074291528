enum ApplicationStatus {
  Draft = 'draft',
  PendingPayment = 'pendingpayment',
  Submitted = 'submitted',
  UnderReview = 'underreview',
  InformationRequired = 'informationrequired',
  Approved = 'approved',
  Denied = 'denied',
}
export default ApplicationStatus;
