import React from 'react';
import FooterLogo from 'assets/images/smacna/footer-logo.png';
import { ReactComponent as LinkedInIcon } from 'assets/icons/smacna/linkedin.svg';
import { ReactComponent as FacebookIcon } from 'assets/icons/smacna/facebook.svg';
import { ReactComponent as TwitterIcon } from 'assets/icons/smacna/x.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/smacna/instagram.svg';
import { Container } from '@mui/material';

import styles from './SmacnaFooter.module.scss';
import commonStyles from 'styles/common.module.scss';

const SmacnaFooter: React.FunctionComponent = () => {
  return (
    <footer className={styles.footer} id={'smacna-footer'}>
      <Container maxWidth={'lg'} className={styles.container}>
        <div className={styles.leftSection}>
          <img
            className={styles.logo}
            src={FooterLogo}
            alt={"Sheet Metal and Air Conditioning Contractors' National Assocation logo"}
          />
          <p className={commonStyles.text}>{'Sheet Metal and Air Conditioning'} </p>
          <p className={commonStyles.text}>{"Contractors' National Association"}</p>
        </div>
        <div className={styles.middleSection}>
          <div className={styles.social}>
            <span className={styles.followTitle}>{'Follow'}</span>
            <a className={styles.facebookIcon} href={'https://www.facebook.com/SMACNA'}>
              <FacebookIcon />
            </a>
            <a href={'https://twitter.com/smacna'}>
              <TwitterIcon />
            </a>
            <a href={'https://www.linkedin.com/company/smacna/'}>
              <LinkedInIcon />
            </a>
            <a href={'https://www.instagram.com/smacna/ '}>
              <InstagramIcon />
            </a>
          </div>
          <div>
            <p>{'4201 Lafayette Center Drive | Chantilly, Virginia | 20151-1219'}</p>
            <p>{'Tel 703.803.2980 | Fax 703.803.3732'}</p>
          </div>
          <p>{'© 1998-2024 SMACNA. All rights reserved.'}</p>
        </div>
        <div className={styles.rightSection}>
          <a className={styles.bookstoreLink} href={'https://store.smacna.org/'} target={'_blank'} rel="noreferrer">
            {'Bookstore'}
          </a>
          <a href={'https://www.smacna.org/contact-smacna'}>{'Contact Smacna'}</a>
          <a href={'https://www.smacna.org/sitemap'}>{'Site Map'}</a>
          <a href={'https://www.smacna.org/privacy-policy'}>{'Privacy Policy'}</a>
          <a href={'https://www.smacna.org/smacna-website-terms-of-use'}>{'Acceptable Use'}</a>
        </div>
      </Container>
    </footer>
  );
};

export default SmacnaFooter;
