import Payment from 'store/types/Payment';
import { PaymentFormValues } from 'store/types/FormValues';
import { defaultPaymentFormValues } from 'util/Payment';

export enum ApplicationPaymentPageActionType {
  UpdatePaymentForm = 'UpdatePaymentForm',
  SetLoading = 'SetLoading',
  OpenPaymentConfirmation = 'OpenPaymentConfirmation',
}

export interface ApplicationPaymentPageState {
  paymentForm: PaymentFormValues;
  loading?: boolean;
  payment?: Payment;
}

export interface ApplicationPaymentPageAction {
  type: ApplicationPaymentPageActionType;
  payload: Partial<Omit<ApplicationPaymentPageState, 'paymentForm'>> & {
    paymentForm?: Partial<PaymentFormValues>;
  };
}

export const initialState: ApplicationPaymentPageState = {
  paymentForm: defaultPaymentFormValues,
  payment: undefined,
};

const reducer = (
  state: ApplicationPaymentPageState,
  { type, payload }: ApplicationPaymentPageAction
): ApplicationPaymentPageState => {
  if (type === ApplicationPaymentPageActionType.SetLoading) {
    const { loading = false } = payload;

    return { ...state, loading };
  }

  if (type === ApplicationPaymentPageActionType.UpdatePaymentForm) {
    const { paymentForm = {} } = payload;

    return { ...state, paymentForm: { ...state.paymentForm, ...paymentForm } };
  }

  if (type === ApplicationPaymentPageActionType.OpenPaymentConfirmation) {
    const { payment } = payload;

    return { ...state, loading: false, payment };
  }

  return state;
};

export default reducer;
