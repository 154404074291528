import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import PaymentMethodsTable from './PaymentMethodsTable';
import { ParametersContext } from 'components/ParametersGuard';

const ManagePaymentMethodsPageView: React.FunctionComponent = () => {
  const {
    settings: { manageSavedPaymentTabHeader },
  } = useContext(ParametersContext);

  return (
    <Grid {...defaultGridContainerProps}>
      <Grid {...defaultGridItemProps}>
        {manageSavedPaymentTabHeader && <p dangerouslySetInnerHTML={{ __html: manageSavedPaymentTabHeader }} />}
      </Grid>
      <Grid {...defaultGridItemProps}>
        <PaymentMethodsTable />
      </Grid>
    </Grid>
  );
};
export default ManagePaymentMethodsPageView;
