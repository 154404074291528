import React, { useCallback, useContext, useState } from 'react';
import { Button, Divider, Grid, IconButton, Link, Tooltip } from '@mui/material';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import { getAddressInfo, getUserFullName, NUMBER_DATE_FORMAT } from 'util/Format';
import Card from 'components/shared/Card';
import commonStyles from 'styles/common.module.scss';
import { getHashRouteUrl } from 'util/Route';
import routes from 'store/configs/Routes';
import { Controller, useForm } from 'react-hook-form';
import Avatar from 'components/shared/Avatar';
import EditIcon from '@mui/icons-material/Edit';
import UserService from 'services/api/UserService';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { ParametersContext } from 'components/ParametersGuard';
import { defaultFormProps } from 'util/Form';
import { useSnackbar } from 'notistack';
import { tenantConfig } from 'config';
import { PublicUser, UserPrivateSettings } from 'store/types/User';
import { UserContext } from 'components/UserGuard';
import UploadImageModal from 'components/shared/UploadImageModal';
import Switch from 'components/shared/Switch';
import MemberDirectoryCustomerType from 'store/enums/MemberDirectoryCustomerType';
import StatusLabel from 'components/shared/StatusLabel';
import moment from 'moment-timezone';
import Spinner from 'components/shared/Spinner';
import Website from 'components/shared/Website';

import styles from './ProfileForm.module.scss';

type FormValues = UserPrivateSettings;

interface ProfileFormProps {
  data: PublicUser;
  isEditable?: boolean;
}

const ProfileForm: React.FunctionComponent<ProfileFormProps> = ({ data, isEditable }) => {
  const {
    profile: { profileSectionTooltip, profilePrivateSectionTooltip },
  } = useContext(ParametersContext);
  const { editProfileUrl = '' } = tenantConfig;
  const { enqueueSnackbar } = useSnackbar();
  const { control, getValues, reset } = useForm<FormValues>({ ...defaultFormProps });
  const currentUser = useContext(UserContext);
  const { setCurrentUser, userLoading } = currentUser;
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false);
  const {
    logoUrl,
    id,
    title,
    parentOrCustomCompanyName,
    biography,
    birthDate,
    nickname,
    cellPhone,
    phone,
    email,
    address,
    company,
    entityId,
    professionalDesignation,
    isBiographyPrivate,
    isContactInformationPrivate,
    isProfilePhotoPrivate,
  } = data;

  const handleUploadImageOpen = useCallback(() => {
    setUploadModalOpen(true);
  }, []);

  const handleUploadImageClose = useCallback(() => {
    setUploadModalOpen(false);
  }, []);

  const handleUploadImageSubmit = useCallback(
    (images: File[]) => {
      setLoading(true);
      UserService.updateCurrentUserImage(images[0])
        .then((newLogoUrl: string) => {
          enqueueSnackbar('Image successfully updated', { variant: 'success' });
          setUploadModalOpen(false);
          setCurrentUser({ logoUrl: newLogoUrl });
          setLoading(false);
        })
        .catch((error: string) => {
          enqueueSnackbar(error, defaultSnackbarErrorProps);
          setLoading(false);
        });
    },
    [enqueueSnackbar, setCurrentUser]
  );

  const handleToggleStateSubmit = useCallback(() => {
    setLoading(true);
    const data: UserPrivateSettings = getValues();

    UserService.updatePrivateProfileFields(data)
      .then(() => {
        enqueueSnackbar('Private settings updated', { variant: 'success' });
        setCurrentUser({ ...data });
        setLoading(false);
      })
      .catch((error: string) => {
        enqueueSnackbar(error, defaultSnackbarErrorProps);
        reset({
          isContactInformationPrivate,
          isBiographyPrivate,
          isProfilePhotoPrivate,
        });
        setLoading(false);
      });
  }, [
    enqueueSnackbar,
    getValues,
    isBiographyPrivate,
    isContactInformationPrivate,
    isProfilePhotoPrivate,
    reset,
    setCurrentUser,
  ]);

  const handleChange = useCallback(
    (onChange) => (e: any) => {
      onChange(e.target.checked);
      handleToggleStateSubmit();
    },
    [handleToggleStateSubmit]
  );

  const getTooltip = useCallback(
    (value: string) => (
      <Tooltip
        arrow={true}
        componentsProps={{ tooltip: { className: styles.tooltipContent } }}
        placement={'top'}
        title={<p className={commonStyles.text} dangerouslySetInnerHTML={{ __html: value }} />}
      >
        <span className={styles.tooltip}>
          <InfoOutlined color={'info'} className={styles.icon} />
        </span>
      </Tooltip>
    ),
    []
  );

  const getToggle = useCallback(
    ({ onChange, value }) => (
      <div className={styles.toggle}>
        <p className={commonStyles.text}>{'Make Private'}</p>
        {profilePrivateSectionTooltip && getTooltip(profilePrivateSectionTooltip)}
        <Switch onChange={handleChange(onChange)} value={value} checked={value} color={'primary'} size={'small'} />
      </div>
    ),
    [getTooltip, handleChange, profilePrivateSectionTooltip]
  );

  return (
    <Spinner loading={loading}>
      <Grid {...defaultGridContainerProps} className={styles.profile} justifyContent={'center'}>
        <Grid {...defaultGridItemProps} lg={true}>
          <div className={styles.userTitle}>
            <h2>{getUserFullName(data, true)}</h2>
            {isEditable ? (
              <p className={styles.id}>{`Customer ID: ${entityId}`}</p>
            ) : (
              <StatusLabel status={MemberDirectoryCustomerType.Individual} />
            )}
          </div>
          <Divider className={styles.divider} />
          <Grid {...defaultGridContainerProps} spacing={2}>
            {(isEditable || !isContactInformationPrivate) && (
              <Grid {...defaultGridItemProps} lg={6}>
                <Grid {...defaultGridContainerProps} spacing={2}>
                  <Grid {...defaultGridItemProps}>
                    <Card
                      className={styles.card}
                      spinnerClassName={styles.spinner}
                      title={<h3>{'Contact Information'}</h3>}
                    >
                      {(isEditable || nickname) && (
                        <p className={commonStyles.text}>
                          <b>{`Nickname: `}</b> <span>{nickname}</span>
                        </p>
                      )}
                      {(isEditable || phone) && (
                        <p className={commonStyles.text}>
                          <b>{`Phone: `}</b> <span>{phone}</span>
                        </p>
                      )}
                      {(isEditable || address) && (
                        <p className={commonStyles.text}>
                          <b>{`Address: `}</b> <span>{getAddressInfo(address)}</span>
                        </p>
                      )}
                      {(isEditable || email) && (
                        <p className={commonStyles.text}>
                          <b>{`Email: `}</b> <span>{email}</span>
                        </p>
                      )}
                      {isEditable && (
                        <span className={styles.helperText}>
                          {'Your email address is used as account login. To edit, please to go '}
                          <Link href={getHashRouteUrl(routes.settingsAccount)} underline={'always'} color={'inherit'}>
                            {'Account Settings.'}
                          </Link>
                        </span>
                      )}
                      {isEditable && (
                        <Controller
                          render={({ field }) => getToggle(field)}
                          defaultValue={isContactInformationPrivate}
                          name={'isContactInformationPrivate'}
                          control={control}
                        />
                      )}
                    </Card>
                  </Grid>
                  {isEditable && (
                    <Grid {...defaultGridItemProps}>
                      <Card
                        className={styles.card}
                        spinnerClassName={styles.spinner}
                        title={<h3>{'Personal Information'}</h3>}
                      >
                        {(isEditable || birthDate) && (
                          <p className={commonStyles.text}>
                            <b>{`BirthDate: `}</b>{' '}
                            <span>{birthDate && moment(birthDate).format(NUMBER_DATE_FORMAT)}</span>
                          </p>
                        )}
                        {(isEditable || cellPhone) && (
                          <p className={commonStyles.text}>
                            <b>{`Mobile: `}</b> <span>{cellPhone}</span>
                          </p>
                        )}
                        {(isEditable || professionalDesignation) && (
                          <p className={commonStyles.text}>
                            <b>{`Professional Designation: `}</b> <span>{professionalDesignation}</span>
                          </p>
                        )}
                        {isEditable && profileSectionTooltip && (
                          <div className={styles.tooltipWrapper}>{getTooltip(profileSectionTooltip)}</div>
                        )}
                      </Card>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
            <Grid {...defaultGridItemProps} lg={6}>
              <Card className={styles.card} spinnerClassName={styles.spinner} contentClassName={styles.cardContent}>
                <div>
                  <h3 className={styles.title}>{'Professional Information'}</h3>
                  {(isEditable || title) && (
                    <p className={commonStyles.text}>
                      <b>{`Title: `}</b> <span>{title}</span>
                    </p>
                  )}
                  {(isEditable || parentOrCustomCompanyName) && (
                    <p className={commonStyles.text}>
                      <b>{`Company: `}</b> <span>{parentOrCustomCompanyName}</span>
                    </p>
                  )}
                  {(isEditable || company?.website) && (
                    <p className={commonStyles.text}>
                      <b>{`Website: `}</b>
                      <span>{company?.website && <Website link={company.website} />}</span>
                    </p>
                  )}
                  {(isEditable || company?.email) && (
                    <p className={commonStyles.text}>
                      <b>{`Email: `}</b> <span>{company?.email}</span>
                    </p>
                  )}
                  {(isEditable || company?.phone) && (
                    <p className={commonStyles.text}>
                      <b>{`Phone: `}</b> <span>{company?.phone}</span>
                    </p>
                  )}
                  {(isEditable || company?.address) && (
                    <p className={commonStyles.text}>
                      <b>{`Address: `}</b> <span>{company?.address && getAddressInfo(company?.address)}</span>
                    </p>
                  )}
                </div>
                {isEditable && profileSectionTooltip && (
                  <div className={styles.tooltipWrapper}>{getTooltip(profileSectionTooltip)}</div>
                )}
              </Card>
            </Grid>
            {(isEditable || !isBiographyPrivate) && (
              <Grid {...defaultGridItemProps}>
                <Card title={<h3>{'Biography'}</h3>}>
                  <p className={commonStyles.text}>{biography}</p>
                  {isEditable && (
                    <Controller
                      render={({ field }) => getToggle(field)}
                      defaultValue={isBiographyPrivate}
                      name={'isBiographyPrivate'}
                      control={control}
                    />
                  )}
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid {...defaultGridItemProps} xs={'auto'} className={styles.avatarSection}>
          <div className={styles.avatar}>
            <Avatar
              className={styles.avatarImage}
              src={isEditable || !isProfilePhotoPrivate ? logoUrl : ''}
              loading={userLoading}
              darkBackground={true}
            />
            {isEditable && (
              <IconButton className={styles.editButton} color={'primary'} onClick={handleUploadImageOpen}>
                <EditIcon className={styles.editButtonIcon} />
              </IconButton>
            )}
          </div>
          {isEditable && (
            <Controller
              render={({ field }) => getToggle(field)}
              defaultValue={isProfilePhotoPrivate}
              name={'isProfilePhotoPrivate'}
              control={control}
            />
          )}
        </Grid>
        {editProfileUrl && isEditable && (
          <Grid {...defaultGridItemProps}>
            <Button
              color={'secondary'}
              variant={'contained'}
              type={'submit'}
              href={editProfileUrl + encodeURIComponent(id)}
            >
              {'Edit Profile'}
            </Button>
          </Grid>
        )}
      </Grid>
      <UploadImageModal
        open={uploadModalOpen}
        loading={loading}
        onClose={handleUploadImageClose}
        onSubmit={handleUploadImageSubmit}
      />
    </Spinner>
  );
};
export default ProfileForm;
