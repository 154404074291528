import React, { useCallback, useMemo } from 'react';
import { DeleteOutlined } from '@mui/icons-material';
import { Button, Divider, Grid } from '@mui/material';
import Table from 'components/shared/Table';
import { ExpenseFile } from 'store/types/Expense';
import { TableColumn } from 'store/types/Table';
import { defaultGridItemProps } from 'util/Layout';

import commonStyles from 'styles/common.module.scss';
import styles from 'components/expense/Expense.module.scss';

interface FileListDeleteProps {
  file: ExpenseFile;
  isReadOnly: boolean;
  onDeleteFile: () => void;
}

const FileListDelete: React.FunctionComponent<FileListDeleteProps> = ({ file, isReadOnly, onDeleteFile }) => {
  const handleDeleteFile = useCallback(() => {
    onDeleteFile();
  }, [onDeleteFile]);

  const columns: Array<TableColumn<ExpenseFile>> = useMemo(
    () => [
      {
        dataIndex: 'name',
        label: 'Filename',
        sortable: false,
      },
      {
        key: 'actions',
        label: 'Action',
        sortable: false,
        align: 'center',
        hidden: isReadOnly,
        render: () => (
          <Button
            size={'small'}
            color={'primary'}
            variant={'outlined'}
            className={commonStyles.dangerButtonOutlined}
            onClick={handleDeleteFile}
          >
            <DeleteOutlined className={styles.icon} />
          </Button>
        ),
      },
    ],
    [handleDeleteFile, isReadOnly]
  );

  return (
    <Grid {...defaultGridItemProps}>
      <Divider className={styles.divider} />
      <Table columns={columns} list={[file]} className={styles.table} />
    </Grid>
  );
};

export default FileListDelete;
