import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { ReactComponent as AppleWalletIcon } from 'assets/icons/appleWallet.svg';
import ComponentProps from 'store/types/ComponentProps';
import { Button } from '@mui/material';
import MembershipService from 'services/api/MembershipService';
import Spinner from 'components/shared/Spinner';
import { UAParser } from 'ua-parser-js';

import styles from './MembershipWalletButton.module.scss';
import { defaultSnackbarErrorProps } from 'util/Layout';

const IOS_NAME = 'iOS';

const MembershipWalletButton: React.FunctionComponent<ComponentProps> = ({ className = '' }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const parser = new UAParser();
  const userOSInfo = parser.getOS();
  const buttonVisible = userOSInfo.name && userOSInfo.name.toLowerCase() === IOS_NAME.toLowerCase();

  const handleButtonClick = () => {
    setLoading(true);
    MembershipService.getAppleWalletFile()
      .then(() => {
        setLoading(false);
      })
      .catch((errorMessage: string) => {
        enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        setLoading(false);
      });
  };

  return (
    <Spinner loading={loading}>
      {buttonVisible ? (
        <Button className={classNames(styles.button, className)} onClick={handleButtonClick}>
          <AppleWalletIcon className={styles.icon} />
        </Button>
      ) : null}
    </Spinner>
  );
};
export default MembershipWalletButton;
