import { Container, Divider } from '@mui/material';
import React, { useContext } from 'react';
import Header from 'components/layout/Header';
import { ConfigContext } from 'components/ConfigGuard';
import SmacnaFooter from 'components/layout/SmacnaFooter';

import commonStyles from 'styles/common.module.scss';
import styles from './PublicPageLayout.module.scss';

interface PublicPageLayoutProps {
  title?: React.ReactNode;
  hasFooter?: boolean;
}

const PublicPageLayout: React.FunctionComponent<PublicPageLayoutProps> = ({
  children,
  title = '',
  hasFooter = false,
}) => {
  const { isSmacnaTheme } = useContext(ConfigContext);

  return (
    <>
      <Header />
      <Container maxWidth={'lg'} data-testid={'public-page-layout'}>
        <div className={hasFooter ? styles.footerContainer : styles.container} data-testid={'container'}>
          {title && (
            <>
              <h1 className={commonStyles.pageTitle}>{title}</h1>
              <Divider className={styles.divider} />
            </>
          )}
          {children}
        </div>
      </Container>
      {isSmacnaTheme && <SmacnaFooter />}
    </>
  );
};
export default PublicPageLayout;
