import { Grid } from '@mui/material';
import Alert from '@mui/material/Alert';
import React from 'react';
import { getQuestionFieldName, getQuestionId } from 'util/Application';
import { PreferenceQuestionView } from 'store/types/PreferenceQuestion';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import Card from 'components/shared/Card';
import { getQuestionInput } from 'util/Preferences';
import { useFormContext } from 'react-hook-form';

import commonStyles from 'styles/common.module.scss';
import styles from './EditPreferencesForm.module.scss';

interface EditPreferencesFormProps {
  data: PreferenceQuestionView[];
  disabled?: boolean;
  parentFieldName?: string;
}

const EditPreferencesForm: React.FunctionComponent<EditPreferencesFormProps> = ({
  data,
  parentFieldName = '',
  disabled,
}) => {
  const {
    formState: { errors },
  } = useFormContext();
  const preferencesErrors = parentFieldName ? errors[parentFieldName] : errors;

  return (
    <>
      {!!preferencesErrors && !!Object.keys(preferencesErrors).length && (
        <>
          <p className={styles.errorsTitle}>{'Please check and correct the errors listed below:'}</p>
          <ul className={styles.errorsList}>
            {Object.entries(preferencesErrors).map((item) => {
              const name = data.find(({ id }) => id === getQuestionId(item[0]))?.name;
              return (
                <li key={name}>
                  <span className={styles.questionName}>{`${name}: `}</span>
                  {item[1]?.message}
                </li>
              );
            })}
          </ul>
        </>
      )}
      {data?.length ? (
        <>
          <Grid {...defaultGridContainerProps}>
            {data.map((question) => (
              <Grid key={getQuestionFieldName(question.id)} {...defaultGridItemProps}>
                <Card
                  bordered={true}
                  headerClassName={styles.header}
                  contentClassName={styles.content}
                  title={<h3>{`${question.name}${question.required ? '*' : ''}`}</h3>}
                >
                  <p dangerouslySetInnerHTML={{ __html: question.description }} />
                  {getQuestionInput(question, disabled)}
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <Alert severity={'error'} className={commonStyles.alert}>
          {'No content found'}
        </Alert>
      )}
    </>
  );
};
export default EditPreferencesForm;
