import React, { useContext } from 'react';
import { UserContext } from 'components/UserGuard';
import ProfileForm from 'components/profile/ProfileForm';

const EditProfilePageView: React.FunctionComponent = () => {
  const currentUser = useContext(UserContext);

  return <ProfileForm isEditable={true} data={currentUser} />;
};

export default EditProfilePageView;
