import React from 'react';
import ComponentProps from 'store/types/ComponentProps';
import { Anchorme } from 'react-anchorme';

import styles from './Website.module.scss';

interface TileProps extends ComponentProps {
  link: string;
}

const Website: React.FunctionComponent<TileProps> = ({ link }) => (
  <Anchorme className={styles.link} target={'_blank'}>
    {link}
  </Anchorme>
);

export default Website;
