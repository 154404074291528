import { FilterConfig, SortConfig } from 'store/types/Table';
import { sorter } from 'util/Table';
import Application from 'store/types/Application';

export enum ApplicationsTableActionType {
  SetInitialList = 'SetInitialList',
  UpdateSort = 'UpdateSort',
  UpdateFilter = 'UpdateFilter',
  OpenDeleteModal = 'OpenDeleteModal',
  CloseDeleteModal = 'CloseDeleteModal',
  OpenDecisionModal = 'OpenDecisionModal',
  CloseDecisionModal = 'CloseDecisionModal',
}

export interface ApplicationsTableState {
  initialList: Application[];
  list: Application[];
  sort: SortConfig<Application>;
  filter: FilterConfig<Application>;
  deleteModalOpen?: boolean;
  decisionModalOpen?: boolean;
  clickedItem?: Application;
}

export interface ApplicationsTableAction {
  type: ApplicationsTableActionType;
  payload: Partial<ApplicationsTableState>;
}

const getSortedList = (list: Application[] = [], sort: SortConfig<Application>): Application[] =>
  list.length ? [...list].sort(sorter<Application>(sort)) : [...list];

const getFilteredList = (list: Application[] = [], filter: FilterConfig<Application>) => {
  let result = [...list];
  const searchValue = (filter.search || '').toLowerCase();

  if (searchValue) {
    result = result.filter(
      ({ name, id }: Application) => name.toLowerCase().includes(searchValue) || id.toLowerCase().includes(searchValue)
    );
  }
  return result;
};

export const initialState: ApplicationsTableState = {
  initialList: [],
  list: [],
  sort: { direction: 'desc', column: 'submittedDate' },
  filter: { search: '' },
};

const reducer = (state: ApplicationsTableState, { type, payload }: ApplicationsTableAction): ApplicationsTableState => {
  if (type === ApplicationsTableActionType.SetInitialList) {
    const { initialList = [] } = payload;
    const list = getSortedList(initialList, state.sort);

    return { ...state, initialList, list };
  }

  if (type === ApplicationsTableActionType.UpdateSort) {
    const { sort = {} } = payload;

    return {
      ...state,
      sort,
      list: getSortedList(state.initialList, sort),
    };
  }

  if (type === ApplicationsTableActionType.OpenDeleteModal) {
    const { clickedItem } = payload;

    return { ...state, deleteModalOpen: true, clickedItem };
  }

  if (type === ApplicationsTableActionType.CloseDeleteModal) {
    return { ...state, deleteModalOpen: false, clickedItem: undefined };
  }

  if (type === ApplicationsTableActionType.OpenDecisionModal) {
    const { clickedItem } = payload;

    return { ...state, decisionModalOpen: true, clickedItem };
  }

  if (type === ApplicationsTableActionType.CloseDecisionModal) {
    return { ...state, decisionModalOpen: false, clickedItem: undefined };
  }

  if (type === ApplicationsTableActionType.UpdateFilter) {
    const { filter = {} } = payload;
    const newFilter = { ...state.filter, ...filter };

    return {
      ...state,
      filter: newFilter,
      list: getFilteredList(getSortedList(state.initialList, state.sort), newFilter),
    };
  }

  return state;
};

export default reducer;
