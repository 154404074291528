import React, { useCallback, useContext } from 'react';
import Spinner from 'components/shared/Spinner';
import { UserContext } from 'components/UserGuard';
import classNames from 'classnames';
import Sidebar from 'components/layout/Sidebar';
import { useWindowSize } from 'util/Window';
import PublicHeader from 'components/layout/PublicHeader/PublicHeader';
import PublicCommitteePageView from 'components/communities/PublicCommitteePageView';
import useRequest from 'hooks/useRequest';
import { Community } from 'store/types/Community';
import CommitteeService from 'services/api/CommitteeService';
import Alert from '@mui/material/Alert';

import commonStyles from 'styles/common.module.scss';
import styles from './PublicCommitteePage.module.scss';

const PublicCommitteePage: React.FunctionComponent = () => {
  const { userLoading = false } = useContext(UserContext);
  const { isMobile } = useWindowSize();
  const committeeRequest = useCallback(() => CommitteeService.getCommittees(true), []);
  const { data, loading, error } = useRequest<Community[]>(committeeRequest);

  return (
    <Spinner loading={userLoading || loading} transparent={false}>
      {error ? (
        <Alert severity={'error'} className={commonStyles.alert}>
          {error}
        </Alert>
      ) : (
        <div className={styles.wrapper}>
          <div className={classNames(styles.sidebar, { [commonStyles.hidden]: isMobile })}>
            <Sidebar alwaysCollapsed={true} />
          </div>
          <div className={'rootContent'}>
            <PublicHeader />
            {data && <PublicCommitteePageView data={data} />}
          </div>
        </div>
      )}
    </Spinner>
  );
};
export default PublicCommitteePage;
