// NOTE: Solupay card type values
enum PaymentMethodCardType {
  Diners = 'Diners',
  Amex = 'Amex',
  Visa = 'Visa',
  Discover = 'Discover',
  Jcb = 'Jcb',
  Maestro = 'Maestro',
  Mastercard = 'Mastercard',
}
export default PaymentMethodCardType;
