import { sorter } from 'util/Table';
import { SortConfig } from 'store/types/Table';
import { PriorApproval } from 'store/types/PriorApproval';

export enum PriorApprovalsTableActionType {
  SetInitialList = 'SetInitialList',
  UpdateSort = 'UpdateSort',
}

export interface PriorApprovalsTableState {
  initialList: PriorApproval[];
  list: PriorApproval[];
  sort: SortConfig<PriorApproval>;
}

export interface PriorApprovalsTableAction {
  type: PriorApprovalsTableActionType;
  payload: Partial<PriorApprovalsTableState>;
}

const getSortedList = (list: PriorApproval[] = [], sort: SortConfig<PriorApproval>): PriorApproval[] => {
  return list.length ? [...list].sort(sorter<PriorApproval>(sort)) : [...list];
};

export const initialState: PriorApprovalsTableState = {
  initialList: [],
  list: [],
  sort: { direction: 'asc', column: 'companyName' },
};

const reducer = (
  state: PriorApprovalsTableState,
  { type, payload }: PriorApprovalsTableAction
): PriorApprovalsTableState => {
  if (type === PriorApprovalsTableActionType.SetInitialList) {
    const { initialList = [] } = payload;

    return { ...state, initialList, list: getSortedList(initialList, state.sort) };
  }

  if (type === PriorApprovalsTableActionType.UpdateSort) {
    const { sort = {} } = payload;
    const newList = getSortedList(state.initialList, sort);

    return { ...state, sort, list: newList };
  }

  return state;
};

export default reducer;
