import React, { useEffect, useMemo } from 'react';
import { TextField, MenuItem, Grid } from '@mui/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormProps, getRequiredValidationRule, getValidationProps } from 'util/Form';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { ApplicationQuestionView, ApplicationQuestionOption } from 'store/types/ApplicationQuestion';
import ExplanationQuestionFormItem from '../ExplanationQuestionFormItem';

interface SelectQuestionFormItemProps extends FormProps {
  question: ApplicationQuestionView;
}

const SelectQuestionFormItem: React.FunctionComponent<SelectQuestionFormItemProps> = ({ question, disabled }) => {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const { fieldName, options = [], required } = question;
  const fieldValue = useWatch({ control, name: fieldName });
  const optionExplanation: ApplicationQuestionOption | undefined = useMemo(
    () =>
      fieldValue
        ? options.find(({ includeExplanation, value }) => !!includeExplanation && value === fieldValue)
        : undefined,
    [options, fieldValue]
  );

  useEffect(() => {
    const selectedOption = options.find(({ isSelected }) => isSelected);

    if (selectedOption) {
      const { value } = selectedOption;

      setValue(fieldName, value);
    }
  }, [options, setValue, fieldName, getValues]);

  return (
    <Grid {...defaultGridContainerProps}>
      <Grid {...defaultGridItemProps}>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              select={true}
              required={required}
              {...getValidationProps(fieldName, errors)}
              disabled={disabled}
            >
              {options.map(({ value, name }) => (
                <MenuItem value={value} key={`${fieldName}-option-${value}`}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
          )}
          name={fieldName}
          control={control}
          rules={{
            required: getRequiredValidationRule('your answer', true, required),
          }}
          defaultValue={''}
        />
      </Grid>
      {optionExplanation && (
        <Grid {...defaultGridItemProps}>
          <ExplanationQuestionFormItem
            parentFieldName={fieldName}
            required={required}
            defaultValue={optionExplanation.explanation}
            disabled={disabled}
          />
        </Grid>
      )}
    </Grid>
  );
};
export default SelectQuestionFormItem;
