import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import useRequest from 'hooks/useRequest';
import CompanyService from 'services/api/CompanyService';
import EditCompanySegmentsModal from './EditCompanySegmentsModal';
import Alert from '@mui/material/Alert';
import Spinner from 'components/shared/Spinner';
import { SortConfig, TableColumn } from 'store/types/Table';
import { CompanySegments } from 'store/types/Company';
import Table from 'components/shared/Table';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { Button, Grid } from '@mui/material';
import { sorter } from 'util/Table';
import { Edit } from '@mui/icons-material';
import { ParametersContext } from 'components/ParametersGuard';

import commonStyles from 'styles/common.module.scss';
import pageStyles from '../CompanyPageView/CompanyPageView.module.scss';

interface CompanySegmentsSectionProps {
  companyId: string;
}

const CompanySegmentsSection: React.FunctionComponent<CompanySegmentsSectionProps> = ({ companyId }) => {
  const [editSegmentsModalOpen, setEditSegmentsModalOpen] = useState<boolean>(false);
  const [list, setList] = useState<CompanySegments[]>([]);
  const [sort, setSort] = useState<SortConfig<CompanySegments>>();
  const {
    companyManagement: { marketSegmentHeader },
  } = useContext(ParametersContext);
  const companySegmentsRequest = useMemo(
    () => (companyId ? () => CompanyService.getCompanySegments(companyId) : undefined),
    [companyId]
  );
  const { data, loading, error, refetch } = useRequest<CompanySegments[]>(companySegmentsRequest);

  useEffect(() => {
    if (data) {
      const newList = data.filter(({ value }) => !!value);

      setList(newList);
    }
  }, [data]);

  const handleEditSegmentsModalClose = useCallback(() => {
    setEditSegmentsModalOpen(false);
  }, []);

  const handleEditSegmentsModalOpen = useCallback(() => {
    setEditSegmentsModalOpen(true);
  }, []);

  const handleSortChange = useCallback((newSort: SortConfig) => {
    setSort(newSort);
    setList((prevState) => (prevState.length ? [...prevState].sort(sorter<CompanySegments>(newSort)) : [...prevState]));
  }, []);

  const columns: Array<TableColumn<CompanySegments>> = [
    {
      dataIndex: 'name',
      label: 'Industry',
      sortable: true,
    },
    {
      dataIndex: 'value',
      label: 'Percentage',
      align: 'center',
      sortable: true,
      render: (value: number) => `${value}%`,
    },
  ];

  return (
    <Spinner loading={loading} transparent={false}>
      {error ? (
        <Alert severity={'error'} className={commonStyles.alert}>
          {error}
        </Alert>
      ) : (
        <Grid {...defaultGridContainerProps}>
          <Grid {...defaultGridItemProps} xs={true} className={pageStyles.wrapper}>
            <h2 className={classNames(commonStyles.subTitle, commonStyles.uppercase, pageStyles.title)}>
              {'Market Segments'}
            </h2>
          </Grid>
          <Grid {...defaultGridItemProps} md={'auto'}>
            <Button variant={'outlined'} onClick={handleEditSegmentsModalOpen}>
              <Edit />
            </Button>
          </Grid>
          <Grid {...defaultGridItemProps}>
            {marketSegmentHeader && <p dangerouslySetInnerHTML={{ __html: marketSegmentHeader }} />}
            <Table columns={columns} list={list} loading={loading} sort={sort} onSortChange={handleSortChange} />
          </Grid>
        </Grid>
      )}
      {editSegmentsModalOpen && (
        <EditCompanySegmentsModal
          open={editSegmentsModalOpen}
          onClose={handleEditSegmentsModalClose}
          companyId={companyId}
          refetch={refetch}
        />
      )}
    </Spinner>
  );
};

export default CompanySegmentsSection;
