import { FilterConfig, SortConfig } from 'store/types/Table';
import { sorter, userFullNameSortLabel } from 'util/Table';
import CommunityMember from 'store/types/CommunityMember';
import { getUserFullName } from 'util/Format';

export enum CommunityMembersSectionActionType {
  SetInitialList = 'SetInitialList',
  UpdateSort = 'UpdateSort',
  UpdateFilter = 'UpdateFilter',
  OpenDetails = 'OpenDetails',
  CloseDetails = 'CloseDetails',
}

export type TableCommunityUser = CommunityMember & { [userFullNameSortLabel]?: string };

export interface CommunityMembersSectionState {
  initialList: CommunityMember[];
  list: CommunityMember[];
  sort: SortConfig<TableCommunityUser>;
  filter: FilterConfig<TableCommunityUser>;
  clickedItem?: CommunityMember;
}

export interface CommunityMembersSectionAction {
  type: CommunityMembersSectionActionType;
  payload: Partial<CommunityMembersSectionState>;
}

const getSortedList = (list: TableCommunityUser[] = [], sort: SortConfig<TableCommunityUser>): TableCommunityUser[] =>
  list.length ? [...list].sort(sorter<TableCommunityUser>(sort)) : [...list];

const getFilteredList = (list: TableCommunityUser[] = [], filter: FilterConfig<CommunityMember>) => {
  const searchValue = filter.search?.toLowerCase();
  let result = [...list];

  if (filter.role.length) {
    result = result.filter(({ role }) => filter.role.includes(role));
  }
  if (searchValue) {
    result = result.filter(({ firstName, lastName }: TableCommunityUser) =>
      getUserFullName({ firstName, lastName }).toLowerCase().includes(searchValue)
    );
  }
  return result;
};

export const initialState: CommunityMembersSectionState = {
  initialList: [],
  list: [],
  sort: { direction: 'asc', column: 'role' },
  filter: { search: '', role: [] },
};

const reducer = (
  state: CommunityMembersSectionState,
  { type, payload }: CommunityMembersSectionAction
): CommunityMembersSectionState => {
  if (type === CommunityMembersSectionActionType.SetInitialList) {
    const { initialList = [] } = payload;

    return { ...state, initialList, list: getSortedList(initialList, state.sort) };
  }

  if (type === CommunityMembersSectionActionType.UpdateSort) {
    const { sort = {} } = payload;

    return {
      ...state,
      sort,
      list: getSortedList(state.initialList, sort),
    };
  }

  if (type === CommunityMembersSectionActionType.UpdateFilter) {
    const { filter = {} } = payload;
    const newFilter = { ...state.filter, ...filter };

    return {
      ...state,
      filter: newFilter,
      list: getFilteredList(getSortedList(state.initialList, state.sort), newFilter),
    };
  }

  if (type === CommunityMembersSectionActionType.OpenDetails) {
    const { clickedItem } = payload;

    return { ...state, clickedItem };
  }

  if (type === CommunityMembersSectionActionType.CloseDetails) {
    return { ...state, clickedItem: undefined };
  }

  return state;
};

export default reducer;
