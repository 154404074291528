import React, { useCallback, useContext, useMemo, useState } from 'react';
import Modal from 'components/shared/Modal';
import { Button, Grid } from '@mui/material';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import Stepper from 'components/shared/Stepper';
import { ModalProps } from 'store/types/ComponentProps';
import { EventSessionView } from 'store/types/events/Event';
import UserRelationshipPageView from 'components/profile/UserRelationshipPageView';
import EventSessionsTable from 'components/events/EventSessionsTable';
import { EventCartContext } from 'components/EventCartGuard';
import EventService from 'services/api/EventService';
import { useSnackbar } from 'notistack';
import { ParametersContext } from 'components/ParametersGuard';
import { getEventPriceLevel } from 'util/Event';

import styles from './AddEventGuestModal.module.scss';

interface AddEventGuestModalProps extends ModalProps {
  data: EventSessionView[];
  eventId: string;
  registrantId?: string;
  companyId?: string;
}

interface GuestEventStepConfigItem {
  value: number;
  label: string;
  icon: string;
}

const stepsConfig: GuestEventStepConfigItem[] = [
  { value: 1, label: 'Guest Information', icon: 'PeopleAlt' },
  { value: 2, label: 'Session Selection', icon: 'LibraryBooks' },
];

const AddEventGuestModal: React.FunctionComponent<AddEventGuestModalProps> = ({
  open,
  onClose,
  data,
  eventId,
  registrantId,
  companyId,
}) => {
  const [step, setStep] = useState<number>(1);
  const { enqueueSnackbar } = useSnackbar();
  const {
    events: { addGuestModalHeader },
  } = useContext(ParametersContext);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const { refetchCart } = useContext(EventCartContext);
  const sessions = useMemo(
    () =>
      data.map((session) => ({
        ...session,
        price: getEventPriceLevel(session.prices),
      })),
    [data]
  );
  const [selectedSessions, setSelectedSessions] = useState<EventSessionView[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string>('');
  const currentStepConfigIndex: number = useMemo(() => stepsConfig.findIndex(({ value }) => step === value), [step]);

  const handleSessionsChange = useCallback((newSelectedSessions: EventSessionView[] = []) => {
    setSelectedSessions(newSelectedSessions);
  }, []);

  const handleNextStep = useCallback(() => {
    const newStepIndex: number = currentStepConfigIndex + 1;
    const nextStep = stepsConfig[newStepIndex].value;

    setStep(nextStep);
  }, [currentStepConfigIndex]);

  const handlePrevStep = useCallback(() => {
    const newStepIndex: number = currentStepConfigIndex - 1;
    const prevStep = stepsConfig[newStepIndex].value;

    setStep(prevStep);
  }, [currentStepConfigIndex]);

  const handleClose = useCallback(() => {
    onClose();
    setStep(1);
  }, [onClose]);

  const handleSelectGuest = useCallback((userId: string) => {
    setSelectedUserId(userId);
  }, []);

  const handleSubmit = useCallback(() => {
    setSubmitLoading(true);
    const selectedSessionIds: string[] = selectedSessions.map((session) => session.id);

    EventService.addEventToCart(eventId, selectedSessionIds, registrantId, selectedUserId, companyId)
      .then(() => {
        setSubmitLoading(false);
        enqueueSnackbar('Event was added to cart', {
          variant: 'success',
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
        });
        refetchCart();
        handleClose();
      })
      .catch((error: string) => {
        setSubmitLoading(false);
        enqueueSnackbar(error, defaultSnackbarErrorProps);
      });
  }, [companyId, enqueueSnackbar, eventId, handleClose, refetchCart, registrantId, selectedSessions, selectedUserId]);

  return (
    <Modal
      title={'Add a Guest'}
      open={open}
      onClose={handleClose}
      loading={submitLoading}
      maxWidth={'lg'}
      actions={
        <>
          <Button color={'secondary'} variant={'outlined'} onClick={step === 1 ? handleClose : handlePrevStep}>
            {step === 1 ? 'Close' : 'Back'}
          </Button>
          <Button
            variant={'contained'}
            className={styles.button}
            disabled={!selectedUserId}
            onClick={step === 2 || !data.length ? handleSubmit : handleNextStep}
          >
            {step === 2 || !data.length ? 'Save' : 'Next'}
          </Button>
        </>
      }
    >
      <Grid {...defaultGridContainerProps}>
        <Grid {...defaultGridItemProps}>
          <p dangerouslySetInnerHTML={{ __html: addGuestModalHeader }} />
        </Grid>
        {!!data.length && (
          <Grid {...defaultGridItemProps} className={styles.stepper}>
            <Stepper activeStep={step} config={stepsConfig} />
          </Grid>
        )}
      </Grid>
      {step === 1 && (
        <UserRelationshipPageView
          onSelectUserId={handleSelectGuest}
          showRadioButtons={true}
          customerId={registrantId}
          eventId={eventId}
        />
      )}
      {step === 2 && <EventSessionsTable data={sessions} onChange={handleSessionsChange} isGuestSelection={true} />}
    </Modal>
  );
};

export default AddEventGuestModal;
