import { SortConfig } from 'store/types/Table';
import { sorter } from 'util/Table';
import DonationPayment from 'store/types/DonationPayment';

export enum DonationPaymentsTableActionType {
  SetInitialList = 'SetInitialList',
  UpdateSort = 'UpdateSort',
  OpenAction = 'OpenAction',
  CloseAction = 'CloseAction',
}

export interface DonationPaymentsTableState {
  initialList: DonationPayment[];
  list: DonationPayment[];
  sort: SortConfig<DonationPayment>;
  clickedItem?: DonationPayment;
}

export interface DonationPaymentsTableAction {
  type: DonationPaymentsTableActionType;
  payload: Partial<DonationPaymentsTableState>;
}

const getSortedList = (list: DonationPayment[] = [], sort: SortConfig<DonationPayment>): DonationPayment[] =>
  list.length ? [...list].sort(sorter<DonationPayment>(sort)) : [...list];

export const initialState: DonationPaymentsTableState = {
  initialList: [],
  list: [],
  sort: { direction: 'asc', column: 'name' },
};

const reducer = (
  state: DonationPaymentsTableState,
  { type, payload }: DonationPaymentsTableAction
): DonationPaymentsTableState => {
  if (type === DonationPaymentsTableActionType.SetInitialList) {
    const { initialList = [] } = payload;
    const list = getSortedList(initialList, state.sort);

    return { ...state, initialList, list };
  }

  if (type === DonationPaymentsTableActionType.UpdateSort) {
    const { sort = {} } = payload;

    return {
      ...state,
      sort,
      list: getSortedList(state.initialList, sort),
    };
  }

  if (type === DonationPaymentsTableActionType.OpenAction) {
    const { clickedItem } = payload;

    return { ...state, clickedItem };
  }

  if (type === DonationPaymentsTableActionType.CloseAction) {
    return { ...state, clickedItem: undefined };
  }

  return state;
};

export default reducer;
