enum QuestionType {
  Radio = 'Radio',
  File = 'File',
  Text = 'Text',
  Dropdown = 'Dropdown',
  Checkbox = 'Checkbox',
  SubList = 'SubList',
  Currency = 'Currency',
  Date = 'Date',
  MultilineText = 'MultilineText',
  DropdownCustomList = 'DropdownCustomList',
}
export default QuestionType;
