import React from 'react';
import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { getValidationProps, FormProps, getMaxLengthValidationRule } from 'util/Form';

const TITLE_MAX_LENGTH = 32;

interface TitleFormItemProps extends FormProps {
  required?: boolean;
  fieldName?: string;
}

const TitleFormItem: React.FunctionComponent<TitleFormItemProps> = ({
  required = false,
  disabled,
  fieldName = 'title',
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      render={({ field }) => (
        <TextField
          {...field}
          {...getValidationProps(fieldName, errors)}
          label={'Title'}
          disabled={disabled}
          required={required}
        />
      )}
      name={fieldName}
      control={control}
      rules={{
        maxLength: getMaxLengthValidationRule(TITLE_MAX_LENGTH),
      }}
    />
  );
};
export default TitleFormItem;
