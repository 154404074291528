import { TextField } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormProps, getMaxLengthValidationRule, getValidationError, getValidationHelperText } from 'util/Form';

import styles from './JoinReasonFormItem.module.scss';

const fieldName = 'reason';

const JoinReasonFormItem: React.FunctionComponent<FormProps> = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const validationError = getValidationError(fieldName, errors);
  const validationHelperText = getValidationHelperText(fieldName, errors);
  const inputValue: string = useWatch({ control, name: fieldName });
  const countHelperText = `${200 - inputValue.length} characters limit`;

  return (
    <Controller
      render={({ field }) => (
        <TextField
          {...field}
          error={validationError}
          helperText={validationHelperText || countHelperText}
          InputProps={{
            multiline: true,
            rows: 4,
          }}
          FormHelperTextProps={{
            classes: {
              root: classNames({ [styles.helperText]: !validationError }),
            },
          }}
        />
      )}
      name={'reason'}
      control={control}
      rules={{
        maxLength: getMaxLengthValidationRule(200),
      }}
    />
  );
};
export default JoinReasonFormItem;
