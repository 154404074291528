import { Avatar, Button, Grid } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { ReactComponent as BagIcon } from 'assets/icons/bag.svg';
import { ReactComponent as ChartIcon } from 'assets/icons/chart.svg';
import { ReactComponent as GlobeIcon } from 'assets/icons/globe.svg';
import { ReactComponent as LikeIcon } from 'assets/icons/like.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/profile.svg';
import { ReactComponent as SaleIcon } from 'assets/icons/sale.svg';
import reviewUserAvatar from 'assets/images/default/review-user-avatar.jpg';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import ComponentProps from 'store/types/ComponentProps';

import pageStyles from '../LoginPage.module.scss';
import styles from './MpPromotionCard.module.scss';

interface MemberplexPromotionCardProps extends ComponentProps {
  onSignupClick: () => void;
}

const MpPromotionCard: React.FunctionComponent<MemberplexPromotionCardProps> = ({ onSignupClick, className = '' }) => {
  return (
    <div className={classNames(pageStyles.card, className)}>
      <Grid {...defaultGridContainerProps}>
        <Grid {...defaultGridItemProps} md={8} className={styles.promotion}>
          <Grid {...defaultGridContainerProps}>
            <Grid {...defaultGridItemProps}>
              <h1 className={pageStyles.cardTitle}>{'Don’t have an account?'}</h1>
              <p className={pageStyles.cardDescription}>
                {'Create an account to get started today and access benefits like:'}
              </p>
              <ul className={styles.benefits}>
                <li>
                  <ChartIcon />
                  <span>{'Keep up to date with new medical standards and regulations'}</span>
                </li>
                <li>
                  <GlobeIcon />
                  {'Keep current on issues impacting the medical profession'}
                </li>
                <li>
                  <ProfileIcon />
                  {'Expand your professional network'}
                </li>
                <li>
                  <LikeIcon />
                  {'Find new clients and business'}
                </li>
                <li>
                  <BagIcon />
                  {'Gain more experience in your area of expertise'}
                </li>
                <li>
                  <SaleIcon />
                  {'Get the education you need to grow FREE'}
                </li>
              </ul>
            </Grid>
            <Grid {...defaultGridItemProps} sm={'auto'}>
              <Button
                variant={'contained'}
                color={'secondary'}
                fullWidth={true}
                className={pageStyles.button}
                onClick={onSignupClick}
              >
                {'Join Now'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid {...defaultGridItemProps} md={4} className={styles.review}>
          <Avatar src={reviewUserAvatar} className={styles.reviewAvatar} />
          <h5 className={styles.reviewTitle}>{'Best decision ever!'}</h5>
          <p>
            {
              'I joined this organization back in 2017. The credentials significantly grew my customer base, and all continuing education credits are included!'
            }
          </p>
          <span className={styles.reviewAuthorName}>{'Sopihe Simmons'}</span>
          <span className={styles.reviewAuthorPosition}>{'Doctor, Northwestern Hospital'}</span>
        </Grid>
      </Grid>
    </div>
  );
};
export default MpPromotionCard;
