import { Button, ButtonProps } from '@mui/material';
import classNames from 'classnames';
import React from 'react';

import styles from './ApplicationsTableActionButton.module.scss';

interface ApplicationsTableActionButtonProps extends Pick<ButtonProps, 'className' | 'href' | 'disabled'> {
  actionLabel: string;
  nameLabel?: string;
  onClick?: () => void;
}

const ApplicationsTableActionButton: React.FunctionComponent<ApplicationsTableActionButtonProps> = ({
  actionLabel,
  nameLabel = '',
  href,
  className = '',
  disabled,
  onClick,
}) => {
  const actionButtonProps: ButtonProps = {
    variant: 'outlined',
    size: 'small',
    color: 'primary',
    className: classNames(styles.actionButton, className),
  };

  return (
    <Button {...actionButtonProps} {...{ href, disabled }} onClick={onClick}>
      {`${actionLabel} `}
      {nameLabel && <span className={styles.nameLabel}>{nameLabel}</span>}
    </Button>
  );
};
export default ApplicationsTableActionButton;
