import React, { useMemo } from 'react';
import { Switch as MuiSwitch, SwitchProps } from '@mui/material';

import styles from './Switch.module.scss';

const switchSmallSizeClasses = {
  root: styles.rootSmall,
  switchBase: styles.switchBaseSmall,
  checked: styles.checkedSmall,
  disabled: styles.disabledSmall,
  thumb: styles.thumbSmall,
  track: styles.trackSmall,
};

const switchMediumSizeClasses = {
  root: styles.rootMedium,
  switchBase: styles.switchBaseMedium,
  checked: styles.checkedMedium,
  disabled: styles.disabledMedium,
  thumb: styles.thumbMedium,
  track: styles.trackMedium,
};

const Switch: React.FunctionComponent<SwitchProps> = (props) => {
  const { size } = props;
  const classes = useMemo(() => (size === 'small' ? switchSmallSizeClasses : switchMediumSizeClasses), [size]);
  return <MuiSwitch classes={classes} data-testid={'switch'} {...props} />;
};
export default Switch;
