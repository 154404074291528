import React from 'react';
import { OrderPayment } from 'store/types/Payment';
import { getFormattedDate } from 'util/Format';
import SectionHeader from 'components/shared/SectionHeader';
import PaymentProductsTable from 'components/payments/PaymentProductsTable';

import styles from './ExpressPaymentOrderSection.module.scss';

interface ExpressPaymentOrderSectionProps {
  order: OrderPayment;
}

const ExpressPaymentOrderSection: React.FunctionComponent<ExpressPaymentOrderSectionProps> = ({ order }) => {
  const {
    orderItems,
    totalAmount,
    invoiceName,
    orderDate,
    orderNumber,
    amountRemaining,
    amountPaid,
    totalTax,
    totalShipping,
    type,
  } = order;

  return (
    <>
      {invoiceName && (
        <div className={styles.row}>
          <span className={styles.label}>Name:</span>
          {invoiceName}
        </div>
      )}
      {orderDate && (
        <div className={styles.row}>
          <span className={styles.label}>{'Date:'}</span>
          <span className={styles.value}>{getFormattedDate(orderDate)}</span>
        </div>
      )}
      {orderNumber && (
        <div className={styles.row}>
          <span className={styles.label}>{'Number:'}</span>
          <span className={styles.value}>{orderNumber}</span>
        </div>
      )}
      <SectionHeader className={styles.tableTitle}>Summary</SectionHeader>
      <PaymentProductsTable
        orderItems={orderItems}
        totalAmount={totalAmount}
        amountRemaining={amountRemaining}
        amountPaid={amountPaid}
        totalTax={totalTax}
        totalShipping={totalShipping}
        type={type}
      />
    </>
  );
};
export default ExpressPaymentOrderSection;
