import React, { useMemo } from 'react';
import { TextField } from '@mui/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormProps, getRequiredValidationRule, getValidationProps } from 'util/Form';
import { ApplicationQuestionView } from 'store/types/ApplicationQuestion';
import classNames from 'classnames';

import styles from './MultilineTextQuestionFormItem.module.scss';

const VALUE_MAX_LENGTH = 300;

interface MultilineTextQuestionFormItemProps extends FormProps {
  question: ApplicationQuestionView;
}

const MultilineTextQuestionFormItem: React.FunctionComponent<MultilineTextQuestionFormItemProps> = ({
  question,
  disabled,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { fieldName, required } = useMemo(() => question, [question]);
  const { error, helperText } = getValidationProps(fieldName, errors);
  const inputValue: string = useWatch({ control, name: fieldName }) || '';
  const countHelperText = useMemo(() => `${VALUE_MAX_LENGTH - inputValue.length} characters limit`, [inputValue]);

  return (
    <Controller
      render={({ field }) => (
        <TextField
          {...field}
          {...getValidationProps(fieldName, errors)}
          multiline={true}
          minRows={3}
          required={required}
          disabled={disabled}
          inputProps={{ maxLength: VALUE_MAX_LENGTH }}
          helperText={helperText || countHelperText}
          FormHelperTextProps={{
            classes: {
              root: classNames({ [styles.helperText]: !error }),
            },
          }}
        />
      )}
      name={fieldName}
      control={control}
      rules={{
        required: getRequiredValidationRule('your answer', false, required),
      }}
    />
  );
};
export default MultilineTextQuestionFormItem;
