import { httpRequestWrapper } from 'util/Request';
import { HttpService } from 'services/HttpService';
import { PROFILE_API_URL, tenantConfig } from 'config';
import { AddUserRelationshipData, LinkUserRelationshipData, UserRelationship } from 'store/types/UserRelationship';
import AuthService from 'services/AuthService';
import SelectOption from 'store/types/SelectOption';

type UserRelationshipResponseData = UserRelationship[];
type UserRelationshipTypesResponseDate = SelectOption[];

class UserRelationshipService {
  public static getUserRelationship(profileId?: string, eventId?: string): Promise<UserRelationshipResponseData> {
    return new Promise<UserRelationshipResponseData>(
      (resolve: (data: UserRelationshipResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<UserRelationshipResponseData>(
          HttpService.get(`${PROFILE_API_URL}/profiles/relationships`, {
            queryParams: { profileId, eventId },
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getUserRelationshipTypes(): Promise<UserRelationshipTypesResponseDate> {
    return new Promise<UserRelationshipTypesResponseDate>(
      (resolve: (data: UserRelationshipTypesResponseDate) => void, reject: (error: string) => void) => {
        httpRequestWrapper<UserRelationshipTypesResponseDate>(
          HttpService.get(`${PROFILE_API_URL}/profiles/relationships/types`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static deleteRelationshipUser(userId: string, customerId?: string): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.delete(`${PROFILE_API_URL}/profiles/relationships/${userId}`, {
          queryParams: { customerId },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static addRelationshipUser(data: AddUserRelationshipData): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.post(`${PROFILE_API_URL}/profiles/relationships/add`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static linkRelationshipUser(data: LinkUserRelationshipData): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.post(`${PROFILE_API_URL}/profiles/relationships/link`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }
}

export default UserRelationshipService;
