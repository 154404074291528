import classNames from 'classnames';
import { DropzoneArea } from 'mui-file-dropzone';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, FormControl, Link, TextField } from '@mui/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormProps, getValidationProps } from 'util/Form';
import { ApplicationQuestionView } from 'store/types/ApplicationQuestion';
import Table from 'components/shared/Table';
import { TableColumn } from 'store/types/Table';
import FileInfo from 'store/types/FileInfo';
import { getFormattedFileSize } from 'util/Format';
import commonStyles from 'styles/common.module.scss';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import ApplicationsService from 'services/api/ApplicationsService';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import { defaultSnackbarErrorProps } from 'util/Layout';

import styles from './FileQuestionFormItem.module.scss';

interface FileQuestionFormItemProps extends FormProps {
  question: ApplicationQuestionView;
  applicationId?: string;
}

const MAX_FILE_SIZE = 5000000;
const MAX_FILES_COUNT = 5;

const FileQuestionFormItem: React.FunctionComponent<FileQuestionFormItemProps> = ({
  question,
  disabled,
  applicationId = '',
}) => {
  const {
    control,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useFormContext();
  const [list, setList] = useState<FileInfo[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>();
  const { applicationId: id = applicationId } = useParams<{ applicationId: string }>();
  const { fieldName, fileRequired, required, files = [] } = useMemo(() => question, [question]);
  const answerFiles = useWatch({ control, name: fieldName });
  const { error } = getValidationProps(fieldName, errors);

  useEffect(() => {
    if (files?.length) {
      setValue(fieldName, files);
      setList(files);
    }
  }, [files, fieldName, setValue, trigger]);

  const handleChange = useCallback(
    (onChange) => (loadedFiles: File[]) => {
      onChange(loadedFiles);
    },
    []
  );

  const handleDeleteButtonClick = useCallback(
    (record: FileInfo) => () => {
      const value = getValues()[fieldName];
      const newValue = value.filter((file: FileInfo) => file.id !== record.id);

      setValue(fieldName, newValue);
      setList(newValue);
      trigger(fieldName);
    },
    [fieldName, getValues, setValue, trigger]
  );

  const handleFileLinkClick = useCallback(
    (record: FileInfo) => () => {
      setLoading(true);
      ApplicationsService.getApplicationFile(id, record)
        .then(() => {
          setLoading(false);
        })
        .catch((error: string) => {
          enqueueSnackbar(error, defaultSnackbarErrorProps);
          setLoading(false);
        });
    },
    [id, enqueueSnackbar]
  );

  const columns: Array<TableColumn<FileInfo>> = useMemo(() => {
    const result: Array<TableColumn<FileInfo>> = [
      {
        dataIndex: 'name',
        label: 'File Name',
        render: (name: string, record: FileInfo) => (
          <Link onClick={handleFileLinkClick(record)} className={commonStyles.link}>
            {name}
          </Link>
        ),
      },
      {
        dataIndex: 'size',
        label: 'Size',
        render: (size: number) => getFormattedFileSize(size),
      },
    ];

    !disabled &&
      result.push({
        label: 'Action',
        render: (_, record) => (
          <Button
            size={'small'}
            color={'primary'}
            variant={'outlined'}
            className={commonStyles.dangerButtonOutlined}
            onClick={handleDeleteButtonClick(record)}
          >
            <DeleteOutlined className={styles.icon} />
          </Button>
        ),
      });

    return result;
  }, [disabled, handleDeleteButtonClick, handleFileLinkClick]);

  const validateFilesField = useCallback(
    (value: File[]) => ((required || fileRequired) && !answerFiles.length ? !!value?.length : true),
    [answerFiles.length, fileRequired, required]
  );

  return (
    <>
      <FormControl required={fileRequired} error={error} className={styles.formControl}>
        <Controller
          render={({ field: { onChange, value } }) => (
            <DropzoneArea
              maxFileSize={MAX_FILE_SIZE}
              filesLimit={MAX_FILES_COUNT}
              fileObjects={value}
              onChange={handleChange(onChange)}
              onDelete={onChange}
              showAlerts={['error']}
              clearOnUnmount={true}
              showPreviews={true}
              showPreviewsInDropzone={false}
              useChipsForPreview={true}
              dropzoneText={'Upload Files'}
              previewText={''}
              classes={{
                root: classNames(styles.dropzone, { [styles.disabled]: disabled }),
                textContainer: styles.dropzoneTextContainer,
                text: styles.dropzoneText,
                icon: styles.dropzoneIcon,
              }}
              previewGridClasses={{
                container: styles.previewContainer,
                item: styles.previewImageContainer,
              }}
              dropzoneProps={{ disabled: disabled, noDrag: true }}
              previewChipProps={{ className: styles.chip, disabled }}
              alertSnackbarProps={{
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
              }}
            />
          )}
          name={`files.${fieldName}`}
          control={control}
          rules={{ validate: validateFilesField }}
        />
      </FormControl>
      <FormControl required={fileRequired} error={error} className={styles.formControl}>
        <Controller
          render={({ field }) => <TextField {...field} required={fileRequired} className={commonStyles.hidden} />}
          name={fieldName}
          control={control}
        />
      </FormControl>
      <Table columns={columns} list={list} className={styles.sectionTable} loading={loading} />
    </>
  );
};
export default FileQuestionFormItem;
