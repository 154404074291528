import React from 'react';
import { getPrice } from 'util/Payment';
import { BasePayment, PaymentProduct } from 'store/types/Payment';
import Table from 'components/shared/Table';
import { getFormattedDate } from 'util/Format';
import { TableColumn } from 'store/types/Table';
import { useLocation } from 'react-router';
import routes from 'store/configs/Routes';
import OrderType from 'store/enums/OrderType';

import styles from './PaymentProductsTable.module.scss';
import PaymentStatus from 'store/enums/PaymentStatus';

interface PaymentProductTableProps
  extends Pick<
    BasePayment,
    | 'orderItems'
    | 'totalAmount'
    | 'totalTax'
    | 'totalShipping'
    | 'amountPaid'
    | 'amountRemaining'
    | 'amountToPay'
    | 'ownerName'
    | 'type'
    | 'status'
  > {
  isOrderPaymentConfirmation?: boolean;
  companyPayment?: boolean;
}

const getOrderPaymentConfirmationColumns = (companyPayment?: boolean): Array<TableColumn<PaymentProduct>> => [
  {
    dataIndex: 'invoiceName',
    label: 'Number',
  },
  {
    dataIndex: 'ownerName',
    label: companyPayment ? 'Company Name' : 'Customer Name',
  },
  {
    dataIndex: 'orderDate',
    label: 'Date',
    render: (value: string) => getFormattedDate(value),
  },
  {
    dataIndex: 'paymentNumber',
    label: 'Payment #',
  },
  {
    dataIndex: 'amount',
    label: 'Amount',
    render: (value: number) => getPrice(value),
  },
  {
    dataIndex: 'tax',
    label: 'Tax',
    render: (value: number) => getPrice(value),
  },
  {
    dataIndex: 'subtotal',
    align: 'right',
    label: 'Subtotal',
    render: (value: number) => getPrice(value),
  },
];

const orderDetailsColumns: Array<TableColumn<PaymentProduct>> = [
  {
    dataIndex: 'description',
    label: 'Product Description',
  },
  {
    dataIndex: 'memo',
    label: 'Memo',
  },
  {
    dataIndex: 'quantity',
    label: 'Quantity',
  },
  {
    dataIndex: 'amountEach',
    label: 'Amount Each',
    render: (value: number) => getPrice(value),
  },
  {
    dataIndex: 'tax',
    label: 'Tax',
    render: (value: number) => getPrice(value),
  },
  {
    dataIndex: 'subtotal',
    align: 'right',
    label: 'Subtotal',
    render: (value: number) => getPrice(value),
  },
];

const PaymentProductsTable: React.FunctionComponent<PaymentProductTableProps> = ({
  orderItems = [],
  totalAmount,
  totalTax,
  totalShipping,
  amountRemaining,
  amountPaid = 0,
  amountToPay = 0,
  isOrderPaymentConfirmation = false,
  type = OrderType.CustInvc,
  status,
  companyPayment,
}) => {
  const { pathname } = useLocation();

  return (
    <>
      <Table
        columns={isOrderPaymentConfirmation ? getOrderPaymentConfirmationColumns(companyPayment) : orderDetailsColumns}
        list={orderItems}
        className={styles.table}
      />
      <div className={styles.total}>
        <span>{isOrderPaymentConfirmation || type === OrderType.SalesOrd ? 'Order Total:' : 'Invoice Total:'}</span>
        <span>{getPrice(totalAmount)}</span>
      </div>
      <div className={styles.total}>
        <span>Tax Total:</span>
        <span>{getPrice(totalTax)}</span>
      </div>
      <div className={styles.total}>
        <span>Shipping Total:</span>
        <span>{getPrice(totalShipping)}</span>
      </div>
      {type === OrderType.CustInvc && (
        <div className={styles.total}>
          <span>Previous Payments / Credits:</span>
          <span>{getPrice(amountPaid)}</span>
        </div>
      )}
      {!pathname.includes(routes.expressSaleOrderPayment) && !pathname.includes(routes.expressInvoicePayment) && (
        <div className={styles.total}>
          <span>Amount Paid:</span>
          <span>{getPrice(amountToPay)}</span>
        </div>
      )}
      {status !== PaymentStatus.FullyBilled && (
        <div className={styles.total}>
          <span>Balance Due:</span>
          <span>{getPrice(amountRemaining)}</span>
        </div>
      )}
    </>
  );
};
export default PaymentProductsTable;
