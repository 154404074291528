import { Button, Grid, Tab, Tabs } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Spinner from 'components/shared/Spinner';
import { UserContext } from 'components/UserGuard';
import { OrderPayment } from 'store/types/Payment';
import { defaultGridContainerProps, defaultGridItemProps, getPageTitle } from 'util/Layout';
import useRequest from 'hooks/useRequest';
import { getActiveTabRoute, getActiveTabValue } from 'util/Route';
import { ConfigContext } from 'components/ConfigGuard';
import MakePaymentPageView from 'components/payments/MakePaymentPageView';
import OrderHistoryPageView from 'components/payments/OrderHistoryPageView';
import CompanyService from 'services/api/CompanyService';
import routes, { companyPaymentsRoutes } from 'store/configs/Routes';
import { useHistory, useLocation } from 'react-router';
import CompanySelect from 'components/company/CompanySelect';
import SiteModule from 'store/enums/SiteModule';
import {
  ALL_COMPANIES_DROPDOWN_KEY,
  getCompanySelectOptions,
  CompanySelectOption,
  ALL_PROGRAMS_OPTION,
} from 'util/Company';
import Tile from 'components/shared/Tile';
import { getPrice, preselectedOrdersStatePropName } from 'util/Payment';
import { CompanyPaymentDetails } from 'store/types/Company';
import DownloadStatementModal from 'components/payments/DownloadStatementModal';
import ApplyCreditsToggle from 'components/payments/ApplyCreditsToggle';
import { UserPrograms } from 'store/types/User';
import { ParametersContext } from 'components/ParametersGuard';

import commonStyles from 'styles/common.module.scss';
import styles from './CompanyPaymentPage.module.scss';

const CompanyPaymentsPage: React.FunctionComponent = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { state } = useLocation<{ [preselectedOrdersStatePropName]?: string[] }>();
  const { functions = [], userLoading, userPermissions } = useContext(UserContext);
  const {
    sidebar,
    isNccerTheme,
    isSmacnaTheme,
    modulesSettings: {
      companyPayments: { enablePaySalesOrders },
    },
  } = useContext(ConfigContext);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>('');
  const [selectedProgramId, setSelectedProgramId] = useState<string>(ALL_PROGRAMS_OPTION);
  const [downloadStatementModalOpen, setDownloadStatementModalOpen] = useState<boolean>(false);
  const [showAllCompaniesMenuItem, setShowAllCompaniesMenuItem] = useState<boolean>(false);
  const permissions: string[] = useMemo(
    () => userPermissions.modules.find(({ name }) => name === SiteModule.Company)?.permissions || [],
    [userPermissions]
  );
  const {
    companyManagement: { companyDropdownName },
  } = useContext(ParametersContext);
  const options: CompanySelectOption[] = useMemo(
    () => getCompanySelectOptions(functions, permissions, showAllCompaniesMenuItem),
    [functions, permissions, showAllCompaniesMenuItem]
  );

  const ordersRequest = useMemo(
    () =>
      selectedCompanyId === ALL_COMPANIES_DROPDOWN_KEY
        ? () => CompanyService.getAllCompanyOrders(selectedProgramId)
        : selectedCompanyId
        ? () => CompanyService.getCompanyOrders(selectedCompanyId, selectedProgramId)
        : undefined,
    [selectedCompanyId, selectedProgramId]
  );
  const companyDetailsRequest = useMemo(
    () =>
      selectedCompanyId && selectedCompanyId !== ALL_COMPANIES_DROPDOWN_KEY
        ? () => CompanyService.getCompanyPaymentDetails(selectedCompanyId)
        : undefined,
    [selectedCompanyId]
  );
  const companyDetailsRequestData = useRequest<CompanyPaymentDetails>(companyDetailsRequest);
  const { data, loading } = companyDetailsRequestData;
  const userPrograms: UserPrograms[] = useMemo(() => data?.programs || [], [data?.programs]);
  const balanceDue: number | undefined = useMemo(() => data?.balance, [data]);
  const ordersRequestData = useRequest<OrderPayment[]>(ordersRequest);
  const activeTab: string = useMemo(() => getActiveTabValue(pathname, companyPaymentsRoutes), [pathname]);
  const { firstLoading } = ordersRequestData;

  useEffect(() => {
    setSelectedProgramId(ALL_PROGRAMS_OPTION);
    if (activeTab === '2' || activeTab === '3') {
      setShowAllCompaniesMenuItem(true);
    } else {
      setShowAllCompaniesMenuItem(false);
    }
  }, [activeTab, selectedCompanyId]);

  const handleCompanyChange = useCallback(
    (newCompanyId: string) => {
      setSelectedCompanyId(newCompanyId);
      if (state) {
        history.replace({ pathname, state: null });
      }
    },
    [history, pathname, state]
  );

  const handleProgramSelectChange = useCallback((value: string) => {
    setSelectedProgramId(value);
  }, []);

  const handleTabChange = useCallback(
    (_: any, value = '1') => {
      history.replace(getActiveTabRoute(value, companyPaymentsRoutes));
    },
    [history]
  );

  const handleDownloadStatementModalOpen = useCallback(() => {
    setDownloadStatementModalOpen(true);
  }, []);

  const handleDownloadStatementModalClose = useCallback(() => {
    setDownloadStatementModalOpen(false);
  }, []);

  const getTabPanelContent = useCallback(
    (children: React.ReactNode, displayApplyCreditToggle?: boolean): React.ReactNode => (
      <Grid {...defaultGridContainerProps} justifyContent={'flex-end'}>
        {isNccerTheme && selectedCompanyId !== ALL_COMPANIES_DROPDOWN_KEY && (
          <Grid {...defaultGridItemProps} xs={true}>
            <div className={styles.tilesWrapper}>
              <Tile
                value={getPrice(balanceDue)}
                label={'Account Balance'}
                classes={{ value: styles.value, label: styles.label }}
                loading={loading}
                theme={'dark-red'}
                className={styles.tile}
              />
              <span className={styles.helperText}>
                {'A negative account balance indicates that there is a credit on the account.'}
              </span>
            </div>
          </Grid>
        )}
        {selectedCompanyId !== ALL_COMPANIES_DROPDOWN_KEY && (
          <>
            {displayApplyCreditToggle && (
              <Grid {...defaultGridItemProps} xs={'auto'}>
                <ApplyCreditsToggle id={selectedCompanyId} {...companyDetailsRequestData} />
              </Grid>
            )}
            <Grid {...defaultGridItemProps} xs={'auto'}>
              <Button variant={'contained'} color={'secondary'} onClick={handleDownloadStatementModalOpen}>
                {'Generate Statement'}
              </Button>
            </Grid>
          </>
        )}
        <Grid {...defaultGridItemProps}>{children}</Grid>
      </Grid>
    ),
    [isNccerTheme, selectedCompanyId, balanceDue, loading, handleDownloadStatementModalOpen, companyDetailsRequestData]
  );

  return (
    <>
      <DownloadStatementModal
        {...companyDetailsRequestData}
        open={downloadStatementModalOpen}
        id={selectedCompanyId}
        onClose={handleDownloadStatementModalClose}
      />
      <h1 className={commonStyles.pageTitle}>
        {getPageTitle(sidebar, SiteModule.Payments, SiteModule.CompanyPayments, !isSmacnaTheme) || 'Manage Company'}
      </h1>
      <Spinner loading={userLoading} transparent={false}>
        <TabContext value={activeTab}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            indicatorColor={'primary'}
            textColor={'primary'}
            variant={'scrollable'}
          >
            <Tab value={'1'} label={'Company Invoices'} className={commonStyles.tab} />
            <Tab value={'2'} label={'Pay Company Invoices'} className={commonStyles.tab} />
            {enablePaySalesOrders && (
              <Tab value={'3'} label={'Pay Company Sales Orders'} className={commonStyles.tab} />
            )}
          </Tabs>
          <div className={styles.companySelect}>
            <CompanySelect
              options={options}
              onChange={handleCompanyChange}
              size={'small'}
              label={companyDropdownName || 'Select Company'}
              showAllCompaniesMenuItem={(activeTab === '2' || activeTab === '3') && showAllCompaniesMenuItem}
            />
          </div>
          <TabPanel className={commonStyles.tabPanel} value={'2'}>
            {getTabPanelContent(
              <Spinner loading={firstLoading} transparent={false}>
                <MakePaymentPageView
                  {...ordersRequestData}
                  companyId={selectedCompanyId}
                  onProgramChange={handleProgramSelectChange}
                  programId={selectedProgramId}
                />
              </Spinner>
            )}
          </TabPanel>
          <TabPanel className={commonStyles.tabPanel} value={'3'}>
            {getTabPanelContent(
              <Spinner loading={firstLoading} transparent={false}>
                <MakePaymentPageView
                  {...ordersRequestData}
                  companyId={selectedCompanyId}
                  onProgramChange={handleProgramSelectChange}
                  programId={selectedProgramId}
                  salesOrders={true}
                />
              </Spinner>
            )}
          </TabPanel>
          <TabPanel className={commonStyles.tabPanel} value={'1'}>
            {getTabPanelContent(
              !!selectedCompanyId && (
                <Spinner loading={firstLoading} transparent={false}>
                  <OrderHistoryPageView
                    {...ordersRequestData}
                    companyId={selectedCompanyId}
                    makePaymentRoute={routes.makeCompanyPayment}
                    openOrdersEnabled={true}
                    onProgramChange={handleProgramSelectChange}
                    programId={selectedProgramId}
                    programsList={userPrograms}
                  />
                </Spinner>
              ),
              true
            )}
          </TabPanel>
        </TabContext>
      </Spinner>
    </>
  );
};
export default CompanyPaymentsPage;
