import { Button, Grid } from '@mui/material';
import EventSessionsTable from 'components/events/EventSessionsTable';
import Modal from 'components/shared/Modal';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useState } from 'react';
import EventService from 'services/api/EventService';
import ManageEventSessionsMode from 'store/enums/ManageEventSessionsMode';
import { ModalProps } from 'store/types/ComponentProps';
import { EventSessionView } from 'store/types/events/Event';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import { EventCartContext } from 'components/EventCartGuard';
import { ParametersContext } from 'components/ParametersGuard';

interface ManageEventSessionsModalProps extends ModalProps {
  id: string;
  data: EventSessionView[];
  registrantId?: string;
  companyId?: string;
  guestId?: string;
  mode: ManageEventSessionsMode;
}

const ManageEventSessionsModal: React.FunctionComponent<ManageEventSessionsModalProps> = ({
  open,
  onClose,
  id,
  mode,
  data,
  registrantId,
  companyId,
  guestId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    events: { sessionSelectionModalHeader },
  } = useContext(ParametersContext);
  const { refetchCart } = useContext(EventCartContext);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [selectedSessions, setSelectedSessions] = useState<EventSessionView[]>([]);

  const handleSessionsChange = useCallback((newSelectedSessions: EventSessionView[] = []) => {
    setSelectedSessions(newSelectedSessions);
  }, []);

  const addToCart = useCallback(
    (sessionsSelected = false) => {
      const selectedSessionIds = sessionsSelected ? selectedSessions.map((session) => session.id) : undefined;

      setSubmitLoading(true);
      EventService.addEventToCart(id, selectedSessionIds, registrantId, undefined, companyId)
        .then(() => {
          setSubmitLoading(false);
          enqueueSnackbar('Event was added to cart', {
            variant: 'success',
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          });
          refetchCart();
          onClose();
        })
        .catch((errorMessage: string) => {
          setSubmitLoading(false);
          enqueueSnackbar(errorMessage, { variant: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } });
        });
    },
    [selectedSessions, id, registrantId, companyId, enqueueSnackbar, refetchCart, onClose]
  );

  const handleAddLaterClick = useCallback(() => {
    addToCart();
  }, [addToCart]);

  const handleAddToCartClick = useCallback(() => {
    addToCart(true);
  }, [addToCart]);

  const handleUpdateInCartClick = useCallback(() => {
    const selectedSessionIds = selectedSessions.map((session) => session.id);

    setSubmitLoading(true);
    EventService.updateCartEventSessions(id, selectedSessionIds, registrantId, guestId, companyId)
      .then(() => {
        setSubmitLoading(false);
        enqueueSnackbar('Event sessions successfully updated', { variant: 'success' });
        refetchCart();
        onClose();
      })
      .catch((errorMessage: string) => {
        setSubmitLoading(false);
        enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
      });
  }, [selectedSessions, id, registrantId, guestId, companyId, enqueueSnackbar, refetchCart, onClose]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      maxWidth={'lg'}
      title={'Session Selection'}
      loading={submitLoading}
      keepMounted={mode === ManageEventSessionsMode.AddToCart}
      actions={
        <Grid {...defaultGridContainerProps} justifyContent={'flex-end'}>
          {mode === ManageEventSessionsMode.AddToCart && (
            <Grid {...defaultGridItemProps} sm={'auto'}>
              <Button color={'secondary'} variant={'outlined'} onClick={handleAddLaterClick} disabled={submitLoading}>
                {'Main Event Only'}
              </Button>
            </Grid>
          )}
          {mode === ManageEventSessionsMode.UpdateInCart ? (
            <Grid {...defaultGridItemProps} sm={'auto'}>
              <Button
                color={'secondary'}
                variant={'contained'}
                type={'submit'}
                onClick={handleUpdateInCartClick}
                disabled={submitLoading}
              >
                {'Update Selection'}
              </Button>
            </Grid>
          ) : (
            <Grid {...defaultGridItemProps} sm={'auto'}>
              <Button
                color={'secondary'}
                variant={'contained'}
                type={'submit'}
                onClick={handleAddToCartClick}
                disabled={submitLoading || !selectedSessions.length}
              >
                {'Complete Selection'}
              </Button>
            </Grid>
          )}
        </Grid>
      }
    >
      <Grid {...defaultGridContainerProps}>
        <Grid {...defaultGridItemProps}>
          <p dangerouslySetInnerHTML={{ __html: sessionSelectionModalHeader }} />
        </Grid>
        <Grid {...defaultGridItemProps}>
          <EventSessionsTable data={data} onChange={handleSessionsChange} isGuestSelection={!!registrantId} />
        </Grid>
      </Grid>
    </Modal>
  );
};
export default ManageEventSessionsModal;
