import { Button, Grid, Link } from '@mui/material';
import { EventCartContext } from 'components/EventCartGuard';
import ManageEventSessionsModal from 'components/events/ManageEventSessionsModal';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import ManageEventSessionsMode from 'store/enums/ManageEventSessionsMode';
import { EventSessionView, MainEventView } from 'store/types/events/Event';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import { getPrice } from 'util/Payment';
import Card from 'components/shared/Card';
import { TableColumn } from 'store/types/Table';
import Table from 'components/shared/Table';
import EventService from 'services/api/EventService';
import { getHashRouteUrl } from 'util/Route';
import { getEventPrice, getFormattedEventDate, getFormattedEventTime } from 'util/Event';
import routes from 'store/configs/Routes';
import AddEventGuestModal from 'components/events/AddEventGuestModal';
import EventStatus from 'store/enums/events/EventStatus';
import { EMPTY_STRING_VALUE, getUserFullName } from 'util/Format';
import { UserContext } from 'components/UserGuard';

import commonStyles from 'styles/common.module.scss';
import styles from './EventsCartListItem.module.scss';

interface EventsCartPageViewProps {
  data: MainEventView;
  showButtons?: boolean;
}

const EventsCartListItem: React.FunctionComponent<EventsCartPageViewProps> = ({ data, showButtons = true }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { refetchCart } = useContext(EventCartContext);
  const currentUser = useContext(UserContext);
  const {
    id,
    title,
    sessions,
    customerName,
    registrantId,
    price,
    guestRegistrationAllowed,
    companyId,
    guestId,
    companyName,
    registrantName,
  } = data;
  const availableSessions: EventSessionView[] = useMemo(
    () =>
      (sessions || []).map((session) => ({
        ...session,
        status: session.status === EventStatus.Selected ? EventStatus.Available : session.status,
      })),
    [sessions]
  );
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [sessionsModalOpen, setSessionsModalOpen] = useState<boolean>(false);
  const [addEventGuestModalOpen, setAddEventGuestModalOpen] = useState<boolean>(false);
  const tableSessions: EventSessionView[] = useMemo(
    () => (sessions || []).filter(({ status }) => status === EventStatus.Selected),
    [sessions]
  );
  const hasSelectedSessions = useMemo(() => !!tableSessions.length, [tableSessions]);
  const hasAvailableSessions = useMemo(() => !!sessions?.length, [sessions]);
  const customerLabelName: string = useMemo(
    () =>
      companyId && !guestId
        ? `${companyName}: ${customerName}`
        : registrantName
        ? `${registrantName}'s Guest: ${customerName}`
        : `${getUserFullName(currentUser, true)}`,
    [companyId, companyName, currentUser, customerName, guestId, registrantName]
  );
  const handleRemoveClick = useCallback(() => {
    setSubmitLoading(true);
    EventService.removeEventFromCart(id, guestId, registrantId)
      .then(() => {
        setSubmitLoading(false);
        refetchCart();
      })
      .catch((errorMessage: string) => {
        setSubmitLoading(false);
        enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
      });
  }, [id, guestId, registrantId, refetchCart, enqueueSnackbar]);

  const handleSessionsModalOpen = useCallback(() => {
    setSessionsModalOpen(true);
  }, []);

  const handleSessionsModalClose = useCallback(() => {
    setSessionsModalOpen(false);
  }, []);

  const handleAddEventGuestModalOpen = useCallback(() => {
    setAddEventGuestModalOpen(true);
  }, []);

  const handleAddEventGuestModalClose = useCallback(() => {
    setAddEventGuestModalOpen(false);
  }, []);

  const columns: Array<TableColumn<EventSessionView>> = [
    {
      dataIndex: 'title',
      label: 'Session Name',
    },
    {
      dataIndex: 'track',
      label: 'Track',
    },
    {
      dataIndex: 'startDate',
      label: 'Date',
      render: (_: any, { startDate, endDate }: EventSessionView) => getFormattedEventDate(startDate, endDate),
    },
    {
      label: 'Time',
      align: 'center',
      render: (_: any, { startDate, endDate, timeZoneName }: EventSessionView) =>
        endDate && startDate.isSame(endDate, 'days')
          ? getFormattedEventTime(startDate, endDate, timeZoneName)
          : EMPTY_STRING_VALUE,
    },
    {
      dataIndex: 'price',
      label: 'Price',
      align: 'right',
      render: (price: number) => getPrice(price),
    },
  ];

  return (
    <>
      <div className={styles.customerLabel}>{customerLabelName}</div>
      <Card className={styles.card} contentClassName={styles.cardContent} loading={submitLoading}>
        <Grid {...defaultGridContainerProps} justifyContent={'space-between'}>
          <Grid {...defaultGridItemProps} md={true}>
            <div className={styles.titleWrapper}>
              <Link color={'inherit'} href={getHashRouteUrl(`${routes.eventDetails}/${id}`)}>
                <h3 className={styles.title}>{title}</h3>
              </Link>
              <span className={styles.price}>{getPrice(price)}</span>
            </div>
            {hasSelectedSessions && (
              <Table
                columns={columns}
                list={tableSessions}
                className={styles.sessionsTable}
                cellClassName={styles.sessionsTableCell}
              />
            )}
            <Grid {...defaultGridItemProps} className={styles.subtotalPrice}>
              <span className={styles.price}>{`Subtotal: ${getPrice(getEventPrice(data))}`}</span>
            </Grid>
          </Grid>
          {showButtons && (
            <Grid {...defaultGridItemProps} md={2} className={styles.rightSection}>
              <Grid {...defaultGridContainerProps}>
                <Grid {...defaultGridItemProps}>
                  {!guestId && guestRegistrationAllowed && (
                    <Button
                      variant={'contained'}
                      size={'small'}
                      color={'primary'}
                      fullWidth={true}
                      className={styles.addGuestButton}
                      onClick={handleAddEventGuestModalOpen}
                    >
                      {'Add Guest'}
                    </Button>
                  )}
                  {hasAvailableSessions && (
                    <Button
                      variant={'contained'}
                      size={'small'}
                      color={'primary'}
                      fullWidth={true}
                      className={styles.manageSessionsButton}
                      onClick={handleSessionsModalOpen}
                    >
                      {'Manage Sessions'}
                    </Button>
                  )}
                  <Button
                    size={'small'}
                    variant={'outlined'}
                    className={commonStyles.dangerButtonOutlined}
                    fullWidth={true}
                    onClick={handleRemoveClick}
                  >
                    {'Remove'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Card>
      {hasAvailableSessions && (
        <ManageEventSessionsModal
          data={sessions || []}
          registrantId={registrantId}
          companyId={companyId}
          guestId={guestId}
          id={id}
          open={sessionsModalOpen}
          onClose={handleSessionsModalClose}
          mode={ManageEventSessionsMode.UpdateInCart}
        />
      )}
      <AddEventGuestModal
        onClose={handleAddEventGuestModalClose}
        open={addEventGuestModalOpen}
        eventId={id}
        registrantId={registrantId}
        data={availableSessions}
        companyId={companyId}
      />
    </>
  );
};
export default EventsCartListItem;
