import { Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import ApplicationDecisionModal from 'components/applications/ApplicationDecisionModal';
import ApplicationQuestionsFormSection from 'components/applications/ApplicationQuestionsFormSection';
import Spinner from 'components/shared/Spinner';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import ApplicationsService, { ApplicationSubmitResponseData } from 'services/api/ApplicationsService';
import routes from 'store/configs/Routes';
import ApplicationAnswer, { ApplicationAnswerData, ApplicationUploadedFile } from 'store/types/ApplicationAnswer';
import { ApplicationContentSectionView, ApplicationContentView } from 'store/types/ApplicationContent';
import ApplicationQuestion from 'store/types/ApplicationQuestion';
import {
  getAnswersData,
  getFormattedApplicationStatus,
  getFormattedSections,
  getQuestionFieldName,
  getQuestionId,
} from 'util/Application';
import ApplicationStatus from 'store/enums/ApplicationStatus';
import ApplicationPageLayout from 'components/applications/ApplicationPageLayout';
import useUnsavedChangesPrompt from 'hooks/useUnsavedChangesPrompt';
import { getUnsavedChangesPromptMessage } from 'util/Form';
import QuestionType from 'store/enums/QuestionType';
import ApplicationConfirmationModal from 'components/applications/ApplicationConfirmationModal';
import { defaultSnackbarErrorProps } from 'util/Layout';

import commonStyles from 'styles/common.module.scss';
import pageStyles from '../ApplicationPage.module.scss';

interface EditApplicationPageViewProps {
  data: ApplicationContentView;
  refetch: () => void;
}

interface ApplicationFileAnswer {
  files: Array<{ questionId: string; file: File[] }>;
}

const EditApplicationPageView: React.FunctionComponent<EditApplicationPageViewProps> = ({ data, refetch }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { handleSubmit, formState, getValues, setError, watch } = useFormContext();
  const { applicationId = '' } = useParams<{ applicationId: string }>();
  const { removeUnsavedChangesPrompt, prompt } = useUnsavedChangesPrompt(getUnsavedChangesPromptMessage());
  const [decisionModalOpen, setDecisionModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [submitResult, setSubmitResult] = useState<ApplicationSubmitResponseData | undefined>(undefined);
  const [sections, setSections] = useState<ApplicationContentSectionView[]>([]);
  const questionsList: ApplicationQuestion[] = useMemo(() => {
    let result: ApplicationQuestion[] = [];

    if (data) {
      (data.sections || []).forEach(({ questions = [] }) => {
        result = result.concat(questions);
      });
    }
    return result;
  }, [data]);
  const values = watch();
  const hasContent: boolean = useMemo(() => !!(data && data.sections && data.sections.length), [data]);
  const requiredCheckboxQuestions: string[] = useMemo(
    () =>
      questionsList
        .filter(({ required, type }) => required && type === QuestionType.Checkbox)
        .map(({ fieldName }) => fieldName),
    [questionsList]
  );

  const requiredSublistQuestions: string[] = useMemo(
    () =>
      questionsList
        .filter(({ required, type }) => required && type === QuestionType.SubList)
        .map(({ fieldName }) => fieldName),
    [questionsList]
  );
  const submitButtonDisabled: boolean = useMemo(
    () =>
      !hasContent ||
      loading ||
      !formState.isValid ||
      !requiredSublistQuestions.every((questionId) => !!values[questionId]?.length) ||
      !requiredCheckboxQuestions.every((questionId) => values[questionId].some(({ value }: any) => !!value)),
    [hasContent, loading, formState.isValid, requiredSublistQuestions, requiredCheckboxQuestions, values]
  );

  const handleDecisionModalOpen = useCallback(() => {
    setDecisionModalOpen(true);
  }, []);

  const handleDecisionModalClose = useCallback(() => {
    setDecisionModalOpen(false);
  }, []);

  useEffect(() => {
    if (data) {
      setSections(getFormattedSections(data.sections));
    }
  }, [data]);

  const uploadFiles = useCallback(
    (request, answersData: ApplicationAnswer[], files: ApplicationFileAnswer) => {
      const result: ApplicationAnswerData[] = answersData;
      const uploadPromiseArray: Promise<ApplicationUploadedFile>[] = [];

      if (files) {
        Object.entries(files).forEach((fileAnswer) => {
          if (fileAnswer[1]?.length) {
            fileAnswer[1].forEach((file: File) =>
              uploadPromiseArray.push(ApplicationsService.uploadFile(applicationId, fileAnswer[0], file))
            );
          }
        });

        Promise.all(uploadPromiseArray)
          .then((data: ApplicationUploadedFile[]) => {
            data.forEach((item: ApplicationUploadedFile) => {
              const fileQuestionId: string = getQuestionId(item.questionId);
              const answerIndex = result.findIndex(({ questionId }) => questionId === fileQuestionId);

              if (answerIndex) {
                result[answerIndex].files = [...(result[answerIndex].files || []), { id: item.fileId }];
              } else {
                result.push({
                  questionId: fileQuestionId,
                  files: [{ id: item.fileId }],
                });
              }
            });

            request(result);
          })
          .catch((errorMessage: string) => {
            setLoading(false);
            enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
          });
      } else {
        request(answersData);
      }
    },
    [applicationId, enqueueSnackbar]
  );

  const handleFormSave = useCallback(() => {
    setLoading(true);
    const formValues = getValues();
    const answersData: ApplicationAnswer[] = getAnswersData(formValues, questionsList);
    const files: ApplicationFileAnswer = formValues['files'];

    uploadFiles(
      (data: ApplicationAnswerData[]) =>
        ApplicationsService.updateApplicationContent(applicationId, data)
          .then(() => {
            setLoading(false);
            removeUnsavedChangesPrompt();
            enqueueSnackbar('Application successfully saved', { variant: 'success' });
            refetch();
          })
          .catch((error: string) => {
            setLoading(false);
            enqueueSnackbar(error, defaultSnackbarErrorProps);
          }),
      answersData,
      files
    );
  }, [applicationId, enqueueSnackbar, getValues, uploadFiles, questionsList, removeUnsavedChangesPrompt, refetch]);

  const handleFormSubmit = useCallback(
    (formValues: any) => {
      setLoading(true);
      const answersData: ApplicationAnswer[] = getAnswersData(formValues, questionsList);
      const files: ApplicationFileAnswer = formValues['files'];

      uploadFiles(
        (data: ApplicationAnswerData[]) =>
          ApplicationsService.submitApplicationContent(applicationId, data)
            .then((result: ApplicationSubmitResponseData) => {
              const { isOk, messages = [] } = result;

              setLoading(false);
              setSubmitResult(result);
              if (isOk) {
                removeUnsavedChangesPrompt();
              } else if (messages.length) {
                messages.forEach(({ questionId, message }) => {
                  setError(getQuestionFieldName(questionId), { type: 'required', message });
                });
              }
              enqueueSnackbar('Application successfully submitted', { variant: 'success' });
            })
            .catch((error: string) => {
              setLoading(false);
              enqueueSnackbar(error, defaultSnackbarErrorProps);
            }),
        answersData,
        files
      );
    },
    [questionsList, uploadFiles, applicationId, enqueueSnackbar, removeUnsavedChangesPrompt, setError]
  );

  const handleCancel = useCallback(() => {
    history.push(routes.applications);
  }, [history]);

  return (
    <>
      <ApplicationPageLayout
        data={data}
        footerProps={{
          onSubmit: handleSubmit(handleFormSubmit),
          submitButtonName: 'Submit',
          submitButtonDisabled,
          children: (
            <>
              <Button
                color={'secondary'}
                variant={'outlined'}
                onClick={handleCancel}
                className={pageStyles.cancelButton}
                disabled={loading}
              >
                {'Go Back'}
              </Button>
              {data.review?.decisionDate && (
                <Button color={'secondary'} variant={'outlined'} onClick={handleDecisionModalOpen}>
                  {'View Decision'}
                </Button>
              )}
              <Button
                color={'secondary'}
                variant={'outlined'}
                onClick={handleFormSave}
                disabled={!hasContent || loading}
                className={pageStyles.saveButton}
              >
                {'Save'}
              </Button>
            </>
          ),
        }}
      >
        <Spinner loading={loading}>
          {data && (
            <div className={pageStyles.questionsWrapper}>
              {data.title && (
                <h1 className={commonStyles.pageTitle}>
                  {data.campaignName ? `${data.title} (${data.campaignName})` : data.title}
                  {` (ID# ${data.id})`}
                </h1>
              )}
              {hasContent ? (
                <ApplicationQuestionsFormSection sections={sections} />
              ) : (
                <Alert severity={'error'} className={commonStyles.alert}>
                  {'No content found'}
                </Alert>
              )}
            </div>
          )}
        </Spinner>
      </ApplicationPageLayout>
      {submitResult && (
        <ApplicationConfirmationModal
          open={submitResult.isOk}
          showPaymentLink={getFormattedApplicationStatus(submitResult.status) === ApplicationStatus.PendingPayment}
        />
      )}
      {prompt}
      {data.review?.decisionDate && (
        <ApplicationDecisionModal
          onClose={handleDecisionModalClose}
          open={decisionModalOpen}
          review={data.review}
          applicationStatus={data.status}
        />
      )}
    </>
  );
};
export default EditApplicationPageView;
