import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ApplicationContentView } from 'store/types/ApplicationContent';
import { defaultFormProps } from 'util/Form';
import { getQuestionsDefaultValues } from 'util/Application';
import ViewApplicationForm from '../ViewApplicationForm';

interface ViewApplicationPageViewProps {
  data: ApplicationContentView;
}

const ViewApplicationPageView: React.FunctionComponent<ViewApplicationPageViewProps> = ({ data }) => {
  const form = useForm({ ...defaultFormProps, defaultValues: getQuestionsDefaultValues(data) });

  return (
    <FormProvider {...form}>
      <ViewApplicationForm data={data} />
    </FormProvider>
  );
};
export default ViewApplicationPageView;
