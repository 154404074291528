import React from 'react';
import PageCard from 'components/shared/PageCard';
import LevelAssessmentIcon from 'assets/icons/nccerCheck.png';
import ModuleTestingIcon from 'assets/icons/nccerList.png';
import OnlineCoursesIcon from 'assets/icons/nccerDesktop.png';
import RegistryIcon from 'assets/icons/nccerCard.png';
import ShopIcon from 'assets/icons/nccerShop.png';
import { getEmailLink, getPhoneLink } from 'util/Format';
import { Link } from '@mui/material';

import styles from './HomeNccerNoticeCard.module.scss';

const HomeNccerNoticeCard: React.FunctionComponent = () => {
  return (
    <PageCard title={'Notice'}>
      <p>
        {
          'Welcome to our new NCCER single sign-on homepage. Use the Quick Links above to access your NCCER systems. For navigation assistance, see system descriptions below.'
        }
      </p>
      <ul className={styles.options}>
        <li>
          <img src={LevelAssessmentIcon} alt={'Journey-Level Assessments'} className={styles.icon} />
          <span>
            <strong>{'Journey-Level Assessments: '}</strong>
            {
              'Assign or take assessments and practical exams that are part of NCCER’s National Craft Assessment and Certification Program.'
            }
          </span>
        </li>
        <li>
          <img src={RegistryIcon} alt={'Registry'} className={styles.icon} />
          <span>
            <strong>{'Registry: '}</strong>
            {
              'Manage training and assessment programs and record completed performance profiles through NCCER’s secure credentialing database.'
            }
          </span>
        </li>
        <li>
          <img src={ModuleTestingIcon} alt={'Module Testing'} className={styles.icon} />
          <span>
            <strong>{'Module Testing: '}</strong>
            {'Assign and proctor module tests through the NCCER Testing System.'}
          </span>
        </li>
        <li>
          <img src={OnlineCoursesIcon} alt={'Online Courses'} className={styles.icon} />
          <span>
            <strong>{'Online Courses: '}</strong>
            {'Assign or access courses through NCCER’s online learning platform.'}
            <Link href={'https://billing.account.nccer.org'}>{'*'}</Link>
          </span>
        </li>
        <li>
          <img src={ShopIcon} alt={'Shop'} className={styles.icon} />
          <span>
            <strong>{'Shop: '}</strong>
            {'Purchase module tests, trainer and performance kits, logo items and Build Your Future resources.'}
          </span>
        </li>
      </ul>
      <p>
        {'If you need additional help, please contact us at '}
        {getEmailLink('support@nccer.org')}
        {' or '}
        {getPhoneLink('(888) 622-3720')}.
      </p>
      <p className={styles.note}>
        {
          '*Courses and products offered here are not part of the NCCER curricula offered through our publisher Pearson.'
        }
      </p>
    </PageCard>
  );
};
export default HomeNccerNoticeCard;
