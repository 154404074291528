import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import React, { useCallback } from 'react';
import { Avatar } from '@mui/material';
import { useHistory } from 'react-router';
import ArrowForward from '@mui/icons-material/ArrowForward';
import routes from 'store/configs/Routes';
import { DonationFundItem } from 'store/types/DonationFund';
import Card from 'components/shared/Card';

import styles from './DonationListItem.module.scss';

interface DonationListItemProps {
  donationFund: DonationFundItem;
}

const DonationListItem: React.FunctionComponent<DonationListItemProps> = ({ donationFund }) => {
  const { push } = useHistory();
  const { name, logoUrl, id } = donationFund;

  const handleClick = useCallback(() => {
    push(`${routes.makeDonation}/${id}`);
  }, [id, push]);

  return (
    <Card className={styles.card} onClick={handleClick} contentClassName={styles.content}>
      <div className={styles.logoWrapper}>
        <Avatar src={logoUrl} variant={'square'} className={styles.logo}>
          <AccountBalanceIcon />
        </Avatar>
      </div>
      <div className={styles.titleWrapper}>
        <h2 className={styles.title}>{name}</h2>
        <ArrowForward className={styles.arrowIcon} />
      </div>
    </Card>
  );
};
export default DonationListItem;
