import { Alert, Button, Grid, Link, MenuItem, TextField } from '@mui/material';
import Modal from 'components/shared/Modal';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { defaultFormProps, getRequiredValidationRule, getValidationProps } from 'util/Form';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import { ModalProps } from 'store/types/ComponentProps';
import SelectOption from 'store/types/SelectOption';
import UserSearchFormItem from 'components/profile/UserSearchFormItem';
import CompanyService from 'services/api/CompanyService';
import { useSnackbar } from 'notistack';
import { UserJobFunction } from 'store/types/JobFunction';
import { UserContext } from 'components/UserGuard';
import { ParametersContext } from 'components/ParametersGuard';
import { UserEmployerAccessRequestsData } from 'store/types/UserEmployerAccessRequest';
import CompanyAccessRequestStatus from 'store/enums/CompanyAccessRequestStatus';

import styles from './LinkCompanyUserModal.module.scss';
import commonStyles from 'styles/common.module.scss';

interface LinkCompanyUserModalProps extends ModalProps {
  companyId: string;
  portalRolesList: SelectOption[];
  refetch: () => void;
  onAddUserModalOpen: () => void;
  showJobFunctionDropdown: boolean;
  preSelectedJobFunctionId?: string;
  companyRequests?: UserEmployerAccessRequestsData;
}

interface LinkCompanyUserFormValues {
  users: SelectOption[];
  jobFunctionId: string;
}

const defaultValues: LinkCompanyUserFormValues = {
  users: [],
  jobFunctionId: '',
};

const getCompanyName = (companyId: string, functions: UserJobFunction[] = []): string => {
  const foundCompany = functions.find((company) => company.companyId === companyId);

  return foundCompany ? foundCompany.companyName : '';
};

const isPendingRequestToCompanyExist = (id: string, { incoming, outgoing }: UserEmployerAccessRequestsData): boolean =>
  incoming?.some(({ requesterId, status }) => requesterId === id && status === CompanyAccessRequestStatus.Pending) ||
  outgoing?.some(({ approverId, status }) => approverId === id && status === CompanyAccessRequestStatus.Pending);

const LinkCompanyUserModal: React.FunctionComponent<LinkCompanyUserModalProps> = ({
  open,
  onClose,
  companyId,
  portalRolesList,
  refetch,
  onAddUserModalOpen,
  showJobFunctionDropdown,
  preSelectedJobFunctionId = '',
  companyRequests,
}) => {
  const form = useForm<LinkCompanyUserFormValues>({ ...defaultFormProps, defaultValues });
  const { functions = [] } = useContext(UserContext);
  const {
    companyManagement: { searchEmployeeModalHeader, searchAdminModalHeader },
  } = useContext(ParametersContext);
  const {
    handleSubmit,
    formState: { isValid },
    control,
    setValue,
    reset,
  } = form;
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [isPendingRequestExist, setIsPendingRequestExist] = useState<boolean>(false);
  const selectedUser = useWatch({ control, name: 'users' })?.[0];
  const companyName: string = useMemo(() => getCompanyName(companyId, functions), [functions, companyId]);

  useEffect(() => {
    setValue('jobFunctionId', preSelectedJobFunctionId);

    if (selectedUser && companyRequests) {
      setIsPendingRequestExist(isPendingRequestToCompanyExist(selectedUser.id, companyRequests));
    }
  }, [companyRequests, preSelectedJobFunctionId, selectedUser, setValue]);

  const handleLinkUserSubmit = useCallback(
    ({ users = [], jobFunctionId }) => {
      const selectedUser: SelectOption | undefined = users.length ? users[0] : undefined;
      if (selectedUser?.id) {
        setLoading(true);

        (showJobFunctionDropdown
          ? CompanyService.linkCompanyAdminUser(companyId, selectedUser.id, jobFunctionId)
          : CompanyService.linkCompanyEmployee(companyId, selectedUser.id)
        )
          .then(() => {
            enqueueSnackbar('Request successfully added', {
              variant: 'success',
            });
            setLoading(false);
            onClose();
            refetch();
            reset(defaultValues);
          })
          .catch((errorMessage) => {
            enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
            setLoading(false);
          });
      }
    },
    [companyId, enqueueSnackbar, onClose, refetch, reset, showJobFunctionDropdown]
  );

  const handleClose = useCallback(() => {
    onClose();
    reset(defaultValues);
  }, [onClose, reset]);

  return (
    <FormProvider {...form}>
      <Modal
        open={open}
        onClose={handleClose}
        maxWidth={'sm'}
        title={showJobFunctionDropdown ? 'Search for Contact' : 'Search for Employee'}
        loading={loading}
        actions={
          <>
            <Button color={'secondary'} variant={'outlined'} onClick={handleClose}>
              {'Cancel'}
            </Button>
            <Button
              color={'secondary'}
              variant={'contained'}
              type={'submit'}
              disabled={!isValid || isPendingRequestExist}
              onClick={handleSubmit(handleLinkUserSubmit)}
            >
              {'Save'}
            </Button>
          </>
        }
      >
        <Grid {...defaultGridContainerProps} spacing={2}>
          {isPendingRequestExist && (
            <Grid {...defaultGridItemProps}>
              <Alert severity={'error'} className={commonStyles.alert}>
                {'Company already have a pending request to/from the same user.'}
              </Alert>
            </Grid>
          )}
          <Grid {...defaultGridItemProps}>
            <p
              className={commonStyles.text}
              dangerouslySetInnerHTML={{
                __html: showJobFunctionDropdown ? searchAdminModalHeader : searchEmployeeModalHeader,
              }}
            />
          </Grid>
          <Grid {...defaultGridItemProps}>
            <h5 className={commonStyles.subTitle}>{'Company'}</h5>
            <TextField value={companyName} disabled={true} />
          </Grid>
          {showJobFunctionDropdown && (
            <Grid {...defaultGridItemProps}>
              <h5 className={commonStyles.subTitle}>{'Select Portal Role'}</h5>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    {...getValidationProps('jobFunctionId')}
                    required={true}
                    select={true}
                    SelectProps={{
                      displayEmpty: true,
                      renderValue: (selected: any) =>
                        portalRolesList.find(({ id }) => selected === id)?.name || (
                          <span className={commonStyles.placeholder}>{'Please select Portal Role'}</span>
                        ),
                    }}
                  >
                    {portalRolesList.map(({ id, name }: SelectOption) => (
                      <MenuItem value={id} key={`portal-role-${id}`}>
                        {name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                name={'jobFunctionId'}
                control={control}
                rules={{ required: getRequiredValidationRule('portal role', true) }}
              />
            </Grid>
          )}
          <Grid {...defaultGridItemProps}>
            <h5 className={commonStyles.subTitle}>{'Enter Email, First or Last Name to Search'}</h5>
            <UserSearchFormItem companyId={companyId} />
          </Grid>
          <Grid {...defaultGridItemProps}>
            <span className={styles.helperText}>{`${
              showJobFunctionDropdown ? 'Contact' : 'Employee'
            } not found? Click `}</span>
            <Link className={styles.link} onClick={onAddUserModalOpen}>
              {'here'}
            </Link>
          </Grid>
        </Grid>
      </Modal>
    </FormProvider>
  );
};

export default LinkCompanyUserModal;
