import { httpRequestWrapper } from 'util/Request';
import { HttpService } from 'services/HttpService';
import { PROFILE_API_URL, tenantConfig } from 'config';
import AuthService from 'services/AuthService';
import { UserEmployerAccessRequest, UserEmployerAccessRequestsData } from 'store/types/UserEmployerAccessRequest';

type EmployersSearchResponseData = UserEmployerAccessRequest[];

class UserEmployerService {
  public static getUserEmployer(): Promise<UserEmployerAccessRequestsData> {
    return new Promise<UserEmployerAccessRequestsData>(
      (resolve: (data: UserEmployerAccessRequestsData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<UserEmployerAccessRequestsData>(
          HttpService.get(`${PROFILE_API_URL}/profiles/employer`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static searchEmployer(companyName: string, excludeCustomer?: string): Promise<EmployersSearchResponseData> {
    return new Promise<EmployersSearchResponseData>(
      (resolve: (data: EmployersSearchResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<EmployersSearchResponseData>(
          HttpService.get(`${PROFILE_API_URL}/profiles/search`, {
            queryParams: { companyName, excludeCustomer },
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static requestEmployer(companyId: string): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.post(`${PROFILE_API_URL}/profiles/employer/request`, {
          data: { companyId },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static cancelEmployerRequest(requestId: string): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.put(`${PROFILE_API_URL}/profiles/employer/request/cancel`, {
          data: { requestId },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }
  public static approveEmployerRequests(requestId: string): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.put(`${PROFILE_API_URL}/profiles/employer/request/approve`, {
          data: { requestId },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static rejectEmployerRequests(requestId: string): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.put(`${PROFILE_API_URL}/profiles/employer/request/reject`, {
          data: { requestId },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }
}

export default UserEmployerService;
