import { Container, Grid, MenuItem, TextField } from '@mui/material';
import React, { ChangeEvent, Reducer, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { getPagesCount } from 'util/Table';
import Pagination from '@mui/material/Pagination';
import { ConfigContext } from 'components/ConfigGuard';
import reducer, {
  initialState,
  PublicCommitteePageViewAction,
  PublicCommitteePageViewActionType,
  PublicCommitteePageViewState,
} from 'components/communities/PublicCommitteePageView/PublicCommitteePageViewReducer';
import SearchInput from 'components/shared/SearchInput';
import { Community } from 'store/types/Community';
import PublicCommitteeItem from 'components/communities/PublicCommitteePageView/PublicCommitteeItem';

import styles from './PublicCommitteePageView.module.scss';

interface PublicCommitteePageViewProps {
  data: Community[];
}

const PublicCommitteePageView: React.FunctionComponent<PublicCommitteePageViewProps> = ({ data }) => {
  const {
    modulesSettings: {
      publicCommittees: { pageTitle, pageSubTitle },
    },
  } = useContext(ConfigContext);
  const [{ pageList = [], page = 1, initialList, itemsPerPage, filter }, dispatch] = useReducer<
    Reducer<PublicCommitteePageViewState, PublicCommitteePageViewAction>
  >(reducer, initialState);
  const pagesCount: number = useMemo(
    () => getPagesCount(initialList.length, itemsPerPage),
    [initialList, itemsPerPage]
  );

  useEffect(() => {
    dispatch({
      type: PublicCommitteePageViewActionType.SetInitialList,
      payload: { initialList: data },
    });
  }, [data]);

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: PublicCommitteePageViewActionType.UpdateFilter,
      payload: { filter: { search: e.target.value } },
    });
  }, []);

  const handlePageChange = useCallback((_: any, newPage: number) => {
    window.scrollTo(0, 500);
    dispatch({
      type: PublicCommitteePageViewActionType.UpdatePage,
      payload: { page: newPage },
    });
  }, []);

  const handleItemsPerPageChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: PublicCommitteePageViewActionType.UpdateItemsPerPage,
      payload: { itemsPerPage: parseInt(e.target.value, 10) },
    });
  }, []);

  return (
    <>
      <div className={styles.container}>
        <section className={styles.banner}>
          <Container maxWidth={'lg'} className={styles.bannerContent}>
            <div className={styles.titleContainer}>
              <h1 className={styles.title}>{pageTitle}</h1>
              {pageSubTitle && (
                <div className={styles.subTitleBox}>
                  <span>{pageSubTitle}</span>
                </div>
              )}
            </div>
            <div>
              <Grid {...defaultGridContainerProps} justifyContent={'flex-end'} alignItems={'center'}>
                <Grid {...defaultGridItemProps} md={5}>
                  <SearchInput
                    value={filter.search}
                    inputClassName={styles.searchInput}
                    onChange={handleSearchChange}
                    placeholder={'Search by Name'}
                  />
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
        <Container maxWidth={'lg'} className={styles.content}>
          <Grid {...defaultGridContainerProps} justifyContent={'flex-end'}>
            {pageList.length ? (
              pageList.map((item, index) => (
                <Grid {...defaultGridItemProps} key={`committee-${item.id}-${index}`}>
                  <PublicCommitteeItem data={item} />
                </Grid>
              ))
            ) : (
              <Grid {...defaultGridItemProps} className={styles.noData}>
                <p>{'There are no results matching your search criteria.'}</p>
              </Grid>
            )}
            <Grid {...defaultGridItemProps} sm={true} className={styles.paginationWrapper}>
              <Pagination
                showFirstButton={true}
                showLastButton={true}
                count={pagesCount}
                page={page}
                onChange={handlePageChange}
              />
            </Grid>
            <Grid {...defaultGridItemProps} sm={'auto'} className={styles.pageSelectWrapper}>
              <div className={styles.select}>
                <span className={styles.selectLabel}>{'Show'}</span>
                <TextField
                  select={true}
                  variant={'outlined'}
                  size={'small'}
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
                  <MenuItem value={10}>{'10'}</MenuItem>
                  <MenuItem value={20}>{'20'}</MenuItem>
                  <MenuItem value={50}>{'50'}</MenuItem>
                  <MenuItem value={100}>{'100'}</MenuItem>
                </TextField>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};
export default PublicCommitteePageView;
