import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import classNames from 'classnames';
import FormFooter from 'components/shared/FormFooter';
import MembershipPlansSection from 'components/subscriptions/MembershipPlansSection';
import SubscriptionDonationSection from 'components/subscriptions/SubscriptionDonationSection';
import { PaymentItemsFormValues, PaymentFormValues } from 'store/types/FormValues';
import PaymentFormSection from 'components/payments/PaymentFormSection';
import { SubscriptionPlan } from 'store/types/Subscription';
import {
  defaultPaymentFormValues,
  donationsAmountSelected,
  getPaymentData,
  paymentFormInvalid,
  shouldValidatePaymentMethod,
  getSummaryTotalPrice,
} from 'util/Payment';
import Payment from 'store/types/Payment';
import { getDonationsPaymentData, getMembershipSummaryList } from 'util/Membership';
import PaymentService, { BasePaymentData, MembershipPaymentData } from 'services/api/PaymentService';
import { MembershipContext } from 'components/MembershipGuard';
import Spinner from 'components/shared/Spinner';
import { useSnackbar } from 'notistack';
import CollectJSResponse from 'store/types/CollectJSResponse';
import Stepper from 'components/shared/Stepper';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import MembershipService from 'services/api/MembershipService';
import Donation from 'store/types/Donation';
import useRequest from 'hooks/useRequest';
import { PaymentMethodsContext } from 'components/PaymentMethodsContextWrapper';
import { DEFAULT_ERROR_MESSAGE } from 'util/Form';
import * as MUIcon from '@mui/icons-material';
import { SiteConfigView } from 'store/types/SiteConfig';
import { ConfigContext } from 'components/ConfigGuard';

import commonStyles from 'styles/common.module.scss';
import styles from './CreateMembershipPageView.module.scss';

interface CreateMembershipPageViewProps {
  onPaymentCompleted: (payment: Payment) => void;
}

interface CreateMembershipStepConfigItem {
  label: string;
  icon: keyof typeof MUIcon;
}

const stepsConfig: CreateMembershipStepConfigItem[] = [
  {
    label: 'Select Membership Type',
    icon: 'CardMembership',
  },
  {
    label: 'Optional Donation',
    icon: 'VolunteerActivism',
  },
  {
    label: 'Complete Payment',
    icon: 'AttachMoney',
  },
];

const getSubmitButtonDisabled = (
  step = 0,
  paymentForm: PaymentFormValues,
  donations: PaymentItemsFormValues,
  membershipType?: SubscriptionPlan
): boolean => {
  const firstStepDisabled = !membershipType;
  const secondStepDisabled = firstStepDisabled || !donationsAmountSelected(donations);
  const thirdStepDisabled = firstStepDisabled || secondStepDisabled || paymentFormInvalid(paymentForm);

  if (step === 0) {
    return firstStepDisabled;
  } else if (step === 1) {
    return secondStepDisabled;
  } else {
    return thirdStepDisabled;
  }
};

const CreateMembershipPageView: React.FunctionComponent<CreateMembershipPageViewProps> = ({ onPaymentCompleted }) => {
  const {
    modulesSettings: {
      payments: { invoices },
    },
  }: SiteConfigView = useContext(ConfigContext);
  const { enqueueSnackbar } = useSnackbar();
  const { refetchMembership } = useContext(MembershipContext);
  const { refetch: refetchPaymentMethods } = useContext(PaymentMethodsContext);
  const donationsRequest = useRequest<Donation[]>(MembershipService.getDonations);
  const [membershipPlan, setMembershipPlan] = useState<SubscriptionPlan | undefined>(undefined);
  const [donations, setDonations] = useState<PaymentItemsFormValues>({});
  const [paymentForm, setPaymentForm] = useState<PaymentFormValues>({
    ...defaultPaymentFormValues,
    autoRenewal: true,
  });
  const [step, setStep] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const submitButtonDisabled: boolean = getSubmitButtonDisabled(step, paymentForm, donations, membershipPlan);
  const { summaryList, totalPrice } = paymentForm;

  useEffect(() => {
    setPaymentForm((prevState) => ({
      ...prevState,
      totalPrice: getSummaryTotalPrice(summaryList),
    }));
  }, [summaryList]);

  useEffect(() => {
    const newSummaryList = getMembershipSummaryList(donations, membershipPlan);

    setPaymentForm((prevState) => ({
      ...prevState,
      summaryList: newSummaryList,
    }));
  }, [donations, membershipPlan]);

  const handleTypeSelected = useCallback((newType: SubscriptionPlan) => {
    setMembershipPlan(newType);
  }, []);

  const handleDonationSelected = useCallback((newDonations: PaymentItemsFormValues) => {
    setDonations(newDonations);
  }, []);

  const handlePaymentChange = useCallback((formValues: Partial<PaymentFormValues>) => {
    setPaymentForm((prevForm) => ({ ...prevForm, ...formValues }));
  }, []);

  const handleNextStep = () => {
    if (step < 2) {
      const newStep = step + 1;

      if (newStep === 2 && totalPrice === 0) {
        (window as any).CollectJS.clearInputs();
      }
      setStep(newStep);
    } else {
      setLoading(true);
      if (shouldValidatePaymentMethod(paymentForm)) {
        (window as any).CollectJS.startPaymentRequest();
      } else {
        enrollMembership();
      }
    }
  };

  const handlePaymentComplete = (response: CollectJSResponse) => {
    enrollMembership(response);
  };

  const enrollMembership = useCallback(
    (collectJSResponse?: CollectJSResponse) => {
      if (membershipPlan) {
        const donationsData = getDonationsPaymentData(donations);
        const basePaymentData: BasePaymentData = getPaymentData(paymentForm, collectJSResponse);
        const data: MembershipPaymentData = {
          ...basePaymentData,
          planId: membershipPlan.id.toString(),
          donations: donationsData,
          autoRenewal: paymentForm.autoRenewal || false,
        };

        PaymentService.makeMembershipPayment(data)
          .then((newPayment: Payment) => {
            refetchPaymentMethods();
            setLoading(false);
            onPaymentCompleted(newPayment);
            enqueueSnackbar('Payment successfully completed', { variant: 'success' });
            refetchMembership();
          })
          .catch((error: string) => {
            setLoading(false);
            enqueueSnackbar(error, defaultSnackbarErrorProps);
          });
      } else {
        enqueueSnackbar(DEFAULT_ERROR_MESSAGE, defaultSnackbarErrorProps);
      }
    },
    [
      paymentForm,
      membershipPlan,
      donations,
      enqueueSnackbar,
      refetchMembership,
      onPaymentCompleted,
      refetchPaymentMethods,
    ]
  );

  const handlePrevStep = useCallback(() => {
    setStep((prevState) => prevState - 1);
  }, []);

  return (
    <Spinner loading={loading}>
      <form className={styles.container}>
        <Grid {...defaultGridContainerProps}>
          <Grid {...defaultGridItemProps}>
            <Stepper
              activeStep={step}
              config={stepsConfig.map(({ label, icon }, index) => ({ label, icon, value: index }))}
            />
          </Grid>
          <Grid {...defaultGridItemProps} className={classNames({ [commonStyles.hidden]: step !== 0 })}>
            <MembershipPlansSection value={membershipPlan} onChange={handleTypeSelected} />
          </Grid>
          <Grid {...defaultGridItemProps} className={classNames({ [commonStyles.hidden]: step !== 1 })}>
            <SubscriptionDonationSection {...donationsRequest} value={donations} onChange={handleDonationSelected} />
          </Grid>
          <Grid {...defaultGridItemProps} className={classNames({ [commonStyles.hidden]: step !== 2 })}>
            <PaymentFormSection
              form={paymentForm}
              onChange={handlePaymentChange}
              onPaymentComplete={handlePaymentComplete}
              autoRenewalVisible={true}
              onlySavedAddresses={true}
              paymentConfig={invoices}
              // TODO: apply after BE is ready
              // discountEnabled={true}
            />
          </Grid>
        </Grid>
        <FormFooter
          onSubmit={handleNextStep}
          submitButtonName={step === 2 ? 'Submit' : 'Next'}
          submitButtonDisabled={submitButtonDisabled}
        >
          <Button color={'secondary'} variant={'outlined'} disabled={step === 0} onClick={handlePrevStep}>
            {'Back'}
          </Button>
        </FormFooter>
      </form>
    </Spinner>
  );
};
export default CreateMembershipPageView;
