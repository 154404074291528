import React, { useCallback, useContext, useMemo } from 'react';
import useRequest from 'hooks/useRequest';
import Alert from '@mui/material/Alert';
import { UserContext } from 'components/UserGuard';
import Spinner from 'components/shared/Spinner';
import { useLocation, useParams } from 'react-router';
import MemberDirectoryService from 'services/api/MemberDirectoryService';
import { CustomerCompanyDetails } from 'store/types/MemberDirectory';
import CompanyDetailsPageView from 'components/directory/CompanyDetailsPageView';
import routes from 'store/configs/Routes';

import commonStyles from 'styles/common.module.scss';

const CompanyDetailsPage: React.FunctionComponent = () => {
  const { id = '' } = useParams<{ id: string }>();
  const { pathname } = useLocation();
  const { userLoading = false } = useContext(UserContext);
  const companyDetails = useCallback(() => MemberDirectoryService.getCompanyDetails(id), [id]);
  const { data, loading, error } = useRequest<CustomerCompanyDetails>(companyDetails);

  const isChapter: boolean = useMemo(() => pathname.includes(routes.customerChapter), [pathname]);

  return (
    <Spinner loading={userLoading || loading} transparent={false}>
      {error ? (
        <Alert severity={'error'} className={commonStyles.alert}>
          {error}
        </Alert>
      ) : (
        data && <CompanyDetailsPageView data={data} isChapter={isChapter} />
      )}
    </Spinner>
  );
};
export default CompanyDetailsPage;
