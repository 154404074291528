import Alert from '@mui/material/Alert';
import React, { useContext } from 'react';
import useRequest from 'hooks/useRequest';
import Spinner from 'components/shared/Spinner';
import { UserContext } from 'components/UserGuard';
import AdvocacyService from 'services/api/AdvocacyService';
import { PriorApproval } from 'store/types/PriorApproval';
import AdvocacyPageView from 'components/profile/AdvocacyPageView';

import commonStyles from 'styles/common.module.scss';

const AdvocacyPage: React.FunctionComponent = () => {
  const { data, error, loading, refetch } = useRequest<PriorApproval[]>(AdvocacyService.getUserPriorApprovals);
  const { userLoading = false } = useContext(UserContext);

  return (
    <Spinner loading={loading || userLoading}>
      {error && (
        <Alert severity={'error'} className={commonStyles.alert}>
          {error}
        </Alert>
      )}
      {data && <AdvocacyPageView data={data} refetch={refetch} />}
    </Spinner>
  );
};

export default AdvocacyPage;
