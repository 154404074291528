import Alert from '@mui/material/Alert';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import ApplicationsService from 'services/api/ApplicationsService';
import useRequest from 'hooks/useRequest';
import ApplicationContent, { ApplicationContentView } from 'store/types/ApplicationContent';
import Spinner from 'components/shared/Spinner';
import NotFoundPage from 'components/layout/NotFoundPage';
import routes from 'store/configs/Routes';
import ApplicationStatus from 'store/enums/ApplicationStatus';
import { getFormattedApplicationStatus, getFormattedSections } from 'util/Application';
import EditApplicationPageView from './EditApplicationPageView';
import ViewApplicationPageView from './ViewApplicationPageView';
import ApplicationPaymentPageView from './ApplicationPaymentPageView';

import commonStyles from 'styles/common.module.scss';

const ApplicationPage: React.FunctionComponent = () => {
  const { pathname } = useLocation();
  const { applicationId = '' } = useParams<{ applicationId: string }>();
  const request = useCallback(() => ApplicationsService.getApplication(applicationId), [applicationId]);
  const { data, error, loading, refetch } = useRequest<ApplicationContent>(request);
  const [application, setApplication] = useState<ApplicationContentView | undefined>(undefined);
  const paymentEnabled: boolean = useMemo(
    () => !!(application && application.status === ApplicationStatus.PendingPayment),
    [application]
  );

  const editEnabled: boolean = useMemo(
    () =>
      !!(
        application &&
        (application.status === ApplicationStatus.Draft || application.status === ApplicationStatus.InformationRequired)
      ),
    [application]
  );

  useEffect(() => {
    if (pathname.includes(routes.payApplication)) {
      refetch();
    }
  }, [pathname, refetch]);

  useEffect(() => {
    if (data) {
      setApplication({
        ...data,
        status: getFormattedApplicationStatus(data.status),
        sections: getFormattedSections(data.sections),
      });
    }
  }, [data]);

  return (
    <Spinner loading={loading} fullPage={true}>
      {error && (
        <Alert severity={'error'} className={commonStyles.alert}>
          {error}
        </Alert>
      )}
      {application && !loading && (
        <>
          {pathname.includes(routes.payApplication) &&
            (paymentEnabled ? <ApplicationPaymentPageView data={application} /> : <NotFoundPage />)}
          {pathname.includes(routes.editApplication) &&
            (editEnabled ? <EditApplicationPageView data={application} refetch={refetch} /> : <NotFoundPage />)}
          {pathname.includes(routes.viewApplication) && <ViewApplicationPageView data={application} />}
        </>
      )}
    </Spinner>
  );
};
export default ApplicationPage;
