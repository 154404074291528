import { Checkbox, MenuItem, TextField, ListItemText } from '@mui/material';
import { Alert } from '@mui/material';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import ComponentProps from 'store/types/ComponentProps';
import { getInputLoadingProps } from 'util/Layout';
import EventService from 'services/api/EventService';
import SelectOption from 'store/types/SelectOption';
import useRequest from 'hooks/useRequest';

import commonStyles from 'styles/common.module.scss';

interface EventSessionsCategoryFilterProps extends ComponentProps {
  value: string[];
  onChange: (newValue: string[]) => void;
}

const getSelectedLabel = (value: string[] = [], categoryList: SelectOption[] = []): React.ReactNode =>
  value.length ? (
    categoryList
      .filter(({ name }) => value.includes(name))
      .map(({ name }) => name)
      .join(', ')
  ) : (
    <span className={commonStyles.placeholder}>{'Select Track'}</span>
  );

const EventSessionsCategoryFilter: React.FunctionComponent<EventSessionsCategoryFilterProps> = ({
  value,
  onChange,
}) => {
  const { data, loading, error } = useRequest<SelectOption[]>(EventService.getEventSessionCategories);
  const [categoryList, setCategoryList] = useState<SelectOption[]>([]);

  useEffect(() => {
    if (data?.length) {
      setCategoryList(data);
    }
  }, [data]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value as unknown as string[]);
    },
    [onChange]
  );

  return error ? (
    <Alert severity={'error'} className={commonStyles.alert}>
      {error}
    </Alert>
  ) : (
    <>
      <h5 className={commonStyles.subTitle}>{'Track'}</h5>
      <TextField
        select={true}
        size={'small'}
        disabled={loading}
        onChange={handleChange}
        value={value}
        InputProps={getInputLoadingProps(loading)}
        SelectProps={{
          renderValue: (selected: any) => getSelectedLabel(selected || [], categoryList),
          multiple: true,
          MenuProps: { variant: 'menu' },
          displayEmpty: true,
        }}
      >
        {categoryList.map(({ name, id }: SelectOption) => (
          <MenuItem value={name} key={`session-category-${id}`}>
            <Checkbox checked={value.includes(name)} color={'primary'} size={'small'} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};
export default EventSessionsCategoryFilter;
