import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import authClient from 'services/AuthService';
import { getDefaultHomeRoute } from 'util/Route';
import { ConfigContext } from 'components/ConfigGuard';
import { UserContext } from 'components/UserGuard';

const RedirectPage: React.FunctionComponent = () => {
  const { replace } = useHistory();
  const { search } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { enabledModules } = useContext(ConfigContext);
  const { setCurrentUser } = useContext(UserContext);
  const defaultHomeRoute: string = useMemo(() => getDefaultHomeRoute(enabledModules), [enabledModules]);

  useEffect(() => {
    const params: URLSearchParams = new URLSearchParams(search);
    const idToken: string = params.get('id_token') || '';
    const sessionState: string = params.get('session_state') || '';
    const returnUrl: string = atob(params.get('state') || '') || defaultHomeRoute;

    if (idToken) {
      if (authClient.isAuthenticated()) {
        authClient.setAuthCookies();
      }
      authClient.setAuthCookies({ idToken, sessionState });
    }
    replace(returnUrl);
  }, [search, replace, defaultHomeRoute, setCurrentUser, enqueueSnackbar]);

  return <></>;
};
export default RedirectPage;
