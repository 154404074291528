import { httpRequestWrapper } from 'util/Request';
import { HttpService } from 'services/HttpService';
import { PROFILE_API_URL, tenantConfig } from 'config';
import AuthService from 'services/AuthService';
import { PriorApproval, PriorApprovalForm } from 'store/types/PriorApproval';
import SelectOption from 'store/types/SelectOption';

type UserPriorApprovalsData = PriorApproval[];

class AdvocacyService {
  public static getUserPriorApprovals(): Promise<UserPriorApprovalsData> {
    return new Promise<UserPriorApprovalsData>(
      (resolve: (data: UserPriorApprovalsData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<UserPriorApprovalsData>(
          HttpService.get(`${PROFILE_API_URL}/profiles/advocacy/approvals`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static submitPriorApproval(data: PriorApprovalForm): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.post(`${PROFILE_API_URL}/profiles/advocacy/approvals`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getPriorApprovalsYears(): Promise<SelectOption[]> {
    return new Promise<SelectOption[]>((resolve: (data: SelectOption[]) => void, reject: (error: string) => void) => {
      httpRequestWrapper<SelectOption[]>(
        HttpService.get(`${PROFILE_API_URL}/profiles/advocacy/approvals/years`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }
}

export default AdvocacyService;
