import React, { useEffect, useMemo } from 'react';
import { FormControl, FormControlLabel, RadioGroup, Radio, FormHelperText, Grid } from '@mui/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormProps, getRequiredValidationRule, getValidationProps } from 'util/Form';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { ApplicationQuestionOption, ApplicationQuestionView } from 'store/types/ApplicationQuestion';
import ExplanationQuestionFormItem from '../ExplanationQuestionFormItem';

import pageStyles from '../ApplicationQuestionsFormSection.module.scss';

interface RadioQuestionFormItemProps extends FormProps {
  question: ApplicationQuestionView;
}

const RadioQuestionFormItem: React.FunctionComponent<RadioQuestionFormItemProps> = ({ question, disabled }) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { fieldName, options = [], required } = question;
  const { error, helperText } = getValidationProps(fieldName, errors);
  const radioValue = useWatch({ control, name: fieldName });
  const optionExplanation: ApplicationQuestionOption | undefined = useMemo(
    () => options.find(({ includeExplanation, value }) => !!includeExplanation && value === radioValue),
    [options, radioValue]
  );

  useEffect(() => {
    const selectedOption = options.find(({ isSelected }) => isSelected);

    if (selectedOption) {
      const { value } = selectedOption;

      setValue(fieldName, value, { shouldValidate: true });
    }
  }, [options, setValue, fieldName]);

  return (
    <Grid {...defaultGridContainerProps}>
      <Grid {...defaultGridItemProps}>
        <FormControl component={'fieldset'} fullWidth={true} required={required} error={error} disabled={disabled}>
          <Controller
            render={({ field }) => (
              <RadioGroup {...field} row={true}>
                {options.map(({ name, value }) => (
                  <FormControlLabel
                    key={`${fieldName}-${value}`}
                    value={value}
                    control={<Radio color={'primary'} />}
                    label={name}
                  />
                ))}
              </RadioGroup>
            )}
            name={fieldName}
            control={control}
            rules={{
              required: getRequiredValidationRule('your answer', true, required),
            }}
            defaultValue={''}
          />
          {helperText && <FormHelperText className={pageStyles.helperText}>{helperText}</FormHelperText>}
        </FormControl>
      </Grid>
      {optionExplanation && (
        <Grid {...defaultGridItemProps}>
          <ExplanationQuestionFormItem
            parentFieldName={fieldName}
            required={required}
            defaultValue={optionExplanation.explanation}
            disabled={disabled}
          />
        </Grid>
      )}
    </Grid>
  );
};
export default RadioQuestionFormItem;
