import React, { useCallback, useState } from 'react';
import EventSpeakerModal from '../EventSpeakerModal';
import EventSpeaker from 'store/types/events/EventSpeaker';
import Card from 'components/shared/Card';
import RecordVoiceOver from '@mui/icons-material/RecordVoiceOver';

import styles from './EventSpeakersSection.module.scss';
import rowStyles from '../EventInfoRow/EventInfoRow.module.scss';

interface EventSpeakersSectionProps {
  data: EventSpeaker[];
}

const EventSpeakersSection: React.FunctionComponent<EventSpeakersSectionProps> = ({ data }) => {
  const [selectedSpeaker, setSelectedSpeaker] = useState<EventSpeaker>(data[0]);
  const [speakerModalOpen, setSpeakerModalOpen] = useState<boolean>(false);

  const handleSpeakerModalOpen = useCallback(
    (selectedSpeaker: EventSpeaker) => () => {
      setSelectedSpeaker(selectedSpeaker);
      setSpeakerModalOpen(true);
    },
    []
  );

  const handleSpeakerModalClose = useCallback(() => {
    setSpeakerModalOpen(false);
  }, []);

  return (
    <>
      <Card className={styles.additionInfoBox}>
        <span className={styles.title}>
          <RecordVoiceOver className={rowStyles.icon} />
          {'Speaker(s): '}
        </span>
        <ul>
          {data?.map((speaker) => (
            <li key={`event-speaker-${speaker.id}`} className={styles.speaker}>
              <span className={styles.value} onClick={handleSpeakerModalOpen(speaker)}>
                {speaker.firstName} {speaker.lastName}
                {speaker.organization && ` - ${speaker.organization}`}
              </span>
            </li>
          ))}
        </ul>
      </Card>
      <EventSpeakerModal data={selectedSpeaker} open={speakerModalOpen} onClose={handleSpeakerModalClose} />
    </>
  );
};
export default EventSpeakersSection;
