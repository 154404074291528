import React, { useMemo } from 'react';
import CompanyService from 'services/api/CompanyService';
import useRequest from 'hooks/useRequest';
import Alert from '@mui/material/Alert';
import Spinner from 'components/shared/Spinner';
import { BillingAddress } from 'store/types/Address';
import AddressesPageView from 'components/addresses/AddressesPageView';
import { CompanySelectOption } from 'util/Company';

import commonStyles from 'styles/common.module.scss';

interface CompanyAddressesPageProps {
  selectedCompany?: CompanySelectOption;
}

const CompanyAddressesPage: React.FunctionComponent<CompanyAddressesPageProps> = ({ selectedCompany }) => {
  const companyAddressesRequest = useMemo(
    () => (selectedCompany?.id ? () => CompanyService.getCompanyAddresses(selectedCompany?.id) : undefined),
    [selectedCompany]
  );

  const { data, loading, error, refetch } = useRequest<BillingAddress[]>(companyAddressesRequest);

  return (
    <Spinner loading={loading} transparent={false}>
      {error ? (
        <Alert severity={'error'} className={commonStyles.alert}>
          {error}
        </Alert>
      ) : (
        data && <AddressesPageView data={data} refetch={refetch} selectedCompany={selectedCompany} />
      )}
    </Spinner>
  );
};
export default CompanyAddressesPage;
