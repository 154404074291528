import StatusLabel from 'components/shared/StatusLabel';
import React from 'react';
import { BasePaymentData, OrderPaymentData } from 'services/api/PaymentService';
import PaymentMethodType from 'store/enums/PaymentMethodType';
import PaymentStatus from 'store/enums/PaymentStatus';
import CollectJSResponse from 'store/types/CollectJSResponse';
import { BaseAddressFormValues, PaymentFormValues, PaymentItemsFormValues } from 'store/types/FormValues';
import Payment, { MultipleCompaniesOrderPayment, OrderPayment } from 'store/types/Payment';
import { PaymentAchData, PaymentCardData } from 'store/types/PaymentMethodsData';
import PaymentSummaryItem from 'store/types/PaymentSummaryItem';
import AmericanExpressIcon from 'assets/icons/cardAmericanExpress.png';
import DinersClubIcon from 'assets/icons/cardDinersClub.png';
import DiscoverIcon from 'assets/icons/cardDiscover.png';
import MasterCardIcon from 'assets/icons/cardMasterCard.png';
import VisaIcon from 'assets/icons/cardVisa.png';
import PaymentMethodCardType from 'store/enums/PaymentMethodCardType';
import PaymentMethodStatus from 'store/enums/PaymentMethodStatus';
import PaymentMethod, { CardPaymentMethodDetails } from 'store/types/PaymentMethod';
import OrderType from 'store/enums/OrderType';
import paymentMethodStatusConfig from 'store/configs/PaymentMethodStatusConfig';
import paymentStatusConfig from 'store/configs/PaymentStatusConfig';

export const defaultBaseAddressFormValues: BaseAddressFormValues = {
  useHomeAddress: true,
  billingAddress: undefined,
};

export const defaultPaymentFormValues: PaymentFormValues = {
  useHomeAddress: true,
  saveMethod: false,
  paymentMethodValid: false,
  paymentMethodType: PaymentMethodType.Card,
  autoRenewal: false,
  billingAddress: undefined,
  paymentMethodId: undefined,
  totalPrice: 0,
};

export const preselectedOrdersStatePropName = 'orderIds';

export const DISCOUNT_LABEL = 'Discount';
export const CREDITS_LABEL = 'Credits';

export const getPrice = (value = 0): string =>
  `$${Intl.NumberFormat(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value)}`;

export const getFormattedNumber = (value = 0): string =>
  `${Intl.NumberFormat(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value)}`;

export const getPaymentStatus = (status?: PaymentStatus): PaymentStatus => status || PaymentStatus.Processing;

export const getPaymentStatusLabel = (status: PaymentStatus = PaymentStatus.Processing): React.ReactNode => {
  const { theme = 'grey', name = '' } = paymentStatusConfig[status];

  return <StatusLabel theme={theme} status={name} />;
};

export const getPaymentMethodStatusLabel = (status: PaymentMethodStatus): React.ReactNode => {
  const { theme = 'grey', name = '' } = paymentMethodStatusConfig[status];

  return <StatusLabel theme={theme} status={name} />;
};

export const getFormattedOrdersList = (
  list: OrderPayment[] = [],
  unpaidOrdersOnly = false,
  salesOrders = false
): OrderPayment[] => {
  const formattedList: OrderPayment[] = list.map((payment: OrderPayment) => ({
    ...payment,
    status: getPaymentStatus(payment.status),
    invoiceName:
      payment.invoiceName || (salesOrders ? `Sale Order ${payment.orderNumber}` : `Invoice ${payment.orderNumber}`),
  }));
  const formattedsalesOrders: OrderPayment[] = formattedList.filter(({ type }) => type === OrderType.SalesOrd);
  const formattedInvoices: OrderPayment[] = formattedList.filter(({ type }) => type === OrderType.CustInvc);
  return !unpaidOrdersOnly
    ? formattedList.filter(
        ({ status }) =>
          status === PaymentStatus.FullyBilled ||
          status === PaymentStatus.Paid ||
          status === PaymentStatus.PendingFulfillment
      )
    : salesOrders
    ? formattedsalesOrders.filter(({ status }) => status === PaymentStatus.PendingBilling)
    : formattedInvoices.filter(({ status }) => status !== PaymentStatus.Paid);
};

export const getCardPaymentData = ({ token, card }: CollectJSResponse): PaymentCardData => {
  return {
    paymentToken: token,
    cardBin: card.bin,
    cardExpDate: card.exp,
    cardType: card.type,
    cardId: card.number,
  };
};

export const getPaymentMethodStatus = (paymentMethod: PaymentMethod): PaymentMethodStatus => {
  const { isDefault, details } = paymentMethod;
  const { cardExpDate } = details as CardPaymentMethodDetails;
  const currentDateYear = new Date().getFullYear();
  const currentDateMonth = new Date().getMonth() + 1;

  if (cardExpDate) {
    const expiredDateYear = Number(cardExpDate.substring(2)) + 2000;
    const expiredDateMonth = Number(cardExpDate.substring(0, 2));
    if (
      expiredDateYear < currentDateYear ||
      (expiredDateYear === currentDateYear && expiredDateMonth < currentDateMonth)
    ) {
      return PaymentMethodStatus.Expired;
    }
  }
  return isDefault ? PaymentMethodStatus.Default : PaymentMethodStatus.Active;
};

export const getAchPaymentData = ({ token, check }: CollectJSResponse): PaymentAchData => {
  return {
    paymentToken: token,
    accountNumber: check.account,
    accountName: check.name,
    routingCode: check.aba,
  };
};

export const getPaymentData = (
  paymentForm: PaymentFormValues,
  collectJSResponse?: CollectJSResponse
): BasePaymentData => {
  const {
    paymentMethodType,
    paymentMethodId,
    useHomeAddress,
    billingAddress,
    saveMethod,
    discountId,
    poNumber,
    credits,
    totalPrice,
  } = paymentForm;
  let result: BasePaymentData = {
    useHomeAddress,
    billingAddress,
    saveMethod,
    paymentMethodId,
    discountId,
    poNumber,
    credits,
  };

  if (collectJSResponse) {
    if (paymentMethodType === PaymentMethodType.Ach) {
      result = {
        ...result,
        ach: getAchPaymentData(collectJSResponse),
      };
    } else if (paymentMethodType === PaymentMethodType.Card) {
      result = {
        ...result,
        creditCard: getCardPaymentData(collectJSResponse),
      };
    }
  } else if (paymentMethodType === PaymentMethodType.Check) {
    result = {
      ...result,
      check: {
        type: 'check',
      },
    };
  } else if (paymentMethodType === PaymentMethodType.Postpone) {
    result = {
      ...result,
      billMe: {
        type: 'billme',
      },
    };
  } else if (!totalPrice && credits) {
    result = {
      ...result,
      creditMemo: {
        type: 'creditMemo',
      },
    };
  }
  return result;
};

export const donationsAmountSelected = (donations: PaymentItemsFormValues): boolean =>
  !Object.keys(donations).length || Object.keys(donations).every((key: string) => donations[key].price !== undefined);

export const paymentFormInvalid = ({
  useHomeAddress,
  totalPrice = 0,
  billingAddress,
  paymentMethodValid,
}: PaymentFormValues): boolean =>
  (!useHomeAddress && billingAddress === undefined) || (totalPrice > 0 && !paymentMethodValid);

export const shouldValidatePaymentMethod = ({
  paymentMethodType,
  paymentMethodId,
  totalPrice,
}: PaymentFormValues): boolean =>
  !(paymentMethodType === PaymentMethodType.Check || paymentMethodType === PaymentMethodType.Postpone) &&
  !(totalPrice === 0 || !!paymentMethodId);

export const getSummaryTotalPrice = (summaryList: PaymentSummaryItem[] = []): number =>
  summaryList.reduce((sum, { name, price = 0 }) => {
    if (name === DISCOUNT_LABEL || name === CREDITS_LABEL) {
      const result = sum - price;

      return result > 0 ? result : 0;
    } else {
      return sum + price;
    }
  }, 0);
export const getFormattedPayment = (payment: Payment, isProcessing?: boolean): Payment => ({
  ...payment,
  status: getPaymentStatus(isProcessing ? undefined : payment.status),
});

export const getPaymentCardTypeConfig = (type: PaymentMethodCardType | string): { icon?: string; text: string } => {
  switch (type) {
    case PaymentMethodCardType.Visa:
      return { icon: VisaIcon, text: 'Visa' };
    case PaymentMethodCardType.Mastercard:
      return { icon: MasterCardIcon, text: 'MasterCard' };
    case PaymentMethodCardType.Discover:
      return { icon: DiscoverIcon, text: 'Discover' };
    case PaymentMethodCardType.Amex:
      return { icon: AmericanExpressIcon, text: 'American Express' };
    case PaymentMethodCardType.Diners:
      return { icon: DinersClubIcon, text: 'Diners Club' };
    case PaymentMethodCardType.Jcb:
      return { icon: undefined, text: 'JCB' };
    case PaymentMethodCardType.Maestro:
      return { icon: undefined, text: 'Maestro' };
    default:
      return { icon: undefined, text: type };
  }
};

export const getInvoiceModalDescription = (status?: PaymentStatus, defaultDescription?: boolean): React.ReactNode => {
  const description = 'Thank you, your payment has been successfully processed.';

  if (defaultDescription || !status) {
    return description;
  }
  switch (status) {
    case PaymentStatus.Paid:
      return 'Thank you, your payment has been successfully processed. Please see invoice details below.';
    case PaymentStatus.Unpaid:
    case PaymentStatus.Overdue:
      return (
        <>
          <span>{'Thank you for your order. '}</span>
          <span>{'Please see invoice details below. '}</span>
        </>
      );
    case PaymentStatus.PendingBilling:
      return (
        <>
          <span>{'Thank you for your order. '}</span>
          <span>{'Please see sales order details below. '}</span>
        </>
      );
    default:
      return description;
  }
};

export const getInvoicesData = (
  invoices: OrderPayment[],
  isMultipleCompanies?: boolean,
  salesOrders?: boolean
): OrderPaymentData['invoices'] => {
  if (isMultipleCompanies) {
    const result: MultipleCompaniesOrderPayment[] = [];
    invoices.forEach(({ ownerId, id }) => {
      if (!result.length && ownerId) {
        result.push({
          companyId: ownerId,
          invoicesId: salesOrders ? undefined : [id],
          salesOrdersId: salesOrders ? [id] : undefined,
        });
      } else {
        for (let i = 0; i < result.length; i++) {
          const { companyId, invoicesId } = result[i];
          if (ownerId === companyId) {
            invoicesId?.push(id);
            break;
          } else if (i === result.length - 1 && ownerId) {
            result.push({
              companyId: ownerId,
              invoicesId: salesOrders ? undefined : [id],
              salesOrdersId: salesOrders ? [id] : undefined,
            });
            break;
          }
        }
      }
    });
    return result;
  }
  return invoices.map(({ id }) => id);
};
