import { Divider, Grid } from '@mui/material';
import Alert from '@mui/material/Alert';
import classNames from 'classnames';
import PaymentDetailsModal from 'components/payments/PaymentDetailsModal';
import FormFooter from 'components/shared/FormFooter';
import Spinner from 'components/shared/Spinner';
import { useSnackbar } from 'notistack';
import React, { Reducer, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';
import { PaymentFormValues, UserFormValues } from 'store/types/FormValues';
import Payment, { OrderPayment } from 'store/types/Payment';
import useRequest from 'hooks/useRequest';
import CollectJSResponse from 'store/types/CollectJSResponse';
import {
  getFormattedPayment,
  getPaymentData,
  getPrice,
  paymentFormInvalid,
  shouldValidatePaymentMethod,
} from 'util/Payment';
import PaymentService, { BasePaymentData, ExpressPaymentData } from 'services/api/PaymentService';
import { CONFIRM_EMAIL_FIELD_NAME } from 'store/configs/FormFieldNames';
import { defaultFormProps, getAddressFormValues } from 'util/Form';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import { emptyUserFormValues } from 'util/User';
import PaymentMethodSection from 'components/payments/PaymentMethodSection';
import AddressFormSection from 'components/shared/AddressFormSection';
import PublicPageLayout from 'components/layout/PublicPageLayout';
import ExpressPaymentUserSection from './ExpressPaymentUserSection';
import ExpressPaymentOrderSection from './ExpressPaymentOrderSection';
import reducer, {
  initialState,
  ExpressPaymentPageAction,
  ExpressPaymentPageState,
  ExpressPaymentPageActionType,
} from './ExpressPaymentPageReducer';
import Card from 'components/shared/Card';
import routes from 'store/configs/Routes';
import { ConfigContext } from 'components/ConfigGuard';
import { SiteConfigView } from 'store/types/SiteConfig';

import commonStyles from 'styles/common.module.scss';
import styles from './ExpressPaymentPage.module.scss';

const defaultProfileFormValues: UserFormValues = {
  ...emptyUserFormValues,
  [CONFIRM_EMAIL_FIELD_NAME]: '',
};

const ExpressPaymentPage: React.FunctionComponent = () => {
  const {
    modulesSettings: {
      payments: { invoices },
    },
  }: SiteConfigView = useContext(ConfigContext);
  const { expressCode = '' } = useParams<{ expressCode: string }>();
  const { pathname } = useLocation();
  const isSalesOrder: boolean = useMemo(() => pathname.includes(routes.expressSaleOrderPayment), [pathname]);
  const { enqueueSnackbar } = useSnackbar();
  const orderRequest = useMemo(
    () =>
      isSalesOrder
        ? () => PaymentService.getExpressSalesOrder(expressCode)
        : () => PaymentService.getExpressInvoice(expressCode),
    [expressCode, isSalesOrder]
  );
  const { data, loading, error } = useRequest<OrderPayment>(orderRequest);
  const profileForm = useForm<UserFormValues>({ ...defaultFormProps, defaultValues: defaultProfileFormValues });
  const [{ paymentForm, payment, submitLoading, selectedOrder }, dispatch] = useReducer<
    Reducer<ExpressPaymentPageState, ExpressPaymentPageAction>
  >(reducer, initialState);
  const { formState, getValues } = profileForm;
  const { totalPrice } = paymentForm;
  const submitButtonDisabled = !selectedOrder || !formState.isValid || paymentFormInvalid(paymentForm);

  useEffect(() => {
    dispatch({
      type: ExpressPaymentPageActionType.UpdatePaymentForm,
      payload: {
        paymentForm: {
          summaryList: selectedOrder
            ? [
                {
                  name: selectedOrder.invoiceName || selectedOrder.orderNumber,
                  price: selectedOrder.amountRemaining,
                },
              ]
            : [],
          totalPrice: selectedOrder ? selectedOrder.amountRemaining : 0,
        },
      },
    });
  }, [isSalesOrder, selectedOrder]);

  useEffect(() => {
    if (data) {
      dispatch({
        type: ExpressPaymentPageActionType.UpdateSelectedOrder,
        payload: { selectedOrder: data },
      });
    }
  }, [data]);

  const makePayment = useCallback(
    (collectJSResponse?: CollectJSResponse) => {
      const { firstName, lastName, address, email }: UserFormValues = getValues();
      const paymentFormValues: PaymentFormValues = {
        ...paymentForm,
        billingAddress: getAddressFormValues(address),
        useHomeAddress: false,
      };
      const basePaymentData: BasePaymentData = getPaymentData(paymentFormValues, collectJSResponse);
      const paymentData: ExpressPaymentData = {
        ...basePaymentData,
        firstName,
        lastName,
        email,
        expressCode,
      };
      (isSalesOrder
        ? PaymentService.makeExpressSalesOrderPayment(expressCode, paymentData)
        : PaymentService.makeExpressInvoicePayment(expressCode, paymentData)
      )
        .then((newPayment: Payment) => {
          enqueueSnackbar('Payment successfully confirmed', { variant: 'success' });
          dispatch({
            type: ExpressPaymentPageActionType.OpenPaymentConfirmation,
            payload: {
              payment: getFormattedPayment(newPayment),
            },
          });
        })
        .catch((errorMessage: string) => {
          dispatch({ type: ExpressPaymentPageActionType.SetLoading, payload: { submitLoading: false } });
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        });
    },
    [getValues, paymentForm, expressCode, isSalesOrder, enqueueSnackbar]
  );

  const handleFormSubmit = () => {
    dispatch({ type: ExpressPaymentPageActionType.SetLoading, payload: { submitLoading: true } });
    if (shouldValidatePaymentMethod(paymentForm)) {
      (window as any).CollectJS.startPaymentRequest();
    } else {
      makePayment();
    }
  };

  const handlePaymentComplete = (response: CollectJSResponse) => {
    makePayment(response);
  };

  const handlePaymentChange = useCallback((formValues: Partial<PaymentFormValues>) => {
    dispatch({
      type: ExpressPaymentPageActionType.UpdatePaymentForm,
      payload: { paymentForm: formValues },
    });
  }, []);

  return (
    <PublicPageLayout title={'Make a Payment'} hasFooter={true}>
      <Spinner fullPage={true} loading={loading}>
        {error ? (
          <Alert severity={'error'} className={commonStyles.alert}>
            {error}
          </Alert>
        ) : selectedOrder ? (
          <Spinner loading={submitLoading}>
            <FormProvider {...profileForm}>
              <Grid {...defaultGridContainerProps}>
                <Grid {...defaultGridItemProps}>
                  <h3 className={commonStyles.subTitle}>{'Order'}</h3>
                  <ExpressPaymentOrderSection order={selectedOrder} />
                  <Divider className={commonStyles.gridItemDivider} />
                </Grid>
                <Grid {...defaultGridItemProps}>
                  <h3 className={commonStyles.subTitle}>{'Contact Information'}</h3>
                  <Grid {...defaultGridContainerProps}>
                    <FormProvider {...profileForm}>
                      <ExpressPaymentUserSection />
                    </FormProvider>
                  </Grid>
                  <Divider className={commonStyles.gridItemDivider} />
                </Grid>
                <Grid {...defaultGridItemProps}>
                  <h3 className={commonStyles.subTitle}>{'Billing Information'}</h3>
                  <Grid {...defaultGridContainerProps}>
                    <AddressFormSection />
                  </Grid>
                  <Divider
                    className={classNames(commonStyles.gridItemDivider, { [commonStyles.hidden]: totalPrice === 0 })}
                  />
                </Grid>
                <Grid {...defaultGridItemProps} className={classNames({ [commonStyles.hidden]: totalPrice === 0 })}>
                  <Grid {...defaultGridContainerProps} className={styles.paymentSection}>
                    <Grid {...defaultGridItemProps} md={6}>
                      <h3 className={commonStyles.subTitle}>{'Payment Information'}</h3>
                      <PaymentMethodSection
                        form={paymentForm}
                        onChange={handlePaymentChange}
                        onPaymentComplete={handlePaymentComplete}
                        saveMethodCheckboxDisabled={true}
                        saveMethodCheckboxVisible={false}
                        paymentConfig={invoices}
                      />
                    </Grid>
                    <Grid {...defaultGridItemProps} md={6}>
                      <Card className={styles.cardSummary}>
                        <div className={styles.summary}>
                          <span>{'Balance Due: '}</span>
                          <span>{getPrice(totalPrice)}</span>
                        </div>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <FormFooter
                maxWidth={'lg'}
                onSubmit={profileForm.handleSubmit(handleFormSubmit)}
                submitButtonName={'Complete Payment'}
                submitButtonDisabled={submitButtonDisabled}
                className={classNames({ [commonStyles.hidden]: totalPrice === 0 })}
              />
            </FormProvider>
          </Spinner>
        ) : null}
      </Spinner>
      {!!payment && (
        <PaymentDetailsModal
          title={'Payment Confirmation'}
          isOrderPaymentConfirmation={true}
          open={!!payment}
          payment={payment}
          actionButtonsEnabled={false}
        />
      )}
    </PublicPageLayout>
  );
};
export default ExpressPaymentPage;
