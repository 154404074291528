import { Button, Grid } from '@mui/material';
import Alert from '@mui/material/Alert';
import React, { useCallback, useContext, useState } from 'react';
import CommitteeService from 'services/api/CommitteeService';
import useRequest from 'hooks/useRequest';
import JoinCommitteeModal from 'components/communities/JoinCommitteeModal';
import Add from '@mui/icons-material/Add';
import { defaultGridContainerProps, getPageTitle } from 'util/Layout';
import { Community } from 'store/types/Community';
import SiteModule from 'store/enums/SiteModule';
import { ConfigContext } from 'components/ConfigGuard';
import CommitteesPageView from './CommitteesPageView';
import Spinner from 'components/shared/Spinner';

import commonStyles from 'styles/common.module.scss';

const CommitteesPage: React.FunctionComponent = () => {
  const { sidebar } = useContext(ConfigContext);
  const [joinModalOpen, setJoinModalOpen] = useState<boolean>(false);
  const { data, loading, error, refetch } = useRequest<Community[]>(CommitteeService.getCommittees);

  const handleJoinButtonClick = useCallback(() => {
    setJoinModalOpen(true);
  }, []);

  const handleJoinModalClose = useCallback(() => {
    setJoinModalOpen(false);
  }, []);

  return (
    <>
      <h1 className={commonStyles.pageTitle}>{getPageTitle(sidebar, SiteModule.Committees) || 'Committees'}</h1>
      <Spinner loading={loading} transparent={false}>
        {error ? (
          <Alert severity={'error'} className={commonStyles.alert}>
            {error}
          </Alert>
        ) : (
          <Grid {...defaultGridContainerProps} justifyContent={'flex-end'}>
            <Grid item={true}>
              <Button
                color={'secondary'}
                variant={'contained'}
                onClick={handleJoinButtonClick}
                startIcon={<Add />}
                className={commonStyles.uppercase}
              >
                {'Join Committee'}
              </Button>
            </Grid>
            <CommitteesPageView data={data || []} />
          </Grid>
        )}
      </Spinner>
      {joinModalOpen && (
        <JoinCommitteeModal open={joinModalOpen} onClose={handleJoinModalClose} refetch={refetch} data={data || []} />
      )}
    </>
  );
};
export default CommitteesPage;
