import Payment, { OrderPayment } from 'store/types/Payment';
import { PaymentFormValues } from 'store/types/FormValues';
import { defaultPaymentFormValues } from 'util/Payment';
import { FilterConfig } from 'store/types/Table';
import { ALL_PROGRAMS_OPTION } from 'util/Company';

export enum MakePaymentPageActionType {
  UpdatePaymentForm = 'UpdatePaymentForm',
  SetSubmitLoading = 'SetSubmitLoading',
  SetOrdersList = 'SetOrdersList',
  UpdateSelectedOrdersList = 'UpdateSelectedOrdersList',
  OpenPaymentConfirmation = 'OpenPaymentConfirmation',
  ClosePaymentConfirmation = 'ClosePaymentConfirmation',
  UpdateFilter = 'UpdateFilter',
  SetFirstPaymentFlag = 'SetFirstPaymentFlag',
}

export interface MakePaymentPageState {
  ordersList: OrderPayment[];
  paymentForm: PaymentFormValues;
  selectedOrders: OrderPayment[];
  submitLoading?: boolean;
  isFirstPayment?: boolean;
  payment?: Payment;
  filter: FilterConfig<OrderPayment>;
}

export interface MakePaymentPageAction {
  type: MakePaymentPageActionType;
  payload: Partial<Omit<MakePaymentPageState, 'paymentForm'>> & {
    paymentForm?: Partial<PaymentFormValues>;
  };
}

export const initialState: MakePaymentPageState = {
  ordersList: [],
  paymentForm: defaultPaymentFormValues,
  selectedOrders: [],
  isFirstPayment: true,
  payment: undefined,
  filter: { programId: ALL_PROGRAMS_OPTION },
};

const reducer = (state: MakePaymentPageState, { type, payload }: MakePaymentPageAction): MakePaymentPageState => {
  if (type === MakePaymentPageActionType.SetSubmitLoading) {
    const { submitLoading = false } = payload;

    return { ...state, submitLoading };
  }

  if (type === MakePaymentPageActionType.SetFirstPaymentFlag) {
    const { isFirstPayment } = payload;

    return { ...state, isFirstPayment };
  }

  if (type === MakePaymentPageActionType.SetOrdersList) {
    const { ordersList = [], isFirstPayment } = payload;

    return {
      ...state,
      ordersList,
      selectedOrders: isFirstPayment ? ordersList : [],
    };
  }

  if (type === MakePaymentPageActionType.UpdatePaymentForm) {
    const { paymentForm = {} } = payload;

    return { ...state, paymentForm: { ...state.paymentForm, ...paymentForm } };
  }

  if (type === MakePaymentPageActionType.UpdateSelectedOrdersList) {
    const { selectedOrders = [] } = payload;

    return { ...state, selectedOrders };
  }

  if (type === MakePaymentPageActionType.OpenPaymentConfirmation) {
    const { payment } = payload;

    return { ...state, submitLoading: false, payment };
  }

  if (type === MakePaymentPageActionType.ClosePaymentConfirmation) {
    return { ...state, payment: undefined };
  }

  if (type === MakePaymentPageActionType.UpdateFilter) {
    const { filter = {} } = payload;
    const newFilter = { ...state.filter, ...filter };

    return {
      ...state,
      filter: newFilter,
    };
  }

  return state;
};

export default reducer;
