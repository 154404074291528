import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, FormControlLabel, Grid, Alert, Skeleton } from '@mui/material';
import { Controller, useForm, FormProvider, useWatch } from 'react-hook-form';
import { defaultFormProps } from 'util/Form';
import {
  defaultGridContainerProps,
  defaultGridItemProps,
  defaultSnackbarErrorProps,
  getButtonLoadingProps,
} from 'util/Layout';
import Modal from 'components/shared/Modal';
import { ModalProps } from 'store/types/ComponentProps';
import { getISODateString } from 'util/Format';
import moment from 'moment-timezone';
import DateFormItem from 'components/shared/DateFormItem';
import Company, { CompanyPaymentDetails } from 'store/types/Company';
import { StatementData, Statement } from 'store/types/Payment';
import { getPrice } from 'util/Payment';
import { useSnackbar } from 'notistack';
import CompanyService from 'services/api/CompanyService';
import { Moment } from 'moment/moment';
import { ConfigContext } from 'components/ConfigGuard';
import UseRequestData from 'store/types/UseRequestData';

import styles from './DownloadStatementModal.module.scss';
import commonStyles from 'styles/common.module.scss';

type DownloadStatementFormValues = Statement;

const defaultValues: DownloadStatementFormValues = {
  startDate: moment(new Date()).startOf('day').subtract(1, 'month').toDate(),
  statementDate: moment(new Date()).startOf('day').toDate(),
  openTransactionOnly: false,
};

const dateFutureErrorMessage = 'The date couldn’t be in the future';

interface DownloadStatementModalProps extends ModalProps, Pick<Company, 'id'>, UseRequestData<CompanyPaymentDetails> {}

const DownloadStatementModal: React.FunctionComponent<DownloadStatementModalProps> = ({
  onClose,
  open,
  id,
  data,
  loading,
  error,
}) => {
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const { isNccerTheme } = useContext(ConfigContext);
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<DownloadStatementFormValues>({ ...defaultFormProps, defaultValues });
  const {
    control,
    handleSubmit,
    formState: { isValid },
    trigger,
  } = form;
  const [startDate, statementDate] = useWatch({ control, name: ['startDate', 'statementDate'] });
  const minDateErrorMessage: string = useMemo(
    () =>
      statementDate && moment(statementDate).isAfter(moment(new Date()).startOf('day'))
        ? dateFutureErrorMessage
        : 'The end time must be later than the start date',
    [statementDate]
  );
  const maxDate: Moment = useMemo(
    () =>
      statementDate && moment(statementDate).isAfter(moment(new Date()).startOf('day'))
        ? moment(new Date()).startOf('day')
        : moment(statementDate),
    [statementDate]
  );
  const maxDateErrorMessage: string = useMemo(
    () =>
      (startDate && moment(startDate).isAfter(moment(new Date()).startOf('day'))) ||
      (statementDate && moment(statementDate).isAfter(moment(new Date()).startOf('day')))
        ? dateFutureErrorMessage
        : 'The start time must be earlier than end date',
    [startDate, statementDate]
  );

  useEffect(() => {
    trigger(['startDate', 'statementDate']);
  }, [startDate, statementDate, trigger]);

  const handleDownloadStatementSubmit = useCallback(
    (data) => {
      setSubmitLoading(true);

      const startDate = getISODateString(moment(data.startDate));
      const statementDate = getISODateString(moment(data.statementDate));

      if (startDate && statementDate) {
        const statementData: StatementData = {
          startDate,
          statementDate,
          openTransactionOnly: data.openTransactionOnly,
        };
        CompanyService.getStatement(id, statementData)
          .then(() => {
            setSubmitLoading(false);
          })
          .catch((errorMessage) => {
            enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
            setSubmitLoading(false);
          });
      }
    },
    [enqueueSnackbar, id]
  );

  const handleChange = useCallback((onChange) => (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.checked), []);

  return (
    <FormProvider {...form}>
      <Modal
        onClose={onClose}
        open={open}
        title={'Download Statement'}
        paperClassName={styles.statementModal}
        contentClassName={styles.statementModalContent}
        actions={
          <Button
            color={'secondary'}
            variant={'contained'}
            onClick={handleSubmit(handleDownloadStatementSubmit)}
            fullWidth={true}
            {...getButtonLoadingProps(submitLoading)}
            disabled={submitLoading || loading || !isValid || !!error}
          >
            {'Download'}
          </Button>
        }
      >
        {error ? (
          <Alert severity={'error'} className={commonStyles.alert}>
            {error}
          </Alert>
        ) : (
          <Grid {...defaultGridItemProps}>
            <Grid {...defaultGridContainerProps} spacing={2}>
              <Grid {...defaultGridItemProps} xs={3} className={styles.label}>
                {'Customer:'}
              </Grid>
              <Grid {...defaultGridItemProps} xs={9} className={styles.value}>
                {loading ? <Skeleton variant={'text'} width={200} /> : data && data.displayName}
              </Grid>
              <Grid {...defaultGridItemProps} xs={3} className={styles.label}>
                {'Balance:'}
              </Grid>
              <Grid {...defaultGridItemProps} xs={9} className={styles.value}>
                {loading ? <Skeleton variant={'text'} width={200} /> : getPrice(data && data.balance)}
              </Grid>
              <Grid {...defaultGridItemProps} xs={3} className={styles.label}>
                {'Start Date:'}
              </Grid>
              <Grid {...defaultGridItemProps} xs={7}>
                <DateFormItem
                  maxDateErrorMessage={maxDateErrorMessage}
                  fieldName={'startDate'}
                  size={'small'}
                  disabled={loading || submitLoading}
                  maxDate={maxDate || undefined}
                  className={styles.date}
                />
              </Grid>
              <Grid {...defaultGridItemProps} xs={3} className={styles.label}>
                {'End Date:'}
              </Grid>
              <Grid {...defaultGridItemProps} xs={7}>
                <DateFormItem
                  maxDateErrorMessage={maxDateErrorMessage}
                  minDateErrorMessage={minDateErrorMessage}
                  fieldName={'statementDate'}
                  size={'small'}
                  disabled={loading || submitLoading}
                  minDate={moment(startDate) || undefined}
                  maxDate={moment(new Date()).startOf('day')}
                  className={styles.date}
                />
              </Grid>
            </Grid>
            {!isNccerTheme && (
              <Grid {...defaultGridItemProps}>
                <FormControlLabel
                  label={'Show only open transactions'}
                  control={
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <Checkbox
                          color={'primary'}
                          onChange={handleChange(onChange)}
                          checked={value}
                          disabled={loading || submitLoading}
                        />
                      )}
                      control={control}
                      name={'openTransactionOnly'}
                    />
                  }
                />
              </Grid>
            )}
          </Grid>
        )}
      </Modal>
    </FormProvider>
  );
};
export default DownloadStatementModal;
