import { Grid, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Moment } from 'moment-timezone';
import React, { useCallback } from 'react';
import ComponentProps from 'store/types/ComponentProps';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';

import styles from './EventSessionsDateFilter.module.scss';
import commonStyles from 'styles/common.module.scss';

interface EventSessionsDateFilterProps extends ComponentProps {
  startDate: Moment | null;
  endDate: Moment | null;
  onChange: (startDate: Moment | null, endDate: Moment | null) => void;
}

const EventSessionsDateFilter: React.FunctionComponent<EventSessionsDateFilterProps> = ({
  startDate = null,
  endDate = null,
  onChange,
}) => {
  const handleStartDateChange = useCallback(
    (date?: any) => {
      onChange(date ? (date as Moment).startOf('day') : null, endDate);
    },
    [endDate, onChange]
  );

  const handleEndDateChange = useCallback(
    (date?: any) => {
      onChange(startDate, date ? (date as Moment).startOf('day') : null);
    },
    [startDate, onChange]
  );

  return (
    <Grid {...defaultGridContainerProps} spacing={1} alignItems={'flex-end'}>
      <Grid {...defaultGridItemProps} sm={true}>
        <h5 className={commonStyles.subTitle}>{'Start Date'}</h5>
        <DatePicker
          views={['month', 'year', 'day']}
          value={startDate}
          OpenPickerButtonProps={{ className: styles.keyboardButton }}
          onChange={handleStartDateChange}
          renderInput={(props) => <TextField {...props} fullWidth={true} size={'small'} />}
        />
      </Grid>
      <Grid {...defaultGridItemProps} sm={'auto'}>
        <span className={styles.divider}>{'~'}</span>
      </Grid>
      <Grid {...defaultGridItemProps} sm={true}>
        <h5 className={commonStyles.subTitle}>{'End Date'}</h5>
        <DatePicker
          views={['month', 'year', 'day']}
          minDate={startDate || undefined}
          value={endDate}
          OpenPickerButtonProps={{ className: styles.keyboardButton }}
          onChange={handleEndDateChange}
          renderInput={(props) => <TextField {...props} fullWidth={true} size={'small'} />}
        />
      </Grid>
    </Grid>
  );
};
export default EventSessionsDateFilter;
