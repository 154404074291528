import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { Grid, TextField, Button } from '@mui/material';
import MakePaymentComponentProps from 'store/types/MakePaymentComponentProps';
import {
  defaultGridContainerProps,
  defaultGridItemProps,
  defaultSnackbarErrorProps,
  getButtonLoadingProps,
} from 'util/Layout';
import PaymentService from 'services/api/PaymentService';
import { DiscountItem } from 'store/types/Payment';
import { DISCOUNT_LABEL } from 'util/Payment';

import styles from './PaymentDiscountSection.module.scss';

const PaymentDiscountSection: React.FunctionComponent<MakePaymentComponentProps> = ({ form, onChange }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [discountCode, setDiscountCode] = useState<string>('');
  const { summaryList } = form;

  const handleDiscountChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setDiscountCode(e.target.value);
  }, []);

  const handleSubmit = useCallback(() => {
    setSubmitLoading(true);
    PaymentService.applyDiscountCode(discountCode)
      .then(({ id, amount }: DiscountItem) => {
        setSubmitLoading(false);
        enqueueSnackbar('Discount code successfully applied', { variant: 'success' });
        onChange({
          summaryList: [...(summaryList || []), { name: DISCOUNT_LABEL, price: amount }],
          discountId: id,
        });
      })
      .catch((errorMessage: string) => {
        setSubmitLoading(false);
        enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
      });
  }, [enqueueSnackbar, discountCode, onChange, summaryList]);

  return (
    <Grid {...defaultGridContainerProps} spacing={2}>
      <Grid {...defaultGridItemProps} xs={8}>
        <TextField
          label={'Discount Code'}
          disabled={submitLoading}
          value={discountCode}
          onChange={handleDiscountChange}
        />
      </Grid>
      <Grid {...defaultGridItemProps} xs={4}>
        <Button
          {...getButtonLoadingProps(submitLoading)}
          fullWidth={true}
          variant={'outlined'}
          className={styles.discountButton}
          disabled={!discountCode || submitLoading}
          onClick={handleSubmit}
        >
          {'Apply'}
        </Button>
      </Grid>
    </Grid>
  );
};
export default PaymentDiscountSection;
