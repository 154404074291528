import { FormHelperText, Grid, InputLabel } from '@mui/material';
import Alert from '@mui/material/Alert';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PaymentMethodType from 'store/enums/PaymentMethodType';
import CollectJSResponse from 'store/types/CollectJSResponse';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import Spinner from 'components/shared/Spinner';
import { DEFAULT_ERROR_MESSAGE } from 'util/Form';

import commonStyles from 'styles/common.module.scss';
import styles from './PaymentMethodForm.module.scss';

interface PaymentMethodFormProps {
  disabled?: boolean;
  type?: PaymentMethodType;
  onPaymentComplete: (response: CollectJSResponse) => void;
  onMethodValidChange: (valid: boolean) => void;
}

interface PaymentMethodFormState {
  [key: string]: {
    valid: boolean;
    message: string;
  };
}

const baseCollectJSConfig = {
  variant: 'inline',
  styleSniffer: false,
  googleFont: 'Montserrat:400',
  customCss: {
    color: 'rgba(0, 0, 0, 0.87)',
    height: '58px',
    'border-radius': '5px',
    'border-width': '1px',
    'border-color': 'rgba(0, 0, 0, 0.23)',
    'border-style': 'solid',
    'font-size': '18px',
    'line-height': '21px',
    'font-family': '"Montserrat", -apple-system, BlinkMacSystemFont, sans-serif',
    padding: '18px 14px',
    outline: 'none',
    'outline-color': 'transparent',
  },
  focusCss: {
    'border-width': '3px',
    'border-color': '#163832',
    'border-radius': '5px',
  },
  invalidCss: {
    color: '#f44336',
    'border-color': '#f44336',
  },
  fields: {
    ccnumber: {
      placeholder: '0000 0000 0000 0000',
      selector: '#ccnumber',
    },
    ccexp: {
      placeholder: '00 / 00',
      selector: '#ccexp',
    },
    cvv: {
      placeholder: '000',
      selector: '#cvv',
    },
    checkaccount: {
      selector: '#checkaccount',
      placeholder: '000000000',
    },
    checkaba: {
      selector: '#checkaba',
      placeholder: '000000000',
    },
    checkname: {
      selector: '#checkname',
      placeholder: 'Name',
    },
  },
};

const initialState = {
  ccnumber: { valid: false, message: '' },
  ccexp: { valid: false, message: '' },
  cvv: { valid: false, message: '' },
  checkname: { valid: false, message: '' },
  checkaba: { valid: false, message: '' },
  checkaccount: { valid: false, message: '' },
};

const cardFieldsConfig: string[] = ['ccnumber', 'ccexp', 'cvv'];
const achFieldsConfig: string[] = ['checkname', 'checkaba', 'checkaccount'];

// NOTE: should be used once per component
const PaymentMethodForm: React.FunctionComponent<PaymentMethodFormProps> = ({
  disabled = false,
  onPaymentComplete,
  onMethodValidChange,
  type = PaymentMethodType.Card,
}) => {
  const [state, setState] = useState<PaymentMethodFormState>(initialState);
  const [formValid, setFormValid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const formControlProps = useMemo(
    () => ({
      className: classNames(styles.formControl, { [styles.disabled]: disabled }),
    }),
    [disabled]
  );

  const validationCallback = useCallback((field: string, valid: boolean, message: string) => {
    setState((prevState) => ({ ...prevState, [field]: { valid, message: message === 'Success' ? '' : message } }));
  }, []);

  const callback = useCallback(
    (response: CollectJSResponse) => {
      onPaymentComplete(response);
    },
    [onPaymentComplete]
  );

  const collectJSConfig = {
    ...baseCollectJSConfig,
    paymentType: type === PaymentMethodType.Ach ? 'ck' : 'cc',
    validationCallback,
    callback,
    fieldsAvailableCallback: () => {
      setLoading(false);
    },
    timeoutCallback: () => {
      setLoading(false);
      setError(DEFAULT_ERROR_MESSAGE);
    },
  };

  useEffect(() => {
    setLoading(true);

    (window as any).CollectJS.configure(collectJSConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onMethodValidChange(formValid);
  }, [formValid, onMethodValidChange]);

  useEffect(() => {
    setState(initialState);
  }, [type, disabled]);

  useEffect(() => {
    const fieldsConfig: string[] = type === PaymentMethodType.Ach ? achFieldsConfig : cardFieldsConfig;

    setFormValid(Object.keys(state).every((key: string) => !fieldsConfig.includes(key) || state[key].valid));
    (window as any).CollectJS.config.validationCallback = validationCallback;
  }, [state, validationCallback, type]);

  useEffect(() => {
    (window as any).CollectJS.config.callback = callback;
  }, [callback]);

  const getInputField = useCallback(
    (fieldName: string, label: string): React.ReactNode => {
      const inputState = state[fieldName] || { message: undefined };

      return (
        <div {...formControlProps}>
          <InputLabel htmlFor={fieldName} className={styles.label} required={true}>
            {label}
          </InputLabel>
          <div id={fieldName} />
          {inputState.message && <FormHelperText error={true}>{inputState.message}</FormHelperText>}
        </div>
      );
    },
    [formControlProps, state]
  );

  return (
    <Spinner loading={loading} transparent={false}>
      {error && (
        <Alert severity={'error'} className={commonStyles.alert}>
          {error}
        </Alert>
      )}
      <Grid {...defaultGridContainerProps}>
        <Grid
          {...defaultGridItemProps}
          className={classNames({ [commonStyles.hidden]: type !== PaymentMethodType.Ach })}
        >
          {getInputField('checkname', 'Name on Account')}
        </Grid>
        <Grid
          {...defaultGridItemProps}
          className={classNames({ [commonStyles.hidden]: type !== PaymentMethodType.Ach })}
        >
          {getInputField('checkaba', 'Routing Code')}
        </Grid>
        <Grid
          {...defaultGridItemProps}
          className={classNames({ [commonStyles.hidden]: type !== PaymentMethodType.Ach })}
        >
          {getInputField('checkaccount', 'Account Number')}
        </Grid>
        <Grid
          {...defaultGridItemProps}
          className={classNames({ [commonStyles.hidden]: type !== PaymentMethodType.Card })}
        >
          {getInputField('ccnumber', 'Card Number')}
        </Grid>
        <Grid
          {...defaultGridItemProps}
          className={classNames({ [commonStyles.hidden]: type !== PaymentMethodType.Card })}
          sm={8}
        >
          {getInputField('ccexp', 'Expiration Date')}
        </Grid>
        <Grid
          {...defaultGridItemProps}
          className={classNames({ [commonStyles.hidden]: type !== PaymentMethodType.Card })}
          sm={4}
        >
          {getInputField('cvv', 'CVV')}
        </Grid>
      </Grid>
    </Spinner>
  );
};
export default PaymentMethodForm;
