import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useRequest from 'hooks/useRequest';
import UserEmployerTable from './UserEmployerTable';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { Button, Grid } from '@mui/material';
import { Alert } from '@mui/lab';
import Spinner from 'components/shared/Spinner';
import { UserEmployerAccessRequest, UserEmployerAccessRequestsData } from 'store/types/UserEmployerAccessRequest';
import UserEmployerService from 'services/api/UserEmployerService';
import { ParametersContext } from 'components/ParametersGuard';
import Table from 'components/shared/Table';
import { TableColumn } from 'store/types/Table';
import { getEmployerRequestStatusLabel } from 'util/User';
import CompanyAccessRequestStatus from 'store/enums/CompanyAccessRequestStatus';
import { UserContext } from 'components/UserGuard';
import RequestEmployerModal from './RequestEmployerModal';

import commonStyles from 'styles/common.module.scss';

interface UserEmployerPageViewProps {
  refetchCount: () => void;
}

const UserEmployerPageView: React.FunctionComponent<UserEmployerPageViewProps> = ({ refetchCount }) => {
  const userEmployersRequest = useRequest<UserEmployerAccessRequestsData>(UserEmployerService.getUserEmployer);
  const { data, loading, error, refetch } = userEmployersRequest;
  const { parentOrCustomCompanyName = '', refetchUser, userLoading, parentCompanyId } = useContext(UserContext);
  const [updateEmployerModalOpen, setUpdateEmployerModalOpen] = useState<boolean>(false);
  const {
    profile: { activeEmployerTableHeader, outgoingEmployerRequestsTableHeader, incomingEmployerRequestsTableHeader },
  } = useContext(ParametersContext);
  const activeUserEmployer: UserEmployerAccessRequest[] = useMemo(
    () =>
      parentOrCustomCompanyName && parentCompanyId
        ? [{ id: '', name: parentOrCustomCompanyName, status: CompanyAccessRequestStatus.Active }]
        : [],
    [parentCompanyId, parentOrCustomCompanyName]
  );

  useEffect(() => {
    refetchUser();
  }, [refetchUser]);

  const handleUpdateEmployerModalOpen = useCallback(() => {
    setUpdateEmployerModalOpen(true);
  }, []);

  const handleUpdateEmployerModalClose = useCallback(() => {
    setUpdateEmployerModalOpen(false);
  }, []);

  const refetchRequests = useCallback(() => {
    refetch();
    refetchCount();
  }, [refetch, refetchCount]);

  const columns: Array<TableColumn<UserEmployerAccessRequest>> = [
    {
      dataIndex: 'status',
      label: 'Status',
      sortable: true,
      align: 'left',
      render: (status) => getEmployerRequestStatusLabel(status),
    },
    {
      dataIndex: 'name',
      label: 'Company',
      sortable: true,
    },
  ];

  return (
    <>
      <Spinner loading={loading}>
        {error ? (
          <Alert severity={'error'} className={commonStyles.alert}>
            {error}
          </Alert>
        ) : (
          <Grid {...defaultGridContainerProps} justifyContent={'flex-end'}>
            <Grid {...defaultGridItemProps} xs={'auto'}>
              <Button
                color={'secondary'}
                variant={'contained'}
                className={commonStyles.uppercase}
                onClick={handleUpdateEmployerModalOpen}
              >
                {'Update My Employer'}
              </Button>
            </Grid>
            <Grid {...defaultGridItemProps}>
              <h3>{'Active Employer'}</h3>
              {activeEmployerTableHeader && <p dangerouslySetInnerHTML={{ __html: activeEmployerTableHeader }} />}
              <Table columns={columns} list={activeUserEmployer} loading={userLoading} />
            </Grid>
            <Grid {...defaultGridItemProps}>
              <h3>{'Company Requests'}</h3>
              {incomingEmployerRequestsTableHeader && (
                <p dangerouslySetInnerHTML={{ __html: incomingEmployerRequestsTableHeader }} />
              )}
              <UserEmployerTable {...userEmployersRequest} data={data?.incoming} refetch={refetchRequests} />
            </Grid>
            <Grid {...defaultGridItemProps}>
              <h3>{'My Requests'}</h3>
              {outgoingEmployerRequestsTableHeader && (
                <p dangerouslySetInnerHTML={{ __html: outgoingEmployerRequestsTableHeader }} />
              )}
              <UserEmployerTable {...userEmployersRequest} data={data?.outgoing} userRequests={true} />
            </Grid>
          </Grid>
        )}
      </Spinner>
      {data && (
        <RequestEmployerModal
          userRequests={data}
          open={updateEmployerModalOpen}
          onClose={handleUpdateEmployerModalClose}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default UserEmployerPageView;
