import React, { Reducer, SyntheticEvent, useCallback, useEffect, useReducer, useState } from 'react';
import Table from 'components/shared/Table';
import { SortConfig, TableColumn } from 'store/types/Table';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import classNames from 'classnames';
import { Grid, Button } from '@mui/material';
import Modal from 'components/shared/Modal';
import { useSnackbar } from 'notistack';
import reducer, {
  initialState,
  ManageEmployeeAccessTableAction,
  ManageEmployeeAccessTableActionType,
  ManageEmployeeAccessTableState,
} from './ManageEmployeeAccessTableReducer';
import { EMPTY_STRING_VALUE, getFormattedDate } from 'util/Format';
import StatusLabel from 'components/shared/StatusLabel';
import CompanyAccessRequestStatus from 'store/enums/CompanyAccessRequestStatus';
import companyAccessRequestStatusConfig from 'store/configs/CompanyAccessRequestStatusConfig';
import CompanyService from 'services/api/CompanyService';
import CompanyEmployeeAccessRequest from 'store/types/CompanyEmployeeAccessRequest';

import commonStyles from 'styles/common.module.scss';

const getEmployeeRequestStatusLabel = (status: CompanyAccessRequestStatus): React.ReactNode => {
  const { theme = 'grey', name = '' } = companyAccessRequestStatusConfig[status];

  return <StatusLabel theme={theme} status={name} />;
};

interface ManageEmployeeAccessTableProps {
  companyId: string;
  companyRequests?: boolean;
  data: CompanyEmployeeAccessRequest[];
  refetch: () => void;
}

const ManageEmployeeAccessTable: React.FunctionComponent<ManageEmployeeAccessTableProps> = ({
  data,
  refetch,
  companyId,
  companyRequests,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [{ sort, list = [], selectedItem, approveModalOpen, rejectModalOpen, cancelModalOpen }, dispatch] = useReducer<
    Reducer<ManageEmployeeAccessTableState, ManageEmployeeAccessTableAction>
  >(reducer, initialState);

  useEffect(() => {
    dispatch({
      type: ManageEmployeeAccessTableActionType.SetInitialList,
      payload: { initialList: data },
    });
  }, [data]);

  const handleSortChange = useCallback((newSort: SortConfig) => {
    dispatch({
      type: ManageEmployeeAccessTableActionType.UpdateSort,
      payload: { sort: newSort },
    });
  }, []);

  const handleRejectButtonClick = useCallback(
    (record) => () => {
      dispatch({
        type: ManageEmployeeAccessTableActionType.OpenRejectModal,
        payload: { selectedItem: record },
      });
    },
    []
  );

  const handleRejectModalClose = useCallback(() => {
    dispatch({
      type: ManageEmployeeAccessTableActionType.CloseRejectModal,
      payload: {},
    });
  }, []);

  const handleApproveButtonClick = useCallback(
    (record) => () => {
      dispatch({
        type: ManageEmployeeAccessTableActionType.OpenApproveModal,
        payload: { selectedItem: record },
      });
    },
    []
  );

  const handleApproveModalClose = useCallback(() => {
    dispatch({
      type: ManageEmployeeAccessTableActionType.CloseApproveModal,
      payload: {},
    });
  }, []);

  const handleCancelButtonClick = useCallback(
    (record) => () => {
      dispatch({
        type: ManageEmployeeAccessTableActionType.OpenCancelModal,
        payload: { selectedItem: record },
      });
    },
    []
  );

  const handleCancelModalClose = useCallback(() => {
    dispatch({
      type: ManageEmployeeAccessTableActionType.CloseCancelModal,
      payload: {},
    });
  }, []);

  const handleCancelRequestSubmit = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      if (selectedItem) {
        setLoading(true);
        CompanyService.cancelEmployeeAccessRequests(companyId, selectedItem.id)
          .then(() => {
            enqueueSnackbar('Request successfully canceled', { variant: 'success' });
            dispatch({
              type: ManageEmployeeAccessTableActionType.CloseCancelModal,
              payload: {},
            });
            setLoading(false);
            refetch();
          })
          .catch((errorMessage) => {
            setLoading(false);
            enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
          });
      }
    },
    [companyId, enqueueSnackbar, refetch, selectedItem]
  );

  const handleRejectRequestSubmit = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      if (selectedItem) {
        setLoading(true);
        CompanyService.rejectEmployeeAccessRequests(companyId, selectedItem.id)
          .then(() => {
            enqueueSnackbar('Request successfully rejected', { variant: 'success' });
            dispatch({
              type: ManageEmployeeAccessTableActionType.CloseRejectModal,
              payload: {},
            });
            setLoading(false);
            refetch();
          })
          .catch((errorMessage) => {
            setLoading(false);
            enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
          });
      }
    },
    [companyId, enqueueSnackbar, refetch, selectedItem]
  );

  const handleApproveRequestSubmit = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      if (selectedItem) {
        setLoading(true);
        CompanyService.approveEmployeeAccessRequests(companyId, selectedItem.id)
          .then(() => {
            enqueueSnackbar('Request successfully approved', { variant: 'success' });
            dispatch({
              type: ManageEmployeeAccessTableActionType.CloseApproveModal,
              payload: {},
            });
            setLoading(false);
            refetch();
          })
          .catch((errorMessage) => {
            setLoading(false);
            enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
          });
      }
    },
    [companyId, enqueueSnackbar, refetch, selectedItem]
  );

  const columns: Array<TableColumn<CompanyEmployeeAccessRequest>> = [
    {
      dataIndex: 'status',
      label: 'Status',
      sortable: true,
      align: 'center',
      render: (status) => getEmployeeRequestStatusLabel(status),
    },
    {
      dataIndex: 'name',
      label: 'Name',
      sortable: true,
    },
    {
      dataIndex: 'email',
      label: 'Email',
      sortable: true,
      hidden: !!companyRequests,
    },
    {
      dataIndex: 'requester',
      label: 'Requester',
      sortable: true,
      hidden: !companyRequests,
    },
    {
      dataIndex: 'approver',
      label: 'Approver',
      sortable: true,
      hidden: companyRequests,
      render: (_: any, record: CompanyEmployeeAccessRequest) =>
        record.status === CompanyAccessRequestStatus.Canceled || !record.approver
          ? EMPTY_STRING_VALUE
          : record.approver,
    },
    {
      dataIndex: 'requestedDate',
      label: 'Requested Date',
      align: 'center',
      render: (date) => (date ? getFormattedDate(date) : EMPTY_STRING_VALUE),
    },
    {
      label: 'Action',
      align: 'center',
      render: (_: any, record: CompanyEmployeeAccessRequest) =>
        record.status === CompanyAccessRequestStatus.Pending &&
        (companyRequests ? (
          <Button
            size={'small'}
            onClick={handleCancelButtonClick(record)}
            variant={'outlined'}
            className={classNames(commonStyles.dangerButtonOutlined)}
          >
            {'Cancel'}
          </Button>
        ) : (
          <Grid container={true} spacing={2} justifyContent={'center'}>
            <Grid {...defaultGridItemProps} sm={'auto'}>
              <Button size={'small'} onClick={handleApproveButtonClick(record)} variant={'outlined'}>
                {'Approve'}
              </Button>
            </Grid>
            <Grid {...defaultGridItemProps} sm={'auto'}>
              <Button
                size={'small'}
                onClick={handleRejectButtonClick(record)}
                variant={'outlined'}
                className={classNames(commonStyles.dangerButtonOutlined)}
              >
                {'Reject'}
              </Button>
            </Grid>
          </Grid>
        )),
    },
  ];

  return (
    <>
      <Grid {...defaultGridContainerProps} justifyContent={'center'}>
        <Grid {...defaultGridItemProps}>
          <Table
            columns={columns}
            list={list}
            sort={sort}
            onSortChange={handleSortChange}
            loading={loading}
            showPagination={true}
          />
        </Grid>
      </Grid>
      <Modal
        title={'Approve Request'}
        loading={loading}
        open={approveModalOpen}
        onClose={handleApproveModalClose}
        maxWidth={'sm'}
        actions={
          <>
            <Button color={'secondary'} variant={'outlined'} onClick={handleApproveModalClose}>
              {'Close'}
            </Button>
            <Button type={'submit'} variant={'contained'} onClick={handleApproveRequestSubmit}>
              {'Accept'}
            </Button>
          </>
        }
      >
        <p className={commonStyles.text}>{'Are you sure you want to approve request?'}</p>
      </Modal>
      <Modal
        title={'Reject Request'}
        loading={loading}
        open={rejectModalOpen}
        onClose={handleRejectModalClose}
        maxWidth={'sm'}
        actions={
          <>
            <Button color={'secondary'} variant={'outlined'} onClick={handleRejectModalClose}>
              {'Close'}
            </Button>
            <Button
              type={'submit'}
              variant={'contained'}
              onClick={handleRejectRequestSubmit}
              className={commonStyles.dangerButtonContained}
            >
              {'Confirm Action'}
            </Button>
          </>
        }
      >
        <p className={commonStyles.text}>{'Are you sure you want to reject request?'}</p>
      </Modal>
      <Modal
        title={'Cancel Request'}
        loading={loading}
        onClose={handleCancelModalClose}
        open={cancelModalOpen}
        maxWidth={'sm'}
        actions={
          <>
            <Button color={'secondary'} variant={'outlined'} onClick={handleCancelModalClose}>
              {'Close'}
            </Button>
            <Button
              type={'submit'}
              variant={'contained'}
              onClick={handleCancelRequestSubmit}
              className={commonStyles.dangerButtonContained}
            >
              {'Confirm Action'}
            </Button>
          </>
        }
      >
        <p className={commonStyles.text}>{'Are you sure you want to cancel request?'}</p>
      </Modal>
    </>
  );
};
export default ManageEmployeeAccessTable;
