import React from 'react';
import { Card, Button, CardContent, Grid } from '@mui/material';
import classNames from 'classnames';
import ComponentProps from 'store/types/ComponentProps';
import ApplicationType from 'store/types/ApplicationType';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import SectionHeader from 'components/shared/SectionHeader';

import styles from './ApplicationTypeCard.module.scss';

interface ApplicationTypeCardProps extends ComponentProps {
  data: ApplicationType;
  onClick: (data: ApplicationType) => void;
  selected?: boolean;
  limited?: boolean;
  customFreeButtonLabel?: string;
}

const ApplicationTypeCard: React.FunctionComponent<ApplicationTypeCardProps> = ({
  data,
  onClick,
  selected,
  customFreeButtonLabel,
  limited,
  className = '',
}) => {
  const { title, description, amountToPay } = data;

  const handleClick = () => {
    !limited && onClick(data);
  };

  return (
    <Card className={classNames(styles.card, { [styles.selected]: selected }, className)} onClick={handleClick}>
      {selected && <span className={styles.selectedLabel}>{'Selected'} </span>}
      <CardContent className={styles.content}>
        <Grid {...defaultGridContainerProps} alignItems={'flex-end'}>
          <Grid {...defaultGridItemProps} sm={8}>
            <SectionHeader>{title}</SectionHeader>
            <p dangerouslySetInnerHTML={{ __html: description }} />
          </Grid>
          <Grid {...defaultGridItemProps} sm={4}>
            <Button
              color={'secondary'}
              variant={'contained'}
              className={styles.button}
              fullWidth={true}
              disabled={!selected || limited}
              disableRipple={true}
            >
              {limited
                ? 'The limit has been reached for the number of times this form can be submitted'
                : amountToPay
                ? `$${amountToPay}`
                : customFreeButtonLabel || 'Free'}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default ApplicationTypeCard;
