import { Container } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import NccerLogo from 'assets/images/nccer/logo.png';
import GamsdLogo from 'assets/images/gamsd/logo.png';
import CompassLogo from 'assets/images/compass/logo.png';
import SnaLogo from 'assets/images/sna/logo.png';
import MemberplexLogo from 'assets/images/default/logo.svg';
import SmacnaLogo from 'assets/images/smacna/logo.png';
import TeamsEliteLogo from 'assets/images/teamsElite/logo.png';
import AcsLogo from 'assets/images/acs/logo-white.png';
import classNames from 'classnames';
import { ConfigContext } from 'components/ConfigGuard';
import { MembershipContext } from 'components/MembershipGuard';
import Avatar from 'components/shared/Avatar';
import { UserContext } from 'components/UserGuard';
import React, { useContext } from 'react';
import { getUserFullName } from 'util/Format';
import SiteModule from 'store/enums/SiteModule';

import styles from './Header.module.scss';

interface HeaderProps {
  collapsedView?: boolean;
}

const Header: React.FunctionComponent<HeaderProps> = ({ collapsedView = true }) => {
  const { userLoading = false, logoUrl, firstName, lastName, suffix, prefix, memberTypeName } = useContext(UserContext);
  const { membershipLoading = false } = useContext(MembershipContext);
  const {
    isNccerTheme,
    isGamsdTheme,
    isCompassTheme,
    isSnaTheme,
    isSmacnaTheme,
    isAcsTheme,
    isTeamsEliteTheme,
    enabledModules,
    modulesSettings: {
      nccer: { portalHeader },
    },
  } = useContext(ConfigContext);

  const userTextSection: React.ReactNode = (
    <div className={styles.userText}>
      <span className={styles.userName}>
        {userLoading ? (
          <Skeleton width={240} variant={'text'} className={styles.skeleton} />
        ) : (
          getUserFullName({ firstName, lastName, suffix, prefix })
        )}
      </span>
      {enabledModules.includes(SiteModule.Membership) &&
        (membershipLoading ? (
          <Skeleton width={200} variant={'text'} className={styles.skeleton} />
        ) : (
          !collapsedView &&
          memberTypeName && <span className={styles.userMembership}>{`Membership Type: ${memberTypeName}`}</span>
        ))}
    </div>
  );

  return (
    <>
      <header
        className={classNames(styles.header, {
          [styles.nccerHeader]: isNccerTheme,
          [styles.gamsdHeader]: isGamsdTheme,
          [styles.snaHeader]: isSnaTheme,
          [styles.teamsEliteHeader]: isTeamsEliteTheme,
          [styles.collapsedView]: collapsedView,
        })}
        data-testid={'header'}
      >
        <Container maxWidth={false} className={classNames(styles.content)}>
          {isNccerTheme ? (
            <div className={styles.nccerContainer}>
              <a href={'/'} className={styles.nccerLogo}>
                <img src={NccerLogo} alt={'logo'} />
              </a>
              <span className={styles.nccerTitle}>{portalHeader}</span>
            </div>
          ) : (
            <a href={'/'} className={styles.logo}>
              <img
                src={
                  isGamsdTheme
                    ? GamsdLogo
                    : isCompassTheme
                    ? CompassLogo
                    : isSnaTheme
                    ? SnaLogo
                    : isSmacnaTheme
                    ? SmacnaLogo
                    : isAcsTheme
                    ? AcsLogo
                    : isTeamsEliteTheme
                    ? TeamsEliteLogo
                    : MemberplexLogo
                }
                alt={'logo'}
              />
            </a>
          )}
          {!collapsedView && <Avatar src={logoUrl} loading={userLoading} darkBackground={true} />}
          {userTextSection}
        </Container>
      </header>
    </>
  );
};
export default Header;
