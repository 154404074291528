import classNames from 'classnames';
import React, { useEffect, useMemo } from 'react';
import { FormControl, FormControlLabel, RadioGroup, Radio, FormHelperText, Grid } from '@mui/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormProps, getRequiredValidationRule, getValidationProps } from 'util/Form';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { ApplicationQuestionOption, ApplicationQuestionView } from 'store/types/ApplicationQuestion';
import ApplicationReviewAnswerValue from 'store/enums/ApplicationReviewAnswerValue';
import ExplanationQuestionFormItem from '../ExplanationQuestionFormItem';

import pageStyles from '../ApplicationQuestionsFormSection.module.scss';

interface ReviewQuestionFormItemProps extends FormProps {
  question: ApplicationQuestionView;
}

const options: ApplicationQuestionOption[] = [
  {
    name: ApplicationReviewAnswerValue.Yes,
    value: ApplicationReviewAnswerValue.Yes,
    isSelected: false,
    includeExplanation: false,
  },
  {
    name: ApplicationReviewAnswerValue.No,
    value: ApplicationReviewAnswerValue.No,
    isSelected: false,
    includeExplanation: true,
  },
  {
    name: ApplicationReviewAnswerValue.Partial,
    value: ApplicationReviewAnswerValue.Partial,
    isSelected: false,
    includeExplanation: true,
  },
];

const ReviewQuestionFormItem: React.FunctionComponent<ReviewQuestionFormItemProps> = ({ question, disabled }) => {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const { fieldName, review } = question;
  const { error, helperText } = getValidationProps(fieldName, errors);
  const radioValue = useWatch({ control, name: fieldName });
  const optionExplanation: ApplicationQuestionOption | undefined = useMemo(
    () =>
      radioValue
        ? options.find(({ includeExplanation, value }) => !!includeExplanation && value === radioValue)
        : undefined,
    [radioValue]
  );

  useEffect(() => {
    if (review) {
      const { response = '' } = review;

      if (response) {
        setValue(fieldName, response);
      }
    }
  }, [review, setValue, fieldName, getValues]);

  return (
    <Grid {...defaultGridContainerProps}>
      <Grid {...defaultGridItemProps}>
        <FormControl component={'fieldset'} fullWidth={true} required={true} error={error} disabled={disabled}>
          <Controller
            render={({ field: { onChange, value } }) => (
              <RadioGroup onChange={onChange} value={value} row={true}>
                {options.map(({ name, value }) => (
                  <FormControlLabel
                    key={`${fieldName}-${value}`}
                    value={value}
                    control={<Radio color={'primary'} />}
                    label={name}
                  />
                ))}
              </RadioGroup>
            )}
            name={fieldName}
            control={control}
            rules={{
              required: getRequiredValidationRule('an option', true),
            }}
            defaultValue={''}
          />
          {helperText && <FormHelperText className={pageStyles.helperText}>{helperText}</FormHelperText>}
        </FormControl>
      </Grid>
      {optionExplanation && (
        <Grid {...defaultGridItemProps}>
          <h4 className={classNames(pageStyles.label, { [pageStyles.disabled]: disabled })}>
            {'Provide more information'}
          </h4>
          <ExplanationQuestionFormItem
            parentFieldName={fieldName}
            required={true}
            defaultValue={review && review.comment ? review.comment : ''}
            disabled={disabled}
          />
        </Grid>
      )}
    </Grid>
  );
};
export default ReviewQuestionFormItem;
