import React, { useContext, useMemo } from 'react';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { Grid } from '@mui/material';
import { ParametersContext } from 'components/ParametersGuard';
import { PriorApproval } from 'store/types/PriorApproval';
import PriorApprovalsTable from 'components/profile/AdvocacyPageView/PriorApprovalsTable';

interface CompanyAdvocacyPageViewProps {
  data: PriorApproval[];
}

const CompanyAdvocacyPageView: React.FunctionComponent<CompanyAdvocacyPageViewProps> = ({ data }) => {
  const {
    companyManagement: { activePriorApprovalTableHeader, inactivePriorApprovalTableHeader },
  } = useContext(ParametersContext);
  const activePriorApproval: PriorApproval[] = useMemo(
    () => data.filter(({ isActive }) => isActive).slice(0, 1),
    [data]
  );
  const inactivePriorApprovals: PriorApproval[] = useMemo(() => data.filter(({ isActive }) => !isActive), [data]);
  return (
    <Grid {...defaultGridContainerProps}>
      <Grid {...defaultGridItemProps}>
        <h3>{'Active Prior Approval'}</h3>
        {activePriorApprovalTableHeader && <p dangerouslySetInnerHTML={{ __html: activePriorApprovalTableHeader }} />}
        <PriorApprovalsTable data={activePriorApproval} isCompany={true} />
      </Grid>
      <Grid {...defaultGridItemProps}>
        <h3>{'Inactive Prior Approvals'}</h3>
        {inactivePriorApprovalTableHeader && (
          <p dangerouslySetInnerHTML={{ __html: inactivePriorApprovalTableHeader }} />
        )}
        <PriorApprovalsTable data={inactivePriorApprovals} isCompany={true} showPagination={true} />
      </Grid>
    </Grid>
  );
};

export default CompanyAdvocacyPageView;
