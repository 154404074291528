import React, { useContext } from 'react';
import Spinner from 'components/shared/Spinner';
import { UserContext } from 'components/UserGuard';
import { getPageTitle } from 'util/Layout';
import { ConfigContext } from 'components/ConfigGuard';
import SiteModule from 'store/enums/SiteModule';
import useRequest from 'hooks/useRequest';
import { BillingAddress } from 'store/types/Address';
import AddressesService from 'services/api/AddressesService';
import AddressesPageView from 'components/addresses/AddressesPageView';
import routes from 'store/configs/Routes';
import { useLocation } from 'react-router';
import Alert from '@mui/material/Alert';

import commonStyles from 'styles/common.module.scss';

const AddressesPage: React.FunctionComponent = () => {
  const { sidebar } = useContext(ConfigContext);
  const { pathname } = useLocation();
  const { userLoading = false } = useContext(UserContext);
  const { error, refetch, data, loading } = useRequest<BillingAddress[]>(AddressesService.getAddresses);

  return (
    <>
      {pathname === routes.addresses && (
        <h1 className={commonStyles.pageTitle}>{getPageTitle(sidebar, SiteModule.Addresses)}</h1>
      )}
      <Spinner loading={userLoading || loading} transparent={false}>
        {error ? (
          <Alert severity={'error'} className={commonStyles.alert}>
            {error}
          </Alert>
        ) : (
          data && <AddressesPageView data={data} refetch={refetch} />
        )}
      </Spinner>
    </>
  );
};
export default AddressesPage;
