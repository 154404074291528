import { ConfigSelectOption } from 'store/types/SelectOption';
import CommunityMemberStatus from 'store/enums/CommunityMemberStatus';

const communityMemberStatusConfig: Record<CommunityMemberStatus, ConfigSelectOption> = {
  [CommunityMemberStatus.Approved]: {
    name: 'Approved',
    theme: 'green',
  },
  [CommunityMemberStatus.Rejected]: {
    name: 'Rejected',
    theme: 'red',
  },
  [CommunityMemberStatus.Pending]: {
    name: 'Pending',
    theme: 'yellow',
  },
  [CommunityMemberStatus.Inactive]: {
    name: 'Inactive',
    theme: 'grey',
  },
};

export default communityMemberStatusConfig;
