import StaticSelectOption from 'store/types/StaticSelectOption';
import PaymentUserAccessLevel from 'store/enums/PaymentUserAccessLevel';

const paymentAccessLevelConfig: StaticSelectOption[] = [
  {
    text: 'Membership and Invoices',
    value: PaymentUserAccessLevel.Full,
  },
  {
    text: 'Membership Only',
    value: PaymentUserAccessLevel.Membership,
  },
  {
    text: 'Invoices Only',
    value: PaymentUserAccessLevel.Invoices,
  },
];

export default paymentAccessLevelConfig;
