import React, { useCallback, useState } from 'react';
import ExpenseReportPrintSection from 'components/expense/PrintExpenseReportHook/ExpenseReportPrintSection';
import Spinner from 'components/shared/Spinner';
import { ExpenseReport } from 'store/types/Expense';
import { printDocument } from 'util/Window';

const printFrameId = 'expense-report-print-frame';
const printContentId = 'expense-report-print-content';

const useExpenseReportPrint = (
  expenseReport?: ExpenseReport | null,
  printCallback?: () => void
): [() => void, React.ReactNode] => {
  const [loading, setLoading] = useState<boolean>(false);

  const handlePrintFinished = useCallback(() => {
    setLoading(false);
    if (printCallback) {
      printCallback();
    }
  }, [printCallback]);

  const printExpenseReport = useCallback(() => {
    if (expenseReport) {
      setLoading(true);
      printDocument(printContentId, printFrameId, handlePrintFinished);
    }
  }, [expenseReport, handlePrintFinished]);

  const printComponent = (
    <>
      <Spinner loading={loading} fullPage={true} />
      {expenseReport ? (
        <ExpenseReportPrintSection
          expenseReport={expenseReport}
          printFrameId={printFrameId}
          printContentId={printContentId}
        />
      ) : null}
    </>
  );

  return [printExpenseReport, printComponent];
};
export default useExpenseReportPrint;
