import { Observable } from 'rxjs';
import authClient from 'services/AuthService';
import { HttpError, HttpUtils } from 'services/HttpService';

export const httpRequestWrapper = <T>(
  request: Observable<T>,
  resolve: (data: T) => void,
  reject: (error: string) => void,
  shouldLogoutIfUnauthorized = true
): void => {
  request
    .toPromise()
    .then((data: T) => {
      resolve(data);
    })
    .catch((error: HttpError) => {
      if (error.code === 401 && shouldLogoutIfUnauthorized) {
        authClient.logout();
      }
      reject(HttpUtils.getErrorMessage(error));
    });
};
