import { Button } from '@mui/material';
import React from 'react';
import ApplicationContent from 'store/types/ApplicationContent';
import { getStringValue, STRING_DATE_FORMAT } from 'util/Format';
import ApplicationReviewType from 'store/enums/ApplicationReviewType';

import styles from './ApplicationReviewHeader.module.scss';

interface ApplicationReviewHeaderProps {
  data: ApplicationContent;
  onDecisionButtonClick?: () => void;
}

const ApplicationReviewHeader: React.FunctionComponent<ApplicationReviewHeaderProps> = ({
  data,
  onDecisionButtonClick,
}) => {
  return (
    <div className={styles.reviewHeader}>
      <div className={styles.reviewInfo}>
        <div className={styles.row}>
          <span className={styles.value}>{getStringValue(data.applicantName)}</span>
          <span>{'Applicant'}</span>
        </div>
        <div className={styles.row}>
          <span className={styles.value}>{getStringValue(data.submittedDate, STRING_DATE_FORMAT)}</span>
          <span>{'Application Date'}</span>
        </div>
      </div>
      <div className={styles.reviewInfo}>
        <div className={styles.row}>
          <span className={styles.value}>{getStringValue(data.review?.dueDate, STRING_DATE_FORMAT)}</span>
          <span>{'Review Due Date'}</span>
        </div>
        {data.reviewType !== ApplicationReviewType.Basic && (
          <div className={styles.row}>
            <span className={styles.value}>{getStringValue(data.review?.updatedDate, STRING_DATE_FORMAT)}</span>
            <span>{'Last Saved'}</span>
          </div>
        )}
        {onDecisionButtonClick && (
          <Button variant={'outlined'} onClick={onDecisionButtonClick} className={styles.decisionButton}>
            Enter Decision
          </Button>
        )}
      </div>
    </div>
  );
};
export default ApplicationReviewHeader;
