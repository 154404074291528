import { HttpService } from 'services/HttpService';
import { API_URL, conf, tenantConfig } from 'config';
import AuthService from 'services/AuthService';
import { httpRequestWrapper } from 'util/Request';
import AppEvent, { AppEventData } from 'store/types/AppEvent';
import FileInfo from 'store/types/FileInfo';
import { PublicUser } from 'store/types/User';
import { JoinedChapter, CommunityDetails } from 'store/types/Community';

type JoinedChaptersResponseData = JoinedChapter[];
type ChapterEventsResponseData = AppEvent[];

class ChapterService {
  public static getJoinedChapters(): Promise<JoinedChaptersResponseData> {
    return new Promise<JoinedChaptersResponseData>(
      (resolve: (data: JoinedChaptersResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<JoinedChaptersResponseData>(
          HttpService.get(`${API_URL}/chapters/my`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getChapterDetails(chapterId: string): Promise<CommunityDetails> {
    return new Promise<CommunityDetails>(
      (resolve: (data: CommunityDetails) => void, reject: (error: string) => void) => {
        httpRequestWrapper<CommunityDetails>(
          HttpService.get(`${API_URL}/chapters/${chapterId}`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getChapterMember(chapterId: string, userId: string): Promise<PublicUser> {
    return new Promise<PublicUser>((resolve: (data: any) => void, reject: (error: string) => void) => {
      httpRequestWrapper<PublicUser>(
        HttpService.get(`${API_URL}/chapters/${chapterId}/members/${userId}`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getChapterMembersRoster(chapterId: string, roles: string[] = [], chapterName: string): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.getFileDownload(`${API_URL}/chapters/${chapterId}/members/download`, {
          queryParams: { role: roles },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
            [conf.filenameHeader]: `${chapterName}.csv`,
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getChapterEvents(id: string): Promise<ChapterEventsResponseData> {
    return new Promise<ChapterEventsResponseData>(
      (resolve: (data: ChapterEventsResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<ChapterEventsResponseData>(
          HttpService.get(`${API_URL}/chapters/${id}/events`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getChapterFile(chapterId: string, { id, name }: FileInfo): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.getFileDownload(`${API_URL}/chapters/${chapterId}/files/${id}`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
            [conf.filenameHeader]: name,
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getChapterEventFile(eventId: string): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.getFileDownload(`${API_URL}/chapters/events/${eventId}/download`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
            [conf.filenameHeader]: `chapters-event-${eventId}.ics`,
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static deleteChapterFile(chapterId: string, fileId: string): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.delete(`${API_URL}/chapters/${chapterId}/files/${fileId}`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static uploadChapterFile(chapterId: string, file: File): Promise<null> {
    const uploadedData = new FormData();

    uploadedData.append('file', file);
    return new Promise((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.post(`${API_URL}/chapters/${chapterId}/files`, {
          data: uploadedData,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static createChapterEvent(chapterId: string, data: AppEventData): Promise<null> {
    return new Promise((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.post(`${API_URL}/chapters/${chapterId}/events`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static updateChapterEvent(chapterId: string, eventId: string, data: AppEventData): Promise<null> {
    return new Promise((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.put(`${API_URL}/chapters/${chapterId}/events/${eventId}`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static deleteChapterEvent(chapterId: string, eventId: string): Promise<null> {
    return new Promise((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.delete(`${API_URL}/chapters/${chapterId}/events/${eventId}`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getChapterReport(chapterId: string, reportOptionId: string, fileName: string): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.getFileDownload(`${API_URL}/chapters/${chapterId}/reports/${reportOptionId}`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
            [conf.filenameHeader]: fileName,
          },
        }),
        resolve,
        reject
      );
    });
  }
}
export default ChapterService;
