import { Button } from '@mui/material';
import Modal from 'components/shared/Modal';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { ModalProps } from 'store/types/ComponentProps';
import AddressesService from 'services/api/AddressesService';
import { defaultSnackbarErrorProps } from 'util/Layout';

import commonStyles from 'styles/common.module.scss';
import CompanyService from '../../../services/api/CompanyService';

interface DeleteAddressModalProps extends ModalProps {
  addressId: string;
  onSubmit: () => void;
  companyId?: string;
}

const DeleteAddressModal: React.FunctionComponent<DeleteAddressModalProps> = ({
  addressId,
  open,
  onClose,
  onSubmit,
  companyId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const handleDelete = useCallback(() => {
    setLoading(true);

    (companyId ? CompanyService.deleteCompanyAddress(addressId, companyId) : AddressesService.deleteAddress(addressId))
      .then(() => {
        setLoading(false);
        enqueueSnackbar('Address successfully deleted', { variant: 'success' });
        onSubmit();
      })
      .catch((errorMessage: string) => {
        setLoading(false);
        enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
      });
  }, [companyId, addressId, enqueueSnackbar, onSubmit]);

  return (
    <Modal
      title={'Delete Address'}
      open={open}
      maxWidth={'sm'}
      loading={loading}
      actions={
        <>
          <Button color={'secondary'} variant={'outlined'} onClick={onClose} disabled={loading}>
            {'Cancel'}
          </Button>
          <Button
            type={'submit'}
            variant={'contained'}
            className={commonStyles.dangerButtonContained}
            disabled={loading}
            onClick={handleDelete}
          >
            {'Delete'}
          </Button>
        </>
      }
    >
      <p className={commonStyles.text}>
        You are about to delete a saved address. Orders already shipping to this address will not be affected. If you
        delete your default address, your profile address will replace it as the new default for shipping and billing.
      </p>
    </Modal>
  );
};
export default DeleteAddressModal;
