import classNames from 'classnames';
import React from 'react';
import { Card as MuiCard, CardProps as MuiCardProps, CardContent, CardHeader } from '@mui/material';
import Spinner from 'components/shared/Spinner';

import styles from './Card.module.scss';

interface CardProps extends Pick<MuiCardProps, 'onClick' | 'className'> {
  title?: React.ReactNode;
  headerAction?: React.ReactNode;
  bordered?: boolean;
  loading?: boolean;
  headerClassName?: string;
  contentClassName?: string;
  spinnerClassName?: string;
}

const Card: React.FunctionComponent<CardProps> = ({
  children,
  title = null,
  headerAction = null,
  bordered = false,
  loading = false,
  headerClassName = '',
  contentClassName = '',
  spinnerClassName = '',
  className = '',
  onClick,
}) => (
  <Spinner loading={loading} className={spinnerClassName}>
    <MuiCard
      className={classNames(bordered ? styles.borderedCard : styles.card, className)}
      onClick={onClick}
      data-testid={'card'}
    >
      {(title || headerAction) && (
        <CardHeader
          title={title}
          action={headerAction}
          disableTypography={true}
          className={classNames(styles.header, headerClassName)}
          classes={{ content: styles.title, action: styles.action }}
          data-testid={'card-header'}
        />
      )}
      <CardContent className={classNames(styles.content, contentClassName)} data-testid={'card-content'}>
        {children}
      </CardContent>
    </MuiCard>
  </Spinner>
);
export default Card;
