import { Button, Grid, MenuItem, TextField } from '@mui/material';
import Modal from 'components/shared/Modal';
import React, { SyntheticEvent, useCallback, useContext, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { defaultFormProps, getRequiredValidationRule, getValidationProps } from 'util/Form';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import { ModalProps } from 'store/types/ComponentProps';
import CompanyService from 'services/api/CompanyService';
import { useSnackbar } from 'notistack';
import JobFunction from 'store/types/JobFunction';
import CompanyUser from 'store/types/CompanyUser';
import UserNameFormSection from 'components/profile/UserNameFormSection';
import EmailFormItem from 'components/profile/EmailFormItem';
import PhoneFormItem from 'components/profile/PhoneFormItem';
import { CompanyUserFormValues } from 'store/types/FormValues';
import jobFunction from 'store/types/JobFunction';
import { UserContext } from 'components/UserGuard';
import TitleFormItem from 'components/profile/TitleFormItem';

import commonStyles from 'styles/common.module.scss';
import styles from './ViewCompanyUserModal.module.scss';

interface ViewCompanyUserModalProps extends ModalProps {
  data: CompanyUser;
  jobFunctionsList?: JobFunction[];
  refetch: () => void;
  companyId: string;
}

type ViewCompanyUserFormValues = CompanyUserFormValues;

const getDefaultValues = (
  { firstName, lastName, phone, email, jobFunctionName, middleInitial, title }: CompanyUser,
  jobFunctionsList?: jobFunction[]
): ViewCompanyUserFormValues => ({
  firstName,
  lastName,
  middleInitial,
  phone,
  email,
  jobFunctionId: jobFunctionsList?.find(({ name }) => name === jobFunctionName)?.id || '',
  title,
});

const ViewCompanyUserModal: React.FunctionComponent<ViewCompanyUserModalProps> = ({
  open,
  onClose,
  refetch,
  data,
  companyId,
  jobFunctionsList,
}) => {
  const defaultValues = useMemo(() => getDefaultValues(data, jobFunctionsList), [data, jobFunctionsList]);
  const { id } = useContext(UserContext);
  const form = useForm<ViewCompanyUserFormValues>({ ...defaultFormProps, defaultValues });
  const { handleSubmit, formState, control, reset } = form;
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const handleClose = useCallback(() => {
    onClose();
    reset(defaultValues);
  }, [defaultValues, onClose, reset]);

  const handleDeleteModalOpen = useCallback(() => {
    setDeleteModalOpen(true);
  }, []);

  const handleDeleteModalClose = useCallback(() => {
    setDeleteModalOpen(false);
  }, []);

  const handleDeleteButtonClick = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      if (data) {
        setLoading(true);
        (jobFunctionsList?.length
          ? CompanyService.removeCompanyAdmin(companyId, data.id)
          : CompanyService.removeCompanyEmployee(companyId, data.id)
        )
          .then(() => {
            enqueueSnackbar('User successfully deleted', { variant: 'success' });
            setLoading(false);
            refetch();
            handleClose();
          })
          .catch((errorMessage) => {
            setLoading(false);
            enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
          });
      }
    },
    [companyId, data, enqueueSnackbar, handleClose, jobFunctionsList?.length, refetch]
  );

  const handleUpdateUserSubmit = useCallback(
    (formValues: CompanyUserFormValues) => {
      if (data) {
        setLoading(true);
        const { profileId, id } = data;
        (jobFunctionsList?.length
          ? CompanyService.updateCompanyAdminUser(formValues, companyId, profileId, id)
          : CompanyService.updateCompanyEmployeeUser(formValues, companyId, profileId, id)
        )
          .then(() => {
            enqueueSnackbar('User successfully updated', { variant: 'success' });
            setLoading(false);
            refetch();
            handleClose();
          })
          .catch((errorMessage) => {
            setLoading(false);
            enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
          });
      }
    },
    [companyId, data, enqueueSnackbar, handleClose, jobFunctionsList, refetch]
  );

  return (
    <>
      <FormProvider {...form}>
        <Modal
          open={open}
          onClose={handleClose}
          maxWidth={'sm'}
          title={'View Account Contact'}
          loading={loading}
          actions={
            <>
              <div>
                <Button color={'secondary'} variant={'outlined'} onClick={handleClose}>
                  {'Cancel'}
                </Button>
                {id !== data?.profileId && (
                  <Button
                    color={'secondary'}
                    variant={'outlined'}
                    onClick={handleDeleteModalOpen}
                    className={styles.submitButton}
                  >
                    {'Remove Contact'}
                  </Button>
                )}
              </div>
              <Button
                color={'secondary'}
                variant={'contained'}
                type={'submit'}
                disabled={!formState.isValid || !formState.isDirty}
                onClick={handleSubmit(handleUpdateUserSubmit)}
              >
                {'Save'}
              </Button>
            </>
          }
        >
          <Grid {...defaultGridContainerProps}>
            <Grid {...defaultGridItemProps}>
              <p className={styles.id}>{`Customer ID: ${data.entityId}`}</p>
            </Grid>
            <UserNameFormSection middleNameVisible={true} />
            <Grid {...defaultGridItemProps} md={6}>
              <EmailFormItem disabled={true} optional={true} />
            </Grid>
            <Grid {...defaultGridItemProps} md={6}>
              <PhoneFormItem />
            </Grid>
            <Grid {...defaultGridItemProps} md={6}>
              <TitleFormItem />
            </Grid>
            {!!jobFunctionsList?.length && (
              <Grid {...defaultGridItemProps} md={6}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      {...getValidationProps('jobFunctionName')}
                      label={'Portal Role'}
                      required={true}
                      select={true}
                      disabled={id === data?.profileId}
                    >
                      {jobFunctionsList.map(({ id, name }: JobFunction) => (
                        <MenuItem value={id} key={`job-function-${id}`}>
                          {name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                  name={'jobFunctionId'}
                  control={control}
                  rules={{ required: getRequiredValidationRule('job function', true) }}
                />
              </Grid>
            )}
          </Grid>
        </Modal>
      </FormProvider>
      <Modal
        title={'Remove Contact'}
        loading={loading}
        open={deleteModalOpen}
        maxWidth={'xs'}
        actions={
          <>
            <Button color={'secondary'} variant={'outlined'} onClick={handleDeleteModalClose}>
              {'Cancel'}
            </Button>
            <Button
              type={'submit'}
              variant={'contained'}
              onClick={handleDeleteButtonClick}
              className={commonStyles.dangerButtonContained}
            >
              {'Remove'}
            </Button>
          </>
        }
      >
        <p className={commonStyles.text}>{'Are you sure you want to remove this Contact?'}</p>
      </Modal>
    </>
  );
};

export default ViewCompanyUserModal;
