import React from 'react';
import { Divider } from '@mui/material';
import classNames from 'classnames';
import { CREDITS_LABEL, DISCOUNT_LABEL, getPrice } from 'util/Payment';
import MakePaymentComponentProps from 'store/types/MakePaymentComponentProps';
import Card from 'components/shared/Card';

import styles from './PaymentSummaryCard.module.scss';

type PaymentSummaryCardProps = Pick<MakePaymentComponentProps, 'form'>;

const PaymentSummaryCard: React.FunctionComponent<PaymentSummaryCardProps> = ({ form }) => {
  const { summaryList = [], totalPrice = 0 } = form;

  return (
    <Card>
      <ul>
        {summaryList.map(({ name, price }, index) => (
          <li key={`summary-item-${index}`} className={styles.summaryRow}>
            <span>{`${name}:`}</span>
            <span className={styles.price}>{`${name === DISCOUNT_LABEL || name === CREDITS_LABEL ? '-' : ''}${getPrice(
              price
            )}`}</span>
          </li>
        ))}
        <Divider className={styles.divider} />
        <li className={classNames(styles.summaryRow, styles.totalSummaryRow)}>
          <span>{'Order Total: '}</span>
          <span>{getPrice(totalPrice)}</span>
        </li>
      </ul>
    </Card>
  );
};
export default PaymentSummaryCard;
