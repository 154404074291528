import React, { Reducer, useCallback, useEffect, useReducer } from 'react';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import { Button, Grid, IconButton } from '@mui/material';
import reducer, {
  ExpenseItemTableAction,
  ExpenseItemTableActionType,
  ExpenseItemTableState,
  initialState,
} from 'components/expense/ExpenseReportTable/ExpenseItemTable/ExpenseItemTableReducer';
import ShowMoreText from 'components/shared/ShowMoreText';
import Table from 'components/shared/Table';
import TableCountLabel from 'components/shared/TableCountLabel';
import ExpenseReportStatus from 'store/enums/ExpenseReportStatus';
import { ExpenseItem, ExpenseReport } from 'store/types/Expense';
import { SortConfig, TableColumn } from 'store/types/Table';
import { getFullDate } from 'util/Format';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { getPrice } from 'util/Payment';

import styles from 'components/expense/Expense.module.scss';

interface ExpenseItemTableProps {
  data: ExpenseReport;
  onModalOpen?: (expenseId: string) => void;
  onDeleteExpense?: (expenseId: string) => void;
  onDownloadReceipt?: (expenseReportId: string, expenseId: string, filenameexpenseId: string) => void;
}

const ExpenseItemTable: React.FunctionComponent<ExpenseItemTableProps> = ({
  data,
  onModalOpen,
  onDeleteExpense,
  onDownloadReceipt,
}) => {
  const [{ initialList = [], list = [], sort }, dispatch] = useReducer<
    Reducer<ExpenseItemTableState, ExpenseItemTableAction>
  >(reducer, initialState);

  useEffect(() => {
    if (data?.expenses?.length) {
      const payload = data.expenses;

      dispatch({
        type: ExpenseItemTableActionType.SetInitialList,
        payload: { initialList: payload },
      });
    }
  }, [data]);

  const handleSortChange = useCallback((newSort: SortConfig) => {
    dispatch({ type: ExpenseItemTableActionType.UpdateSort, payload: { sort: newSort } });
  }, []);

  const getExpenseActionButton = ({ id }: ExpenseItem) => {
    switch (data.status) {
      case ExpenseReportStatus.Draft:
      case ExpenseReportStatus.NeedInfo:
        return (
          <div className={styles.buttonGroup}>
            {onModalOpen && (
              <Button
                color={'primary'}
                variant={'outlined'}
                className={styles.tableButton}
                onClick={() => onModalOpen(id)}
              >
                {'Edit'}
              </Button>
            )}
            {onDeleteExpense && (
              <Button
                color={'warning'}
                variant={'outlined'}
                className={styles.tableButton}
                disabled={data?.expenses.length === 1}
                onClick={() => onDeleteExpense(id)}
              >
                {'Remove'}
              </Button>
            )}
          </div>
        );
      default:
        return (
          <>
            {onModalOpen && (
              <Button
                color={'secondary'}
                variant={'outlined'}
                className={styles.tableButton}
                onClick={() => onModalOpen(id)}
              >
                {'View'}
              </Button>
            )}
          </>
        );
    }
  };

  const columns: Array<TableColumn<ExpenseItem>> = [
    {
      dataIndex: 'id',
      label: '',
      sortable: true,
      verticalAlign: 'top',
    },
    {
      dataIndex: 'date',
      label: 'Date',
      align: 'center',
      sortable: true,
      verticalAlign: 'top',
      render: (date: string) => getFullDate(date),
    },
    {
      dataIndex: 'categoryName',
      label: 'Category',
      sortable: true,
      verticalAlign: 'top',
    },
    {
      dataIndex: 'amount',
      label: 'Amount',
      sortable: true,
      verticalAlign: 'top',
      render: (amount: number) => getPrice(amount),
    },
    {
      dataIndex: 'memo',
      label: 'Description',
      sortable: true,
      verticalAlign: 'top',
      render: (memo: string) => <ShowMoreText maxLength={50}>{memo}</ShowMoreText>,
    },
    {
      key: 'attachment',
      label: 'Attachment',
      align: 'center',
      verticalAlign: 'top',
      hidden: data?.expenses?.findIndex((item) => item.fileId) < 0,
      render: (_: any, record: ExpenseItem) => {
        return onDownloadReceipt && record.fileId ? (
          <IconButton
            color={'primary'}
            onClick={() => onDownloadReceipt(data.id, record.id, record.filename)}
            style={{ padding: '0' }}
          >
            <ReceiptLongOutlinedIcon />
          </IconButton>
        ) : null;
      },
    },
    {
      key: 'actions',
      label: 'Action',
      sortable: false,
      align: 'center',
      verticalAlign: 'top',
      render: (_: any, record: ExpenseItem) => getExpenseActionButton(record),
    },
  ];

  return (
    <>
      <Grid {...defaultGridContainerProps} justifyContent={'flex-end'}>
        <Grid {...defaultGridItemProps}>
          <Grid container={true} spacing={2}>
            <Grid {...defaultGridItemProps}>
              <TableCountLabel viewCount={list.length} totalCount={initialList.length} />
            </Grid>
            <Grid {...defaultGridItemProps}>
              <Table columns={columns} list={list} sort={sort} showPagination={true} onSortChange={handleSortChange} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ExpenseItemTable;
