import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { ChangeEvent, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormProps, getRequiredValidationRule } from 'util/Form';
import StaticSelectOption from 'store/types/StaticSelectOption';
import MeetRequirementsOption from 'store/enums/MeetRequirementsOption';

const fieldName = 'isRequirementsMet';

const options: StaticSelectOption[] = [
  {
    text: MeetRequirementsOption.Yes,
    value: MeetRequirementsOption.Yes,
  },
  {
    text: MeetRequirementsOption.No,
    value: MeetRequirementsOption.No,
  },
];

const MeetRequirementsRadioButton: React.FunctionComponent<FormProps> = () => {
  const { control } = useFormContext();

  const handleChange = useCallback(
    (onChange) => (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    []
  );

  return (
    <>
      <FormControl component={'fieldset'} fullWidth={true} required={true}>
        <Controller
          render={({ field: { onChange, value } }) => (
            <RadioGroup onChange={handleChange(onChange)} value={value} row={true}>
              {options.map(({ text, value }) => (
                <FormControlLabel
                  key={`${fieldName}-${text}`}
                  value={value}
                  control={<Radio color={'primary'} />}
                  label={text}
                />
              ))}
            </RadioGroup>
          )}
          name={fieldName}
          control={control}
          rules={{
            required: getRequiredValidationRule('your answer', true, true),
          }}
          defaultValue={''}
        />
      </FormControl>
    </>
  );
};
export default MeetRequirementsRadioButton;
