import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import AuthService from 'services/AuthService';
import routes from 'store/configs/Routes';
import { tenantConfig } from 'config';

const ProtectedRoute: React.FunctionComponent<RouteProps> = (props) => (
  <Route {...props}>
    {AuthService.isAuthenticated() ? (
      props.children
    ) : (
      <Redirect to={tenantConfig.loginUrl ? '' : routes.login} data-testid={'redirect'} />
    )}
  </Route>
);
export default ProtectedRoute;
