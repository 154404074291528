import Subscription from 'store/types/Subscription';
import { FilterConfig, SortConfig } from 'store/types/Table';
import { sorter } from 'util/Table';

export enum UserSubscriptionsTableActionType {
  SetInitialList = 'SetInitialList',
  UpdateSort = 'UpdateSort',
  UpdateFilter = 'UpdateFilter',
  OpenRenewalModal = 'OpenRenewalModal',
  CloseRenewalModal = 'CloseRenewalModal',
  OpenUsersModal = 'OpenUsersModal',
  CloseUsersModal = 'CloseUsersModal',
}

export interface UserSubscriptionsTableState {
  initialList: Subscription[];
  list: Subscription[];
  filter: FilterConfig<Subscription>;
  sort: SortConfig<Subscription>;
  clickedItem?: Subscription;
  usersModalOpen: boolean;
  renewalModalOpen: boolean;
}

export interface UserSubscriptionsTableAction {
  type: UserSubscriptionsTableActionType;
  payload: Partial<Omit<UserSubscriptionsTableState, 'initialList'> & { initialList: Subscription[] }>;
}

const getFilteredList = (list: Subscription[] = [], filter: FilterConfig<Subscription>) => {
  let result = [...list];

  if (filter.customerId) {
    result = result.filter(
      ({ customerId = '' }: Subscription) => customerId.toLowerCase() === filter.customerId.toLowerCase()
    );
  }
  return result;
};

const getSortedList = (list: Subscription[] = [], sort: SortConfig<Subscription>): Subscription[] =>
  list.length ? [...list].sort(sorter<Subscription>(sort)) : [...list];

export const initialState: UserSubscriptionsTableState = {
  initialList: [],
  list: [],
  filter: { customerId: '' },
  sort: { direction: 'asc', column: 'status' },
  usersModalOpen: false,
  renewalModalOpen: false,
};

const reducer = (
  state: UserSubscriptionsTableState,
  { type, payload }: UserSubscriptionsTableAction
): UserSubscriptionsTableState => {
  if (type === UserSubscriptionsTableActionType.SetInitialList) {
    const { initialList = [] } = payload;
    const list = getSortedList(initialList, state.sort);

    return { ...state, initialList, list };
  }

  if (type === UserSubscriptionsTableActionType.UpdateSort) {
    const { sort = {} } = payload;

    return {
      ...state,
      sort,
      list: getSortedList(state.initialList, sort),
    };
  }

  if (type === UserSubscriptionsTableActionType.UpdateFilter) {
    const { filter = {} } = payload;
    const newFilter = { ...state.filter, ...filter };

    return {
      ...state,
      filter: newFilter,
      list: getSortedList(getFilteredList(state.initialList, newFilter), state.sort),
    };
  }

  if (type === UserSubscriptionsTableActionType.OpenRenewalModal) {
    const { clickedItem } = payload;

    return { ...state, clickedItem, renewalModalOpen: true };
  }

  if (type === UserSubscriptionsTableActionType.CloseRenewalModal) {
    return { ...state, clickedItem: undefined, renewalModalOpen: false };
  }

  if (type === UserSubscriptionsTableActionType.OpenUsersModal) {
    const { clickedItem } = payload;

    return { ...state, clickedItem, usersModalOpen: true };
  }

  if (type === UserSubscriptionsTableActionType.CloseUsersModal) {
    return { ...state, clickedItem: undefined, usersModalOpen: false };
  }

  return state;
};

export default reducer;
