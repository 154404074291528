import React from 'react';
import { PublicUser } from 'store/types/User';
import { isCanadaSelected, isUSSelected } from 'util/Form';

import commonStyles from 'styles/common.module.scss';

interface PaymentInfoModalProps {
  users: PublicUser[];
  printContentId: string;
  printFrameId: string;
}

function capitalizeFirstLetter(content: string) {
  return content.charAt(0).toUpperCase() + content.slice(1);
}

const EventRegistrationUsersCards: React.FunctionComponent<PaymentInfoModalProps> = ({
  users,
  printContentId,
  printFrameId,
}) => {
  return (
    <>
      <div id={printContentId} className={commonStyles.hidden}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
            width: '816px',
            height: '1056',
          }}
        >
          {users?.map(
            (
              { parentOrCustomCompanyName, firstName, lastName, id, nickname, address: { country, city, stateRegion } },
              index
            ) => (
              <div
                key={id + index}
                style={{
                  textAlign: 'center',
                  wordBreak: 'break-word',
                  breakAfter: (index + 1) % 6 === 0 ? 'page' : 'auto',
                  fontFamily: 'arial',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  width: '384px',
                  height: '289px',
                }}
              >
                <span style={{ fontSize: '50px', fontWeight: 'bold', textTransform: 'uppercase' }}>{nickname}</span>
                <span style={{ fontSize: '33px' }}>{`${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(
                  lastName
                )}`}</span>
                {parentOrCustomCompanyName && <span style={{ fontSize: '18px' }}>{parentOrCustomCompanyName}</span>}
                {country && (
                  <span style={{ fontSize: '18px' }}>{`${
                    isUSSelected(country) || isCanadaSelected(country)
                      ? `${capitalizeFirstLetter(city)}, ${capitalizeFirstLetter(stateRegion ?? '')}`
                      : `${capitalizeFirstLetter(country)}`
                  }`}</span>
                )}
                <div style={{ height: '103px', width: '100%' }} />
              </div>
            )
          )}
        </div>
      </div>
      <iframe id={printFrameId} className={commonStyles.hidden} title={'Users'} />
    </>
  );
};
export default EventRegistrationUsersCards;
