import Skeleton from '@mui/material/Skeleton';
import React, { useContext } from 'react';
import PageCard from 'components/shared/PageCard';
import { Button, ButtonProps } from '@mui/material';
import { getHashRouteUrl } from 'util/Route';
import routes from 'store/configs/Routes';
import { MembershipContext } from 'components/MembershipGuard';
import { getFormattedDate } from 'util/Format';
import SubscriptionStatus from 'store/enums/SubscriptionStatus';
import { CurrentMembership } from 'store/types/Membership';
import {
  hasGracePeriodExpired,
  isJoinMembershipPage,
  isReJoinMembershipPage,
  isRenewMembershipPage,
} from 'util/Membership';
import Spinner from 'components/shared/Spinner';
import { UserContext } from 'components/UserGuard';

import pageStyles from '../HomePage.module.scss';
import styles from './HomeMembershipCard.module.scss';

const buttonProps: ButtonProps = {
  variant: 'contained',
  color: 'primary',
  className: pageStyles.button,
};

const getDescription = (membership: CurrentMembership): React.ReactNode => {
  const { status, endDate = '', autoRenewal, gracePeriod } = membership;

  switch (status) {
    case SubscriptionStatus.Active:
      return (
        <>
          <p>
            <span>
              {'Thank you for a being a valued member. '}
              {endDate && (
                <>
                  {'Your membership expires on '}
                  <span className={styles.date}>{getFormattedDate(endDate)}</span>.
                </>
              )}
            </span>
          </p>
          {autoRenewal && (
            <p>
              {
                'You are enrolled in auto-renewal and your membership will be automatically extended during the renewal period.'
              }
            </p>
          )}
        </>
      );
    case SubscriptionStatus.Expired:
      return hasGracePeriodExpired(membership) ? (
        <p>
          {
            "Your membership benefits have been suspended due to nonpayment. Don't miss out on another outstanding year of great programs and professional development. Re-join today!"
          }
        </p>
      ) : (
        <p>{`Your membership expired but you have been granted a ${gracePeriod} day grace period. All dues must be paid immediately to retain your membership.`}</p>
      );
    case SubscriptionStatus.NonMember:
      return <p>{'You are not an active member. Let’s change that.'}</p>;
    default:
      return (
        <p>
          <span>
            {`Your membership status is ${status}.`}
            {endDate ? (
              <>
                {'Your membership expires on '}
                <span className={styles.date}>{getFormattedDate(endDate)}</span>.
              </>
            ) : null}
          </span>
        </p>
      );
  }
};

const getActionButton = (membership: CurrentMembership): React.ReactNode => {
  if (isJoinMembershipPage(membership)) {
    return (
      <Button {...buttonProps} href={getHashRouteUrl(routes.enrollMembership)}>
        {'Join Now'}
      </Button>
    );
  } else if (isRenewMembershipPage(membership)) {
    return (
      <Button {...buttonProps} href={getHashRouteUrl(routes.enrollMembership)}>
        {'Renew Now'}
      </Button>
    );
  } else if (isReJoinMembershipPage(membership)) {
    return (
      <Button {...buttonProps} href={getHashRouteUrl(routes.enrollMembership)}>
        {'Re-join Now'}
      </Button>
    );
  } else {
    return null;
  }
};

const HomeMembershipCard: React.FunctionComponent = () => {
  const currentMembership = useContext(MembershipContext);
  const currentUser = useContext(UserContext);
  const { status, membershipLoading = false } = currentMembership;
  const { userLoading = false } = currentUser;

  return (
    <PageCard title={'Membership'}>
      {membershipLoading || userLoading ? (
        <>
          <Skeleton width={'100%'} variant={'text'} />
          <Skeleton width={'100%'} variant={'text'} />
          <Skeleton width={30} variant={'text'} />
        </>
      ) : currentMembership && status === SubscriptionStatus.Pending ? (
        <div className={styles.spinnerWrapper}>
          <Spinner loading={true} />
        </div>
      ) : (
        <div className={pageStyles.cardContent}>
          {getDescription(currentMembership)}
          {getActionButton(currentMembership)}
        </div>
      )}
    </PageCard>
  );
};
export default HomeMembershipCard;
