import { Button, Divider } from '@mui/material';
import React from 'react';
import { getAddressInfo } from 'util/Format';
import { BillingAddress } from 'store/types/Address';
import Card from 'components/shared/Card';
import Edit from '@mui/icons-material/Edit';
import StatusLabel from 'components/shared/StatusLabel';

import styles from './AddressListItem.module.scss';

interface AddressListItemProps {
  data: BillingAddress;
  onClick: () => void;
}

const AddressListItem: React.FunctionComponent<AddressListItemProps> = ({ data, onClick }) => {
  const { label, addressee, phone, isDefaultBilling, isDefaultShipping, isCongressionalInterest, isVotingDistrict } =
    data;

  return (
    <Card className={styles.card} contentClassName={styles.content} spinnerClassName={styles.spinner}>
      <span className={styles.title}>{label}</span>
      <Divider className={styles.divider} />
      <span className={styles.fullName}>{addressee}</span>
      <div className={styles.addressWrapper}>
        {getAddressInfo({ ...data, label: '' })}
        {phone && <span>{`Phone Number: ${phone}`}</span>}
      </div>
      <Button variant={'outlined'} className={styles.actionButton} onClick={onClick}>
        <Edit className={styles.icon} />
      </Button>
      {isCongressionalInterest && (
        <StatusLabel theme={'yellow'} status={'Congressional Interest'} className={styles.label} />
      )}
      {isVotingDistrict && <StatusLabel status={'Voting District'} theme={'yellow'} className={styles.label} />}
      {isDefaultBilling && <StatusLabel status={'Default Billing'} className={styles.label} />}
      {isDefaultShipping && <StatusLabel status={'Default Shipping'} className={styles.label} />}
    </Card>
  );
};

export default AddressListItem;
