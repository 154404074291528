import PreferenceQuestion, {
  PreferenceQuestionAnswer,
  PreferenceQuestionAnswerFormValues,
  PreferenceQuestionView,
  SublistAnswer,
  SublistQuestion,
} from 'store/types/PreferenceQuestion';
import { getExplanationFieldName, getQuestionFieldName, getQuestionId } from './Application';
import QuestionType from 'store/enums/QuestionType';
import React from 'react';
import TextQuestionFormItem from 'components/profile/EditPreferencesPage/EditPreferencesForm/TextQuestionFormItem';
import CheckboxQuestionFormItem from 'components/profile/EditPreferencesPage/EditPreferencesForm/CheckboxQuestionFormItem';
import RadioQuestionFormItem from 'components/profile/EditPreferencesPage/EditPreferencesForm/RadioQuestionFormItem';
import DropdownQuestionFormItem from 'components/profile/EditPreferencesPage/EditPreferencesForm/DropdownQuestionFormItem';
import SublistQuestionFormItem from 'components/profile/EditPreferencesPage/EditPreferencesForm/SublistQuestionFormItem';
import MultilineTextQuestionFormItem from 'components/profile/EditPreferencesPage/EditPreferencesForm/MultilineTextQuestionFormItem';

export const PREFERENCES_FIELD_NAME = 'preferences';

export const getPreferencesAnswersData = (
  formValues: any,
  questionsList: PreferenceQuestionView[] = []
): PreferenceQuestionAnswer[] => {
  const result: PreferenceQuestionAnswer[] = [];
  Object.keys(formValues).forEach((key: string) => {
    const questionId: string = getQuestionId(key);

    if (questionId) {
      const formValue = formValues[key];
      const explanationFormValue: string | undefined = formValues[getExplanationFieldName(key)];
      const question = questionsList.find(({ id }) => id === questionId);
      const questionType: QuestionType = question?.type || QuestionType.Text;
      const answerName = question?.options?.find(({ id }) => id === formValue)?.name;
      switch (questionType) {
        case QuestionType.Text:
        case QuestionType.MultilineText:
          result.push({ attributeId: questionId, answer: formValue });
          break;
        case QuestionType.Checkbox:
          result.push({
            attributeId: questionId,
            selections: (formValue || [])
              .filter(({ value }: any) => !!value)
              .map(({ id, explanation, name }: any) => ({ value: id, explanation, name })),
          });
          break;
        case QuestionType.SubList:
          result.push({ attributeId: questionId, sublistAnswers: formValue });
          break;
        case QuestionType.Dropdown:
        case QuestionType.Radio:
          result.push({
            attributeId: questionId,
            selections: formValue ? [{ value: formValue, explanation: explanationFormValue, name: answerName }] : [],
          });
          break;
      }
    }
  });
  return result;
};

export const getQuestionInput = (question: PreferenceQuestionView, disabled?: boolean): React.ReactNode => {
  const inputProps = { question, disabled };

  switch (question.type) {
    case QuestionType.Text:
      return <TextQuestionFormItem {...inputProps} />;
    case QuestionType.MultilineText:
      return <MultilineTextQuestionFormItem {...inputProps} />;
    case QuestionType.Checkbox:
      return <CheckboxQuestionFormItem {...inputProps} />;
    case QuestionType.Radio:
      return <RadioQuestionFormItem {...inputProps} />;
    case QuestionType.Dropdown:
      return <DropdownQuestionFormItem {...inputProps} />;
    case QuestionType.SubList:
      return <SublistQuestionFormItem {...inputProps} />;
    default:
      return <></>;
  }
};

const getSublistAnswerValues = (questions: SublistQuestion[]): Array<SublistAnswer[]> => {
  const result: Array<SublistAnswer[]> = [];

  questions?.forEach(({ answers, id: sublistQuestionId, content, type }) =>
    answers.forEach(({ referenceKey, answer, answerId }) => {
      const foundIndex = result.findIndex(
        (answerArray) => !!answerArray.find((answer) => answer.referenceKey === referenceKey)
      );
      const newValue = {
        referenceKey,
        answer: answer,
        answerId,
        type,
        sublistQuestionId,
        content,
      };

      if (foundIndex !== -1) {
        result[foundIndex].push(newValue);
      } else {
        result.push([newValue]);
      }
    })
  );

  return result;
};

export const getDefaultPreferencesFormValues = (
  questions: PreferenceQuestionView[] = [],
  includeAnswers = true
): PreferenceQuestionAnswerFormValues => {
  let result = {};

  questions.forEach(({ type, id, options = [], answer, sublistQuestions }) => {
    const fieldName = getQuestionFieldName(id);

    switch (type) {
      case QuestionType.Checkbox:
        result = {
          ...result,
          [fieldName]: options.map(({ id, name, isSelected, includeExplanation, explanation }) => ({
            id,
            name,
            includeExplanation,
            explanation,
            value: includeAnswers ? isSelected : false,
          })),
        };
        break;
      case QuestionType.SubList:
        result = {
          ...result,
          [fieldName]: getSublistAnswerValues(sublistQuestions || []),
        };
        break;
      case QuestionType.Text:
      case QuestionType.MultilineText:
      case QuestionType.Radio:
        result = { ...result, [fieldName]: includeAnswers ? answer || '' : '' };
        break;
      case QuestionType.Dropdown:
        result = {
          ...result,
          [fieldName]: includeAnswers ? options.find(({ isSelected }) => isSelected)?.id || '' : '',
        };
        break;
    }
  });
  return result;
};

const getSorterSequenceList = <T extends { sequence?: number }>(list: T[]) =>
  list.sort((a, b) =>
    !a.sequence && a.sequence !== 0 ? 1 : !b.sequence && b.sequence !== 0 ? -1 : a.sequence - b.sequence
  );

export const getPreferencesQuestionsView = (
  questions: PreferenceQuestion[] = [],
  fieldName = ''
): PreferenceQuestionView[] =>
  getSorterSequenceList(questions || []).map((item) => ({
    ...item,
    fieldName: `${fieldName ? `${fieldName}.` : ''}${getQuestionFieldName(item.id)}`,
    options: getSorterSequenceList(item.options || []),
    sublistQuestions: getSorterSequenceList(item.sublistQuestions || []),
  }));

export const isSubListQuestionsValid = (data: PreferenceQuestionView[] = [], values: any): boolean =>
  data
    .filter(({ required, type }) => required && type === QuestionType.SubList)
    .map(({ fieldName }) => fieldName)
    .every(
      (questionId) => !!questionId.split('.').reduce((nextObject: any, key: string) => nextObject[key], values).length
    );
