import React, { useMemo } from 'react';
import { Button, Divider, Grid } from '@mui/material';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import Modal from 'components/shared/Modal';
import { ModalProps } from 'store/types/ComponentProps';
import { SupportCaseMessage, SupportCaseView } from 'store/types/SupportCase';
import { getFormattedLocalDate, NUMBER_DATE_FORMAT } from 'util/Format';
import Table from 'components/shared/Table';
import SupportCaseFilesList from './SupportCaseFilesList';
import { TableColumn } from 'store/types/Table';
import SupportCasePriority from 'store/enums/SupportCasePriority';

import styles from './ReviewSupportCaseModal.module.scss';

interface AddSupportCaseModalProps extends ModalProps {
  data: SupportCaseView;
}

const ReviewSupportCaseModal: React.FunctionComponent<AddSupportCaseModalProps> = ({ onClose, open, data }) => {
  const { user, email, issueDate, priority, subject, files, id, messages } = useMemo(() => data, [data]);

  const columns: Array<TableColumn<SupportCaseMessage>> = useMemo(
    () => [
      {
        dataIndex: 'date',
        label: 'Date',
        render: (date: string) => getFormattedLocalDate(date).format(),
      },
      { dataIndex: 'author', label: 'Author' },
      { dataIndex: 'message', label: 'Message' },
    ],
    []
  );

  return (
    <Modal
      onClose={onClose}
      open={open}
      maxWidth={'md'}
      title={'Support Case Details'}
      actions={
        <>
          <Button color={'secondary'} variant={'outlined'} onClick={onClose}>
            {'Close'}
          </Button>
        </>
      }
    >
      <Grid {...defaultGridContainerProps} spacing={2} className={styles.container}>
        <Grid {...defaultGridItemProps} xs={2} className={styles.label}>
          {'User:'}
        </Grid>
        <Grid {...defaultGridItemProps} xs={10} className={styles.value}>
          {user}
        </Grid>
        <Grid {...defaultGridItemProps} xs={2} className={styles.label}>
          {'Email Address:'}
        </Grid>
        <Grid {...defaultGridItemProps} xs={10} className={styles.value}>
          {email}
        </Grid>
        <Grid {...defaultGridItemProps} xs={2} className={styles.label}>
          {'Incident Date:'}
        </Grid>
        <Grid {...defaultGridItemProps} xs={10} className={styles.value}>
          {getFormattedLocalDate(issueDate).format(NUMBER_DATE_FORMAT)}
        </Grid>
        <Grid {...defaultGridItemProps} xs={2} className={styles.label}>
          {'Incident Time:'}
        </Grid>
        <Grid {...defaultGridItemProps} xs={10} className={styles.value}>
          {getFormattedLocalDate(issueDate).format('HH:mm A')}
        </Grid>
        <Grid {...defaultGridItemProps} xs={2} className={styles.label}>
          {'Priority:'}
        </Grid>
        <Grid {...defaultGridItemProps} xs={10} className={styles.value}>
          {SupportCasePriority[priority]}
        </Grid>
        <Grid {...defaultGridItemProps} xs={2} className={styles.label}>
          {'Subject:'}
        </Grid>
        <Grid {...defaultGridItemProps} xs={10} className={styles.value}>
          {subject}
        </Grid>
        {files?.length && (
          <>
            <Grid {...defaultGridItemProps} xs={2} className={styles.label}>
              {'Attachments:'}
            </Grid>
            <Grid {...defaultGridItemProps} xs={10} className={styles.value}>
              <SupportCaseFilesList data={files} supportCaseId={id} />
            </Grid>
          </>
        )}
        <Grid {...defaultGridItemProps}>
          <h1 className={styles.title}>{'Messages'}</h1>
          <Divider className={styles.divider} />
          <Table columns={columns} list={messages} className={styles.table} />
        </Grid>
      </Grid>
    </Modal>
  );
};
export default ReviewSupportCaseModal;
