import { FilterConfig, SortConfig } from 'store/types/Table';
import { sorter } from 'util/Table';
import { PartnerDonor } from 'store/types/Partner';
import { getFormattedDate, NUMBER_DATE_FORMAT } from 'util/Format';
import moment from 'moment/moment';

type FilterProps = FilterConfig<PartnerDonor> & Partial<FilterFormProps>;
export enum PartnerCampaignDetailsPageActionType {
  SetInitialList = 'SetInitialList',
  UpdateFilter = 'UpdateFilter',
  UpdateSort = 'UpdateSort',
}

export interface FilterFormProps {
  endDate: string;
  startDate: string;
}

export interface PartnerCampaignDetailsPageState {
  initialList: PartnerDonor[];
  list: PartnerDonor[];
  filter: FilterProps;
  sort: SortConfig<PartnerDonor>;
}

export interface PartnerCampaignDetailsPageAction {
  type: PartnerCampaignDetailsPageActionType;
  payload: Partial<PartnerCampaignDetailsPageState>;
}

export const ALL_DONORS_VALUE = 'All Donors';

const getSortedList = (list: PartnerDonor[] = [], sort: SortConfig<PartnerDonor>): PartnerDonor[] =>
  list.length ? [...list].sort(sorter<PartnerDonor>(sort)) : [...list];

const getFilteredList = (list: PartnerDonor[] = [], filter: FilterProps): PartnerDonor[] => {
  let result: PartnerDonor[] = [...list];
  const searchValue = (filter.search || '').toLowerCase();
  const { donor: donorValue, endDate: endDateValue, startDate: startDateValue } = filter;

  if (donorValue) {
    result = filter.donor === ALL_DONORS_VALUE ? result : result.filter(({ donor }) => donorValue === donor);
  }

  if (endDateValue) {
    result = result.filter(({ date }) => moment(date).isSameOrBefore(moment(endDateValue)));
  }

  if (startDateValue) {
    result = result.filter(({ date }) => moment(date).isSameOrAfter(moment(startDateValue)));
  }

  if (searchValue) {
    result = result.filter(
      ({ donor, amount, date, email, invoiceId }: PartnerDonor) =>
        donor.toLowerCase().includes(searchValue) ||
        email.toLowerCase().includes(searchValue) ||
        invoiceId.toLowerCase().includes(searchValue) ||
        getFormattedDate(date, NUMBER_DATE_FORMAT).includes(searchValue) ||
        amount.toString().includes(searchValue)
    );
  }
  return result;
};

export const initialState: PartnerCampaignDetailsPageState = {
  initialList: [],
  list: [],
  sort: { direction: 'asc', column: 'invoiceId' },
  filter: { search: '', donor: ALL_DONORS_VALUE, endDate: '', startDate: '' },
};

const reducer = (
  state: PartnerCampaignDetailsPageState,
  { type, payload }: PartnerCampaignDetailsPageAction
): PartnerCampaignDetailsPageState => {
  if (type === PartnerCampaignDetailsPageActionType.SetInitialList) {
    const { initialList = [] } = payload;

    return { ...state, initialList, list: initialList };
  }

  if (type === PartnerCampaignDetailsPageActionType.UpdateSort) {
    const { sort = {} } = payload;

    return {
      ...state,
      sort,
      list: getSortedList(state.initialList, sort),
    };
  }

  if (type === PartnerCampaignDetailsPageActionType.UpdateFilter) {
    const { filter = {} } = payload;
    const newFilter = { ...state.filter, ...filter };
    const list = getFilteredList(state.initialList, newFilter);

    return {
      ...state,
      filter: newFilter,
      list,
    };
  }

  return state;
};

export default reducer;
