import React, { useCallback, useContext } from 'react';
import useRequest from 'hooks/useRequest';
import Alert from '@mui/material/Alert';
import { UserContext } from 'components/UserGuard';
import Spinner from 'components/shared/Spinner';
import { useParams } from 'react-router';
import { PublicUser } from 'store/types/User';
import ProfileForm from 'components/profile/ProfileForm';
import MemberDirectoryService from 'services/api/MemberDirectoryService';

import commonStyles from 'styles/common.module.scss';

const IndividualDetailsPage: React.FunctionComponent = () => {
  const { id = '' } = useParams<{ id: string }>();
  const { userLoading = false } = useContext(UserContext);
  const individualDetails = useCallback(() => MemberDirectoryService.getIndividualDetails(id), [id]);
  const { data, loading, error } = useRequest<PublicUser>(individualDetails);

  return (
    <Spinner loading={userLoading || loading} transparent={false}>
      {error ? (
        <Alert severity={'error'} className={commonStyles.alert}>
          {error}
        </Alert>
      ) : (
        data && <ProfileForm data={data} />
      )}
    </Spinner>
  );
};

export default IndividualDetailsPage;
