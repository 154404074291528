import { Tab, Tabs } from '@mui/material';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import Spinner from 'components/shared/Spinner';
import { UserContext } from 'components/UserGuard';
import CompanyPageView from 'components/company/CompanyPageView';
import CompanySelect from 'components/company/CompanySelect';
import TabContext from '@mui/lab/TabContext';
import { CompanySelectOption, getCompanySelectOptions } from 'util/Company';
import TabPanel from '@mui/lab/TabPanel';
import { useHistory, useLocation } from 'react-router';
import { getActiveTabRoute, getActiveTabValue } from 'util/Route';
import { companyRoutes } from 'store/configs/Routes';
import CompanyDetailsPage from 'components/company/CompanyDetailsPage';
import CompanyAddressesPage from 'components/company/CompanyAddressesPage';
import CompanyRequestsPage from 'components/company/CompanyRequestsPage';
import { ParametersContext } from 'components/ParametersGuard';
import { ConfigContext } from 'components/ConfigGuard';
import SiteModule from 'store/enums/SiteModule';
import classNames from 'classnames';
import CountBadge from 'components/shared/CountBadge';
import CompanyService from 'services/api/CompanyService';
import { CompanyRequestsInfo } from 'store/types/Company';
import useRequest from 'hooks/useRequest';
import CompanyAdvocacyPage from 'components/company/CompanyAdvocacyPage';

import commonStyles from 'styles/common.module.scss';
import styles from './CompanyPage.module.scss';

const CompanyPage: React.FunctionComponent = () => {
  const { functions = [], userLoading, userPermissions } = useContext(UserContext);
  const history = useHistory();
  const {
    companyManagement: {
      companyMgtHeader,
      companyAddressHeader,
      companyInformationHeader,
      companyAdvocacyHeader,
      companyRequestsHeader,
    },
  } = useContext(ParametersContext);
  const {
    modulesSettings: {
      companyManagement: { enableAdvocacy },
    },
  } = useContext(ConfigContext);
  const { pathname } = useLocation();
  const [selectedCompany, setSelectedCompany] = useState<CompanySelectOption | undefined>(undefined);
  const companyEmployeeRequestsCountRequest = useMemo(
    () => (selectedCompany?.id ? () => CompanyService.getCompanyRequestsCount(selectedCompany.id) : undefined),
    [selectedCompany]
  );
  const { data, refetch } = useRequest<CompanyRequestsInfo>(companyEmployeeRequestsCountRequest);
  const permissions: string[] = useMemo(
    () => userPermissions.modules.find(({ name }) => name === SiteModule.Company)?.permissions || [],
    [userPermissions]
  );
  const options: CompanySelectOption[] = useMemo(
    () => getCompanySelectOptions(functions, permissions),
    [functions, permissions]
  );
  const activeTab: string = useMemo(() => getActiveTabValue(pathname, companyRoutes), [pathname]);

  const handleCompanyChange = useCallback(
    (newCompanyId: string) => {
      setSelectedCompany(options.find(({ id }) => id === newCompanyId) || undefined);
    },
    [options]
  );

  const handleTabChange = useCallback(
    (_: any, value = '1') => {
      history.replace(getActiveTabRoute(value, companyRoutes));
    },
    [history]
  );

  return (
    <TabContext value={activeTab}>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        indicatorColor={'primary'}
        textColor={'primary'}
        TabIndicatorProps={{ sx: { display: 'none' } }}
        sx={{
          '& .MuiTabs-flexContainer': {
            flexWrap: 'wrap',
          },
        }}
      >
        <Tab
          value={'1'}
          label={'Company Management'}
          className={commonStyles.tab}
          classes={{ selected: commonStyles.activeTab }}
        />
        <Tab
          value={'2'}
          label={'Company Information'}
          className={commonStyles.tab}
          classes={{ selected: commonStyles.activeTab }}
        />
        <Tab
          value={'3'}
          label={'Manage Company Addresses'}
          className={commonStyles.tab}
          classes={{ selected: commonStyles.activeTab }}
        />
        <Tab
          value={'4'}
          label={
            <>
              {'Employee Requests'}
              {!!data?.pending && <CountBadge className={styles.countBadge}>{data.pending}</CountBadge>}
            </>
          }
          className={classNames(commonStyles.tab, styles.tabWithCounter)}
          classes={{ selected: commonStyles.activeTab }}
        />
        {enableAdvocacy && (
          <Tab
            value={'5'}
            label={'Advocacy'}
            className={commonStyles.tab}
            classes={{ selected: commonStyles.activeTab }}
          />
        )}
      </Tabs>
      <div className={styles.companySelect}>
        {activeTab === '1' && companyMgtHeader ? (
          <p dangerouslySetInnerHTML={{ __html: companyMgtHeader }} />
        ) : activeTab === '2' && companyInformationHeader ? (
          <p dangerouslySetInnerHTML={{ __html: companyInformationHeader }} />
        ) : activeTab === '3' && companyAddressHeader ? (
          <p dangerouslySetInnerHTML={{ __html: companyAddressHeader }} />
        ) : activeTab === '4' && companyRequestsHeader ? (
          <p dangerouslySetInnerHTML={{ __html: companyRequestsHeader }} />
        ) : (
          activeTab === '5' &&
          companyAdvocacyHeader && <p dangerouslySetInnerHTML={{ __html: companyAdvocacyHeader }} />
        )}
        <CompanySelect value={selectedCompany?.id} options={options} onChange={handleCompanyChange} size={'small'} />
      </div>
      <TabPanel value={'1'} className={commonStyles.tabPanel}>
        <Spinner loading={userLoading} transparent={false}>
          <CompanyPageView companyId={selectedCompany?.id || ''} refetchCount={refetch} />
        </Spinner>
      </TabPanel>
      <TabPanel value={'2'} className={commonStyles.tabPanel}>
        <Spinner loading={userLoading} transparent={false}>
          <CompanyDetailsPage companyId={selectedCompany?.id || ''} />
        </Spinner>
      </TabPanel>
      <TabPanel value={'3'} className={commonStyles.tabPanel}>
        <Spinner loading={userLoading} transparent={false}>
          <CompanyAddressesPage selectedCompany={selectedCompany} />
        </Spinner>
      </TabPanel>
      <TabPanel value={'4'} className={commonStyles.tabPanel}>
        <Spinner loading={userLoading} transparent={false}>
          <CompanyRequestsPage companyId={selectedCompany?.id || ''} refetchCount={refetch} />
        </Spinner>
      </TabPanel>
      {enableAdvocacy && (
        <TabPanel value={'5'} className={commonStyles.tabPanel}>
          <Spinner loading={userLoading} transparent={false}>
            <CompanyAdvocacyPage companyId={selectedCompany?.id || ''} />
          </Spinner>
        </TabPanel>
      )}
    </TabContext>
  );
};
export default CompanyPage;
