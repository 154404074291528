import { httpRequestWrapper } from 'util/Request';
import { HttpService } from 'services/HttpService';
import { API_URL, tenantConfig } from 'config';
import AuthService from 'services/AuthService';
import {
  MemberDirectorySearchFilter,
  MemberDirectoryCustomer,
  CustomerCompanyDetails,
} from 'store/types/MemberDirectory';
import { PublicUser } from 'store/types/User';
import SelectOption from 'store/types/SelectOption';

type MemberDirectoryUserResponseDate = MemberDirectoryCustomer[];
type SearchFiltersResponseData = MemberDirectorySearchFilter[];
type IndustriesResponseData = SelectOption[];

class MemberDirectoryService {
  public static searchMember(
    keyword: string,
    cityOrZip: string,
    type: string,
    filters: string,
    country = '',
    states: string[] = [],
    industries: string[] = []
  ): Promise<MemberDirectoryUserResponseDate> {
    return new Promise<MemberDirectoryUserResponseDate>(
      (resolve: (data: MemberDirectoryUserResponseDate) => void, reject: (error: string) => void) => {
        httpRequestWrapper<MemberDirectoryUserResponseDate>(
          HttpService.get(`${API_URL}/memberdirectory/search`, {
            queryParams: { keyword, cityOrZip, type, filters, country, states, marketSegments: industries },
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getSearchFilters(type: string): Promise<SearchFiltersResponseData> {
    return new Promise<SearchFiltersResponseData>(
      (resolve: (data: SearchFiltersResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<SearchFiltersResponseData>(
          HttpService.get(`${API_URL}/memberdirectory/filters`, {
            queryParams: { type },
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getIndividualDetails(profileId: string): Promise<PublicUser> {
    return new Promise<PublicUser>((resolve: (value: PublicUser) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.get(`${API_URL}/memberdirectory/customer/individual`, {
          queryParams: { profileId },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getCompanyDetails(companyId: string): Promise<CustomerCompanyDetails> {
    return new Promise<CustomerCompanyDetails>(
      (resolve: (value: CustomerCompanyDetails) => void, reject: (error: string) => void) => {
        httpRequestWrapper(
          HttpService.get(`${API_URL}/memberdirectory/customer/company`, {
            queryParams: { companyId },
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getIndustries(): Promise<IndustriesResponseData> {
    return new Promise<IndustriesResponseData>(
      (resolve: (data: IndustriesResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<IndustriesResponseData>(
          HttpService.get(`${API_URL}/memberdirectory/industries`, {
            headers: {
              TenantId: tenantConfig.id,
            },
          }),
          resolve,
          reject
        );
      }
    );
  }
}

export default MemberDirectoryService;
