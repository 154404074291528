import Modal from 'components/shared/Modal';
import { Button, Grid } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { ModalProps } from 'store/types/ComponentProps';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import EmployerSearchItem from 'components/profile/UserEmployerPageView/EmployerSearchItem';

import commonStyles from 'styles/common.module.scss';

interface CompanySelectModalProps extends ModalProps {
  addRecord: (id: string) => void;
}

const CompanySelectModal: React.FunctionComponent<CompanySelectModalProps> = ({ open, onClose, addRecord }) => {
  const [employerId, setEmployerId] = useState<string>('');

  const handleRequestSubmit = useCallback(() => {
    addRecord(employerId);
    setEmployerId('');
  }, [addRecord, employerId]);

  const handleSelectEmployerId = useCallback((id: string) => {
    setEmployerId(id);
  }, []);

  return (
    <Modal
      title={'Company Search'}
      open={open}
      maxWidth={'md'}
      actions={
        <>
          <Button color={'secondary'} variant={'outlined'} onClick={onClose}>
            {'Cancel'}
          </Button>
          <Button type={'submit'} variant={'contained'} onClick={handleRequestSubmit} disabled={!employerId}>
            {'Compare'}
          </Button>
        </>
      }
    >
      <Grid {...defaultGridContainerProps}>
        <Grid {...defaultGridItemProps}>
          <h5 className={commonStyles.subTitle}>{'Enter Company name to search'}</h5>
          <EmployerSearchItem onSelectEmployerId={handleSelectEmployerId} />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default CompanySelectModal;
