import { Alert, Button, Grid } from '@mui/material';
import classNames from 'classnames';
import Modal from 'components/shared/Modal';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { ModalProps } from 'store/types/ComponentProps';
import { defaultFormProps } from 'util/Form';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import CommitteeService from 'services/api/CommitteeService';
import { JoinCommitteeFormValues } from 'store/types/FormValues';
import CommitteeNameFormItem from './CommitteeNameFormItem';
import CommitteeRoleFormItem from './CommitteeRoleFormItem';
import JoinReasonFormItem from './JoinReasonFormItem';
import RelevantExperienceFormItem from './RelevantExperienceFormItem';
import { Community } from 'store/types/Community';
import { ParametersContext } from 'components/ParametersGuard';
import MeetRequirementsOption from 'store/enums/MeetRequirementsOption';
import MeetRequirementsRadioButton from 'components/communities/JoinCommitteeModal/MeetRequirementsRadioButton';

import commonStyles from 'styles/common.module.scss';
import styles from './JoinCommitteeModal.module.scss';

interface JoinCommitteeModalProps extends ModalProps {
  refetch: () => void;
  data: Community[];
}

const defaultValues: JoinCommitteeFormValues = {
  reason: '',
  role: '',
  experience: '',
  committeeId: '',
  isRequirementsMet: '',
};

const JoinCommitteeModal: React.FunctionComponent<JoinCommitteeModalProps> = ({ open, onClose, refetch, data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [selectedCommittee, setSelectedCommittee] = useState<Community | undefined>();
  const {
    committees: { relevantWorkExperienceHeader, joinCommitteeReasonHeader },
  } = useContext(ParametersContext);
  const form = useForm<JoinCommitteeFormValues>({
    ...defaultFormProps,
    defaultValues,
  });
  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid, isDirty },
  } = form;
  const committeeId = useWatch({ control, name: 'committeeId' });

  useEffect(() => {
    if (committeeId && data) {
      reset({ ...defaultValues, committeeId });
      setSelectedCommittee(data.find(({ id }) => committeeId === id));
    }
  }, [committeeId, data, reset]);

  const handleClose = useCallback(() => {
    onClose();
    reset();
    setSelectedCommittee(undefined);
  }, [onClose, reset]);

  const handleFormSubmit = useCallback(
    (formValues: JoinCommitteeFormValues) => {
      setSubmitLoading(true);
      const data: JoinCommitteeFormValues = {
        ...formValues,
        isRequirementsMet: formValues.isRequirementsMet === MeetRequirementsOption.Yes,
      };

      CommitteeService.joinCommittee(data)
        .then(() => {
          setSubmitLoading(false);
          enqueueSnackbar('Request successfully sent', { variant: 'success' });
          handleClose();
          refetch();
        })
        .catch((error: string) => {
          setSubmitLoading(false);
          enqueueSnackbar(error, defaultSnackbarErrorProps);
        });
    },
    [enqueueSnackbar, handleClose, refetch]
  );

  return (
    <Modal
      loading={submitLoading}
      open={open}
      onClose={handleClose}
      maxWidth={'sm'}
      title={'Join a Committee'}
      actions={
        <Button
          className={styles.submitButton}
          color={'secondary'}
          variant={'contained'}
          type={'submit'}
          onClick={handleSubmit(handleFormSubmit)}
          disabled={!isValid || !isDirty || submitLoading || !selectedCommittee?.acceptApplication}
        >
          {'Request to Join'}
        </Button>
      }
    >
      <FormProvider {...form}>
        <Grid {...defaultGridContainerProps} justifyContent={'space-between'} alignItems={'center'}>
          <Grid {...defaultGridItemProps}>
            <h5 className={classNames(commonStyles.subTitle, styles.label)}>{'Enter Committee Name to search'}</h5>
            <CommitteeNameFormItem data={data} />
          </Grid>
          {selectedCommittee?.acceptApplication ? (
            <>
              <Grid {...defaultGridItemProps} sm={'auto'}>
                <h5 className={classNames(commonStyles.subTitle, styles.label, styles.roleLabel)}>
                  {'Role Requested*'}
                </h5>
              </Grid>
              <Grid {...defaultGridItemProps} sm={true}>
                <CommitteeRoleFormItem data={selectedCommittee?.roles} />
              </Grid>
              <Grid {...defaultGridItemProps}>
                <h5 className={classNames(commonStyles.subTitle, styles.label)}>{'Relevant Work Experience'}</h5>
                {relevantWorkExperienceHeader && (
                  <p dangerouslySetInnerHTML={{ __html: relevantWorkExperienceHeader }} />
                )}
                <RelevantExperienceFormItem />
              </Grid>
              <Grid {...defaultGridItemProps}>
                <h5 className={classNames(commonStyles.subTitle, styles.label)}>
                  {'Why do you want to join this committee?'}
                </h5>
                {joinCommitteeReasonHeader && <p dangerouslySetInnerHTML={{ __html: joinCommitteeReasonHeader }} />}
                <JoinReasonFormItem />
              </Grid>
              {selectedCommittee?.disclaimer && (
                <Grid {...defaultGridItemProps}>
                  <p className={styles.description}>{selectedCommittee?.disclaimer}</p>
                </Grid>
              )}
              <Grid {...defaultGridItemProps}>
                <h5 className={classNames(commonStyles.subTitle, styles.label)}>
                  {'Do you meet all of the requirements?*'}
                </h5>
                <MeetRequirementsRadioButton />
              </Grid>
            </>
          ) : (
            selectedCommittee && (
              <Grid {...defaultGridItemProps}>
                <Alert severity={'info'} className={commonStyles.alert}>
                  {'This committee is not currently accepting application'}
                </Alert>
              </Grid>
            )
          )}
        </Grid>
      </FormProvider>
    </Modal>
  );
};
export default JoinCommitteeModal;
