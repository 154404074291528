import { HttpService } from 'services/HttpService';
import { API_URL, tenantConfig } from 'config';
import AuthService from 'services/AuthService';
import { httpRequestWrapper } from 'util/Request';
import EngagementReport from 'store/types/EngagementReport';

class EngagementService {
  public static getReport(id: string): Promise<EngagementReport> {
    return new Promise<EngagementReport>(
      (resolve: (data: EngagementReport) => void, reject: (error: string) => void) => {
        httpRequestWrapper<EngagementReport>(
          HttpService.get(`${API_URL}/engagement/report/${id}`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }
}
export default EngagementService;
