import { Grid } from '@mui/material';
import PaymentProductsTable from 'components/payments/PaymentProductsTable';
import CopyInput from 'components/shared/CopyInput';
import SectionHeader from 'components/shared/SectionHeader';
import React from 'react';
import routes from 'store/configs/Routes';
import { BasePayment } from 'store/types/Payment';
import PaymentStatus from 'store/enums/PaymentStatus';
import { getAddressInfo, getFormattedDate } from 'util/Format';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { getPaymentStatusLabel } from 'util/Payment';
import OrderType from 'store/enums/OrderType';

import commonStyles from 'styles/common.module.scss';
import styles from './PaymentDetailsSection.module.scss';

interface PaymentInfoModalProps {
  payment: BasePayment;
  membershipPayment?: boolean;
  companyPayment?: boolean;
  isOrderPaymentConfirmation?: boolean;
}

const PaymentDetailsSection: React.FunctionComponent<PaymentInfoModalProps> = ({
  payment,
  isOrderPaymentConfirmation = false,
  companyPayment = false,
}) => {
  const {
    orderDate,
    orderNumber,
    orderItems = [],
    billingAddress,
    billingAddressText = '',
    totalAmount,
    totalTax,
    totalShipping,
    paymentNumber,
    dueDate,
    paymentDate,
    status,
    type,
    paymentTypeText = '',
    billTo = '',
    expressCode = '',
    poNumber = '',
    ownerName,
    amountPaid,
    amountRemaining,
    amountToPay,
    billingMonth,
    applicationId,
    programId,
  } = payment;

  return (
    <>
      <Grid {...defaultGridContainerProps} className={styles.paymentInfo}>
        <Grid {...defaultGridItemProps}>
          <Grid {...defaultGridContainerProps} spacing={1}>
            <Grid {...defaultGridItemProps} md={6} className={styles.paymentInfoSection}>
              <Grid {...defaultGridContainerProps} spacing={1} alignItems={'flex-start'}>
                {status && (
                  <>
                    <Grid {...defaultGridItemProps} xs={5} className={styles.label}>
                      {'Payment Status:'}
                    </Grid>
                    <Grid {...defaultGridItemProps} xs={7} className={styles.value}>
                      {getPaymentStatusLabel(status)}
                    </Grid>
                  </>
                )}
                {dueDate && status !== PaymentStatus.Paid && (
                  <>
                    <Grid {...defaultGridItemProps} xs={5} className={styles.label}>
                      {'Due Date:'}
                    </Grid>
                    <Grid {...defaultGridItemProps} xs={7} className={styles.value}>
                      {getFormattedDate(dueDate)}
                    </Grid>
                  </>
                )}
                {paymentDate && status === PaymentStatus.Paid && (
                  <>
                    <Grid {...defaultGridItemProps} xs={5} className={styles.label}>
                      {'Payment Date:'}
                    </Grid>
                    <Grid {...defaultGridItemProps} xs={7} className={styles.value}>
                      {getFormattedDate(paymentDate)}
                    </Grid>
                  </>
                )}
                {paymentNumber && !isOrderPaymentConfirmation && (
                  <>
                    <Grid {...defaultGridItemProps} xs={5} className={styles.label}>
                      {'Payment Number:'}
                    </Grid>
                    <Grid {...defaultGridItemProps} xs={7} className={styles.value}>
                      {paymentNumber}
                    </Grid>
                  </>
                )}
                {paymentTypeText && (
                  <>
                    <Grid {...defaultGridItemProps} xs={5} className={styles.label}>
                      {'Payment Type:'}
                    </Grid>
                    <Grid {...defaultGridItemProps} xs={7} className={styles.value}>
                      {paymentTypeText}
                    </Grid>
                  </>
                )}
                {ownerName && (
                  <>
                    <Grid {...defaultGridItemProps} xs={5} className={styles.label}>
                      {'Customer:'}
                    </Grid>
                    <Grid {...defaultGridItemProps} xs={7} className={styles.value}>
                      {ownerName}
                    </Grid>
                  </>
                )}
                {billingMonth && (
                  <>
                    <Grid {...defaultGridItemProps} xs={5} className={styles.label}>
                      {'Billing Month:'}
                    </Grid>
                    <Grid {...defaultGridItemProps} xs={7} className={styles.value}>
                      {billingMonth}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid {...defaultGridItemProps} md={6} className={styles.paymentInfoSection}>
              <Grid {...defaultGridContainerProps} spacing={1} alignItems={'flex-start'}>
                {applicationId && (
                  <>
                    <Grid {...defaultGridItemProps} xs={5} className={styles.label}>
                      {'Application Number:'}
                    </Grid>
                    <Grid {...defaultGridItemProps} xs={7} className={styles.value}>
                      {applicationId}
                    </Grid>
                  </>
                )}
                {programId && (
                  <>
                    <Grid {...defaultGridItemProps} xs={5} className={styles.label}>
                      {'Program ID:'}
                    </Grid>
                    <Grid {...defaultGridItemProps} xs={7} className={styles.value}>
                      {programId}
                    </Grid>
                  </>
                )}
                {orderDate && !isOrderPaymentConfirmation && (
                  <>
                    <Grid {...defaultGridItemProps} xs={5} className={styles.label}>
                      {'Date'}
                    </Grid>
                    <Grid {...defaultGridItemProps} xs={7} className={styles.value}>
                      {getFormattedDate(orderDate)}
                    </Grid>
                  </>
                )}
                {orderNumber && (
                  <>
                    <Grid {...defaultGridItemProps} xs={5} className={styles.label}>
                      {'Number'}
                    </Grid>
                    <Grid {...defaultGridItemProps} xs={7} className={styles.value}>
                      {orderNumber}
                    </Grid>
                  </>
                )}
                {poNumber && (
                  <>
                    <Grid {...defaultGridItemProps} xs={5} className={styles.label}>
                      {'PO Number:'}
                    </Grid>
                    <Grid {...defaultGridItemProps} xs={7} className={styles.value}>
                      {poNumber}
                    </Grid>
                  </>
                )}
                {(billingAddress || billingAddressText || billTo) && (
                  <>
                    <Grid {...defaultGridItemProps} xs={5} className={styles.label}>
                      {'Bill To:'}
                    </Grid>
                    <Grid {...defaultGridItemProps} xs={7} className={styles.value}>
                      {billTo && <span className={styles.billToValue}>{billTo}</span>}
                      {billingAddressText ? (
                        <span className={styles.billToValue}>{billingAddressText}</span>
                      ) : billingAddress ? (
                        getAddressInfo({ ...billingAddress, label: '' }, { className: styles.value })
                      ) : null}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid {...defaultGridItemProps}>
          <SectionHeader>
            {isOrderPaymentConfirmation || type === OrderType.SalesOrd ? 'Order Summary' : 'Invoice Summary'}
          </SectionHeader>
          <PaymentProductsTable
            orderItems={orderItems}
            totalAmount={totalAmount}
            totalTax={totalTax}
            totalShipping={totalShipping}
            amountPaid={amountPaid}
            amountRemaining={amountRemaining}
            amountToPay={amountToPay}
            isOrderPaymentConfirmation={isOrderPaymentConfirmation}
            type={type}
            status={status}
            companyPayment={companyPayment}
          />
        </Grid>
        {expressCode && (
          <Grid {...defaultGridItemProps} sm={7}>
            <h5 className={commonStyles.subTitle}>{`Private URL to Share Payment for this ${
              type === OrderType.SalesOrd ? 'Sales Order' : 'Invoice'
            }:`}</h5>
            <CopyInput
              link={`${window.location.origin}/#${
                type === OrderType.SalesOrd
                  ? `${routes.expressSaleOrderPayment}/${expressCode}`
                  : `${routes.expressInvoicePayment}/${expressCode}`
              }`}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};
export default PaymentDetailsSection;
