import { HttpService } from 'services/HttpService';
import { API_URL, tenantConfig } from 'config';
import { httpRequestWrapper } from 'util/Request';
import Product from 'store/types/Products';
import AuthService from 'services/AuthService';
import ProductsCartItem from 'store/types/ProductsCartItem';

type ProductDetailsResponseData = Product[];
type ProductsCartResponseData = ProductsCartItem[];

class ProductsService {
  public static getProductDetails(ids: string[]): Promise<ProductDetailsResponseData> {
    return new Promise<ProductDetailsResponseData>(
      (resolve: (data: ProductDetailsResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<ProductDetailsResponseData>(
          HttpService.get(`${API_URL}/products/details`, {
            queryParams: { ids },
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getCart(): Promise<ProductsCartResponseData> {
    return new Promise<ProductsCartResponseData>(
      (resolve: (data: ProductsCartResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<ProductsCartResponseData>(
          HttpService.get(`${API_URL}/products/cart`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject,
          false
        );
      }
    );
  }

  public static addToCart(cartLines: ProductsCartItem[]): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.post(`${API_URL}/products/cart`, {
          data: cartLines,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static removeFromCart(productId: string): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.delete(`${API_URL}/products/cart`, {
          queryParams: { productId },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static emptyCart(): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.delete(`${API_URL}/products/cart`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }
}
export default ProductsService;
