import classNames from 'classnames';
import React, { useCallback, useContext, useMemo } from 'react';
import { Grid, TextField } from '@mui/material';
import { Controller, FieldErrors, useFormContext, useWatch } from 'react-hook-form';
import {
  getMaxLengthValidationRule,
  getRequiredValidationRule,
  getValidationProps,
  isCanadaSelected,
  isUSSelected,
  FormProps,
  getAddressControlName,
  countryFieldName,
} from 'util/Form';
import { defaultGridItemProps } from 'util/Layout';
import { ConfigContext } from 'components/ConfigGuard';
import CountryFormItem from './CountryFormItem';
import StateFormItem from './StateFormItem';
import PostalCodeFormItem from './PostalCodeFormItem';

import commonStyles from 'styles/common.module.scss';

interface AddressFormSectionProps extends FormProps {
  addressFieldName?: string;
}

const checkStatesEnabled = (countryValue: string): boolean =>
  isUSSelected(countryValue) || isCanadaSelected(countryValue);

const AddressFormSection: React.FunctionComponent<AddressFormSectionProps> = (props) => {
  const { isNccerTheme } = useContext(ConfigContext);
  const {
    inputProps,
    rowProps = defaultGridItemProps,
    required = true,
    disabled = false,
    addressFieldName = 'address',
  } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const getControlName = useCallback(
    (label: string) => getAddressControlName(addressFieldName, label),
    [addressFieldName]
  );
  const validationProps = useCallback(
    (label: string) => getValidationProps(label, addressFieldName ? (errors[addressFieldName] as FieldErrors) : errors),
    [errors, addressFieldName]
  );
  const countryValue: string = useWatch({ control, name: getControlName(countryFieldName) });
  const statesEnabled: boolean = useMemo(() => checkStatesEnabled(countryValue), [countryValue]);

  return (
    <>
      <Grid {...rowProps}>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              {...inputProps}
              {...validationProps('street1')}
              label={'Address Line 1'}
              required={required}
              disabled={disabled}
              inputProps={{ ['data-testid']: 'street1-input' }}
              data-testid={'street1-text-field'}
              FormHelperTextProps={{ ['data-testid']: 'street1-helper-text' } as any}
            />
          )}
          name={getControlName('street1')}
          control={control}
          rules={{
            required: getRequiredValidationRule('address', false, required),
            maxLength: getMaxLengthValidationRule(150),
          }}
        />
      </Grid>
      {!isNccerTheme && (
        <Grid {...rowProps}>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                {...inputProps}
                {...validationProps('street2')}
                label={'Address Line 2'}
                disabled={disabled}
                inputProps={{ ['data-testid']: 'street2-input' }}
                data-testid={'street2-text-field'}
                FormHelperTextProps={{ ['data-testid']: 'street2-helper-text' } as any}
              />
            )}
            name={getControlName('street2')}
            control={control}
            rules={{ maxLength: getMaxLengthValidationRule(150) }}
          />
        </Grid>
      )}
      <Grid {...rowProps} md={6} lg={3}>
        <CountryFormItem {...props} addressFieldName={addressFieldName} />
      </Grid>
      <Grid {...rowProps} md={6} lg={statesEnabled ? 3 : 6}>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              {...inputProps}
              {...validationProps('city')}
              label={'City'}
              required={checkStatesEnabled(countryValue) || required}
              disabled={disabled}
              inputProps={{ ['data-testid']: 'city-input' }}
              data-testid={'city-text-field'}
              FormHelperTextProps={{ ['data-testid']: 'city-helper-text' } as any}
            />
          )}
          name={getControlName('city')}
          control={control}
          rules={{
            required: getRequiredValidationRule('city', false, checkStatesEnabled(countryValue) || required),
            maxLength: getMaxLengthValidationRule(40),
          }}
        />
      </Grid>
      <Grid
        {...rowProps}
        md={6}
        lg={3}
        className={classNames({
          [commonStyles.hidden]: !checkStatesEnabled(countryValue),
        })}
      >
        <StateFormItem {...props} addressFieldName={addressFieldName} />
      </Grid>
      <Grid {...rowProps} md={6} lg={3}>
        <PostalCodeFormItem {...props} addressFieldName={addressFieldName} />
      </Grid>
    </>
  );
};
export default AddressFormSection;
