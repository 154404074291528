import React, { useContext, useMemo } from 'react';
import useRequest from 'hooks/useRequest';
import CompanyService from 'services/api/CompanyService';
import SelectOption from 'store/types/SelectOption';
import { UserContext } from './UserGuard';
import { sorter } from 'util/Table';

interface CompaniesContextProps {
  data?: SelectOption[];
  loading?: boolean;
  error?: string;
}

export const CompaniesContext = React.createContext<CompaniesContextProps>({
  loading: false,
});

const CompaniesContextWrapper: React.FunctionComponent = ({ children }) => {
  const { id = '' } = useContext(UserContext);
  const { data, loading, error } = useRequest<SelectOption[]>(id ? CompanyService.getCompanies : undefined);
  const sortedData = useMemo(() => data || [].sort(sorter<SelectOption>({ column: 'name', direction: 'asc' })), [data]);

  return <CompaniesContext.Provider value={{ data: sortedData, loading, error }}>{children}</CompaniesContext.Provider>;
};
export default CompaniesContextWrapper;
