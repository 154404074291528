import React, { ChangeEvent, Reducer, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { Button, Grid, InputAdornment, MenuItem, TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import classNames from 'classnames';
import stylesDropzone from 'components/applications/ApplicationQuestionsFormSection/FileQuestionFormItem/FileQuestionFormItem.module.scss';
import reducer, {
  ExpenseReportAction,
  ExpenseReportActionType,
  ExpenseReportState,
  initialState,
} from 'components/expense/ExpenseReportPage/ExpenseReportReducer';
import FileListDelete from 'components/expense/ExpenseReportPage/FileListDelete';
import FileListDownload from 'components/expense/ExpenseReportPage/FileListDownload';
import DateFormItem from 'components/shared/DateFormItem';
import Modal from 'components/shared/Modal';
import useRequest from 'hooks/useRequest';
import { DropzoneArea } from 'mui-file-dropzone';
import { useSnackbar } from 'notistack';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import ExpenseService from 'services/api/ExpenseService';
import routes from 'store/configs/Routes';
import { ModalProps } from 'store/types/ComponentProps';
import { ExpenseCategory, ExpenseFile, ExpenseItemSaveRequest } from 'store/types/Expense';
import { MAX_FILE_LIMIT, MAX_FILE_SIZE } from 'util/Expense';
import {
  defaultFormProps,
  FREEFORM_TEXT_MAX_LENGTH,
  getMaxLengthValidationRule,
  getRequiredValidationRule,
  getValidationProps,
  NUMBER_WHOLE_PLUS_DECIMAL_REGEXP,
} from 'util/Form';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';

import commonStyles from 'styles/common.module.scss';
import styles from 'components/expense/Expense.module.scss';

interface ExpenseFormModalProps extends ModalProps {
  expenseItem?: ExpenseItemSaveRequest | null;
  isReadOnly: boolean;
  defaultValues: ExpenseItemSaveRequest;
  headerBlock?: string;
  onSubmit: (data: ExpenseItemSaveRequest) => void;
}

const getDefaultValues = (
  expenseItem: ExpenseItemSaveRequest,
  pageLoad: boolean,
  selectedCategory?: ExpenseCategory,
  amount = ''
): ExpenseItemSaveRequest => {
  if (selectedCategory) {
    expenseItem.expense.category = selectedCategory;
  }

  if (amount && expenseItem?.expense) {
    expenseItem.expense.amount = amount;
  }

  const expense = {
    ...expenseItem.expense,
    quantity: expenseItem?.expense?.quantity || (pageLoad ? '' : null),
    rate: expenseItem?.expense?.rate || (pageLoad ? '' : null),
    amount: expenseItem?.expense?.amount || (pageLoad ? '' : '0'),
    files: expenseItem?.expense?.files ?? [],
  };

  return {
    ...expenseItem,
    expense: expense,
  } as ExpenseItemSaveRequest;
};

const ExpenseFormModal: React.FunctionComponent<ExpenseFormModalProps> = ({
  expenseItem,
  isReadOnly,
  defaultValues,
  headerBlock,
  onClose,
  onSubmit,
  open,
}) => {
  const form = useForm<ExpenseItemSaveRequest>({ ...defaultFormProps, defaultValues, shouldUnregister: false });
  const {
    control,
    handleSubmit,
    reset,
    resetField,
    formState: { errors, isDirty, isValid },
  } = form;

  const [{ amount, selectedCategory, loading: pageLoading }, dispatch] = useReducer<
    Reducer<ExpenseReportState, ExpenseReportAction>
  >(reducer, initialState);

  const {
    data: categoryOptions,
    error: categoryError,
    loading: categoryLoading,
  } = useRequest<ExpenseCategory[]>(ExpenseService.getExpenseCategories);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [fileDeleted, setFileDeleted] = useState<boolean>(false);
  const [key, setKey] = useState('');

  const submitButtonDisabled: boolean = useMemo(
    () => !fileDeleted && (!isDirty || !isValid || categoryLoading || pageLoading),
    [fileDeleted, isDirty, isValid, categoryLoading, pageLoading]
  );

  useEffect(() => {
    if (categoryOptions && expenseItem?.expense?.category) {
      dispatch({
        type: ExpenseReportActionType.SetCategory,
        payload: { selectedCategory: expenseItem.expense.category },
      });
    }
  }, [categoryOptions, expenseItem?.expense?.category]);

  useEffect(() => {
    if (expenseItem) {
      if (expenseItem?.expense) {
        if (expenseItem.expense?.amount !== '') {
          expenseItem.expense.amount = Number(expenseItem.expense.amount).toFixed(2);
        }
      } else {
        expenseItem.expense = defaultValues.expense;
      }

      reset(getDefaultValues(expenseItem, true));

      dispatch({
        type: ExpenseReportActionType.SetAmount,
        payload: { amount: expenseItem.expense.amount },
      });
    }
  }, [defaultValues.expense, expenseItem, reset]);

  const handleModalClose = useCallback(() => {
    onClose();

    if (expenseItem) {
      reset(getDefaultValues(expenseItem, true));
    } else {
      reset(defaultValues);
    }
    setFileDeleted(false);
    setKey(Date.now().toString());
  }, [defaultValues, expenseItem, onClose, reset]);

  const handleFileChange = useCallback(
    (onChange) => (loadedFiles: File[]) => {
      onChange(loadedFiles);
    },
    []
  );

  const handleFileDelete = useCallback(() => {
    if (expenseItem?.expense.fileId && expenseItem?.expense.filename) {
      setFileDeleted(true);
    }
  }, [expenseItem]);

  const uploadFile = useCallback((data: ExpenseItemSaveRequest) => {
    if (data?.expense?.files?.length === 1) {
      return ExpenseService.uploadFile(data.id, data.expense.files[0]);
    } else {
      return Promise.resolve('');
    }
  }, []);

  const handleFormSubmit = useCallback(
    (data: ExpenseItemSaveRequest, closeModal: boolean) => {
      data = getDefaultValues(data, false, selectedCategory, amount);

      dispatch({
        type: ExpenseReportActionType.SetLoading,
        payload: { loading: true },
      });

      if (fileDeleted) {
        data.expense.fileId = '';
        data.expense.filename = '';
      }

      uploadFile(data)
        .then((newFileId) => {
          if (newFileId) {
            data.expense.fileId = newFileId;
          }

          if (!data?.id) {
            ExpenseService.createExpenseReport(data)
              .then((newReportId) => {
                onSubmit(data);
                dispatch({
                  type: ExpenseReportActionType.SetLoading,
                  payload: { loading: false },
                });

                if (closeModal) {
                  handleModalClose();
                } else {
                  data.id = newReportId;
                  data.expense = defaultValues.expense;
                  reset(data);
                }
                enqueueSnackbar('Expense report successfully created', { variant: 'success' });
                history.push(`${routes.expenseReport}/${newReportId}`);
              })
              .catch((errorMessage: string) => {
                dispatch({
                  type: ExpenseReportActionType.SetLoading,
                  payload: { loading: false },
                });
                enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
              });
          } else if (data.expense.id) {
            ExpenseService.updateExpenseItem(data)
              .then(() => {
                onSubmit(data);
                dispatch({
                  type: ExpenseReportActionType.SetLoading,
                  payload: { loading: false },
                });
                handleModalClose();
                enqueueSnackbar('Expense successfully updated', { variant: 'success' });
              })
              .catch((errorMessage: string) => {
                dispatch({
                  type: ExpenseReportActionType.SetLoading,
                  payload: { loading: false },
                });
                enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
              });
          } else {
            ExpenseService.createExpenseItem(data)
              .then(() => {
                onSubmit(data);
                dispatch({
                  type: ExpenseReportActionType.SetLoading,
                  payload: { loading: false },
                });
                if (closeModal) {
                  handleModalClose();
                } else {
                  data.expense = defaultValues.expense;
                  reset(data);
                }
                enqueueSnackbar('Expense successfully created', { variant: 'success' });
              })
              .catch((errorMessage: string) => {
                dispatch({
                  type: ExpenseReportActionType.SetLoading,
                  payload: { loading: false },
                });
                enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
              });
          }
        })
        .catch((errorMessage: string) => {
          dispatch({
            type: ExpenseReportActionType.SetLoading,
            payload: { loading: false },
          });
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        });
    },
    [
      amount,
      defaultValues.expense,
      enqueueSnackbar,
      fileDeleted,
      handleModalClose,
      history,
      onSubmit,
      reset,
      selectedCategory,
      uploadFile,
    ]
  );

  const handleFormSubmitModalOpen = useCallback(
    (data: ExpenseItemSaveRequest) => {
      handleFormSubmit(data, false);
    },
    [handleFormSubmit]
  );

  const handleFormSubmitModalClosed = useCallback(
    (data: ExpenseItemSaveRequest) => {
      handleFormSubmit(data, true);
    },
    [handleFormSubmit]
  );

  const handleCategoryChange = useCallback(
    (onChange) => (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);

      if (categoryOptions) {
        const category = categoryOptions.find((item) => item.id === e.target.value);

        dispatch({
          type: ExpenseReportActionType.SetCategory,
          payload: { selectedCategory: category },
        });

        if (category?.isQtyRateCategory) {
          dispatch({
            type: ExpenseReportActionType.SetAmount,
            payload: {},
          });
          resetField('expense.amount');
        }
      }
    },
    [categoryOptions, resetField]
  );

  const handleAmountChange = useCallback(
    (onChange) => (e: ChangeEvent<HTMLInputElement>) => {
      dispatch({
        type: ExpenseReportActionType.SetAmount,
        payload: { amount: e.target.value },
      });

      onChange(e.target.value);
    },
    []
  );

  return categoryError ? (
    <Alert severity={'error'} className={commonStyles.alert}>
      {categoryError}
    </Alert>
  ) : (
    <Modal
      title={`${expenseItem?.expense?.id !== '' ? 'Edit ' : 'New '}Expense Item`}
      keepMounted={true}
      open={open}
      loading={categoryLoading || pageLoading}
      onClose={handleModalClose}
      maxWidth={'md'}
      actions={
        <>
          {!isReadOnly && (
            <Button
              color={'secondary'}
              variant={'outlined'}
              disabled={categoryLoading || pageLoading}
              onClick={onClose}
            >
              {'Cancel'}
            </Button>
          )}

          {expenseItem?.expense?.id === '' && !isReadOnly && (
            <Button
              color={'secondary'}
              variant={'contained'}
              disabled={submitButtonDisabled}
              className={styles.createNewExpenseButton}
              onClick={handleSubmit(handleFormSubmitModalOpen)}
            >
              {'Add & Create New'}
            </Button>
          )}

          {!isReadOnly && (
            <Button
              color={'secondary'}
              variant={'contained'}
              disabled={submitButtonDisabled}
              onClick={handleSubmit(handleFormSubmitModalClosed)}
            >
              {`${expenseItem?.expense?.id === '' ? 'Add' : 'Save'}`}
            </Button>
          )}
        </>
      }
    >
      <FormProvider {...form}>
        <Grid {...defaultGridContainerProps}>
          {headerBlock && (
            <Grid {...defaultGridItemProps}>
              <div dangerouslySetInnerHTML={{ __html: headerBlock }} />
            </Grid>
          )}
          <Grid {...defaultGridItemProps}>
            <Grid {...defaultGridItemProps} sm={12} md={4}>
              <DateFormItem fieldName={'expense.date'} label={'Date'} disabled={isReadOnly} />
            </Grid>
          </Grid>
          {!!categoryOptions?.length && (
            <Grid {...defaultGridItemProps} sm={12} md={6}>
              <Controller
                name={'expense.category.id'}
                control={control}
                rules={{
                  required: getRequiredValidationRule('Category'),
                }}
                render={({ field: { onChange, value, onBlur } }) => (
                  <TextField
                    {...getValidationProps('expense.category.id', errors)}
                    onBlur={onBlur}
                    select={true}
                    label={'Category'}
                    value={value}
                    onChange={handleCategoryChange(onChange)}
                    required={true}
                    disabled={isReadOnly}
                    SelectProps={{
                      displayEmpty: true,
                    }}
                  >
                    {categoryOptions.map(({ id, name }) => (
                      <MenuItem key={`expense-category-${id}`} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
          )}
          {selectedCategory?.isQtyRateCategory && (
            <>
              <Grid {...defaultGridItemProps} sm={12} md={3}>
                <Controller
                  name={'expense.quantity'}
                  control={control}
                  rules={{
                    required: getRequiredValidationRule('Quantity', false, selectedCategory?.isQtyRateCategory),
                    min: {
                      value: 0.01,
                      message: 'Please input valid quantity',
                    },
                    pattern: {
                      value: NUMBER_WHOLE_PLUS_DECIMAL_REGEXP,
                      message: 'Please input valid quantity',
                    },
                  }}
                  render={({ field }) => {
                    return (
                      <TextField
                        label={'Quantity'}
                        placeholder={'0'}
                        required={selectedCategory?.isQtyRateCategory}
                        disabled={isReadOnly}
                        {...field}
                        {...getValidationProps('expense.quantity', errors)}
                        InputProps={{
                          inputProps: { min: 0 },
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid {...defaultGridItemProps} sm={12} md={3}>
                <Controller
                  name={'expense.rate'}
                  control={control}
                  rules={{
                    required: getRequiredValidationRule('Rate', false, selectedCategory?.isQtyRateCategory),
                    min: {
                      value: 0.01,
                      message: 'Please input valid rate',
                    },
                    pattern: {
                      value: NUMBER_WHOLE_PLUS_DECIMAL_REGEXP,
                      message: 'Please input valid rate',
                    },
                  }}
                  render={({ field }) => {
                    return (
                      <TextField
                        label={'Rate'}
                        placeholder={'0'}
                        required={selectedCategory?.isQtyRateCategory}
                        disabled={isReadOnly}
                        {...field}
                        {...getValidationProps('expense.rate', errors)}
                        InputProps={{
                          inputProps: { min: 0 },
                          startAdornment: <InputAdornment position={'start'}>$</InputAdornment>,
                        }}
                      />
                    );
                  }}
                />
              </Grid>
            </>
          )}
          <Grid {...defaultGridItemProps} sm={12} md={4}>
            <Controller
              name={'expense.amount'}
              control={control}
              rules={{
                required: getRequiredValidationRule('Amount', false, !selectedCategory?.isQtyRateCategory),
                min: {
                  value: 0.01,
                  message: 'Please input valid amount',
                },
                pattern: {
                  value: NUMBER_WHOLE_PLUS_DECIMAL_REGEXP,
                  message: 'Please input valid amount',
                },
              }}
              render={({ field: { onChange, onBlur } }) => (
                <TextField
                  onBlur={onBlur}
                  label={'Amount'}
                  placeholder={'0'}
                  value={amount}
                  onChange={handleAmountChange(onChange)}
                  required={!selectedCategory?.isQtyRateCategory}
                  {...getValidationProps('expense.amount', errors)}
                  disabled={isReadOnly || selectedCategory?.isQtyRateCategory}
                  InputProps={{
                    inputProps: { min: 0 },
                    startAdornment: <InputAdornment position={'start'}>$</InputAdornment>,
                  }}
                />
              )}
            />
          </Grid>
          <Grid {...defaultGridItemProps}>
            <Controller
              name={'expense.memo'}
              control={control}
              rules={{
                required: getRequiredValidationRule('Memo'),
                maxLength: getMaxLengthValidationRule(FREEFORM_TEXT_MAX_LENGTH),
              }}
              render={({ field }) => (
                <TextField
                  label={'Memo'}
                  {...field}
                  {...getValidationProps('expense.memo', errors)}
                  required={true}
                  disabled={isReadOnly}
                  multiline={true}
                  minRows={3}
                />
              )}
            />
          </Grid>
          {!isReadOnly && (
            <Grid {...defaultGridItemProps} sm={'auto'} className={stylesDropzone.uploadFilesWrapper}>
              <h2>Attach Receipt</h2>

              <Controller
                name={'expense.files'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DropzoneArea
                    key={`upload-${key}`}
                    onChange={handleFileChange(onChange)}
                    onDelete={onChange}
                    dropzoneText={'Upload Files'}
                    maxFileSize={MAX_FILE_SIZE}
                    filesLimit={MAX_FILE_LIMIT}
                    fileObjects={value}
                    showAlerts={['error']}
                    clearOnUnmount={true}
                    showPreviews={true}
                    showPreviewsInDropzone={false}
                    useChipsForPreview={true}
                    previewText={''}
                    classes={{
                      root: classNames(stylesDropzone.dropzone),
                      textContainer: stylesDropzone.dropzoneTextContainer,
                      text: stylesDropzone.dropzoneText,
                      icon: stylesDropzone.dropzoneIcon,
                    }}
                    previewGridClasses={{
                      container: stylesDropzone.previewContainer,
                      item: stylesDropzone.previewImageContainer,
                    }}
                    dropzoneProps={{ noDrag: true }}
                    previewChipProps={{ className: stylesDropzone.chip }}
                    alertSnackbarProps={{
                      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    }}
                  />
                )}
              />
            </Grid>
          )}

          {expenseItem?.expense?.filename && !fileDeleted && (
            <>
              <Grid {...defaultGridItemProps} xs={10} className={styles.value}>
                <FileListDownload
                  expenseReportId={expenseItem.id}
                  expenseId={expenseItem.expense.id}
                  filename={expenseItem.expense.filename}
                />
              </Grid>
              <Grid {...defaultGridItemProps}>
                <FileListDelete
                  file={
                    {
                      id: expenseItem.expense.fileId,
                      name: expenseItem.expense.filename,
                    } as ExpenseFile
                  }
                  isReadOnly={isReadOnly}
                  onDeleteFile={handleFileDelete}
                />
              </Grid>
            </>
          )}
        </Grid>
      </FormProvider>
    </Modal>
  );
};

export default ExpenseFormModal;
