import { Grid, TextField } from '@mui/material';
import classNames from 'classnames';
import React, { ChangeEvent, useCallback, useContext, useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { getRequiredInputMessage, getValidationProps } from 'util/Form';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { UserFormValues } from 'store/types/FormValues';
import EmailFormItem from 'components/profile/EmailFormItem';
import UserNameFormSection from 'components/profile/UserNameFormSection';
import AddressFormSection from 'components/shared/AddressFormSection';
import { UserContext } from 'components/UserGuard';
import Card from 'components/shared/Card';
import { CONFIRM_EMAIL_FIELD_NAME, EMAIL_FIELD_NAME } from 'store/configs/FormFieldNames';

import commonStyles from 'styles/common.module.scss';
import pageStyles from '../DonationPaymentPageView.module.scss';
import styles from './DonationUserProfileSection.module.scss';

interface DonationUserProfileSectionProps {
  loginError?: string;
  disabled?: boolean;
}

const DonationUserProfileSection: React.FunctionComponent<DonationUserProfileSectionProps> = ({
  loginError = '',
  disabled = false,
}) => {
  const currentUser = useContext(UserContext);
  const {
    control,
    getValues,
    trigger,
    formState: { dirtyFields, errors },
  } = useFormContext<UserFormValues>();
  const hasAuthorizedUser = !!currentUser.id;
  const emailValue: string = useWatch({ control, name: EMAIL_FIELD_NAME });

  const validateField = useCallback(
    (value?: string) => {
      if (hasAuthorizedUser) {
        return true;
      }
      if (!value) {
        return getRequiredInputMessage('email confirm');
      } else {
        return getValues(EMAIL_FIELD_NAME) === value || 'Emails must match';
      }
    },
    [getValues, hasAuthorizedUser]
  );

  useEffect(() => {
    if (dirtyFields[CONFIRM_EMAIL_FIELD_NAME]) {
      trigger(CONFIRM_EMAIL_FIELD_NAME);
    }
  }, [trigger, emailValue, dirtyFields]);

  const handleCopy = useCallback((e) => {
    e.preventDefault();
  }, []);

  const handleChange = useCallback(
    (onChange) => (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value.replace(/\s/g, '')),
    []
  );

  return (
    <Card contentClassName={pageStyles.cardContent}>
      <form>
        <Grid {...defaultGridContainerProps}>
          <UserNameFormSection disabled={disabled} prefixAndSuffixVisible={false} />
          <AddressFormSection disabled={disabled} />
          <Grid {...defaultGridItemProps} md={6}>
            <EmailFormItem disabled={hasAuthorizedUser || disabled} copyDisabled={true} />
          </Grid>
          {!hasAuthorizedUser && (
            <>
              <Grid {...defaultGridItemProps} md={6}>
                <Controller
                  render={({ field: { onChange, value, onBlur } }) => (
                    <TextField
                      {...getValidationProps(CONFIRM_EMAIL_FIELD_NAME, errors)}
                      label={'Confirm Email Address'}
                      required={true}
                      value={value}
                      disabled={!emailValue.length}
                      onChange={handleChange(onChange)}
                      onBlur={onBlur}
                      onCopy={handleCopy}
                      onPaste={handleCopy}
                    />
                  )}
                  name={CONFIRM_EMAIL_FIELD_NAME}
                  control={control}
                  rules={{ validate: validateField }}
                />
              </Grid>
              <Grid {...defaultGridItemProps}>
                <p className={classNames(commonStyles.text, { [styles.error]: !!loginError })}>
                  {loginError ||
                    'Email address entered above will be used for account creation. After completing your donation, you will receive an email with password setup instructions.'}
                </p>
              </Grid>
            </>
          )}
        </Grid>
      </form>
    </Card>
  );
};
export default DonationUserProfileSection;
