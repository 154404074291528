import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, FormControl, FormControlLabel, Grid, TextField } from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import classNames from 'classnames';
import Modal from 'components/shared/Modal';
import { ModalProps } from 'store/types/ComponentProps';
import {
  defaultFormProps,
  getAddressFormValues,
  getMaxLengthValidationRule,
  getRequiredValidationRule,
  getValidationProps,
} from 'util/Form';
import { useSnackbar } from 'notistack';
import AddressFormSection from 'components/shared/AddressFormSection';
import PhoneFormItem from 'components/profile/PhoneFormItem';
import { ConfigContext } from 'components/ConfigGuard';
import AddressesService from 'services/api/AddressesService';
import { BillingAddress } from 'store/types/Address';
import DeleteAddressModal from 'components/addresses/DeleteAddressModal';
import { UserContext } from 'components/UserGuard';
import CompanyService from 'services/api/CompanyService';
import { CurrentUser } from 'store/types/User';
import { getUserFullName } from 'util/Format';
import { CompanySelectOption } from 'util/Company';

import styles from './EditAddressModal.module.scss';
import commonStyles from 'styles/common.module.scss';

interface AddAddressModalProps extends ModalProps {
  onSubmit: () => void;
  data?: BillingAddress;
  isFirstAddress?: boolean;
  selectedCompany?: CompanySelectOption;
}

const getAddresseeDefaultValue = (currentUser: CurrentUser, selectedCompany?: CompanySelectOption): string =>
  !selectedCompany ? getUserFullName(currentUser, true) : selectedCompany.label;

const getDefaultFormValues = (
  currentUser: CurrentUser,
  selectedCompany?: CompanySelectOption,
  data?: Partial<BillingAddress>
): BillingAddress => ({
  addressee: getAddresseeDefaultValue(currentUser, selectedCompany),
  phone: '',
  label: '',
  id: '',
  isDefaultBilling: false,
  isDefaultShipping: false,
  isCongressionalInterest: false,
  isVotingDistrict: false,
  ...data,
  ...getAddressFormValues({ country: data?.country || 'US', ...data }),
});

const EditAddressModal: React.FunctionComponent<AddAddressModalProps> = ({
  onClose,
  open,
  data,
  onSubmit,
  isFirstAddress,
  selectedCompany,
}) => {
  const {
    isNccerTheme,
    isSnaTheme,
    modulesSettings: {
      addresses: { enableSelectDefaultAddress },
    },
  } = useContext(ConfigContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useContext(UserContext);
  const defaultValues = useMemo(
    () => getDefaultFormValues(currentUser, selectedCompany, data),
    [currentUser, data, selectedCompany]
  );
  const { isDefaultShipping, isDefaultBilling } = defaultValues;
  const form = useForm<BillingAddress>({ ...defaultFormProps, defaultValues, shouldUnregister: false });
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { isValid, isDirty, errors },
  } = form;

  useEffect(() => {
    if (!data) {
      setValue('isCongressionalInterest', true);
    }
  }, [data, setValue]);

  useEffect(() => {
    setValue('isDefaultBilling', isFirstAddress);
    setValue('isDefaultShipping', isFirstAddress);
  }, [setValue, isFirstAddress, data]);

  useEffect(() => {
    if (data) {
      reset(getDefaultFormValues(currentUser, selectedCompany, data));
    }
  }, [currentUser, data, reset, selectedCompany]);

  const handleClose = useCallback(() => {
    onClose();
    reset(
      getDefaultFormValues(currentUser, selectedCompany, {
        isDefaultShipping: isFirstAddress,
        isDefaultBilling: isFirstAddress,
      })
    );
  }, [onClose, reset, currentUser, selectedCompany, isFirstAddress]);

  const handleSubmitAddress = useCallback(
    (formValues: BillingAddress) => {
      setLoading(true);
      const request = data
        ? selectedCompany?.id
          ? () => CompanyService.updateCompanyAddress({ ...formValues, id: data.id }, selectedCompany.id)
          : () => AddressesService.updateAddress({ ...formValues, id: data.id })
        : selectedCompany?.id
        ? () => CompanyService.createCompanyAddress({ ...formValues }, selectedCompany.id)
        : () => AddressesService.addAddress(formValues);
      request()
        .then(() => {
          if (!selectedCompany?.id && formValues.isDefaultBilling) {
            currentUser.refetchUser();
          }
          setLoading(false);
          onSubmit();
          enqueueSnackbar(`Address successfully ${data ? 'updated' : 'added'}`, { variant: 'success' });
        })
        .catch((error: string) => {
          setLoading(false);
          enqueueSnackbar(error, defaultSnackbarErrorProps);
        });
    },
    [data, selectedCompany, onSubmit, enqueueSnackbar, currentUser]
  );

  const handleConfirmDeleteOpen = useCallback(() => {
    setDeleteModalOpen(true);
  }, []);

  const handleConfirmModalClose = useCallback(() => {
    setDeleteModalOpen(false);
  }, []);

  const handleDeleteModalSubmit = useCallback(() => {
    setDeleteModalOpen(false);
    reset(getDefaultFormValues(currentUser));
    onSubmit();
  }, [currentUser, onSubmit, reset]);

  const handleChange = useCallback((onChange) => (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.checked), []);

  return (
    <>
      <FormProvider {...form}>
        <Modal
          onClose={handleClose}
          open={open}
          loading={loading}
          maxWidth={'md'}
          title={data ? 'Edit Address' : 'Add a New Address'}
          actions={
            <>
              <Button color={'secondary'} variant={'outlined'} onClick={handleClose} disabled={loading}>
                {'Cancel'}
              </Button>
              {data && !isDefaultBilling && (
                <Button
                  onClick={handleConfirmDeleteOpen}
                  disabled={loading}
                  variant={'outlined'}
                  className={classNames(commonStyles.dangerButtonOutlined, styles.deleteButton)}
                >
                  {'Delete Address'}
                </Button>
              )}
              <Button
                color={'secondary'}
                variant={'contained'}
                onClick={handleSubmit(handleSubmitAddress)}
                disabled={loading || !isValid || !isDirty}
              >
                {data ? 'Save' : 'Add Address'}
              </Button>
            </>
          }
        >
          <Grid {...defaultGridContainerProps} spacing={5}>
            <Grid {...defaultGridItemProps}>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    {...getValidationProps('label', errors)}
                    label={isNccerTheme ? 'Credit Card Nickname' : 'Label (My nickname for this address)'}
                    required={true}
                  />
                )}
                name={'label'}
                control={control}
                rules={{
                  required: getRequiredValidationRule(isNccerTheme ? 'credit card nickname' : 'label', false, true),
                  maxLength: getMaxLengthValidationRule(40),
                }}
              />
            </Grid>
            <Grid {...defaultGridItemProps}>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    {...getValidationProps('addressee', errors)}
                    label={isNccerTheme ? 'Name on Card' : 'Addressee'}
                    required={true}
                  />
                )}
                name={'addressee'}
                control={control}
                rules={{
                  required: getRequiredValidationRule(isNccerTheme ? 'name on card' : 'addressee', false, true),
                  maxLength: getMaxLengthValidationRule(100),
                }}
              />
            </Grid>
            <AddressFormSection required={true} addressFieldName={''} />
            {!isSnaTheme && (
              <Grid {...defaultGridItemProps} md={isNccerTheme ? 7 : 5}>
                <PhoneFormItem label={isNccerTheme ? 'Mobile Number' : undefined} />
              </Grid>
            )}
            {enableSelectDefaultAddress && (
              <Grid {...defaultGridItemProps} className={styles.checkboxes}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <Checkbox
                            color={'primary'}
                            onChange={handleChange(onChange)}
                            checked={value}
                            disabled={isDefaultBilling || isFirstAddress}
                          />
                        )}
                        control={control}
                        name={'isDefaultBilling'}
                      />
                    }
                    label={'Make this my default billing address'}
                  />
                  {!selectedCompany?.id && (
                    <span className={styles.helperText}>
                      {'Setting this as my default will replace my profile address'}
                    </span>
                  )}
                </FormControl>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <Checkbox
                            color={'primary'}
                            onChange={handleChange(onChange)}
                            checked={value}
                            disabled={isDefaultShipping || isFirstAddress}
                          />
                        )}
                        control={control}
                        name={'isDefaultShipping'}
                      />
                    }
                    label={'Make this my default shipping address'}
                  />
                </FormControl>
                {selectedCompany?.id && (
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <Checkbox
                              color={'primary'}
                              onChange={handleChange(onChange)}
                              checked={value}
                              disabled={isFirstAddress}
                            />
                          )}
                          control={control}
                          name={'isCongressionalInterest'}
                        />
                      }
                      label={'Add address to my Congressional Districts of Interest'}
                    />
                  </FormControl>
                )}
                {!selectedCompany?.id && (
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <Checkbox color={'primary'} onChange={handleChange(onChange)} checked={value} />
                          )}
                          control={control}
                          name={'isVotingDistrict'}
                        />
                      }
                      label={'This is my primary residence and voting district'}
                    />
                  </FormControl>
                )}
              </Grid>
            )}
          </Grid>
        </Modal>
      </FormProvider>
      {data && (
        <DeleteAddressModal
          companyId={selectedCompany?.id}
          addressId={data.id}
          onSubmit={handleDeleteModalSubmit}
          open={deleteModalOpen}
          onClose={handleConfirmModalClose}
        />
      )}
    </>
  );
};
export default EditAddressModal;
