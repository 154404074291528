import { FilterConfig, SortConfig } from 'store/types/Table';
import { sorter, userFullNameSortLabel } from 'util/Table';
import PaymentUser from 'store/types/PaymentUser';
import { getUserFullName } from 'util/Format';

export enum PaymentUsersTableActionType {
  SetInitialList = 'SetInitialList',
  UpdateSort = 'UpdateSort',
  UpdateFilter = 'UpdateFilter',
  ShowRequestModal = 'ShowRequestModal',
}

export type TablePaymentUser = PaymentUser & { [userFullNameSortLabel]?: string };

export interface PaymentUsersTableState {
  initialList: PaymentUser[];
  list: PaymentUser[];
  sort: SortConfig<TablePaymentUser>;
  filter: FilterConfig<TablePaymentUser>;
  requestModalOpen?: boolean;
}

export interface PaymentUsersTableAction {
  type: PaymentUsersTableActionType;
  payload: Partial<PaymentUsersTableState>;
}

const getSortedList = (list: PaymentUser[] = [], sort: SortConfig<TablePaymentUser>): PaymentUser[] =>
  list.length ? [...list].sort(sorter<PaymentUser>(sort)) : [...list];

const getFilteredList = (list: PaymentUser[] = [], filter: FilterConfig<TablePaymentUser>) => {
  let result = [...list];
  const searchValue = (filter.search || '').toLowerCase();

  if (searchValue) {
    result = result.filter(({ firstName, lastName }: PaymentUser) =>
      getUserFullName({ firstName, lastName }).toLowerCase().includes(searchValue)
    );
  }
  return result;
};

export const initialState: PaymentUsersTableState = {
  initialList: [],
  list: [],
  sort: { direction: 'asc', column: userFullNameSortLabel },
  filter: { search: '' },
};

const reducer = (state: PaymentUsersTableState, { type, payload }: PaymentUsersTableAction): PaymentUsersTableState => {
  if (type === PaymentUsersTableActionType.SetInitialList) {
    const { initialList = [] } = payload;
    const list = getSortedList(initialList, state.sort);

    return { ...state, initialList, list };
  }

  if (type === PaymentUsersTableActionType.UpdateSort) {
    const { sort = {} } = payload;

    return {
      ...state,
      sort,
      list: getSortedList(state.initialList, sort),
    };
  }

  if (type === PaymentUsersTableActionType.UpdateFilter) {
    const { filter = {} } = payload;
    const newFilter = { ...state.filter, ...filter };

    return {
      ...state,
      filter: newFilter,
      list: getFilteredList(getSortedList(state.initialList, state.sort), newFilter),
    };
  }

  if (type === PaymentUsersTableActionType.ShowRequestModal) {
    const { requestModalOpen = false } = payload;

    return { ...state, requestModalOpen };
  }

  return state;
};

export default reducer;
