import { Container, Grid, MenuItem, TextField } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import classNames from 'classnames';
import React, { ChangeEvent, Reducer, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import { MainEventView } from 'store/types/events/Event';
import { ALL_EVENT_MODES_VALUE } from 'util/Event';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { getPagesCount } from 'util/Table';
import SearchInput from 'components/shared/SearchInput';
import EventCatalogCard from './EventCatalogCard';
import EventCatalogFilterBar from 'components/events/EventCatalogFilterBar';
import reducer, {
  EventCatalogPageViewAction,
  EventCatalogPageViewActionType,
  EventCatalogPageViewState,
  initialState,
} from './EventCatalogPageViewReducer';
import { useHistory, useLocation } from 'react-router';
import { EventsCategories } from 'store/types/events/EventFilter';
import { ConfigContext } from 'components/ConfigGuard';

import styles from './EventCatalogPageView.module.scss';

interface EventsSearchPageViewProps {
  data: MainEventView[];
  categories: EventsCategories[];
}

const EventCatalogPageView: React.FunctionComponent<EventsSearchPageViewProps> = ({ data, categories }) => {
  const [{ filter, list = [], pageList = [], page, itemsPerPage, sort }, dispatch] = useReducer<
    Reducer<EventCatalogPageViewState, EventCatalogPageViewAction>
  >(reducer, initialState);
  const {
    modulesSettings: {
      events: { pageTitle, pageSubTitle },
    },
  } = useContext(ConfigContext);
  const pagesCount: number = useMemo(() => getPagesCount(list.length, itemsPerPage), [itemsPerPage, list.length]);
  const { pathname, search } = useLocation();
  const history = useHistory();
  const eventsCount: number = categories.reduce((prevValue, { count }) => prevValue + count, 0);
  const allEventsCategory: EventsCategories = useMemo(
    () => ({
      name: ALL_EVENT_MODES_VALUE,
      id: ALL_EVENT_MODES_VALUE,
      count: eventsCount,
    }),
    [eventsCount]
  );

  useEffect(() => {
    dispatch({
      type: EventCatalogPageViewActionType.SetInitialList,
      payload: { initialList: data.filter(({ showInCatalog }) => !!showInCatalog) },
    });
  }, [data]);

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: EventCatalogPageViewActionType.UpdateFilter,
      payload: { filter: { search: e.target.value } },
    });
  }, []);

  const handleItemsPerPageChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: EventCatalogPageViewActionType.UpdateItemsPerPage,
      payload: { itemsPerPage: parseInt(e.target.value, 10) },
    });
  }, []);

  const handleSortChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: EventCatalogPageViewActionType.UpdateSort,
      payload: { sort: { column: e.target.value as keyof MainEventView, direction: 'asc' } },
    });
  }, []);

  const handlePageChange = useCallback((_: any, newPage: number) => {
    window.scrollTo(0, 0);
    dispatch({
      type: EventCatalogPageViewActionType.UpdatePage,
      payload: { page: newPage },
    });
  }, []);

  useEffect(() => {
    const params: URLSearchParams = new URLSearchParams(search);
    const mode: string = params.get('mode') || ALL_EVENT_MODES_VALUE;

    dispatch({
      type: EventCatalogPageViewActionType.UpdateFilter,
      payload: { filter: { mode } },
    });
  }, [search]);

  const getBookmark = useCallback(
    ({ name, count, id }: EventsCategories) => {
      const handleClick = () => {
        const newParams: URLSearchParams = new URLSearchParams(search);
        if (id) {
          id === ALL_EVENT_MODES_VALUE ? newParams.delete('mode') : newParams.set('mode', id);
        }
        history.replace({ pathname, search: `?${newParams.toString()}` });
      };

      return (
        <div
          className={classNames(styles.bookmark, { [styles.active]: id === filter.mode })}
          key={name}
          onClick={handleClick}
        >
          <span className={styles.count}>{count}</span>
          <span className={styles.label}>{name}</span>
        </div>
      );
    },
    [filter.mode, history, pathname, search]
  );

  return (
    <div className={styles.container}>
      <section className={styles.banner}>
        <Container maxWidth={'lg'} className={styles.bannerContent}>
          <div className={styles.titleContainer}>
            <h1 className={styles.title}>{pageTitle}</h1>
            {pageSubTitle && (
              <div className={styles.subTitleBox}>
                <span>{pageSubTitle}</span>
              </div>
            )}
          </div>
          <div>
            <Grid {...defaultGridContainerProps} justifyContent={'flex-end'} alignItems={'center'}>
              <Grid {...defaultGridItemProps} md={4}>
                <SearchInput
                  value={filter.search}
                  inputClassName={styles.searchInput}
                  onChange={handleSearchChange}
                  placeholder={'Search by Name'}
                />
              </Grid>
              <Grid {...defaultGridItemProps} md={8} className={styles.bookmarksRow}>
                {[allEventsCategory, ...categories].map(getBookmark)}
              </Grid>
            </Grid>
          </div>
        </Container>
      </section>
      <section className={styles.searchWrapper}>
        <EventCatalogFilterBar className={styles.sidebar} />
        <div className={styles.content}>
          <Grid {...defaultGridContainerProps} justifyContent={'flex-end'}>
            <Grid {...defaultGridItemProps} sm={'auto'}>
              <div className={styles.select}>
                <span className={styles.selectLabel}>{'Order By'}</span>
                <TextField
                  select={true}
                  variant={'outlined'}
                  size={'small'}
                  value={sort.column}
                  onChange={handleSortChange}
                >
                  <MenuItem value={'startDate'}>{'Date'}</MenuItem>
                  <MenuItem value={'title'}>{'Name'}</MenuItem>
                </TextField>
              </div>
            </Grid>
            {eventsCount ? (
              pageList.length ? (
                pageList.map((item, index) => (
                  <Grid {...defaultGridItemProps} key={`event-${item.id}-${index}`}>
                    <EventCatalogCard data={item} />
                  </Grid>
                ))
              ) : (
                <Grid {...defaultGridItemProps} className={styles.noData}>
                  <p>{'There are no results matching your search criteria.'}</p>
                </Grid>
              )
            ) : (
              <Grid {...defaultGridItemProps} className={styles.noData}>
                <p>{'There are no events to display yet, wait for the staff to set up some.'}</p>
              </Grid>
            )}
            <Grid {...defaultGridItemProps} sm={true} className={styles.paginationWrapper}>
              <Pagination
                showFirstButton={true}
                showLastButton={true}
                count={pagesCount}
                page={page}
                onChange={handlePageChange}
              />
            </Grid>
            <Grid {...defaultGridItemProps} sm={'auto'} className={styles.pageSelectWrapper}>
              <div className={styles.select}>
                <span className={styles.selectLabel}>Show</span>
                <TextField
                  select={true}
                  variant={'outlined'}
                  size={'small'}
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
                  <MenuItem value={10}>{'10'}</MenuItem>
                  <MenuItem value={20}>{'20'}</MenuItem>
                  <MenuItem value={50}>{'50'}</MenuItem>
                  <MenuItem value={100}>{'100'}</MenuItem>
                </TextField>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
    </div>
  );
};
export default EventCatalogPageView;
