import { Box, Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { defaultGridContainerProps, defaultSnackbarErrorProps } from 'util/Layout';

import commonStyles from 'styles/common.module.scss';
import PaymentService from 'services/api/PaymentService';
import { useSnackbar } from 'notistack';

interface PromoCodeSectionProps {
  setPromoCodeId: (value: string) => void;
}

const PromoCodeSection: React.FunctionComponent<PromoCodeSectionProps> = ({ setPromoCodeId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [promoCode, setPromoCode] = useState<string>('');
  const [promoCodeApplied, setPromoCodeApplied] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const disableApplyButton = useMemo(
    () => promoCode == '' || promoCodeApplied || loading,
    [loading, promoCode, promoCodeApplied]
  );

  const disableRemoveButton = useMemo(() => !promoCodeApplied || loading, [loading, promoCodeApplied]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPromoCode(e.target.value);
  }, []);

  const applyCode = useCallback(() => {
    setLoading(true);
    PaymentService.validatePromotion(promoCode)
      .then((promotionId) => {
        if (parseInt(promotionId) > 0) {
          setPromoCodeApplied(true);
          setPromoCodeId(promotionId);
          enqueueSnackbar('Discount code applied', { variant: 'success' });
          setLoading(false);
        } else {
          enqueueSnackbar('Invalid discount code', defaultSnackbarErrorProps);
          setLoading(false);
        }
      })
      .catch((error: string) => {
        enqueueSnackbar(error, defaultSnackbarErrorProps);
        setLoading(false);
      });
  }, [enqueueSnackbar, promoCode, setPromoCodeId]);

  const removeCode = useCallback(() => {
    setPromoCode('');
    setPromoCodeApplied(false);
    setPromoCodeId('');
  }, [setPromoCodeId]);

  return (
    <>
      <Box mt={2}>
        <h3 className={commonStyles.subTitle}>{'Discount Code'}</h3>
      </Box>
      {promoCodeApplied ? (
        <Grid {...defaultGridContainerProps} alignItems={'center'}>
          <Grid item xs>
            <Typography>
              {`Discount code applied: `} <b>{promoCode}</b>
            </Typography>
          </Grid>
          <Grid item>
            <Button color={'error'} variant={'contained'} onClick={removeCode} disabled={disableRemoveButton}>
              {'Remove'}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid {...defaultGridContainerProps} alignItems={'center'}>
          <Grid item xs>
            <TextField disabled={loading} value={promoCode} label={'Discount Code'} onChange={handleChange}></TextField>
          </Grid>
          <Grid item>
            <Button color={'primary'} variant={'contained'} onClick={applyCode} disabled={disableApplyButton}>
              {loading ? <CircularProgress /> : 'Apply'}
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default PromoCodeSection;
