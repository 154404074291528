import { Container, Grid } from '@mui/material';
import EventFilesSection from 'components/events/EventFilesSection';
import EventLocationRow from 'components/events/EventInfoRow/EventLocationRow';
import EventTimeRow from 'components/events/EventInfoRow/EventTimeRow';
import Card from 'components/shared/Card';
import EventDateRow from 'components/events/EventInfoRow/EventCalendarIcon';
import React, { useCallback, useContext, useMemo } from 'react';
import eventModeConfig from 'store/configs/EventModeConfig';
import { MainEventView } from 'store/types/events/Event';
import { getEventModeIcon, getTotalCredits } from 'util/Event';
import { EMPTY_STRING_VALUE } from 'util/Format';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import SchoolOutlined from '@mui/icons-material/SchoolOutlined';
import classNames from 'classnames';
import StatusLabel from 'components/shared/StatusLabel';
import EventSpeakersSection from '../EventSpeakersSection';
import { ParametersContext } from 'components/ParametersGuard';
import EventPriceCard from 'components/events/EventPriceCard';

import commonStyles from 'styles/common.module.scss';
import styles from './EventDetailsPageView.module.scss';
import rowStyles from '../EventInfoRow/EventInfoRow.module.scss';

interface EventDetailsPageViewProps {
  data: MainEventView;
}

const EventDetailsPageView: React.FunctionComponent<EventDetailsPageViewProps> = ({ data }) => {
  const {
    events: { multiSessionLabelName },
  } = useContext(ParametersContext);
  const {
    title,
    mode,
    description,
    imageUrl,
    material,
    sessions,
    endDate,
    startDate,
    type,
    speakers,
    titleColor,
    cardBackgroundColor,
  } = data;
  const totalCredits = useMemo(() => getTotalCredits(data), [data]);
  const creditsAmount: number = useMemo(
    () => totalCredits?.reduce((prevValue, { count }) => count + prevValue, 0) || 0,
    [totalCredits]
  );
  const showTimeBox: boolean = useMemo(() => endDate && startDate.isSame(endDate, 'days'), [endDate, startDate]);

  const getCardBox = useCallback(
    (children) => (
      <div className={styles.box} style={{ backgroundColor: cardBackgroundColor }}>
        {children}
      </div>
    ),
    [cardBackgroundColor]
  );

  return (
    <>
      <section>
        <Container maxWidth={'lg'} className={styles.container}>
          <Grid {...defaultGridContainerProps}>
            <Grid {...defaultGridItemProps} className={styles.topInfo}>
              <div className={styles.typeLabel} style={{ color: titleColor }}>
                {getEventModeIcon(mode, styles.typeIcon)}
                {`${eventModeConfig[mode]?.name} | ${type}`}
              </div>
              {!!sessions?.length && <StatusLabel status={multiSessionLabelName}></StatusLabel>}
            </Grid>
            <Grid {...defaultGridItemProps}>
              <h1 className={styles.name} style={{ color: titleColor }}>
                {title}
              </h1>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section className={styles.banner} style={{ backgroundImage: `url(${imageUrl})` }}>
        <Container maxWidth={'lg'} className={styles.container}>
          <Grid {...defaultGridContainerProps}>
            <Grid {...defaultGridItemProps} lg={9} xs={12}>
              <Grid {...defaultGridContainerProps} spacing={1}>
                <Grid
                  {...defaultGridItemProps}
                  lg={showTimeBox ? (creditsAmount ? 5 : 6) : true}
                  sm={showTimeBox ? 6 : true}
                >
                  {getCardBox(<EventDateRow data={data} className={styles.eventInfoRow} />)}
                </Grid>
                {showTimeBox && (
                  <Grid {...defaultGridItemProps} lg={creditsAmount ? 5 : 6} sm={6}>
                    {getCardBox(<EventTimeRow data={data} className={styles.eventInfoRow} />)}
                  </Grid>
                )}
                {!!creditsAmount && (
                  <Grid {...defaultGridItemProps} lg={2}>
                    {getCardBox(
                      <div className={classNames(rowStyles.row, styles.eventInfoRow)}>
                        <SchoolOutlined className={rowStyles.icon} />
                        {`${creditsAmount} credit(s)`}
                      </div>
                    )}
                  </Grid>
                )}
                <Grid {...defaultGridItemProps} lg={12}>
                  {getCardBox(<EventLocationRow data={data} className={styles.eventInfoRow} fullLocation={true} />)}
                </Grid>
              </Grid>
            </Grid>
            <Grid {...defaultGridItemProps} lg={3} xs={12}>
              <EventPriceCard data={data} />
              {!!totalCredits?.length && (
                <Card className={styles.additionInfoBox}>
                  <span className={styles.title}>
                    <SchoolOutlined className={rowStyles.icon} />
                    {'Credits: '}
                  </span>
                  {totalCredits.map(({ name, count, id }) => (
                    <span className={styles.value} key={id}>{`${count.toFixed(2)} credit(s) - ${name}`}</span>
                  ))}
                </Card>
              )}
              {!!speakers?.length && (
                <EventSpeakersSection
                  data={speakers.sort((a, b) => (a.lastName.toLowerCase() < b.lastName.toLowerCase() ? -1 : 1))}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </section>
      <section className={styles.description}>
        <Container maxWidth={'lg'} className={styles.container}>
          <Grid {...defaultGridContainerProps}>
            <Grid {...defaultGridItemProps} sm={8}>
              <p
                className={commonStyles.text}
                dangerouslySetInnerHTML={{ __html: description || EMPTY_STRING_VALUE }}
              />
            </Grid>
            {!!material && (
              <Grid {...defaultGridItemProps}>
                <h4 className={commonStyles.subTitle}>{'Materials:'}</h4>
                <EventFilesSection data={data} />
              </Grid>
            )}
          </Grid>
        </Container>
      </section>
    </>
  );
};

export default EventDetailsPageView;
