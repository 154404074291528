import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Button, Grid, InputAdornment, MenuItem, TextField } from '@mui/material';
import { Controller, FieldErrors, useFormContext, useWatch } from 'react-hook-form';
import { SublistQuestion, SublistAnswer } from 'store/types/PreferenceQuestion';
import Modal from 'components/shared/Modal';
import { ModalProps } from 'store/types/ComponentProps';
import QuestionType from 'store/enums/QuestionType';
import SelectOption from 'store/types/SelectOption';
import { v4 as uuid } from 'uuid';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment, { Moment } from 'moment/moment';
import { getISODateString } from 'util/Format';
import { getRequiredValidationRule, getValidationProps } from 'util/Form';

import commonStyles from 'styles/common.module.scss';

interface AddSublistItemModalProps extends ModalProps {
  questions: SublistQuestion[];
  fieldName: string;
}

const getQuestionInput = (
  { type, options, id }: SublistQuestion,
  value: any,
  onChange: any,
  errors: FieldErrors
): React.ReactElement => {
  const selectOptions: SelectOption[] = options?.length
    ? options.map(({ value, name }) => ({ name, id: String(value) }))
    : [];

  switch (type) {
    case QuestionType.Dropdown:
      return (
        <TextField
          value={value}
          onChange={onChange}
          {...getValidationProps(`sublistQuestion-${id}`, errors)}
          select={true}
          SelectProps={{
            displayEmpty: true,
            renderValue: (selected: any) =>
              selectOptions.find(({ id }) => selected === id)?.name || (
                <span className={commonStyles.placeholder}>{'Please select value'}</span>
              ),
          }}
        >
          {selectOptions.map(({ name, id }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </TextField>
      );
    case QuestionType.DropdownCustomList:
      return (
        <TextField
          value={value}
          onChange={onChange}
          {...getValidationProps(`sublistQuestion-${id}`, errors)}
          select={true}
          SelectProps={{
            displayEmpty: true,
            renderValue: (selected: any) =>
              selectOptions.find(({ name }) => selected === name)?.name || (
                <span className={commonStyles.placeholder}>{'Please select value'}</span>
              ),
          }}
        >
          {selectOptions.map(({ name }) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </TextField>
      );
    case QuestionType.Currency:
      return (
        <TextField
          value={value}
          onChange={onChange}
          {...getValidationProps(`sublistQuestion-${id}`, errors)}
          type={'number'}
          InputProps={{
            inputProps: { min: 0 },
            startAdornment: <InputAdornment position={'start'}>$</InputAdornment>,
          }}
          placeholder={'0'}
        />
      );
    case QuestionType.Text:
      return (
        <TextField
          value={value}
          onChange={onChange}
          {...getValidationProps(`sublistQuestion-${id}`, errors)}
          placeholder={'Enter your answer here...'}
          multiline={true}
          rows={5}
        />
      );
    case QuestionType.Date:
      return (
        <DatePicker
          views={['month', 'year', 'day']}
          value={value || null}
          onChange={onChange}
          renderInput={(props) => (
            <TextField {...props} {...getValidationProps(`sublistQuestion-${id}`, errors)} fullWidth={true} />
          )}
        />
      );
    default:
      return <></>;
  }
};

const AddSublistItemModal: React.FunctionComponent<AddSublistItemModalProps> = ({
  onClose,
  open,
  questions,
  fieldName,
}) => {
  const {
    control,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useFormContext();
  const [referenceKey, setReferenceKey] = useState<string>(uuid());
  const controlFieldNameView = useMemo(() => `${fieldName}-view`, [fieldName]);
  const controlFieldNameData = useMemo(() => `${fieldName}-data`, [fieldName]);
  const sublistAnswers: Array<SublistAnswer[]> = useWatch({ control, name: fieldName });
  const sublistAnswersView = useWatch({ control, name: controlFieldNameView });
  const addButtonDisabled: boolean = useMemo(
    () => !sublistAnswersView || !Object.values(sublistAnswersView)?.every((item) => !!item),
    [sublistAnswersView]
  );
  const generateNewReferenceKey = useCallback(() => {
    setReferenceKey(uuid());
  }, []);

  const handleAddItem = useCallback(() => {
    if (fieldName.split('.').length > 1) {
      reset({
        ...getValues(),
        preferences: {
          [fieldName.split('.')[1]]: [
            ...sublistAnswers,
            Object.values(
              controlFieldNameData.split('.').reduce((nextObject: any, key: string) => nextObject[key], getValues())
            ),
          ],
          [controlFieldNameView]: '',
          [controlFieldNameData]: '',
        },
      });
    } else {
      reset({
        ...getValues(),
        [fieldName]: [...sublistAnswers, Object.values(getValues()[controlFieldNameData])],
        [controlFieldNameView]: '',
        [controlFieldNameData]: '',
      });
    }
    generateNewReferenceKey();
  }, [
    reset,
    getValues,
    fieldName,
    sublistAnswers,
    controlFieldNameData,
    controlFieldNameView,
    generateNewReferenceKey,
  ]);

  const handleAddItemAndClose = useCallback(() => {
    handleAddItem();
    onClose();
  }, [handleAddItem, onClose]);

  const handleClose = useCallback(() => {
    reset({
      ...getValues(),
      [controlFieldNameView]: '',
      [controlFieldNameData]: '',
    });
    onClose();
  }, [controlFieldNameData, controlFieldNameView, getValues, onClose, reset]);

  const handleChange = useCallback(
    (onChange, { type, id, content }: SublistQuestion) =>
      (e: ChangeEvent<HTMLInputElement> | Moment) => {
        const value: string = moment.isMoment(e) ? getISODateString(e) || '' : e.target.value;

        setValue(`${controlFieldNameData}.${id}`, {
          answer: value,
          sublistQuestionId: id,
          type,
          content,
          referenceKey,
        });
        onChange(value);
      },
    [controlFieldNameData, referenceKey, setValue]
  );

  return (
    <Modal
      onClose={handleClose}
      open={open}
      title={'Add New Item'}
      actions={
        <Grid {...defaultGridContainerProps}>
          <Grid {...defaultGridItemProps} xs={true}>
            <Button color={'secondary'} variant={'outlined'} onClick={handleClose}>
              {'Cancel'}
            </Button>
          </Grid>
          <Grid {...defaultGridItemProps} xs={'auto'}>
            <Button color={'secondary'} variant={'contained'} onClick={handleAddItem} disabled={addButtonDisabled}>
              {'Add & Create New'}
            </Button>
          </Grid>
          <Grid {...defaultGridItemProps} xs={'auto'}>
            <Button
              color={'secondary'}
              variant={'contained'}
              onClick={handleAddItemAndClose}
              disabled={addButtonDisabled}
            >
              {'Add'}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Grid {...defaultGridContainerProps}>
        {questions.map((question) => (
          <Grid {...defaultGridItemProps} key={question.id}>
            <h5 className={commonStyles.subTitle}>{question.content}</h5>
            <Controller
              render={({ field: { value, onChange } }) =>
                getQuestionInput(
                  question,
                  value,
                  handleChange(onChange, question),
                  errors[controlFieldNameView] as FieldErrors
                )
              }
              name={`${controlFieldNameView}.sublistQuestion-${question.id}`}
              control={control}
              defaultValue={''}
              rules={{ required: getRequiredValidationRule(question.content, false, open) }}
            />
          </Grid>
        ))}
      </Grid>
    </Modal>
  );
};
export default AddSublistItemModal;
