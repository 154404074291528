import { Grid, ListItemText, MenuItem, TextField } from '@mui/material';
import { Alert } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormProps, getRequiredValidationRule, getValidationProps } from 'util/Form';
import useRequest from 'hooks/useRequest';
import { defaultGridItemProps, getInputLoadingProps } from 'util/Layout';
import UserService from 'services/api/UserService';
import Country from 'store/types/Country';
import { useHistory, useLocation } from 'react-router';
import { ParametersContext } from 'components/ParametersGuard';

import commonStyles from 'styles/common.module.scss';

interface EventCountryFormItemProps extends FormProps {
  required?: boolean;
}

const EventCountryFormItem: React.FunctionComponent<EventCountryFormItemProps> = ({ fieldName = '', required }) => {
  const { data, loading, error } = useRequest<Country[]>(UserService.getCountries);
  const { search, pathname } = useLocation();
  const history = useHistory();
  const [options, setOptions] = useState<Country[]>([]);
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const value = useWatch({ control, name: fieldName });
  const stateOptions = useMemo(() => options.find(({ id }) => value === id)?.states || [], [options, value]);

  const {
    events: { defaultEventSearchToUS },
  } = useContext(ParametersContext);

  useEffect(() => {
    if (options.length) {
      const searchParams: URLSearchParams = new URLSearchParams(search);

      if (searchParams.has('country')) return;

      if (defaultEventSearchToUS) {
        const usCountryId = options.find(({ name }) => name === 'United States')?.id || '';

        setValue('country', usCountryId);
        history.replace({
          pathname,
          search: `?country=${usCountryId}`,
        });
      } else {
        history.replace({
          pathname,
          search: `?country=`,
        });
      }
    }
  }, [defaultEventSearchToUS, history, options, pathname, search, setValue, value]);

  useEffect(() => {
    if (value) {
      setValue('state', '');
    }
  }, [options, setValue, value]);

  useEffect(() => {
    if (data?.length) {
      setOptions(data);
    }
  }, [data, setValue]);

  return error ? (
    <Alert severity={'error'} className={commonStyles.alert}>
      {error}
    </Alert>
  ) : (
    <>
      <Grid {...defaultGridItemProps}>
        <h3 className={commonStyles.subTitle}>{'Country'}</h3>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              {...getValidationProps(fieldName, errors)}
              InputProps={getInputLoadingProps(loading)}
              disabled={loading}
              required={required}
              select={true}
              SelectProps={{
                displayEmpty: true,
                renderValue: (selectedId: any) =>
                  options.find(({ id }) => selectedId === id)?.name || <span>{'All Countries'}</span>,
              }}
            >
              <MenuItem value={''}>
                <ListItemText primary={'All Countries'} />
              </MenuItem>
              {options.map(({ name, id }: Country) => (
                <MenuItem value={id} key={`eventCountry-${id}`}>
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </TextField>
          )}
          name={'country'}
          control={control}
          rules={{ required: getRequiredValidationRule('event country', true, required) }}
        />
      </Grid>
      {!!stateOptions.length && (
        <Grid {...defaultGridItemProps}>
          <h3 className={commonStyles.subTitle}>{'State/Province'}</h3>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                {...getValidationProps(fieldName, errors)}
                InputProps={getInputLoadingProps(loading)}
                placeholder={'Select State/Province'}
                disabled={loading}
                required={required}
                select={true}
                SelectProps={{
                  displayEmpty: true,
                  renderValue: (selectedId: any) =>
                    stateOptions.find(({ id }) => selectedId === id)?.name || <span>{'All States/Provinces'}</span>,
                }}
              >
                <MenuItem value={''}>
                  <ListItemText primary={'All States/Provinces'} />
                </MenuItem>
                {stateOptions.map(({ id, name }: Country) => (
                  <MenuItem value={id} key={`eventState-${id}`}>
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </TextField>
            )}
            name={'state'}
            control={control}
            rules={{ required: getRequiredValidationRule('event state', true, required) }}
          />
        </Grid>
      )}
    </>
  );
};
export default EventCountryFormItem;
