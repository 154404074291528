import { Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import ApplicationPageLayout from 'components/applications/ApplicationPageLayout';
import ApplicationQuestionsFormSection from 'components/applications/ApplicationQuestionsFormSection';
import React, { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import routes from 'store/configs/Routes';
import { ApplicationContentView } from 'store/types/ApplicationContent';
import { getQuestionsDefaultValues } from 'util/Application';
import { defaultFormProps, FormProps } from 'util/Form';
import { useWindowSize } from 'util/Window';
import ApplicationReviewHeader from '../ApplicationReviewHeader';
import ApplicationReviewType from 'store/enums/ApplicationReviewType';
import ApplicationDecisionModal from 'components/applications/ApplicationDecisionModal';

import commonStyles from 'styles/common.module.scss';
import pageStyles from '../ApplicationReviewPage.module.scss';

interface ViewApplicationReviewPageViewProps extends FormProps {
  data: ApplicationContentView;
}

const ViewApplicationReviewPageView: React.FunctionComponent<ViewApplicationReviewPageViewProps> = ({ data }) => {
  const { height } = useWindowSize();
  const history = useHistory();
  const applicationForm = useForm({ ...defaultFormProps, defaultValues: getQuestionsDefaultValues(data) });
  const hasContent: boolean = useMemo(() => !!(data?.sections && data?.sections.length), [data]);
  const [decisionModalOpen, setDecisionModalOpen] = useState<boolean>(false);

  const handleDecisionModalOpen = useCallback(() => {
    setDecisionModalOpen(true);
  }, []);

  const handleDecisionModalClose = useCallback(() => {
    setDecisionModalOpen(false);
  }, []);

  const handleCancel = useCallback(() => {
    history.push(routes.applicationReviews);
  }, [history]);

  return (
    <>
      <ApplicationPageLayout
        data={data}
        footerProps={{
          hideSubmitButton: true,
          children: (
            <>
              <Button
                color={'secondary'}
                variant={'outlined'}
                onClick={handleCancel}
                className={pageStyles.cancelButton}
              >
                {'Go Back'}
              </Button>
              {data.review?.decisionDate && (
                <Button color={'secondary'} variant={'outlined'} onClick={handleDecisionModalOpen}>
                  {'View Decision'}
                </Button>
              )}
            </>
          ),
        }}
      >
        {data && (
          <>
            <ApplicationReviewHeader data={data} />
            {hasContent ? (
              <div className={pageStyles.content} style={{ height }}>
                <div className={pageStyles.formWrapper}>
                  {data.title && (
                    <h1 className={commonStyles.pageTitle}>
                      {data.campaignName ? `${data.title} (${data.campaignName})` : data.title}
                      {` (ID# ${data.id})`}
                    </h1>
                  )}
                  <FormProvider {...applicationForm}>
                    <ApplicationQuestionsFormSection sections={data.sections} disabled={true} applicationId={data.id} />
                  </FormProvider>
                </div>
                {data?.reviewType === ApplicationReviewType.Standard && (
                  <div className={pageStyles.formWrapper}>
                    <h1 className={commonStyles.pageTitle}>Review</h1>
                    <ApplicationQuestionsFormSection
                      sections={data.sections}
                      isReviewForm={true}
                      disabled={true}
                      applicationId={data.id}
                    />
                  </div>
                )}
              </div>
            ) : (
              <Alert severity={'error'} className={commonStyles.alert}>
                {'No content found'}
              </Alert>
            )}
          </>
        )}
      </ApplicationPageLayout>
      {data.review?.decisionDate && (
        <ApplicationDecisionModal
          onClose={handleDecisionModalClose}
          open={decisionModalOpen}
          review={data.review}
          applicationStatus={data.status}
        />
      )}
    </>
  );
};
export default ViewApplicationReviewPageView;
