import React, { useCallback, useState } from 'react';
import { Link } from '@mui/material';
import classNames from 'classnames';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVert from '@mui/icons-material/MoreVert';
import { NavLink } from 'react-router-dom';
import MenuConfigItem from 'store/types/MenuConfigItem';

import styles from './ActionsMenu.module.scss';

interface ActionsMenuProps {
  menuConfig: MenuConfigItem[];
  buttonIcon?: React.ReactNode;
  buttonClassName?: string;
  disabled?: boolean;
}

const ActionsMenu: React.FunctionComponent<ActionsMenuProps> = ({
  menuConfig,
  buttonIcon,
  buttonClassName = '',
  disabled = false,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <IconButton
        onClick={handleMenuOpen}
        className={classNames(styles.button, buttonClassName)}
        disabled={disabled}
        aria-haspopup={'true'}
        data-testid={'action-button'}
      >
        {buttonIcon || <MoreVert />}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted={true}
        open={!!anchorEl}
        onClose={handleMenuClose}
        autoFocus={false}
        data-testid={'action-menu'}
      >
        {menuConfig.map((item, index, arr) => {
          const { onClick, children } = item;
          const handleClick = (e: any) => {
            if (onClick) {
              onClick(e);
            }
            handleMenuClose();
          };

          const getMenuItem = ({ title, key, type, to }: MenuConfigItem, index: number) => {
            const itemProps = {
              className: styles.menuItemLink,
              onClick: handleClick,
              children: (
                <div className={styles.content}>
                  <span className={styles.label}>{title}</span>
                </div>
              ),
            };

            return (
              <MenuItem
                key={key || `${type}-${index}`}
                className={styles.menuItem}
                divider={arr[index + 1] && arr[index + 1].divider}
                data-testid={`action-menu-item-${index}`}
              >
                {to ? (
                  <NavLink {...itemProps} to={to} data-testid={linkTestId} activeClassName={styles.active} />
                ) : (
                  <Link {...itemProps} underline={'none'} data-testid={linkTestId} />
                )}
              </MenuItem>
            );
          };

          const linkTestId = `action-menu-link-${index}`;

          return children ? children.map((item, index) => getMenuItem(item, index)) : getMenuItem(item, index);
        })}
      </Menu>
    </>
  );
};

export default ActionsMenu;
