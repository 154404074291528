import React, { useMemo } from 'react';
import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { FormProps, getRequiredValidationRule, getValidationProps } from 'util/Form';
import { ApplicationQuestionView } from 'store/types/ApplicationQuestion';

interface TextQuestionFormItemProps extends FormProps {
  question: ApplicationQuestionView;
}

const TextQuestionFormItem: React.FunctionComponent<TextQuestionFormItemProps> = ({ question, disabled }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { fieldName, required } = useMemo(() => question, [question]);

  return (
    <Controller
      render={({ field }) => (
        <TextField {...field} required={required} {...getValidationProps(fieldName, errors)} disabled={disabled} />
      )}
      name={fieldName}
      control={control}
      rules={{
        required: getRequiredValidationRule('your answer', false, required),
      }}
    />
  );
};
export default TextQuestionFormItem;
