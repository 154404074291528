import { Checkbox, ListItemText, MenuItem, TextField } from '@mui/material';
import { Alert } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormProps, getRequiredValidationRule, getValidationProps, multipleSelectProps } from 'util/Form';
import useRequest from 'hooks/useRequest';
import { getInputLoadingProps } from 'util/Layout';
import SelectOption from 'store/types/SelectOption';
import EventService from 'services/api/EventService';

import commonStyles from 'styles/common.module.scss';

interface LocationFormItemProps extends FormProps {
  required?: boolean;
}

const LocationFormItem: React.FunctionComponent<LocationFormItemProps> = ({ fieldName = '', required = false }) => {
  const { data, loading, error } = useRequest<SelectOption[]>(EventService.getEventLocations);
  const [options, setOptions] = useState<SelectOption[]>([]);
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const value = useWatch({ control, name: fieldName });

  useEffect(() => {
    if (data?.length) {
      setOptions(data);
    }
  }, [data, setValue]);

  return error ? (
    <Alert severity={'error'} className={commonStyles.alert}>
      {error}
    </Alert>
  ) : (
    <Controller
      render={({ field }) => (
        <TextField
          {...field}
          {...getValidationProps(fieldName, errors)}
          {...multipleSelectProps(options, 'Select Location')}
          InputProps={getInputLoadingProps(loading)}
          select={true}
          disabled={loading}
          required={required}
        >
          {options.map(({ id, name }: SelectOption) => (
            <MenuItem value={id} key={`location-${id}`}>
              <Checkbox checked={value.includes(id)} color={'primary'} size={'small'} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </TextField>
      )}
      name={'location'}
      control={control}
      rules={{ required: getRequiredValidationRule('location', true, required) }}
    />
  );
};
export default LocationFormItem;
