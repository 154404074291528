import { ConfigSelectOption } from 'store/types/SelectOption';
import ExpenseReportStatus from 'store/enums/ExpenseReportStatus';

const expenseReportStatusConfig: Record<ExpenseReportStatus, ConfigSelectOption> = {
  [ExpenseReportStatus.Draft]: {
    name: ExpenseReportStatus.Draft,
    theme: 'grey',
  },
  [ExpenseReportStatus.PendingApproval]: {
    name: ExpenseReportStatus.PendingApproval,
    theme: 'yellow',
  },
  [ExpenseReportStatus.NeedInfo]: {
    name: ExpenseReportStatus.NeedInfo,
    theme: 'red',
    variant: 'outlined',
  },
  [ExpenseReportStatus.Rejected]: {
    name: ExpenseReportStatus.Rejected,
    theme: 'red',
  },
  [ExpenseReportStatus.Approved]: {
    name: ExpenseReportStatus.Approved,
    theme: 'green',
    variant: 'outlined',
  },
  [ExpenseReportStatus.PaidInFull]: {
    name: ExpenseReportStatus.PaidInFull,
    theme: 'green',
  },
};

export default expenseReportStatusConfig;
