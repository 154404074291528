import React from 'react';
import { ModalProps } from 'store/types/ComponentProps';
import { Grid } from '@mui/material';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { EMPTY_STRING_VALUE } from 'util/Format';
import Modal from 'components/shared/Modal';
import Avatar from 'components/shared/Avatar';
import EventSpeaker from 'store/types/events/EventSpeaker';

import commonStyles from 'styles/common.module.scss';
import styles from './EventSpeakerModal.module.scss';

interface EventSpeakerModalProps extends ModalProps {
  data: EventSpeaker;
}

const EventSpeakerModal: React.FunctionComponent<EventSpeakerModalProps> = ({ data, open, onClose }) => (
  <Modal title={'Speaker'} open={open} onClose={onClose} maxWidth={'sm'}>
    <Grid {...defaultGridContainerProps}>
      <Grid {...defaultGridItemProps} md={8}>
        <h1 className={styles.title}>
          {data?.firstName} {data?.lastName}
        </h1>
        {data?.organization && (
          <>
            <h4 className={styles.title}>{'Company'}</h4>
            <p className={commonStyles.text}>{data?.organization}</p>
          </>
        )}
      </Grid>
      <Grid {...defaultGridItemProps} md={4}>
        <div className={styles.avatar}>
          <Avatar className={styles.avatarImage} src={data?.image} darkBackground={true} />
        </div>
      </Grid>
    </Grid>
    {data?.biography && (
      <>
        <h4 className={styles.title}>{'Biography'}</h4>
        <p className={commonStyles.text} dangerouslySetInnerHTML={{ __html: data?.biography || EMPTY_STRING_VALUE }} />
      </>
    )}
  </Modal>
);
export default EventSpeakerModal;
