import React, { useCallback, useEffect, useState } from 'react';
import { Button, InputAdornment, Grid, TextField } from '@mui/material';
import ComponentProps from 'store/types/ComponentProps';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { ValidationProps } from 'util/Form';

import styles from './DonationPriceForm.module.scss';

interface DonationFormProps extends ComponentProps {
  value?: number;
  onChange: (value: number) => void;
  amountItems?: number[];
  showZeroPrice?: boolean;
  title?: React.ReactNode;
  validationProps?: ValidationProps;
  isOtherDonationAmountAvailable?: boolean;
}

const DonationPriceForm: React.FunctionComponent<DonationFormProps> = ({
  value,
  onChange,
  className = '',
  amountItems = [22, 44, 122],
  showZeroPrice = false,
  title,
  validationProps,
  isOtherDonationAmountAvailable,
}) => {
  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    if (!value) {
      setInputValue('');
    }
  }, [value]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const amountValue: string = event.target.value.replace(/\D/g, '') || '';

      setInputValue(amountValue);
      onChange(amountValue ? parseInt(amountValue, 10) : 0);
    },
    [onChange]
  );

  const handleAmountSelected = useCallback(
    (amountValue: number) => () => {
      setInputValue('');
      onChange(amountValue);
    },
    [onChange]
  );

  const getAmountButton = useCallback(
    (amountValue: number, showZeroPrice?: boolean) => (
      <Button
        color={'primary'}
        variant={value === amountValue ? 'contained' : 'outlined'}
        onClick={handleAmountSelected(amountValue)}
        className={styles.amountButton}
      >
        {showZeroPrice ? 'No, thank you.' : `$${amountValue}`}
      </Button>
    ),
    [handleAmountSelected, value]
  );

  return (
    <Grid {...defaultGridContainerProps} spacing={3} className={className}>
      <Grid {...defaultGridItemProps}>{title || <span>{'Donation Amount:'}</span>}</Grid>
      <Grid {...defaultGridItemProps}>
        <Grid {...defaultGridContainerProps} spacing={1}>
          {amountItems.map((amountItem: number) => (
            <Grid {...defaultGridItemProps} xs={'auto'} key={`donation-amount-button-${amountItem}`}>
              {getAmountButton(amountItem)}
            </Grid>
          ))}
        </Grid>
      </Grid>
      {isOtherDonationAmountAvailable && (
        <Grid {...defaultGridItemProps}>
          <Grid {...defaultGridContainerProps} spacing={2}>
            <Grid {...defaultGridItemProps} xs={true}>
              <TextField
                label={'Other'}
                InputProps={{
                  startAdornment: <InputAdornment position={'start'}>$</InputAdornment>,
                }}
                {...validationProps}
                placeholder={'0'}
                fullWidth={false}
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleInputChange}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {showZeroPrice && <Grid {...defaultGridItemProps}>{getAmountButton(0, true)}</Grid>}
    </Grid>
  );
};
export default DonationPriceForm;
