import { Divider } from '@mui/material';
import Modal from 'components/shared/Modal';
import React from 'react';
import { ModalProps } from 'store/types/ComponentProps';
import Announcement from 'store/types/Announcement';
import AnnouncementListItem from 'components/home/AnnouncementListItem';

import styles from './AnnouncementsModal.module.scss';

interface AnnouncementsModalProps extends ModalProps {
  list: Announcement[];
}

const AnnouncementsModal: React.FunctionComponent<AnnouncementsModalProps> = ({ open, onClose, list = [] }) => {
  return (
    <Modal open={open} onClose={onClose} title={'Announcements'} maxWidth={'lg'}>
      {list.map((announcement: Announcement, index: number, arr: Announcement[]) => {
        const key = `home-announcement-${announcement.id}`;

        return index < arr.length - 1 ? (
          <React.Fragment key={key}>
            <AnnouncementListItem key={key} announcement={announcement} />
            <Divider className={styles.divider} />
          </React.Fragment>
        ) : (
          <AnnouncementListItem key={key} announcement={announcement} />
        );
      })}
    </Modal>
  );
};
export default AnnouncementsModal;
