enum ApplicationReviewStatus {
  PendingInfo = 'pendinginfo',
  NeedMoreInformation = 'needmoreinformation',
  PendingReview = 'pendingreview',
  Terminated = 'terminated',
  Completed = 'completed',
  Submitted = 'submitted',
  WaitingForReview = 'waitingforreview',
}
export default ApplicationReviewStatus;
