import { Container } from '@mui/material';
import Logo from 'assets/images/default/logo.svg';
import SmacnaLogo from 'assets/images/smacna/logo.png';
import classNames from 'classnames';
import { ConfigContext } from 'components/ConfigGuard';
import React, { useContext } from 'react';

import styles from './ProductsPageHeader.module.scss';

const ProductsPageHeader: React.FunctionComponent = () => {
  const { isSmacnaTheme } = useContext(ConfigContext);

  return (
    <header className={styles.header}>
      <Container maxWidth={'lg'} className={styles.content}>
        <a href={'/'} className={styles.logoWrapper}>
          <img
            src={isSmacnaTheme ? SmacnaLogo : Logo}
            alt={'logo'}
            className={classNames(styles.logo, { [styles.smacnaLogo]: isSmacnaTheme })}
          />
        </a>
      </Container>
    </header>
  );
};
export default ProductsPageHeader;
