import { HttpService } from 'services/HttpService';
import { API_URL, conf, tenantConfig } from 'config';
import AuthService from 'services/AuthService';
import { httpRequestWrapper } from 'util/Request';
import SelectOption from 'store/types/SelectOption';
import { EventRegistrationViewStatus } from 'store/enums/events/EventRegistrationStatus';
import {
  EventHotelPreferences,
  EventHotelPreferencesData,
  EventRegistrationHotelPreferences,
} from 'store/types/events/EventHotelPreferences';
import { PublicUser } from 'store/types/User';
import { MainEventRegistration } from 'store/types/events/EventRegistration';

type RegistrationsResponseData = MainEventRegistration[];
type CreditCategoriesResponseData = SelectOption[];
type EventHotelPreferencesResponseData = EventHotelPreferences[];
type EventRegistrationHotelPreferencesResponseData = EventRegistrationHotelPreferences;

class EventRegistrationService {
  public static getCreditCategories(): Promise<CreditCategoriesResponseData> {
    return new Promise<CreditCategoriesResponseData>(
      (resolve: (data: CreditCategoriesResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<CreditCategoriesResponseData>(
          HttpService.get(`${API_URL}/events/credits/categories`, {
            headers: {
              TenantId: tenantConfig.id,
            },
          }),
          resolve,
          reject,
          false
        );
      }
    );
  }

  public static getEventRegistrations(
    status: EventRegistrationViewStatus,
    search = ''
  ): Promise<RegistrationsResponseData> {
    return new Promise<RegistrationsResponseData>(
      (resolve: (data: RegistrationsResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<RegistrationsResponseData>(
          HttpService.get(`${API_URL}/events/my/${search}`, {
            queryParams: { type: status },
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getEventsHotelPreferences(eventsId: string[]): Promise<EventHotelPreferencesResponseData> {
    return new Promise<EventHotelPreferencesResponseData>(
      (resolve: (data: EventHotelPreferencesResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<EventHotelPreferencesResponseData>(
          HttpService.get(`${API_URL}/events/registrations`, {
            queryParams: { eventsId },
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getEventRegistrationUsers(eventRegistrationId: string): Promise<PublicUser[]> {
    return new Promise<PublicUser[]>((resolve: (data: PublicUser[]) => void, reject: (error: string) => void) => {
      httpRequestWrapper<PublicUser[]>(
        HttpService.get(`${API_URL}/events/registrations/${eventRegistrationId}/print`, {
          headers: {
            TenantId: tenantConfig.id,
          },
        }),
        resolve,
        reject,
        false
      );
    });
  }

  public static downloadDocumentBadges(eventRegistrationId: string): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.getFileDownload(`${API_URL}/events/registrations/${eventRegistrationId}/print/doc`, {
          headers: {
            TenantId: tenantConfig.id,
            [conf.filenameHeader]: `badges.doc`,
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getEventRegistrationHotelPreferences(
    eventId: string,
    eventRegistrationId: string,
    registrantId: string
  ): Promise<EventRegistrationHotelPreferencesResponseData> {
    return new Promise<EventRegistrationHotelPreferencesResponseData>(
      (resolve: (data: EventRegistrationHotelPreferencesResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<EventRegistrationHotelPreferencesResponseData>(
          HttpService.get(`${API_URL}/events/registrations`, {
            queryParams: { eventRegistrationId, eventsId: [eventId], registrantId },
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static updateEventHotelPreferences(data: EventHotelPreferencesData, registrantId: string): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.put(`${API_URL}/events/registrations`, {
          data: { ...data, registrantId },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }
}
export default EventRegistrationService;
