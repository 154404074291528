import { API_URL, conf, tenantConfig } from 'config';
import AuthService from 'services/AuthService';
import { HttpService } from 'services/HttpService';
import {
  ExpenseCategory,
  ExpenseItemSaveRequest,
  ExpenseProfile,
  ExpenseReport,
  ExpenseReportUpdateRequest,
} from 'store/types/Expense';
import { AchPaymentMethodDetails } from 'store/types/PaymentMethod';
import { httpRequestWrapper } from 'util/Request';

class ExpenseService {
  public static getExpenseProfile(): Promise<ExpenseProfile> {
    return new Promise<ExpenseProfile>((resolve: (data: ExpenseProfile) => void, reject: (error: string) => void) => {
      httpRequestWrapper<ExpenseProfile>(
        HttpService.get(`${API_URL}/expense/profile`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static updatePaymentMethodType(paymentMethodType: string): Promise<string> {
    return new Promise<string>((resolve: (value: string) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.put(`${API_URL}/expense/profile`, {
          data: { paymentMethodType: paymentMethodType },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static saveAchPayment(data: AchPaymentMethodDetails): Promise<string> {
    return new Promise<string>((resolve: (value: string) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.post(`${API_URL}/expense/profile`, {
          data: data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getAllExpenseReports(): Promise<ExpenseReport[]> {
    return new Promise<ExpenseReport[]>((resolve: (data: ExpenseReport[]) => void, reject: (error: string) => void) => {
      httpRequestWrapper<ExpenseReport[]>(
        HttpService.get(`${API_URL}/expense/reports`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getSingleExpenseReport(id: string): Promise<ExpenseReport> {
    return new Promise<ExpenseReport>((resolve: (data: ExpenseReport) => void, reject: (error: string) => void) => {
      httpRequestWrapper<ExpenseReport>(
        HttpService.get(`${API_URL}/expense/reports/${id}`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static createExpenseReport(data: ExpenseItemSaveRequest): Promise<string> {
    return new Promise<string>((resolve: (value: string) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.post(`${API_URL}/expense/reports`, {
          data: { memo: data.memo, expenses: [data.expense] },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static updateExpenseReport(data: ExpenseReportUpdateRequest): Promise<string> {
    return new Promise<string>((resolve: (value: string) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.put(`${API_URL}/expense/reports/${data.id}`, {
          data: data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static deleteExpenseReport(expenseReportId: string): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.delete(`${API_URL}/expense/reports/${expenseReportId}`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static createExpenseItem(data: ExpenseItemSaveRequest): Promise<string> {
    return new Promise<string>((resolve: (value: string) => void, reject: (error: string) => void) => {
      httpRequestWrapper<string>(
        HttpService.post(`${API_URL}/expense/item`, {
          data: { id: data.id, memo: data.memo, expenses: [data.expense] },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static updateExpenseItem(data: ExpenseItemSaveRequest): Promise<string> {
    return new Promise<string>((resolve: (value: string) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.put(`${API_URL}/expense/item/${data.expense.id}`, {
          data: { id: data.id, memo: data.memo, expenses: [data.expense] },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static deleteExpenseItem(expenseReportId: string, expenseId: string): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.delete(`${API_URL}/expense/reports/${expenseReportId}/item/${expenseId}`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static downloadFile(expenseReportId: string, expenseId: string, filename: string): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.getFileDownload(`${API_URL}/expense/reports/${expenseReportId}/expense/${expenseId}/download/`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
            [conf.filenameHeader]: filename,
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static uploadFile(expenseReportId: string, file: File): Promise<string> {
    const uploadedData = new FormData();
    uploadedData.append('file', file);

    return new Promise<string>((resolve: (data: string) => void, reject: (error: string) => void) => {
      httpRequestWrapper<string>(
        HttpService.post(`${API_URL}/expense/reports/${expenseReportId}/upload`, {
          data: uploadedData,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getExpenseCategories(): Promise<ExpenseCategory[]> {
    return new Promise<ExpenseCategory[]>(
      (resolve: (data: ExpenseCategory[]) => void, reject: (error: string) => void) => {
        httpRequestWrapper<ExpenseCategory[]>(
          HttpService.get(`${API_URL}/expense/categories`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static createVendor(): Promise<string> {
    return new Promise<string>((resolve: (value: string) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.post(`${API_URL}/expense/vendor`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }
}

export default ExpenseService;
