import SelectOption from 'store/types/SelectOption';
import EventMode from 'store/enums/events/EventMode';

const eventModeConfig: Record<EventMode, Pick<SelectOption, 'name'>> = {
  [EventMode.OnDemand]: { name: 'On Demand' },
  [EventMode.Online]: { name: 'Online' },
  [EventMode.InPerson]: { name: 'In Person' },
};

export default eventModeConfig;
