import { ConfigSelectOption } from 'store/types/SelectOption';
import CompanyAccessRequestStatus from 'store/enums/CompanyAccessRequestStatus';

const companyAccessRequestStatusConfig: Record<CompanyAccessRequestStatus, ConfigSelectOption> = {
  [CompanyAccessRequestStatus.Active]: {
    name: 'Active',
    theme: 'green',
  },
  [CompanyAccessRequestStatus.Approved]: {
    name: 'Approved',
    theme: 'green',
  },
  [CompanyAccessRequestStatus.Canceled]: {
    name: 'Canceled',
    theme: 'grey',
  },
  [CompanyAccessRequestStatus.Rejected]: {
    name: 'Rejected',
    theme: 'red',
  },
  [CompanyAccessRequestStatus.Pending]: {
    name: 'Pending',
    theme: 'yellow',
  },
};

export default companyAccessRequestStatusConfig;
