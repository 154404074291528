import { Grid } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { ApplicationContentSectionView } from 'store/types/ApplicationContent';
import QuestionType from 'store/enums/QuestionType';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { getQuestionFieldName, getReviewSectionId, getSectionId } from 'util/Application';
import { ApplicationQuestionView } from 'store/types/ApplicationQuestion';
import CheckboxQuestionFormItem from './CheckboxQuestionFormItem';
import FileQuestionFormItem from './FileQuestionFormItem';
import RadioQuestionFormItem from './RadioQuestionFormItem';
import ReviewQuestionFormItem from './ReviewQuestionFormItem';
import SelectQuestionFormItem from './SelectQuestionFormItem';
import TextQuestionFormItem from './TextQuestionFormItem';
import SublistQuestionFormItem from './SublistQuestionFormItem';
import DateQuestionFormItem from './DateQuestionFormItem';
import MultilineTextQuestionFormItem from './MultilineTextQuestionFormItem';
import DropdownCustomListQuestionFormItem from './DropdownCustomListQuestionFormItem';

import commonStyles from 'styles/common.module.scss';
import styles from './ApplicationQuestionsFormSection.module.scss';

interface ApplicationQuestionsFormSectionProps {
  sections?: ApplicationContentSectionView[];
  isReviewForm?: boolean;
  disabled?: boolean;
  applicationId?: string;
}

const getQuestionInput = (
  question: ApplicationQuestionView,
  disabled?: boolean,
  isReviewForm?: boolean,
  applicationId?: string
): React.ReactNode => {
  const inputProps = {
    question,
    disabled,
    applicationId,
  };

  if (isReviewForm) {
    return <ReviewQuestionFormItem {...inputProps} />;
  } else {
    switch (question.type) {
      case QuestionType.Text:
        return <TextQuestionFormItem {...inputProps} />;
      case QuestionType.Checkbox:
        return <CheckboxQuestionFormItem {...inputProps} />;
      case QuestionType.Radio:
        return <RadioQuestionFormItem {...inputProps} />;
      case QuestionType.Dropdown:
        return <SelectQuestionFormItem {...inputProps} />;
      case QuestionType.File:
        return <FileQuestionFormItem {...inputProps} />;
      case QuestionType.SubList:
        return <SublistQuestionFormItem {...inputProps} />;
      case QuestionType.Date:
        return <DateQuestionFormItem {...inputProps} />;
      case QuestionType.MultilineText:
        return <MultilineTextQuestionFormItem {...inputProps} />;
      case QuestionType.DropdownCustomList:
        return <DropdownCustomListQuestionFormItem {...inputProps} />;
      default:
        return <></>;
    }
  }
};

const getQuestionTitle = (title: string, index: number, required: boolean): string =>
  `Q${++index}. ${title}${required ? '*' : ''}`;

const ApplicationQuestionsFormSection: React.FunctionComponent<ApplicationQuestionsFormSectionProps> = ({
  sections = [],
  disabled = false,
  isReviewForm = false,
  applicationId,
}) => {
  return (
    <>
      {sections.map(({ title, description, questions = [] }, index) => {
        const sectionId = isReviewForm ? getReviewSectionId(index) : getSectionId(index);

        return (
          <section key={sectionId} id={sectionId}>
            <Grid {...defaultGridContainerProps}>
              <Grid {...defaultGridItemProps}>
                <h2 className={classNames({ [styles.disabled]: disabled })}>{title}</h2>
                <p
                  className={classNames(commonStyles.text, { [styles.disabled]: disabled })}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </Grid>
              {questions.map((question, questionIndex) => {
                const { id, content, required } = question;
                const questionTitle: string = getQuestionTitle(
                  isReviewForm ? 'Requirement Met?' : question.title,
                  questionIndex,
                  required
                );

                return (
                  <React.Fragment key={getQuestionFieldName(id)}>
                    <Grid {...defaultGridItemProps}>
                      <h4 className={classNames(styles.label, { [styles.disabled]: disabled })}>{questionTitle}</h4>
                      {!isReviewForm && (
                        <p
                          dangerouslySetInnerHTML={{ __html: content }}
                          className={classNames({ [styles.disabled]: disabled })}
                        />
                      )}
                      {getQuestionInput(question, disabled, isReviewForm, applicationId)}
                    </Grid>
                  </React.Fragment>
                );
              })}
            </Grid>
          </section>
        );
      })}
    </>
  );
};
export default ApplicationQuestionsFormSection;
