import { Alert } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import CompanyRequestsPageView from 'components/company/CompanyRequestsPageView';
import useRequest from 'hooks/useRequest';
import { CompanyEmployeeAccessRequestsData } from 'store/types/CompanyEmployeeAccessRequest';
import Spinner from 'components/shared/Spinner';
import CompanyService from 'services/api/CompanyService';

import commonStyles from 'styles/common.module.scss';

interface CompanyRequestsPageProps {
  companyId: string;
  refetchCount: () => void;
}

const CompanyRequestsPage: React.FunctionComponent<CompanyRequestsPageProps> = ({ companyId, refetchCount }) => {
  const companyEmployeeAccessRequestsRequest = useMemo(
    () => (companyId ? () => CompanyService.getCompanyEmployeeAccessRequests(companyId) : undefined),
    [companyId]
  );
  const { data, loading, error, refetch } = useRequest<CompanyEmployeeAccessRequestsData>(
    companyEmployeeAccessRequestsRequest
  );

  const refetchRequests = useCallback(() => {
    refetch();
    refetchCount();
  }, [refetch, refetchCount]);

  return (
    <Spinner loading={loading} transparent={false}>
      {error ? (
        <Alert severity={'error'} className={commonStyles.alert}>
          {error}
        </Alert>
      ) : (
        data && <CompanyRequestsPageView data={data} companyId={companyId} refetch={refetchRequests} />
      )}
    </Spinner>
  );
};
export default CompanyRequestsPage;
