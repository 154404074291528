import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import PaymentAccessTable from './PaymentAccessTable';
import { ParametersContext } from 'components/ParametersGuard';

const ManageAccessSettingsPageView: React.FunctionComponent = () => {
  const {
    groupPayments: { manageAccessTabHeader },
  } = useContext(ParametersContext);

  return (
    <Grid {...defaultGridContainerProps}>
      <Grid {...defaultGridItemProps}>
        {manageAccessTabHeader && <p dangerouslySetInnerHTML={{ __html: manageAccessTabHeader }} />}
      </Grid>
      <Grid {...defaultGridItemProps}>
        <PaymentAccessTable />
      </Grid>
    </Grid>
  );
};
export default ManageAccessSettingsPageView;
