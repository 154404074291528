import React from 'react';
import Group from '@mui/icons-material/Group';
import Avatar from 'components/shared/Avatar';
import { getFormattedDate } from 'util/Format';
import { Community } from 'store/types/Community';
import { Divider } from '@mui/material';
import StatusLabel from 'components/shared/StatusLabel';
import CommunityMemberStatus from 'store/enums/CommunityMemberStatus';
import communityMemberStatusConfig from 'store/configs/communityMemberStatusConfig';

import styles from './CommunityListItem.module.scss';

interface CommunityListItemProps {
  item: Community;
  active?: boolean;
  showDateRequestedField?: boolean;
}

const getStatusLabel = (status: CommunityMemberStatus): React.ReactNode => {
  const { theme = 'grey', name = '' } = communityMemberStatusConfig[status];

  return <StatusLabel theme={theme} status={name} />;
};

const CommunityListItem: React.FunctionComponent<CommunityListItemProps> = ({
  item,
  active = false,
  showDateRequestedField = false,
}) => {
  const { logoUrl, name, memberHistory } = item;

  return (
    <div className={styles.container}>
      <Avatar src={logoUrl} icon={<Group />} className={styles.avatar} />
      <div className={styles.information}>
        <h5 className={styles.name}>{name}</h5>
        {!!memberHistory?.length && (
          <>
            <h3 className={styles.subTitle}>{active ? 'Active Member' : 'Member History'}</h3>
            <Divider className={styles.divider} />
            <div className={styles.memberHistory}>
              {memberHistory?.map(({ role, id, requestedDate, startDate, endDate, status }, index) => (
                <div className={styles.member} key={id}>
                  {!!index && <Divider className={styles.divider} />}
                  <span>{getStatusLabel(status)}</span>
                  {showDateRequestedField && requestedDate && (
                    <span>{`Date Requested: ${getFormattedDate(requestedDate)}`}</span>
                  )}
                  <span>{`Role: ${role}`}</span>
                  {startDate && <span>{`Start Date: ${getFormattedDate(startDate)}`}</span>}
                  {endDate && <span>{`End Date: ${getFormattedDate(endDate)}`}</span>}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default CommunityListItem;
