import React, { useEffect, useMemo, useState } from 'react';
import { CompanyDetails, CompanyDetailsView, CompanySocialLink } from 'store/types/Company';
import CompanyService from 'services/api/CompanyService';
import useRequest from 'hooks/useRequest';
import Alert from '@mui/material/Alert';
import Spinner from 'components/shared/Spinner';
import CompanyDetailsPageView from 'components/company/CompanyDetailsPageView';
import { getPreferencesQuestionsView } from 'util/Preferences';

import commonStyles from 'styles/common.module.scss';

interface CompanyInformationPageViewProps {
  companyId: string;
}

const CompanyDetailsPage: React.FunctionComponent<CompanyInformationPageViewProps> = ({ companyId }) => {
  const companyDetailsRequest = useMemo(
    () => (companyId ? () => CompanyService.getCompanyDetails(companyId) : undefined),
    [companyId]
  );
  const [companyDetails, setCompanyDetails] = useState<CompanyDetailsView>();
  const { data, loading, error } = useRequest<CompanyDetails>(companyDetailsRequest);

  useEffect(() => {
    if (data) {
      setCompanyDetails({
        ...data,
        preferences: getPreferencesQuestionsView(data.preferences),
        socialLinks: data?.socialLinks?.sort((a: CompanySocialLink) => (a.type === 'Other' ? 1 : -1)),
      });
    }
  }, [data]);

  return (
    <Spinner loading={loading} transparent={false}>
      {error ? (
        <Alert severity={'error'} className={commonStyles.alert}>
          {error}
        </Alert>
      ) : (
        companyDetails && <CompanyDetailsPageView companyId={companyId} data={companyDetails} />
      )}
    </Spinner>
  );
};
export default CompanyDetailsPage;
