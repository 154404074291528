import { FieldErrors, UseFormProps, ValidationRule } from 'react-hook-form';
import React, { KeyboardEvent } from 'react';
import { BaseTextFieldProps, GridProps } from '@mui/material';
import Address, { BillingAddress } from 'store/types/Address';
import { ValidateResult } from 'react-hook-form/dist/types';
import { DonationInfoFormValues } from 'store/types/FormValues';
import DonationActionType from 'store/enums/DonationActionType';
import { getDonationActionTypeLabel } from './Format';
import { DonationPaymentData } from 'services/api/PaymentService';
import DonationType from 'store/enums/DonationType';
import { sorter } from 'util/Table';
import SelectOption from 'store/types/SelectOption';
import commonStyles from 'styles/common.module.scss';

export interface InputProps {
  variant: 'outlined';
  fullWidth: boolean;
}

export interface FormProps {
  inputProps?: InputProps;
  rowProps?: GridProps;
  fieldName?: string;
  disabled?: boolean;
  required?: boolean;
}

export interface ValidationProps {
  error: boolean;
  helperText?: string | React.ReactElement;
}

export const defaultFormProps: UseFormProps<any> = {
  reValidateMode: 'onChange',
  mode: 'all',
  criteriaMode: 'all',
};

export const countryFieldName: keyof Address = 'country';

export const ACH_REGEXP = /^([0-9]{6}|[0-9]{9})$/;
export const FREEFORM_TEXT_MAX_LENGTH = 300;
export const EMAIL_MAX_LENGTH = 254;
export const EMAIL_REGEXP = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;
export const DEFAULT_POSTAL_CODE_REGEXP = /^[a-zA-Z0-9]{1,20}$/;
export const NUMBER_WHOLE_REGEXP = /^\d+$/;
export const NUMBER_DECIMAL_REGEXP = /^\d*\.\d+$/;
export const NUMBER_WHOLE_PLUS_DECIMAL_REGEXP = /^\d*(\.\d+)?$/;

export const LOGIN_USERNAME_FIELD_ID = 'login-username-field';
export const LOGIN_SECTION_ID = 'login-section';

export const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Please reload the page and try again';

export const getRequiredInputMessage = (label: string): string => `Please input ${label}`;

export const getRequiredSelectMessage = (label: string): string => `Please select ${label}`;

export const getLimitExceedInputMessage = (maxLength: number): string =>
  `Input cannot be longer than ${maxLength} character(s)`;

export const getMaxLengthValidationRule = (maxLength: number): ValidationRule<number> => ({
  value: maxLength,
  message: getLimitExceedInputMessage(maxLength),
});

export const getMinLengthValidationRule = (minLength: number): ValidationRule<number> => ({
  value: minLength,
  message: `Input cannot be less than ${minLength} character(s)`,
});

export const getRequiredValidationRule = (
  label: string,
  isSelect = false,
  ruleRequired = true
): ValidationRule<boolean> => ({
  value: ruleRequired,
  message: isSelect ? getRequiredSelectMessage(label) : getRequiredInputMessage(label),
});

export const getValidationError = (label: string, errors?: FieldErrors): boolean => {
  return !!errors && errors.hasOwnProperty(label) && errors[label] !== undefined;
};

export const getValidationHelperText = (label: string, errors?: FieldErrors): string =>
  !!errors && errors.hasOwnProperty(label) && errors[label] !== undefined ? (errors[label] as any).message : '';

export const getValidationProps = (label: string, errors?: FieldErrors): ValidationProps => {
  const values = label.split('.').reduce((nextObject: any, key: string) => {
    if (nextObject) {
      return nextObject[key];
    }
  }, errors);

  return !!errors && values
    ? {
        error: true,
        helperText: values.message || undefined,
      }
    : {
        error: false,
        helperText: undefined,
      };
};

export const getArrayFieldValidationProps = <T,>(
  label: keyof T,
  fieldIndex: number,
  errors?: FieldErrors
): ValidationProps => {
  const errorObj =
    !!errors && errors.hasOwnProperty(label) && Array.isArray(errors[label])
      ? (errors[label] as any[])[fieldIndex]
      : undefined;

  return errorObj
    ? {
        error: true,
        helperText: errorObj.value.message || '',
      }
    : { error: false, helperText: '' };
};

export const isCanadaSelected = (country = ''): boolean => country === 'CA' || country === 'Canada';
export const isUSSelected = (country = ''): boolean => country === 'US' || country === 'United States';

export const getAddressControlName = (addressFieldName: string, label: string): string =>
  `${addressFieldName}.${label}`;

export const getAddressFormValues = (address?: Partial<Address>): Address =>
  address
    ? {
        city: address.city || '',
        stateRegion: address.stateRegion || '',
        postalCode: address.postalCode || '',
        country: address.country || '',
        street1: address.street1 || '',
        street2: address.street2 || '',
        label: address.label || '',
      }
    : {
        city: '',
        stateRegion: '',
        postalCode: '',
        country: '',
        street1: '',
        street2: '',
        label: '',
      };

export const getBillingAddressFormValues = (address?: Partial<BillingAddress>): BillingAddress => ({
  ...getAddressFormValues(address),
  id: address?.id || '',
  addressee: address?.addressee || '',
  label: address?.label || '',
});

export const getSortedAddressList = (list: BillingAddress[] = []): BillingAddress[] =>
  list.length
    ? [...list]
        .sort(sorter<BillingAddress>({ column: 'label', direction: 'asc' }))
        .sort(sorter<BillingAddress>({ column: 'isDefaultShipping', direction: 'asc' }))
        .sort(sorter<BillingAddress>({ column: 'isDefaultBilling', direction: 'asc' }))
    : [...list];

export const validateEmailValue = (
  value: string,
  disabled = false,
  optional = false
): ValidateResult | Promise<ValidateResult> => {
  if (disabled) {
    return true;
  }
  if (value) {
    if (value.length > EMAIL_MAX_LENGTH) {
      return getLimitExceedInputMessage(EMAIL_MAX_LENGTH);
    } else if (!EMAIL_REGEXP.test(value)) {
      return 'Please input valid email';
    }
    return true;
  } else {
    return optional || getRequiredInputMessage('email');
  }
};

export const preventDecimalCharsInput = (e: KeyboardEvent): void => {
  if (e.key === '.' || e.key === 'e') {
    e.preventDefault();
  }
};

export const getUnsavedChangesPromptMessage = (): string =>
  'Your changes have not been saved. Click "Ok" to continue to the application homepage or "Cancel" to go back to the application and save your changes.';

export const getDonationsFormValues = (data?: DonationInfoFormValues): DonationInfoFormValues => ({
  amount: data?.amount || 0,
  donationType: data?.donationType || DonationType.Single,
  recurrentPeriod: data?.recurrentPeriod || undefined,
  isAnonymousGift: data?.isAnonymousGift || false,
  isAnonymousDonation: data?.isAnonymousDonation || false,
  acceptTerms: data?.acceptTerms || false,
  subscribeToUpdates: data?.subscribeToUpdates || false,
  payProcessingFee: data?.payProcessingFee || false,
  ...getGiftDonationFormValues(),
});

export const getGiftDonationFormValues = (
  data?: DonationInfoFormValues
): Partial<DonationInfoFormValues> | Partial<DonationPaymentData> => {
  let result: Partial<DonationInfoFormValues> | Partial<DonationPaymentData> = {};

  if (data) {
    const { hasEmail, hasAction, hasMessage, actionUserName, actionType, message, notifyEmail } = data;
    if (hasAction && actionType) {
      result = {
        ...result,
        actionType,
        actionUserName,
        dedication: `${getDonationActionTypeLabel(actionType)} ${actionUserName}`,
      };
    } else {
      result = {
        ...result,
        actionType: undefined,
      };
    }
    if (hasEmail) {
      result = { ...result, notifyEmail };
    }
    if (hasMessage) {
      result = { ...result, emailText: message };
    }
    return result;
  }
  return {
    actionType: DonationActionType.Honor,
    actionUserName: '',
    hasAction: false,
    hasEmail: false,
    hasMessage: false,
    message: '',
    notifyEmail: '',
  };
};

export const multipleSelectProps = (
  options: SelectOption[],
  placeholder: string
): Pick<BaseTextFieldProps, 'SelectProps'> => ({
  SelectProps: {
    displayEmpty: true,
    renderValue: (selected: any) => {
      const selectedValues =
        selected?.length && options.filter(({ id, name }) => selected.includes(id) || selected.includes(name)).length;
      return selectedValues ? (
        `(${selectedValues}) Selected`
      ) : (
        <span className={commonStyles.placeholder}>{placeholder}</span>
      );
    },
    multiple: true,
    MenuProps: { variant: 'menu' },
  },
});
