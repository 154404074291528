import { Grid } from '@mui/material';
import Tile from 'components/shared/Tile';
import React, { useContext } from 'react';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { UserContext } from 'components/UserGuard';

import styles from './HomeCreditsSection.module.scss';

const HomeCreditsSection: React.FunctionComponent = () => {
  const { creditBalance = 0, userLoading } = useContext(UserContext);

  return (
    <Grid {...defaultGridContainerProps}>
      <Grid {...defaultGridItemProps} sm={6}>
        <Tile loading={userLoading} value={`${creditBalance}`} label={'Credit Balance'} className={styles.tile} />
      </Grid>
    </Grid>
  );
};
export default HomeCreditsSection;
