import React, { useMemo } from 'react';
import { FormProps } from 'util/Form';
import { ApplicationQuestionView } from 'store/types/ApplicationQuestion';
import DateFormItem from 'components/shared/DateFormItem';

interface DateQuestionFormItemProps extends FormProps {
  question: ApplicationQuestionView;
}

const DateQuestionFormItem: React.FunctionComponent<DateQuestionFormItemProps> = ({ question, disabled }) => {
  const { fieldName, required } = useMemo(() => question, [question]);

  return <DateFormItem fieldName={fieldName} required={required} disabled={disabled} />;
};
export default DateQuestionFormItem;
