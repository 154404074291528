import { HttpService } from 'services/HttpService';
import { API_URL, tenantConfig } from 'config';
import AuthService from 'services/AuthService';
import { httpRequestWrapper } from 'util/Request';
import PaymentMethod from 'store/types/PaymentMethod';
import { NewPaymentMethodData } from 'store/types/PaymentMethodsData';

type PaymentMethodsResponseData = PaymentMethod[];

class PaymentMethodService {
  public static getPaymentMethods(): Promise<PaymentMethodsResponseData> {
    return new Promise<PaymentMethodsResponseData>(
      (resolve: (data: PaymentMethodsResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<PaymentMethodsResponseData>(
          HttpService.get(`${API_URL}/payment/methods`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject,
          false
        );
      }
    );
  }

  public static addPaymentMethod(data: NewPaymentMethodData): Promise<PaymentMethod> {
    return new Promise<PaymentMethod>((resolve: (data: PaymentMethod) => void, reject: (error: string) => void) => {
      httpRequestWrapper<PaymentMethod>(
        HttpService.post(`${API_URL}/payment/methods`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static updatePaymentMethod(paymentMethodId: string, data: NewPaymentMethodData): Promise<PaymentMethod> {
    return new Promise<PaymentMethod>((resolve: (data: PaymentMethod) => void, reject: (error: string) => void) => {
      httpRequestWrapper<PaymentMethod>(
        HttpService.put(`${API_URL}/payment/methods/${paymentMethodId}`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static deletePaymentMethod(paymentMethodId: string): Promise<null> {
    return new Promise((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.delete(`${API_URL}/payment/methods/${paymentMethodId}`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static changeDefaultPaymentMethod(id: string): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.put(`${API_URL}/payment/methods/default`, {
          data: { id },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getPaymentMethodDetails(id: string): Promise<PaymentMethod> {
    return new Promise<PaymentMethod>((resolve: (data: PaymentMethod) => void, reject: (error: string) => void) => {
      httpRequestWrapper<PaymentMethod>(
        HttpService.get(`${API_URL}/payment/methods/${id}`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject,
        false
      );
    });
  }
}
export default PaymentMethodService;
