import { TextField } from '@mui/material';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { getMaxLengthValidationRule, getRequiredValidationRule, getValidationProps } from 'util/Form';

import styles from './CampaignDescriptionFormItem.module.scss';

const VALUE_MAX_LENGTH = 500;

interface CampaignDescriptionFormItemProps {
  fieldName: string;
  label: string;
}

const CampaignDescriptionFormItem: React.FunctionComponent<CampaignDescriptionFormItemProps> = ({
  fieldName,
  label,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { error, helperText } = getValidationProps(fieldName, errors);
  const inputValue: string = useWatch({ control, name: fieldName }) || '';
  const countHelperText = useMemo(() => `${VALUE_MAX_LENGTH - inputValue.length} characters limit`, [inputValue]);

  return (
    <Controller
      render={({ field }) => (
        <TextField
          {...field}
          error={error}
          helperText={helperText || countHelperText}
          required={true}
          placeholder={label}
          InputProps={{
            multiline: true,
            rows: 4,
          }}
          FormHelperTextProps={{
            classes: {
              root: classNames({ [styles.helperText]: !error }),
            },
          }}
        />
      )}
      name={fieldName}
      control={control}
      rules={{
        required: getRequiredValidationRule(label),
        maxLength: getMaxLengthValidationRule(VALUE_MAX_LENGTH),
      }}
    />
  );
};
export default CampaignDescriptionFormItem;
