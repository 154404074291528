import { Alert, Button, Container, Grid, GridProps, MenuItem } from '@mui/material';
import React, { Reducer, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import {
  defaultGridContainerProps,
  defaultGridItemProps,
  defaultSnackbarErrorProps,
  getButtonLoadingProps,
} from 'util/Layout';
import SearchInput from 'components/shared/SearchInput';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { defaultFormProps } from 'util/Form';
import { MemberDirectorySearchFilter, MemberDirectoryCustomer } from 'store/types/MemberDirectory';
import MemberDirectoryService from 'services/api/MemberDirectoryService';
import MemberDirectorySearchFilterBar from 'components/directory/MemberDirectorySearchFilterBar';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import reducer, {
  initialState,
  ITEMS_PER_PAGE,
  MemberDirectoryPageAction,
  MemberDirectoryPageActionType,
  MemberDirectoryPageState,
} from './MemberDirectoryPageReducer';
import { getPagesCount } from 'util/Table';
import Pagination from '@mui/material/Pagination';
import MemberListItem from './MemberListItem';
import { useSnackbar } from 'notistack';
import MemberDirectoryCustomerType from 'store/enums/MemberDirectoryCustomerType';
import useRequest from 'hooks/useRequest';
import { ConfigContext } from 'components/ConfigGuard';
import CountryFormItem from './CountryFormItem/CountryFormItem';
import { useHistory, useLocation } from 'react-router';
import { ParametersContext } from 'components/ParametersGuard';

import styles from './MemberDirectoryPageView.module.scss';
import commonStyles from 'styles/common.module.scss';
import IndividualDetailsModal from './IndividualDetailsModal';
import CompanyDetailsModal from './CompanyDetailsModal';

const listItemRowProps: GridProps = { xl: 3, sm: 6, md: 4, xs: true };

interface MemberDirectoryPageViewFormValues {
  keyword: string;
  cityOrZip: string;
  states: string[];
  country: string;
  type: MemberDirectoryCustomerType;
  filters: string[];
  industries: string[];
}

const defaultValues: MemberDirectoryPageViewFormValues = {
  keyword: '',
  cityOrZip: '',
  states: [],
  country: '',
  type: MemberDirectoryCustomerType.Individual,
  filters: [],
  industries: [],
};

const getFormValues = (search?: string, defaultToUS?: boolean): MemberDirectoryPageViewFormValues => {
  let result: MemberDirectoryPageViewFormValues = { ...defaultValues, country: defaultToUS ? 'United States' : '' };

  if (search) {
    const searchParams: URLSearchParams = new URLSearchParams(search);

    searchParams.forEach((value: string, key: string) => {
      if (key === 'states' || key === 'filters' || key === 'industries') {
        result = { ...result, [key]: value !== '' ? value.split(',') : [] };
      } else {
        result = { ...result, [key]: value };
      }
    });
  }
  return result;
};

const MemberDirectoryPageView: React.FunctionComponent = () => {
  const { search, pathname } = useLocation();
  const history = useHistory();
  const [submitLoading, setSubmitLoading] = useState<boolean>();
  const [selectedMember, setSelectedMember] = useState<MemberDirectoryCustomer | undefined>(undefined);
  const [viewDetailsModalOpen, setViewDetailsModalOpen] = useState<boolean>(false);
  const {
    memberDirectory: { defaultMemberDirectorySearchToUS },
  } = useContext(ParametersContext);
  const form = useForm<MemberDirectoryPageViewFormValues>({
    ...defaultFormProps,
    defaultValues: getFormValues(search, defaultMemberDirectorySearchToUS),
  });
  const {
    modulesSettings: {
      memberDirectory: { pageTitle, pageSubTitle },
    },
  } = useContext(ConfigContext);
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, watch, resetField } = form;
  const [{ pageList = [], page = 1, initialList, filterBarOpen }, dispatch] = useReducer<
    Reducer<MemberDirectoryPageState, MemberDirectoryPageAction>
  >(reducer, initialState);
  const pagesCount: number = useMemo(() => getPagesCount(initialList.length, ITEMS_PER_PAGE), [initialList.length]);
  const { type, cityOrZip, keyword, country, states } = watch();
  const searchFiltersRequest = useMemo(() => () => MemberDirectoryService.getSearchFilters(type), [type]);
  const { data, loading, error } = useRequest<MemberDirectorySearchFilter[]>(searchFiltersRequest);
  const searchButtonDisabled: boolean = useMemo(
    () => cityOrZip?.length < 2 && keyword?.length < 2 && country === '' && states.length <= 0,
    [cityOrZip?.length, country, keyword?.length, states.length]
  );

  useEffect(() => {
    if (type) {
      dispatch({
        type: MemberDirectoryPageActionType.CloseFilterBar,
        payload: {},
      });
      resetField('filters');
    }
  }, [resetField, type]);

  const handleSearchSubmit = useCallback(
    (formValues: MemberDirectoryPageViewFormValues) => {
      setSubmitLoading(true);

      const newParams: URLSearchParams = new URLSearchParams();
      Object.keys(formValues).forEach((key: string) => {
        const value = formValues[key as keyof MemberDirectoryPageViewFormValues];

        if (value) {
          if (Array.isArray(value)) {
            newParams.set(key, value?.join());
          } else {
            newParams.set(key, value.toString());
          }
        }
      });
      history.replace({ pathname, search: `?${newParams.toString()}` });

      const { keyword, cityOrZip, type, filters, country, states, industries } = formValues;
      MemberDirectoryService.searchMember(keyword, cityOrZip, type, filters?.join(), country, states, industries)
        .then((data: MemberDirectoryCustomer[]) => {
          dispatch({
            type: MemberDirectoryPageActionType.SetInitialList,
            payload: { initialList: data },
          });
          setSubmitLoading(false);
        })
        .catch((errorMessage) => {
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
          setSubmitLoading(false);
        });
    },
    [enqueueSnackbar, history, pathname]
  );

  const handleFilterBarOpen = useCallback(() => {
    dispatch({
      type: MemberDirectoryPageActionType.OpenFilterBar,
      payload: {},
    });
  }, []);

  const handleFilterBarClose = useCallback(() => {
    dispatch({
      type: MemberDirectoryPageActionType.CloseFilterBar,
      payload: {},
    });
  }, []);

  const handlePageChange = useCallback((_: any, newPage: number) => {
    window.scrollTo(0, 500);
    dispatch({
      type: MemberDirectoryPageActionType.UpdatePage,
      payload: { page: newPage },
    });
  }, []);

  const handleViewDetails = useCallback(
    (user: MemberDirectoryCustomer) => () => {
      setSelectedMember(user);
      setViewDetailsModalOpen(true);
    },
    []
  );

  const handleViewDetailsModalClose = useCallback(() => {
    setViewDetailsModalOpen(false);
    setSelectedMember(undefined);
  }, []);

  return (
    <>
      <FormProvider {...form}>
        <div className={styles.container}>
          <Container maxWidth={'lg'} className={styles.container}>
            <div className={styles.bannerTitle}>
              <h1>{pageTitle}</h1>
              {pageSubTitle && (
                <div className={styles.subTitleBox}>
                  <span>{pageSubTitle}</span>
                </div>
              )}
            </div>
          </Container>
          <section className={styles.banner}>
            <Container maxWidth={'lg'} className={styles.bannerContent}>
              <Grid {...defaultGridContainerProps} className={styles.searchContainer} spacing={2}>
                <Grid {...defaultGridItemProps} md={4}>
                  <span className={styles.title}>{'Search By'}</span>
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <SearchInput
                        value={value}
                        inputClassName={styles.input}
                        onChange={onChange}
                        placeholder={
                          type === MemberDirectoryCustomerType.Company ? 'Company Name or Keyword' : 'Name or Keyword'
                        }
                      />
                    )}
                    defaultValue={''}
                    name={'keyword'}
                    control={control}
                  />
                </Grid>
                <Grid {...defaultGridItemProps} md={4}>
                  <span className={styles.title}>{'Type'}</span>
                  <Controller
                    render={({ field }) => (
                      <SearchInput {...field} select={true} className={styles.input} size={'small'}>
                        <MenuItem key={'individual'} value={MemberDirectoryCustomerType.Individual}>
                          {MemberDirectoryCustomerType.Individual}
                        </MenuItem>
                        <MenuItem key={'company'} value={MemberDirectoryCustomerType.Company}>
                          {MemberDirectoryCustomerType.Company}
                        </MenuItem>
                        <MenuItem key={'chapter'} value={MemberDirectoryCustomerType.Chapter}>
                          {MemberDirectoryCustomerType.Chapter}
                        </MenuItem>
                      </SearchInput>
                    )}
                    name={'type'}
                    defaultValue={MemberDirectoryCustomerType.Individual}
                    control={control}
                  />
                </Grid>
                <Grid {...defaultGridItemProps} md={2}>
                  <Button
                    {...getButtonLoadingProps(submitLoading)}
                    variant={'contained'}
                    color={'primary'}
                    onClick={handleSubmit(handleSearchSubmit)}
                    disabled={submitLoading || searchButtonDisabled}
                    fullWidth
                  >
                    {'Search'}
                  </Button>
                </Grid>
                <Grid {...defaultGridItemProps} md={2}>
                  <Button
                    {...getButtonLoadingProps(loading)}
                    variant={'contained'}
                    disabled={!data?.length || loading}
                    className={styles.filterButton}
                    onClick={filterBarOpen ? handleFilterBarClose : handleFilterBarOpen}
                    fullWidth
                  >
                    {'Filters'}
                    {filterBarOpen ? <CloseIcon /> : <FilterListIcon />}
                  </Button>
                </Grid>
              </Grid>
              <Grid {...defaultGridContainerProps} className={styles.locationContainer} spacing={2}>
                <Grid {...defaultGridItemProps} md={4}>
                  <span className={styles.title}>{'City or Zipcode'}</span>
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <SearchInput
                        value={value}
                        inputClassName={styles.input}
                        onChange={onChange}
                        placeholder={'City or Zipcode'}
                      />
                    )}
                    defaultValue={''}
                    name={'cityOrZip'}
                    control={control}
                  />
                </Grid>
                <CountryFormItem required={false} />
              </Grid>
            </Container>
          </section>
          {error ? (
            <Alert severity={'error'} className={commonStyles.alert}>
              {error}
            </Alert>
          ) : (
            !!data?.length &&
            filterBarOpen && (
              <MemberDirectorySearchFilterBar
                data={data}
                onSubmit={handleSubmit(handleSearchSubmit)}
                loading={submitLoading}
                submitButtonDisabled={searchButtonDisabled}
              />
            )
          )}
          <section className={styles.darkSection}>
            <Container maxWidth={'xl'} className={styles.sectionContent}>
              <Grid {...defaultGridContainerProps}>
                {pageList.length ? (
                  pageList.map((user: MemberDirectoryCustomer, index) => (
                    <Grid {...defaultGridItemProps} {...listItemRowProps} key={`member-${user.id}-${index}`}>
                      <MemberListItem key={user.id} data={user} onClick={handleViewDetails(user)} />
                    </Grid>
                  ))
                ) : (
                  <Grid {...defaultGridItemProps}>
                    <p className={styles.noResultText}>{'Please update search criteria to display results'}</p>
                  </Grid>
                )}
              </Grid>
            </Container>
          </section>
          <section>
            <Container maxWidth={'xl'} className={styles.sectionContent}>
              <div className={styles.paginationWrapper}>
                <Pagination
                  showFirstButton={true}
                  showLastButton={true}
                  count={pagesCount}
                  page={page}
                  onChange={handlePageChange}
                />
              </div>
            </Container>
          </section>
        </div>
      </FormProvider>
      {selectedMember?.customerType === MemberDirectoryCustomerType.Individual && (
        <IndividualDetailsModal
          member={selectedMember}
          open={viewDetailsModalOpen}
          onClose={handleViewDetailsModalClose}
        />
      )}
      {selectedMember?.customerType === MemberDirectoryCustomerType.Company && (
        <CompanyDetailsModal
          member={selectedMember}
          open={viewDetailsModalOpen}
          onClose={handleViewDetailsModalClose}
        />
      )}
      {selectedMember?.customerType === MemberDirectoryCustomerType.Chapter && (
        <CompanyDetailsModal
          member={selectedMember}
          open={viewDetailsModalOpen}
          onClose={handleViewDetailsModalClose}
          isChapter={true}
        />
      )}
    </>
  );
};
export default MemberDirectoryPageView;
