import { ConfigSelectOption } from 'store/types/SelectOption';
import EventStatus from 'store/enums/events/EventStatus';

const eventStatusConfig: Record<EventStatus, ConfigSelectOption> = {
  [EventStatus.NotAvailable]: { name: 'Not Available', theme: 'grey' },
  [EventStatus.Available]: { name: 'Available', theme: 'green' },
  [EventStatus.Selected]: { name: 'Selected', theme: 'navy' },
  [EventStatus.Full]: { name: 'Full', theme: 'blue' },
  [EventStatus.Waitlist]: { name: 'Full', theme: 'blue' },
};

export default eventStatusConfig;
