import React, { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { defaultFormProps } from 'util/Form';
import { PreferenceQuestionAnswer, PreferenceQuestionView } from 'store/types/PreferenceQuestion';
import EditPreferencesForm from 'components/profile/EditPreferencesPage/EditPreferencesForm';
import FormFooter from 'components/shared/FormFooter';
import PreferencesService from 'services/api/PreferencesService';
import { useSnackbar } from 'notistack';
import Spinner from 'components/shared/Spinner';
import { getPreferencesAnswersData, getDefaultPreferencesFormValues } from 'util/Preferences';
import { useLocation } from 'react-router';
import routes from 'store/configs/Routes';
import EventService from 'services/api/EventService';
import { defaultSnackbarErrorProps } from 'util/Layout';

interface EditCustomPreferencesPageViewProps {
  data: PreferenceQuestionView[];
  alternateId?: string;
}

const EditPreferencesPageView: React.FunctionComponent<EditCustomPreferencesPageViewProps> = ({
  data,
  alternateId,
}) => {
  const defaultValues = useMemo(() => getDefaultPreferencesFormValues(data), [data]);
  const form = useForm({ ...defaultFormProps, defaultValues });
  const { pathname } = useLocation();
  const { handleSubmit } = form;
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleFormSubmit = useCallback(
    (formValues) => {
      setSubmitLoading(true);
      const answers: PreferenceQuestionAnswer[] = getPreferencesAnswersData(formValues, data);

      (alternateId
        ? pathname.includes(routes.viewPrivatePreferences)
          ? PreferencesService.updatePreferenceQuestionsByAlternateId(alternateId, answers)
          : EventService.updatePreferenceQuestionsByAlternateId(alternateId, answers)
        : PreferencesService.updatePreferenceQuestions(answers)
      )
        .then(() => {
          setSubmitLoading(false);
          enqueueSnackbar('Preferences successfully updated', { variant: 'success' });
        })
        .catch((error: string) => {
          setSubmitLoading(false);
          enqueueSnackbar(error, defaultSnackbarErrorProps);
        });
    },
    [alternateId, data, enqueueSnackbar, pathname]
  );

  const handleSubmitButtonClick = useCallback(() => {
    window.scrollTo(0, 0);
    handleSubmit(handleFormSubmit)();
  }, [handleFormSubmit, handleSubmit]);

  return (
    <Spinner loading={submitLoading}>
      <FormProvider {...form}>
        <EditPreferencesForm data={data} />
        <FormFooter onSubmit={handleSubmitButtonClick} />
      </FormProvider>
    </Spinner>
  );
};

export default EditPreferencesPageView;
