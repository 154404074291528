import AccessTime from '@mui/icons-material/AccessTime';
import classNames from 'classnames';
import React from 'react';
import { MainEventView } from 'store/types/events/Event';
import { getStringValue } from 'util/Format';
import { getFormattedEventTime } from 'util/Event';
import ComponentProps from 'store/types/ComponentProps';
import { MainEventRegistrationView } from 'store/types/events/EventRegistration';

import rowStyles from '../EventInfoRow.module.scss';

interface EventTimeRowProps extends ComponentProps {
  data: MainEventView | MainEventRegistrationView;
}

const EventTimeRow: React.FunctionComponent<EventTimeRowProps> = ({
  data: { startDate, endDate, timeZoneName },
  className = '',
}) => (
  <div className={classNames(rowStyles.row, className)}>
    <AccessTime className={rowStyles.icon} />
    {getStringValue(getFormattedEventTime(startDate, endDate, timeZoneName))}
  </div>
);
export default EventTimeRow;
