import routes from 'store/configs/Routes';
import TenantConfig from 'store/types/TenantConfig';
import { INTERNAL_IDP_TYPE, tenantConfig } from 'config';
import SiteModule from 'store/enums/SiteModule';
import MenuConfigItem from 'store/types/MenuConfigItem';
import { CurrentUser } from 'store/types/User';
import authClient from 'services/AuthService';
import AuthService from 'services/AuthService';
import { SidebarSiteConfigItem } from 'store/types/SiteConfig';
import moduleRoutesConfig from 'store/configs/ModuleRoutesConfig';
import { isModuleAvailable } from 'util/User';
import {
  getLocalStorageValue,
  IMPERSONATION_FLAG_LS_LABEL,
  SUPER_ADMIN_DETAILS_FLAG_LS_LABEL,
  SUPER_ADMIN_FLAG_LS_LABEL,
} from './LocalStorage';

export const preselectedCommunityStatePropName = 'communityId';

export const getHashRouteUrl = (route: string): string => (route ? `${routes.root}${route}` : '');

export const isInternalIdpType = ({ idpType }: TenantConfig): boolean => idpType === INTERNAL_IDP_TYPE;

// NOTE: default/parent route should be the first element in tabRoutesConfig array
export const getActiveTabValue = (pathname: string, tabRoutesConfig: string[] = []): string => {
  const foundReversedIndex: number = [...tabRoutesConfig].reverse().findIndex((route) => pathname.includes(route));
  const foundIndex = foundReversedIndex > -1 ? Math.abs(foundReversedIndex - tabRoutesConfig.length + 1) : 0;

  return `${foundIndex + 1}`;
};

export const getActiveTabRoute = (value: string, tabRoutesConfig: string[] = []): string =>
  tabRoutesConfig[parseInt(value, 10) - 1];

export const getExternalUrl = (url: string): string => (url.slice(0, 4).includes('http') ? url : `//${url}`);

export const getAppMenuConfig = (
  currentUser: CurrentUser,
  sidebar: SidebarSiteConfigItem[] = [],
  isNccerBillingApp?: boolean
): MenuConfigItem[] => {
  const result: MenuConfigItem[] = [];
  const getItemProps = ({ title, icon, type, url = '', tooltipText }: SidebarSiteConfigItem): MenuConfigItem => ({
    title,
    icon,
    type,
    tooltipText,
    ...(url
      ? {
          onClick: () => {
            window.open(getExternalUrl(url), '_blank');
          },
        }
      : {
          to: moduleRoutesConfig[type as SiteModule] || '',
        }),
  });

  sidebar.forEach((menuConfigItem) => {
    const { functions, userPermissions } = currentUser;
    const { type, children } = menuConfigItem;
    const childrenItems: SidebarSiteConfigItem[] | undefined = children?.filter(({ type }) =>
      isModuleAvailable(type, functions, userPermissions)
    );

    if (!isModuleAvailable(type, functions, userPermissions)) {
      return;
    }

    result.push({
      ...getItemProps(menuConfigItem),
      ...(childrenItems && {
        children: childrenItems.map((item) => ({ ...getItemProps(item) })),
      }),
    });
  });

  const isImpersonation = !!getLocalStorageValue(IMPERSONATION_FLAG_LS_LABEL);
  const isUserSuperAdmin = !!getLocalStorageValue(SUPER_ADMIN_FLAG_LS_LABEL);
  const superAdminDetails = getLocalStorageValue(SUPER_ADMIN_DETAILS_FLAG_LS_LABEL);

  if (!isImpersonation && isUserSuperAdmin && !isNccerBillingApp) {
    result.push({
      title: 'Impersonation',
      key: 'impersonation',
      icon: 'camera_front',
      divider: true,
      onClick: () => window.open(tenantConfig.impersonationUrl, '_self'),
    });
  }

  const token: string = AuthService.getIdToken();
  const logoutLink: string = isImpersonation
    ? tenantConfig.reLoginUrl + encodeURIComponent(superAdminDetails)
    : isNccerBillingApp
    ? `${tenantConfig.logoutUrl}&id_token_hint=${token}`
    : '';

  if (!isImpersonation || !isNccerBillingApp) {
    result.push({
      title: 'Sign Out',
      key: 'logout',
      icon: 'exit_to_app',
      divider: !isUserSuperAdmin || isNccerBillingApp,
      onClick: () => authClient.logout(logoutLink),
    });
  }

  return result;
};

export const getPersonalPaymentsRoutes = (enablePayInvoices?: boolean): string[] => {
  const defaultRoutes: string[] = [routes.personalPayments, routes.personalPaymentDetails];

  return !enablePayInvoices
    ? defaultRoutes
    : [...defaultRoutes, routes.makePersonalPayment, routes.makePersonalSalesPayment];
};

export const getLoginPath = (redirectUrl = ''): string => {
  const { loginUrl } = tenantConfig;

  return `${loginUrl}${btoa(redirectUrl.substring(1))}`;
};

export const getDefaultHomeRoute = (enabledModules: SiteModule[]): string =>
  enabledModules.includes(SiteModule.Home) ? routes.home : routes.profile;

export const modulesRequirePayment = (enabledModules: SiteModule[] = []): boolean =>
  enabledModules.includes(SiteModule.PersonalPayments) ||
  enabledModules.includes(SiteModule.GroupPayments) ||
  enabledModules.includes(SiteModule.CompanyPayments) ||
  enabledModules.includes(SiteModule.Membership) ||
  enabledModules.includes(SiteModule.Subscriptions) ||
  enabledModules.includes(SiteModule.Donations) ||
  enabledModules.includes(SiteModule.DonationsPremium);
