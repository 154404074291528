import React from 'react';
import { Grid } from '@mui/material';
import ComponentProps from 'store/types/ComponentProps';
import { defaultGridItemProps } from 'util/Layout';
import PaymentMethodCardType from 'store/enums/PaymentMethodCardType';
import { getPaymentCardTypeConfig } from 'util/Payment';

const cardsConfig: PaymentMethodCardType[] = [
  PaymentMethodCardType.Visa,
  PaymentMethodCardType.Mastercard,
  PaymentMethodCardType.Amex,
  PaymentMethodCardType.Diners,
  PaymentMethodCardType.Discover,
];

const PaymentCardImages: React.FunctionComponent<ComponentProps> = ({ className = '' }) => {
  return (
    <Grid {...defaultGridItemProps} className={className}>
      {cardsConfig.map((name: PaymentMethodCardType) => {
        const { text = '', icon = '' } = getPaymentCardTypeConfig(name);

        return <img key={text} src={icon} alt={text} />;
      })}
    </Grid>
  );
};
export default PaymentCardImages;
