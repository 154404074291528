import React, { useCallback, useContext, useMemo, useState } from 'react';
import { MainEventView } from 'store/types/events/Event';
import Card from 'components/shared/Card';
import { Button, ButtonProps, Grid, Link } from '@mui/material';
import { defaultGridContainerProps, defaultGridItemProps, getButtonLoadingProps } from 'util/Layout';
import EventStatus from 'store/enums/events/EventStatus';
import { getPrice } from 'util/Payment';
import { EVENT_DATE_FORMAT, getEventPriceLevel } from 'util/Event';
import { getHashRouteUrl, getLoginPath } from 'util/Route';
import routes from 'store/configs/Routes';
import { UserContext } from 'components/UserGuard';
import { useLocation } from 'react-router';
import { isModuleAvailable } from 'util/User';
import SiteModule from 'store/enums/SiteModule';
import EventService from 'services/api/EventService';
import { useSnackbar } from 'notistack';
import ManageEventSessionsModal from 'components/events/ManageEventSessionsModal';
import ManageEventSessionsMode from 'store/enums/ManageEventSessionsMode';
import AddToWaitListModal from 'components/events/AddToWaitListModal';
import GroupRegistrationModal from 'components/events/GroupRegistrationModal';
import AddEventGuestModal from 'components/events/AddEventGuestModal';
import { EventCartContext } from 'components/EventCartGuard';

import styles from './EventPriceCard.module.scss';

interface EventPriceCardProps {
  data: MainEventView;
}

const EventPriceCard: React.FunctionComponent<EventPriceCardProps> = ({ data }) => {
  const { id, status, availableUntilDate, prices, isUserRegistered, memberTypes, multipleRegistrationsAllowed } = data;
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { id: currentUserId, priceLevelId, memberTypeName, functions, userPermissions } = useContext(UserContext);
  const { cart = [], refetchCart, cartLoading } = useContext(EventCartContext);
  const sessions = useMemo(
    () =>
      data.sessions?.map((session) => ({
        ...session,
        price: getEventPriceLevel(session.prices, priceLevelId),
      })),
    [data, priceLevelId]
  );
  const isEventAvailableForSale: boolean = useMemo(
    () => !!availableUntilDate && availableUntilDate.isAfter(new Date()),
    [availableUntilDate]
  );
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [sessionsModalOpen, setSessionsModalOpen] = useState<boolean>(false);
  const [addEventGuestModalOpen, setAddEventGuestModalOpen] = useState<boolean>(false);
  const [waitListModalOpen, setWaitListModalOpen] = useState<boolean>(false);
  const [groupRegistrationModalOpen, setGroupRegistrationModalOpen] = useState<boolean>(false);
  const buttonProps: ButtonProps = useMemo(
    () => ({
      variant: 'contained',
      size: 'large',
      color: 'secondary',
      disabled: submitLoading || cartLoading,
      ...getButtonLoadingProps(submitLoading || cartLoading),
    }),
    [cartLoading, submitLoading]
  );

  const eventAddedToCart = useMemo(() => cart.find(({ eventId }) => eventId === id), [cart, id]);
  const hasAuthorizedUser: boolean = useMemo(() => !!currentUserId, [currentUserId]);
  const eventAvailableByMemberType: boolean = useMemo(
    () => !memberTypes?.length || !!memberTypes?.find(({ name }) => name === memberTypeName),
    [memberTypeName, memberTypes]
  );
  const showGroupRegistration: boolean = useMemo(
    () =>
      isModuleAvailable(SiteModule.GroupRegistration, functions, userPermissions) &&
      !(status === EventStatus.Full || status === EventStatus.Waitlist),
    [functions, status, userPermissions]
  );

  const handleSessionsModalClose = useCallback(() => {
    setSessionsModalOpen(false);
  }, []);

  const handleAddWaitListModalOpen = useCallback(() => {
    setWaitListModalOpen(true);
  }, []);

  const handleAddWaitListModalClose = useCallback(() => {
    setWaitListModalOpen(false);
  }, []);

  const handleGroupRegModalClick = useCallback(() => {
    setGroupRegistrationModalOpen(true);
  }, []);

  const handleGroupRegModalClose = useCallback(() => {
    setGroupRegistrationModalOpen(false);
  }, []);

  // const handleAddEventGuestModalOpen = useCallback(() => {
  //   setAddEventGuestModalOpen(true);
  // }, []);

  const handleAddEventGuestModalClose = useCallback(() => {
    setAddEventGuestModalOpen(false);
  }, []);

  const handleAddEvent = useCallback(() => {
    if (sessions?.length) {
      setSessionsModalOpen(true);
    } else {
      setSubmitLoading(true);
      EventService.addEventToCart(id)
        .then(() => {
          setSubmitLoading(false);
          enqueueSnackbar('Event was added to cart', {
            variant: 'success',
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          });
          refetchCart();
        })
        .catch((errorMessage: string) => {
          setSubmitLoading(false);
          enqueueSnackbar(errorMessage, { variant: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } });
        });
    }
  }, [enqueueSnackbar, id, refetchCart, sessions?.length]);

  return (
    <>
      <Card contentClassName={styles.priceCardContent}>
        <Grid {...defaultGridContainerProps} spacing={2}>
          {status === EventStatus.Available || status === EventStatus.Waitlist ? (
            currentUserId && !eventAvailableByMemberType ? (
              <Grid {...defaultGridItemProps}>
                <p className={styles.notAvailableEvent}>{'Become a Member to Attend!'}</p>
              </Grid>
            ) : (
              <>
                <Grid {...defaultGridItemProps}>
                  <span className={styles.subtitle}>{'Event Starts At:'}</span>
                </Grid>
                <Grid {...defaultGridItemProps} className={styles.price}>
                  <span>{getPrice(getEventPriceLevel(prices, priceLevelId))}</span>
                </Grid>
                {hasAuthorizedUser ? (
                  <>
                    {status === EventStatus.Waitlist && (!isUserRegistered || multipleRegistrationsAllowed) ? (
                      <Grid {...defaultGridItemProps}>
                        <Button {...buttonProps} onClick={handleAddWaitListModalOpen}>
                          {'Join Waitlist'}
                        </Button>
                      </Grid>
                    ) : eventAddedToCart ? (
                      <Grid {...defaultGridItemProps}>
                        <Button {...buttonProps} variant={'outlined'} href={getHashRouteUrl(routes.eventCart)}>
                          {'View cart'}
                        </Button>
                      </Grid>
                    ) : (
                      (!isUserRegistered || multipleRegistrationsAllowed) && (
                        <Grid {...defaultGridItemProps}>
                          <Button {...buttonProps} onClick={handleAddEvent}>
                            {'Add To Cart'}
                          </Button>
                        </Grid>
                      )
                    )}
                    {/*hidden due PBI-20123-Hide 'Register a Guest' button on event details page*/}
                    {/*{guestRegistrationAllowed && status !== EventStatus.Waitlist && isUserRegistered && (*/}
                    {/*  <Grid {...defaultGridItemProps}>*/}
                    {/*    <Button {...buttonProps} variant={'contained'} onClick={handleAddEventGuestModalOpen}>*/}
                    {/*      {'Register a Guest'}*/}
                    {/*    </Button>*/}
                    {/*  </Grid>*/}
                    {/*)}*/}
                    {showGroupRegistration && (
                      <Grid {...defaultGridItemProps}>
                        <Button {...buttonProps} onClick={handleGroupRegModalClick}>
                          {'Start Group Registration'}
                        </Button>
                      </Grid>
                    )}
                  </>
                ) : (
                  <Grid {...defaultGridItemProps}>
                    <p className={styles.loginText}>{'Please log in or register to complete your purchase.'}</p>
                    <Button variant={'contained'} color={'secondary'} href={getLoginPath(pathname)}>
                      {'Sign In'}
                    </Button>
                  </Grid>
                )}
                {isEventAvailableForSale && availableUntilDate && (
                  <Grid {...defaultGridItemProps}>
                    <span
                      className={styles.availableUntilDate}
                    >{`Registration for the event is available until ${availableUntilDate.format(EVENT_DATE_FORMAT)} ${
                      data.timeZoneName
                    }`}</span>
                  </Grid>
                )}
              </>
            )
          ) : (
            <Grid {...defaultGridItemProps}>
              <p className={styles.notAvailableEvent}>
                {status === EventStatus.Full
                  ? 'Sold Out'
                  : isEventAvailableForSale
                  ? 'Coming Soon'
                  : 'Registration Closed'}
              </p>
            </Grid>
          )}
          {isUserRegistered && (
            <Grid {...defaultGridItemProps}>
              <Link href={getHashRouteUrl(routes.myEvents)} underline={'always'}>
                {'Visit My Events'}
              </Link>
            </Grid>
          )}
        </Grid>
      </Card>
      <ManageEventSessionsModal
        data={sessions || []}
        id={id}
        open={sessionsModalOpen}
        onClose={handleSessionsModalClose}
        mode={ManageEventSessionsMode.AddToCart}
      />
      <AddToWaitListModal data={data} open={waitListModalOpen} onClose={handleAddWaitListModalClose} />
      <AddEventGuestModal
        onClose={handleAddEventGuestModalClose}
        open={addEventGuestModalOpen}
        eventId={id}
        data={sessions || []}
      />
      {groupRegistrationModalOpen && (
        <GroupRegistrationModal event={data} open={groupRegistrationModalOpen} onClose={handleGroupRegModalClose} />
      )}
    </>
  );
};

export default EventPriceCard;
