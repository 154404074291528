import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import classNames from 'classnames';
import { Alert, Box, Button, Container, Grid } from '@mui/material';
import ProductsService from 'services/api/ProductsService';
import PaymentService, { ProductCheckoutData, ProductsPaymentData } from 'services/api/PaymentService';
import useRequest from 'hooks/useRequest';
import { PaymentMethodsContext } from 'components/PaymentMethodsContextWrapper';
import { UserContext } from 'components/UserGuard';
import { ProductCartContext } from 'components/ProductCartGuard';
import { getSelectedProducts, getTotalPrice } from 'util/Product';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import {
  defaultPaymentFormValues,
  defaultBaseAddressFormValues,
  getPaymentData,
  getPrice,
  paymentFormInvalid,
  shouldValidatePaymentMethod,
} from 'util/Payment';
import { EventsCartPaymentData } from 'store/types/events/EventsCartItem';
import Product, { SelectedProduct } from 'store/types/Products';
import { BaseAddressFormValues, PaymentFormValues } from 'store/types/FormValues';
import CollectJSResponse from 'store/types/CollectJSResponse';
import Payment from 'store/types/Payment';
import Card from 'components/shared/Card';
import Spinner from 'components/shared/Spinner';
import ProductsCartListItem from './ProductsCartListItem';
import FormFooter from 'components/shared/FormFooter';
import PaymentAddressSection from 'components/payments/PaymentFormSection/PaymentAddressSection';
import PaymentMethodSection from 'components/payments/PaymentMethodSection';
import PaymentDetailsModal from 'components/payments/PaymentDetailsModal';
import { ConfigContext } from 'components/ConfigGuard';
import { ParametersContext } from 'components/ParametersGuard';
import PromoCodeSection from 'components/payments/PromoCodeSection';
import ShippingMethodSection from './ShippingMethodSection';
import { SiteConfigView } from 'store/types/SiteConfig';

import commonStyles from 'styles/common.module.scss';
import styles from './ProductCartPageView.module.scss';

const ProductCartPageView: React.FunctionComponent = () => {
  const {
    modulesSettings: {
      payments: { products: productsPaymentsConfig },
    },
  }: SiteConfigView = useContext(ConfigContext);
  const { enqueueSnackbar } = useSnackbar();
  const {
    products: { returnButtonLabel, returnButtonLink },
  } = useContext(ParametersContext);
  const { priceLevelId } = useContext(UserContext);
  const { cart, cartLoading } = useContext(ProductCartContext);
  const { refetch: refetchPaymentMethods } = useContext(PaymentMethodsContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [isCheckoutPage, setIsCheckoutPage] = useState<boolean>(false);
  const [cartProductIds, setCartProductIds] = useState<string[]>([]);
  const [cartPaymentData, setCartPaymentData] = useState<EventsCartPaymentData>();
  const [payment, setPayment] = useState<Payment>();
  const [promoCodeId, setPromoCodeId] = useState<string>('');
  const [shippingMethodId, setShippingMethodId] = useState<string>('');
  const [paymentForm, setPaymentForm] = useState<PaymentFormValues>({
    ...defaultPaymentFormValues,
    useHomeAddress: true,
  });
  const [shippingAddressForm, setShippingAddressForm] = useState<BaseAddressFormValues>({
    ...defaultBaseAddressFormValues,
  });
  const getProductDetailsRequest = useMemo(
    () => (cartProductIds?.length > 0 ? () => ProductsService.getProductDetails(cartProductIds) : undefined),
    [cartProductIds]
  );
  const {
    data: products,
    loading: productsLoading,
    error: productsError,
  } = useRequest<Product[]>(getProductDetailsRequest);
  const selectedProducts: SelectedProduct[] = useMemo(() => getSelectedProducts(cart, products), [cart, products]);
  const subTotal: number = useMemo(
    () => getTotalPrice(selectedProducts, priceLevelId),
    [priceLevelId, selectedProducts]
  );
  const totalPrice: number = useMemo(
    () => (isCheckoutPage ? cartPaymentData?.totalAmount || subTotal : subTotal),
    [cartPaymentData?.totalAmount, isCheckoutPage, subTotal]
  );
  const discount: number = useMemo(
    () =>
      cartPaymentData
        ? subTotal + cartPaymentData.shippingCoast + cartPaymentData.tax - cartPaymentData.totalAmount
        : 0,
    [cartPaymentData, subTotal]
  );
  const skipShipping: boolean = useMemo(
    () => !selectedProducts.some((product) => !product.excludeFromShipping),
    [selectedProducts]
  );
  const submitButtonDisabled: boolean = useMemo(
    () =>
      (isCheckoutPage && paymentFormInvalid(paymentForm)) ||
      (!skipShipping && !shippingMethodId) ||
      selectedProducts.length <= 0,
    [isCheckoutPage, paymentForm, selectedProducts.length, shippingMethodId, skipShipping]
  );
  const handlePaymentFormChange = useCallback((values: Partial<PaymentFormValues>) => {
    setPaymentForm((prevState) => ({ ...prevState, ...values }));
  }, []);

  const handleShippingAddressFormChange = useCallback((values: Partial<BaseAddressFormValues>) => {
    setShippingAddressForm((prevState) => ({ ...prevState, ...values }));
  }, []);

  const handleCheckout = useCallback(() => {
    setLoading(true);
    const checkoutData: ProductCheckoutData = {
      shippingMethodId: shippingMethodId,
      useDefaultShippingAddress: shippingAddressForm.useHomeAddress,
      shippingAddress: shippingAddressForm.billingAddress,
    };
    PaymentService.checkoutProductsCart(checkoutData)
      .then((cartPaymentData: EventsCartPaymentData) => {
        refetchPaymentMethods();
        setLoading(false);
        setIsCheckoutPage(true);
        setCartPaymentData(cartPaymentData);
      })
      .catch((errorMessage: string) => {
        setLoading(false);
        enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
      });
  }, [
    enqueueSnackbar,
    refetchPaymentMethods,
    shippingAddressForm.billingAddress,
    shippingAddressForm.useHomeAddress,
    shippingMethodId,
  ]);

  const makePayment = useCallback(
    (collectJSResponse?: CollectJSResponse) => {
      setLoading(true);

      const paymentData: ProductsPaymentData = {
        ...getPaymentData(paymentForm, collectJSResponse),
        totalAmount: totalPrice,
        shippingMethodId: shippingMethodId,
        promoCodeId: promoCodeId,
        useDefaultShippingAddress: shippingAddressForm.useHomeAddress,
        shippingAddress: shippingAddressForm.billingAddress,
        products: selectedProducts.map((product) => ({ productId: product.id, quantity: product.quantity })),
      };

      PaymentService.makeProductsCartPayment(paymentData)
        .then((newPayment: Payment) => {
          refetchPaymentMethods();
          setLoading(false);
          setPayment(newPayment);
          enqueueSnackbar('Products successfully paid', { variant: 'success' });
        })
        .catch((errorMessage: string) => {
          setLoading(false);
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        });
    },
    [
      enqueueSnackbar,
      paymentForm,
      promoCodeId,
      refetchPaymentMethods,
      selectedProducts,
      shippingAddressForm.billingAddress,
      shippingAddressForm.useHomeAddress,
      shippingMethodId,
      totalPrice,
    ]
  );

  const handlePaymentComplete = (response: CollectJSResponse) => {
    makePayment(response);
  };

  const handleSubmit = useCallback(() => {
    setLoading(true);
    if (shouldValidatePaymentMethod(paymentForm)) {
      (window as any).CollectJS.startPaymentRequest();
    } else {
      makePayment();
    }
  }, [makePayment, paymentForm]);

  const handleCheckoutPageBack = useCallback(() => {
    setIsCheckoutPage(false);
    setPromoCodeId('');
  }, []);

  const handlePromoCode = useCallback(
    (newPromoCodeId) => {
      setLoading(true);
      const checkoutData: ProductCheckoutData = {
        shippingMethodId: shippingMethodId,
        promoCodeId: newPromoCodeId,
        useDefaultShippingAddress: shippingAddressForm.useHomeAddress,
        shippingAddress: shippingAddressForm.billingAddress,
      };
      PaymentService.checkoutProductsCart(checkoutData)
        .then((cartPaymentData: EventsCartPaymentData) => {
          setLoading(false);
          setPromoCodeId(newPromoCodeId);
          setCartPaymentData(cartPaymentData);
        })
        .catch((errorMessage: string) => {
          setLoading(false);
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        });
    },
    [enqueueSnackbar, shippingAddressForm.billingAddress, shippingAddressForm.useHomeAddress, shippingMethodId]
  );

  useEffect(() => {
    setCartProductIds(cart.map((cartLine) => cartLine.productId));
  }, [cart]);

  useEffect(() => {
    setPaymentForm((prevState) => ({
      ...prevState,
      totalPrice,
    }));
  }, [totalPrice]);

  return (
    <>
      <Spinner loading={loading || productsLoading} fullPage={true} transparent={false}>
        <div className={styles.container}>
          {productsError ? (
            <Container maxWidth={'lg'}>
              <Grid {...defaultGridContainerProps}>
                <Grid {...defaultGridItemProps}>
                  <Alert severity={'error'}>{productsError}</Alert>
                </Grid>
              </Grid>
            </Container>
          ) : (
            <form>
              <Container maxWidth={'lg'}>
                <Spinner loading={cartLoading}>
                  <Grid {...defaultGridContainerProps}>
                    <Grid {...defaultGridItemProps}>
                      <h1 className={classNames(commonStyles.pageTitle, styles.pageTitle)}>
                        <span>{`Cart (${cart.length})`}</span>
                      </h1>
                    </Grid>
                    {selectedProducts.length ? (
                      <>
                        {selectedProducts.map((item, index) => (
                          <Grid {...defaultGridItemProps} key={item.id + index}>
                            <ProductsCartListItem data={item} showButtons={!isCheckoutPage} />
                          </Grid>
                        ))}
                        {isCheckoutPage && (
                          <>
                            <Grid {...defaultGridItemProps}>
                              <Card className={styles.thinCard} contentClassName={styles.cardContent}>
                                <h3 className={styles.title}>{'Subtotal'}</h3>
                                <span className={styles.price}>{getPrice(subTotal)}</span>
                              </Card>
                            </Grid>
                            {discount > 0 && (
                              <Grid {...defaultGridItemProps}>
                                <Card className={styles.thinCard} contentClassName={styles.cardContent}>
                                  <h3 className={styles.title}>{'Discount'}</h3>
                                  <span className={styles.discount}>{`-${getPrice(discount)}`}</span>
                                </Card>
                              </Grid>
                            )}
                            <Grid {...defaultGridItemProps}>
                              <Card className={styles.thinCard} contentClassName={styles.cardContent}>
                                <h3 className={styles.title}>{'Shipping & Handling'}</h3>
                                <span className={styles.price}>{getPrice(cartPaymentData?.shippingCoast || 0)}</span>
                              </Card>
                            </Grid>
                            <Grid {...defaultGridItemProps}>
                              <Card className={styles.thinCard} contentClassName={styles.cardContent}>
                                <h3 className={styles.title}>{'Sales Tax'}</h3>
                                <span className={styles.price}>{getPrice(cartPaymentData?.tax || 0)}</span>
                              </Card>
                            </Grid>
                            <Grid {...defaultGridItemProps}>
                              <Card className={styles.card} contentClassName={styles.cardContent}>
                                <h3 className={styles.title}>{'Total'}</h3>
                                <span className={styles.price}>{getPrice(totalPrice)}</span>
                              </Card>
                            </Grid>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <Grid {...defaultGridItemProps} className={styles.notFoundSection}>
                          <p className={commonStyles.text}>{'No products selected'}</p>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Spinner>
                {!isCheckoutPage && !skipShipping && (
                  <div className={styles.paymentSection}>
                    <Grid {...defaultGridContainerProps}>
                      <Grid {...defaultGridItemProps} lg={8}>
                        <PaymentAddressSection
                          form={shippingAddressForm}
                          onChange={handleShippingAddressFormChange}
                          onlySavedAddresses={true}
                          useShippingAddress={true}
                        />
                      </Grid>
                      <Grid {...defaultGridItemProps} md={4}>
                        <ShippingMethodSection
                          onChange={setShippingMethodId}
                          value={shippingMethodId}
                          selectedCountry={shippingAddressForm.billingAddress?.country}
                        />
                      </Grid>
                    </Grid>
                  </div>
                )}
                {isCheckoutPage && (
                  <div className={styles.paymentSection}>
                    <Grid {...defaultGridContainerProps}>
                      <Grid {...defaultGridItemProps} lg={8}>
                        <PaymentAddressSection
                          form={paymentForm}
                          onChange={handlePaymentFormChange}
                          onlySavedAddresses={true}
                        />
                      </Grid>
                      <Grid {...defaultGridItemProps} lg={4}>
                        <PromoCodeSection setPromoCodeId={handlePromoCode} />
                        <Box mt={2}>
                          <h3 className={commonStyles.subTitle}>{'Payment Information'}</h3>
                        </Box>
                        <PaymentMethodSection
                          form={paymentForm}
                          onPaymentComplete={handlePaymentComplete}
                          onChange={handlePaymentFormChange}
                          paymentConfig={productsPaymentsConfig}
                        />
                      </Grid>
                    </Grid>
                  </div>
                )}
              </Container>
              <FormFooter
                onSubmit={isCheckoutPage ? handleSubmit : handleCheckout}
                submitButtonDisabled={submitButtonDisabled}
                submitButtonName={isCheckoutPage ? 'Complete Order' : 'Checkout'}
                maxWidth={'lg'}
              >
                {isCheckoutPage && (
                  <Button
                    color={'secondary'}
                    variant={'outlined'}
                    onClick={handleCheckoutPageBack}
                    className={styles.backButton}
                  >
                    {'Back'}
                  </Button>
                )}
                {!isCheckoutPage && returnButtonLabel && returnButtonLink && (
                  <Button color={'primary'} variant={'contained'} href={returnButtonLink} className={styles.backButton}>
                    {returnButtonLabel}
                  </Button>
                )}
              </FormFooter>
            </form>
          )}
        </div>
      </Spinner>
      {!!payment && (
        <PaymentDetailsModal
          open={!!payment}
          title={'Sales Order'}
          showDescription={false}
          isOrderPaymentConfirmation={false}
          payment={payment}
          actions={
            returnButtonLabel &&
            returnButtonLink && (
              <Button color={'primary'} variant={'contained'} href={returnButtonLink}>
                {returnButtonLabel}
              </Button>
            )
          }
        />
      )}
    </>
  );
};
export default ProductCartPageView;
