import { Box, Button, Container, Grid } from '@mui/material';
import classNames from 'classnames';
import { EventCartContext } from 'components/EventCartGuard';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import { defaultPaymentFormValues, getPrice, paymentFormInvalid, shouldValidatePaymentMethod } from 'util/Payment';
import FormFooter from 'components/shared/FormFooter';
import Spinner from 'components/shared/Spinner';
import EventsCartListItem from './EventsCartListItem';
import { PaymentFormValues } from 'store/types/FormValues';
import PaymentAddressSection from 'components/payments/PaymentFormSection/PaymentAddressSection';
import PaymentMethodSection from 'components/payments/PaymentMethodSection';
import CollectJSResponse from 'store/types/CollectJSResponse';
import PaymentService, { EventsPaymentData } from 'services/api/PaymentService';
import Payment from 'store/types/Payment';
import { useSnackbar } from 'notistack';
import { PaymentMethodsContext } from 'components/PaymentMethodsContextWrapper';
import Card from 'components/shared/Card';
import { getEventsPaymentData, getSelectedEventRegistrationIds, getSelectedEvents, getTotalPrice } from 'util/Event';
import routes from 'store/configs/Routes';
import PaymentDetailsModal from 'components/payments/PaymentDetailsModal';
import { EventsCartPaymentData } from 'store/types/events/EventsCartItem';
import { getHashRouteUrl } from 'util/Route';
import EventHotelPreferencesModal from 'components/events/EventHotelPreferencesModal';
import { EventHotelPreferences, EventHotelPreferencesFormValues } from 'store/types/events/EventHotelPreferences';
import EventRegistrationService from 'services/api/EventRegistrationService';
import useRequest from 'hooks/useRequest';
import EventService from 'services/api/EventService';
import PromoCodeSection from 'components/payments/PromoCodeSection';
import { MainEvent, MainEventView } from 'store/types/events/Event';
import { SiteConfigView } from 'store/types/SiteConfig';
import { ConfigContext } from 'components/ConfigGuard';
import { UserContext } from 'components/UserGuard';
import { ParametersContext } from 'components/ParametersGuard';

import commonStyles from 'styles/common.module.scss';
import styles from './EventsCartPageView.module.scss';
import { getEventsRegistrationData } from 'util/EventHotelPreferences';

const EventsCartPageView: React.FunctionComponent = () => {
  const {
    modulesSettings: {
      payments: { events },
    },
  }: SiteConfigView = useContext(ConfigContext);
  const {
    events: { paymentConfirmationModalHeader },
  } = useContext(ParametersContext);
  const { enqueueSnackbar } = useSnackbar();
  const { cart = [], cartLoading, refetchCart } = useContext(EventCartContext);
  const { refetch: refetchPaymentMethods } = useContext(PaymentMethodsContext);
  const { priceLevelId } = useContext(UserContext);
  const getEventDetailsRequest = useMemo(
    () =>
      cart.length
        ? () => EventService.getEventDetails(Array.from(new Set(cart.map(({ eventId }) => eventId))))
        : undefined,
    [cart]
  );
  const { data: eventsDetails, loading: eventsDetailsLoading } = useRequest<MainEvent[]>(getEventDetailsRequest);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [cartPaymentData, setCartPaymentData] = useState<EventsCartPaymentData>();
  const [isCheckoutPage, setIsCheckoutPage] = useState<boolean>(false);
  const [promoCodeId, setPromoCodeId] = useState<string>('');
  const [payment, setPayment] = useState<Payment>();
  const [eventsRegistrationData, setEventsRegistrationData] = useState<EventHotelPreferencesFormValues[]>([]);
  const [eventRegistrationModalOpen, setEventRegistrationModalOpen] = useState<boolean>(false);
  const [paymentForm, setPaymentForm] = useState<PaymentFormValues>({
    ...defaultPaymentFormValues,
    useHomeAddress: true,
  });
  const selectedEvents: MainEventView[] = useMemo(
    () => getSelectedEvents(cart, eventsDetails, priceLevelId),
    [cart, eventsDetails, priceLevelId]
  );
  const subTotal: number = useMemo(() => getTotalPrice(selectedEvents), [selectedEvents]);
  const totalPrice: number = useMemo(
    () => (isCheckoutPage ? cartPaymentData?.totalAmount || subTotal : subTotal),
    [cartPaymentData, isCheckoutPage, subTotal]
  );
  const discount: number = useMemo(
    () =>
      cartPaymentData
        ? subTotal + cartPaymentData.shippingCoast + cartPaymentData.tax - cartPaymentData.totalAmount
        : 0,
    [cartPaymentData, subTotal]
  );
  const submitButtonDisabled: boolean = useMemo(
    () => isCheckoutPage && paymentFormInvalid(paymentForm),
    [isCheckoutPage, paymentForm]
  );

  const eventsHotelPreferencesRequest = useMemo(
    () =>
      !selectedEvents.length ||
      (selectedEvents.every(({ guestId }) => !!guestId) &&
        selectedEvents.every(
          ({ isPreferencesCollectedForGuest, isHotelInformationCollectedForGuest }) =>
            !isPreferencesCollectedForGuest && !isHotelInformationCollectedForGuest
        ))
        ? undefined
        : () => EventRegistrationService.getEventsHotelPreferences(getSelectedEventRegistrationIds(selectedEvents)),
    [selectedEvents]
  );
  const { data, loading } = useRequest<EventHotelPreferences[]>(eventsHotelPreferencesRequest);

  useEffect(() => {
    setPaymentForm((prevState) => ({
      ...prevState,
      totalPrice,
    }));
  }, [totalPrice]);

  const handleCheckout = useCallback(
    (newPromoCodeId?) => {
      setSubmitLoading(true);
      PaymentService.checkoutEventsCart(newPromoCodeId)
        .then((cartPaymentData: EventsCartPaymentData) => {
          refetchPaymentMethods();
          setSubmitLoading(false);
          setIsCheckoutPage(true);
          setPromoCodeId(newPromoCodeId);
          setCartPaymentData(cartPaymentData);
        })
        .catch((errorMessage: string) => {
          setSubmitLoading(false);
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        });
    },
    [enqueueSnackbar, refetchPaymentMethods]
  );

  const makePayment = useCallback(
    (collectJSResponse?: CollectJSResponse) => {
      setSubmitLoading(true);

      const paymentData: EventsPaymentData = {
        ...getEventsPaymentData(
          paymentForm,
          selectedEvents,
          getEventsRegistrationData(eventsRegistrationData),
          totalPrice,
          collectJSResponse
        ),
        promoCodeId,
      };

      PaymentService.makeEventsCartPayment(paymentData)
        .then((newPayment: Payment) => {
          refetchPaymentMethods();
          setSubmitLoading(false);
          setPayment(newPayment);
          refetchCart();
          enqueueSnackbar('Events successfully paid', { variant: 'success' });
        })
        .catch((errorMessage: string) => {
          setSubmitLoading(false);
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        });
    },
    [
      enqueueSnackbar,
      eventsRegistrationData,
      paymentForm,
      promoCodeId,
      refetchCart,
      refetchPaymentMethods,
      selectedEvents,
      totalPrice,
    ]
  );

  const handleSubmit = useCallback(() => {
    setSubmitLoading(true);
    if (shouldValidatePaymentMethod(paymentForm)) {
      (window as any).CollectJS.startPaymentRequest();
    } else {
      makePayment();
    }
  }, [makePayment, paymentForm]);

  const handlePaymentFormChange = useCallback((values: Partial<PaymentFormValues>) => {
    setPaymentForm((prevState) => ({ ...prevState, ...values }));
  }, []);

  const handlePaymentComplete = (response: CollectJSResponse) => {
    makePayment(response);
  };

  const handleCheckoutPageBack = useCallback(() => {
    setIsCheckoutPage(false);
    setPromoCodeId('');
  }, []);

  const handleEventDetailsModalClose = useCallback(() => {
    setEventRegistrationModalOpen(false);
  }, []);

  const handleEventDetailsModalOpen = useCallback(() => {
    setEventRegistrationModalOpen(true);
  }, []);

  const handleEventHotelPreferencesSubmit = useCallback(
    (hotelPreferences: EventHotelPreferencesFormValues[] = []) => {
      window.scrollTo(0, 0);
      setEventsRegistrationData(hotelPreferences);

      handleCheckout();
    },
    [handleCheckout]
  );

  return (
    <>
      <Spinner loading={submitLoading || loading || eventsDetailsLoading} fullPage={true}>
        <div className={styles.container}>
          <form>
            <Container maxWidth={'lg'}>
              <Spinner loading={cartLoading}>
                <Grid {...defaultGridContainerProps} justifyContent={'flex-end'}>
                  <Grid {...defaultGridItemProps}>
                    <h1 className={classNames(commonStyles.pageTitle, styles.pageTitle)}>
                      <span>{`Cart (${cart.length})`}</span>
                      <span>{`Total: ${getPrice(totalPrice)}`}</span>
                    </h1>
                  </Grid>
                  {selectedEvents.length ? (
                    <>
                      {selectedEvents.map((item, index) => (
                        <Grid {...defaultGridItemProps} key={item.id + index}>
                          <EventsCartListItem data={item} showButtons={!isCheckoutPage} />
                        </Grid>
                      ))}
                      {isCheckoutPage && (
                        <>
                          {!!discount && (
                            <Grid {...defaultGridItemProps}>
                              <Card className={styles.card} contentClassName={styles.cardContent}>
                                <h3 className={styles.title}>{'Discount'}</h3>
                                <span className={styles.discount}>{`-${getPrice(discount)}`}</span>
                              </Card>
                            </Grid>
                          )}
                          <Grid {...defaultGridItemProps}>
                            <Card className={styles.card} contentClassName={styles.cardContent}>
                              <h3 className={styles.title}>{'Shipping & Handling'}</h3>
                              <span className={styles.price}>{getPrice(cartPaymentData?.shippingCoast || 0)}</span>
                            </Card>
                          </Grid>
                          <Grid {...defaultGridItemProps}>
                            <Card className={styles.card} contentClassName={styles.cardContent}>
                              <h3 className={styles.title}>{'Sales Tax'}</h3>
                              <span className={styles.price}>{getPrice(cartPaymentData?.tax || 0)}</span>
                            </Card>
                          </Grid>
                          <Grid {...defaultGridItemProps} xs={'auto'}>
                            <h1 className={classNames(commonStyles.pageTitle, styles.pageTitle)}>
                              <span>{`Total: ${getPrice(totalPrice)}`}</span>
                            </h1>
                          </Grid>
                        </>
                      )}
                    </>
                  ) : (
                    <Grid {...defaultGridItemProps} className={styles.notFoundSection}>
                      <p className={commonStyles.text}>{'No events selected'}</p>
                    </Grid>
                  )}
                </Grid>
              </Spinner>
              {isCheckoutPage && (
                <div className={styles.paymentSection}>
                  <Grid {...defaultGridContainerProps}>
                    <Grid {...defaultGridItemProps} lg={8}>
                      <PaymentAddressSection
                        form={paymentForm}
                        onChange={handlePaymentFormChange}
                        onlySavedAddresses={true}
                      />
                    </Grid>
                    {paymentForm.billingAddress && (
                      <Grid {...defaultGridItemProps} lg={4}>
                        <PromoCodeSection setPromoCodeId={handleCheckout} />
                        <Box mt={2}>
                          <h3 className={commonStyles.subTitle}>{'Payment Information'}</h3>
                        </Box>
                        <PaymentMethodSection
                          form={paymentForm}
                          onPaymentComplete={handlePaymentComplete}
                          onChange={handlePaymentFormChange}
                          paymentConfig={events}
                        />
                      </Grid>
                    )}
                  </Grid>
                </div>
              )}
            </Container>
            <FormFooter
              onSubmit={isCheckoutPage ? handleSubmit : data?.length ? handleEventDetailsModalOpen : handleCheckout}
              submitButtonDisabled={submitButtonDisabled}
              submitButtonName={isCheckoutPage ? 'Complete Order' : 'Checkout'}
              className={classNames({ [commonStyles.hidden]: !selectedEvents.length })}
              maxWidth={'lg'}
            >
              {isCheckoutPage && (
                <Button
                  color={'secondary'}
                  variant={'outlined'}
                  onClick={handleCheckoutPageBack}
                  className={styles.backButton}
                >
                  {'Back'}
                </Button>
              )}
            </FormFooter>
          </form>
        </div>
      </Spinner>
      {!!payment && (
        <PaymentDetailsModal
          open={!!payment}
          title={'Payment Confirmation'}
          description={<div dangerouslySetInnerHTML={{ __html: paymentConfirmationModalHeader }} />}
          showDescription={!!paymentConfirmationModalHeader}
          payment={payment}
          actions={
            <Button color={'secondary'} variant={'contained'} href={getHashRouteUrl(routes.myEvents)}>
              {'Go to My Events'}
            </Button>
          }
        />
      )}
      {eventRegistrationModalOpen && data?.length && (
        <EventHotelPreferencesModal
          onClose={handleEventDetailsModalClose}
          open={eventRegistrationModalOpen}
          data={data}
          storedData={eventsRegistrationData}
          onSubmit={handleEventHotelPreferencesSubmit}
          selectedEvents={selectedEvents}
        />
      )}
    </>
  );
};
export default EventsCartPageView;
