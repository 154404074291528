import { FilterConfig, SortConfig } from 'store/types/Table';
import { getListByPage, sorter } from 'util/Table';
import { SupportCaseView } from 'store/types/SupportCase';

export enum SupportCasesTableActionType {
  SetInitialList = 'SetInitialList',
  UpdateSort = 'UpdateSort',
  UpdatePage = 'UpdatePage',
  UpdateFilter = 'UpdateFilter',
  CloseReviewModal = 'CloseReviewModal',
  OpenReviewModal = 'OpenReviewModal',
}

export interface SupportCasesTableState {
  initialList: SupportCaseView[];
  list: SupportCaseView[];
  pageList: SupportCaseView[];
  sort: SortConfig<SupportCaseView>;
  page: number;
  selectedItem?: SupportCaseView;
  filter: FilterConfig<SupportCaseView>;
}

export interface SupportCasesTableAction {
  type: SupportCasesTableActionType;
  payload: Partial<SupportCasesTableState>;
}

export const ITEMS_PER_PAGE = 10;

const getSortedList = (list: SupportCaseView[] = [], sort: SortConfig<SupportCaseView>): SupportCaseView[] =>
  list.length ? [...list].sort(sorter<SupportCaseView>(sort)) : [...list];

const getFilteredList = (list: SupportCaseView[] = [], filter: FilterConfig<SupportCaseView>) => {
  let result = [...list];
  const searchValue = (filter.search || '').toLowerCase();

  if (searchValue) {
    result = result.filter(({ subject }: SupportCaseView) => subject.toLowerCase().includes(searchValue));
  }
  return result;
};

export const initialState: SupportCasesTableState = {
  initialList: [],
  list: [],
  pageList: [],
  page: 1,
  sort: { direction: 'asc', column: 'priority' },
  filter: { search: '' },
};

const reducer = (state: SupportCasesTableState, { type, payload }: SupportCasesTableAction): SupportCasesTableState => {
  if (type === SupportCasesTableActionType.SetInitialList) {
    const { initialList = [] } = payload;
    const list = getSortedList(initialList, state.sort);
    const pageList = getListByPage(list, ITEMS_PER_PAGE, 1);

    return { ...state, initialList, list, pageList };
  }

  if (type === SupportCasesTableActionType.UpdateFilter) {
    const { filter = {} } = payload;
    const newFilter = { ...state.filter, ...filter };
    const list = getFilteredList(state.initialList, newFilter);
    const pageList = getListByPage(list, ITEMS_PER_PAGE, 1);

    return {
      ...state,
      filter: newFilter,
      list: getFilteredList(getSortedList(state.initialList, state.sort), newFilter),
      pageList,
    };
  }

  if (type === SupportCasesTableActionType.UpdatePage) {
    const { page = 1 } = payload;

    return {
      ...state,
      page,
      pageList: getListByPage(state.list, ITEMS_PER_PAGE, page),
    };
  }

  if (type === SupportCasesTableActionType.UpdateSort) {
    const { sort = {} } = payload;

    return {
      ...state,
      sort,
      list: getSortedList(state.initialList, sort),
    };
  }

  if (type === SupportCasesTableActionType.OpenReviewModal) {
    const { selectedItem } = payload;

    return {
      ...state,
      selectedItem,
    };
  }

  if (type === SupportCasesTableActionType.CloseReviewModal) {
    return {
      ...state,
      selectedItem: undefined,
    };
  }

  return state;
};

export default reducer;
