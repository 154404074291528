import { FilterConfig, SortConfig } from 'store/types/Table';
import { sorter } from 'util/Table';
import ApplicationReview from 'store/types/ApplicationReview';

export enum ApplicationReviewsTableActionType {
  SetInitialList = 'SetInitialList',
  UpdateSort = 'UpdateSort',
  UpdateFilter = 'UpdateFilter',
}

export interface ApplicationReviewsTableState {
  initialList: ApplicationReview[];
  list: ApplicationReview[];
  sort: SortConfig<ApplicationReview>;
  filter: FilterConfig<ApplicationReview>;
}

export interface ApplicationReviewsTableAction {
  type: ApplicationReviewsTableActionType;
  payload: Partial<ApplicationReviewsTableState>;
}

const getSortedList = (list: ApplicationReview[] = [], sort: SortConfig<ApplicationReview>): ApplicationReview[] =>
  list.length ? [...list].sort(sorter<ApplicationReview>(sort)) : [...list];

const getFilteredList = (list: ApplicationReview[] = [], filter: FilterConfig<ApplicationReview>) => {
  let result = [...list];
  const searchValue = (filter.search || '').toLowerCase();

  if (searchValue) {
    result = result.filter(
      ({ name, id }: ApplicationReview) =>
        name.toLowerCase().includes(searchValue) || id.toLowerCase().includes(searchValue)
    );
  }
  return result;
};

export const initialState: ApplicationReviewsTableState = {
  initialList: [],
  list: [],
  sort: { direction: 'desc', column: 'submittedDate' },
  filter: { search: '' },
};

const reducer = (
  state: ApplicationReviewsTableState,
  { type, payload }: ApplicationReviewsTableAction
): ApplicationReviewsTableState => {
  if (type === ApplicationReviewsTableActionType.SetInitialList) {
    const { initialList = [] } = payload;
    const list = getSortedList(initialList, state.sort);

    return { ...state, initialList, list };
  }

  if (type === ApplicationReviewsTableActionType.UpdateSort) {
    const { sort = {} } = payload;

    return {
      ...state,
      sort,
      list: getSortedList(state.initialList, sort),
    };
  }

  if (type === ApplicationReviewsTableActionType.UpdateFilter) {
    const { filter = {} } = payload;
    const newFilter = { ...state.filter, ...filter };

    return {
      ...state,
      filter: newFilter,
      list: getFilteredList(getSortedList(state.initialList, state.sort), newFilter),
    };
  }

  return state;
};

export default reducer;
