import { Alert, Button } from '@mui/material';
import Modal from 'components/shared/Modal';
import useRequest from 'hooks/useRequest';
import React, { useCallback, useMemo } from 'react';
import MemberDirectoryService from 'services/api/MemberDirectoryService';
import { PublicUser } from 'store/types/User';
import { ModalProps } from 'store/types/ComponentProps';
import { MemberDirectoryCustomer } from 'store/types/MemberDirectory';
import ProfileForm from 'components/profile/ProfileForm';

import commonStyles from 'styles/common.module.scss';

interface IndividualDetailsModalProps extends ModalProps {
  member?: MemberDirectoryCustomer;
}

const IndividualDetailsModal: React.FunctionComponent<IndividualDetailsModalProps> = ({ member, open, onClose }) => {
  const individualDetails = useMemo(
    () => (member?.id ? () => MemberDirectoryService.getIndividualDetails(member.id) : undefined),
    [member?.id]
  );
  const { data, loading, error } = useRequest<PublicUser>(individualDetails);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      maxWidth={'lg'}
      title={'Member Details'}
      loading={loading}
      actions={
        <>
          <Button color={'secondary'} variant={'outlined'} onClick={handleClose} disabled={loading}>
            {'Close'}
          </Button>
        </>
      }
    >
      {error ? (
        <Alert severity={'error'} className={commonStyles.alert}>
          {error}
        </Alert>
      ) : (
        data && <ProfileForm data={data} />
      )}
    </Modal>
  );
};
export default IndividualDetailsModal;
