import { createTheme, ThemeOptions } from '@mui/material/styles';
import React from 'react';
import { ButtonProps, CircularProgress, GridProps, IconButton, InputAdornment, InputBaseProps } from '@mui/material';
import SelectOption from 'store/types/SelectOption';
import { SidebarSiteConfigItem, SiteThemes } from 'store/types/SiteConfig';
import SiteModule from 'store/enums/SiteModule';
import { OptionsObject, useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';

import commonStyles from 'styles/common.module.scss';

export const defaultGridItemProps: GridProps = { item: true, xs: 12 };
export const defaultGridContainerProps: GridProps = { container: true, spacing: 4 };

export const materialOutlinedIconsClassName = 'material-icons-outlined';

const SnackbarCloseButton = ({ snackbarKey }: any) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton sx={{ color: 'white' }} onClick={() => closeSnackbar(snackbarKey)}>
      <CloseIcon />
    </IconButton>
  );
};

export const defaultSnackbarErrorProps: OptionsObject = {
  variant: 'error',
  persist: true,
  action: SnackbarCloseButton,
};

export const getInputLoadingProps = (loading?: boolean): InputBaseProps => ({
  startAdornment: loading ? (
    <InputAdornment position={'start'}>
      <CircularProgress size={20} color={'inherit'} />
    </InputAdornment>
  ) : null,
});

export const getButtonLoadingProps = (loading?: boolean): ButtonProps =>
  loading
    ? {
        startIcon: <CircularProgress color={'inherit'} size={16} />,
      }
    : {};

export const getSelectPlaceholder = (
  value: string[] = [],
  optionsList: SelectOption[] = [],
  placeholder: string
): React.ReactNode => {
  const foundValue = value ? optionsList.find(({ id }) => value.includes(id)) : undefined;

  return foundValue ? foundValue.name : <span className={commonStyles.placeholder}>{placeholder}</span>;
};

export const appTheme: Partial<ThemeOptions> = {
  typography: {
    fontSize: 16,
    fontFamily: ['Montserrat', '-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
    body1: {
      fontSize: 16,
    },
    button: {
      textTransform: 'none',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 960,
      lg: 1400,
      xl: 1920,
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        variant: 'outlined',
      },
    },
  },
};

export const memberplexTheme = createTheme({
  ...appTheme,
  typography: {
    ...appTheme.typography,
    fontFamily: ['Work Sans', '-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: '#5163F8',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#F6A148',
      contrastText: '#ffffff',
    },
    text: {
      primary: '#231F20',
    },
  },
});

export const nccerTheme = createTheme({
  ...appTheme,
  typography: {
    ...appTheme.typography,
    fontFamily: ['Noto Sans', '-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: '#172535',
    },
    secondary: {
      main: '#FD6A3C',
      contrastText: '#ffffff',
    },
    text: {
      primary: '#717273',
    },
  },
});

export const gamsdTheme = createTheme({
  ...appTheme,
  typography: {
    ...appTheme.typography,
    fontFamily: [
      'Montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      'sans-serif',
      'Roboto Slab',
      '-apple-system',
      'BlinkMacSystemFont',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#20395D',
    },
    secondary: {
      main: '#F1C81A',
      contrastText: '#ffffff',
    },
    text: {
      primary: '#000000',
    },
  },
});

export const compassTheme = createTheme({
  ...appTheme,
  typography: {
    ...appTheme.typography,
    fontFamily: ['Open Sans', '-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: '#002248',
    },
    secondary: {
      main: '#f9423e',
      contrastText: '#ffffff',
    },
    text: {
      primary: '#59788A',
    },
  },
});

export const snaTheme = createTheme({
  ...appTheme,
  typography: {
    ...appTheme.typography,
    fontFamily: ['Red Hat Display', '-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: '#5C0A55',
    },
    secondary: {
      main: '#D71E30',
      contrastText: '#ffffff',
    },
    text: {
      primary: '#453E51',
    },
  },
});

export const smacnaTheme = createTheme({
  ...appTheme,
  typography: {
    ...appTheme.typography,
    fontFamily: ['Montserrat', '-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: '#3C5896',
    },
    secondary: {
      main: '#E4002B',
      contrastText: '#ffffff',
    },
    text: {
      primary: '#000000',
    },
  },
});

export const acsTheme = createTheme({
  ...appTheme,
  typography: {
    ...appTheme.typography,
    fontFamily: ['Open Sans', '-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: '#0F2245',
    },
    secondary: {
      main: '#FF1C05',
      contrastText: '#ffffff',
    },
    text: {
      primary: '#000000',
    },
  },
});

export const teamsEliteTheme = createTheme({
  ...appTheme,
  typography: {
    ...appTheme.typography,
    fontFamily: ['Poppins', '-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: '#13647F',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#00AEEF',
      contrastText: '#ffffff',
    },
    text: {
      primary: '#000000',
    },
  },
});

interface FaviconLinkConfigItem {
  href: string;
  rel?: string;
  type?: string;
  sizes?: string;
}

const defaultLinksConfig: Array<FaviconLinkConfigItem> = [
  {
    rel: 'shortcut icon',
    type: 'image/png',
    href: '/images/default/favicons/favicon.ico',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '57x57',
    href: '/images/default/favicons/apple-icon-57x57.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '60x60',
    href: '/images/default/favicons/apple-icon-60x60.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '72x72',
    href: '/images/default/favicons/apple-icon-72x72.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '76x76',
    href: '/images/default/favicons/apple-icon-76x76.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '114x114',
    href: '/images/default/favicons/apple-icon-114x114.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '120x120',
    href: '/images/default/favicons/apple-icon-120x120.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '144x144',
    href: '/images/default/favicons/apple-icon-144x144.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '152x152',
    href: '/images/default/favicons/apple-icon-152x152.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '180x180',
    href: '/images/default/favicons/apple-icon-180x180.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '192x192',
    href: '/images/default/favicons/android-icon-192x192.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '32x32',
    href: '/images/default/favicons/favicon-32x32.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '96x96',
    href: '/images/default/favicons/favicon-96x96.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '16x16',
    href: '/images/default/favicons/favicon-16x16.png',
  },
];

const addAppFavicons = ({
  isNccerTheme,
  isGamsdTheme,
  isCompassTheme,
  isSnaTheme,
  isSmacnaTheme,
  isAcsTheme,
  isTeamsEliteTheme,
}: SiteThemes): void => {
  defaultLinksConfig.forEach(({ href = '', type = '', sizes = '', rel = '' }) => {
    const newLink = document.createElement('link');
    const customerTheme = isNccerTheme
      ? 'nccer'
      : isGamsdTheme
      ? 'gamsd'
      : isCompassTheme
      ? 'compass'
      : isSnaTheme
      ? 'sna'
      : isSmacnaTheme
      ? 'smacna'
      : isAcsTheme
      ? 'acs'
      : isTeamsEliteTheme && 'teamsElite';

    newLink.href = customerTheme ? href.replace('default', customerTheme) : href;
    newLink.rel = rel;

    if (sizes) {
      newLink.setAttribute('sizes', sizes);
    }
    if (type) {
      newLink.setAttribute('type', type);
    }
    document.head.appendChild(newLink);
  });
};

export const addDocumentInfo = ({
  isNccerTheme,
  isMemberplexTheme,
  isGamsdTheme,
  isCompassTheme,
  isSnaTheme,
  isSmacnaTheme,
  isAcsTheme,
  isTeamsEliteTheme,
}: SiteThemes): void => {
  document.body.classList.add(
    isNccerTheme
      ? 'theme-nccer'
      : isGamsdTheme
      ? 'theme-gamsd'
      : isMemberplexTheme
      ? 'theme-memberplex'
      : isCompassTheme
      ? 'theme-compass'
      : isSnaTheme
      ? 'theme-sna'
      : isSmacnaTheme
      ? 'theme-smacna'
      : isAcsTheme
      ? 'theme-acs'
      : isTeamsEliteTheme
      ? 'theme-teams-elite'
      : 'theme-memberplex'
  );
  addAppFavicons({
    isNccerTheme,
    isGamsdTheme,
    isCompassTheme,
    isSnaTheme,
    isSmacnaTheme,
    isAcsTheme,
    isTeamsEliteTheme,
  });
};

export const getPageTitle = (
  sidebar: SidebarSiteConfigItem[] = [],
  moduleName: SiteModule,
  subModuleName?: SiteModule,
  includeModuleName = true
): string => {
  const foundSidebarConfig = sidebar.find(({ type }) => type === moduleName);
  if (subModuleName && foundSidebarConfig) {
    const children = foundSidebarConfig.children?.find(({ type }) => type === subModuleName);

    return `${children && children.title} ${includeModuleName ? foundSidebarConfig.title : ''}`;
  }
  return foundSidebarConfig ? foundSidebarConfig.title : '';
};
