import { AxiosRequestConfig } from 'axios';
import { Axios } from 'axios-observable';
import { buildVersion } from 'config';

export const AURA_FE_VERSION_HEADER_NAME = 'X-Aura-FE-Version';
export const AURA_TRACE_ID_HEADER_NAME = 'X-Aura-Trace-Id';

export default class HttpServiceTracingInterceptorService {
  private static getHexId(length: number): string {
    const bytes = new Uint8Array(length);
    crypto.getRandomValues(bytes);
    let hex = '';
    for (let i = 0; i < bytes.length; i++) {
      hex += bytes[i].toString(16);
    }
    return hex.substring(0, length);
  }

  public static init(): void {
    Axios.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        config.headers[AURA_FE_VERSION_HEADER_NAME] = buildVersion;
        config.headers[AURA_TRACE_ID_HEADER_NAME] = `${this.getHexId(3)}-${this.getHexId(3)}-${this.getHexId(3)}`;
        return config;
      },
      (error: any) => {
        return Promise.reject(error);
      }
    );
  }
}
