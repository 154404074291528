import moment, { Moment } from 'moment-timezone';
import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { Card, Button, CardContent, Divider, FormHelperText, Grid, GridProps, TextField } from '@mui/material';
import classNames from 'classnames';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import ComponentProps from 'store/types/ComponentProps';
import ShowMoreText from 'components/shared/ShowMoreText';
import { SubscriptionPlanView } from 'store/types/Subscription';
import { defaultGridItemProps } from 'util/Layout';
import { defaultFormProps, getRequiredValidationRule, getValidationProps, preventDecimalCharsInput } from 'util/Form';
import { getPrice } from 'util/Payment';
import DateFormItem from 'components/shared/DateFormItem';

import styles from './SubscriptionPlanCard.module.scss';

interface SubscriptionPlanCardProps extends ComponentProps {
  data: SubscriptionPlanView;
  onUsersAmountChange?: (newQuantity: number, planId: string) => void;
  onSelect?: (plan: SubscriptionPlanView) => void;
  selected?: boolean;
  customSelectedLabel?: string;
}

interface EditSubscriptionPlanFormValues {
  usersAmount: number;
  startDate: any;
}

const gridContainerProps: GridProps = { container: true, spacing: 2 };
const minDate: Moment = moment().startOf('day');
const maxDate: Moment = minDate.clone().add(1, 'year');
const invalidAmountMessage = 'Please input valid amount';
const USERS_AMOUNT_REGEXP = /^[0-9]+$/;

const isSameDay = (date1: Moment, date2?: Moment): boolean =>
  !!date2 && date1.clone().startOf('day').unix() === date2.clone().startOf('day').unix();

const SubscriptionPlanCard: React.FunctionComponent<SubscriptionPlanCardProps> = ({
  data,
  onSelect,
  onUsersAmountChange,
  selected,
  customSelectedLabel,
  className = '',
}) => {
  const form = useForm<EditSubscriptionPlanFormValues>({
    ...defaultFormProps,
    defaultValues: { usersAmount: 1, startDate: minDate },
  });
  const {
    name,
    description,
    price,
    usersAmount = 1,
    id,
    term,
    customStartDate = false,
    startDate,
  } = useMemo(() => data, [data]);
  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = form;
  const [usersAmountValue, startDateValue] = useWatch({ control, name: ['usersAmount', 'startDate'] });

  const handleUsersAmountChange = useCallback(
    (onChange) => (e: ChangeEvent<HTMLInputElement>) => {
      const formattedValue = parseInt(e.target.value.replace(/\D/g, ''), 10);

      if (onUsersAmountChange) {
        onUsersAmountChange(formattedValue, id);
      }
      onChange(formattedValue || '');
    },
    [onUsersAmountChange, id]
  );

  const handleSelect = useCallback(
    (formValues: EditSubscriptionPlanFormValues) => {
      if (onSelect) {
        onSelect({
          ...data,
          usersAmount: formValues.usersAmount,
          startDate: customStartDate ? formValues.startDate : undefined,
        });
      }
    },
    [onSelect, data, customStartDate]
  );

  return (
    <FormProvider {...form}>
      <Card className={classNames(styles.card, { [styles.selected]: selected }, className)}>
        {selected && <span className={styles.selectedLabel}>{customSelectedLabel || 'Selected'} </span>}
        <CardContent className={styles.content}>
          <Grid
            container={true}
            spacing={0}
            direction={'column'}
            justifyContent={'space-between'}
            alignItems={'center'}
            className={styles.info}
          >
            <Grid {...defaultGridItemProps} xs={true} className={styles.textSection}>
              <Grid {...gridContainerProps}>
                <Grid {...defaultGridItemProps}>
                  <Grid {...gridContainerProps} justifyContent={'space-between'} alignItems={'baseline'}>
                    <Grid {...defaultGridItemProps} xs={true}>
                      <h4 className={styles.title}>{name}</h4>
                    </Grid>
                    <Grid {...defaultGridItemProps} xs={'auto'}>
                      <span className={styles.price}>
                        <span>{price ? `$${price}` : 'Free'}</span>
                        {!!price && <span className={styles.period}>{'/subscription'}</span>}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid {...defaultGridItemProps}>
                  <Divider className={styles.divider} />
                </Grid>
                <Grid {...defaultGridItemProps} md={true}>
                  <p className={styles.description}>
                    <ShowMoreText>{description}</ShowMoreText>
                  </p>
                </Grid>
                {customStartDate && (
                  <Grid {...defaultGridItemProps} sm={'auto'} className={styles.dateInputWrapper}>
                    <DateFormItem
                      fieldName={'startDate'}
                      label={'Start Date'}
                      required={false}
                      minDate={minDate}
                      maxDate={maxDate}
                      className={styles.input}
                      size={'small'}
                    />
                  </Grid>
                )}
                <Grid {...defaultGridItemProps} xs={6} sm={'auto'} className={styles.amountInputWrapper}>
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        size={'small'}
                        type={'number'}
                        {...getValidationProps('usersAmount', errors)}
                        className={styles.quantityInput}
                        label={'# of Subscriptions'}
                        value={value}
                        onChange={handleUsersAmountChange(onChange)}
                        onKeyPress={preventDecimalCharsInput}
                      />
                    )}
                    name={'usersAmount'}
                    control={control}
                    rules={{
                      required: getRequiredValidationRule('subscriptions quantity'),
                      min: {
                        value: 1,
                        message: invalidAmountMessage,
                      },
                      pattern: {
                        value: USERS_AMOUNT_REGEXP,
                        message: invalidAmountMessage,
                      },
                    }}
                  />
                </Grid>
                {onSelect && (
                  <Grid {...defaultGridItemProps} xs={6} sm={4} md={'auto'}>
                    <Button
                      color={'secondary'}
                      variant={'contained'}
                      onClick={handleSubmit(handleSelect)}
                      fullWidth={true}
                      disabled={
                        !isValid ||
                        (selected &&
                          usersAmount === usersAmountValue &&
                          (!customStartDate || isSameDay(startDateValue, startDate)))
                      }
                    >
                      {`Buy Now (${getPrice(price * usersAmountValue)})`}
                    </Button>
                    {!!term && <FormHelperText className={styles.helperText}>{term}</FormHelperText>}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </FormProvider>
  );
};
export default SubscriptionPlanCard;
