enum SiteModule {
  Addresses = 'addresses',
  Home = 'home',
  Announcements = 'announcements',
  Notice = 'notice',
  Profile = 'profile',
  Committees = 'committees',
  Chapters = 'chapters',
  Membership = 'membership',
  Subscriptions = 'subscriptions',
  CompanyPayments = 'companypayments',
  Company = 'company',
  MyEvents = 'myevents',
  Events = 'events',
  Donations = 'donations',
  DonationsPremium = 'donationspremium',
  Applications = 'applications',
  Payments = 'payments',
  Partner = 'partner',
  PersonalPayments = 'personalpayments',
  Products = 'products',
  GroupPayments = 'grouppayments',
  Settings = 'settings',
  Support = 'support',
  MemberDirectory = 'memberdirectory',
  EngagementReport = 'engagementreport',
  GroupRegistration = 'groupregistration',
  PublicCommittees = 'publiccommittees',
  ExpenseProfile = 'expenseprofile',
  ExpenseReport = 'expensereport',
  StayConnected = 'stayconnected',
}
export default SiteModule;
