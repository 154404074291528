import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, TextField } from '@mui/material';
import FormFooter from 'components/shared/FormFooter';
import Spinner from 'components/shared/Spinner';
import { useSnackbar } from 'notistack';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import ExpenseService from 'services/api/ExpenseService';
import { AchPaymentMethodDetails } from 'store/types/PaymentMethod';
import { ACH_REGEXP, defaultFormProps, getRequiredValidationRule, getValidationProps } from 'util/Form';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';

interface AchFormProps {
  data: AchPaymentMethodDetails;
  submitLoading?: boolean;
}

const defaultValues: AchPaymentMethodDetails = {
  accountName: '',
  routingCode: '',
  accountNumber: '',
};

const AchForm: React.FunctionComponent<AchFormProps> = ({ data }) => {
  const form = useForm<AchPaymentMethodDetails>({ ...defaultFormProps, defaultValues });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = form;

  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const submitButtonDisabled: boolean = useMemo(() => !isValid || !isDirty || loading, [isValid, isDirty, loading]);

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, reset]);

  const handleFormSubmit = useCallback(
    (data: AchPaymentMethodDetails) => {
      setLoading(true);
      ExpenseService.saveAchPayment(data)
        .then(() => {
          reset(data);
          setLoading(false);
          enqueueSnackbar('Payment option successfully saved', { variant: 'success' });
        })
        .catch((error: string) => {
          setLoading(false);
          enqueueSnackbar(error, { variant: 'error' });
        });
    },
    [enqueueSnackbar, reset]
  );

  return (
    <Spinner loading={loading} transparent={true}>
      <FormProvider {...form}>
        <Grid {...defaultGridContainerProps}>
          <Grid {...defaultGridItemProps}>
            <Controller
              name={'routingCode'}
              control={control}
              rules={{
                required: getRequiredValidationRule('Routing Number'),
                pattern: {
                  value: ACH_REGEXP,
                  message: 'Routing number must be 6 or 9 digits and a recognizable format.',
                },
              }}
              render={({ field }) => (
                <TextField
                  label={'Routing Number'}
                  {...field}
                  {...getValidationProps('routingCode', errors)}
                  required={true}
                  placeholder={'XXXXXXXXX'}
                />
              )}
            />
          </Grid>
          <Grid {...defaultGridItemProps}>
            <Controller
              name={'accountNumber'}
              control={control}
              rules={{
                required: getRequiredValidationRule('Account Number'),
              }}
              render={({ field }) => (
                <TextField
                  label={'Account Number'}
                  {...field}
                  {...getValidationProps('accountNumber', errors)}
                  required={true}
                  placeholder={'XXXXXXXXX'}
                />
              )}
            />
          </Grid>
        </Grid>
        <FormFooter
          onSubmit={handleSubmit(handleFormSubmit)}
          submitButtonName={'Save'}
          submitButtonDisabled={submitButtonDisabled}
        />
      </FormProvider>
    </Spinner>
  );
};

export default AchForm;
