import { Tab, Tabs } from '@mui/material';
import { Alert } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import ApplicationsService from 'services/api/ApplicationsService';
import useRequest from 'hooks/useRequest';
import routes, { applicationsRoutes } from 'store/configs/Routes';
import { getActiveTabRoute, getActiveTabValue } from 'util/Route';
import ApplicationReviewsTable from 'components/applications/ApplicationReviewsTable';
import ApplicationReview from 'store/types/ApplicationReview';
import UserApplicationsPageView from 'components/applications/UserApplicationsPageView';
import Spinner from 'components/shared/Spinner';
import NotFoundPage from 'components/layout/NotFoundPage';
import { getFormattedApplicationReviewStatus } from 'util/Application';

import commonStyles from 'styles/common.module.scss';

const ApplicationsPage: React.FunctionComponent = () => {
  const location = useLocation();
  const history = useHistory();
  const [applications, setApplications] = useState<ApplicationReview[]>([]);
  const { data, loading, error } = useRequest<ApplicationReview[]>(ApplicationsService.getApplicationReviews);
  const reviewsEnabled: boolean = useMemo(() => !loading && !!data && !!data.length, [loading, data]);
  const isReviewsPage: boolean = useMemo(() => location.pathname.includes(routes.applicationReviews), [location]);
  const activeTab: string = useMemo(
    () => getActiveTabValue(location.pathname, applicationsRoutes),
    [location.pathname]
  );

  useEffect(() => {
    if (data?.length) {
      setApplications(
        data.map((application) => ({ ...application, status: getFormattedApplicationReviewStatus(application.status) }))
      );
    }
  }, [data]);

  const handleTabChange = useCallback(
    (_: any, value = '1') => {
      history.replace(getActiveTabRoute(value, applicationsRoutes));
    },
    [history]
  );

  const applicationsPageView: React.ReactNode = <UserApplicationsPageView />;

  return (
    <>
      <h1 className={commonStyles.pageTitle}>{isReviewsPage ? 'Reviews' : 'Applications'}</h1>
      <Spinner loading={loading} transparent={false}>
        {reviewsEnabled ? (
          <TabContext value={activeTab}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              indicatorColor={'primary'}
              textColor={'primary'}
              variant={'scrollable'}
            >
              <Tab value={'1'} label={'My Applications'} className={commonStyles.tab} />
              <Tab value={'2'} label={'My Reviews'} className={commonStyles.tab} />
            </Tabs>
            <TabPanel value={'1'} className={commonStyles.tabPanel}>
              {applicationsPageView}
            </TabPanel>
            <TabPanel value={'2'} className={commonStyles.tabPanel}>
              <ApplicationReviewsTable data={applications} />
            </TabPanel>
          </TabContext>
        ) : (
          <>
            {isReviewsPage ? (
              error ? (
                <Alert severity={'error'} className={commonStyles.alert}>
                  {error}
                </Alert>
              ) : (
                <NotFoundPage />
              )
            ) : (
              applicationsPageView
            )}
          </>
        )}
      </Spinner>
    </>
  );
};
export default ApplicationsPage;

// DISCLOSURE SECTION
//
// const handleDownloadLinkClick = () => {
//   setFileLoading(true);
//   ApplicationsService.getDisclosureFile()
//       .then(() => {
//         setFileLoading(false);
//       })
//       .catch((errorMessage: string) => {
//         enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
//         setFileLoading(false);
//       });
// };
// <Grid {...defaultGridItemProps}>
//                 <Card>
//                   <SectionHeader>Disclosure</SectionHeader>
//                   {applicationReviewsRequest.data && (
//                     <p>
//                       {applicationReviewsRequest.data.hasDisclosure
//                         ? `Your annual disclosure has been submitted on `
//                         : `Please complete and upload you annual disclosure by `}
//                       {getFormattedDate(applicationReviewsRequest.data.disclosureSubmitDate, NUMBER_DATE_FORMAT)}
//                       {hasDisclosure
//                         ? `. You can download your submission `
//                         : `. You can download the template `}
//                       <Link onClick={handleDownloadLinkClick} className={commonStyles.link}>
//                         here
//                       </Link>
//                     </p>
//                   )}
//                 </Card>
//               </Grid>
