import { HttpService } from 'services/HttpService';
import { API_URL, conf, tenantConfig } from 'config';
import AuthService from 'services/AuthService';
import { httpRequestWrapper } from 'util/Request';
import SupportCase, { SupportCaseFile, SupportCaseView } from 'store/types/SupportCase';

type SupportCaseViewResponseData = SupportCaseView[];

class SupportService {
  public static getSupportCases(): Promise<SupportCaseViewResponseData> {
    return new Promise<SupportCaseViewResponseData>(
      (resolve: (data: SupportCaseViewResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<SupportCaseViewResponseData>(
          HttpService.get(`${API_URL}/support`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static addSupportCase(data: SupportCase): Promise<SupportCaseView> {
    return new Promise<SupportCaseView>((resolve: (data: SupportCaseView) => void, reject: (error: string) => void) => {
      httpRequestWrapper<SupportCaseView>(
        HttpService.post(`${API_URL}/support`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getSupportCaseFile({ id, name }: SupportCaseFile, supportCaseId: string): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.getFileDownload(`${API_URL}/support/${supportCaseId}/files/${id}`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
            [conf.filenameHeader]: name,
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static uploadFile(supportCaseId: string, files: FormData): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.post(`${API_URL}/support/${supportCaseId}/files`, {
          data: files,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }
}
export default SupportService;
