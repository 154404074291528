import { Button, Divider, Grid, InputAdornment, TextField } from '@mui/material';
import Modal from 'components/shared/Modal';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, FieldErrors, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { defaultFormProps, getValidationProps } from 'util/Form';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import { ModalProps } from 'store/types/ComponentProps';
import CompanyService from 'services/api/CompanyService';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import useRequest from 'hooks/useRequest';
import Alert from '@mui/material/Alert';
import { CompanySegments } from 'store/types/Company';

import commonStyles from 'styles/common.module.scss';
import styles from './EditCompanySegmentsModal.module.scss';

const SEGMENTS_ARRAY_FIELD_NAME = 'segments';

interface EditCompanySegmentsModalProps extends ModalProps {
  companyId: string;
  refetch: () => void;
}

interface EditCompanySegmentsFormValues {
  [SEGMENTS_ARRAY_FIELD_NAME]: CompanySegments[];
}

const EditCompanySegmentsModal: React.FunctionComponent<EditCompanySegmentsModalProps> = ({
  open,
  onClose,
  companyId,
  refetch,
}) => {
  const companySegmentsRequest = useMemo(
    () => (companyId ? () => CompanyService.getCompanySegments(companyId, true) : undefined),
    [companyId]
  );
  const { data, loading, error } = useRequest<CompanySegments[]>(companySegmentsRequest);
  const form = useForm<EditCompanySegmentsFormValues>({ ...defaultFormProps });
  const {
    handleSubmit,
    formState: { isDirty, isValid, errors },
    setValue,
    control,
    reset,
  } = form;
  const { fields } = useFieldArray({
    control,
    name: SEGMENTS_ARRAY_FIELD_NAME,
  });
  const validationErrors = errors[SEGMENTS_ARRAY_FIELD_NAME];
  const { enqueueSnackbar } = useSnackbar();
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const segments = useWatch({ control, name: SEGMENTS_ARRAY_FIELD_NAME });
  const percentagesValue: number = useMemo(
    () => segments?.reduce((prevValue, { value }) => prevValue + (value || 0), 0) || 0,
    [segments]
  );

  useEffect(() => {
    if (data?.length) {
      setValue(SEGMENTS_ARRAY_FIELD_NAME, data);
    }
  }, [data, setValue]);

  const handleClose = useCallback(() => {
    onClose();
    reset({ [SEGMENTS_ARRAY_FIELD_NAME]: data });
  }, [data, onClose, reset]);

  const handleEditSegmentsSubmit = useCallback(
    (formValues: EditCompanySegmentsFormValues) => {
      setSubmitLoading(true);
      const data: CompanySegments[] = formValues[SEGMENTS_ARRAY_FIELD_NAME].filter(({ value }) => !!value);

      CompanyService.updateCompanySegments(data, companyId)
        .then(() => {
          enqueueSnackbar('Market segments successfully updated', { variant: 'success' });
          setSubmitLoading(false);
          handleClose();
          refetch();
        })
        .catch((errorMessage) => {
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
          setSubmitLoading(false);
        });
    },
    [companyId, enqueueSnackbar, handleClose, refetch]
  );

  const validateField = useCallback((value: number) => {
    if (value > 100) {
      return 'The value cannot be greater than 100';
    } else {
      return true;
    }
  }, []);

  const handleChange = useCallback(
    (onChange) => (e: ChangeEvent<HTMLInputElement>) => {
      const value = parseFloat(e.target.value) || '';
      onChange(value);
    },
    []
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      maxWidth={'sm'}
      title={'Edit Market Segments'}
      loading={loading || submitLoading}
      actions={
        <>
          <Button color={'secondary'} variant={'outlined'} onClick={handleClose} className={styles.closeButton}>
            {'Cancel'}
          </Button>
          {!error && (
            <div
              className={classNames(styles.result, {
                [styles.sumError]: isDirty && percentagesValue !== 100,
                [styles.sumSuccess]: isDirty && percentagesValue === 100,
              })}
            >
              <span className={styles.value}>{`${percentagesValue}%/100%`}</span>
              <Button
                color={'secondary'}
                variant={'contained'}
                type={'submit'}
                disabled={!isValid || !isDirty || percentagesValue !== 100}
                onClick={handleSubmit(handleEditSegmentsSubmit)}
              >
                {'Save'}
              </Button>
            </div>
          )}
        </>
      }
    >
      {error ? (
        <Alert severity={'error'} className={commonStyles.alert}>
          {error}
        </Alert>
      ) : (
        <Grid {...defaultGridContainerProps} spacing={3}>
          <span className={styles.helperText}>{'Total must equal 100%'}</span>
          <Grid {...defaultGridItemProps} className={styles.item}>
            <span className={classNames(commonStyles.bold, styles.title)}>{'Industry'}</span>
            <span className={commonStyles.bold}>{'Percentage'}</span>
          </Grid>
          <Divider className={styles.divider} />
          {fields.map(({ id, name }, index) => (
            <React.Fragment key={id}>
              <Grid {...defaultGridItemProps} className={styles.item}>
                <span>{name}</span>
                <div>
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        {...getValidationProps('value', validationErrors?.[index] as FieldErrors)}
                        onChange={handleChange(onChange)}
                        value={value}
                        type={'number'}
                        size={'small'}
                        placeholder={'0'}
                        InputProps={{
                          className: styles.input,
                          inputProps: { min: 0, max: 100 },
                          endAdornment: <InputAdornment position={'end'}>{'%'}</InputAdornment>,
                        }}
                      />
                    )}
                    control={control}
                    name={`${SEGMENTS_ARRAY_FIELD_NAME}.${index}.value`}
                    rules={{ validate: validateField }}
                  />
                </div>
              </Grid>
              <Divider className={styles.divider} />
            </React.Fragment>
          ))}
        </Grid>
      )}
    </Modal>
  );
};

export default EditCompanySegmentsModal;
