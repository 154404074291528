import { sorter, userFullNameSortLabel } from 'util/Table';
import { SortConfig } from 'store/types/Table';
import CompanyUser from 'store/types/CompanyUser';

export enum CompanyAdministratorUsersTableActionType {
  SetInitialList = 'SetInitialList',
  UpdateSort = 'UpdateSort',
  UpdateFilter = 'UpdateFilter',
  OpenRemoveModal = 'OpenRemoveModal',
  CloseRemoveModal = 'CloseRemoveModal',
  OpenViewUserModal = 'OpenViewUserModal',
  CloseViewUserModal = 'CloseViewUserModal',
}

export type TableCompanyAdministratorUsers = CompanyUser & { [userFullNameSortLabel]?: string };

export interface CompanyAdministratorUsersTableState {
  initialList: TableCompanyAdministratorUsers[];
  list: TableCompanyAdministratorUsers[];
  sort: SortConfig<TableCompanyAdministratorUsers>;
  clickedItem?: TableCompanyAdministratorUsers;
  selectedUser?: TableCompanyAdministratorUsers;
}

export interface CompanyAdministratorUsersTableAction {
  type: CompanyAdministratorUsersTableActionType;
  payload: Partial<Omit<CompanyAdministratorUsersTableState, 'initialList'>> & {
    initialList?: CompanyUser[];
    selectedItem?: TableCompanyAdministratorUsers;
  };
}

const getSortedList = (
  list: TableCompanyAdministratorUsers[] = [],
  sort: SortConfig<TableCompanyAdministratorUsers>
): TableCompanyAdministratorUsers[] => {
  return list.length ? [...list].sort(sorter<TableCompanyAdministratorUsers>(sort)) : [...list];
};

export const initialState: CompanyAdministratorUsersTableState = {
  initialList: [],
  list: [],
  sort: { direction: 'asc', column: userFullNameSortLabel },
  clickedItem: undefined,
};

const reducer = (
  state: CompanyAdministratorUsersTableState,
  { type, payload }: CompanyAdministratorUsersTableAction
): CompanyAdministratorUsersTableState => {
  if (type === CompanyAdministratorUsersTableActionType.SetInitialList) {
    const { initialList = [] } = payload;

    return { ...state, initialList, list: getSortedList(initialList, state.sort) };
  }

  if (type === CompanyAdministratorUsersTableActionType.UpdateSort) {
    const { sort = {} } = payload;
    const newList = getSortedList(state.initialList, sort);

    return { ...state, sort, list: newList };
  }

  if (type === CompanyAdministratorUsersTableActionType.OpenRemoveModal) {
    const { clickedItem } = payload;

    return { ...state, clickedItem };
  }

  if (type === CompanyAdministratorUsersTableActionType.CloseRemoveModal) {
    return { ...state, clickedItem: undefined };
  }

  if (type === CompanyAdministratorUsersTableActionType.OpenViewUserModal) {
    const { selectedUser } = payload;

    return { ...state, selectedUser };
  }

  if (type === CompanyAdministratorUsersTableActionType.CloseViewUserModal) {
    return { ...state, selectedUser: undefined };
  }

  return state;
};

export default reducer;
