import React from 'react';
import { StepConnector, Step, StepLabel, Stepper as MuiStepper, StepIconProps } from '@mui/material';
import classNames from 'classnames';
import Check from '@mui/icons-material/Check';
import { useWindowSize } from 'util/Window';
import * as MUIcon from '@mui/icons-material';

import styles from './Stepper.module.scss';

interface StepConfigItem {
  label: string;
  value: number;
  icon?: string;
}

interface StepperProps {
  config: StepConfigItem[];
  activeStep: number;
}

interface StepProps extends Omit<StepIconProps, 'icon'> {
  icon: keyof typeof MUIcon;
}

const StepIcon: React.ElementType = ({ active, completed, icon }: StepProps) => {
  const Icon = icon && MUIcon[icon];

  return (
    <div className={classNames(styles.step, { [styles.disabled]: !completed && !active })} data-testid={'step-item'}>
      {completed ? <Check data-testid={'step-item-completed'} /> : Icon && <Icon className={styles.icon} />}
    </div>
  );
};

const Stepper: React.FunctionComponent<StepperProps> = ({ activeStep, config = [] }) => {
  const { isMobile } = useWindowSize();

  return (
    <MuiStepper
      alternativeLabel={!isMobile}
      activeStep={activeStep}
      connector={
        !isMobile ? (
          <StepConnector
            classes={{ completed: styles.active, root: styles.connector, line: styles.line, active: styles.active }}
          />
        ) : undefined
      }
      orientation={isMobile ? 'vertical' : 'horizontal'}
      data-testid={'stepper'}
    >
      {config.map(({ label, value, icon }) => (
        <Step key={label} completed={value < activeStep} active={value === activeStep}>
          <StepLabel
            StepIconComponent={StepIcon}
            StepIconProps={{ icon }}
            classes={{ root: styles.label, active: styles.activeLabel }}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </MuiStepper>
  );
};
export default Stepper;
