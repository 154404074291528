import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import PaymentService from 'services/api/PaymentService';
import useRequest from 'hooks/useRequest';

import commonStyles from 'styles/common.module.scss';
import { getCountryName } from 'util/Format';
import ShippingOptions from 'store/types/ShippingMethod';

interface ShippingMethodSectionProps {
  onChange: (value: string) => void;
  value: string;
  selectedCountry?: string;
}

const isCountryValid = (selectedCountry: string, countries?: string[], excludeCountries?: boolean): boolean => {
  return countries && countries.length > 0
    ? excludeCountries
      ? !countries.some((country) => country === getCountryName(selectedCountry))
      : countries.some((country) => country === getCountryName(selectedCountry))
    : true;
};

const ShippingMethodSection: React.FunctionComponent<ShippingMethodSectionProps> = ({
  onChange,
  value,
  selectedCountry,
}) => {
  const { data } = useRequest<ShippingOptions>(PaymentService.getShippingMethods);

  const shippingMethods = useMemo(
    () =>
      data && selectedCountry
        ? data.shippingMethods.filter(({ countries, excludeCountries }) =>
            isCountryValid(selectedCountry, countries, excludeCountries)
          )
        : [],
    [data, selectedCountry]
  );

  const defaultShippingMethod = useMemo(
    () =>
      shippingMethods.length > 0 && data && selectedCountry
        ? shippingMethods.find((method) =>
            data.defaultShippings.some(
              ({ shippingMethodId, countries, excludeCountries }) =>
                method.id == shippingMethodId && isCountryValid(selectedCountry, countries, excludeCountries)
            )
          )?.id
        : undefined,
    [data, selectedCountry, shippingMethods]
  );

  useEffect(() => {
    if (defaultShippingMethod) onChange(defaultShippingMethod);
  }, [defaultShippingMethod, onChange]);

  const handleShippingMethodChange = useCallback(
    (onChange) => (e: SelectChangeEvent) => {
      onChange(e.target.value);
    },
    []
  );

  return (
    <>
      <h3 className={commonStyles.subTitle}>{'Select Shipping Method'}</h3>
      <FormControl fullWidth>
        <InputLabel id={`shipping-method-select-label`}>Shipping Method</InputLabel>
        <Select
          labelId={`shipping-method-select-label`}
          value={`${value}`}
          label="Shipping Method"
          onChange={handleShippingMethodChange(onChange)}
          defaultValue={defaultShippingMethod}
        >
          {shippingMethods?.map(({ id, name }, index) => (
            <MenuItem key={`quantity-option-${id}-${index}`} value={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
export default ShippingMethodSection;
