import { Button } from '@mui/material';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { Alert } from '@mui/material';
import EditPaymentMethodModal from 'components/payments/EditPaymentMethodModal';
import Modal from 'components/shared/Modal';
import Table from 'components/shared/Table';
import { useSnackbar } from 'notistack';
import React, { Reducer, SyntheticEvent, useCallback, useContext, useEffect, useReducer, useState } from 'react';
import PaymentMethodService from 'services/api/PaymentMethodService';
import PaymentMethodStatus from 'store/enums/PaymentMethodStatus';
import PaymentMethodType from 'store/enums/PaymentMethodType';
import PaymentMethod, {
  AchPaymentMethodDetails,
  CardPaymentMethodDetails,
  PaymentMethodView,
} from 'store/types/PaymentMethod';
import { SortConfig, TableColumn } from 'store/types/Table';
import { EMPTY_STRING_VALUE } from 'util/Format';
import { PaymentMethodsContext } from 'components/PaymentMethodsContextWrapper';
import { DEFAULT_ERROR_MESSAGE } from 'util/Form';
import PaymentMethodCardType from 'store/enums/PaymentMethodCardType';
import { getPaymentCardTypeConfig, getPaymentMethodStatus, getPaymentMethodStatusLabel } from 'util/Payment';
import reducer, {
  initialState,
  PaymentMethodsTableAction,
  PaymentMethodsTableActionType,
  PaymentMethodsTableState,
} from './PaymentMethodsTableReducer';
import classNames from 'classnames';
import { ConfigContext } from 'components/ConfigGuard';

import commonStyles from 'styles/common.module.scss';
import styles from './PaymentMethodsTable.module.scss';
import { defaultSnackbarErrorProps } from 'util/Layout';

const getFormattedCardExpDate = (cardExpDate?: string): string =>
  cardExpDate
    ? cardExpDate[2] === '/'
      ? cardExpDate
      : cardExpDate.substring(0, 2) + '/' + cardExpDate.substring(2, cardExpDate.length)
    : '';

const getFormattedList = (initialList: PaymentMethod[] = []): PaymentMethodView[] =>
  initialList.map((item) => {
    let formattedItem: PaymentMethodView = {
      ...item,
      status: getPaymentMethodStatus(item),
    };

    if (item.type === PaymentMethodType.Card && formattedItem.details) {
      const cardDetails = formattedItem.details as CardPaymentMethodDetails;
      const cardExpDate = getFormattedCardExpDate(cardDetails.cardExpDate);

      formattedItem = {
        ...formattedItem,
        details: { ...cardDetails, cardExpDate },
      };
    }
    return formattedItem;
  });

const getCardTypeImage = (type: PaymentMethodCardType | string = ''): React.ReactNode => {
  const { icon = '', text } = getPaymentCardTypeConfig(type);

  return icon ? <img className={styles.cardTypeLogo} src={icon} alt={text} /> : text;
};

const getTruncatedMethodNumber = (methodNumber: string): string => methodNumber.slice(-4);

const getDescription = ({ details, type }: PaymentMethodView): React.ReactNode => {
  const cardDetails = details as CardPaymentMethodDetails;
  const achDetails = details as AchPaymentMethodDetails;

  return details ? (
    <span className={styles.methodDescription}>
      {type === PaymentMethodType.Card && (
        <>
          {cardDetails.cardType ? getCardTypeImage(cardDetails.cardType) : 'Credit Card'}
          {cardDetails.cardExpDate && ` ending in ${getTruncatedMethodNumber(cardDetails.cardId)}`}
        </>
      )}
      {type === PaymentMethodType.Ach && (
        <>
          {'Bank Account'}
          {achDetails.accountNumber && ` ending in ${getTruncatedMethodNumber(achDetails.accountNumber)}`}
        </>
      )}
    </span>
  ) : (
    EMPTY_STRING_VALUE
  );
};

const PaymentMethodsTable: React.FunctionComponent = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [deleteMethodLoading, setDeleteMethodLoading] = useState<boolean>(false);
  const { data, loading, error, refetch } = useContext(PaymentMethodsContext);
  const { isNccerTheme } = useContext(ConfigContext);
  const [
    {
      sort,
      list = [],
      editModalOpen = false,
      deleteModalOpen = false,
      submitLoading,
      selectedItem,
      deletePaymentMethodDisabled = false,
    },
    dispatch,
  ] = useReducer<Reducer<PaymentMethodsTableState, PaymentMethodsTableAction>>(reducer, initialState);

  useEffect(() => {
    if (data) {
      dispatch({
        type: PaymentMethodsTableActionType.SetInitialList,
        payload: { initialList: getFormattedList(data) },
      });
    }
  }, [data]);

  const handleSortChange = useCallback((newSort: SortConfig) => {
    dispatch({ type: PaymentMethodsTableActionType.UpdateSort, payload: { sort: newSort } });
  }, []);

  const handleDeleteMethodConfirm = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      if (selectedItem) {
        dispatch({
          type: PaymentMethodsTableActionType.SetSubmitLoading,
          payload: { submitLoading: true },
        });

        PaymentMethodService.deletePaymentMethod(selectedItem.id)
          .then(() => {
            dispatch({
              type: PaymentMethodsTableActionType.CloseConfirmationModal,
              payload: {},
            });
            enqueueSnackbar(`Payment method successfully deleted`, { variant: 'success' });
            refetch();
          })
          .catch((errorMessage: string) => {
            dispatch({
              type: PaymentMethodsTableActionType.SetSubmitLoading,
              payload: { submitLoading: false },
            });
            enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
          });
      } else {
        enqueueSnackbar(DEFAULT_ERROR_MESSAGE, defaultSnackbarErrorProps);
      }
    },
    [selectedItem, enqueueSnackbar, refetch]
  );

  const handleAddButtonClick = useCallback(() => {
    dispatch({
      type: PaymentMethodsTableActionType.OpenEditModal,
      payload: {},
    });
  }, []);

  const handleEditModalSubmit = useCallback(() => {
    dispatch({
      type: PaymentMethodsTableActionType.CloseEditModal,
      payload: {},
    });
    refetch();
  }, [refetch]);

  const handleDeleteButtonClick = useCallback(
    (record?: PaymentMethodView) => () => {
      if (!isNccerTheme) {
        const id = record?.id || selectedItem?.id || '';

        setDeleteMethodLoading(true);
        PaymentMethodService.getPaymentMethodDetails(id)
          .then((data: PaymentMethod) => {
            const { recurringDonationsId }: PaymentMethod = data;

            dispatch({
              type: PaymentMethodsTableActionType.SetDeletePaymentMethodDisabled,
              payload: { deletePaymentMethodDisabled: !!recurringDonationsId?.length, deleteModalOpen: true },
            });
          })
          .catch((error: string) => {
            enqueueSnackbar(error, defaultSnackbarErrorProps);
          })
          .finally(() => {
            setDeleteMethodLoading(false);
          });
      } else {
        dispatch({
          type: PaymentMethodsTableActionType.OpenConfirmationModal,
          payload: { selectedItem: record },
        });
      }
    },
    [enqueueSnackbar, selectedItem, isNccerTheme]
  );

  const handleEditModalClose = useCallback(() => {
    dispatch({
      type: PaymentMethodsTableActionType.CloseEditModal,
      payload: {},
    });
  }, []);

  const handleEditButtonClick = useCallback(
    (record: PaymentMethodView) => () => {
      dispatch({
        type: PaymentMethodsTableActionType.OpenEditModal,
        payload: { selectedItem: record },
      });
    },
    []
  );

  const handleConfirmationModalClose = useCallback(() => {
    dispatch({ type: PaymentMethodsTableActionType.CloseConfirmationModal, payload: {} });
  }, []);

  const columns: Array<TableColumn<PaymentMethodView>> = [
    {
      dataIndex: 'status',
      label: 'Card Type',
      sortable: true,
      render: (status: PaymentMethodStatus) => getPaymentMethodStatusLabel(status),
    },
    {
      dataIndex: 'name',
      label: 'Nickname',
      sortable: true,
    },
    {
      key: 'description',
      label: 'Method',
      render: (_: any, record: PaymentMethodView) => getDescription(record),
    },
    {
      key: 'expiration',
      label: 'Expiration',
      render: (_: any, { type, details }: PaymentMethodView) => {
        const cardDetails = details as CardPaymentMethodDetails;

        return type === PaymentMethodType.Card
          ? cardDetails && cardDetails.cardExpDate
            ? cardDetails.cardExpDate
            : EMPTY_STRING_VALUE
          : 'N/A';
      },
    },
    {
      key: 'action',
      label: 'Action',
      align: 'center',
      render: (_: any, record: PaymentMethodView) =>
        record.status === PaymentMethodStatus.Expired ? (
          <Button
            size={'small'}
            variant={'outlined'}
            className={commonStyles.dangerButtonOutlined}
            onClick={handleDeleteButtonClick(record)}
          >
            <DeleteOutlined className={styles.icon} />
          </Button>
        ) : (
          <Button
            size={'small'}
            color={'primary'}
            variant={'outlined'}
            className={styles.editButton}
            onClick={handleEditButtonClick(record)}
          >
            <EditOutlined className={styles.icon} />
          </Button>
        ),
    },
  ];

  return (
    <>
      <Button color={'secondary'} variant={'contained'} className={styles.addButton} onClick={handleAddButtonClick}>
        Add New Payment Method
      </Button>
      {error ? (
        <Alert severity={'error'} className={commonStyles.alert}>
          {error}
        </Alert>
      ) : (
        <Table
          columns={columns}
          list={list}
          sort={sort}
          onSortChange={handleSortChange}
          loading={loading || deleteMethodLoading}
          submitLoading={submitLoading}
        />
      )}
      <EditPaymentMethodModal
        data={selectedItem}
        open={editModalOpen}
        onSubmit={handleEditModalSubmit}
        onDelete={handleDeleteButtonClick()}
        onClose={handleEditModalClose}
        deleteButtonLoading={deleteMethodLoading}
      />
      <Modal
        title={'Delete This Payment Method?'}
        open={deleteModalOpen}
        maxWidth={'xs'}
        loading={submitLoading}
        actions={
          <>
            <Button
              color={'secondary'}
              variant={'outlined'}
              onClick={handleConfirmationModalClose}
              disabled={submitLoading}
            >
              Cancel
            </Button>
            <Button
              type={'submit'}
              disabled={submitLoading}
              onClick={handleDeleteMethodConfirm}
              variant={'contained'}
              className={classNames(commonStyles.dangerButtonContained, {
                [commonStyles.hidden]: deletePaymentMethodDisabled,
              })}
            >
              Delete
            </Button>
          </>
        }
      >
        {deletePaymentMethodDisabled ? (
          <p className={commonStyles.text}>
            This payment information is required to process scheduled recurring donations.
          </p>
        ) : (
          <p className={commonStyles.text}>
            Deleting it means you won't be able to use this payment method within the system.
          </p>
        )}
      </Modal>
    </>
  );
};
export default PaymentMethodsTable;
