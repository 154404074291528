import { sorter } from 'util/Table';
import { SortConfig } from 'store/types/Table';
import { UserEmployerAccessRequest } from 'store/types/UserEmployerAccessRequest';

export enum UserEmployerTableActionType {
  SetInitialList = 'SetInitialList',
  UpdateSort = 'UpdateSort',
  UpdateFilter = 'UpdateFilter',
  OpenCancelModal = 'OpenCancelModal',
  CloseCancelModal = 'CloseCancelModal',
  OpenRejectModal = 'OpenRejectModal',
  CloseRejectModal = 'CloseRejectModal',
  OpenApproveModal = 'OpenApproveModal',
  CloseApproveModal = 'CloseApproveModal',
}

export interface UserEmployerTableState {
  approveModalOpen: boolean;
  rejectModalOpen: boolean;
  cancelModalOpen: boolean;
  initialList: UserEmployerAccessRequest[];
  list: UserEmployerAccessRequest[];
  sort: SortConfig<UserEmployerAccessRequest>;
  selectedItem?: UserEmployerAccessRequest;
}

export interface UserEmployerTableAction {
  type: UserEmployerTableActionType;
  payload: Partial<UserEmployerTableState>;
}

const getSortedList = (
  list: UserEmployerAccessRequest[] = [],
  sort: SortConfig<UserEmployerAccessRequest>
): UserEmployerAccessRequest[] => {
  return list.length ? [...list].sort(sorter<UserEmployerAccessRequest>(sort)) : [...list];
};

export const initialState: UserEmployerTableState = {
  rejectModalOpen: false,
  cancelModalOpen: false,
  approveModalOpen: false,
  initialList: [],
  list: [],
  sort: { direction: 'asc', column: 'name' },
  selectedItem: undefined,
};

const reducer = (state: UserEmployerTableState, { type, payload }: UserEmployerTableAction): UserEmployerTableState => {
  if (type === UserEmployerTableActionType.SetInitialList) {
    const { initialList = [] } = payload;

    return { ...state, initialList, list: getSortedList(initialList, state.sort) };
  }

  if (type === UserEmployerTableActionType.UpdateSort) {
    const { sort = {} } = payload;
    const newList = getSortedList(state.initialList, sort);

    return { ...state, sort, list: newList };
  }

  if (type === UserEmployerTableActionType.OpenCancelModal) {
    const { selectedItem } = payload;

    return { ...state, selectedItem, cancelModalOpen: true };
  }

  if (type === UserEmployerTableActionType.CloseCancelModal) {
    return { ...state, selectedItem: undefined, cancelModalOpen: false };
  }

  if (type === UserEmployerTableActionType.OpenRejectModal) {
    const { selectedItem } = payload;

    return { ...state, selectedItem, rejectModalOpen: true };
  }

  if (type === UserEmployerTableActionType.CloseRejectModal) {
    return { ...state, selectedItem: undefined, rejectModalOpen: false };
  }

  if (type === UserEmployerTableActionType.OpenApproveModal) {
    const { selectedItem } = payload;

    return { ...state, selectedItem, approveModalOpen: true };
  }

  if (type === UserEmployerTableActionType.CloseApproveModal) {
    return { ...state, selectedItem: undefined, approveModalOpen: false };
  }

  return state;
};

export default reducer;
