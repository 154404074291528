import { Button, FormControlLabel, Grid } from '@mui/material';
import { Alert } from '@mui/material';
import Spinner from 'components/shared/Spinner';
import { MembershipContext } from 'components/MembershipGuard';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { CurrentMembership } from 'store/types/Membership';
import PaymentMethod from 'store/types/PaymentMethod';
import SubscriptionStatus from 'store/enums/SubscriptionStatus';
import PageCard from 'components/shared/PageCard';
import Switch from 'components/shared/Switch';
import SavedPaymentMethodsSelect from 'components/payments/SavedPaymentMethodsSelect';
import MembershipService from 'services/api/MembershipService';
import { getFormattedDate, STRING_DATE_FORMAT } from 'util/Format';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import EditPaymentMethodModal from 'components/payments/EditPaymentMethodModal';
import { PaymentMethodsContext } from 'components/PaymentMethodsContextWrapper';
import MembershipStatusCard from './MembershipStatusCard';
import { ParametersContext } from 'components/ParametersGuard';

import commonStyles from 'styles/common.module.scss';
import styles from './MembershipInfoPage.module.scss';

const MembershipInfoPage: React.FunctionComponent = () => {
  const currentMembership: CurrentMembership = useContext(MembershipContext);
  const { enqueueSnackbar } = useSnackbar();
  const [addMethodModalOpen, setAddMethodModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    membership: { membershipPageHeader },
  } = useContext(ParametersContext);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const { data, error, loading: paymentMethodsLoading } = useContext(PaymentMethodsContext);
  const { status, autoRenewal = false, setCurrentMembership, endDate = '', membershipLoading } = currentMembership;
  const isNonMember: boolean = status === SubscriptionStatus.NonMember;

  useEffect(() => {
    if (data && data.length) {
      setPaymentMethods(data);
    }
  }, [data]);

  useEffect(() => {
    setLoading(paymentMethodsLoading);
  }, [paymentMethodsLoading]);

  const changeAutoRenewal = useCallback(
    (isEnabled: boolean) => {
      setLoading(true);
      MembershipService.changeAutoRenewal(isEnabled)
        .then(() => {
          enqueueSnackbar(isEnabled ? 'Auto-Renewal successfully turned on' : 'Auto-Renewal successfully turned off', {
            variant: 'success',
          });
          setLoading(false);
          setCurrentMembership({ autoRenewal: isEnabled });
        })
        .catch((errorMessage: string) => {
          setLoading(false);
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        });
    },
    [setCurrentMembership, enqueueSnackbar]
  );

  const handleAddMethodModalClose = useCallback(() => {
    setAddMethodModalOpen(false);
  }, []);

  const handleNewMethodAdded = useCallback(() => {
    setAddMethodModalOpen(false);
    changeAutoRenewal(true);
  }, [changeAutoRenewal]);

  const handleTurnOnClick = useCallback(() => {
    if (!paymentMethods.length) {
      setAddMethodModalOpen(true);
    } else {
      changeAutoRenewal(true);
    }
  }, [changeAutoRenewal, paymentMethods]);

  const handleTurnOffClick = useCallback(() => {
    changeAutoRenewal(false);
  }, [changeAutoRenewal]);

  return (
    <>
      <Grid {...defaultGridContainerProps}>
        {isNonMember && (
          <Grid {...defaultGridItemProps}>
            {membershipPageHeader && <p dangerouslySetInnerHTML={{ __html: membershipPageHeader }} />}
          </Grid>
        )}
        <Grid {...defaultGridItemProps}>
          <MembershipStatusCard />
        </Grid>
        <Grid {...defaultGridItemProps}>
          {!isNonMember && !membershipLoading && (
            <PageCard title={'Auto-Renewal'} className={styles.card}>
              <Spinner loading={loading}>
                <Grid {...defaultGridContainerProps}>
                  <Grid {...defaultGridItemProps}>
                    <p className={styles.description}>
                      {autoRenewal
                        ? 'Thank you for enrolling in automatic renewal.'
                        : 'Make sure you never miss a payment, and enroll in automatic renewal now.'}
                    </p>
                  </Grid>
                  <Grid {...defaultGridItemProps}>
                    <FormControlLabel
                      classes={{ label: styles.checkboxLabel }}
                      checked={autoRenewal}
                      control={<Switch color={'primary'} disabled={true} />}
                      label={
                        autoRenewal ? (
                          <>
                            {'You are enrolled in auto-renewal. Your next payment will be automatically processed on '}
                            <span className={styles.date}>{getFormattedDate(endDate, STRING_DATE_FORMAT)}</span>.
                          </>
                        ) : (
                          'You are not currently enrolled in automatic renewal.'
                        )
                      }
                    />
                  </Grid>
                  {error ? (
                    <Grid {...defaultGridItemProps}>
                      <Alert severity={'error'} className={commonStyles.alert}>
                        {error}
                      </Alert>
                    </Grid>
                  ) : (
                    <>
                      {!!paymentMethods.length && (
                        <Grid {...defaultGridItemProps}>
                          <SavedPaymentMethodsSelect />
                        </Grid>
                      )}
                      <Grid {...defaultGridItemProps}>
                        <div className={styles.row}>
                          {autoRenewal ? (
                            <Button variant={'text'} className={styles.turnOffButton} onClick={handleTurnOffClick}>
                              {'Turn off auto renewal'}
                            </Button>
                          ) : (
                            <Button
                              color={'secondary'}
                              variant={'contained'}
                              onClick={handleTurnOnClick}
                              className={styles.submitButton}
                            >
                              {'Enroll Now'}
                            </Button>
                          )}
                        </div>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Spinner>
            </PageCard>
          )}
        </Grid>
      </Grid>
      <EditPaymentMethodModal
        open={addMethodModalOpen}
        onSubmit={handleNewMethodAdded}
        onClose={handleAddMethodModalClose}
        makeDefaultPreselected={true}
      />
    </>
  );
};
export default MembershipInfoPage;
