import React, { useEffect, useMemo } from 'react';
import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { FormProps, getRequiredValidationRule, getValidationProps } from 'util/Form';
import { PreferenceQuestionView } from 'store/types/PreferenceQuestion';

interface TextQuestionFormItemProps extends FormProps {
  question: PreferenceQuestionView;
}

const TextQuestionFormItem: React.FunctionComponent<TextQuestionFormItemProps> = ({ question, disabled }) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { fieldName, required = false, answer } = useMemo(() => question, [question]);
  useEffect(() => {
    if (answer) {
      setValue(fieldName, answer);
    }
  }, [answer, fieldName, setValue]);

  return (
    <Controller
      render={({ field }) => (
        <TextField
          {...field}
          {...getValidationProps(fieldName, errors)}
          disabled={disabled}
          placeholder={'Please Enter Your Answer'}
          required={required}
        />
      )}
      name={fieldName}
      control={control}
      rules={{
        required: getRequiredValidationRule('your answer', false, required),
      }}
    />
  );
};
export default TextQuestionFormItem;
