import React, { useCallback, useEffect, useState } from 'react';
import PaymentAddressSection from 'components/payments/PaymentFormSection/PaymentAddressSection';
import Spinner from 'components/shared/Spinner';
import { useSnackbar } from 'notistack';
import AddressesService from 'services/api/AddressesService';
import { PaymentFormValues } from 'store/types/FormValues';
import { defaultPaymentFormValues } from 'util/Payment';

const AddressForm: React.FunctionComponent = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [initialValues, setInitialValues] = useState<Partial<PaymentFormValues> | null>(null);
  const [key, setKey] = useState('');

  const [paymentForm, setPaymentForm] = useState<PaymentFormValues>({
    ...defaultPaymentFormValues,
    useHomeAddress: true,
  });

  const handleSubmit = useCallback(async () => {
    setSubmitLoading(true);
    AddressesService.getAddresses()
      .then((result) => {
        const billingAddress = result.find(
          (item) =>
            item.street1 === paymentForm.billingAddress?.street1 &&
            item?.street2 === paymentForm.billingAddress?.street2 &&
            item.city === paymentForm.billingAddress?.city &&
            item?.stateRegion === paymentForm.billingAddress?.stateRegion &&
            item.country === paymentForm.billingAddress?.country
        );

        if (!billingAddress) {
          setSubmitLoading(false);
          return Promise.reject('Billing Address not found.');
        }

        return billingAddress;
      })
      .then((result) => {
        result.isDefaultBilling = true;
        return AddressesService.updateAddress(result);
      })
      .then(() => {
        setKey(Date.now().toString());
        setSubmitLoading(false);
        enqueueSnackbar('Address selection successfully updated', { variant: 'success' });
      })
      .catch((error: string) => {
        setSubmitLoading(false);
        enqueueSnackbar(error, { variant: 'error' });
      });
  }, [enqueueSnackbar, paymentForm]);

  const handlePaymentFormChange = useCallback(
    async (values: Partial<PaymentFormValues>) => {
      if (!initialValues && values?.billingAddress) {
        setInitialValues(values);
      }
      setPaymentForm((prevState) => ({ ...prevState, ...values }));
      const isDirty =
        values?.billingAddress &&
        initialValues?.billingAddress &&
        (values.billingAddress.street1 !== initialValues.billingAddress.street1 ||
          values.billingAddress.street2 !== initialValues.billingAddress.street2 ||
          values.billingAddress.city !== initialValues.billingAddress.city ||
          values.billingAddress.stateRegion !== initialValues.billingAddress.stateRegion ||
          values.billingAddress.postalCode !== initialValues.billingAddress.postalCode ||
          values.billingAddress.country !== initialValues.billingAddress.country);
      setDirty(isDirty ?? false);
    },
    [initialValues]
  );

  useEffect(() => {
    (async () => {
      if (dirty) {
        setInitialValues(null);
        if (initialValues) {
          await handleSubmit();
        }
      }
    })();
  }, [dirty, initialValues, handleSubmit]);

  return (
    <Spinner loading={submitLoading || submitLoading} transparent={true}>
      <PaymentAddressSection
        key={key}
        form={paymentForm}
        onChange={handlePaymentFormChange}
        onlySavedAddresses={true}
      />
    </Spinner>
  );
};

export default AddressForm;
