import { FilterConfig } from 'store/types/Table';
import { getListByPage } from 'util/Table';
import { Community } from 'store/types/Community';

export enum PublicCommitteePageViewActionType {
  SetInitialList = 'SetInitialList',
  UpdateFilter = 'UpdateFilter',
  UpdatePage = 'UpdatePage',
  UpdateItemsPerPage = 'UpdateItemsPerPage',
}

export interface PublicCommitteePageViewState {
  initialList: Community[];
  list: Community[];
  pageList: Community[];
  filter: FilterConfig<Community>;
  page: number;
  itemsPerPage: number;
}

export interface PublicCommitteePageViewAction {
  type: PublicCommitteePageViewActionType;
  payload: Partial<PublicCommitteePageViewState>;
}

export const initialState: PublicCommitteePageViewState = {
  initialList: [],
  list: [],
  pageList: [],
  page: 1,
  itemsPerPage: 10,
  filter: { search: '' },
};

const getList = (list: Community[] = [], filter: FilterConfig<Community>) => {
  const searchValue = (filter.search || '').toLowerCase();
  let result = [...list];

  if (searchValue) {
    result = result.filter(({ name }: Community) => name.toLowerCase().includes(searchValue));
  }
  return result;
};

const reducer = (
  state: PublicCommitteePageViewState,
  { type, payload }: PublicCommitteePageViewAction
): PublicCommitteePageViewState => {
  if (type === PublicCommitteePageViewActionType.SetInitialList) {
    const { initialList = [] } = payload;

    return {
      ...state,
      initialList,
      list: initialList,
      page: 1,
      pageList: getListByPage(initialList, state.itemsPerPage),
    };
  }

  if (type === PublicCommitteePageViewActionType.UpdateFilter) {
    const { filter = {} } = payload;
    const newFilter = { ...state.filter, ...filter };
    const list = getList(state.initialList, newFilter);

    return {
      ...state,
      filter: newFilter,
      list,
      pageList: getListByPage(list, state.itemsPerPage),
    };
  }

  if (type === PublicCommitteePageViewActionType.UpdatePage) {
    const { page = 1 } = payload;

    return {
      ...state,
      page,
      pageList: getListByPage(state.list, state.itemsPerPage, page),
    };
  }

  if (type === PublicCommitteePageViewActionType.UpdateItemsPerPage) {
    const { itemsPerPage = 20 } = payload;

    return {
      ...state,
      itemsPerPage,
      page: 1,
      pageList: getListByPage(state.list, itemsPerPage),
    };
  }

  return state;
};

export default reducer;
