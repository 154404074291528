import Alert from '@mui/material/Alert';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';
import ApplicationsService from 'services/api/ApplicationsService';
import useRequest from 'hooks/useRequest';
import ApplicationContent, { ApplicationContentView } from 'store/types/ApplicationContent';
import Spinner from 'components/shared/Spinner';
import { defaultFormProps } from 'util/Form';
import routes from 'store/configs/Routes';
import { getFormattedApplicationReviewStatus, getFormattedSections, isReviewEditable } from 'util/Application';
import NotFoundPage from 'components/layout/NotFoundPage';
import EditApplicationReviewPageView from './EditApplicationReviewPageView';
import ViewApplicationReviewPageView from './ViewApplicationReviewPageView';

import commonStyles from 'styles/common.module.scss';

const ApplicationReviewPage: React.FunctionComponent = () => {
  const { pathname = '' } = useLocation();
  const { reviewId = '' } = useParams<{ reviewId: string }>();
  const request = useCallback(() => ApplicationsService.getApplicationReview(reviewId), [reviewId]);
  const { data, error, loading } = useRequest<ApplicationContent>(request);
  const form = useForm({ ...defaultFormProps });
  const [review, setReview] = useState<ApplicationContentView | undefined>(undefined);
  const editEnabled: boolean = useMemo(() => isReviewEditable(data ? data.reviewStatus : undefined), [data]);

  useEffect(() => {
    if (data) {
      setReview({
        ...data,
        status: getFormattedApplicationReviewStatus(data.status),
        sections: getFormattedSections(data.sections),
      });
    }
  }, [data]);

  return (
    <Spinner loading={loading} fullPage={true}>
      {error && (
        <Alert severity={'error'} className={commonStyles.alert}>
          {error}
        </Alert>
      )}
      {review && (
        <FormProvider {...form}>
          {pathname.includes(routes.editApplicationReview) &&
            (editEnabled ? <EditApplicationReviewPageView data={review} /> : <NotFoundPage />)}
          {pathname.includes(routes.viewApplicationReview) && <ViewApplicationReviewPageView data={review} />}
        </FormProvider>
      )}
    </Spinner>
  );
};
export default ApplicationReviewPage;
