import { Grid } from '@mui/material';
import React, { useContext, useMemo } from 'react';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { UserContext } from 'components/UserGuard';
import { UserJobFunction } from 'store/types/JobFunction';
import CompanyUsersSection from 'components/company/CompanyUsersSection';

interface CompanyUsersPageViewProps {
  companyId: string;
  refetchCount: () => void;
}

const getCompanyIsParent = (companyId: string, functions: UserJobFunction[] = []): boolean => {
  const foundCompany = functions.find((company) => company.companyId === companyId);
  return foundCompany ? !!foundCompany.isParent : false;
};

const CompanyPageView: React.FunctionComponent<CompanyUsersPageViewProps> = ({ companyId, refetchCount }) => {
  const { functions = [] } = useContext(UserContext);
  const companyIsParent: boolean = useMemo(() => getCompanyIsParent(companyId, functions), [functions, companyId]);

  return (
    <>
      <Grid
        {...defaultGridContainerProps}
        justifyContent={companyIsParent ? 'flex-end' : 'space-between'}
        alignItems={'center'}
      >
        {/*hidden due https://aura-master.visualstudio.com/MemberPlex/_workitems/edit/18534*/}
        {/*{!companyIsParent && !isNccerTheme && (*/}
        {/*  <Grid {...defaultGridItemProps}>*/}
        {/*    <SendInvoicesToSponsorToggle companyId={companyId} />*/}
        {/*  </Grid>*/}
        {/*)}*/}
        <Grid {...defaultGridItemProps}>
          <CompanyUsersSection companyId={companyId} refetchCount={refetchCount} />
        </Grid>
      </Grid>
    </>
  );
};
export default CompanyPageView;
