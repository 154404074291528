import { Button, ButtonGroup, Grid, TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { getRequiredInputMessage, getValidationProps } from 'util/Form';
import applicationReviewDecisionConfig from 'store/configs/ApplicationReviewDecisionConfig';
import ApplicationReviewDecisionValue from 'store/enums/ApplicationReviewDecisionValue';
import { ModalProps } from 'store/types/ComponentProps';
import { ApplicationReviewDecisionFormValues } from 'store/types/FormValues';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import Modal from 'components/shared/Modal';

import commonStyles from 'styles/common.module.scss';

interface ApplicationReviewDecisionModalProps extends ModalProps {
  onSubmit: () => void;
  loading?: boolean;
}

const isCommentRequired = (decisionValue: ApplicationReviewDecisionValue): boolean =>
  decisionValue !== ApplicationReviewDecisionValue.Approve;

const ApplicationReviewDecisionModal: React.FunctionComponent<ApplicationReviewDecisionModalProps> = ({
  onClose,
  onSubmit,
  open,
  loading = false,
}) => {
  const {
    formState: { errors, isValid },
    control,
    setValue,
    trigger,
    getValues,
  } = useFormContext<ApplicationReviewDecisionFormValues>();
  const decisionValue: ApplicationReviewDecisionValue = useWatch({ control, name: 'decision' });
  const commentsRequired: boolean = useMemo(() => isCommentRequired(decisionValue), [decisionValue]);

  useEffect(() => {
    trigger('comments');
  }, [commentsRequired, trigger]);

  const getDecisionButton = useCallback(
    (value: ApplicationReviewDecisionValue) => {
      const label = applicationReviewDecisionConfig[value].name;

      const handleDecisionSelected = () => {
        if (value !== decisionValue) {
          setValue('decision', value);
        }
      };

      return (
        <Button
          fullWidth={true}
          color={'secondary'}
          variant={decisionValue === value ? 'contained' : 'outlined'}
          onClick={handleDecisionSelected}
          value={value}
        >
          {label}
        </Button>
      );
    },
    [decisionValue, setValue]
  );

  const validateCommentsField = useCallback(
    (value?: string) => {
      const decision: ApplicationReviewDecisionValue = getValues('decision');

      return isCommentRequired(decision) && !value ? getRequiredInputMessage('your comments') : true;
    },
    [getValues]
  );

  return (
    <Modal
      title={'Enter Decision'}
      keepMounted={true}
      open={open}
      loading={loading}
      onClose={onClose}
      actions={
        <>
          <Button color={'secondary'} variant={'outlined'} onClick={onClose} disabled={loading}>
            {'Cancel'}
          </Button>
          <Button color={'secondary'} variant={'contained'} disabled={!isValid || loading} onClick={onSubmit}>
            {'Submit'}
          </Button>
        </>
      }
    >
      <Grid {...defaultGridContainerProps}>
        <Grid {...defaultGridItemProps}>
          <Controller
            render={() => (
              <ButtonGroup color={'secondary'} fullWidth={true}>
                {getDecisionButton(ApplicationReviewDecisionValue.Approve)}
                {getDecisionButton(ApplicationReviewDecisionValue.Reject)}
                {getDecisionButton(ApplicationReviewDecisionValue.RequestInformation)}
              </ButtonGroup>
            )}
            name={'decision'}
            control={control}
            rules={{ required: true }}
          />
        </Grid>
        <Grid {...defaultGridItemProps}>
          <h5 className={commonStyles.subTitle}>{`Comments ${commentsRequired ? '*' : ''}`}</h5>
          <Controller
            render={({ field: { onChange, value, onBlur } }) => (
              <TextField
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                {...getValidationProps('comments', errors)}
                required={true}
                InputProps={{
                  multiline: true,
                  rows: 4,
                }}
              />
            )}
            name={'comments'}
            control={control}
            rules={{ validate: validateCommentsField }}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};
export default ApplicationReviewDecisionModal;
