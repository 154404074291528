import {
  EventHotelPreferences,
  EventHotelPreferencesData,
  EventHotelPreferencesFormValues,
  EventHotelPreferencesStepConfigItem,
  EventHotelRoom,
  EventHotelRoomView,
  EventRegistrationHotelPreferences,
} from 'store/types/events/EventHotelPreferences';
import { PreferenceQuestionView } from 'store/types/PreferenceQuestion';
import {
  getDefaultPreferencesFormValues,
  getPreferencesAnswersData,
  getPreferencesQuestionsView,
  PREFERENCES_FIELD_NAME,
} from './Preferences';
import { HotelConfirmStatus } from 'store/enums/events/HotelConfirmStatus';
import moment from 'moment-timezone';
import { CurrentUser } from 'store/types/User';
import { getUserFullName } from 'util/Format';

export const MAX_GUEST_ROOMS = 5;
export const numberOfGuestsConfig: number[] = [1, 2, 3, 4];

export const getStepsConfig = (
  events: EventHotelPreferences[] = [],
  currentUser: CurrentUser
): EventHotelPreferencesStepConfigItem[] =>
  events.map(({ eventTitle, customerName }, index) => ({
    label: `${eventTitle} (${customerName || getUserFullName(currentUser)})`,
    value: index + 1,
    icon: 'LibraryBooks',
  }));

export const getDefaultFormValues = (
  data?: EventHotelPreferences | EventRegistrationHotelPreferences
): EventHotelPreferencesFormValues => {
  const preferencesQuestionsList: PreferenceQuestionView[] = getPreferencesQuestionsView(
    data?.preferences || [],
    PREFERENCES_FIELD_NAME
  );

  return {
    preferencesQuestionsList,
    hotelConfirmStatus:
      data?.hotelRegistration?.rooms?.length || !data?.eventRegistrationId
        ? HotelConfirmStatus.Yes
        : HotelConfirmStatus.No,
    preferences: getDefaultPreferencesFormValues(preferencesQuestionsList),
    rooms:
      data?.hotelRegistration?.rooms?.map((room) => getDefaultRoomValues(room)) ||
      (data?.eventRegistrationId
        ? []
        : [getDefaultRoomValues(undefined, data?.defaultHotelCheckInDate, data?.defaultHotelCheckOutDate)]),
    eventId: data?.eventId || '',
    hotelId: data?.hotelRegistration?.hotelId || '',
    registrantId: data?.registrantId || '',
    guestId: data?.guestId || '',
    userId: data?.userId || '',
    guestName: data?.customerName || '',
    roomTypes: data?.hotelRegistration?.roomTypes || [],
    accessibilities: data?.hotelRegistration?.accessibilities || [],
    hotelName: data?.hotelRegistration?.hotelName || '',
    defaultHotelCheckInDate: data?.defaultHotelCheckInDate,
    defaultHotelCheckOutDate: data?.defaultHotelCheckOutDate,
    isHotelInformationCollected: !!data?.isHotelInformationCollected,
    isPreferencesCollected: !!data?.isPreferencesCollected,
    isHotelInformationCollectedForGuest: !!data?.isHotelInformationCollectedForGuest,
    isPreferencesCollectedForGuest: !!data?.isPreferencesCollectedForGuest,
    eventTitle: data?.eventTitle || '',
    summaryDescription: data?.summaryDescription || '',
    startDate: data?.startDate || '',
    endDate: data?.endDate || '',
    location: data?.location || '',
    address: data?.address,
    timeZoneName: data?.timeZoneName || '',
  };
};
export const getDefaultRoomValues = (
  data?: EventHotelRoom,
  defaultHotelCheckInDate = '',
  defaultHotelCheckOutDate = ''
): EventHotelRoomView => ({
  id: data?.id || '',
  checkInDate: data?.id
    ? data?.checkInDate
    : data?.checkInDate || (defaultHotelCheckInDate && moment(defaultHotelCheckInDate).isSameOrAfter(moment()))
    ? defaultHotelCheckInDate
    : '',
  checkOutDate: data?.id
    ? data?.checkOutDate
    : data?.checkOutDate ||
      (defaultHotelCheckOutDate &&
        moment(defaultHotelCheckOutDate).isSameOrAfter(moment()) &&
        moment(defaultHotelCheckOutDate).isAfter(moment(defaultHotelCheckInDate)))
    ? defaultHotelCheckOutDate
    : '',
  roomTypeId: data?.roomTypeId || '',
  numberOfGuests: data?.numberOfGuests || '',
  accessibilityId: data?.accessibilityId || '',
  specialRequest: data?.specialRequest || '',
  hotelRewards: data?.hotelRewards || '',
  guestName: data?.guestName || '',
  viewExpanded: true,
});

export const getEventsRegistrationData = (data: EventHotelPreferencesFormValues[]): EventHotelPreferencesData[] =>
  data.map(
    ({
      preferencesQuestionsList,
      preferences,
      eventId,
      registrantId,
      rooms,
      hotelId,
      isHotelInformationCollected,
      isHotelInformationCollectedForGuest,
      hotelConfirmStatus,
      guestId,
    }) => ({
      eventId,
      hotelId,
      preferences: getPreferencesAnswersData(preferences, preferencesQuestionsList),
      registrantId,
      guestId,
      rooms:
        hotelConfirmStatus === HotelConfirmStatus.Yes
          ? isHotelInformationCollected
            ? guestId
              ? isHotelInformationCollectedForGuest
                ? rooms
                : []
              : rooms
            : []
          : [],
    })
  );
