import { Grid } from '@mui/material';
import GetApp from '@mui/icons-material/GetAppOutlined';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import Card from 'components/shared/Card';
import { Community } from 'store/types/Community';
import ChapterService from 'services/api/ChapterService';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import SplitButton from 'components/shared/SplitButton';
import { ReportsConfig } from 'store/types/SiteConfig';
import UserService from 'services/api/UserService';
import useRequest from 'hooks/useRequest';
import SiteModule from 'store/enums/SiteModule';
import Alert from '@mui/material/Alert';

import styles from './ChapterReportsSection.module.scss';
import commonStyles from 'styles/common.module.scss';

interface CommunityInfoSectionProps {
  data: Community;
}

interface ReportOption {
  label: React.ReactNode;
  onClick: () => void;
}

const getMenuLabel = (text: string): React.ReactNode => (
  <span className={styles.menuLabel}>
    {text}
    <GetApp color={'inherit'} className={styles.menuLabelIcon} />
  </span>
);

const ChapterReportsSection: React.FunctionComponent<CommunityInfoSectionProps> = ({ data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = data;
  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const { data: reportsConfig, loading, error } = useRequest<ReportsConfig>(UserService.getReportsConfig);
  const [reportsMenuOptions, setReportsMenuOptions] = useState<ReportOption[]>([]);

  const downloadChapterReport = useCallback(
    (reportOptionId: string, reportOptionName) => {
      setFileLoading(true);
      ChapterService.getChapterReport(id, reportOptionId, reportOptionName)
        .then(() => {
          setFileLoading(false);
        })
        .catch((errorMessage: string) => {
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
          setFileLoading(false);
        });
    },
    [enqueueSnackbar, id]
  );

  useEffect(() => {
    if (reportsConfig) {
      setReportsMenuOptions(
        reportsConfig[SiteModule.Chapters]?.reportsMembersRenew.map(({ id, name }) => ({
          label: getMenuLabel(name),
          onClick: () => {
            downloadChapterReport(id, name);
          },
        }))
      );
    }
  }, [downloadChapterReport, reportsConfig]);

  return (
    <Card bordered={true} className={styles.card} contentClassName={styles.cardContent} loading={loading}>
      {error ? (
        <Alert severity={'error'} className={commonStyles.alert}>
          {error}
        </Alert>
      ) : (
        <Grid {...defaultGridContainerProps} alignItems={'center'} justifyContent={'space-between'}>
          <Grid {...defaultGridItemProps} sm={'auto'}>
            <h3 className={styles.sectionTitle}>Reports</h3>
          </Grid>
          <Grid {...defaultGridItemProps} sm={'auto'}>
            <SplitButton options={reportsMenuOptions} loading={fileLoading} />
          </Grid>
        </Grid>
      )}
    </Card>
  );
};
export default ChapterReportsSection;
