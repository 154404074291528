import { HttpService } from 'services/HttpService';
import { API_URL, tenantConfig } from 'config';
import AuthService from 'services/AuthService';
import { httpRequestWrapper } from 'util/Request';
import Donation from 'store/types/Donation';
import DonationFund, {
  CreateCampaignData,
  DonationCampaign,
  DonationParentCampaign,
  DonationPartner,
} from 'store/types/DonationFund';

type DonationFundsResponseData = DonationFund[];
type DonationPartnersResponseDate = DonationPartner[];
type DonationParentCampaignsResponseDate = DonationParentCampaign[];
type CampaignImageType = 'logo' | 'image';

class DonationService {
  public static getDonationFunds(): Promise<DonationFundsResponseData> {
    return new Promise<DonationFundsResponseData>(
      (resolve: (data: DonationFundsResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<DonationFundsResponseData>(
          HttpService.get(`${API_URL}/donations`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getDonation(id: string): Promise<Donation> {
    return new Promise<Donation>((resolve: (data: Donation) => void, reject: (error: string) => void) => {
      httpRequestWrapper<Donation>(
        HttpService.get(`${API_URL}/donations/${id}`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getDonationPartners(): Promise<DonationPartnersResponseDate> {
    return new Promise<DonationPartnersResponseDate>(
      (resolve: (data: DonationPartnersResponseDate) => void, reject: (error: string) => void) => {
        httpRequestWrapper<DonationPartnersResponseDate>(
          HttpService.get(`${API_URL}/donations/partners`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getDonationParentCampaigns(): Promise<DonationParentCampaignsResponseDate> {
    return new Promise<DonationParentCampaignsResponseDate>(
      (resolve: (data: DonationParentCampaignsResponseDate) => void, reject: (error: string) => void) => {
        httpRequestWrapper<DonationParentCampaignsResponseDate>(
          HttpService.get(`${API_URL}/donations/parentCampaigns`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static uploadCampaignImage(campaignId: string, file: File, type: CampaignImageType): Promise<string> {
    const data = new FormData();

    data.append('file', file);
    return new Promise<string>((resolve: (logoUrl: string) => void, reject: (error: string) => void) => {
      httpRequestWrapper<string>(
        HttpService.post(`${API_URL}/donations/${campaignId}/file`, {
          data,
          queryParams: { type },
          headers: {
            Accept: 'application/json',
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static createDonationCampaign(data: CreateCampaignData): Promise<DonationCampaign> {
    return new Promise<DonationCampaign>(
      (resolve: (campaignLink: DonationCampaign) => void, reject: (error: string) => void) => {
        httpRequestWrapper<DonationCampaign>(
          HttpService.post(`${API_URL}/donations/campaign`, {
            data,
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static cancelRecurringDonation(invoiceId: string): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.post(`${API_URL}/donations/${invoiceId}/cancel`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }
}
export default DonationService;
