import {
  AutocompleteRenderInputParams,
  Divider,
  FormControl,
  FormHelperText,
  InputAdornment,
  TextField,
} from '@mui/material';
import Search from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { getRequiredValidationRule, getValidationError, getValidationHelperText } from 'util/Form';
import CommunityMemberStatus from 'store/enums/CommunityMemberStatus';
import { Community } from 'store/types/Community';
import moment from 'moment/moment';

import styles from './CommitteeNameFormItem.module.scss';

const fieldName = 'committeeId';

interface CommitteeNameFormItemProps {
  data?: Community[];
}

const CommitteeNameFormItem: React.FunctionComponent<CommitteeNameFormItemProps> = ({ data = [] }) => {
  const [options, setOptions] = useState<Community[]>([]);
  const [selectedOption, setSelectedOption] = useState<Community>();
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const validationError = getValidationError(fieldName, errors);
  const validationHelperText = getValidationHelperText(fieldName, errors);
  const selectedCommitteeId = useWatch({ control, name: fieldName });

  useEffect(() => {
    if (selectedCommitteeId) {
      setSelectedOption(options.find(({ id }) => id === selectedCommitteeId));
    }
  }, [options, selectedCommitteeId, selectedOption]);

  useEffect(() => {
    if (data?.length) {
      const initialData: Community[] = data.filter(({ endDate }) =>
        endDate ? moment(endDate).isSameOrAfter(moment()) : true
      );

      setOptions(initialData);
    }
  }, [data]);

  const getOption = useCallback(
    (option: Community | string): Community => {
      let result = option as Community;

      if (typeof option === 'string') {
        result = options.find((op: Community) => op.id === option) as Community;
      }
      return result;
    },
    [options]
  );

  const getOptionLabel = useCallback(
    (option: Community) => {
      const selectedOption = getOption(option);

      return selectedOption ? selectedOption.name : '';
    },
    [getOption]
  );

  const searchInputRender = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <InputAdornment position={'start'}>
            <Search color={'inherit'} />
          </InputAdornment>
        ),
      }}
      placeholder={'Start typing to search...'}
    />
  );

  const getOptionDisabled = useCallback(
    ({ memberHistory }: Community) =>
      !!memberHistory?.find(
        ({ status }) => status === CommunityMemberStatus.Approved || status === CommunityMemberStatus.Pending
      ),
    []
  );

  return (
    <>
      <FormControl error={validationError} fullWidth={true} required={true}>
        <Controller
          render={({ field: { onChange } }) => (
            <Autocomplete
              getOptionDisabled={getOptionDisabled}
              renderInput={searchInputRender}
              disableClearable={true}
              options={options}
              getOptionLabel={getOptionLabel}
              onChange={(_: any, value: Community) => {
                onChange(value.id.toString());
              }}
            />
          )}
          name={fieldName}
          control={control}
          rules={{ required: getRequiredValidationRule('committee', true) }}
        />
        {validationError && <FormHelperText>{validationHelperText}</FormHelperText>}
      </FormControl>
      {selectedOption && (
        <>
          <span className={styles.name}>{selectedOption.name}</span>
          <Divider className={styles.divider} />
          <span className={styles.type}>{selectedOption.type}</span>
          <p className={styles.description}>{selectedOption.description}</p>
        </>
      )}
    </>
  );
};
export default CommitteeNameFormItem;
