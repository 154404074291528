import React, { useContext } from 'react';
import { UserContext } from 'components/UserGuard';
import Spinner from 'components/shared/Spinner';
import EventRegistrationsPageView from 'components/events/EventRegistrationsPageView';
import SiteModule from 'store/enums/SiteModule';
import { getPageTitle } from 'util/Layout';
import { ConfigContext } from 'components/ConfigGuard';

import commonStyles from 'styles/common.module.scss';

const EventRegistrationsPage: React.FunctionComponent = () => {
  const { sidebar } = useContext(ConfigContext);
  const { userLoading = false } = useContext(UserContext);

  return (
    <>
      <h1 className={commonStyles.pageTitle}>{getPageTitle(sidebar, SiteModule.MyEvents) || 'My Events'}</h1>
      <Spinner loading={userLoading} transparent={false}>
        <EventRegistrationsPageView />
      </Spinner>
    </>
  );
};
export default EventRegistrationsPage;
