import React, { useCallback, useState } from 'react';
import { printDocument } from 'util/Window';
import EventRegistrationUsersCards from 'components/events/EventRegistrationUsersPrintPage/EventRegistrationUsersCards';
import Spinner from 'components/shared/Spinner';
import { PublicUser } from 'store/types/User';

const printFrameId = 'registration-users-print-frame';
const printContentId = 'registration-users-print-content';

const useEventRegistrationUsersPrint = (
  users: PublicUser[],
  printCallback?: () => void
): [() => void, React.ReactNode] => {
  const [loading, setLoading] = useState<boolean>(false);

  const handlePrintFinished = useCallback(() => {
    setLoading(false);
    if (printCallback) {
      printCallback();
    }
  }, [printCallback]);

  const printUsers = useCallback(() => {
    setLoading(true);
    printDocument(printContentId, printFrameId, handlePrintFinished, 0, 'portrait');
  }, [handlePrintFinished]);

  const printComponent = (
    <>
      <Spinner loading={loading} fullPage={true} />
      {users?.length ? (
        <EventRegistrationUsersCards users={users} printFrameId={printFrameId} printContentId={printContentId} />
      ) : null}
    </>
  );

  return [printUsers, printComponent];
};
export default useEventRegistrationUsersPrint;
