import { API_URL, conf, tenantConfig } from 'config';
import AuthService from 'services/AuthService';
import { HttpService } from 'services/HttpService';
import { BillingAddress } from 'store/types/Address';
import {
  CompanyDetails,
  CompanyDetailsData,
  CompanyPaymentDetails,
  CompanyRequestsInfo,
  CompanySegments,
  NccerCompanyDetails,
} from 'store/types/Company';
import { CompanyEmployeeAccessRequestsData } from 'store/types/CompanyEmployeeAccessRequest';
import CompanyUser from 'store/types/CompanyUser';
import { CompanyUserFormValues } from 'store/types/FormValues';
import { OrderPayment, StatementData } from 'store/types/Payment';
import { PriorApproval } from 'store/types/PriorApproval';
import SelectOption from 'store/types/SelectOption';
import User from 'store/types/User';
import { getFormattedDate } from 'util/Format';
import { httpRequestWrapper } from 'util/Request';

type CompanyUsersResponseData = CompanyUser[];
type CompanyOrdersResponseData = OrderPayment[];
type CompaniesResponseData = SelectOption[];
type NccerCompanyResponseData = NccerCompanyDetails;
type CompanySegmentsResponseData = CompanySegments[];
type CompanyDetailsResponseData = CompanyDetails;
type CompanyAddressesResponseData = BillingAddress[];
type CompanyPriorApprovalsData = PriorApproval[];

class CompanyService {
  public static updateCompanyPaymentDetails(
    companyId: string,
    applyCreditsFlag: CompanyPaymentDetails['applyCredits']
  ): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.put(`${API_URL}/companies/${companyId}/details`, {
          data: { applyCredits: applyCreditsFlag },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getCompanyPaymentDetails(companyId: string): Promise<CompanyPaymentDetails> {
    return new Promise<CompanyPaymentDetails>(
      (resolve: (value: CompanyPaymentDetails) => void, reject: (error: string) => void) => {
        httpRequestWrapper(
          HttpService.get(`${API_URL}/companies/${companyId}/details`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getCompanyRequestsCount(companyId: string): Promise<CompanyRequestsInfo> {
    return new Promise<CompanyRequestsInfo>(
      (resolve: (value: CompanyRequestsInfo) => void, reject: (error: string) => void) => {
        httpRequestWrapper(
          HttpService.get(`${API_URL}/companies/${companyId}/employees/requests/count`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getCompanyDetails(companyId: string): Promise<CompanyDetailsResponseData> {
    return new Promise<CompanyDetailsResponseData>(
      (resolve: (data: CompanyDetailsResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<CompanyDetailsResponseData>(
          HttpService.get(`${API_URL}/companies/${companyId}`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getCompanyAddresses(companyId: string): Promise<CompanyAddressesResponseData> {
    return new Promise<CompanyAddressesResponseData>(
      (resolve: (data: CompanyAddressesResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<CompanyAddressesResponseData>(
          HttpService.get(`${API_URL}/companies/${companyId}/addresses`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static updateCompanyAddress(data: BillingAddress, companyId: string): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.put(`${API_URL}/companies/${companyId}/addresses`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static createCompanyAddress(data: BillingAddress, companyId: string): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.post(`${API_URL}/companies/${companyId}/addresses`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static updateCompanyDetails(data: CompanyDetailsData, companyId: string): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.put(`${API_URL}/companies/${companyId}`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getCompanySizeOptions(): Promise<SelectOption[]> {
    return new Promise<SelectOption[]>((resolve: (data: SelectOption[]) => void, reject: (error: string) => void) => {
      httpRequestWrapper<SelectOption[]>(
        HttpService.get(`${API_URL}/companies/items`, {
          queryParams: { name: 'size' },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getCompanyRevenueOptions(): Promise<SelectOption[]> {
    return new Promise<SelectOption[]>((resolve: (data: SelectOption[]) => void, reject: (error: string) => void) => {
      httpRequestWrapper<SelectOption[]>(
        HttpService.get(`${API_URL}/companies/items`, {
          queryParams: { name: 'revenue' },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static deleteCompanyAddress(addressId: string, companyId: string): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.delete(`${API_URL}/companies/${companyId}/addresses/${addressId}`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getCompanySegments(companyId: string, allSegments?: boolean): Promise<CompanySegmentsResponseData> {
    return new Promise<CompanySegmentsResponseData>(
      (resolve: (value: CompanySegmentsResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper(
          HttpService.get(`${API_URL}/companies/${companyId}/segments`, {
            queryParams: { all: allSegments },
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static updateCompanySegments(data: CompanySegments[], companyId: string): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.put(`${API_URL}/companies/${companyId}/segments`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getStatement(
    companyId: string,
    { startDate, statementDate, openTransactionOnly }: StatementData
  ): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.getFileDownload(`${API_URL}/companies/${companyId}/generateStatement`, {
          queryParams: { startDate, statementDate, openTransactionOnly },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
            [conf.filenameHeader]: `statement-${getFormattedDate(startDate)}-${getFormattedDate(statementDate)}`,
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static linkCompanyAdminUser(companyId: string, profileId: User['id'], jobFunctionId: string): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.post(`${API_URL}/companies/${companyId}/members/link`, {
          data: {
            profileId,
            jobFunctionId,
          },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static linkCompanyEmployee(companyId: string, profileId: User['id']): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.post(`${API_URL}/companies/${companyId}/employees/link`, {
          data: {
            profileId,
          },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static addCompanyAdmin(data: CompanyUserFormValues, companyId: string): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.post(`${API_URL}/companies/${companyId}/members/add`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static addCompanyEmployee(data: CompanyUserFormValues, companyId: string): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.post(`${API_URL}/companies/${companyId}/employees/add`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static removeCompanyAdmin(companyId: string, accountId: User['id']): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.delete(`${API_URL}/companies/${companyId}/members/${accountId}`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static removeCompanyEmployee(companyId: string, accountId: User['id']): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.delete(`${API_URL}/companies/${companyId}/employees/${accountId}`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getCompanyAdministratorUsers(companyId: string): Promise<CompanyUsersResponseData> {
    return new Promise<CompanyUsersResponseData>(
      (resolve: (data: CompanyUsersResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<CompanyUsersResponseData>(
          HttpService.get(`${API_URL}/companies/${companyId}/members`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getCompanyEmployees(companyId: string): Promise<CompanyUsersResponseData> {
    return new Promise<CompanyUsersResponseData>(
      (resolve: (data: CompanyUsersResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<CompanyUsersResponseData>(
          HttpService.get(`${API_URL}/companies/${companyId}/employees`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getCompanyEmployeesGroupReg(companyId: string, eventId: string): Promise<CompanyUsersResponseData> {
    return new Promise<CompanyUsersResponseData>(
      (resolve: (data: CompanyUsersResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<CompanyUsersResponseData>(
          HttpService.get(`${API_URL}/companies/${companyId}/event/${eventId}/employees`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getCompanyEmployeeAccessRequests(companyId: string): Promise<CompanyEmployeeAccessRequestsData> {
    return new Promise<CompanyEmployeeAccessRequestsData>(
      (resolve: (data: CompanyEmployeeAccessRequestsData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<CompanyEmployeeAccessRequestsData>(
          HttpService.get(`${API_URL}/companies/${companyId}/employees/access`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static cancelEmployeeAccessRequests(companyId: string, requestId: string): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.put(`${API_URL}/companies/${companyId}/employees/access/cancel`, {
          data: { requestId },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static approveEmployeeAccessRequests(companyId: string, requestId: string): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.put(`${API_URL}/companies/${companyId}/employees/access/approve`, {
          data: { requestId },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static rejectEmployeeAccessRequests(companyId: string, requestId: string): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.put(`${API_URL}/companies/${companyId}/employees/access/reject`, {
          data: { requestId },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getCompanyOrders(companyId = '', programId?: string): Promise<CompanyOrdersResponseData> {
    return new Promise<CompanyOrdersResponseData>(
      (resolve: (data: CompanyOrdersResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<CompanyOrdersResponseData>(
          HttpService.get(`${API_URL}/companies/${companyId}/orders`, {
            queryParams: { programId },
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getAllCompanyOrders(programId?: string): Promise<CompanyOrdersResponseData> {
    return new Promise<CompanyOrdersResponseData>(
      (resolve: (data: CompanyOrdersResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<CompanyOrdersResponseData>(
          HttpService.get(`${API_URL}/companies/orders/all`, {
            queryParams: { programId },
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getCompanies(): Promise<CompaniesResponseData> {
    return new Promise<CompaniesResponseData>(
      (resolve: (data: CompaniesResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<CompaniesResponseData>(
          HttpService.get(`${API_URL}/subscriptions/organizations`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static updateCompanyAdmin(
    companyId: string,
    newAdminId: string,
    currentAdminId?: string,
    customerFunctionId?: string
  ): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.put(`${API_URL}/companies/${companyId}/admins`, {
          data: { newAdminId, currentAdminId, customerFunctionId },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getSendInvoicesToSponsorFlag(companyId: string): Promise<NccerCompanyResponseData> {
    return new Promise<NccerCompanyResponseData>(
      (resolve: (data: NccerCompanyResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<NccerCompanyResponseData>(
          HttpService.get(`${API_URL}/nccer/companies/${companyId}`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static updateSendInvoicesToSponsorFlag(
    companyId: string,
    flag: NccerCompanyDetails['sendInvoicesToSponsor']
  ): Promise<NccerCompanyResponseData> {
    return new Promise<NccerCompanyResponseData>(
      (resolve: (data: NccerCompanyResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<NccerCompanyResponseData>(
          HttpService.put(`${API_URL}/nccer/companies/${companyId}`, {
            data: { sendInvoicesToSponsor: flag },
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static updateReceiveEmailsFlag(
    companyId: string,
    accountId: string,
    flag: CompanyUser['receiveEmails']
  ): Promise<NccerCompanyResponseData> {
    return new Promise<NccerCompanyResponseData>(
      (resolve: (data: NccerCompanyResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<NccerCompanyResponseData>(
          HttpService.put(`${API_URL}/companies/${companyId}/members/${accountId}/email`, {
            data: { receiveEmails: flag },
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static updateCompanyAdminUser(
    data: CompanyUserFormValues,
    companyId: string,
    profileId: string,
    id: string
  ): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.put(`${API_URL}/companies/${companyId}/members/${id}`, {
          data: { profileId, ...data },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static updateCompanyEmployeeUser(
    data: CompanyUserFormValues,
    companyId: string,
    profileId: string,
    id: string
  ): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.put(`${API_URL}/companies/${companyId}/employees/${id}`, {
          data: { profileId, ...data },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getCompanyPriorApprovals(companyId: string): Promise<CompanyPriorApprovalsData> {
    return new Promise<CompanyPriorApprovalsData>(
      (resolve: (data: CompanyPriorApprovalsData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<CompanyPriorApprovalsData>(
          HttpService.get(`${API_URL}/companies/${companyId}/advocacy/approvals`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }
}
export default CompanyService;
