import { Button, Grid } from '@mui/material';
import React, { useCallback, useState } from 'react';
import {
  defaultGridContainerProps,
  defaultGridItemProps,
  defaultSnackbarErrorProps,
  getButtonLoadingProps,
} from 'util/Layout';
import AdminService from 'services/api/AdminService';
import { useSnackbar } from 'notistack';

import pageStyles from './PortalManagementPageView.module.scss';

const PortalManagementPageView: React.FunctionComponent = () => {
  const [loading, setLoading] = useState<boolean>();
  const { enqueueSnackbar } = useSnackbar();

  const handleResetCache = useCallback(() => {
    setLoading(true);
    AdminService.resetCache()
      .then(() => {
        setLoading(false);
        enqueueSnackbar('Tenant cache successfully has been reset', { variant: 'success' });
      })
      .catch((errorMessage: string) => {
        setLoading(false);
        enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
      });
  }, [enqueueSnackbar]);

  return (
    <Grid {...defaultGridContainerProps}>
      <Grid {...defaultGridItemProps} sm={'auto'}>
        <Button
          {...getButtonLoadingProps(loading)}
          color={'secondary'}
          variant={'contained'}
          type={'submit'}
          className={pageStyles.button}
          onClick={handleResetCache}
        >
          {'Reset Cache'}
        </Button>
      </Grid>
    </Grid>
  );
};

export default PortalManagementPageView;
