import { HttpService } from 'services/HttpService';
import { API_URL, tenantConfig } from 'config';
import AuthService from 'services/AuthService';
import { httpRequestWrapper } from 'util/Request';
import { BillingAddress, BillingAddressData } from 'store/types/Address';

type BillingAddressResponseData = BillingAddress[];

class AddressesService {
  public static getAddresses(): Promise<BillingAddressResponseData> {
    return new Promise<BillingAddressResponseData>(
      (resolve: (data: BillingAddressResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<BillingAddressResponseData>(
          HttpService.get(`${API_URL}/addresses`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static addAddress(data: BillingAddressData): Promise<BillingAddressData> {
    return new Promise<BillingAddressData>(
      (resolve: (data: BillingAddressData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<BillingAddressData>(
          HttpService.post(`${API_URL}/addresses`, {
            data,
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static deleteAddress(id: string): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.delete(`${API_URL}/addresses/${id}`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static updateAddress(data: BillingAddress): Promise<BillingAddress> {
    return new Promise<BillingAddress>((resolve: (data: BillingAddress) => void, reject: (error: string) => void) => {
      httpRequestWrapper<BillingAddress>(
        HttpService.put(`${API_URL}/addresses`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }
}
export default AddressesService;
