import React, { useCallback, useMemo, useState } from 'react';
import useRequest from 'hooks/useRequest';
import EventRegistrationService from 'services/api/EventRegistrationService';
import { useParams } from 'react-router';
import { PublicUser } from 'store/types/User';
import Spinner from 'components/shared/Spinner';
import { Alert, Button } from '@mui/material';
import useEventRegistrationUsersPrint from 'components/events/EventRegistrationUsersPrintPage/EventRegistrationUsersPrintHook';
import PublicPageLayout from 'components/layout/PublicPageLayout';
import { defaultSnackbarErrorProps } from 'util/Layout';

import commonStyles from 'styles/common.module.scss';
import styles from './EventRegistrationUsersPrintPage.module.scss';
import { useSnackbar } from 'notistack';

const EventRegistrationUsersPrintPage: React.FunctionComponent = () => {
  const { id = '' } = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const eventRegistrationUsersRequest = useMemo(
    () => (id ? () => EventRegistrationService.getEventRegistrationUsers(id) : undefined),
    [id]
  );
  const { data, loading, error } = useRequest<PublicUser[]>(eventRegistrationUsersRequest);
  const [printUsers, printComponent] = useEventRegistrationUsersPrint(data || [], undefined);

  const handlePrintButtonClick = useCallback(() => {
    printUsers();
  }, [printUsers]);

  const handleDownloadDocument = useCallback(() => {
    setSubmitLoading(true);
    EventRegistrationService.downloadDocumentBadges(id)
      .then(() => {
        setSubmitLoading(false);
      })
      .catch((error: string) => {
        setSubmitLoading(false);
        enqueueSnackbar(error, defaultSnackbarErrorProps);
      });
  }, [enqueueSnackbar, id]);

  return (
    <>
      <Spinner fullPage={true} loading={loading || submitLoading} transparent={false}>
        <PublicPageLayout title={'Print Badges'} hasFooter={true}>
          {error ? (
            <Alert severity={'error'} className={commonStyles.alert}>
              {error}
            </Alert>
          ) : data?.length ? (
            <>
              <Button color={'primary'} variant={'outlined'} onClick={handlePrintButtonClick}>
                {'Print Badges'}
              </Button>
              <Button color={'primary'} variant={'outlined'} onClick={handleDownloadDocument} className={styles.button}>
                {'Download Document Badges'}
              </Button>
            </>
          ) : (
            !loading && (
              <Alert severity={'warning'} className={commonStyles.alert}>
                {'There are no users to print'}
              </Alert>
            )
          )}
        </PublicPageLayout>
      </Spinner>
      <div className={commonStyles.hidden}>{printComponent}</div>
    </>
  );
};

export default EventRegistrationUsersPrintPage;
