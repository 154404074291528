import { Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import ApplicationQuestionsFormSection from 'components/applications/ApplicationQuestionsFormSection';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import routes from 'store/configs/Routes';
import { ApplicationContentView } from 'store/types/ApplicationContent';
import ApplicationPageLayout from 'components/applications/ApplicationPageLayout';
import ApplicationDecisionModal from 'components/applications/ApplicationDecisionModal';
import { UserContext } from 'components/UserGuard';

import commonStyles from 'styles/common.module.scss';
import styles from './ViewApplicationForm.module.scss';
import pageStyles from '../ApplicationPage.module.scss';

interface ViewApplicationFormProps {
  data: ApplicationContentView;
}

const ViewApplicationForm: React.FunctionComponent<ViewApplicationFormProps> = ({ data }) => {
  const history = useHistory();
  const { id = '' } = useContext(UserContext);
  const [decisionModalOpen, setDecisionModalOpen] = useState<boolean>(false);
  const [isPrintActive, setIsPrintActive] = useState<boolean>(false);

  const hasContent: boolean = useMemo(() => !!(data && data.sections && data.sections.length), [data]);

  const handleCancel = useCallback(() => {
    history.push(routes.applications);
  }, [history]);

  const handlePrintButtonClick = useCallback(() => {
    setTimeout(() => {
      setIsPrintActive(true);
      window.print();
      setIsPrintActive(false);
    }, 0);
  }, []);

  const handleDecisionModalOpen = useCallback(() => {
    setDecisionModalOpen(true);
  }, []);

  const handleDecisionModalClose = useCallback(() => {
    setDecisionModalOpen(false);
  }, []);

  return (
    <>
      <ApplicationPageLayout
        data={data}
        isPrintActive={isPrintActive}
        footerProps={{
          hideSubmitButton: true,
          children: (
            <>
              {id && (
                <Button
                  color={'secondary'}
                  variant={'outlined'}
                  onClick={handleCancel}
                  className={pageStyles.cancelButton}
                >
                  {'Go Back'}
                </Button>
              )}
              {data.review?.decisionDate && (
                <Button color={'secondary'} variant={'outlined'} onClick={handleDecisionModalOpen}>
                  {'View Decision'}
                </Button>
              )}
              {!id && (
                <Button
                  color={'secondary'}
                  variant={'contained'}
                  onClick={handlePrintButtonClick}
                  className={styles.printButton}
                >
                  {'Print'}
                </Button>
              )}
            </>
          ),
        }}
      >
        {data && (
          <div className={pageStyles.questionsWrapper}>
            {data.title && (
              <h1 className={commonStyles.pageTitle}>
                {data.campaignName ? `${data.title} (${data.campaignName})` : data.title}
                {` (ID# ${data.id})`}
              </h1>
            )}
            {hasContent ? (
              <ApplicationQuestionsFormSection sections={data.sections} disabled={true} />
            ) : (
              <Alert severity={'error'} className={commonStyles.alert}>
                No content found
              </Alert>
            )}
          </div>
        )}
      </ApplicationPageLayout>
      {data.review?.decisionDate && (
        <ApplicationDecisionModal
          onClose={handleDecisionModalClose}
          open={decisionModalOpen}
          review={data.review}
          applicationStatus={data.status}
        />
      )}
    </>
  );
};
export default ViewApplicationForm;
