import { Grid, MenuItem, TextField } from '@mui/material';
import React, { createElement, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import * as Icon from '@mui/icons-material';
import FormFooter from 'components/shared/FormFooter';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import {
  defaultFormProps,
  getBillingAddressFormValues,
  getRequiredValidationRule,
  getValidationProps,
} from 'util/Form';
import PhoneFormItem from 'components/profile/PhoneFormItem';
import classNames from 'classnames';
import { CompanyDetailsData, CompanyDetailsView } from 'store/types/Company';
import { useSnackbar } from 'notistack';
import SelectOption from 'store/types/SelectOption';
import Spinner from 'components/shared/Spinner';
import useRequest from 'hooks/useRequest';
import CompanyService from 'services/api/CompanyService';
import Alert from '@mui/material/Alert';
import { PreferenceQuestionView } from 'store/types/PreferenceQuestion';
import { getPreferencesAnswersData, getDefaultPreferencesFormValues, isSubListQuestionsValid } from 'util/Preferences';
import EditPreferencesForm from 'components/profile/EditPreferencesPage/EditPreferencesForm';
import { ParametersContext } from 'components/ParametersGuard';
import CompanySegmentsSection from 'components/company/CompanySegmentsSection';
import QuestionType from 'store/enums/QuestionType';
import { isSectionAvailable } from 'util/User';
import SiteSection from 'store/enums/SiteSection';
import { UserContext } from 'components/UserGuard';
import WebsiteFormItem from './WebsiteFormItem/WebsiteFormItem';
import EmailFormItem from 'components/profile/EmailFormItem';

import commonStyles from 'styles/common.module.scss';
import pageStyles from '../CompanyPageView/CompanyPageView.module.scss';
import styles from './CompanyDetailsPageView.module.scss';

const SOCIAL_LINKS_ARRAY_FIELD_NAME = 'socialLinks';

interface CompanyInformationPageViewProps {
  companyId: string;
  data: CompanyDetailsView;
}

type CompanyDetailsFormValues = Omit<CompanyDetailsView, 'memberType'>;
type CompanyPreferencesFormValues = PreferenceQuestionView;
const getDefaultValues = (data: CompanyDetailsView): CompanyDetailsFormValues => ({
  address: { ...getBillingAddressFormValues(data.address) },
  sizeId: data.sizeId || '',
  email: data.email || '',
  revenueId: data.revenueId || '',
  name: data.name || '',
  phone: data.phone || '',
  acronym: data.acronym || '',
  website: data.website || '',
  socialLinks: data.socialLinks || [],
});

const CompanyDetailsPageView: React.FunctionComponent<CompanyInformationPageViewProps> = ({ companyId, data }) => {
  const preferencesSectionRef = useRef<HTMLInputElement>(null);
  const {
    companyManagement: { companyProfileHeader, companyAdditionInformationHeader },
  } = useContext(ParametersContext);
  const { userPermissions } = useContext(UserContext);
  const form = useForm<CompanyDetailsFormValues>({
    ...defaultFormProps,
    defaultValues: getDefaultValues(data),
  });
  const preferencesForm = useForm<CompanyPreferencesFormValues>({
    ...defaultFormProps,
    defaultValues: getDefaultPreferencesFormValues(data.preferences),
  });
  const {
    getValues,
    trigger,
    watch,
    reset: resetPreferences,
    setError,
    formState: { isValid: isPreferencesValid },
  } = preferencesForm;
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
  } = form;
  const {
    data: sizeOptions,
    loading: sizeOptionsLoading,
    error: sizeOptionsError,
  } = useRequest<SelectOption[]>(CompanyService.getCompanySizeOptions);
  const {
    data: revenueOptions,
    loading: revenueOptionsLoading,
    error: revenueOptionsError,
  } = useRequest<SelectOption[]>(CompanyService.getCompanyRevenueOptions);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { fields } = useFieldArray({
    control,
    name: SOCIAL_LINKS_ARRAY_FIELD_NAME,
  });

  const values = watch();
  const isPreferenceFormValid = useMemo(
    () => isPreferencesValid && isSubListQuestionsValid(data.preferences, values),
    [data, isPreferencesValid, values]
  );

  useEffect(() => {
    if (data) {
      reset(getDefaultValues(data));
      resetPreferences(getDefaultPreferencesFormValues(data.preferences));
    }
  }, [data, reset, resetPreferences]);

  const handleFormSubmit = useCallback(
    (formValues: CompanyDetailsFormValues) => {
      if (!isPreferenceFormValid) {
        trigger();
        data.preferences
          ?.filter(({ required, type }) => required && type === QuestionType.SubList)
          .forEach(({ fieldName }: any) => setError(fieldName, { message: 'Please input your answer' }));
      } else {
        setSubmitLoading(true);
        const preferences = getPreferencesAnswersData(getValues(), data.preferences);
        const companyDetailsData: CompanyDetailsData = {
          ...formValues,
          preferences,
        };

        CompanyService.updateCompanyDetails(companyDetailsData, companyId)
          .then(() => {
            enqueueSnackbar('Company details successfully updated', { variant: 'success' });
            setSubmitLoading(false);
          })
          .catch((errorMessage) => {
            enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
            setSubmitLoading(false);
          });
      }
    },
    [companyId, data.preferences, enqueueSnackbar, getValues, isPreferenceFormValid, setError, trigger]
  );

  const handleSubmitButtonClick = useCallback(() => {
    preferencesSectionRef.current?.scrollIntoView();
    handleSubmit(handleFormSubmit)();
  }, [handleFormSubmit, handleSubmit, preferencesSectionRef]);

  return (
    <Spinner loading={submitLoading}>
      <FormProvider {...form}>
        <Spinner loading={revenueOptionsLoading || sizeOptionsLoading}>
          <Grid {...defaultGridContainerProps}>
            {revenueOptionsError || sizeOptionsError ? (
              <Grid {...defaultGridItemProps}>
                <Alert severity={'error'} className={commonStyles.alert}>
                  {revenueOptionsError || sizeOptionsError}
                </Alert>
              </Grid>
            ) : (
              <>
                <Grid {...defaultGridItemProps} className={pageStyles.wrapper}>
                  <h2 className={classNames(commonStyles.subTitle, commonStyles.uppercase, pageStyles.title)}>
                    {'Company Profile'}
                  </h2>
                </Grid>
                {companyProfileHeader && (
                  <Grid {...defaultGridItemProps}>
                    <p dangerouslySetInnerHTML={{ __html: companyProfileHeader }} />
                  </Grid>
                )}
                <Grid {...defaultGridItemProps} md={6}>
                  <Controller
                    render={({ field }) => (
                      <TextField {...field} {...getValidationProps('name')} label={'Company Name'} required={true} />
                    )}
                    name={'name'}
                    control={control}
                    rules={{ required: getRequiredValidationRule('company name') }}
                  />
                </Grid>
                <Grid {...defaultGridItemProps} md={6}>
                  <Controller
                    render={({ field }) => <TextField {...field} label={'Company Acronym'} />}
                    name={'acronym'}
                    control={control}
                  />
                </Grid>
                <Grid {...defaultGridItemProps} md={6}>
                  <WebsiteFormItem />
                </Grid>
                <Grid {...defaultGridItemProps} md={6}>
                  <PhoneFormItem />
                </Grid>
                <Grid {...defaultGridItemProps} md={6}>
                  <EmailFormItem optional={true} />
                </Grid>
                {!!revenueOptions?.length && (
                  <Grid {...defaultGridItemProps} md={6}>
                    <Controller
                      render={({ field }) => (
                        <TextField {...field} select={true} label={'Revenue Category'}>
                          {revenueOptions.map(({ name, id }) => (
                            <MenuItem key={id} value={id}>
                              {name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                      name={'revenueId'}
                      control={control}
                    />
                  </Grid>
                )}
                {!!sizeOptions?.length && (
                  <Grid {...defaultGridItemProps} md={6}>
                    <Controller
                      render={({ field }) => (
                        <TextField {...field} select={true} label={'Company Size'}>
                          {sizeOptions.map(({ name, id }) => (
                            <MenuItem key={id} value={id}>
                              {name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                      name={'sizeId'}
                      control={control}
                    />
                  </Grid>
                )}
                <Grid {...defaultGridItemProps} lg={12} className={styles.socialLinkWrapper}>
                  {fields.map(({ type, id }, index) => {
                    const socialIcon = Icon[type as keyof typeof Icon];

                    return (
                      <div key={id} className={styles.socialLink}>
                        <span className={styles.icon}>{createElement(socialIcon || 'svg', { color: 'primary' })}</span>
                        <Controller
                          render={({ field }) => <TextField {...field} label={type} />}
                          name={`${SOCIAL_LINKS_ARRAY_FIELD_NAME}.${index}.link`}
                          control={control}
                        />
                      </div>
                    );
                  })}
                </Grid>
              </>
            )}
            {isSectionAvailable(SiteSection.MarketSegments, data.memberType, userPermissions) && (
              <Grid {...defaultGridItemProps}>
                <CompanySegmentsSection companyId={companyId} />
              </Grid>
            )}
            {!!data.preferences?.length && (
              <>
                <Grid {...defaultGridItemProps} className={pageStyles.wrapper} ref={preferencesSectionRef}>
                  <h2 className={classNames(commonStyles.subTitle, commonStyles.uppercase, pageStyles.title)}>
                    {'Additional Company Information'}
                  </h2>
                </Grid>
                <Grid {...defaultGridItemProps}>
                  {companyAdditionInformationHeader && (
                    <p dangerouslySetInnerHTML={{ __html: companyAdditionInformationHeader }} />
                  )}
                  <FormProvider {...preferencesForm}>
                    <EditPreferencesForm data={data.preferences} />
                  </FormProvider>
                </Grid>
              </>
            )}
          </Grid>
        </Spinner>
        <FormFooter submitButtonDisabled={!isValid} onSubmit={handleSubmitButtonClick} />
      </FormProvider>
    </Spinner>
  );
};
export default CompanyDetailsPageView;
