import Skeleton from '@mui/material/Skeleton';
import classNames from 'classnames';
import React from 'react';
import ComponentProps from 'store/types/ComponentProps';

import styles from './TableCountLabel.module.scss';

interface TableCountLabelProps extends ComponentProps {
  viewCount?: number;
  totalCount?: number;
  loading?: boolean;
}

const TableCountLabel: React.FunctionComponent<TableCountLabelProps> = ({
  viewCount = 0,
  totalCount = 0,
  loading = false,
  className = '',
}) => (
  <span className={classNames(styles.label, className)} data-testid={'table-count-label'}>
    {loading ? (
      <Skeleton width={200} variant={'text'} data-testid={'skeleton'} />
    ) : (
      `Viewing ${viewCount} out of ${totalCount}`
    )}
  </span>
);
export default TableCountLabel;
