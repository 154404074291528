import Product, { SelectedProduct } from 'store/types/Products';
import { GUEST_PRICE_LEVEL, NON_MEMBER_PRICE_LEVEL } from './Event';
import ProductsCartItem from 'store/types/ProductsCartItem';
import EventPrice from 'store/types/events/EventPrice';

export const getSelectedProducts = (cart: ProductsCartItem[] = [], products: Product[] = []): SelectedProduct[] => {
  return products
    .map((product) => {
      const cartLine = cart.find(({ productId }) => productId === product.id);
      return {
        ...product,
        quantity: cartLine?.quantity ?? 0,
      };
    })
    .filter(({ quantity }) => quantity > 0);
};

export const getTotalPrice = (products: SelectedProduct[] = [], userPriceLevelId: string): number =>
  products.reduce(
    (prevProductPrice, product) =>
      prevProductPrice +
      (product.quantity > 0
        ? product.quantity * getProductPriceLevel(product.prices, userPriceLevelId)
        : getProductPriceLevel(product.prices, userPriceLevelId)),
    0
  );

export const getProductPriceLevel = (
  prices: EventPrice[] = [],
  userPriceLevelId: string,
  isGuest?: boolean
): number => {
  const guestPrice: number = prices.find(({ priceLevel }) => priceLevel === GUEST_PRICE_LEVEL)?.unitPrice || 0;
  const nonMemberPrice: number = prices.find(({ priceLevel }) => priceLevel === NON_MEMBER_PRICE_LEVEL)?.unitPrice || 0;
  const userPriceLevel: number = prices.find(({ priceLevelId }) => priceLevelId === userPriceLevelId)?.unitPrice || 0;
  const highestPrice: number =
    prices.length &&
    Math.max(
      ...prices.filter(({ priceLevel }) => priceLevel !== GUEST_PRICE_LEVEL).map((priceLevel) => priceLevel.unitPrice)
    );
  const defaultPrice: number = nonMemberPrice || highestPrice || 0;

  return isGuest ? guestPrice ?? defaultPrice : userPriceLevel ?? defaultPrice;
};
