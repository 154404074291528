import { Button, Grid, TextField } from '@mui/material';
import Search from '@mui/icons-material/Search';
import React, { useCallback, useContext } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { DEFAULT_POSTAL_CODE_REGEXP, defaultFormProps, getValidationProps } from 'util/Form';
import DateFormItem from 'components/shared/DateFormItem';
import ComponentProps from 'store/types/ComponentProps';
import { EventCatalogFilterFormValues } from 'store/types/FormValues';
import CreditTypeFormItem from 'components/events/CreditTypeFormItem';
import { getISODateString } from 'util/Format';
import EventTypeFormItem from './EventTypeFormItem';
import DistanceFormItem from './DistanceFormItem';
import LocationFormItem from './LocationFormItem';
import EventCountryFormItem from './EventCountryFormItem';
import moment from 'moment/moment';
import { ParametersContext } from 'components/ParametersGuard';

import commonStyles from 'styles/common.module.scss';

const defaultValues: EventCatalogFilterFormValues = {
  type: [],
  creditType: [],
  location: [],
  distance: '',
  zipCode: '',
  country: '',
  state: '',
};

const getFormValues = (search?: string): EventCatalogFilterFormValues => {
  let result: EventCatalogFilterFormValues = { ...defaultValues };

  if (search) {
    const searchParams: URLSearchParams = new URLSearchParams(search);

    searchParams.forEach((value: string, key: string) => {
      if (key === 'startTime' || key === 'endTime' || key === 'state' || key === 'country') {
        result = { ...result, [key]: value };
      } else {
        result = { ...result, [key]: value.split(',') };
      }
    });
  }
  return result;
};

const EventCatalogFilterBar: React.FunctionComponent<ComponentProps> = ({ className = '' }) => {
  const { search, pathname } = useLocation();
  const history = useHistory();
  const form = useForm<EventCatalogFilterFormValues>({
    ...defaultFormProps,
    defaultValues: getFormValues(search),
  });
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = form;

  const {
    events: { hideEventTypeFilter, hideCreditTypeFilter },
  } = useContext(ParametersContext);

  const handleFormSubmit = useCallback(
    (formValues: EventCatalogFilterFormValues) => {
      reset(formValues);
      const newParams: URLSearchParams = new URLSearchParams();

      Object.keys(formValues).forEach((key: string) => {
        const value = formValues[key as keyof EventCatalogFilterFormValues];

        if (value) {
          if (key === 'startTime' || key === 'endTime') {
            const dateValue: string | null = getISODateString(moment(value));

            if (dateValue) {
              newParams.set(key, dateValue);
            }
          } else {
            newParams.set(key, value.toString());
          }
        }
      });
      history.replace({ pathname, search: `?${newParams.toString()}` });
    },
    [reset, pathname, history]
  );

  const handleClearFilters = useCallback(() => {
    reset(defaultValues);
    history.replace({ pathname, search: `?country=` });
  }, [reset, history, pathname]);

  return (
    <aside className={className}>
      <FormProvider {...form}>
        <Grid {...defaultGridContainerProps}>
          <Grid {...defaultGridItemProps}>
            <h3 className={commonStyles.subTitle}>{'Event Dates'}</h3>
            <DateFormItem fieldName={'startTime'} label={'Start Date'} required={false} />
          </Grid>
          <Grid {...defaultGridItemProps}>
            <DateFormItem fieldName={'endTime'} label={'End Date'} required={false} />
          </Grid>
          {!hideEventTypeFilter && (
            <Grid {...defaultGridItemProps}>
              <h3 className={commonStyles.subTitle}>{'Event Type'}</h3>
              <EventTypeFormItem fieldName={'type'} required={false} />
            </Grid>
          )}
          {!hideCreditTypeFilter && (
            <Grid {...defaultGridItemProps}>
              <h3 className={commonStyles.subTitle}>{'Credit Type'}</h3>
              <CreditTypeFormItem fieldName={'creditType'} required={false} multiple={true} />
            </Grid>
          )}
          <Grid {...defaultGridItemProps}>
            <h3 className={commonStyles.subTitle}>{'Location'}</h3>
            <LocationFormItem fieldName={'location'} />
          </Grid>
          <EventCountryFormItem fieldName={'country'} required={false} />
          <Grid {...defaultGridItemProps} className={commonStyles.hidden}>
            <h3 className={commonStyles.subTitle}>{'Distance'}</h3>
            <Grid {...defaultGridContainerProps}>
              <Grid {...defaultGridItemProps} xs={6}>
                <DistanceFormItem />
              </Grid>
              <Grid {...defaultGridItemProps} xs={6}>
                <Controller
                  render={({ field }) => (
                    <TextField {...field} {...getValidationProps('zipCode', errors)} label={'Zip Code'} />
                  )}
                  name={'zipCode'}
                  control={control}
                  rules={{ pattern: { value: DEFAULT_POSTAL_CODE_REGEXP, message: 'Please input valid zip code' } }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid {...defaultGridItemProps}>
            <Button
              variant={'contained'}
              color={'primary'}
              fullWidth={true}
              endIcon={<Search />}
              onClick={handleSubmit(handleFormSubmit)}
            >
              {'Search'}
            </Button>
          </Grid>
          <Grid {...defaultGridItemProps}>
            <Button variant={'text'} color={'primary'} onClick={handleClearFilters} className={commonStyles.uppercase}>
              {'Clear Filters'}
            </Button>
          </Grid>
        </Grid>
      </FormProvider>
    </aside>
  );
};
export default EventCatalogFilterBar;
