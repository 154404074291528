import Alert from '@mui/material/Alert';
import React, { Reducer, useCallback, useEffect, useReducer, useState } from 'react';
import Table from 'components/shared/Table';
import { EMPTY_STRING_VALUE, getEmailLink, getUserFullName } from 'util/Format';
import { SortConfig, TableColumn } from 'store/types/Table';
import { userFullNameSortLabel } from 'util/Table';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import CompanyUser from 'store/types/CompanyUser';
import UseRequestData from 'store/types/UseRequestData';
import { Grid, Button, FormControlLabel } from '@mui/material';
import CompanyService from 'services/api/CompanyService';
import { useSnackbar } from 'notistack';
import Switch from 'components/shared/Switch';
import { Skeleton } from '@mui/material';
import reducer, {
  initialState,
  CompanyAdministratorUsersTableAction,
  CompanyAdministratorUsersTableActionType,
  CompanyAdministratorUsersTableState,
  TableCompanyAdministratorUsers,
} from './CompanyAdministratorUsersTableReducer';
import JobFunction from 'store/types/JobFunction';
import ViewCompanyUserModal from 'components/company/CompanyUsersSection/ViewCompanyUserModal';

import styles from './CompanyAdministratorUsersTable.module.scss';
import commonStyles from 'styles/common.module.scss';

interface CompanyAdministratorUsersProps extends UseRequestData<CompanyUser[]> {
  companyId: string;
  jobFunctionsList: JobFunction[];
}

const CompanyAdministratorUsersTable: React.FunctionComponent<CompanyAdministratorUsersProps> = ({
  companyId,
  data,
  loading,
  error,
  refetch,
  jobFunctionsList,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loadingReceiveEmails, setLoadingReceiveEmails] = useState<boolean>(false);
  const [{ sort, list = [], selectedUser }, dispatch] = useReducer<
    Reducer<CompanyAdministratorUsersTableState, CompanyAdministratorUsersTableAction>
  >(reducer, initialState);

  useEffect(() => {
    if (data) {
      dispatch({
        type: CompanyAdministratorUsersTableActionType.SetInitialList,
        payload: { initialList: data },
      });
    }
  }, [data, companyId]);

  const handleViewModalOpen = useCallback(
    (record) => () => {
      dispatch({
        type: CompanyAdministratorUsersTableActionType.OpenViewUserModal,
        payload: { selectedUser: record },
      });
    },
    []
  );

  const handleViewModalClose = useCallback(() => {
    dispatch({ type: CompanyAdministratorUsersTableActionType.CloseViewUserModal, payload: {} });
  }, []);

  const handleSortChange = useCallback((newSort: SortConfig) => {
    dispatch({
      type: CompanyAdministratorUsersTableActionType.UpdateSort,
      payload: { sort: newSort },
    });
  }, []);

  const handleReceiveEmailsClick = useCallback(
    (record) => () => {
      setLoadingReceiveEmails(true);
      const { receiveEmails, id } = record;
      CompanyService.updateReceiveEmailsFlag(companyId, id, !receiveEmails)
        .then(() => {
          enqueueSnackbar('Receive Emails successfully updated', { variant: 'success' });
          refetch();
          setLoadingReceiveEmails(false);
        })
        .catch((errorMessage) => {
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
          setLoadingReceiveEmails(false);
        });
    },
    [companyId, enqueueSnackbar, refetch]
  );

  const columns: Array<TableColumn<TableCompanyAdministratorUsers>> = [
    {
      dataIndex: 'jobFunctionName',
      label: 'Portal Role',
      sortable: true,
      align: 'center',
    },
    {
      dataIndex: userFullNameSortLabel,
      label: 'Name',
      sortable: true,
      render: (_: any, record: TableCompanyAdministratorUsers) => getUserFullName(record, true),
    },
    {
      dataIndex: 'email',
      label: 'Email',
      sortable: true,
      render: (email: string) => (email ? getEmailLink(email) : EMPTY_STRING_VALUE),
    },
    {
      dataIndex: 'receiveEmails',
      label: 'Receive Emails',
      align: 'center',
      render: (_: any, record: TableCompanyAdministratorUsers) =>
        loadingReceiveEmails ? (
          <Skeleton variant={'text'} width={70} />
        ) : (
          <FormControlLabel
            className={styles.toggle}
            onClick={handleReceiveEmailsClick(record)}
            checked={record.receiveEmails}
            labelPlacement={'end'}
            label={''}
            control={<Switch size={'small'} color={'primary'} />}
          />
        ),
    },
    {
      label: 'Action',
      align: 'center',
      render: (_: any, record: TableCompanyAdministratorUsers) => (
        <Button size={'small'} onClick={handleViewModalOpen(record)} variant={'outlined'}>
          {'Edit'}
        </Button>
      ),
    },
  ];

  return (
    <>
      <Grid {...defaultGridContainerProps}>
        {error ? (
          <Grid {...defaultGridItemProps}>
            <Alert severity={'error'} className={commonStyles.alert}>
              {error}
            </Alert>
          </Grid>
        ) : (
          <Grid {...defaultGridItemProps}>
            <Table
              columns={columns}
              list={list}
              sort={sort}
              onSortChange={handleSortChange}
              loading={loading}
              showPagination={true}
            />
          </Grid>
        )}
      </Grid>
      {selectedUser && (
        <ViewCompanyUserModal
          open={!!selectedUser}
          onClose={handleViewModalClose}
          data={selectedUser}
          jobFunctionsList={jobFunctionsList}
          refetch={refetch}
          companyId={companyId}
        />
      )}
    </>
  );
};
export default CompanyAdministratorUsersTable;
