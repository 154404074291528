import React, { createElement } from 'react';
import { CustomerCompanyDetails } from 'store/types/MemberDirectory';
import Card from 'components/shared/Card';
import { getAddressInfo } from 'util/Format';
import Website from 'components/shared/Website';
import { Divider, Grid } from '@mui/material';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import StatusLabel from 'components/shared/StatusLabel';
import MemberDirectoryCustomerType from 'store/enums/MemberDirectoryCustomerType';
import * as Icon from '@mui/icons-material';

import commonStyles from 'styles/common.module.scss';
import styles from './CompanyDetailsPageView.module.scss';

interface CompanyDetailsPageViewProps {
  data: CustomerCompanyDetails;
  isChapter?: boolean;
}

const CompanyDetailsPageView: React.FunctionComponent<CompanyDetailsPageViewProps> = ({ data, isChapter }) => {
  const { name, email, website, address, phone, acronym, socialLinks, marketSegments } = data;

  return (
    <Grid {...defaultGridContainerProps} className={styles.container} spacing={2}>
      <Grid {...defaultGridItemProps} className={styles.title}>
        <h2>{name}</h2>
        <StatusLabel status={isChapter ? MemberDirectoryCustomerType.Chapter : MemberDirectoryCustomerType.Company} />
      </Grid>
      <Grid {...defaultGridItemProps} className={styles.dividerWrapper}>
        <Divider className={styles.divider} />
      </Grid>
      <Grid {...defaultGridItemProps} md={6}>
        <Card title={<h3>{`${isChapter ? 'Chapter' : 'Company'} Information`}</h3>}>
          <p className={commonStyles.text}>
            <b>{`Name: `}</b> <span>{name}</span>
          </p>
          <p className={commonStyles.text}>
            <b>{`Acronym: `}</b> <span>{acronym}</span>
          </p>
          <p className={commonStyles.text}>
            <b>{`Website: `}</b>
            {website && <Website link={website} />}
          </p>
          <p className={commonStyles.text}>
            <b>{`Email: `}</b> <span>{email}</span>
          </p>
          <p className={commonStyles.text}>
            <b>{`Phone: `}</b> <span>{phone}</span>
          </p>
          <p className={commonStyles.text}>
            <b>{`Address: `}</b> <span>{address && getAddressInfo(address)}</span>
          </p>
          <p className={commonStyles.text}>
            {socialLinks?.map(({ type, link, id }) => {
              const socialIcon = Icon[type as keyof typeof Icon];

              return (
                <div key={id} className={styles.socialLink}>
                  <span className={styles.icon}>{createElement(socialIcon || 'svg', { color: 'primary' })}</span>
                  <span>{link}</span>
                </div>
              );
            })}
          </p>
        </Card>
      </Grid>
      {!isChapter && (
        <Grid {...defaultGridItemProps} md={6}>
          <Card title={<h3>{'Market Sectors'}</h3>}>
            {marketSegments?.map((segment) => (
              <StatusLabel key={segment} className={styles.segmentLabel} status={segment} variant={'outlined'} />
            )) || <p>{'No market sectors available'}</p>}
          </Card>
        </Grid>
      )}
    </Grid>
  );
};
export default CompanyDetailsPageView;
