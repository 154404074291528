import { HttpError, HttpService } from 'services/HttpService';
import { API_URL, conf, tenantConfig } from 'config';
import AuthService from 'services/AuthService';
import Payment, { OrderPayment, DiscountItem, MultipleCompaniesOrderPayment } from 'store/types/Payment';
import Address from 'store/types/Address';
import { httpRequestWrapper } from 'util/Request';
import { PaymentCardData, PaymentAchData, PaymentCheckData } from 'store/types/PaymentMethodsData';
import DonationPayment from 'store/types/DonationPayment';
import User from 'store/types/User';
import PaymentUser, { PaymentUserIdList } from 'store/types/PaymentUser';
import DonationRecurringPeriod from 'store/enums/DonationRecurringPeriod';
import { HttpRequestOptions } from 'services/HttpTypes';
import PaymentUserAccessLevel from 'store/enums/PaymentUserAccessLevel';
import { EventsCartPaymentData } from 'store/types/events/EventsCartItem';
import { EventHotelPreferencesData } from 'store/types/events/EventHotelPreferences';
import ProductsCartItem from 'store/types/ProductsCartItem';
import OrderType from 'store/enums/OrderType';
import ShippingOptions from 'store/types/ShippingMethod';

type OrdersResponseData = OrderPayment[];
type DonationPaymentsResponseData = DonationPayment[];
type PaymentUsersResponseData = PaymentUser[];
type PaymentAccessResponseData = PaymentUser[];
type CheckoutEventsCartResponseData = EventsCartPaymentData;

export interface BasePaymentData {
  useHomeAddress?: boolean;
  billingAddress?: Address;
  discountId?: string;
  poNumber?: string;
  credits?: number;

  saveMethod: boolean;
  paymentMethodId?: string;
  creditCard?: PaymentCardData;
  ach?: PaymentAchData;
  check?: PaymentCheckData;
  billMe?: PaymentCheckData;
  creditMemo?: { type: 'creditMemo' };
}

export interface OrderPaymentData extends BasePaymentData {
  invoices?: Array<OrderPayment['id']> | MultipleCompaniesOrderPayment[];
  salesOrders?: Array<OrderPayment['id']> | MultipleCompaniesOrderPayment[];
}

export interface ApplicationPaymentData extends BasePaymentData {
  applicationId: string;
}

export interface ExpressPaymentData extends BasePaymentData, Pick<User, 'firstName' | 'lastName' | 'email'> {
  expressCode: string;
}

export interface EnrollSubscriptionPaymentData extends BasePaymentData {
  planId: string;
  donations: Record<string, number>;
  autoRenewal: boolean;
  renew?: boolean;
  startDate?: string;

  quantity: number;
  companyId?: string;
}

export interface UpdateSubscriptionPaymentData extends Pick<BasePaymentData, 'poNumber' | 'billMe' | 'useHomeAddress'> {
  quantity: number;
}

export type MembershipPaymentData = Omit<EnrollSubscriptionPaymentData, 'quantity' | 'companyId'>;

export interface DonationPaymentData extends BasePaymentData, Pick<User, 'firstName' | 'lastName' | 'email'> {
  donationId: string;
  amount: number;
  processingFeeAmount: number;
  amountWithoutFee: number;
  recurrentPeriod?: DonationRecurringPeriod;
  dedication?: string;
  notifyEmail?: string;
  emailText?: string;
  isAnonymousGift: boolean;
  isAnonymousDonation: boolean;
  subscribeToUpdates: boolean;
  payProcessingFee: boolean;
  donationRegistrationUrl?: string;
  donationCampaignName?: string;
  donationCompanyLink?: string;
}

export interface EventsPaymentData extends BasePaymentData {
  totalAmount: number;
  eventsRegistration: EventHotelPreferencesData[];
  promoCodeId?: string;
}

export interface ProductCheckoutData {
  shippingMethodId?: string;
  promoCodeId?: string;
  useDefaultShippingAddress?: boolean;
  shippingAddress?: Address;
}

export interface ProductsPaymentData extends BasePaymentData, ProductCheckoutData {
  totalAmount: number;
  products: ProductsCartItem[];
}

export interface UpdateAccessLevelData extends Pick<PaymentUser, 'accessLevel'> {
  payerId: PaymentUser['profileId'];
}

const paymentHttpRequestOptions: Pick<HttpRequestOptions, 'httpRequestTimeoutRetryLimit'> = {
  httpRequestTimeoutRetryLimit: 0,
};

class PaymentService {
  public static makeMembershipPayment(data: MembershipPaymentData): Promise<Payment> {
    return new Promise<Payment>((resolve: (confirmData: Payment) => void, reject: (error: string) => void) => {
      httpRequestWrapper<Payment>(
        HttpService.post(`${API_URL}/payment/membership`, {
          ...paymentHttpRequestOptions,
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static makePayment(data: OrderPaymentData): Promise<Payment> {
    return new Promise<Payment>((resolve: (confirmData: Payment) => void, reject: (error: string) => void) => {
      httpRequestWrapper<Payment>(
        HttpService.post(`${API_URL}/payment/invoices`, {
          ...paymentHttpRequestOptions,
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static makeSalesPayment(data: OrderPaymentData): Promise<Payment> {
    return new Promise<Payment>((resolve: (confirmData: Payment) => void, reject: (error: string) => void) => {
      httpRequestWrapper<Payment>(
        HttpService.post(`${API_URL}/payment/salesorders`, {
          ...paymentHttpRequestOptions,
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static checkoutEventsCart(promoCodeId?: string): Promise<CheckoutEventsCartResponseData> {
    return new Promise<CheckoutEventsCartResponseData>(
      (resolve: (data: CheckoutEventsCartResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<CheckoutEventsCartResponseData>(
          HttpService.get(`${API_URL}/payment/events/checkout`, {
            queryParams: { promoCodeId },
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static checkoutProductsCart(data?: ProductCheckoutData): Promise<CheckoutEventsCartResponseData> {
    return new Promise<CheckoutEventsCartResponseData>(
      (resolve: (data: CheckoutEventsCartResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<CheckoutEventsCartResponseData>(
          HttpService.post(`${API_URL}/payment/products/checkout`, {
            data,
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static makeCompanyPayment(data: OrderPaymentData, companyId: string): Promise<Payment> {
    return new Promise<Payment>((resolve: (confirmData: Payment) => void, reject: (error: string) => void) => {
      httpRequestWrapper<Payment>(
        HttpService.post(`${API_URL}/companies/${companyId}/invoices/pay`, {
          ...paymentHttpRequestOptions,
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static makeCompanySalesPayment(data: OrderPaymentData, companyId: string): Promise<Payment> {
    return new Promise<Payment>((resolve: (confirmData: Payment) => void, reject: (error: string) => void) => {
      httpRequestWrapper<Payment>(
        HttpService.post(`${API_URL}/companies/${companyId}/salesorders/pay`, {
          ...paymentHttpRequestOptions,
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static makeMultipleCompaniesPayment(data: OrderPaymentData): Promise<Payment> {
    return new Promise<Payment>((resolve: (confirmData: Payment) => void, reject: (error: string) => void) => {
      httpRequestWrapper<Payment>(
        HttpService.post(`${API_URL}/companies/invoices/pay`, {
          ...paymentHttpRequestOptions,
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static makeMultipleCompaniesSalesPayment(data: OrderPaymentData): Promise<Payment> {
    return new Promise<Payment>((resolve: (confirmData: Payment) => void, reject: (error: string) => void) => {
      httpRequestWrapper<Payment>(
        HttpService.post(`${API_URL}/companies/salesorders/pay`, {
          ...paymentHttpRequestOptions,
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static makeDonationPayment(data: DonationPaymentData, newUser?: boolean): Promise<Payment> {
    return new Promise<Payment>((resolve: (confirmData: Payment) => void, reject: (error: HttpError) => void) => {
      HttpService.post(`${API_URL}/payment/donation`, {
        ...paymentHttpRequestOptions,
        data,
        headers: newUser
          ? {
              TenantId: tenantConfig.id,
            }
          : {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
      })
        .toPromise()
        .then((data: Payment) => {
          resolve(data);
        })
        .catch((error: HttpError) => {
          reject(error);
        });
    });
  }

  public static makeSubscriptionPayment(data: EnrollSubscriptionPaymentData): Promise<Payment> {
    return new Promise<Payment>((resolve: (confirmData: Payment) => void, reject: (error: string) => void) => {
      httpRequestWrapper<Payment>(
        HttpService.post(`${API_URL}/payment/subscription`, {
          ...paymentHttpRequestOptions,
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static createSubscriptionOrder(
    subscriptionId: string,
    data: Omit<UpdateSubscriptionPaymentData, 'billMe' | 'useHomeAddress'>
  ): Promise<OrderPayment> {
    const paymentData: UpdateSubscriptionPaymentData = {
      ...data,
      billMe: { type: 'billme' },
      useHomeAddress: true,
    };

    return new Promise<OrderPayment>((resolve: (order: OrderPayment) => void, reject: (error: string) => void) => {
      httpRequestWrapper<OrderPayment>(
        HttpService.post(`${API_URL}/payment/subscription/${subscriptionId}`, {
          data: paymentData,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static makeGroupPayment(data: OrderPaymentData): Promise<Payment> {
    return new Promise<Payment>((resolve: (confirmData: Payment) => void, reject: (error: string) => void) => {
      httpRequestWrapper<Payment>(
        HttpService.put(`${API_URL}/payment/group/invoices`, {
          ...paymentHttpRequestOptions,
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static makeApplicationPayment(data: ApplicationPaymentData): Promise<Payment> {
    return new Promise<Payment>((resolve: (confirmData: Payment) => void, reject: (error: string) => void) => {
      httpRequestWrapper<Payment>(
        HttpService.post(`${API_URL}/payment/applications`, {
          ...paymentHttpRequestOptions,
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getOrdersList(): Promise<OrdersResponseData> {
    return new Promise<OrdersResponseData>(
      (resolve: (data: OrdersResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<OrdersResponseData>(
          HttpService.get(`${API_URL}/payment/orders`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getDonationPaymentsList(): Promise<DonationPaymentsResponseData> {
    return new Promise<DonationPaymentsResponseData>(
      (resolve: (data: DonationPaymentsResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<DonationPaymentsResponseData>(
          HttpService.get(`${API_URL}/donations/recurring`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static sendOrderConfirmation(
    emails: string[],
    orderTypeId: string,
    orderType: OrderType,
    companyId?: string
  ): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.post(
          companyId
            ? `${API_URL}/companies/${companyId}/${orderTypeId}/email`
            : `${API_URL}/payment/${orderTypeId}/email`,
          {
            data: { toAddresses: emails, orderType },
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }
        ),
        resolve,
        reject
      );
    });
  }

  public static getGroupPaymentOrdersList(userIds: PaymentUserIdList = []): Promise<OrdersResponseData> {
    return new Promise<OrdersResponseData>(
      (resolve: (data: OrdersResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<OrdersResponseData>(
          HttpService.get(`${API_URL}/payment/group/invoices`, {
            queryParams: { id: userIds },
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getGroupPaymentUsers(): Promise<PaymentUsersResponseData> {
    return new Promise<PaymentUsersResponseData>(
      (resolve: (data: PaymentUsersResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<PaymentUsersResponseData>(
          HttpService.get(`${API_URL}/payment/group/people`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static linkGroupPaymentUser(data: { accountId: string; accessLevel: PaymentUserAccessLevel }): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.post(`${API_URL}/payment/group/permissions`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getPaymentAccessList(): Promise<PaymentAccessResponseData> {
    return new Promise<PaymentAccessResponseData>(
      (resolve: (data: PaymentAccessResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<PaymentAccessResponseData>(
          HttpService.get(`${API_URL}/payment/group/permissions`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static updatePaymentAccess(accessData: UpdateAccessLevelData[] = []): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.put(`${API_URL}/payment/group/permissions`, {
          data: accessData,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static removePaymentAccess(userId: PaymentUser['profileId']): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.delete(`${API_URL}/payment/group/permissions/${userId}`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static approvePaymentAccessRequest(userId: PaymentUser['profileId']): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.post(`${API_URL}/payment/group/permissions/${userId}/approve`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static rejectPaymentAccessRequest(userId: PaymentUser['profileId']): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.post(`${API_URL}/payment/group/permissions/${userId}/reject`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getExpressInvoice(expressCode: string): Promise<OrderPayment> {
    return new Promise<OrderPayment>((resolve: (data: OrderPayment) => void, reject: (error: string) => void) => {
      httpRequestWrapper<OrderPayment>(
        HttpService.get(`${API_URL}/express/invoice/${expressCode}`, {
          headers: {
            TenantId: tenantConfig.id,
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static makeExpressInvoicePayment(expressCode: string, data: ExpressPaymentData): Promise<Payment> {
    return new Promise<Payment>((resolve: (confirmData: Payment) => void, reject: (error: string) => void) => {
      httpRequestWrapper<Payment>(
        HttpService.post(`${API_URL}/express/invoice/${expressCode}/payment`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getExpressSalesOrder(expressCode: string): Promise<OrderPayment> {
    return new Promise<OrderPayment>((resolve: (data: OrderPayment) => void, reject: (error: string) => void) => {
      httpRequestWrapper<OrderPayment>(
        HttpService.get(`${API_URL}/express/salesorder/${expressCode}`, {
          headers: {
            TenantId: tenantConfig.id,
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static makeExpressSalesOrderPayment(expressCode: string, data: ExpressPaymentData): Promise<Payment> {
    return new Promise<Payment>((resolve: (confirmData: Payment) => void, reject: (error: string) => void) => {
      httpRequestWrapper<Payment>(
        HttpService.post(`${API_URL}/express/salesorder/${expressCode}/payment`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static makeEventsCartPayment(data?: EventsPaymentData): Promise<Payment> {
    return new Promise<Payment>((resolve: (confirmData: Payment) => void, reject: (error: string) => void) => {
      httpRequestWrapper<Payment>(
        HttpService.post(`${API_URL}/payment/events`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static makeProductsCartPayment(data?: ProductsPaymentData): Promise<Payment> {
    return new Promise<Payment>((resolve: (confirmData: Payment) => void, reject: (error: string) => void) => {
      httpRequestWrapper<Payment>(
        HttpService.post(`${API_URL}/payment/products`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getShippingMethods(): Promise<ShippingOptions> {
    return new Promise<ShippingOptions>(
      (resolve: (shippingMethods: ShippingOptions) => void, reject: (error: string) => void) => {
        httpRequestWrapper<ShippingOptions>(
          HttpService.get(`${API_URL}/payment/products/shipping`, {
            headers: {
              TenantId: tenantConfig.id,
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static validatePromotion(promoCode?: string): Promise<string> {
    return new Promise<string>((resolve: (promotionId: string) => void, reject: (error: string) => void) => {
      httpRequestWrapper<string>(
        HttpService.get(`${API_URL}/promotion/validate`, {
          queryParams: { promoCode },
          headers: {
            TenantId: tenantConfig.id,
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getInvoiceDetailsFile(invoiceId: string): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.getFileDownload(`${API_URL}/payment/invoices/${invoiceId}/tests`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
            [conf.filenameHeader]: `invoice-details-${invoiceId}.csv`,
          },
        }),
        resolve,
        reject
      );
    });
  }

  // NOTE: discount logic can be changed
  public static applyDiscountCode(discount: string): Promise<DiscountItem> {
    return new Promise<DiscountItem>(
      (resolve: (discountItem: DiscountItem) => void, reject: (error: string) => void) => {
        httpRequestWrapper<DiscountItem>(
          HttpService.post(`${API_URL}/payment/discount`, {
            data: { discount },
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }
}
export default PaymentService;
