import React, { Reducer, useCallback, useEffect, useReducer } from 'react';
import Table from 'components/shared/Table';
import { SortConfig, TableColumn } from 'store/types/Table';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { Grid } from '@mui/material';
import reducer, {
  initialState,
  PriorApprovalsTableAction,
  PriorApprovalsTableActionType,
  PriorApprovalsTableState,
} from './PriorApprovalsTableReducer';
import { PriorApproval } from 'store/types/PriorApproval';
import StatusLabel from 'components/shared/StatusLabel';
import priorApprovalStatusConfig from 'store/configs/PriorApprovalStatusConfig';
import PriorApprovalStatus from 'store/enums/PriorApprovalStatus';
import { getStringValue } from 'util/Format';

import styles from './PriorApprovalsTable.module.scss';

const getStatusLabel = (status: PriorApprovalStatus) => {
  const { theme = 'grey', name = '' } = priorApprovalStatusConfig[status];

  return <StatusLabel theme={theme} status={name} />;
};

interface PriorApprovalsTableProps {
  data: PriorApproval[];
  isCompany?: boolean;
  showPagination?: boolean;
}

const PriorApprovalsTable: React.FunctionComponent<PriorApprovalsTableProps> = ({
  data,
  isCompany,
  showPagination,
}) => {
  const [{ sort, list = [] }, dispatch] = useReducer<Reducer<PriorApprovalsTableState, PriorApprovalsTableAction>>(
    reducer,
    initialState
  );

  useEffect(() => {
    if (data) {
      dispatch({
        type: PriorApprovalsTableActionType.SetInitialList,
        payload: { initialList: data },
      });
    }
  }, [data]);

  const handleSortChange = useCallback((newSort: SortConfig) => {
    dispatch({
      type: PriorApprovalsTableActionType.UpdateSort,
      payload: { sort: newSort },
    });
  }, []);

  const columns: Array<TableColumn<PriorApproval>> = [
    {
      dataIndex: 'status',
      label: 'Status',
      sortable: true,
      align: 'left',
      verticalAlign: 'top',
      hidden: isCompany,
      render: (status) => getStatusLabel(status),
    },
    {
      dataIndex: 'companyName',
      verticalAlign: 'top',
      label: 'Company',
      hidden: isCompany,
      sortable: true,
    },
    {
      dataIndex: 'signatoryName',
      verticalAlign: 'top',
      label: 'Signatory Name',
      sortable: true,
    },
    {
      dataIndex: 'dateSigned',
      verticalAlign: 'top',
      label: 'Date Signed',
      hidden: !isCompany,
      render: (date) => getStringValue(date, true),
    },
    {
      dataIndex: 'years',
      label: 'Years',
      render: (years) =>
        years.map((year: string) => (
          <span className={styles.year} key={year}>
            {year}
          </span>
        )),
    },
  ];

  return (
    <>
      <Grid {...defaultGridContainerProps} justifyContent={'center'}>
        <Grid {...defaultGridItemProps}>
          <Table
            columns={columns}
            list={list}
            sort={sort}
            onSortChange={handleSortChange}
            showPagination={showPagination}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default PriorApprovalsTable;
