import React, { Reducer, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import Table from 'components/shared/Table';
import { getFormattedDate, NUMBER_DATE_FORMAT } from 'util/Format';
import { getPrice } from 'util/Payment';
import { SortConfig, TableColumn } from 'store/types/Table';
import { OrderPayment } from 'store/types/Payment';
import PaymentDetailsModal from 'components/payments/PaymentDetailsModal';
import { ConfigContext } from 'components/ConfigGuard';
import reducer, {
  initialState,
  InvoicesTableAction,
  InvoicesTableActionType,
  InvoicesTableState,
} from './InvoicesTableReducer';
import { Checkbox, Link } from '@mui/material';

import commonStyles from 'styles/common.module.scss';
import OrderType from 'store/enums/OrderType';

interface InvoicesTableProps {
  data?: OrderPayment[];
  selectedItems: OrderPayment[];
  onItemsSelected: (selectedItems: OrderPayment[]) => void;
  loading?: boolean;
  isCompany?: boolean;
  salesOrders?: boolean;
}

const getSelectedItems = (initialArray: OrderPayment[] = [], selectedItem: OrderPayment): OrderPayment[] => {
  let result = [...initialArray];
  const foundItemIndex: number = initialArray.findIndex(
    ({ orderNumber }: OrderPayment) => orderNumber === selectedItem.orderNumber
  );

  if (foundItemIndex >= 0) {
    result.splice(foundItemIndex, 1);
  } else {
    result = [...result, selectedItem];
  }
  return result;
};

const InvoicesTable: React.FunctionComponent<InvoicesTableProps> = ({
  data = [],
  onItemsSelected,
  selectedItems = [],
  loading = false,
  isCompany,
  salesOrders,
}) => {
  const { isNccerTheme } = useContext(ConfigContext);
  const [{ sort, list = [], clickedItem }, dispatch] = useReducer<Reducer<InvoicesTableState, InvoicesTableAction>>(
    reducer,
    initialState
  );
  const allItemsSelected = !!list.length && selectedItems.length === list.length;

  useEffect(() => {
    if (data) {
      dispatch({
        type: InvoicesTableActionType.SetInitialList,
        payload: { initialList: data },
      });
    }
  }, [data]);

  const handleOrderLinkClick = useCallback(
    (payment: OrderPayment) => () => {
      dispatch({
        type: InvoicesTableActionType.OpenDetails,
        payload: { clickedItem: payment },
      });
    },
    []
  );

  const handleModalClose = useCallback(() => {
    dispatch({
      type: InvoicesTableActionType.CloseDetails,
      payload: {},
    });
  }, [dispatch]);

  const isRowSelected = useCallback(
    (record: OrderPayment) =>
      selectedItems.findIndex(({ orderNumber }: OrderPayment) => orderNumber === record.orderNumber) >= 0,
    [selectedItems]
  );

  const handleSortChange = useCallback((newSort: SortConfig) => {
    dispatch({ type: InvoicesTableActionType.UpdateSort, payload: { sort: newSort } });
  }, []);

  const handleCheckboxClick = useCallback(
    (selectedItem: OrderPayment) => () => {
      const newSelectedItems = getSelectedItems(selectedItems, selectedItem);

      onItemsSelected(newSelectedItems);
    },
    [onItemsSelected, selectedItems]
  );

  const handleSelectAllCheckbox = useCallback(() => {
    onItemsSelected(allItemsSelected ? [] : list);
  }, [onItemsSelected, list, allItemsSelected]);

  const columns: Array<TableColumn<OrderPayment>> = useMemo(() => {
    const result: Array<TableColumn<OrderPayment>> = [
      {
        key: 'checkbox',
        label: (
          <Checkbox
            color={'primary'}
            checked={allItemsSelected}
            onChange={handleSelectAllCheckbox}
            disabled={!list.length}
          />
        ),
        render: (_: any, record: OrderPayment) => (
          <Checkbox color={'primary'} checked={isRowSelected(record)} onChange={handleCheckboxClick(record)} />
        ),
      },
      {
        dataIndex: 'orderNumber',
        label: 'Number',
        sortable: true,
        render: (orderNumber: number, record: OrderPayment) => (
          <Link className={commonStyles.link} onClick={handleOrderLinkClick(record)}>
            {orderNumber}
          </Link>
        ),
      },
      {
        dataIndex: 'orderDate',
        label: isNccerTheme ? 'Date' : 'Date',
        sortable: true,
        render: (date: string) => getFormattedDate(date, NUMBER_DATE_FORMAT),
      },
      {
        dataIndex: 'totalAmount',
        label: 'Amount',
        sortable: true,
        render: (totalAmount: number) => getPrice(totalAmount),
      },
      {
        dataIndex: 'amountPaid',
        label: 'Previous Payments / Credits',
        sortable: true,
        hidden: salesOrders,
        render: (amountPaid: number) => getPrice(amountPaid),
      },
      {
        dataIndex: 'amountRemaining',
        label: 'Balance Due',
        sortable: true,
        render: (amountRemaining: number) => getPrice(amountRemaining),
      },
    ];

    isCompany &&
      result.splice(2, 0, {
        dataIndex: 'ownerName',
        label: 'Company Name',
        sortable: true,
      });
    return result;
  }, [
    allItemsSelected,
    handleSelectAllCheckbox,
    list,
    isNccerTheme,
    salesOrders,
    isCompany,
    isRowSelected,
    handleCheckboxClick,
    handleOrderLinkClick,
  ]);

  return (
    <>
      <Table
        columns={columns}
        list={list}
        sort={sort}
        onSortChange={handleSortChange}
        rowProps={{ selected: isRowSelected }}
        loading={loading}
      />
      {!!clickedItem && (
        <PaymentDetailsModal
          open={!!clickedItem}
          payment={clickedItem}
          title={clickedItem.type === OrderType.SalesOrd ? 'Sales Order' : ''}
          onClose={handleModalClose}
          actionButtonsEnabled={false}
        />
      )}
    </>
  );
};
export default InvoicesTable;
