import { Grid, Link, MenuItem, TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PaymentMethod from 'store/types/PaymentMethod';
import { DEFAULT_ERROR_MESSAGE } from 'util/Form';
import PaymentMethodService from 'services/api/PaymentMethodService';
import {
  defaultGridContainerProps,
  defaultGridItemProps,
  defaultSnackbarErrorProps,
  getInputLoadingProps,
} from 'util/Layout';
import routes from 'store/configs/Routes';
import { getHashRouteUrl } from 'util/Route';
import { PaymentMethodsContext } from 'components/PaymentMethodsContextWrapper';

import commonStyles from 'styles/common.module.scss';
import styles from './SavedPaymentMethodsSelect.module.scss';

const helperText: React.ReactNode = (
  <>
    {'Click '}
    <Link href={getHashRouteUrl(routes.paymentMethodsSettings)} underline={'always'} color={'inherit'}>
      {'here'}
    </Link>
    {' to edit payment methods'}
  </>
);

const SavedPaymentMethodsSelect: React.FunctionComponent = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, loading, error, refetch } = useContext(PaymentMethodsContext);
  const [defaultPaymentMethodId, setDefaultPaymentMethodId] = useState<string>('');
  const [requestLoading, setRequestLoading] = useState<boolean>(false);
  const textFieldVisible: boolean = useMemo(() => !!(loading || (data && data.length)), [loading, data]);

  useEffect(() => {
    if (data) {
      const defaultMethod = data.find(({ isDefault = false }: PaymentMethod) => !!isDefault);

      if (defaultMethod) {
        setDefaultPaymentMethodId(defaultMethod.id);
      }
    }
  }, [data]);

  const handleMethodChange = useCallback(
    (event: any) => {
      const newDefaultMethodId = `${event.target.value}`;

      if (newDefaultMethodId) {
        setRequestLoading(true);
        PaymentMethodService.changeDefaultPaymentMethod(newDefaultMethodId)
          .then(() => {
            enqueueSnackbar('Default method successfully changed', { variant: 'success' });
            setRequestLoading(false);
            setDefaultPaymentMethodId(newDefaultMethodId);
            refetch();
          })
          .catch((errorMessage: string) => {
            setRequestLoading(false);
            enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
          });
      } else {
        enqueueSnackbar(DEFAULT_ERROR_MESSAGE, defaultSnackbarErrorProps);
      }
    },
    [refetch, enqueueSnackbar]
  );

  return (
    <Grid {...defaultGridContainerProps} alignItems={textFieldVisible ? 'stretch' : 'baseline'}>
      <Grid {...defaultGridItemProps} sm={'auto'}>
        <span className={styles.label}>{'Saved Method: '}</span>
      </Grid>
      <Grid {...defaultGridItemProps} sm={true}>
        {error ? (
          <Alert severity={'error'}>{error}</Alert>
        ) : textFieldVisible ? (
          <TextField
            select={true}
            size={'small'}
            disabled={loading || requestLoading}
            onChange={handleMethodChange}
            value={defaultPaymentMethodId}
            InputProps={getInputLoadingProps(loading || requestLoading)}
            helperText={helperText}
          >
            {(data || []).map(({ name, id }: PaymentMethod) => (
              <MenuItem value={id} key={`saved-method-${id}`}>
                {name}
              </MenuItem>
            ))}
          </TextField>
        ) : (
          <p className={commonStyles.text}>{helperText}</p>
        )}
      </Grid>
    </Grid>
  );
};
export default SavedPaymentMethodsSelect;
