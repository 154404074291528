import React, { useCallback, useState } from 'react';
import { CardActionArea, Grid, Tab, Tabs } from '@mui/material';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import IndividualReportSection from './IndividualReportSection';
import Card from 'components/shared/Card';

import commonStyles from 'styles/common.module.scss';
import styles from './EngagementReportPage.module.scss';
import { Add } from '@mui/icons-material';
import CompanySelectModal from './CompanySelectModal';
import { TabContext, TabPanel } from '@mui/lab';

enum EngagementReportTab {
  Company = '1',
  Chapter = '2',
}

const EngagementReportPage: React.FunctionComponent = () => {
  const [companiesToCompare, setCompaniesToCompare] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState<string>(EngagementReportTab.Company);
  const [companySelectModal, setCompanySelectModal] = useState<boolean>(false);

  const removeCompanyRecord = useCallback(
    (id: string) => {
      setCompaniesToCompare(companiesToCompare.filter((companyId) => companyId !== id));
    },
    [companiesToCompare]
  );

  const addCompanyRecord = useCallback(
    (id: string) => {
      setCompaniesToCompare([...companiesToCompare, id]);
      setCompanySelectModal(false);
    },
    [companiesToCompare]
  );

  const handleCompanySelectModalOpen = useCallback(() => {
    setCompanySelectModal(true);
  }, []);

  const handleCompanySelectModalClose = useCallback(() => {
    setCompanySelectModal(false);
  }, []);

  const handleTabChange = useCallback((_: any, value = '1') => {
    setActiveTab(value);
  }, []);

  return (
    <>
      <h1 className={commonStyles.pageTitle}>{'Member Engagement Report'}</h1>
      <TabContext value={activeTab}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor={'primary'}
          textColor={'primary'}
          variant={'scrollable'}
        >
          <Tab value={EngagementReportTab.Company} label={'Company'} className={commonStyles.tab} />
          {/* <Tab value={EngagementReportTab.Chapter} label={'Chapter'} className={commonStyles.tab} /> */}
        </Tabs>
        <TabPanel className={commonStyles.tabPanel} value={EngagementReportTab.Company}>
          <Grid {...defaultGridContainerProps}>
            {companiesToCompare.map((recordId: string) => (
              <Grid {...defaultGridItemProps} key={`report-${recordId}`} md={4} xl={3}>
                <IndividualReportSection id={recordId} removeRecord={removeCompanyRecord} />
              </Grid>
            ))}
            <Grid {...defaultGridItemProps} md={4} xl={3}>
              <Card
                className={styles.card}
                onClick={handleCompanySelectModalOpen}
                contentClassName={styles.content}
                spinnerClassName={styles.spinner}
              >
                <CardActionArea className={styles.actionArea}>
                  <div className={styles.icon}>
                    <Add />
                  </div>
                  <div className={styles.title}>{'Compare'}</div>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel className={commonStyles.tabPanel} value={EngagementReportTab.Chapter}></TabPanel>
      </TabContext>
      <CompanySelectModal
        open={companySelectModal}
        onClose={handleCompanySelectModalClose}
        addRecord={addCompanyRecord}
      />
    </>
  );
};
export default EngagementReportPage;
