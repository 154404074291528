import { Button, Grid, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { defaultFormProps, getRequiredValidationRule, getValidationProps } from 'util/Form';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import SetPasswordFormSection from 'components/shared/SetPasswordFormSection';
import { SetPasswordFormValues } from 'store/types/FormValues';
import UserService from 'services/api/UserService';
import Spinner from 'components/shared/Spinner';

import pageStyles from '../EditSettingsPageView.module.scss';

interface EditPasswordFormValues extends SetPasswordFormValues {
  password: string;
}

const EditPasswordSection: React.FunctionComponent = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [passwordsValid, setPasswordsValid] = useState<boolean>(false);
  const form = useForm<EditPasswordFormValues>({
    ...defaultFormProps,
    defaultValues: {
      password: '',
      newPassword: '',
      confirmNewPassword: '',
    },
  });
  const {
    handleSubmit,
    formState: { errors, isDirty, isValid },
    reset,
    control,
  } = form;
  const submitButtonDisabled: boolean = !isValid || !isDirty || loading || !passwordsValid;

  const handleFormSubmit = useCallback(
    ({ password, newPassword }: EditPasswordFormValues) => {
      setLoading(true);
      UserService.updateCurrentUserPassword(password, newPassword)
        .then(() => {
          reset();
          setLoading(false);
          enqueueSnackbar('Password successfully changed.', { variant: 'success' });
        })
        .catch((error: string) => {
          setLoading(false);
          enqueueSnackbar(error, defaultSnackbarErrorProps);
        });
    },
    [enqueueSnackbar, reset]
  );

  const handleValidChange = useCallback((valid: boolean) => {
    setPasswordsValid(valid);
  }, []);

  return (
    <Spinner loading={loading}>
      <FormProvider {...form}>
        <form>
          <Grid {...defaultGridContainerProps}>
            <Grid {...defaultGridItemProps} lg={8}>
              <Grid {...defaultGridContainerProps}>
                <Grid {...defaultGridItemProps}>
                  <Grid {...defaultGridContainerProps}>
                    <Grid {...defaultGridItemProps} lg={6}>
                      <Controller
                        render={({ field }) => (
                          <TextField
                            {...field}
                            {...getValidationProps('password', errors)}
                            label={'Current Password'}
                            type={'password'}
                            required={true}
                            autoComplete={'off'}
                          />
                        )}
                        name={'password'}
                        control={control}
                        rules={{
                          required: getRequiredValidationRule('current password'),
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <SetPasswordFormSection
                  onValidChange={handleValidChange}
                  labelPrefix={'New'}
                  rowProps={{ ...defaultGridItemProps, lg: 6 }}
                />
              </Grid>
            </Grid>
            <Grid {...defaultGridItemProps} lg={4}>
              <Button
                color={'secondary'}
                variant={'contained'}
                type={'submit'}
                size={'large'}
                className={pageStyles.button}
                disabled={submitButtonDisabled}
                onClick={handleSubmit(handleFormSubmit)}
              >
                {'Change Password'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Spinner>
  );
};
export default EditPasswordSection;
