import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Container, Grid } from '@mui/material';
import { UserContext } from 'components/UserGuard';
import Spinner from 'components/shared/Spinner';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import ProductsCartPageView from '../ProductsCartPageView';
import ProductsPageHeader from './ProductsPageHeader';
import NotFoundPage from 'components/layout/NotFoundPage';
import ProductsService from 'services/api/ProductsService';
import { useSnackbar } from 'notistack';
import { ProductCartContext } from 'components/ProductCartGuard';
import Sidebar from 'components/layout/Sidebar';
import classNames from 'classnames';
import { useWindowSize } from 'util/Window';

import styles from './ProductsCartPage.module.scss';
import commonStyles from 'styles/common.module.scss';

const ProductsCartPage: React.FunctionComponent = () => {
  const { ids = '' } = useParams<{ ids: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const { isMobile } = useWindowSize();
  const { id: userId, userLoading = false } = useContext(UserContext);
  const { cart, cartLoading, refetchCart } = useContext(ProductCartContext);
  const [productIds, setProductIds] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);

  const hasAuthorizedUser: boolean = useMemo(() => !!userId, [userId]);

  const addProductsToCart = useCallback(() => {
    const productsToAdd = productIds.filter((id) => !cart.some((line) => line.productId === id));

    if (productsToAdd.length <= 0) return;

    (async () => {
      setLoading(true);
      await Promise.allSettled(
        productsToAdd.map((id) => {
          ProductsService.getProductDetails([id])
            .then((productDetails) => {
              const detail = productDetails.find((p) => p.id === id);
              if (detail?.availableForSale) {
                return ProductsService.addToCart([{ productId: id, quantity: 1 }])
                  .then(() => {
                    refetchCart();
                    enqueueSnackbar(`${detail?.title} was added to cart`, {
                      variant: 'success',
                      anchorOrigin: { horizontal: 'center', vertical: 'top' },
                    });
                  })
                  .catch(() => {
                    enqueueSnackbar(`Unable to add ${detail?.title} to cart`, {
                      variant: 'error',
                      anchorOrigin: { horizontal: 'center', vertical: 'top' },
                    });
                  });
              } else if (detail) {
                enqueueSnackbar(`${detail?.title} is currently not available for sale`, {
                  variant: 'warning',
                  anchorOrigin: { horizontal: 'center', vertical: 'top' },
                });
              } else {
                enqueueSnackbar(`${id} is not a valid product`, {
                  variant: 'warning',
                  anchorOrigin: { horizontal: 'center', vertical: 'top' },
                });
              }
            })
            .catch(() => {
              enqueueSnackbar(`${id} is not a valid product`, {
                variant: 'error',
                anchorOrigin: { horizontal: 'center', vertical: 'top' },
              });
            });
        })
      );

      setLoading(false);
    })();
  }, [cart, enqueueSnackbar, productIds, refetchCart]);

  useEffect(() => {
    if (ids) {
      setProductIds(
        ids
          .replace(/[^\d|,]/g, '')
          .split(',')
          .filter((id) => parseInt(id) > 0) || []
      );
    }
  }, [ids]);

  useEffect(() => {
    if (initialLoad && !cartLoading && productIds.length > 0) {
      addProductsToCart();
      setInitialLoad(false);
    }
  }, [addProductsToCart, cartLoading, initialLoad, productIds.length]);

  return (
    <Spinner loading={userLoading || loading} transparent={false}>
      <div className={styles.wrapper}>
        <div className={classNames(styles.sidebar, { [commonStyles.hidden]: isMobile })}>
          <Sidebar alwaysCollapsed={true} />
        </div>
        <div className={'rootContent'}>
          <Grid {...defaultGridContainerProps}>
            <Grid {...defaultGridItemProps} sm={true}>
              <ProductsPageHeader />
              {hasAuthorizedUser ? (
                <ProductsCartPageView />
              ) : (
                <Container maxWidth={'lg'}>
                  <NotFoundPage />
                </Container>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </Spinner>
  );
};
export default ProductsCartPage;
