import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import CompanyAdministratorUsersTable from './CompanyAdministratorUsersTable';
import classNames from 'classnames';
import useRequest from 'hooks/useRequest';
import JobFunction from 'store/types/JobFunction';
import UserService from 'services/api/UserService';
import CompanyService from 'services/api/CompanyService';
import CompanyUser from 'store/types/CompanyUser';
import Alert from '@mui/material/Alert';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { Button, Grid } from '@mui/material';
import { Add } from '@mui/icons-material';
import Spinner from 'components/shared/Spinner';
import AddCompanyUserModal from './AddCompanyUserModal';
import { CompanyEmployeeAccessRequestsData } from 'store/types/CompanyEmployeeAccessRequest';
import CompanyEmployeesTable from './CompanyEmployeesTable';
import LinkCompanyUserModal from './LinkCompanyUserModal';
import SplitButton from 'components/shared/SplitButton';
import { ParametersContext } from 'components/ParametersGuard';
import { CompanyPaymentDetails } from 'store/types/Company';
import SelectOption from 'store/types/SelectOption';
import { getAvailablePortalRoles } from 'util/User';
import { UserContext } from 'components/UserGuard';

import commonStyles from 'styles/common.module.scss';
import pageStyles from '../CompanyPageView/CompanyPageView.module.scss';
import styles from './CompanyUsersSection.module.scss';

interface CompanyUsersSectionProps {
  companyId: string;
  refetchCount: () => void;
}

interface PortalRoleOption {
  label: React.ReactNode;
  onClick: () => void;
}

const CompanyUsersSection: React.FunctionComponent<CompanyUsersSectionProps> = ({ companyId, refetchCount }) => {
  const {
    companyManagement: { adminsTableHeader, employeesTableHeader },
  } = useContext(ParametersContext);
  const { functions } = useContext(UserContext);
  const { data, loading, error } = useRequest<JobFunction[]>(UserService.getJobFunctionList);
  const companyUsersRequest = useMemo(
    () => (companyId ? () => CompanyService.getCompanyAdministratorUsers(companyId) : undefined),
    [companyId]
  );
  const companyEmployeesRequest = useMemo(
    () => (companyId ? () => CompanyService.getCompanyEmployees(companyId) : undefined),
    [companyId]
  );
  const companyEmployeeAccessRequests = useMemo(
    () => (companyId ? () => CompanyService.getCompanyEmployeeAccessRequests(companyId) : undefined),
    [companyId]
  );
  const companyDetailsRequest = useMemo(
    () => (companyId ? () => CompanyService.getCompanyPaymentDetails(companyId) : undefined),
    [companyId]
  );
  const companyAdminsRequestData = useRequest<CompanyUser[]>(companyUsersRequest);
  const companyEmployeesRequestData = useRequest<CompanyUser[]>(companyEmployeesRequest);
  const { refetch: refetchEmployees } = companyEmployeesRequestData;
  const companyEmployeeAccessRequestsData =
    useRequest<CompanyEmployeeAccessRequestsData>(companyEmployeeAccessRequests);
  const {
    data: companyDetailsData,
    loading: companyDetailsLoading,
    error: companyDetailsError,
  } = useRequest<CompanyPaymentDetails>(companyDetailsRequest);
  const { refetch: refetchAdmins } = companyAdminsRequestData;
  const { data: employeeAccessRequests } = companyEmployeeAccessRequestsData;
  const [addUserModalOpen, setAddUserModalOpen] = useState<boolean>(false);
  const [linkUserModalOpen, setLinkUserModalOpen] = useState<boolean>(false);
  const [showJobFunctionDropdown, setShowDropFunctionDropdown] = useState<boolean>(false);
  const [portalRoleOptions, setPortalRoleOptions] = useState<PortalRoleOption[]>([]);
  const [portalRoles, setPortalRoles] = useState<SelectOption[]>([]);
  const [selectedPortalRoleId, setSelectedPortalRoleId] = useState<string>('');

  useEffect(() => {
    if (data?.length) {
      const userRoles: string[] = functions?.find((company) => company.companyId === companyId)?.jobFunctionNames || [];
      const portalRoles: SelectOption[] = getAvailablePortalRoles(data, userRoles);

      setPortalRoles(portalRoles);
      setPortalRoleOptions(
        portalRoles.map(({ name, id }) => ({
          label: name,
          onClick: () => setSelectedPortalRoleId(id),
        }))
      );
    }
  }, [companyId, data, functions]);

  const refetchRequests = useCallback(() => {
    showJobFunctionDropdown ? refetchAdmins() : refetchEmployees();
    refetchCount();
  }, [refetchAdmins, refetchCount, refetchEmployees, showJobFunctionDropdown]);

  const handleAddUserModalOpen = useCallback(() => {
    setAddUserModalOpen(true);
    setLinkUserModalOpen(false);
  }, []);

  const handleAddUserModalClose = useCallback(() => {
    setAddUserModalOpen(false);
  }, []);

  const handleLinkUserModalOpen = useCallback(() => {
    setLinkUserModalOpen(true);
    setAddUserModalOpen(false);
  }, []);

  const handleLinkUserModalClose = useCallback(() => {
    setLinkUserModalOpen(false);
  }, []);

  const handleAddEmployee = useCallback(() => {
    setShowDropFunctionDropdown(false);
    handleLinkUserModalOpen();
  }, [handleLinkUserModalOpen]);

  const handleAddAdministrator = useCallback(() => {
    setShowDropFunctionDropdown(true);
    handleLinkUserModalOpen();
  }, [handleLinkUserModalOpen]);

  return (
    <Spinner loading={loading || companyDetailsLoading} transparent={false}>
      {error || companyDetailsError ? (
        <Alert severity={'error'} className={commonStyles.alert}>
          {error || companyDetailsError}
        </Alert>
      ) : (
        <Grid {...defaultGridContainerProps}>
          <Grid {...defaultGridItemProps} xs={true} className={pageStyles.wrapper}>
            <h2 className={classNames(commonStyles.subTitle, commonStyles.uppercase, pageStyles.title)}>
              {'Administrative Roles'}
            </h2>
          </Grid>
          <Grid {...defaultGridItemProps} md={'auto'}>
            <div className={styles.addAdminButton}>
              <SplitButton
                options={portalRoleOptions}
                isSelect={true}
                placeholder={'Select Portal Role'}
                showDefaultValue={false}
              />
              <Button
                color={'secondary'}
                variant={'contained'}
                className={styles.iconButton}
                onClick={handleAddAdministrator}
              >
                <Add />
              </Button>
            </div>
          </Grid>
          <Grid {...defaultGridItemProps}>
            {adminsTableHeader && <p dangerouslySetInnerHTML={{ __html: adminsTableHeader }} />}
            <CompanyAdministratorUsersTable
              {...companyAdminsRequestData}
              companyId={companyId}
              jobFunctionsList={data || []}
            />
          </Grid>
          <Grid {...defaultGridItemProps} xs={true} className={pageStyles.wrapper}>
            <h2 className={classNames(commonStyles.subTitle, commonStyles.uppercase, pageStyles.title)}>
              {'Company Employees'}
            </h2>
          </Grid>
          <Grid {...defaultGridItemProps} md={'auto'}>
            <Button
              color={'secondary'}
              variant={'contained'}
              startIcon={<Add />}
              className={commonStyles.uppercase}
              onClick={handleAddEmployee}
            >
              {'Add Employee'}
            </Button>
          </Grid>
          <Grid {...defaultGridItemProps}>
            {employeesTableHeader && <p dangerouslySetInnerHTML={{ __html: employeesTableHeader }} />}
            <CompanyEmployeesTable {...companyEmployeesRequestData} companyId={companyId} />
          </Grid>
        </Grid>
      )}
      {linkUserModalOpen && (
        <LinkCompanyUserModal
          companyRequests={employeeAccessRequests}
          open={linkUserModalOpen}
          onClose={handleLinkUserModalClose}
          showJobFunctionDropdown={showJobFunctionDropdown}
          onAddUserModalOpen={handleAddUserModalOpen}
          companyId={companyId}
          portalRolesList={portalRoles || []}
          refetch={refetchRequests}
          preSelectedJobFunctionId={showJobFunctionDropdown ? selectedPortalRoleId : ''}
        />
      )}
      {addUserModalOpen && (
        <AddCompanyUserModal
          predefinedAddress={companyDetailsData?.address}
          open={addUserModalOpen}
          onClose={handleAddUserModalClose}
          showJobFunctionDropdown={showJobFunctionDropdown}
          companyId={companyId}
          portalRolesList={portalRoles || []}
          refetch={refetchRequests}
          preSelectedJobFunctionId={showJobFunctionDropdown ? selectedPortalRoleId : ''}
        />
      )}
    </Spinner>
  );
};

export default CompanyUsersSection;
