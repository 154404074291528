import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';
import { FormProps, getRequiredInputMessage, getValidationProps } from 'util/Form';
import anchorme from 'anchorme';

interface WebsiteFormItemProps extends FormProps {
  fieldName?: string;
}

const WebsiteFormItem: React.FunctionComponent<WebsiteFormItemProps> = ({
  fieldName = 'website',
  disabled,
  required = false,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const validateField = useCallback(
    (value: string) => {
      if (required && !value) {
        return getRequiredInputMessage('website');
      } else {
        if (value) {
          if (anchorme.validate.url(value)) {
            return true;
          } else {
            return 'Please input valid website';
          }
        } else {
          return true;
        }
      }
    },
    [required]
  );

  return (
    <Controller
      render={({ field }) => (
        <TextField
          {...getValidationProps(fieldName, errors)}
          {...field}
          label={'Website'}
          disabled={disabled}
          required={required}
        />
      )}
      name={fieldName}
      control={control}
      rules={{ validate: validateField }}
    />
  );
};

export default WebsiteFormItem;
