import { Button, Grid, MenuItem, TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import ApplicationsService from 'services/api/ApplicationsService';
import useRequest from 'hooks/useRequest';
import {
  defaultGridContainerProps,
  defaultGridItemProps,
  defaultSnackbarErrorProps,
  getInputLoadingProps,
} from 'util/Layout';
import FormFooter from 'components/shared/FormFooter';
import routes from 'store/configs/Routes';
import ApplicationType from 'store/types/ApplicationType';
import Spinner from 'components/shared/Spinner';
import Application from 'store/types/Application';
import ApplicationTypeCard from './ApplicationTypeCard';
import Modal from 'components/shared/Modal';
import { UserContext } from 'components/UserGuard';
import ApplicationStatus from 'store/enums/ApplicationStatus';
import { ParametersContext } from 'components/ParametersGuard';
import { getUserRoles } from 'util/User';
import { getFormattedApplicationStatus } from 'util/Application';

import commonStyles from 'styles/common.module.scss';
import styles from './SelectApplicationTypePage.module.scss';

const MAX_DRAFT_APPLICATIONS = 10;

const isApplicationLimited = ({ id, limit }: ApplicationType, userApplications?: Application[]): boolean =>
  !!limit &&
  !!userApplications &&
  userApplications?.filter(
    ({ typeId, status }) => typeId === id && status !== ApplicationStatus.Draft && status !== ApplicationStatus.Denied
  ).length >= limit;

const getCategoryList = (appList: ApplicationType[] = [], userRoles: string[]): string[] =>
  appList.length
    ? Array.from(
        new Set(
          appList
            .filter(({ roles, isPublic }) => isPublic || !!roles.filter(({ name }) => userRoles.includes(name)).length)
            .map((item) => item.category)
        )
      ).sort()
    : [];

const getFilteredAppList = (
  list: ApplicationType[],
  selectedCategory: string,
  userRoles: string[]
): ApplicationType[] => {
  return list
    .filter(({ category }) => category.toLowerCase() === selectedCategory.toLowerCase())
    .filter(({ roles, isPublic }) => isPublic || !!roles.filter(({ name }) => userRoles.includes(name)).length);
};

const SelectApplicationTypePage: React.FunctionComponent = () => {
  const [applications, setApplications] = useState<Application[]>([]);
  const { data, loading, error } = useRequest<ApplicationType[]>(ApplicationsService.getApplicationTypes);
  const {
    applications: { applicationButtonLabel },
  } = useContext(ParametersContext);
  const {
    data: userApplicationsData,
    loading: userApplicationsLoading,
    error: userApplicationsError,
  } = useRequest<Application[]>(ApplicationsService.getUserApplications);
  const history = useHistory();
  const { functions } = useContext(UserContext);
  const userRoles: string[] = useMemo(() => getUserRoles(functions), [functions]);
  const { enqueueSnackbar } = useSnackbar();
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [warningModalOpen, setWarningModalOpen] = useState<boolean>(false);
  const [selectedTypeId, setSelectedTypeId] = useState<ApplicationType['id']>('');
  const categoryList: string[] = useMemo(() => getCategoryList(data, userRoles), [data, userRoles]);
  const filteredAppList: ApplicationType[] = useMemo(
    () => getFilteredAppList(data || [], selectedCategory, userRoles),
    [data, selectedCategory, userRoles]
  );

  useEffect(() => {
    if (userApplicationsData?.length) {
      setApplications(
        userApplicationsData.map((application) => ({
          ...application,
          status: getFormattedApplicationStatus(application.status),
        }))
      );
    }
  }, [data, userApplicationsData]);

  useEffect(() => {
    if (categoryList.length === 1) {
      setSelectedCategory(categoryList[0]);
    }
  }, [categoryList]);

  const handleWarningModalClose = useCallback(() => {
    setWarningModalOpen(false);
  }, []);

  const handleCategoryChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSelectedCategory(e.target.value);
    setSelectedTypeId('');
  }, []);

  const handleTypeClick = useCallback(({ id }: ApplicationType) => {
    setSelectedTypeId(id);
  }, []);

  const handleCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleSubmit = useCallback(() => {
    const draftApplications: Application[] = (applications || []).filter(
      ({ status }) => status === ApplicationStatus.Draft
    );

    if (draftApplications?.length >= MAX_DRAFT_APPLICATIONS) {
      setWarningModalOpen(true);
    } else {
      setSubmitLoading(true);
      ApplicationsService.createApplication(selectedTypeId)
        .then(({ id }: Application) => {
          setSubmitLoading(false);
          enqueueSnackbar('Draft application successfully created', { variant: 'success' });

          history.push(`${routes.editApplication}/${id}`);
        })
        .catch((errorMessage: string) => {
          setSubmitLoading(false);
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        });
    }
  }, [applications, selectedTypeId, enqueueSnackbar, history]);

  return (
    <Spinner loading={submitLoading} fullPage={true}>
      <h1 className={commonStyles.pageTitle}>{'New Application'}</h1>
      {error || userApplicationsError ? (
        <Alert severity={'error'} className={commonStyles.alert}>
          {error}
        </Alert>
      ) : (
        <Grid {...defaultGridContainerProps}>
          <Grid {...defaultGridItemProps} sm={5}>
            <h5 className={commonStyles.subTitle}>{'What are you applying for?'}</h5>
            <TextField
              select={true}
              disabled={loading || userApplicationsLoading}
              onChange={handleCategoryChange}
              value={selectedCategory}
              InputProps={getInputLoadingProps(loading)}
            >
              {categoryList.map((name: string, index: number) => (
                <MenuItem value={name} key={`appCategory-${name}-${index}`}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {!!filteredAppList.length && (
            <Grid {...defaultGridItemProps}>
              <h5>{'Select Application'}</h5>
              <Grid {...defaultGridContainerProps}>
                {filteredAppList.map((item: ApplicationType) => (
                  <Grid {...defaultGridItemProps} key={`application-type-${item.id}`}>
                    <ApplicationTypeCard
                      data={item}
                      limited={isApplicationLimited(item, applications)}
                      onClick={handleTypeClick}
                      selected={selectedTypeId === item.id}
                      customFreeButtonLabel={applicationButtonLabel}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
      <FormFooter onSubmit={handleSubmit} submitButtonName={'Next'} submitButtonDisabled={!selectedTypeId}>
        <Button color={'secondary'} variant={'outlined'} onClick={handleCancel} className={styles.cancelButton}>
          {'Cancel'}
        </Button>
      </FormFooter>
      <Modal
        open={warningModalOpen}
        title={'Warning'}
        onClose={handleWarningModalClose}
        maxWidth={'xs'}
        actions={
          <Button color={'secondary'} variant={'outlined'} onClick={handleWarningModalClose}>
            {'Close'}
          </Button>
        }
      >
        <p className={commonStyles.text}>
          {'You already have 10 draft applications, please delete the unnecessary ones to create a new application'}
        </p>
      </Modal>
    </Spinner>
  );
};
export default SelectApplicationTypePage;
