import { Typography } from '@mui/material';
import React, { useContext } from 'react';
import PublicPageLayout from 'components/layout/PublicPageLayout';
import { ConfigContext } from 'components/ConfigGuard';
import NotFoundPage from 'components/layout/NotFoundPage';

const SubscriptionTermsPage: React.FunctionComponent = () => {
  const { isNccerTheme } = useContext(ConfigContext);

  return isNccerTheme ? (
    <PublicPageLayout title={'Terms and Conditions'}>
      <>
        <Typography paragraph={true} color={'textPrimary'}>
          {
            'The Purchasing Organization (referred to as the “Organization”) is solely responsible for the accuracy of the number of subscriptions purchased and the subscription terms. The number of subscriptions purchased by the organization is for each student who will be taking NCCER module tests. Subscriptions are non-refundable and expire at the end of the term. If the number of test takers exceeds the number of subscriptions purchased, the organization is responsible for purchasing additional subscriptions. NCCER reserves the right to bill the organization for additional subscriptions should the organization fail to purchase these additional subscriptions.'
          }
        </Typography>
        <Typography paragraph={true} color={'textPrimary'}>
          {
            'The organization is responsible for providing the Purchase Order number and for the accuracy of the Purchase Order number. Purchase Order numbers are for the organization’s reference only; NCCER does not track the balance of purchase orders.'
          }
        </Typography>
        <Typography paragraph={true} color={'textPrimary'}>
          {
            'NCCER will work to remedy systems issues expeditiously.  However, NCCER will not be liable for any delay, non-delivery or default in the availability of the billing portal, the NCCER testing system, the organization’s user account or any particular test or assessment. NCCER’S BILLING PORTAL, TESTING SYSTEM, THE ORGANIZATION’S USER ACCOUNT AND THE TESTS AND ASSESSMENTS NCCER MAKES AVAILABLE TO USERS THROUGH THE SYSTEM ARE PROVIDED ON AN ‘AS IS’ BASIS, WITHOUT (TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW) ANY WARRANTIES, CONDITIONS OR REPRESENTATIONS, WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING, WITHOUT LIMITATION, WARRANTIES OR CONDITIONS OF QUALITY, PERFORMANCE, NON-INFRINGEMENT, MERCHANTABILITY, DURABILITY, OR FITNESS FOR A PARTICULAR PURPOSE, NOR ARE THERE ANY WARRANTIES OR CONDITIONS CREATED BY A COURSE OF DEALING, COURSE OF PERFORMANCE OR TRADE USAGE. NCCER DOES NOT WARRANT AND SPECIFICALLY DISCLAIMS THAT THE BILLING PORTAL, TESTING SYSTEM, THE ORGANIZATION’S USER ACCOUNT AND THE TESTS AND ASSESSMENTS NCCER MAKES AVAILABLE TO USERS THROUGH THE SYSTEM WILL MEET THE ORGANIZATION’S NEEDS OR BE FREE FROM ERRORS, OR THAT THE OPERATION OF SAME WILL BE UNINTERRUPTED. THE FOREGOING EXCLUSIONS AND DISCLAIMERS ARE AN ESSENTIAL PART OF THESE TERMS & CONDITIONS AND FORM THE BASIS FOR DETERMINING THE FEES CHARGED FOR THE TESTS AND ASSESSMENTS NCCER MAKES AVAILABLE TO USERS THROUGH THE SYSTEM.'
          }
        </Typography>
      </>
    </PublicPageLayout>
  ) : (
    <NotFoundPage publicPage={true} />
  );
};
export default SubscriptionTermsPage;
