import Alert from '@mui/material/Alert';
import React, { Reducer, SyntheticEvent, useCallback, useContext, useEffect, useReducer, useState } from 'react';
import Table from 'components/shared/Table';
import { SortConfig, TableColumn } from 'store/types/Table';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import UseRequestData from 'store/types/UseRequestData';
import classNames from 'classnames';
import { Grid, Button } from '@mui/material';
import Modal from 'components/shared/Modal';
import { useSnackbar } from 'notistack';
import reducer, {
  initialState,
  UserEmployerTableAction,
  UserEmployerTableActionType,
  UserEmployerTableState,
} from './UserEmployerTableReducer';
import { EMPTY_STRING_VALUE, getFormattedDate } from 'util/Format';
import CompanyAccessRequestStatus from 'store/enums/CompanyAccessRequestStatus';
import { UserEmployerAccessRequest } from 'store/types/UserEmployerAccessRequest';
import UserEmployerService from 'services/api/UserEmployerService';
import { getEmployerRequestStatusLabel } from 'util/User';
import { UserContext } from 'components/UserGuard';

import commonStyles from 'styles/common.module.scss';

interface UserRelationshipTableProps extends UseRequestData<UserEmployerAccessRequest[]> {
  userRequests?: boolean;
}

const UserEmployerTable: React.FunctionComponent<UserRelationshipTableProps> = ({
  data,
  loading,
  error,
  refetch,
  userRequests,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { refetchUser } = useContext(UserContext);
  const [loadingModal, setLoadingModal] = useState<boolean>(false);
  const [{ sort, list = [], selectedItem, rejectModalOpen, approveModalOpen, cancelModalOpen }, dispatch] = useReducer<
    Reducer<UserEmployerTableState, UserEmployerTableAction>
  >(reducer, initialState);

  useEffect(() => {
    if (data) {
      dispatch({
        type: UserEmployerTableActionType.SetInitialList,
        payload: { initialList: data },
      });
    }
  }, [data]);

  const handleSortChange = useCallback((newSort: SortConfig) => {
    dispatch({
      type: UserEmployerTableActionType.UpdateSort,
      payload: { sort: newSort },
    });
  }, []);

  const handleCancelButtonClick = useCallback(
    (record) => () => {
      dispatch({
        type: UserEmployerTableActionType.OpenCancelModal,
        payload: { selectedItem: record },
      });
    },
    []
  );

  const handleCancelModalClose = useCallback(() => {
    dispatch({
      type: UserEmployerTableActionType.CloseCancelModal,
      payload: {},
    });
  }, []);

  const handleRejectButtonClick = useCallback(
    (record) => () => {
      dispatch({
        type: UserEmployerTableActionType.OpenRejectModal,
        payload: { selectedItem: record },
      });
    },
    []
  );

  const handleRejectModalClose = useCallback(() => {
    dispatch({
      type: UserEmployerTableActionType.CloseRejectModal,
      payload: {},
    });
  }, []);

  const handleApproveButtonClick = useCallback(
    (record) => () => {
      dispatch({
        type: UserEmployerTableActionType.OpenApproveModal,
        payload: { selectedItem: record },
      });
    },
    []
  );

  const handleApproveModalClose = useCallback(() => {
    dispatch({
      type: UserEmployerTableActionType.CloseApproveModal,
      payload: {},
    });
  }, []);

  const handleCancelRequestSubmit = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      if (selectedItem) {
        setLoadingModal(true);
        UserEmployerService.cancelEmployerRequest(selectedItem.id)
          .then(() => {
            enqueueSnackbar('Request successfully canceled', { variant: 'success' });
            dispatch({
              type: UserEmployerTableActionType.CloseCancelModal,
              payload: {},
            });
            setLoadingModal(false);
            refetch();
          })
          .catch((errorMessage) => {
            setLoadingModal(false);
            enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
          });
      }
    },
    [enqueueSnackbar, refetch, selectedItem]
  );

  const handleRejectRequestSubmit = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      if (selectedItem) {
        setLoadingModal(true);
        UserEmployerService.rejectEmployerRequests(selectedItem.id)
          .then(() => {
            enqueueSnackbar('Request successfully rejected', { variant: 'success' });
            dispatch({
              type: UserEmployerTableActionType.CloseRejectModal,
              payload: {},
            });
            setLoadingModal(false);
            refetch();
          })
          .catch((errorMessage) => {
            setLoadingModal(false);
            enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
          });
      }
    },
    [enqueueSnackbar, refetch, selectedItem]
  );

  const handleApproveRequestSubmit = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      if (selectedItem) {
        setLoadingModal(true);
        UserEmployerService.approveEmployerRequests(selectedItem.id)
          .then(() => {
            enqueueSnackbar('Request successfully approved', { variant: 'success' });
            dispatch({
              type: UserEmployerTableActionType.CloseApproveModal,
              payload: {},
            });
            setLoadingModal(false);
            refetch();
            refetchUser();
          })
          .catch((errorMessage) => {
            setLoadingModal(false);
            enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
          });
      }
    },
    [enqueueSnackbar, refetch, refetchUser, selectedItem]
  );

  const columns: Array<TableColumn<UserEmployerAccessRequest>> = [
    {
      dataIndex: 'status',
      label: 'Status',
      sortable: true,
      align: 'left',
      render: (status) => getEmployerRequestStatusLabel(status),
    },
    {
      dataIndex: 'name',
      label: 'Company',
      sortable: true,
    },
    {
      dataIndex: 'requester',
      label: 'Requester',
      sortable: true,
      hidden: !!userRequests,
    },
    {
      dataIndex: 'email',
      label: 'Email',
      sortable: true,
      hidden: !!userRequests,
    },
    {
      dataIndex: 'requestedDate',
      label: 'Requested Date',
      align: 'center',
      render: (date) => (date ? getFormattedDate(date) : EMPTY_STRING_VALUE),
    },
    {
      label: 'Action',
      align: 'center',
      render: (_: any, record: UserEmployerAccessRequest) =>
        record.status === CompanyAccessRequestStatus.Pending &&
        (userRequests ? (
          <Button
            size={'small'}
            onClick={handleCancelButtonClick(record)}
            variant={'outlined'}
            className={classNames(commonStyles.dangerButtonOutlined)}
          >
            {'Cancel'}
          </Button>
        ) : (
          <Grid container={true} spacing={2} justifyContent={'center'}>
            <Grid {...defaultGridItemProps} sm={'auto'}>
              <Button size={'small'} onClick={handleApproveButtonClick(record)} variant={'outlined'}>
                {'Approve'}
              </Button>
            </Grid>
            <Grid {...defaultGridItemProps} sm={'auto'}>
              <Button
                size={'small'}
                onClick={handleRejectButtonClick(record)}
                variant={'outlined'}
                className={classNames(commonStyles.dangerButtonOutlined)}
              >
                {'Reject'}
              </Button>
            </Grid>
          </Grid>
        )),
    },
  ];

  return (
    <>
      <Grid {...defaultGridContainerProps} justifyContent={'center'}>
        {error ? (
          <Grid {...defaultGridItemProps}>
            <Alert severity={'error'} className={commonStyles.alert}>
              {error}
            </Alert>
          </Grid>
        ) : (
          <>
            <Grid {...defaultGridItemProps}>
              <Table
                columns={columns}
                list={list}
                sort={sort}
                onSortChange={handleSortChange}
                loading={loading}
                showPagination={true}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Modal
        title={'Cancel Request'}
        loading={loadingModal}
        onClose={handleCancelModalClose}
        open={cancelModalOpen}
        maxWidth={'sm'}
        actions={
          <>
            <Button color={'secondary'} variant={'outlined'} onClick={handleCancelModalClose}>
              {'Close'}
            </Button>
            <Button
              type={'submit'}
              variant={'contained'}
              onClick={handleCancelRequestSubmit}
              className={commonStyles.dangerButtonContained}
            >
              {'Confirm Action'}
            </Button>
          </>
        }
      >
        <p className={commonStyles.text}>{'Are you sure you want to cancel request?'}</p>
      </Modal>
      <Modal
        title={'Approve Request'}
        loading={loadingModal}
        open={approveModalOpen}
        onClose={handleApproveModalClose}
        maxWidth={'sm'}
        actions={
          <>
            <Button color={'secondary'} variant={'outlined'} onClick={handleApproveModalClose}>
              {'Close'}
            </Button>
            <Button type={'submit'} variant={'contained'} onClick={handleApproveRequestSubmit}>
              {'Accept'}
            </Button>
          </>
        }
      >
        <p className={commonStyles.text}>{'Are you sure you want to approve request?'}</p>
      </Modal>
      <Modal
        title={'Reject Request'}
        loading={loadingModal}
        open={rejectModalOpen}
        onClose={handleRejectModalClose}
        maxWidth={'sm'}
        actions={
          <>
            <Button color={'secondary'} variant={'outlined'} onClick={handleRejectModalClose}>
              {'Close'}
            </Button>
            <Button
              type={'submit'}
              variant={'contained'}
              onClick={handleRejectRequestSubmit}
              className={commonStyles.dangerButtonContained}
            >
              {'Confirm Action'}
            </Button>
          </>
        }
      >
        <p className={commonStyles.text}>{'Are you sure you want to reject request?'}</p>
      </Modal>
    </>
  );
};
export default UserEmployerTable;
