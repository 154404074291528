import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import Card from 'components/shared/Card';
import { Icon } from '@mui/material';
import { materialOutlinedIconsClassName } from 'util/Layout';
import classNames from 'classnames';

import styles from './HomeNavigationCard.module.scss';

interface HomeNavigationCardProps {
  title: string;
  icon: string;
  path: string;
}

const HomeNavigationCard: React.FunctionComponent<HomeNavigationCardProps> = ({ title, icon, path }) => {
  const { push } = useHistory();

  const handleClick = useCallback(() => {
    const isAbsolutePath = /^https?:\/\/|^\/\//;
    if (isAbsolutePath.test(path)) {
      window.location.href = path;
    } else {
      push(path);
    }
  }, [path, push]);

  return (
    <Card className={styles.card} contentClassName={styles.content} onClick={handleClick}>
      {
        <Icon color={'primary'} className={classNames(materialOutlinedIconsClassName, styles.icon)}>
          {icon}
        </Icon>
      }
      <h2 className={styles.title}>{title}</h2>
    </Card>
  );
};
export default HomeNavigationCard;
