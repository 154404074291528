import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { useSnackbar } from 'notistack';
import React, { Reducer, useCallback, useEffect, useReducer, useState } from 'react';
import Table from 'components/shared/Table';
import { getFormattedDate, NUMBER_DATE_FORMAT } from 'util/Format';
import { getPrice } from 'util/Payment';
import { SortConfig, TableColumn } from 'store/types/Table';
import DonationPayment from 'store/types/DonationPayment';
import DonationService from 'services/api/DonationService';
import UseRequestData from 'store/types/UseRequestData';
import Modal from 'components/shared/Modal';
import reducer, {
  initialState,
  DonationPaymentsTableAction,
  DonationPaymentsTableActionType,
  DonationPaymentsTableState,
} from './DonationPaymentsTableReducer';
import { Avatar, Button } from '@mui/material';
import { defaultSnackbarErrorProps } from 'util/Layout';

import commonStyles from 'styles/common.module.scss';
import styles from './DonationPaymentsTable.module.scss';

const DonationPaymentsTable: React.FunctionComponent<UseRequestData<DonationPayment[]>> = ({
  data = [],
  loading,
  refetch,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [{ sort, list = [], clickedItem }, dispatch] = useReducer<
    Reducer<DonationPaymentsTableState, DonationPaymentsTableAction>
  >(reducer, initialState);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      dispatch({
        type: DonationPaymentsTableActionType.SetInitialList,
        payload: { initialList: data },
      });
    }
  }, [data]);

  const handleSortChange = useCallback((newSort: SortConfig) => {
    dispatch({ type: DonationPaymentsTableActionType.UpdateSort, payload: { sort: newSort } });
  }, []);

  const handleRemoveConfirm = () => {
    if (clickedItem) {
      setSubmitLoading(true);
      DonationService.cancelRecurringDonation(clickedItem.itemId)
        .then(() => {
          dispatch({
            type: DonationPaymentsTableActionType.CloseAction,
            payload: {},
          });
          setSubmitLoading(false);
          refetch();
        })
        .catch((errorMessage: string) => {
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
          setSubmitLoading(false);
        });
    }
  };

  const handleConfirmModalClose = useCallback(() => {
    dispatch({
      type: DonationPaymentsTableActionType.CloseAction,
      payload: {},
    });
  }, []);

  const handleRemoveClick = useCallback(
    (item: DonationPayment) => () => {
      dispatch({
        type: DonationPaymentsTableActionType.OpenAction,
        payload: { clickedItem: item },
      });
    },
    []
  );

  const columns: Array<TableColumn<DonationPayment>> = [
    {
      dataIndex: 'logoUrl',
      label: '',
      render: (logoUrl: string) => (
        <Avatar src={logoUrl} variant={'square'} className={styles.logo}>
          <AccountBalanceIcon />
        </Avatar>
      ),
    },
    { dataIndex: 'campaignName', label: 'Campaign Name', sortable: true },
    {
      dataIndex: 'recurrentPeriod',
      label: 'Plan',
    },
    {
      dataIndex: 'nextBillingDate',
      label: 'Next Donation',
      render: (nextBillingDate: string) => <span>{getFormattedDate(nextBillingDate, NUMBER_DATE_FORMAT)}</span>,
      sortable: true,
    },
    { dataIndex: 'paymentMethodName', label: 'Payment Method', sortable: true },
    {
      dataIndex: 'amount',
      label: 'Amount',
      render: (amount: number) => <span>{getPrice(amount)}</span>,
    },
    { dataIndex: 'paymentFailedMessage', label: 'Error Message', sortable: true },
    {
      key: 'action',
      label: 'Status',
      render: (_: any, record: DonationPayment) => (
        <Button variant={'text'} className={commonStyles.link} onClick={handleRemoveClick(record)}>
          {'Remove'}
        </Button>
      ),
    },
  ];

  return (
    <>
      <Table columns={columns} list={list} sort={sort} onSortChange={handleSortChange} loading={loading} />
      {clickedItem && (
        <Modal
          title={'Remove Recurring Donation Confirmation'}
          open={!!clickedItem}
          maxWidth={'sm'}
          loading={submitLoading}
          onClose={handleConfirmModalClose}
          actions={
            <Button
              type={'submit'}
              disabled={submitLoading}
              onClick={handleRemoveConfirm}
              variant={'contained'}
              className={commonStyles.dangerButtonContained}
            >
              {'Remove'}
            </Button>
          }
        >
          <p className={commonStyles.text}>{'You are about to remove your recurring donation.'}</p>
          <p className={commonStyles.text}>
            {'If you remove this recurrence you will need to set up a new donation to make a payment to this campaign.'}
          </p>
        </Modal>
      )}
    </>
  );
};
export default DonationPaymentsTable;
