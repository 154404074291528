import { Button, ButtonProps, Collapse, Grid } from '@mui/material';
import EventLocationRow from 'components/events/EventInfoRow/EventLocationRow';
import EventTimeRow from 'components/events/EventInfoRow/EventTimeRow';
import Card from 'components/shared/Card';
import EventDateRow from 'components/events/EventInfoRow/EventCalendarIcon';
import StatusLabel from 'components/shared/StatusLabel';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import eventModeConfig from 'store/configs/EventModeConfig';
import { getFormattedEventDate, getEventModeIcon, getFormattedEventTime } from 'util/Event';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import routes from 'store/configs/Routes';
import { getHashRouteUrl } from 'util/Route';
import UpdateEventHotelPreferencesModal from 'components/events/UpdateEventHotelPreferencesModal';
import { TableColumn } from 'store/types/Table';
import Table from 'components/shared/Table';
import { EventSessionView } from 'store/types/events/Event';
import { EMPTY_STRING_VALUE } from 'util/Format';
import { ConfigContext } from 'components/ConfigGuard';
import SiteModule from 'store/enums/SiteModule';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { MainEventRegistrationView } from 'store/types/events/EventRegistration';
import moment from 'moment';
import { UserContext } from 'components/UserGuard';

import styles from './EventRegistrationCard.module.scss';
import commonStyles from 'styles/common.module.scss';

interface EventRegistrationCardProps {
  data: MainEventRegistrationView;
}

const actionButtonProps: ButtonProps = {
  size: 'small',
  color: 'primary',
  variant: 'contained',
  fullWidth: true,
  className: styles.actionButton,
};

const EventRegistrationCard: React.FunctionComponent<EventRegistrationCardProps> = ({ data }) => {
  const {
    id,
    title,
    mode,
    status,
    startDate,
    endDate,
    sessions,
    isGuest,
    isPreferencesCollected,
    isHotelInformationCollected,
    isHotelInformationCollectedForGuest,
    isPreferencesCollectedForGuest,
    hotelInformationEditEndDate,
    preferencesEditEndDate,
    registrant: { userName },
    primaryRegistrant,
  } = data;

  const currentUser = useContext(UserContext);
  const [collapsed, setCollapsed] = useState<boolean>(!isGuest);
  const { enabledModules } = useContext(ConfigContext);
  const [updateHotelPreferencesModalOpen, setUpdateHotelPreferencesModalOpen] = useState<boolean>(false);
  const isEventDonated: boolean = useMemo(
    () => !isGuest && primaryRegistrant.id !== currentUser.id,
    [currentUser.id, isGuest, primaryRegistrant.id]
  );

  const handleUpdateHotelPreferencesModalOpen = useCallback(() => {
    setUpdateHotelPreferencesModalOpen(true);
  }, []);

  const handleUpdateHotelPreferencesModalClose = useCallback(() => {
    setUpdateHotelPreferencesModalOpen(false);
  }, []);

  const isPreferencesEditable: boolean = useMemo(
    () =>
      !!(isGuest ? isPreferencesCollectedForGuest && isPreferencesCollected : isPreferencesCollected) &&
      (moment(preferencesEditEndDate).isSameOrAfter(moment()) || !preferencesEditEndDate),
    [isGuest, isPreferencesCollected, isPreferencesCollectedForGuest, preferencesEditEndDate]
  );

  const isHotelInformationEditable: boolean = useMemo(
    () =>
      !!(isGuest ? isHotelInformationCollectedForGuest && isHotelInformationCollected : isHotelInformationCollected) &&
      (moment(hotelInformationEditEndDate).isSameOrAfter(moment()) || !hotelInformationEditEndDate),
    [hotelInformationEditEndDate, isGuest, isHotelInformationCollected, isHotelInformationCollectedForGuest]
  );

  const hidePreferencesButton: boolean = useMemo(
    () =>
      (!isHotelInformationCollected && !isPreferencesCollected) ||
      (!!isGuest && !isHotelInformationCollectedForGuest && !isPreferencesCollectedForGuest),
    [
      isGuest,
      isHotelInformationCollected,
      isHotelInformationCollectedForGuest,
      isPreferencesCollected,
      isPreferencesCollectedForGuest,
    ]
  );

  const columns: Array<TableColumn<EventSessionView>> = [
    {
      dataIndex: 'title',
      label: 'Session Name',
    },
    {
      dataIndex: 'track',
      label: 'Track',
    },
    {
      dataIndex: 'startDate',
      label: 'Date',
      render: (_: any, { startDate, endDate }: EventSessionView) => getFormattedEventDate(startDate, endDate),
    },
    {
      label: 'Time',
      align: 'center',
      render: (_: any, { startDate, endDate, timeZoneName }: EventSessionView) =>
        endDate && startDate.isSame(endDate, 'days')
          ? getFormattedEventTime(startDate, endDate, timeZoneName)
          : EMPTY_STRING_VALUE,
    },
  ];

  const handleCollapse = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  return (
    <>
      {isGuest && (
        <div className={styles.guestLabel}>
          <span>{`Guest: ${userName}`}</span>
          <Button size={'small'} onClick={handleCollapse} variant={'contained'}>
            {collapsed ? <ExpandLess /> : <ExpandMore />}
          </Button>
        </div>
      )}
      <Collapse in={collapsed} unmountOnExit={true}>
        <Card contentClassName={styles.cardContent} className={styles.card}>
          <Grid {...defaultGridContainerProps}>
            <Grid {...defaultGridItemProps} lg={2} md={3}>
              <div className={styles.leftSection}>
                <div className={styles.wrapper}>
                  {getEventModeIcon(mode, styles.typeIcon)}
                  <span className={styles.typeLabel}>{eventModeConfig[mode]?.name}</span>
                  <StatusLabel theme={'green'} status={status} className={styles.status} />
                </div>
              </div>
            </Grid>
            <Grid {...defaultGridItemProps} lg={8} md={9}>
              <h2 className={styles.title}>{title}</h2>
              {isEventDonated && (
                <h3 className={commonStyles.subTitle}>{`Registered By ${primaryRegistrant.userName}`}</h3>
              )}
              <div className={styles.infoSection}>
                <Grid {...defaultGridItemProps} sm={'auto'}>
                  <EventDateRow data={data} className={styles.infoSectionRow} />
                  {endDate && startDate.isSame(endDate, 'days') && (
                    <EventTimeRow data={data} className={styles.infoSectionRow} />
                  )}
                </Grid>
                <EventLocationRow data={data} className={styles.infoSectionRow} />
              </div>
              {!!sessions?.length && <Table columns={columns} list={sessions} className={styles.table} />}
            </Grid>
            <Grid {...defaultGridItemProps} lg={2} md={12} className={styles.rightSection}>
              {!isGuest && (
                <Button
                  {...actionButtonProps}
                  variant={'outlined'}
                  href={getHashRouteUrl(`${routes.eventDetails}/${id}`)}
                >
                  {'Event Details'}
                </Button>
              )}
              {!hidePreferencesButton && (
                <Button {...actionButtonProps} variant={'contained'} onClick={handleUpdateHotelPreferencesModalOpen}>
                  {isPreferencesEditable || isHotelInformationEditable ? 'Update Preferences' : 'View Preferences'}
                </Button>
              )}
              {enabledModules.includes(SiteModule.PersonalPayments) && !isGuest && !isEventDonated && (
                <Button {...actionButtonProps} variant={'outlined'} href={getHashRouteUrl(routes.personalPayments)}>
                  {'View Receipt'}
                </Button>
              )}
            </Grid>
          </Grid>
        </Card>
      </Collapse>
      {updateHotelPreferencesModalOpen && (
        <UpdateEventHotelPreferencesModal
          event={data}
          open={updateHotelPreferencesModalOpen}
          onClose={handleUpdateHotelPreferencesModalClose}
        />
      )}
    </>
  );
};
export default EventRegistrationCard;
