import { HttpService } from 'services/HttpService';
import { API_URL, tenantConfig } from 'config';
import AuthService from 'services/AuthService';
import { httpRequestWrapper } from 'util/Request';
import Subscription, { SubscriptionPlan } from 'store/types/Subscription';
import Donation from 'store/types/Donation';

type SubscriptionsResponseData = Subscription[];
type SubscriptionPlansResponseData = SubscriptionPlan[];
type SubscriptionDonationsResponseData = Donation[];

class SubscriptionService {
  public static getSubscriptions(companyId: string[] = []): Promise<SubscriptionsResponseData> {
    return new Promise<SubscriptionsResponseData>(
      (resolve: (data: SubscriptionsResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<SubscriptionsResponseData>(
          HttpService.get(`${API_URL}/subscriptions`, {
            queryParams: { companyId },
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static updateAutoRenewal(subscriptionId: string, autoRenewal: boolean): Promise<null> {
    return new Promise((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.put(`${API_URL}/subscriptions/${subscriptionId}/autorenew`, {
          data: { autoRenewal },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getSubscriptionPlans(): Promise<SubscriptionPlansResponseData> {
    return new Promise<SubscriptionPlansResponseData>(
      (resolve: (data: SubscriptionPlansResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<SubscriptionPlansResponseData>(
          HttpService.get(`${API_URL}/subscriptions/plans`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getSubscriptionPlan(subscriptionId: string): Promise<SubscriptionPlan> {
    return new Promise<SubscriptionPlan>(
      (resolve: (data: SubscriptionPlan) => void, reject: (error: string) => void) => {
        httpRequestWrapper<SubscriptionPlan>(
          HttpService.get(`${API_URL}/subscriptions/${subscriptionId}/plan`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getDonations(): Promise<SubscriptionDonationsResponseData> {
    return new Promise<SubscriptionDonationsResponseData>(
      (resolve: (data: SubscriptionDonationsResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<SubscriptionDonationsResponseData>(
          HttpService.get(`${API_URL}/subscriptions/donations`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }
}
export default SubscriptionService;
