import React, { ChangeEvent, useCallback } from 'react';
import { FormControl, FormGroup, Checkbox, FormControlLabel, FormHelperText } from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { FormProps, getValidationProps } from 'util/Form';
import { ApplicationQuestionView } from 'store/types/ApplicationQuestion';
import { getCheckboxFieldName } from 'util/Application';
import ExplanationQuestionFormItem from 'components/applications/ApplicationQuestionsFormSection/ExplanationQuestionFormItem';
import { SelectQuestionOption } from 'store/types/SelectOption';

import styles from './CheckboxQuestionFormItem.module.scss';

interface CheckboxQuestionFormItemProps extends FormProps {
  question: ApplicationQuestionView;
}

const CheckboxQuestionFormItem: React.FunctionComponent<CheckboxQuestionFormItemProps> = ({ question, disabled }) => {
  const { required, fieldName } = question;
  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext<{ [fieldName: string]: SelectQuestionOption[] }>();
  const { fields, update } = useFieldArray({ control, name: fieldName });
  const resultFieldName = getCheckboxFieldName(fieldName);
  const { error = false, helperText = '' } = getValidationProps(resultFieldName, errors);

  const handleChange = useCallback(
    (onChange, index) => (e: ChangeEvent<HTMLInputElement>) => {
      const fieldValues = getValues()[fieldName][index];
      const value = e.target.checked;

      update(index, { ...fieldValues, value });
      onChange(value);
    },
    [fieldName, getValues, update]
  );

  return (
    <>
      <FormControl component={'fieldset'} required={required} error={error} disabled={disabled} fullWidth={true}>
        <FormGroup>
          {fields.map(({ id, name = '', explanation, includeExplanation, value }, index) => (
            <React.Fragment key={id}>
              <FormControlLabel
                label={name}
                control={
                  <>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <Checkbox
                          color={'primary'}
                          size={'small'}
                          checked={value}
                          value={value}
                          onChange={handleChange(onChange, index)}
                        />
                      )}
                      control={control}
                      name={`${fieldName}[${index}].value`}
                    />
                  </>
                }
              />
              {includeExplanation && value && (
                <div className={styles.explanationField}>
                  <ExplanationQuestionFormItem
                    parentFieldName={fieldName}
                    checkboxField={`${fieldName}.${index}.explanation`}
                    required={true}
                    defaultValue={explanation}
                    disabled={disabled}
                  />
                </div>
              )}
            </React.Fragment>
          ))}
        </FormGroup>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </>
  );
};
export default CheckboxQuestionFormItem;
