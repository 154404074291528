import { Grid } from '@mui/material';
import React, { useCallback, useContext, useMemo } from 'react';
import PageCard from 'components/shared/PageCard';
import ComponentProps from 'store/types/ComponentProps';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import CommunityInfoSection from 'components/communities/CommunityInfoSection';
import CommunityFilesSection from 'components/communities/CommunityFilesSection';
import CommunityMembersSection from 'components/communities/CommunityMembersSection';
import ChapterService from 'services/api/ChapterService';
import { hasChapterAdminRole } from 'util/User';
import { JoinedChapter } from 'store/types/Community';
import Tile from 'components/shared/Tile';
import ChapterReportsSection from 'components/communities/ChapterReportsSection';
import useRequest from 'hooks/useRequest';
import { ConfigContext } from 'components/ConfigGuard';

import styles from './ChapterDetailsCard.module.scss';

interface ChapterDetailsCardProps extends ComponentProps {
  data: JoinedChapter;
}

const fileRequests = {
  uploadFileRequest: ChapterService.uploadChapterFile,
  downloadFileRequest: ChapterService.getChapterFile,
  deleteFileRequest: ChapterService.deleteChapterFile,
};
const membersRequests = {
  getUserRequest: ChapterService.getChapterMember,
  downloadRosterRequest: ChapterService.getChapterMembersRoster,
};
// const eventsRequests = {
//   createEventRequest: ChapterService.createChapterEvent,
//   updateEventRequest: ChapterService.updateChapterEvent,
//   deleteEventRequest: ChapterService.deleteChapterEvent,
//   downloadEventFileRequest: ChapterService.getChapterEventFile,
// };

const ChapterDetailsCard: React.FunctionComponent<ChapterDetailsCardProps> = ({ data, className = '' }) => {
  const { member, name, id, completedFees = 0, remainingFees = 0, roles } = data;
  const detailsRequestFn = useCallback(() => ChapterService.getChapterDetails(id), [id]);
  const detailsRequest = useRequest(detailsRequestFn);
  const { data: detailsData } = detailsRequest;
  const { isAcsTheme } = useContext(ConfigContext);
  const hasAdminRole: boolean = useMemo(() => hasChapterAdminRole(member), [member]);

  const infoSection: React.ReactNode = <CommunityInfoSection data={data} />;
  // const eventsSection: React.ReactNode = (
  //   <CommunityEventsSection
  //     {...detailsRequest}
  //     data={detailsData ? detailsData.events : undefined}
  //     requests={eventsRequests}
  //     communityId={id}
  //     editEnabled={hasAdminRole}
  //   />
  // );
  const filesSection: React.ReactNode = (
    <CommunityFilesSection
      {...detailsRequest}
      data={detailsData ? detailsData.files : undefined}
      communityId={id}
      editEnabled={hasAdminRole}
      description={'Documentation uploaded by Chapter Chairs.'}
      requests={fileRequests}
    />
  );
  const membersSection: React.ReactNode = (
    <CommunityMembersSection
      {...detailsRequest}
      data={detailsData ? detailsData.members : undefined}
      roles={roles}
      communityName={name}
      requests={membersRequests}
      communityId={id}
      downloadRosterEnabled={hasAdminRole}
      extendedTableView={hasAdminRole}
      description={isAcsTheme ? 'The list of dues paying chapter members.' : 'The list of chapter members.'}
    />
  );

  return (
    <PageCard title={name} className={className} headerClassName={styles.cardHeader}>
      <Grid {...defaultGridContainerProps} className={styles.container}>
        {hasAdminRole ? (
          <>
            <Grid {...defaultGridItemProps} md={6}>
              <Grid {...defaultGridContainerProps}>
                {!isAcsTheme && (
                  <>
                    <Grid {...defaultGridItemProps} sm={6}>
                      <Tile
                        theme={'dark-grey'}
                        value={`$${completedFees}`}
                        label={'Chapter fees collected to date'}
                        classes={{ value: styles.value, label: styles.label }}
                      />
                    </Grid>
                    <Grid {...defaultGridItemProps} sm={6}>
                      <Tile
                        value={`$${remainingFees}`}
                        label={'Remaining fees payable to chapter'}
                        classes={{ value: styles.value, label: styles.label }}
                      />
                    </Grid>
                  </>
                )}
                <Grid {...defaultGridItemProps}>
                  <ChapterReportsSection data={data} />
                </Grid>
              </Grid>
            </Grid>
            <Grid {...defaultGridItemProps} md={6}>
              {infoSection}
            </Grid>
            <Grid {...defaultGridItemProps}>{membersSection}</Grid>
            {/*Hidden due PBI-18379-Hide entire upcoming meetings section from Chapters and Committees*/}
            {/*<Grid {...defaultGridItemProps} md={6}>*/}
            {/*  {eventsSection}*/}
            {/*</Grid>*/}
            <Grid {...defaultGridItemProps} md={6}>
              {filesSection}
            </Grid>
          </>
        ) : (
          <>
            <Grid {...defaultGridItemProps} md={6}>
              <Grid {...defaultGridContainerProps}>
                <Grid {...defaultGridItemProps}>{infoSection}</Grid>
                {/*Hidden due PBI-18379-Hide entire upcoming meetings section from Chapters and Committees*/}
                {/*<Grid {...defaultGridItemProps}>{eventsSection}</Grid>*/}
              </Grid>
            </Grid>
            <Grid {...defaultGridItemProps} md={6}>
              <Grid {...defaultGridContainerProps}>
                <Grid {...defaultGridItemProps}>{filesSection}</Grid>
                <Grid {...defaultGridItemProps}>{membersSection}</Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </PageCard>
  );
};
export default ChapterDetailsCard;
