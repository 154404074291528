import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
} from '@mui/material';
import Modal from 'components/shared/Modal';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ModalProps } from 'store/types/ComponentProps';
import DonationService from 'services/api/DonationService';
import { defaultFormProps, getMaxLengthValidationRule, getRequiredValidationRule, getValidationProps } from 'util/Form';
import {
  CreateCampaignData,
  DonationCampaign,
  DonationHeaderValues,
  DonationParentCampaign,
} from 'store/types/DonationFund';
import { CreateCampaignFormValues } from 'store/types/FormValues';
import { getISODateString } from 'util/Format';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import DateFormItem from 'components/shared/DateFormItem';
import routes from 'store/configs/Routes';
import CampaignDescriptionFormItem from './CampaignDescriptionFormItem';
import moment from 'moment-timezone';
import { Color, ColorPicker, createColor } from 'mui-color';
import Stepper from 'components/shared/Stepper';
import UploadImageFormItem from './UploadImageFormItem';
import * as MUIcon from '@mui/icons-material';
import DonationHeader from 'components/donation/DonationPaymentPageView/DonationHeader';
import { ConfigContext } from 'components/ConfigGuard';
import { isCampaignParent } from 'util/Donations';
import DonationType from 'store/enums/DonationType';
import classNames from 'classnames';

import commonStyles from 'styles/common.module.scss';
import styles from './CreateCampaignModal.module.scss';

const GOAL_REGEXP = /^[0-9]+(\.[0-9])?([0-9])?$/;
const UNKNOWN_COLOR_FORMAT = 'unknown';

interface CreateCampaignModalProps extends ModalProps {
  donations: DonationCampaign[];
  defaultParentCampaignValue?: string;
  refetch?: () => void;
}

interface CreateCampaignFormValuesView
  extends Omit<CreateCampaignFormValues, 'backgroundColor' | 'parentCampaign' | 'fontColor'> {
  backgroundColor: Color;
  fontColor: Color;
  parentCampaign: string;
}

interface CreateCampaignStepConfigItem {
  value: CreateCampaignStep;
  label: string;
  icon: keyof typeof MUIcon;
}

enum CreateCampaignStep {
  ChooseCampaign = 1,
  AddDetails = 2,
  CampaignGoals = 3,
  DesignElements = 4,
  AdditionalConfiguration = 5,
}

const getStepsConfig = (isParentCampaignAllowed?: boolean): CreateCampaignStepConfigItem[] => {
  const result: CreateCampaignStepConfigItem[] = [
    {
      value: CreateCampaignStep.ChooseCampaign,
      label: 'Name campaign',
      icon: 'PlaylistAdd',
    },
    {
      value: CreateCampaignStep.AddDetails,
      label: 'Add Details',
      icon: 'LibraryBooks',
    },
    {
      value: CreateCampaignStep.CampaignGoals,
      label: 'Campaign Goals',
      icon: 'Flag',
    },
    {
      value: CreateCampaignStep.DesignElements,
      label: 'Design Elements',
      icon: 'Brush',
    },
  ];

  isParentCampaignAllowed &&
    result.push({
      value: CreateCampaignStep.AdditionalConfiguration,
      label: 'Additional Configuration',
      icon: 'SettingsSuggest',
    });

  return result;
};

const getColor = (value?: Color): string =>
  (value &&
    (value?.css?.backgroundColor ||
      (createColor(value)?.format !== UNKNOWN_COLOR_FORMAT && createColor(value)?.css?.backgroundColor))) ||
  '';

const getHeaderPreviewValues = (data: CreateCampaignFormValuesView): DonationHeaderValues => ({
  ...data,
  partner: {
    name: 'Partner',
    id: '',
  },
  fontColor: getColor(data.fontColor),
  backgroundColor: getColor(data.backgroundColor),
  imageUrl: data.image?.length ? URL.createObjectURL(data.image[0]) : '',
  targetDate: getISODateString(moment(data.targetDate)) || '',
  logoUrl: data.logo?.length ? URL.createObjectURL(data.logo[0]) : '',
  name: data.title,
});

const getParentCampaignsList = (list: DonationCampaign[] = []): DonationParentCampaign[] =>
  list
    .filter((campaign) => isCampaignParent(campaign))
    .map(({ id, title, endDate }) => ({
      id,
      endDate,
      name: title,
    }));

const CreateCampaignModal: React.FunctionComponent<CreateCampaignModalProps> = ({
  open,
  onClose,
  refetch,
  donations,
  defaultParentCampaignValue,
}) => {
  const {
    modulesSettings: {
      donations: { predefinedAmounts },
    },
  } = useContext(ConfigContext);
  const [step, setStep] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    modulesSettings: {
      donations: { isParentCampaignAllowed },
    },
  } = useContext(ConfigContext);
  const [previewModalOpen, setPreviewModalOpen] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const parentCampaigns: DonationParentCampaign[] = useMemo(() => getParentCampaignsList(donations), [donations]);
  const form = useForm<CreateCampaignFormValuesView>({
    ...defaultFormProps,
  });
  const {
    handleSubmit,
    formState: { errors, dirtyFields },
    control,
    trigger,
    setValue,
    reset,
    watch,
  } = form;
  const values: CreateCampaignFormValuesView = watch();
  const stepsConfig: CreateCampaignStepConfigItem[] = useMemo(
    () => getStepsConfig(isParentCampaignAllowed),
    [isParentCampaignAllowed]
  );
  const { endDate, startDate, isRecurringDonationAvailable, isSingleDonationAvailable, defaultDonation } = values;
  const previewValues: DonationHeaderValues = useMemo(() => getHeaderPreviewValues(values), [values]);
  const maxEndDate: string = useMemo(
    () => parentCampaigns?.find(({ id }) => id === values.parentCampaign)?.endDate || '',
    [parentCampaigns, values.parentCampaign]
  );

  useEffect(() => {
    if (
      (defaultDonation === DonationType.Single && !isSingleDonationAvailable) ||
      (defaultDonation === DonationType.Recurring && !isRecurringDonationAvailable)
    ) {
      setValue('defaultDonation', undefined);
    }
  }, [defaultDonation, isRecurringDonationAvailable, isSingleDonationAvailable, setValue]);

  useEffect(() => {
    if (dirtyFields.endDate) {
      trigger(['endDate']);
    }
    if (dirtyFields.targetDate) {
      trigger(['targetDate']);
    }
  }, [trigger, dirtyFields, endDate, startDate]);

  const handleClose = useCallback(() => {
    reset();
    onClose();
    setStep(1);
  }, [reset, onClose]);

  const resetForm = useCallback(() => {
    handleClose();
    if (refetch) {
      refetch();
    }
    setLoading(false);
    enqueueSnackbar('Donation Campaign successfully created', { variant: 'success' });
  }, [enqueueSnackbar, handleClose, refetch]);

  const handleCreateCampaign = useCallback(
    (formValues: CreateCampaignFormValuesView) => {
      setLoading(true);
      const { endDate, startDate, targetDate, backgroundColor, logo, image, parentCampaign, fontColor } = formValues;
      const parentCampaignValue: DonationParentCampaign = parentCampaigns?.find(({ id }) => id === parentCampaign) || {
        id: '',
        name: '',
      };
      const data: CreateCampaignData = {
        ...formValues,
        parentCampaign: parentCampaignValue,
        backgroundColor: getColor(backgroundColor),
        fontColor: getColor(fontColor),
        startDate: getISODateString(moment(startDate)) || '',
        endDate: getISODateString(moment(endDate)) || '',
        targetDate: getISODateString(moment(targetDate)) || '',
        donationUrl: `${window.location.origin}/#${routes.makeDonation}`,
      };

      DonationService.createDonationCampaign(data)
        .then((data: DonationCampaign) => {
          const campaignId: string = data.id;
          if (logo?.length) {
            DonationService.uploadCampaignImage(campaignId, logo[0], 'logo')
              .then(() => {
                if (image?.length) {
                  DonationService.uploadCampaignImage(campaignId, image[0], 'image')
                    .then(() => {
                      resetForm();
                    })
                    .catch((error: string) => {
                      setLoading(false);
                      enqueueSnackbar(error, defaultSnackbarErrorProps);
                    });
                } else {
                  resetForm();
                }
              })
              .catch((error: string) => {
                setLoading(false);
                enqueueSnackbar(error, defaultSnackbarErrorProps);
              });
          }
        })
        .catch((error: string) => {
          setLoading(false);
          enqueueSnackbar(error, defaultSnackbarErrorProps);
        });
    },
    [enqueueSnackbar, resetForm, parentCampaigns]
  );

  const submitButtonDisabled: boolean = useMemo(() => {
    const {
      endDate,
      startDate,
      title,
      goal,
      description,
      backgroundColor,
      parentCampaign,
      targetDate,
      logo,
      isRecurringDonationAvailable,
      isSingleDonationAvailable,
      defaultDonation,
      preDefinedDonationAmount,
      fontColor,
    }: CreateCampaignFormValuesView = values;

    switch (step) {
      case CreateCampaignStep.ChooseCampaign:
        return !title || (!isParentCampaignAllowed && !parentCampaign);
      case CreateCampaignStep.AddDetails:
        return (
          !description ||
          !endDate ||
          !startDate ||
          !moment(endDate).isSameOrAfter(startDate) ||
          moment(endDate).isAfter(maxEndDate)
        );
      case CreateCampaignStep.CampaignGoals:
        return !goal || !moment(targetDate).isSameOrBefore(endDate) || !targetDate;
      case CreateCampaignStep.DesignElements:
        return !getColor(backgroundColor) || !getColor(fontColor) || !logo?.length;
      case CreateCampaignStep.AdditionalConfiguration:
        return (
          !defaultDonation ||
          (!isRecurringDonationAvailable && !isSingleDonationAvailable) ||
          !preDefinedDonationAmount?.amount1 ||
          !preDefinedDonationAmount?.amount2 ||
          !preDefinedDonationAmount?.amount3
        );
      default:
        return true;
    }
  }, [isParentCampaignAllowed, maxEndDate, step, values]);

  const currentStepConfigIndex: number = useMemo(
    () => stepsConfig.findIndex(({ value }) => step === value),
    [step, stepsConfig]
  );

  const handleNextStep = useCallback(() => {
    const newStepIndex: number = currentStepConfigIndex + 1;

    if (currentStepConfigIndex >= 0) {
      const nextStep: CreateCampaignStep = stepsConfig[newStepIndex].value;

      setStep(nextStep);
    }
  }, [currentStepConfigIndex, stepsConfig]);

  const handlePrevStep = useCallback(() => {
    const newStepIndex: number = currentStepConfigIndex - 1;

    if (currentStepConfigIndex > 0) {
      const prevStep: CreateCampaignStep = stepsConfig[newStepIndex].value;

      setStep(prevStep);
    }
  }, [currentStepConfigIndex, stepsConfig]);

  const handleChange = useCallback((onChange) => (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.checked), []);

  const handlePreviewModalOpen = useCallback(() => {
    setPreviewModalOpen(true);
  }, []);

  const handlePreviewModalClose = useCallback(() => {
    setPreviewModalOpen(false);
  }, []);

  const handleInputChange = useCallback(
    (onChange) =>
      (e: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const amountValue: string = e.target.value.replace(/\D/g, '') || '';

        onChange(amountValue ? parseInt(amountValue, 10) : 0);
      },
    []
  );

  return (
    <>
      <FormProvider {...form}>
        <Modal
          loading={loading}
          open={open}
          title={'Create Campaign'}
          onClose={handleClose}
          maxWidth={'lg'}
          actions={
            <>
              <Button color={'secondary'} variant={'outlined'} onClick={step === 1 ? handleClose : handlePrevStep}>
                {step === 1 ? 'Close' : 'Back'}
              </Button>
              <div className={styles.buttonsWrapper}>
                {step === 4 && (
                  <Button
                    color={'secondary'}
                    variant={'outlined'}
                    onClick={handlePreviewModalOpen}
                    className={styles.previewButton}
                  >
                    {'Preview'}
                  </Button>
                )}
                <Button
                  color={'secondary'}
                  variant={'contained'}
                  disabled={submitButtonDisabled}
                  onClick={
                    step === CreateCampaignStep.AdditionalConfiguration ||
                    (!isParentCampaignAllowed && step === CreateCampaignStep.DesignElements)
                      ? handleSubmit(handleCreateCampaign)
                      : handleNextStep
                  }
                >
                  {step === CreateCampaignStep.AdditionalConfiguration ||
                  (!isParentCampaignAllowed && step === CreateCampaignStep.DesignElements)
                    ? 'Submit'
                    : 'Next'}
                </Button>
              </div>
            </>
          }
        >
          <Grid {...defaultGridContainerProps}>
            <Grid {...defaultGridItemProps} className={styles.stepper}>
              <Stepper activeStep={step} config={stepsConfig} />
            </Grid>
          </Grid>
          {step === 1 && (
            <>
              <Grid {...defaultGridContainerProps} spacing={2}>
                <Grid {...defaultGridItemProps}>
                  <h1 className={styles.title}>{'Let’s create a campaign...'}</h1>
                </Grid>
                {!isParentCampaignAllowed && (
                  <Grid {...defaultGridItemProps}>
                    <h3 className={commonStyles.subTitle}>{'Choose Campaign'}</h3>
                    {parentCampaigns?.length ? (
                      <Controller
                        render={({ field }) => (
                          <TextField
                            {...field}
                            {...getValidationProps('parentCampaign', errors)}
                            select={true}
                            label={'Parent Campaign'}
                          >
                            {parentCampaigns.map(({ id, name }: DonationParentCampaign) => (
                              <MenuItem value={id} key={`parent-campaign-${id}`}>
                                {name}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                        defaultValue={defaultParentCampaignValue || ''}
                        name={'parentCampaign'}
                        control={control}
                        rules={{ required: getRequiredValidationRule('parent campaign', true) }}
                      />
                    ) : (
                      <Alert severity={'error'} className={commonStyles.alert}>
                        {'No campaigns available'}
                      </Alert>
                    )}
                  </Grid>
                )}
                <Grid {...defaultGridItemProps}>
                  <h3 className={commonStyles.subTitle}>{'Campaign Name'}</h3>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        {...getValidationProps('title', errors)}
                        placeholder={'Campaign name'}
                        required={true}
                      />
                    )}
                    defaultValue={''}
                    name={'title'}
                    control={control}
                    rules={{
                      required: getRequiredValidationRule('title', false, true),
                      maxLength: getMaxLengthValidationRule(55),
                    }}
                  />
                </Grid>
              </Grid>
            </>
          )}
          {step === 2 && (
            <Grid {...defaultGridContainerProps} spacing={2}>
              <Grid {...defaultGridItemProps}>
                <h1 className={styles.title}>{'Add a description of your campaign'}</h1>
              </Grid>
              <Grid {...defaultGridItemProps}>
                <h3 className={commonStyles.subTitle}>{'Campaign description and details'}</h3>
                <CampaignDescriptionFormItem label={'Campaign description'} fieldName={'description'} />
              </Grid>
              <Grid {...defaultGridItemProps}>
                <h1 className={styles.title}>{'Campaign Length'}</h1>
              </Grid>
              <Grid {...defaultGridItemProps} sm={6}>
                <h3 className={commonStyles.subTitle}>{'Start Date'}</h3>
                <DateFormItem
                  fieldName={'startDate'}
                  maxDate={maxEndDate ? moment(maxEndDate) : undefined}
                  minDate={moment(new Date()).startOf('day')}
                  maxDateErrorMessage={'You cannot set the Start Date after the end date of selected parent campaign'}
                  required={true}
                />
              </Grid>
              <Grid {...defaultGridItemProps} sm={6}>
                <h3 className={commonStyles.subTitle}>{'End Date'}</h3>
                <DateFormItem
                  fieldName={'endDate'}
                  maxDate={maxEndDate ? moment(maxEndDate) : undefined}
                  minDate={moment(startDate)}
                  maxDateErrorMessage={'You cannot set the End Date after the end date of selected parent campaign'}
                  required={true}
                />
              </Grid>
              {!isParentCampaignAllowed && (
                <FormHelperText className={styles.helperText}>
                  {
                    'The Start Date and The End Date of the campaign must be before the end date of the selected parent campaign'
                  }
                </FormHelperText>
              )}
            </Grid>
          )}
          {step === 3 && (
            <Grid {...defaultGridContainerProps} spacing={2}>
              <Grid {...defaultGridItemProps}>
                <h1 className={styles.title}>{'Campaign Goals'}</h1>
              </Grid>
              <Grid {...defaultGridItemProps} sm={6}>
                <h3 className={commonStyles.subTitle}>{'Goal'}</h3>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type={'number'}
                      required={true}
                      placeholder={'0'}
                      {...getValidationProps('goal', errors)}
                      InputProps={{
                        startAdornment: <InputAdornment position={'start'}>$</InputAdornment>,
                      }}
                    />
                  )}
                  name={'goal'}
                  defaultValue={0}
                  control={control}
                  rules={{
                    required: getRequiredValidationRule('goal'),
                    maxLength: getMaxLengthValidationRule(300),
                    min: {
                      value: 0.01,
                      message: 'Please input valid goal',
                    },
                    pattern: {
                      value: GOAL_REGEXP,
                      message: 'Please input valid goal',
                    },
                  }}
                />
              </Grid>
              <Grid {...defaultGridItemProps} sm={6}>
                <h3 className={commonStyles.subTitle}>{'Target Date'}</h3>
                <DateFormItem
                  fieldName={'targetDate'}
                  maxDate={moment(endDate)}
                  minDate={moment(startDate)}
                  maxDateErrorMessage={'You cannot set the Target Date after the End Date'}
                  minDateErrorMessage={'You cannot set the Target Date before the Start Date'}
                  required={true}
                />
              </Grid>
              <Grid {...defaultGridItemProps} sm={8}>
                <h3 className={commonStyles.subTitle}>
                  {'Please select below what will be displayed on the campaign page (optional)'}
                </h3>
                <FormControlLabel
                  control={
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <Checkbox color={'primary'} onChange={handleChange(onChange)} checked={value} />
                      )}
                      control={control}
                      name={'displayGoal'}
                    />
                  }
                  label={'Display Goal'}
                />
                <FormControlLabel
                  control={
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <Checkbox color={'primary'} onChange={handleChange(onChange)} checked={value} />
                      )}
                      control={control}
                      name={'displayTargetDate'}
                    />
                  }
                  label={'Display Target Date'}
                />
                <FormControlLabel
                  control={
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <Checkbox color={'primary'} onChange={handleChange(onChange)} checked={value} />
                      )}
                      defaultValue={false}
                      control={control}
                      name={'isDisplayProgress'}
                    />
                  }
                  label={'Display Progress'}
                />
              </Grid>
            </Grid>
          )}
          <Grid
            {...defaultGridContainerProps}
            spacing={2}
            className={classNames({ [commonStyles.hidden]: step !== 4 })}
          >
            <Grid {...defaultGridItemProps}>
              <h1 className={styles.title}>{'Design Options'}</h1>
            </Grid>
            <Grid {...defaultGridItemProps} xs={3}>
              <h3 className={commonStyles.subTitle}>{'Add Logo (1:1 ratio)'}</h3>
              <UploadImageFormItem fieldName={'logo'} label={'Choose Logo'} helperText={'Max size 2mb (jpg, png)'} />
            </Grid>
            <Grid {...defaultGridItemProps} xs={3}>
              <h3 className={commonStyles.subTitle}>{'Add Image (optional)'}</h3>
              <UploadImageFormItem fieldName={'image'} label={'Choose Image'} helperText={'Max size 2mb (jpg, png)'} />
            </Grid>
            <Grid {...defaultGridItemProps} xs={12}>
              <Controller
                render={({ field: { value, onChange } }) => (
                  <>
                    <h3 className={commonStyles.subTitle}>{'Background Color'}</h3>
                    <ColorPicker onChange={onChange} value={value} defaultValue={'#3C73AE'} disablePlainColor={true} />
                  </>
                )}
                defaultValue={'#3C73AE' as any}
                name={'backgroundColor'}
                control={control}
                rules={{ required: getRequiredValidationRule('backgroundColor', true) }}
              />
            </Grid>
            <Grid {...defaultGridItemProps} xs={12}>
              <Controller
                render={({ field: { value, onChange } }) => (
                  <>
                    <h3 className={commonStyles.subTitle}>{'Font Color'}</h3>
                    <ColorPicker onChange={onChange} value={value} defaultValue={'#FFFFFF'} disablePlainColor={true} />
                  </>
                )}
                defaultValue={'#FFFFFF' as any}
                name={'fontColor'}
                control={control}
                rules={{ required: getRequiredValidationRule('backgroundColor', true) }}
              />
            </Grid>
          </Grid>
          {step === 5 && (
            <Grid {...defaultGridContainerProps} spacing={2}>
              <Grid {...defaultGridItemProps}>
                <h1 className={styles.title}>{'Additional Configuration'}</h1>
              </Grid>
              <Grid {...defaultGridItemProps} xs={4}>
                <h3 className={commonStyles.subTitle}>{'Campaign Amounts'}</h3>
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      value={value}
                      onChange={handleInputChange(onChange)}
                      label={'Default Donation Amount 1'}
                      className={styles.amountInput}
                      InputProps={{
                        startAdornment: <InputAdornment position={'start'}>$</InputAdornment>,
                      }}
                      placeholder={'0'}
                    />
                  )}
                  defaultValue={predefinedAmounts?.length && predefinedAmounts[0]}
                  control={control}
                  name={'preDefinedDonationAmount.amount1'}
                />
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      value={value}
                      onChange={handleInputChange(onChange)}
                      label={'Default Donation Amount 2'}
                      className={styles.amountInput}
                      InputProps={{
                        startAdornment: <InputAdornment position={'start'}>$</InputAdornment>,
                      }}
                      placeholder={'0'}
                    />
                  )}
                  defaultValue={predefinedAmounts?.length && predefinedAmounts[1]}
                  control={control}
                  name={'preDefinedDonationAmount.amount2'}
                />
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      value={value}
                      onChange={handleInputChange(onChange)}
                      label={'Default Donation Amount 3'}
                      className={styles.amountInput}
                      InputProps={{
                        startAdornment: <InputAdornment position={'start'}>$</InputAdornment>,
                      }}
                      placeholder={'0'}
                    />
                  )}
                  defaultValue={predefinedAmounts?.length && predefinedAmounts[2]}
                  control={control}
                  name={'preDefinedDonationAmount.amount3'}
                />
                <FormControlLabel
                  control={
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <Checkbox color={'primary'} onChange={handleChange(onChange)} checked={value} />
                      )}
                      defaultValue={false}
                      control={control}
                      name={'isOtherDonationAmountAvailable'}
                    />
                  }
                  label={'Allow donor to add Other amount'}
                />
              </Grid>
              <Grid {...defaultGridItemProps} xs={8}>
                <h3 className={commonStyles.subTitle}>{'Campaign Type'}</h3>
                <FormHelperText>{'At least one of the types must be selected'}</FormHelperText>
                <FormControlLabel
                  control={
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <Checkbox color={'primary'} onChange={handleChange(onChange)} checked={value} />
                      )}
                      defaultValue={false}
                      control={control}
                      name={'isSingleDonationAvailable'}
                    />
                  }
                  label={'Allow single donations'}
                />
                <FormControlLabel
                  control={
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <Checkbox color={'primary'} onChange={handleChange(onChange)} checked={value} />
                      )}
                      defaultValue={false}
                      control={control}
                      name={'isRecurringDonationAvailable'}
                    />
                  }
                  label={'Allow recurring donations'}
                />
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      {...getValidationProps('defaultDonation', errors)}
                      select={true}
                      label={'Default Donation Type'}
                    >
                      <MenuItem
                        value={DonationType.Single}
                        key={DonationType.Single}
                        disabled={!isSingleDonationAvailable}
                      >
                        {DonationType.Single}
                      </MenuItem>
                      <MenuItem
                        value={DonationType.Recurring}
                        key={DonationType.Recurring}
                        disabled={!isRecurringDonationAvailable}
                      >
                        {DonationType.Recurring}
                      </MenuItem>
                    </TextField>
                  )}
                  defaultValue={''}
                  name={'defaultDonation'}
                  control={control}
                  rules={{ required: getRequiredValidationRule('defaultDonation', true) }}
                />
              </Grid>
            </Grid>
          )}
        </Modal>
      </FormProvider>
      <Modal
        open={previewModalOpen}
        title={'Preview'}
        onClose={handlePreviewModalClose}
        maxWidth={'xl'}
        actions={
          <Button color={'secondary'} variant={'outlined'} onClick={handlePreviewModalClose}>
            {'Close'}
          </Button>
        }
      >
        <DonationHeader data={previewValues} />
      </Modal>
    </>
  );
};
export default CreateCampaignModal;
