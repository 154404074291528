import { SortConfig } from 'store/types/Table';
import { sorter, userFullNameSortLabel } from 'util/Table';
import PaymentUser from 'store/types/PaymentUser';
import PaymentUserAccessLevel from 'store/enums/PaymentUserAccessLevel';

export enum PaymentAccessTableActionType {
  SetInitialList = 'SetInitialList',
  SetSubmitLoading = 'SetSubmitLoading',
  UpdateSort = 'UpdateSort',
  OpenConfirmationModal = 'OpenConfirmationModal',
  CloseConfirmationModal = 'CloseConfirmationModal',
}

export type TablePaymentUser = PaymentUser & { [userFullNameSortLabel]?: string };

export interface PaymentAccessTableState {
  initialList: PaymentUser[];
  list: PaymentUser[];
  sort: SortConfig<TablePaymentUser>;
  selectedItem?: PaymentUser;
  submitLoading?: boolean;
  removeModalOpen?: boolean;
  rejectModalOpen?: boolean;
}

export interface PaymentAccessTableAction {
  type: PaymentAccessTableActionType;
  payload: Partial<PaymentAccessTableState>;
}

const getSortedList = (list: PaymentUser[] = [], sort: SortConfig<TablePaymentUser>): PaymentUser[] =>
  list.length ? [...list].sort(sorter<PaymentUser>(sort)) : [...list];

export const initialState: PaymentAccessTableState = {
  initialList: [],
  list: [],
  sort: { direction: 'asc', column: userFullNameSortLabel },
};

const reducer = (
  state: PaymentAccessTableState,
  { type, payload }: PaymentAccessTableAction
): PaymentAccessTableState => {
  if (type === PaymentAccessTableActionType.SetInitialList) {
    const { initialList = [] } = payload;
    const list = getSortedList(
      initialList.filter(({ isApproved, accessLevel }) => !(isApproved && accessLevel === PaymentUserAccessLevel.None)),
      state.sort
    );

    return { ...state, initialList, list };
  }

  if (type === PaymentAccessTableActionType.SetSubmitLoading) {
    const { submitLoading = false } = payload;

    return { ...state, submitLoading };
  }

  if (type === PaymentAccessTableActionType.UpdateSort) {
    const { sort = {} } = payload;

    return {
      ...state,
      sort,
      list: getSortedList(state.initialList, sort),
    };
  }

  if (type === PaymentAccessTableActionType.OpenConfirmationModal) {
    const { removeModalOpen = false, rejectModalOpen = false, selectedItem } = payload;

    return {
      ...state,
      selectedItem,
      removeModalOpen,
      rejectModalOpen,
    };
  }

  if (type === PaymentAccessTableActionType.CloseConfirmationModal) {
    return {
      ...state,
      selectedItem: undefined,
      removeModalOpen: false,
      rejectModalOpen: false,
      submitLoading: false,
    };
  }

  return state;
};

export default reducer;
