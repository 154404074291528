import { HttpService } from 'services/HttpService';
import { API_URL, tenantConfig } from 'config';
import AuthService from 'services/AuthService';
import { httpRequestWrapper } from 'util/Request';
import Announcement from 'store/types/Announcement';

export type AnnouncementsResponseData = Announcement[];

class NewsService {
  public static getAnnouncements(): Promise<AnnouncementsResponseData> {
    return new Promise<AnnouncementsResponseData>(
      (resolve: (data: AnnouncementsResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<AnnouncementsResponseData>(
          HttpService.get(`${API_URL}/announcements`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }
}
export default NewsService;
