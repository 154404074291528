import { Button } from '@mui/material';
import Add from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';
import classNames from 'classnames';
import React from 'react';
import UseRequestData from 'store/types/UseRequestData';
import Subscription from 'store/types/Subscription';
import routes from 'store/configs/Routes';
import { getHashRouteUrl } from 'util/Route';
import PageCard from 'components/shared/PageCard';
import Spinner from 'components/shared/Spinner';
import UserSubscriptionsTable from './UserSubscriptionsTable';

import commonStyles from 'styles/common.module.scss';
import styles from './UserSubscriptionsPageView.module.scss';

const UserSubscriptionsPageView: React.FunctionComponent<UseRequestData<Subscription[]>> = (props) => {
  const { data, error, firstLoading } = props;

  return (
    <Spinner loading={firstLoading} transparent={false}>
      {error ? (
        <Alert severity={'error'} className={commonStyles.alert}>
          {error}
        </Alert>
      ) : data && data.length ? (
        <UserSubscriptionsTable {...props} />
      ) : (
        <PageCard title={'Manage Subscriptions'} className={styles.noDataCard}>
          <p>{'You have no open subscriptions.'}</p>
          <Button
            color={'secondary'}
            variant={'contained'}
            startIcon={<Add />}
            className={classNames(commonStyles.uppercase, styles.addButton)}
            href={getHashRouteUrl(routes.enrollSubscription)}
          >
            {'Buy Now'}
          </Button>
        </PageCard>
      )}
    </Spinner>
  );
};
export default UserSubscriptionsPageView;
