import { AutocompleteRenderInputParams, Button, Grid, InputAdornment, TextField } from '@mui/material';
import Search from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import UserEmployerService from 'services/api/UserEmployerService';
import { UserEmployerAccessRequest } from 'store/types/UserEmployerAccessRequest';
import {
  defaultGridContainerProps,
  defaultGridItemProps,
  defaultSnackbarErrorProps,
  getInputLoadingProps,
} from 'util/Layout';
import { isCanadaSelected, isUSSelected } from 'util/Form';

import styles from './EmployerSearchItem.module.scss';

const MIN_CHARS_COUNT = 3;

interface EmployerSearchItemProps {
  onSelectEmployerId: (id: string) => void;
  companyId?: string;
}

const getEmployerFormattedName = (employer: UserEmployerAccessRequest) => {
  let label = employer.name;

  if (employer.address?.country) {
    label = `${label} ${
      isUSSelected(employer.address.country) || isCanadaSelected(employer.address.country)
        ? `(${employer.address?.city}, ${employer.address?.stateRegion})`
        : `(${employer.address?.city}, ${employer.address?.country})`
    }`;
  }
  return label;
};

const EmployerSearchItem: React.FunctionComponent<EmployerSearchItemProps> = ({ onSelectEmployerId, companyId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<UserEmployerAccessRequest[]>([]);
  const [selectedEmployers, setSelectedEmployers] = useState<UserEmployerAccessRequest[]>([]);

  const searchButtonDisabled = useMemo(
    () => !!selectedEmployers.length || search.length < MIN_CHARS_COUNT || loading,
    [selectedEmployers, search, loading]
  );

  const handleInputChange = useCallback(
    (_: any, value: string) => {
      if (!selectedEmployers.length) {
        setSearch(value);
      }
    },
    [selectedEmployers]
  );

  const handleSearchClick = useCallback(() => {
    setLoading(true);
    UserEmployerService.searchEmployer(search, companyId)
      .then((employers: UserEmployerAccessRequest[] = []) => {
        setLoading(false);
        setOptions(employers);
        setSearch('');
        setOpen(true);
      })
      .catch((errorMessage: string) => {
        setLoading(false);
        enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
      });
  }, [search, companyId, enqueueSnackbar]);

  const searchInputRender = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      placeholder={!selectedEmployers.length ? 'Company Name' : ''}
      InputProps={{
        ...params.InputProps,
        ...(loading
          ? getInputLoadingProps(loading)
          : {
              startAdornment: (
                <>
                  <InputAdornment position={'start'}>
                    <Search color={'inherit'} />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }),
      }}
    />
  );

  const handleChange = useCallback(
    (value) => {
      const newValue = value.length ? value.pop() : undefined;

      setSelectedEmployers(newValue ? [newValue] : []);
      onSelectEmployerId(newValue?.id || '');
    },
    [onSelectEmployerId]
  );

  return (
    <Grid {...defaultGridContainerProps} spacing={0}>
      <Grid {...defaultGridItemProps} xs={true}>
        <Autocomplete
          loading={loading}
          open={open}
          inputValue={search}
          options={options}
          value={selectedEmployers}
          disableClearable={true}
          clearOnBlur={false}
          forcePopupIcon={true}
          multiple={true}
          getOptionLabel={(employer) => getEmployerFormattedName(employer)}
          onInputChange={handleInputChange}
          renderInput={searchInputRender}
          isOptionEqualToValue={(option: UserEmployerAccessRequest, value: UserEmployerAccessRequest) =>
            option.id === value.id
          }
          renderTags={(value = []) => <span>{value.map((option) => getEmployerFormattedName(option))}</span>}
          onChange={(_: any, value: (UserEmployerAccessRequest | string)[]) => {
            handleChange(value);
          }}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
        />
      </Grid>
      <Grid {...defaultGridItemProps} xs={'auto'} className={styles.searchButtonWrapper}>
        <Button
          variant={'outlined'}
          color={'primary'}
          className={styles.searchButton}
          onClick={handleSearchClick}
          disabled={searchButtonDisabled}
        >
          {'Search'}
        </Button>
      </Grid>
    </Grid>
  );
};
export default EmployerSearchItem;
