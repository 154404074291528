import { UserJobFunction } from 'store/types/JobFunction';

export const ALL_COMPANIES_DROPDOWN_KEY = 'all-available';
export const ALL_PROGRAMS_OPTION = 'All Programs';

export interface CompanySelectOption {
  label: string;
  id: string;
}

export const getCompanySelectOptions = (
  list: UserJobFunction[],
  permissions?: string[],
  showAllCompaniesMenuItem?: boolean
): CompanySelectOption[] => {
  const newList = (
    permissions?.length
      ? list.filter(({ jobFunctionNames }) => permissions.some((role) => jobFunctionNames.includes(role)))
      : list
  ).map(({ companyId, companyName }) => ({
    label: companyName,
    id: companyId,
  }));
  if (showAllCompaniesMenuItem) {
    newList.unshift({ id: ALL_COMPANIES_DROPDOWN_KEY, label: 'All Companies' });
  }

  return newList;
};
