import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import {
  defaultGridContainerProps,
  defaultGridItemProps,
  defaultSnackbarErrorProps,
  getButtonLoadingProps,
} from 'util/Layout';
import Modal from 'components/shared/Modal';
import { ModalProps } from 'store/types/ComponentProps';
import SupportCase from 'store/types/SupportCase';
import SupportCasePriority from 'store/enums/SupportCasePriority';
import {
  defaultFormProps,
  getMaxLengthValidationRule,
  getMinLengthValidationRule,
  getRequiredValidationRule,
  getValidationProps,
} from 'util/Form';
import { useSnackbar } from 'notistack';
import { CurrentUser } from 'store/types/User';
import { UserContext } from 'components/UserGuard';
import { getFormattedDate, getUserFullName, NUMBER_DATE_FORMAT } from 'util/Format';
import moment from 'moment/moment';
import { DropzoneAreaBase, FileObject } from 'mui-file-dropzone';
import SupportService from 'services/api/SupportService';
import classNames from 'classnames';

import styles from './AddSupportCaseModal.module.scss';
import commonStyles from 'styles/common.module.scss';

interface AddSupportCaseFormValues extends Omit<SupportCase, 'issueDate' | 'category'> {
  issueDate: Date;
  category: string;
}
interface AddSupportCaseModalProps extends ModalProps {
  onSubmit: () => void;
}

const MAX_FILE_SIZE = 2000000;
const MAX_FILE_LIMIT = 99999;
const prioritySelectOptionConfig: SupportCasePriority[] = [
  SupportCasePriority.High,
  SupportCasePriority.Medium,
  SupportCasePriority.Low,
];
const defaultValues: AddSupportCaseFormValues = {
  issueDate: new Date(),
  message: '',
  email: '',
  subject: '',
  priority: SupportCasePriority.Medium,
  category: '',
};

const AddSupportCaseModal: React.FunctionComponent<AddSupportCaseModalProps> = ({ onClose, open, onSubmit }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { email, firstName, lastName }: CurrentUser = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<AddSupportCaseFormValues>({ ...defaultFormProps, defaultValues });
  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
    setValue,
    reset,
  } = form;
  const [files, setFiles] = useState<FileObject[]>([]);
  const issueDate: Date = useWatch({ control, name: 'issueDate' });

  useEffect(() => {
    if (open) {
      setValue('issueDate', new Date());
    }
  }, [open, setValue]);

  const resetForm = useCallback(() => {
    setLoading(false);
    onSubmit();
    reset();
    setFiles([]);
    enqueueSnackbar('Case successfully added', { variant: 'success' });
  }, [enqueueSnackbar, reset, onSubmit]);

  const handleClose = useCallback(() => {
    onClose();
    reset();
    setFiles([]);
  }, [onClose, reset]);

  const handleAddSupportCaseSubmit = useCallback(
    (data: AddSupportCaseFormValues) => {
      setLoading(true);
      const values = { issueDate: moment.utc(issueDate).format() || '', email };
      SupportService.addSupportCase({ ...data, ...values })
        .then(({ id }) => {
          if (files.length) {
            const uploadedFiles = new FormData();
            files.forEach(({ file }) => {
              uploadedFiles.append('files', file);
            });
            SupportService.uploadFile(id, uploadedFiles)
              .then(() => {
                resetForm();
              })
              .catch((errorMessage) => {
                enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
                setLoading(false);
              });
          } else {
            resetForm();
          }
        })
        .catch((errorMessage) => {
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
          setLoading(false);
        });
    },
    [enqueueSnackbar, issueDate, files, email, resetForm]
  );

  const handleAdd = useCallback((files: FileObject[] = []) => {
    setFiles((prevState) => [...prevState, ...files]);
  }, []);

  const handleDelete = useCallback((file: FileObject, index: number) => {
    setFiles((prevState) => {
      const newState = [...prevState];

      newState.splice(index, 1);
      return newState;
    });
  }, []);

  return (
    <FormProvider {...form}>
      <Modal
        onClose={handleClose}
        open={open}
        title={'Submit New Case'}
        actions={
          <>
            <Button color={'secondary'} variant={'outlined'} onClick={handleClose}>
              {'Close'}
            </Button>
            <Button
              color={'secondary'}
              variant={'contained'}
              onClick={handleSubmit(handleAddSupportCaseSubmit)}
              {...getButtonLoadingProps(loading)}
              disabled={loading || !isValid}
              className={styles.submitButton}
            >
              {'Submit Case'}
            </Button>
          </>
        }
      >
        <Grid {...defaultGridContainerProps} spacing={5}>
          <Grid {...defaultGridContainerProps} spacing={2} className={styles.container}>
            <Grid {...defaultGridItemProps} xs={3} className={styles.label}>
              {'User:'}
            </Grid>
            <Grid {...defaultGridItemProps} xs={9} className={styles.value}>
              {getUserFullName({ firstName, lastName })}
            </Grid>
            <Grid {...defaultGridItemProps} xs={3} className={styles.label}>
              {'Email Address:'}
            </Grid>
            <Grid {...defaultGridItemProps} xs={9} className={styles.value}>
              {email}
            </Grid>
            <Grid {...defaultGridItemProps} xs={3} className={styles.label}>
              {'Incident Date:'}
            </Grid>
            <Grid {...defaultGridItemProps} xs={9} className={styles.value}>
              {getFormattedDate(issueDate.toISOString(), NUMBER_DATE_FORMAT)}
            </Grid>
            <Grid {...defaultGridItemProps} xs={3} className={styles.label}>
              {'Incident Time:'}
            </Grid>
            <Grid {...defaultGridItemProps} xs={9} className={styles.value}>
              {moment(issueDate).format('hh:mm A')}
            </Grid>
          </Grid>
          <Grid {...defaultGridItemProps}>
            <h5 className={commonStyles.subTitle}>{'Priority'}</h5>
            <Controller
              render={({ field }) => (
                <TextField {...field} select={true} disabled={loading}>
                  {prioritySelectOptionConfig.map((value: SupportCasePriority) => (
                    <MenuItem value={value} key={`action-${value}`}>
                      {SupportCasePriority[value]}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              name={'priority'}
              control={control}
            />
          </Grid>
          <Grid {...defaultGridItemProps}>
            <h5 className={commonStyles.subTitle}>{'Subject'}</h5>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  {...getValidationProps('subject', errors)}
                  required={true}
                  disabled={loading}
                  placeholder={'Describe the subject here...'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              name={'subject'}
              control={control}
              rules={{
                required: getRequiredValidationRule('subject', false, true),
                maxLength: getMaxLengthValidationRule(30),
                minLength: getMinLengthValidationRule(10),
              }}
            />
          </Grid>
          <Grid {...defaultGridItemProps}>
            <h5 className={commonStyles.subTitle}>{'Message'}</h5>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  {...getValidationProps('message', errors)}
                  placeholder={'Describe the issue here...'}
                  required={true}
                  disabled={loading}
                  multiline={true}
                  rows={5}
                />
              )}
              name={'message'}
              control={control}
              rules={{
                required: getRequiredValidationRule('message', false, true),
                maxLength: getMaxLengthValidationRule(200),
                minLength: getMinLengthValidationRule(10),
              }}
            />
          </Grid>
          <Grid {...defaultGridItemProps} sm={'auto'} className={styles.uploadFilesWrapper}>
            <DropzoneAreaBase
              maxFileSize={MAX_FILE_SIZE}
              filesLimit={MAX_FILE_LIMIT}
              fileObjects={files}
              onAdd={handleAdd}
              onDelete={handleDelete}
              showAlerts={['error']}
              clearOnUnmount={true}
              showPreviews={true}
              showPreviewsInDropzone={false}
              useChipsForPreview={true}
              dropzoneText={'Upload Files'}
              previewText={''}
              classes={{
                root: classNames(styles.dropzone),
                textContainer: styles.dropzoneTextContainer,
                text: styles.dropzoneText,
                icon: styles.dropzoneIcon,
              }}
              previewGridClasses={{
                container: styles.previewContainer,
                item: styles.previewImageContainer,
              }}
              dropzoneProps={{ noDrag: true, disabled: loading }}
              previewChipProps={{ className: styles.chip, disabled: loading }}
              alertSnackbarProps={{
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
              }}
            />
          </Grid>
        </Grid>
      </Modal>
    </FormProvider>
  );
};
export default AddSupportCaseModal;
