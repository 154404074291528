import Modal from 'components/shared/Modal';
import { Button } from '@mui/material';
import commonStyles from 'styles/common.module.scss';
import React, { SyntheticEvent, useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import { ModalProps } from 'store/types/ComponentProps';
import UserService from 'services/api/UserService';
import { defaultSnackbarErrorProps } from 'util/Layout';

interface EmailNotificationsToggleModalProps extends ModalProps {
  onSubmit: () => void;
}

const EmailNotificationsModal: React.FunctionComponent<EmailNotificationsToggleModalProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      setLoading(true);
      UserService.updateEmailNotificationsFlag(true)
        .then(() => {
          enqueueSnackbar('Opt Out of emails successfully submitted', { variant: 'success' });
          onSubmit();
          setLoading(false);
        })
        .catch((errorMessage) => {
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
          setLoading(false);
        });
    },
    [onSubmit, enqueueSnackbar]
  );

  return (
    <Modal
      loading={loading}
      title={'Confirm Notification Changes'}
      open={open}
      maxWidth={'xs'}
      actions={
        <>
          <Button color={'secondary'} variant={'outlined'} onClick={onClose}>
            {'Cancel'}
          </Button>
          <Button
            type={'submit'}
            variant={'contained'}
            onClick={handleSubmit}
            className={commonStyles.dangerButtonContained}
          >
            {'Confirm'}
          </Button>
        </>
      }
    >
      <p className={commonStyles.text}>
        {'This will opt out your email notifications from all companies you are assigned as a billing contact/admin.'}
      </p>
    </Modal>
  );
};

export default EmailNotificationsModal;
