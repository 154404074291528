import Alert from '@mui/material/Alert';
import React, { useEffect, useState } from 'react';
import PreferencesService from 'services/api/PreferencesService';
import useRequest from 'hooks/useRequest';
import Spinner from 'components/shared/Spinner';
import PreferenceQuestion, { PreferenceQuestionView } from 'store/types/PreferenceQuestion';
import EditPreferencesPageView from './EditPreferencesPageView';
import { getPreferencesQuestionsView } from 'util/Preferences';

import commonStyles from 'styles/common.module.scss';

const EditPreferencesPage: React.FunctionComponent = () => {
  const { data, error, loading } = useRequest<PreferenceQuestion[]>(PreferencesService.getPreferenceQuestions);
  const [questions, setQuestions] = useState<PreferenceQuestionView[]>([]);

  useEffect(() => {
    if (data?.length) {
      setQuestions(getPreferencesQuestionsView(data));
    }
  }, [data]);

  return (
    <Spinner loading={loading}>
      {error && (
        <Alert severity={'error'} className={commonStyles.alert}>
          {error}
        </Alert>
      )}
      {!!questions.length && <EditPreferencesPageView data={questions} />}
    </Spinner>
  );
};

export default EditPreferencesPage;
