import { SortConfig } from 'store/types/Table';
import { OrderPayment } from 'store/types/Payment';
import { sorter } from 'util/Table';

export enum InvoicesTableActionType {
  SetInitialList = 'SetInitialList',
  UpdateSort = 'UpdateSort',
  OpenDetails = 'OpenDetails',
  CloseDetails = 'CloseDetails',
}

export interface InvoicesTableState {
  initialList: OrderPayment[];
  list: OrderPayment[];
  sort: SortConfig<OrderPayment>;
  clickedItem?: OrderPayment;
}

export interface InvoicesTableAction {
  type: InvoicesTableActionType;
  payload: Partial<InvoicesTableState & { selectedItem?: OrderPayment }>;
}

const getSortedList = (list: OrderPayment[] = [], sort: SortConfig<OrderPayment>): OrderPayment[] =>
  list.length ? [...list].sort(sorter<OrderPayment>(sort)) : [...list];

export const initialState: InvoicesTableState = {
  initialList: [],
  list: [],
  sort: { direction: 'desc', column: 'status' },
  clickedItem: undefined,
};

const reducer = (state: InvoicesTableState, { type, payload }: InvoicesTableAction): InvoicesTableState => {
  if (type === InvoicesTableActionType.SetInitialList) {
    const { initialList = [] } = payload;
    const list = getSortedList(initialList, state.sort);

    return { ...state, initialList, list };
  }

  if (type === InvoicesTableActionType.UpdateSort) {
    const { sort = {} } = payload;

    return {
      ...state,
      sort,
      list: getSortedList(state.initialList, sort),
    };
  }

  if (type === InvoicesTableActionType.OpenDetails) {
    const { clickedItem } = payload;

    return { ...state, clickedItem };
  }

  if (type === InvoicesTableActionType.CloseDetails) {
    return { ...state, clickedItem: undefined };
  }

  return state;
};

export default reducer;
