import { FilterConfig, SortConfig } from 'store/types/Table';
import { sorter } from 'util/Table';
import { EventSessionView } from 'store/types/events/Event';
import EventStatus from 'store/enums/events/EventStatus';
import { Moment } from 'moment-timezone';

export enum EventSessionsTableActionType {
  SetInitialList = 'SetInitialList',
  SetSubmitLoading = 'SetSubmitLoading',
  UpdateSort = 'UpdateSort',
  UpdateFilter = 'UpdateFilter',
  ResetFilter = 'ResetFilter',
  AddItem = 'AddItem',
  RemoveItem = 'RemoveItem',
  OpenAddWaitListModal = 'OpenAddWaitListModal',
  CloseAddWaitListModal = 'CloseAddWaitListModal',
}

type EventSessionsFilterConfig = FilterConfig<
  Omit<EventSessionView, 'startDate' | 'endDate'> & {
    tracks: string[];
    startDate: Moment | null;
    endDate: Moment | null;
  }
>;

export interface EventSessionsTableState {
  selectedItem?: EventSessionView;
  initialList: EventSessionView[];
  list: EventSessionView[];
  sort: SortConfig<EventSessionView>;
  filter: EventSessionsFilterConfig;
}

export interface EventSessionsTableAction {
  type: EventSessionsTableActionType;
  payload: Partial<EventSessionsTableState> & {
    selectedId?: EventSessionView['id'];
  };
}

const getSortedList = (list: EventSessionView[] = [], sort: SortConfig<EventSessionView>): EventSessionView[] => {
  const initialSortedList: EventSessionView[] = list.length
    ? [...list].sort(sorter<EventSessionView>(sort))
    : [...list];
  const registeredItems: EventSessionView[] = [];
  const selectedItems: EventSessionView[] = [];
  const availableItems: EventSessionView[] = [];

  initialSortedList.forEach((item) => {
    if (item.status === EventStatus.Available) {
      availableItems.push(item);
    } else if (item.status === EventStatus.NotAvailable) {
      registeredItems.push(item);
    } else {
      selectedItems.push(item);
    }
  });
  return registeredItems.concat(selectedItems.concat(availableItems));
};

const getFilteredList = (list: EventSessionView[] = [], filter: EventSessionsFilterConfig) => {
  const searchValue = (filter.search || '').toLowerCase();
  const tracksValue: string[] = filter.tracks || [];
  const startDateValue: Moment = filter.startDate;
  const endDateValue: Moment = filter.endDate;
  let result: EventSessionView[] = [...list];

  if (tracksValue.length) {
    result = result.filter(({ track }) => tracksValue.includes(track));
  }

  if (searchValue || tracksValue.length || startDateValue || endDateValue) {
    result = result.filter(({ title, track, startDate, endDate }: EventSessionView) => {
      const filteredBySearch = !searchValue || title.toLowerCase().includes(searchValue);
      const filteredByCategory = !tracksValue.length || tracksValue.includes(track);
      const filteredByStartDate = !startDateValue || startDate.startOf('day').isSameOrAfter(startDateValue);
      const filteredByEndDate = !endDateValue || endDate.startOf('day').isSameOrBefore(endDateValue);

      return filteredBySearch && filteredByCategory && filteredByStartDate && filteredByEndDate;
    });
  }
  return result;
};

const initialFilter: EventSessionsFilterConfig = { search: '', tracks: [], startDate: null, endDate: null };

export const initialState: EventSessionsTableState = {
  initialList: [],
  list: [],
  sort: { direction: 'asc', column: 'startDate' },
  filter: initialFilter,
};

const reducer = (
  state: EventSessionsTableState,
  { type, payload }: EventSessionsTableAction
): EventSessionsTableState => {
  if (type === EventSessionsTableActionType.SetInitialList) {
    const { initialList = [] } = payload;
    const list = getSortedList(initialList, state.sort);

    return { ...state, initialList, list };
  }

  if (type === EventSessionsTableActionType.UpdateSort) {
    const { sort = {} } = payload;

    return {
      ...state,
      sort,
      list: getSortedList(state.list, sort),
    };
  }

  if (type === EventSessionsTableActionType.UpdateFilter) {
    const { filter = {} } = payload;
    const newFilter = { ...state.filter, ...filter };

    return {
      ...state,
      filter: newFilter,
      list: getFilteredList(getSortedList(state.initialList, state.sort), newFilter),
    };
  }

  if (type === EventSessionsTableActionType.ResetFilter) {
    return {
      ...state,
      filter: initialFilter,
      list: getSortedList(state.initialList, state.sort),
    };
  }

  if (type === EventSessionsTableActionType.OpenAddWaitListModal) {
    const { selectedItem } = payload;

    return { ...state, selectedItem };
  }

  if (type === EventSessionsTableActionType.CloseAddWaitListModal) {
    return { ...state, selectedItem: undefined };
  }

  if (type === EventSessionsTableActionType.AddItem || type === EventSessionsTableActionType.RemoveItem) {
    const { selectedId } = payload;
    const initialList = [...state.initialList];
    const selectedItem = selectedId ? initialList.find(({ id }) => id === selectedId) : undefined;

    if (selectedItem) {
      const selectedIndex = initialList.findIndex(({ id }) => id === selectedId);
      const newStatus = selectedItem.status === EventStatus.Available ? EventStatus.Selected : EventStatus.Available;
      initialList.splice(selectedIndex, 1, { ...selectedItem, status: newStatus });
    }

    return {
      ...state,
      initialList,
      list: getFilteredList(getSortedList(initialList, state.sort), state.filter),
    };
  }

  return state;
};

export default reducer;
