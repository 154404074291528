import { FilterConfig, SortConfig } from 'store/types/Table';
import { ALL_EVENT_MODES_VALUE } from 'util/Event';
import { getListByPage, sorter } from 'util/Table';
import { MainEventView } from 'store/types/events/Event';

export enum EventCatalogPageViewActionType {
  SetInitialList = 'SetInitialList',
  UpdateFilter = 'UpdateFilter',
  UpdateSort = 'UpdateSort',
  UpdatePage = 'UpdatePage',
  UpdateItemsPerPage = 'UpdateItemsPerPage',
}

export interface EventCatalogPageViewState {
  initialList: MainEventView[];
  list: MainEventView[];
  pageList: MainEventView[];
  filter: FilterConfig<MainEventView>;
  sort: SortConfig<MainEventView>;
  page: number;
  itemsPerPage: number;
}

export interface EventCatalogPageViewAction {
  type: EventCatalogPageViewActionType;
  payload: Partial<EventCatalogPageViewState>;
}

export const initialState: EventCatalogPageViewState = {
  initialList: [],
  list: [],
  pageList: [],
  page: 1,
  itemsPerPage: 10,
  filter: { search: '', mode: ALL_EVENT_MODES_VALUE },
  sort: { direction: 'asc', column: 'startDate' },
};

const getList = (list: MainEventView[] = [], filter: FilterConfig<MainEventView>) => {
  const searchValue = (filter.search || '').toLowerCase();
  let result = [...list];

  if (searchValue) {
    result = result.filter(({ title }: MainEventView) => title.toLowerCase().includes(searchValue));
  }
  return result;
};

const getSortedList = (list: MainEventView[] = [], sort: SortConfig<MainEventView>): MainEventView[] =>
  list.length ? [...list].sort(sorter<MainEventView>(sort)) : [...list];

const reducer = (
  state: EventCatalogPageViewState,
  { type, payload }: EventCatalogPageViewAction
): EventCatalogPageViewState => {
  if (type === EventCatalogPageViewActionType.SetInitialList) {
    const { initialList = [] } = payload;
    const list = getSortedList(initialList, state.sort);

    return {
      ...state,
      initialList,
      list,
      page: 1,
      pageList: getListByPage(list, state.itemsPerPage),
    };
  }

  if (type === EventCatalogPageViewActionType.UpdateFilter) {
    const { filter = {} } = payload;
    const newFilter = { ...state.filter, ...filter };
    const list = getList(getSortedList(state.initialList, state.sort), newFilter);
    const newPage = filter.mode ? 1 : state.page;

    return {
      ...state,
      filter: newFilter,
      page: newPage,
      list,
      pageList: getListByPage(list, state.itemsPerPage, newPage),
    };
  }

  if (type === EventCatalogPageViewActionType.UpdateSort) {
    const { sort = {} } = payload;
    const list = getList(getSortedList(state.initialList, sort), state.filter);

    return {
      ...state,
      page: 1,
      sort,
      list,
      pageList: getListByPage(list, state.itemsPerPage),
    };
  }

  if (type === EventCatalogPageViewActionType.UpdatePage) {
    const { page = 1 } = payload;

    return {
      ...state,
      page,
      pageList: getListByPage(state.list, state.itemsPerPage, page),
    };
  }

  if (type === EventCatalogPageViewActionType.UpdateItemsPerPage) {
    const { itemsPerPage = 20 } = payload;

    return {
      ...state,
      itemsPerPage,
      page: 1,
      pageList: getListByPage(state.list, itemsPerPage),
    };
  }

  return state;
};

export default reducer;
