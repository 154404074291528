import React, { useContext } from 'react';
import useRequest from 'hooks/useRequest';
import PaymentMethodService from 'services/api/PaymentMethodService';
import PaymentMethod from 'store/types/PaymentMethod';
import UseRequestData from 'store/types/UseRequestData';
import { UserContext } from 'components/UserGuard';

export const PaymentMethodsContext = React.createContext<
  Pick<UseRequestData<PaymentMethod[]>, 'data' | 'loading' | 'error' | 'firstLoading' | 'refetch'>
>({
  loading: false,
  firstLoading: false,
  data: [],
  refetch: () => {
    return false;
  },
});

const PaymentMethodsContextWrapper: React.FunctionComponent = ({ children }) => {
  const { id = '' } = useContext(UserContext);
  const { data, loading, firstLoading, error, refetch } = useRequest<PaymentMethod[]>(
    id ? PaymentMethodService.getPaymentMethods : undefined
  );

  return (
    <PaymentMethodsContext.Provider value={{ data, loading, error, refetch, firstLoading }}>
      {children}
    </PaymentMethodsContext.Provider>
  );
};
export default PaymentMethodsContextWrapper;
