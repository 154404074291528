import { Alert, Divider, FormControlLabel, Grid, Link } from '@mui/material';
import { ConfigContext } from 'components/ConfigGuard';
import PaymentMethodSection from 'components/payments/PaymentMethodSection';
import Switch from 'components/shared/Switch';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import routes from 'store/configs/Routes';
import MakePaymentComponentProps from 'store/types/MakePaymentComponentProps';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { getHashRouteUrl } from 'util/Route';
import PaymentMethodType from 'store/enums/PaymentMethodType';
import PaymentAddressSection from './PaymentAddressSection';
import PaymentDiscountSection from './PaymentDiscountSection';
import PaymentSummaryCard from './PaymentSummaryCard';
import PaymentCreditsSection from './PaymentCreditsSection';
import { getPrice } from 'util/Payment';
import { ALL_COMPANIES_DROPDOWN_KEY } from 'util/Company';
import classNames from 'classnames';
import { Skeleton } from '@mui/lab';
import { PaymentConfigItem } from 'store/types/SiteConfig';

import commonStyles from 'styles/common.module.scss';
import styles from './PaymentFormSection.module.scss';

interface PaymentFormSectionProps extends MakePaymentComponentProps {
  loading?: boolean;
  creditMemoBalance?: number;
  salesOrders?: boolean;
  paymentConfig: PaymentConfigItem;
}

const PaymentFormSection: React.FunctionComponent<PaymentFormSectionProps> = (props) => {
  const { isNccerTheme } = useContext(ConfigContext);
  const {
    form,
    onChange,
    autoRenewalVisible = false,
    discountEnabled = false,
    defaultAddress,
    onlySavedAddresses,
    id = '',
    creditMemoBalance,
    loading,
    salesOrders,
  } = props;
  const { autoRenewal, totalPrice = 0, paymentMethodType, credits = 0, billingAddress, useHomeAddress } = form;
  const paymentSectionDisabled: boolean = useMemo(() => totalPrice === 0, [totalPrice]);
  const saveMethodCheckboxDisabled: boolean = useMemo(
    () => autoRenewalVisible && (paymentSectionDisabled || !!autoRenewal),
    [autoRenewalVisible, autoRenewal, paymentSectionDisabled]
  );

  const autoRenewalDisabled: boolean = useMemo(
    () =>
      paymentSectionDisabled ||
      paymentMethodType === PaymentMethodType.Ach ||
      paymentMethodType === PaymentMethodType.Postpone,
    [paymentSectionDisabled, paymentMethodType]
  );

  useEffect(() => {
    if (autoRenewal && (!autoRenewalVisible || autoRenewalDisabled)) {
      onChange({ autoRenewal: false });
    }
  }, [autoRenewal, autoRenewalVisible, autoRenewalDisabled, onChange]);

  const handleAutoRenewalChange = useCallback(
    (_: any, checked: boolean) => {
      onChange({ autoRenewal: checked });
    },
    [onChange]
  );

  return (
    <Grid {...defaultGridContainerProps} className={styles.container}>
      <Grid {...defaultGridItemProps} className={styles.addressSection}>
        <PaymentAddressSection
          form={form}
          onChange={onChange}
          defaultAddress={defaultAddress}
          onlySavedAddresses={onlySavedAddresses}
        />
        {!useHomeAddress && !billingAddress && (
          <Alert severity={'info'} className={commonStyles.alert}>
            {'Please add a new address to proceed with the payment'}
          </Alert>
        )}
        <Divider className={commonStyles.gridItemDivider} />
      </Grid>
      {autoRenewalVisible && (
        <>
          <Grid {...defaultGridItemProps}>
            <h3 className={commonStyles.subTitle}>{'Auto-Renewal'}</h3>
            <p>
              {
                'By enrolling in auto-renewal, you agree for your payment to be automatically processed during the renewal period. See '
              }
              <Link
                underline={'always'}
                target={'_blank'}
                href={isNccerTheme ? getHashRouteUrl(routes.subscriptionTerms) : ''}
              >
                {'terms & conditions'}
              </Link>
              {'.'}
            </p>
            <FormControlLabel
              checked={autoRenewal}
              control={<Switch onChange={handleAutoRenewalChange} color={'secondary'} disabled={autoRenewalDisabled} />}
              label={
                autoRenewal
                  ? 'Yes, I would like to enroll in auto-renewal.'
                  : 'No, I would not like to enroll in auto-renewal.'
              }
            />
            <Divider className={commonStyles.gridItemDivider} />
          </Grid>
        </>
      )}
      <Grid
        {...defaultGridItemProps}
        lg={6}
        className={classNames({ [commonStyles.hidden]: !useHomeAddress && !billingAddress })}
      >
        <h3 className={commonStyles.subTitle}>{'Payment Information'}</h3>
        <Grid {...defaultGridContainerProps}>
          {!salesOrders && id && id !== ALL_COMPANIES_DROPDOWN_KEY && (
            <Grid {...defaultGridItemProps}>
              <p className={styles.balance}>
                {`Available Credits: `}
                {loading ? (
                  <Skeleton width={80} variant={'text'} className={styles.balance} />
                ) : (
                  getPrice(creditMemoBalance)
                )}
              </p>
              <PaymentCreditsSection {...props} />
            </Grid>
          )}
          <Grid
            {...defaultGridItemProps}
            className={classNames({ [commonStyles.hidden]: credits === totalPrice + credits })}
          >
            <PaymentMethodSection {...props} saveMethodCheckboxDisabled={saveMethodCheckboxDisabled} />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        {...defaultGridItemProps}
        lg={6}
        className={classNames({ [commonStyles.hidden]: !useHomeAddress && !billingAddress })}
      >
        <Grid {...defaultGridContainerProps}>
          <Grid {...defaultGridItemProps}>
            <h3 className={commonStyles.subTitle}>{'Order Summary'}</h3>
            <PaymentSummaryCard form={form} />
          </Grid>
          {discountEnabled && (
            <Grid {...defaultGridItemProps}>
              <PaymentDiscountSection {...props} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default PaymentFormSection;
