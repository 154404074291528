import FormFooter from 'components/shared/FormFooter';
import Spinner from 'components/shared/Spinner';
import { useSnackbar } from 'notistack';
import React, { Reducer, useCallback, useContext, useEffect, useReducer } from 'react';
import { useParams } from 'react-router';
import PaymentService, { ApplicationPaymentData, BasePaymentData } from 'services/api/PaymentService';
import { ApplicationContentView } from 'store/types/ApplicationContent';
import CollectJSResponse from 'store/types/CollectJSResponse';
import { PaymentFormValues } from 'store/types/FormValues';
import Payment from 'store/types/Payment';
import { getFormattedPayment, getPaymentData, paymentFormInvalid, shouldValidatePaymentMethod } from 'util/Payment';
import { PaymentMethodsContext } from 'components/PaymentMethodsContextWrapper';
import reducer, {
  initialState,
  ApplicationPaymentPageAction,
  ApplicationPaymentPageActionType,
  ApplicationPaymentPageState,
} from './ApplicationPaymentPageViewReducer';
import PaymentDetailsModal from 'components/payments/PaymentDetailsModal';
import { Button } from '@mui/material';
import { getHashRouteUrl } from 'util/Route';
import routes from 'store/configs/Routes';
import PaymentFormSection from 'components/payments/PaymentFormSection';
import { SiteConfigView } from 'store/types/SiteConfig';
import { ConfigContext } from 'components/ConfigGuard';
import { defaultSnackbarErrorProps } from 'util/Layout';

import commonStyles from 'styles/common.module.scss';

interface ApplicationPaymentPageViewProps {
  data: ApplicationContentView;
}

const ApplicationPaymentPageView: React.FunctionComponent<ApplicationPaymentPageViewProps> = ({ data }) => {
  const {
    modulesSettings: {
      payments: { applications },
    },
  }: SiteConfigView = useContext(ConfigContext);
  const { applicationId = '' } = useParams<{ applicationId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const { refetch: refetchPaymentMethods } = useContext(PaymentMethodsContext);
  const [{ paymentForm, payment, loading }, dispatch] = useReducer<
    Reducer<ApplicationPaymentPageState, ApplicationPaymentPageAction>
  >(reducer, initialState);
  const submitButtonDisabled = paymentFormInvalid(paymentForm);

  useEffect(() => {
    const newTotalPrice: number = (data && data.amountToPay) || 0;

    dispatch({
      type: ApplicationPaymentPageActionType.UpdatePaymentForm,
      payload: {
        paymentForm: {
          totalPrice: newTotalPrice,
          summaryList: [{ price: newTotalPrice, name: 'Application Fee' }],
        },
      },
    });
  }, [data]);

  const handlePaymentChange = useCallback((formValues: Partial<PaymentFormValues>) => {
    dispatch({
      type: ApplicationPaymentPageActionType.UpdatePaymentForm,
      payload: { paymentForm: formValues },
    });
  }, []);

  const handleSubmit = () => {
    dispatch({ type: ApplicationPaymentPageActionType.SetLoading, payload: { loading: true } });
    if (shouldValidatePaymentMethod(paymentForm)) {
      (window as any).CollectJS.startPaymentRequest();
    } else {
      makePayment();
    }
  };

  const handlePaymentComplete = (response: CollectJSResponse) => {
    makePayment(response);
  };

  const makePayment = useCallback(
    (collectJSResponse?: CollectJSResponse) => {
      const basePaymentData: BasePaymentData = getPaymentData(paymentForm, collectJSResponse);
      const paymentData: ApplicationPaymentData = {
        ...basePaymentData,
        applicationId,
      };

      PaymentService.makeApplicationPayment(paymentData)
        .then((newPayment: Payment) => {
          refetchPaymentMethods();
          enqueueSnackbar('Payment successfully confirmed', { variant: 'success' });
          dispatch({
            type: ApplicationPaymentPageActionType.OpenPaymentConfirmation,
            payload: {
              payment: getFormattedPayment(newPayment),
            },
          });
        })
        .catch((error: string) => {
          dispatch({ type: ApplicationPaymentPageActionType.SetLoading, payload: { loading: false } });
          enqueueSnackbar(error, defaultSnackbarErrorProps);
        });
    },
    [paymentForm, enqueueSnackbar, applicationId, refetchPaymentMethods]
  );

  return (
    <>
      <Spinner loading={loading}>
        <h1 className={commonStyles.pageTitle}>{`Application #${applicationId} Payment`}</h1>
        <form>
          <PaymentFormSection
            form={paymentForm}
            onChange={handlePaymentChange}
            onPaymentComplete={handlePaymentComplete}
            onlySavedAddresses={true}
            paymentConfig={applications}
          />
          <FormFooter
            onSubmit={handleSubmit}
            submitButtonName={'Complete Payment'}
            submitButtonDisabled={submitButtonDisabled}
          />
        </form>
      </Spinner>
      {!!payment && (
        <PaymentDetailsModal
          open={!!payment}
          payment={payment}
          actions={
            <Button color={'secondary'} variant={'contained'} href={getHashRouteUrl(routes.applications)}>
              {'Go to Applications'}
            </Button>
          }
        />
      )}
    </>
  );
};
export default ApplicationPaymentPageView;
