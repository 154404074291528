import React from 'react';
import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { getValidationProps, FormProps, getMaxLengthValidationRule, getMinLengthValidationRule } from 'util/Form';
import { UserFormValues } from 'store/types/FormValues';

const DEFAULT_PHONE_MIN_LENGTH = 7;
const DEFAULT_PHONE_MAX_LENGTH = 32;

interface PhoneFormItemProps extends FormProps {
  required?: boolean;
  label?: string;
  phoneFieldName?: keyof UserFormValues;
}

const PhoneFormItem: React.FunctionComponent<PhoneFormItemProps> = ({
  required = false,
  label = 'Phone Number',
  disabled,
  phoneFieldName = 'phone',
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      render={({ field }) => (
        <TextField
          {...field}
          {...getValidationProps(phoneFieldName, errors)}
          label={label}
          disabled={disabled}
          required={required}
        />
      )}
      name={phoneFieldName}
      control={control}
      rules={{
        maxLength: getMaxLengthValidationRule(DEFAULT_PHONE_MAX_LENGTH),
        minLength: getMinLengthValidationRule(DEFAULT_PHONE_MIN_LENGTH),
      }}
    />
  );
};
export default PhoneFormItem;
