import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ModalProps } from 'store/types/ComponentProps';
import { MainEventRegistrationView } from 'store/types/events/EventRegistration';
import Modal from 'components/shared/Modal';
import { Alert, Button } from '@mui/material';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { defaultFormProps } from 'util/Form';
import {
  EventHotelPreferencesData,
  EventHotelPreferencesFormValues,
  EventRegistrationHotelPreferences,
} from 'store/types/events/EventHotelPreferences';
import { HotelConfirmStatus } from 'store/enums/events/HotelConfirmStatus';
import EventRegistrationService from 'services/api/EventRegistrationService';
import useRequest from 'hooks/useRequest';
import { useSnackbar } from 'notistack';
import EventHotelPreferencesForm from 'components/events/EventHotelPreferencesForm';
import { getDefaultFormValues, getDefaultRoomValues } from 'util/EventHotelPreferences';
import { getPreferencesAnswersData, isSubListQuestionsValid } from 'util/Preferences';
import moment from 'moment-timezone';
import { defaultSnackbarErrorProps } from 'util/Layout';

import styles from './UpdateEventHotelPreferencesModal.module.scss';
import commonStyles from 'styles/common.module.scss';

interface UpdateEventHotelPreferencesModalProps extends ModalProps {
  event: MainEventRegistrationView;
}

const UpdateEventHotelPreferencesModal: React.FunctionComponent<UpdateEventHotelPreferencesModalProps> = ({
  event: { id, registrant, eventRegistrationId, defaultHotelCheckInDate, defaultHotelCheckOutDate },
  open,
  onClose,
}) => {
  const form = useForm<EventHotelPreferencesFormValues>({ ...defaultFormProps, defaultValues: getDefaultFormValues() });
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    resetField,
    formState: { isValid, isDirty },
  } = form;
  const values = watch();
  const submitButtonDisabled = useMemo(
    () => !isValid || !isDirty || !isSubListQuestionsValid(values?.preferencesQuestionsList, values),
    [isDirty, isValid, values]
  );
  const { enqueueSnackbar } = useSnackbar();
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const hotelConfirmStatus = useWatch({ control, name: 'hotelConfirmStatus' });
  const rooms = useWatch({ control, name: 'rooms' });
  const eventHotelPreferencesRequest = useCallback(
    () => EventRegistrationService.getEventRegistrationHotelPreferences(id, eventRegistrationId, registrant.id),
    [eventRegistrationId, id, registrant]
  );
  const { data, loading, error } = useRequest<EventRegistrationHotelPreferences>(eventHotelPreferencesRequest);
  const preferencesSectionDisabled: boolean = useMemo(
    () => !!data?.preferencesEditEndDate && !moment().isSameOrBefore(moment(data?.preferencesEditEndDate)),
    [data]
  );
  const hotelSectionDisabled: boolean = useMemo(
    () => !!data?.hotelInformationEditEndDate && !moment().isSameOrBefore(moment(data?.hotelInformationEditEndDate)),
    [data]
  );

  useEffect(() => {
    if (hotelConfirmStatus === HotelConfirmStatus.No && rooms.length) {
      resetField('rooms', { defaultValue: [] });
    } else if (hotelConfirmStatus === HotelConfirmStatus.Yes && !rooms.length) {
      setValue('rooms', [getDefaultRoomValues(undefined, defaultHotelCheckInDate, defaultHotelCheckOutDate)]);
    }
  }, [defaultHotelCheckInDate, defaultHotelCheckOutDate, hotelConfirmStatus, reset, resetField, rooms, setValue]);

  useEffect(() => {
    if (data) {
      reset(getDefaultFormValues({ ...data, customerName: registrant.userName }));
    }
  }, [data, registrant, reset]);

  const handleUpdate = useCallback(
    ({ eventId, hotelId, preferences, preferencesQuestionsList, rooms }: EventHotelPreferencesFormValues) => {
      setSubmitLoading(true);
      const answersData: EventHotelPreferencesData = {
        preferences: getPreferencesAnswersData(preferences, preferencesQuestionsList),
        eventId,
        hotelId,
        rooms,
      };

      EventRegistrationService.updateEventHotelPreferences(
        {
          ...answersData,
          eventRegistrationId,
        },
        registrant.id
      )
        .then(() => {
          setSubmitLoading(false);
          enqueueSnackbar('Event Details successfully updated', { variant: 'success' });
          onClose();
        })
        .catch((errorMessage: string) => {
          setSubmitLoading(false);
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        });
    },
    [enqueueSnackbar, eventRegistrationId, onClose, registrant.id]
  );

  return (
    <Modal
      title={'Event Details'}
      loading={loading || submitLoading}
      open={open}
      onClose={onClose}
      maxWidth={'lg'}
      actions={
        <>
          <Button
            variant={'contained'}
            className={styles.button}
            disabled={submitButtonDisabled}
            onClick={handleSubmit(handleUpdate)}
          >
            {'Update'}
          </Button>
        </>
      }
    >
      {error ? (
        <Alert severity={'error'} className={commonStyles.alert}>
          {error}
        </Alert>
      ) : (
        <FormProvider {...form}>
          <EventHotelPreferencesForm
            additionalInformation={data?.additionalInformation}
            eventRegistrationDisclaimer={data?.eventRegistrationDisclaimer}
            preferencesSectionDisabled={preferencesSectionDisabled}
            hotelSectionDisabled={hotelSectionDisabled}
            availabilityDisclaimer={data?.availabilityDisclaimer}
          />
        </FormProvider>
      )}
    </Modal>
  );
};

export default UpdateEventHotelPreferencesModal;
