import React from 'react';
import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { FormProps, getRequiredValidationRule, getValidationProps } from 'util/Form';

interface DonationNameFormItemProps extends FormProps {
  optional?: boolean;
}

const FIELD_NAME = 'actionUserName';

const DonationNameFormItem: React.FunctionComponent<DonationNameFormItemProps> = ({ optional = true }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      render={({ field }) => (
        <TextField {...field} {...getValidationProps(FIELD_NAME, errors)} label={'Name'} required={!optional} />
      )}
      name={FIELD_NAME}
      control={control}
      rules={{ required: getRequiredValidationRule('name', false, !optional) }}
    />
  );
};
export default DonationNameFormItem;
