import { Divider, Icon, Link, Tooltip } from '@mui/material';
import classNames from 'classnames';
import { ConfigContext } from 'components/ConfigGuard';
import { UserContext } from 'components/UserGuard';
import React, { useContext, useMemo } from 'react';
import { LinkProps, NavLink } from 'react-router-dom';
import routes from 'store/configs/Routes';
import { materialOutlinedIconsClassName } from 'util/Layout';
import { getAppMenuConfig } from 'util/Route';
import SiteModule from 'store/enums/SiteModule';
import SidebarMenuListButton from 'components/layout/Sidebar/SidebarMenuListButton/SidebarMenuListButton';
import MenuConfigItem from 'store/types/MenuConfigItem';
import { useLocation } from 'react-router';

import styles from '../Sidebar.module.scss';

interface SidebarMenuProps {
  collapsed?: boolean;
  onItemClick?: () => void;
}

const SidebarMenu: React.FunctionComponent<SidebarMenuProps> = ({ collapsed = false, onItemClick }) => {
  const currentUser = useContext(UserContext);
  const { sidebar, isNccerBillingApp } = useContext(ConfigContext);
  const { pathname } = useLocation();
  const menuConfig = useMemo(
    () => getAppMenuConfig(currentUser, sidebar, isNccerBillingApp),
    [currentUser, isNccerBillingApp, sidebar]
  );
  const getNavLinkProps = ({ onClick, title, icon }: MenuConfigItem) => {
    const handleClick = (e: any) => {
      if (onClick) {
        onClick(e);
      }
      if (onItemClick) {
        onItemClick();
      }
    };

    const props: Pick<LinkProps, 'tabIndex' | 'className' | 'onClick' | 'children'> = {
      tabIndex: 0,
      className: styles.link,
      onClick: handleClick,
      children: (
        <div className={styles.content}>
          <Icon className={classNames(materialOutlinedIconsClassName, styles.icon)}>{icon}</Icon>
          <span className={styles.label}>{title}</span>
        </div>
      ),
    };
    return props;
  };

  return (
    <nav className={classNames(styles.menu, { [styles.collapsed]: collapsed })}>
      {menuConfig.map(({ tooltipText, title, to, type, icon, divider, onClick, key = '', children }, index) => {
        const isPaymentLinkActive = (_: any, { pathname }: any): boolean =>
          pathname.includes(routes.personalPayments) &&
          !pathname.includes(routes.groupPayments) &&
          !pathname.includes(routes.companyPayments);

        if (children) {
          const isOpen = pathname.includes(routes.personalPayments) || pathname.includes(routes.companyPayments);
          return (
            <SidebarMenuListButton title={title} icon={icon} isOpen={isOpen} key={key || `${type}-${index}`}>
              {children.map(({ tooltipText, title, to, type, icon, onClick }, index) => {
                const props = getNavLinkProps({ onClick, title, icon });
                return (
                  <React.Fragment key={`${type}-${index}`}>
                    <Tooltip arrow={true} placement={'right'} disableInteractive={true} title={tooltipText || ''}>
                      {to ? (
                        <NavLink
                          {...props}
                          className={classNames(styles.link, styles.listLink)}
                          to={to}
                          activeClassName={styles.active}
                          isActive={type && type === SiteModule.Payments ? isPaymentLinkActive : undefined}
                        />
                      ) : (
                        <Link {...props} className={classNames(styles.link, styles.listLink)} />
                      )}
                    </Tooltip>
                  </React.Fragment>
                );
              })}
            </SidebarMenuListButton>
          );
        } else {
          const props = getNavLinkProps({ onClick, title, icon });
          return (
            <React.Fragment key={key || `${type}-${index}`}>
              {divider && <Divider className={styles.divider} />}
              <Tooltip arrow={true} placement={'right'} disableInteractive={true} title={tooltipText || ''}>
                {to ? (
                  <NavLink
                    {...props}
                    to={to}
                    activeClassName={styles.active}
                    isActive={type && type === SiteModule.Payments ? isPaymentLinkActive : undefined}
                  />
                ) : (
                  <Link {...props} />
                )}
              </Tooltip>
            </React.Fragment>
          );
        }
      })}
    </nav>
  );
};

export default SidebarMenu;
