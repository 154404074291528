import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ApplicationContentView } from 'store/types/ApplicationContent';
import { defaultFormProps } from 'util/Form';
import { getQuestionsDefaultValues } from 'util/Application';
import EditApplicationForm from '../EditApplicationForm';

interface EditApplicationPageViewProps {
  data: ApplicationContentView;
  refetch: () => void;
}

const EditApplicationPageView: React.FunctionComponent<EditApplicationPageViewProps> = ({ data, refetch }) => {
  const form = useForm({ ...defaultFormProps, defaultValues: getQuestionsDefaultValues(data) });

  return (
    <FormProvider {...form}>
      <EditApplicationForm data={data} refetch={refetch} />
    </FormProvider>
  );
};
export default EditApplicationPageView;
