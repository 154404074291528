import { Link } from '@mui/material';
import moment, { Moment } from 'moment-timezone';
import React, { HTMLAttributes } from 'react';
import countries from 'store/configs/Countries';
import DonationActionType from 'store/enums/DonationActionType';
import StaticSelectOption from 'store/types/StaticSelectOption';
import User from 'store/types/User';
import Address, { BillingAddress } from 'store/types/Address';
import { getAddressFormValues } from 'util/Form';
import AppEvent from 'store/types/AppEvent';

export const EMPTY_STRING_VALUE = '-';

export const NUMBER_DATE_FORMAT = 'MM/DD/YYYY';
export const STRING_DATE_FORMAT = 'MMMM DD, YYYY';

export const ISO_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export const getUserFullName = (
  user?: Pick<User, 'firstName' | 'lastName' | 'middleInitial' | 'suffix' | 'prefix'>,
  includeMiddleInitial?: boolean,
  includePrefix = true,
  emptyValue = EMPTY_STRING_VALUE
): string => {
  const firstName = user?.firstName || '';
  const lastName = user?.lastName || '';
  const middleInitial = user?.middleInitial || '';
  const suffix = user?.suffix || '';
  const prefix = user?.prefix || '';

  if (lastName || firstName) {
    return (
      (includePrefix ? `${prefix}` : '') +
      ` ${firstName}` +
      (includeMiddleInitial ? ` ${middleInitial}` : '') +
      ` ${lastName}` +
      ` ${suffix}`
    ).trim();
  } else {
    return emptyValue;
  }
};

export const getFullDate = (startDate: string, endDate?: string) =>
  endDate
    ? `${moment(startDate).format(NUMBER_DATE_FORMAT)} - ${moment(endDate).format(NUMBER_DATE_FORMAT)}`
    : moment(startDate).format(NUMBER_DATE_FORMAT);

export const getFormattedDate = (date: string, format?: string): string =>
  moment(date).format(format || STRING_DATE_FORMAT);

export const getCountryName = (countryValue?: StaticSelectOption['value']): string | undefined => {
  if (countryValue) {
    const foundCountry = countries.find(({ value }) => value.toLowerCase() === countryValue.toLowerCase());

    return foundCountry ? foundCountry.text : countryValue;
  } else {
    return countryValue;
  }
};

export const getDonationActionTypeLabel = (value: DonationActionType): string => {
  switch (value) {
    case DonationActionType.Honor:
      return 'In Honor Of';
    case DonationActionType.Memory:
      return 'In Memory Of';
    case DonationActionType.Support:
      return 'In Support Of';
    default:
      return '';
  }
};

export const getStringValue = (value?: string, dateStringFormat: boolean | string = false): string => {
  return value
    ? dateStringFormat
      ? getFormattedDate(value, typeof dateStringFormat === 'string' ? dateStringFormat : undefined)
      : value
    : EMPTY_STRING_VALUE;
};

export const getAddressInfo = (
  address: Address | BillingAddress,
  spanProps?: HTMLAttributes<HTMLSpanElement>
): React.ReactNode => {
  const { street1, street2, city, stateRegion, country, postalCode, label } = getAddressFormValues(address);

  return (
    <>
      {label && <span {...spanProps}>{`${label} `}</span>}
      {(street1 || street2) && <span {...spanProps}>{`${street1}${street1 && street2 && ', '}${street2} `}</span>}
      {(city || stateRegion || postalCode) && (
        <span {...spanProps}>
          {`${city}${city && (stateRegion || postalCode) && ', '}${stateRegion}${
            (city || stateRegion) && ' '
          }${postalCode} `}
        </span>
      )}
      {country && <span {...spanProps}>{`${getCountryName(country)}`}</span>}
    </>
  );
};

export const getUpcomingEventsList = (list: AppEvent[] = []): AppEvent[] =>
  list.filter(({ endTime }) => new Date(endTime).getTime() - Date.now() > 0);

export const getEmailLink = (value: string, className?: string): React.ReactNode => (
  <Link href={`mailto:${value}`} className={className}>
    {value}
  </Link>
);

export const getPhoneLink = (value: string, className?: string): React.ReactNode => (
  <Link href={`tel:${value}`} className={className}>
    {value}
  </Link>
);

export const getFormattedFileSize = (value: number): string =>
  `${Intl.NumberFormat(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(value / 1024)} kB`;

export const getFormattedLocalDate = (date: string, timeZone = ''): Moment => {
  const localTimeZone = timeZone || moment.tz.guess();

  return moment.utc(date).tz(localTimeZone);
};

export const getEventDate = (date: string, format: string): string => moment(date).format(format);

export const getISODateString = (date?: Moment | null, startOfDay = true): string | null =>
  date ? (startOfDay ? date.clone().startOf('day').format(ISO_FORMAT) : date.clone().format(ISO_FORMAT)) : null;

export const getNumberDaysBetweenDates = (firstDate: Date, secondDate: Date): string =>
  String(Math.floor((firstDate.getTime() - secondDate.getTime()) / (1000 * 3600 * 24)));
