import { Button, TextField, OutlinedTextFieldProps, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';

import styles from './CopyInput.module.scss';

const FIELD_ID = 'copyInput';

interface CopyInputProps {
  link: string;
  inputProps?: Partial<OutlinedTextFieldProps>;
  id?: string;
}

const CopyInput: React.FunctionComponent<CopyInputProps> = ({ inputProps, link, id = FIELD_ID }) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleCopyClick = useCallback(() => {
    const input = document.getElementById(id) as HTMLInputElement;

    if (input) {
      input.disabled = false;
      input.select();
      input.setSelectionRange(0, 99999);
      input.disabled = true;
      const copySuccess: boolean = document.execCommand('copy');

      enqueueSnackbar(
        copySuccess ? 'Link copied successfully' : 'Link was not copied',
        copySuccess
          ? {
              variant: 'info',
            }
          : defaultSnackbarErrorProps
      );
    }
  }, [enqueueSnackbar, id]);

  return (
    <Grid {...defaultGridContainerProps} spacing={0}>
      <Grid {...defaultGridItemProps} xs={true}>
        <TextField size={'small'} disabled={true} value={link} id={id} {...inputProps} data-testid={'copy-input'} />
      </Grid>
      <Grid {...defaultGridItemProps} xs={'auto'} className={styles.copyButtonWrapper}>
        <Button
          variant={'outlined'}
          color={'primary'}
          className={styles.copyButton}
          onClick={handleCopyClick}
          data-testid={'copy-button'}
        >
          {'Copy'}
        </Button>
      </Grid>
    </Grid>
  );
};
export default CopyInput;
