import classNames from 'classnames';
import React from 'react';
import { CardHeader } from '@mui/material';
import ComponentProps from 'store/types/ComponentProps';

import styles from './SectionHeader.module.scss';

interface SectionHeaderProps extends ComponentProps {
  action?: React.ReactNode;
}

const SectionHeader: React.FunctionComponent<SectionHeaderProps> = ({ children, action, className = '' }) => (
  <CardHeader
    data-testid={'section-header'}
    title={children}
    action={action}
    disableTypography={true}
    className={classNames(styles.header, className)}
    classes={{ content: styles.headerContent, action: styles.headerAction }}
  />
);
export default SectionHeader;
