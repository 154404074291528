import Alert from '@mui/material/Alert';
import React, { useContext, useMemo } from 'react';
import useRequest from 'hooks/useRequest';
import Spinner from 'components/shared/Spinner';
import { UserContext } from 'components/UserGuard';
import { PriorApproval } from 'store/types/PriorApproval';
import CompanyService from 'services/api/CompanyService';
import CompanyAdvocacyPageView from 'components/company/CompanyAdvocacyPageView';

import commonStyles from 'styles/common.module.scss';

interface CompanyAdvocacyPageProps {
  companyId: string;
}

const CompanyAdvocacyPage: React.FunctionComponent<CompanyAdvocacyPageProps> = ({ companyId }) => {
  const companyPriorApprovalsRequest = useMemo(
    () => (companyId ? () => CompanyService.getCompanyPriorApprovals(companyId) : undefined),
    [companyId]
  );
  const { data, error, loading } = useRequest<PriorApproval[]>(companyPriorApprovalsRequest);
  const { userLoading = false } = useContext(UserContext);

  return (
    <Spinner loading={loading || userLoading}>
      {error ? (
        <Alert severity={'error'} className={commonStyles.alert}>
          {error}
        </Alert>
      ) : (
        data && <CompanyAdvocacyPageView data={data} />
      )}
    </Spinner>
  );
};

export default CompanyAdvocacyPage;
