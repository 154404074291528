import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { Button, Container, ContainerProps } from '@mui/material';
import classNames from 'classnames';
import ComponentProps from 'store/types/ComponentProps';
import { ConfigContext } from 'components/ConfigGuard';

import styles from './FormFooter.module.scss';

export interface FormFooterProps extends ComponentProps, Pick<ContainerProps, 'maxWidth'> {
  onSubmit?: () => void;
  hideSubmitButton?: boolean;
  submitButtonDisabled?: boolean;
  submitButtonName?: string;
}

const FormFooter: React.FunctionComponent<FormFooterProps> = ({
  onSubmit,
  children,
  submitButtonDisabled = false,
  hideSubmitButton = false,
  submitButtonName = 'Save',
  className = '',
  maxWidth = false,
}) => {
  const { isSmacnaTheme } = useContext(ConfigContext);
  const footerRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isSmacnaTheme) {
      const flexibleFooter = () => {
        const smacnaFooterHeight = document.getElementById('smacna-footer')?.offsetHeight || 0;
        const distanceToBottom = document.body.scrollHeight - window.innerHeight - window.scrollY;
        if (footerRef?.current) {
          if (distanceToBottom <= smacnaFooterHeight) {
            footerRef.current.style.bottom = `${smacnaFooterHeight - distanceToBottom + 10}px`;
          } else {
            footerRef.current.style.bottom = '0px';
          }
        }
      };

      const mainPageLayout = document.getElementById('main-page-layout');
      const resizeObserver = new ResizeObserver(flexibleFooter);
      if (mainPageLayout) {
        resizeObserver.observe(mainPageLayout);
      }
      window.addEventListener('scroll', flexibleFooter);

      return () => {
        resizeObserver.disconnect();
        window.removeEventListener('scroll', flexibleFooter);
      };
    }
  }, [isSmacnaTheme]);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();

      if (onSubmit) {
        onSubmit();
      }
    },
    [onSubmit]
  );

  const footerContent = (
    <div className={styles.buttons}>
      {children && (
        <div className={styles.leftButtonWrapper} data-testid={'footer-actions'}>
          {children}
        </div>
      )}
      {!hideSubmitButton && (
        <Button
          color={'secondary'}
          variant={'contained'}
          type={'submit'}
          disabled={submitButtonDisabled}
          onClick={handleClick}
          data-testid={'footer-submit-button'}
          className={styles.submitButton}
        >
          {submitButtonName}
        </Button>
      )}
    </div>
  );

  return (
    <footer ref={footerRef} className={classNames(styles.formFooter, className)} data-testid={'footer'}>
      <div className={styles.content}>
        {maxWidth ? (
          <Container maxWidth={maxWidth}>{footerContent}</Container>
        ) : (
          <div className={styles.fullWidth} data-testid={'full-width-footer'}>
            {footerContent}
          </div>
        )}
      </div>
    </footer>
  );
};
export default FormFooter;
