import { Button } from '@mui/material';
import React, { useCallback, useContext, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import useRequest from 'hooks/useRequest';
import SubscriptionService from 'services/api/SubscriptionService';
import Subscription from 'store/types/Subscription';
import RenewSubscriptionPageView from 'components/subscriptions/RenewSubscriptionPageView';
import UserSubscriptionsPageView from 'components/subscriptions/UserSubscriptionsPageView';
import routes from 'store/configs/Routes';
import Payment from 'store/types/Payment';
import { getPaymentStatus } from 'util/Payment';
import PaymentDetailsModal from 'components/payments/PaymentDetailsModal';
import Spinner from 'components/shared/Spinner';
import EnrollSubscriptionPageView from 'components/subscriptions/EnrollSubscriptionPageView';
import { CompaniesContext } from 'components/CompaniesContextWrapper';
import PaymentStatus from 'store/enums/PaymentStatus';
import { ParametersContext } from 'components/ParametersGuard';

const SubscriptionsPageView: React.FunctionComponent = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { subscriptionId = '' } = useParams<{ subscriptionId: string }>();
  const { data: companiesData } = useContext(CompaniesContext);
  const subscriptionsRequestFn = useCallback(
    () => SubscriptionService.getSubscriptions((companiesData || []).map(({ id }) => id)),
    [companiesData]
  );
  const {
    subscriptions: { subscriptionsPageHeader },
  } = useContext(ParametersContext);
  const subscriptionsRequest = useRequest<Subscription[]>(subscriptionsRequestFn);
  const [payment, setPayment] = useState<Payment | undefined>(undefined);
  const { data, loading, firstLoading, refetch } = subscriptionsRequest;
  const isRenewalPage = !!subscriptionId;
  const isEnrollPage = pathname === routes.enrollSubscription;

  const handlePaymentCompleted = useCallback(
    (newPayment: Payment) => {
      setPayment({ ...newPayment, status: getPaymentStatus(newPayment.status) });
      refetch();
    },
    [refetch]
  );

  const handleButtonClick = useCallback(() => {
    setPayment(undefined);
    push(routes.subscriptions);
  }, [push]);

  return (
    <>
      {subscriptionsPageHeader && <p dangerouslySetInnerHTML={{ __html: subscriptionsPageHeader }} />}
      {isRenewalPage || isEnrollPage ? (
        <Spinner loading={loading} transparent={!firstLoading}>
          {isRenewalPage && data && (
            <RenewSubscriptionPageView onPaymentCompleted={handlePaymentCompleted} data={data} />
          )}
          {isEnrollPage && <EnrollSubscriptionPageView onPaymentCompleted={handlePaymentCompleted} />}
        </Spinner>
      ) : (
        <Spinner loading={firstLoading} transparent={false}>
          <UserSubscriptionsPageView {...subscriptionsRequest} />
        </Spinner>
      )}
      {!!payment && (
        <PaymentDetailsModal
          open={!!payment}
          payment={payment}
          description={
            <>
              {payment.status === PaymentStatus.Unpaid ? (
                <>
                  <span>{'Thank you for your order. '}</span>
                  <span>{'Please see invoice details below. '}</span>
                  <span>{'To pay please visit Billing & Invoices section. '}</span>
                </>
              ) : (
                <span>
                  {'Thank you, your payment has been successfully processed. Please see invoice details below.'}
                </span>
              )}
              <br />
              <span>
                <b>{'All subscription sales are final. '}</b>
                {
                  'Please use discretion when ordering subscriptions as no refunds or credits will be issued for unused subscriptions. Additional subscriptions can be ordered as needed.'
                }
              </span>
            </>
          }
          makePaymentActionVisible={!isEnrollPage}
          actions={
            <Button color={'secondary'} variant={'contained'} onClick={handleButtonClick}>
              {'Go to Subscriptions'}
            </Button>
          }
        />
      )}
    </>
  );
};
export default SubscriptionsPageView;
