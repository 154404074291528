import { Link } from '@mui/material';
import React, { useCallback, useState } from 'react';
import Spinner from 'components/shared/Spinner';
import SupportService from 'services/api/SupportService';
import { useSnackbar } from 'notistack';
import AttachFile from '@mui/icons-material/AttachFile';
import { SupportCaseFile } from 'store/types/SupportCase';

import commonStyles from 'styles/common.module.scss';
import styles from './SupportCaseFilesList.module.scss';
import { defaultSnackbarErrorProps } from 'util/Layout';

interface SupportCaseFilesListProps {
  data: SupportCaseFile[];
  supportCaseId: string;
}

const SupportCaseFilesList: React.FunctionComponent<SupportCaseFilesListProps> = ({ data, supportCaseId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const handleDownloadFile = useCallback(
    (file: SupportCaseFile) => () => {
      setLoading(true);
      SupportService.getSupportCaseFile(file, supportCaseId)
        .then(() => {
          setLoading(false);
        })
        .catch((errorMessage: string) => {
          setLoading(false);
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        });
    },
    [enqueueSnackbar, supportCaseId]
  );

  return (
    <Spinner loading={loading}>
      <ul>
        {data.map((file) => (
          <li key={`support-file-${file.id}`} className={styles.file}>
            <AttachFile className={styles.fileIcon} />
            <Link className={commonStyles.link} onClick={handleDownloadFile(file)}>
              {file.name}
            </Link>
          </li>
        ))}
      </ul>
    </Spinner>
  );
};
export default SupportCaseFilesList;
