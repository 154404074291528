enum SiteTheme {
  MemberPlex = 'memberplex',
  NccerSSO = 'nccer-sso',
  NccerBilling = 'nccer-billing',
  Gamsd = 'gamsd',
  Sna = 'sna',
  Default = 'default',
  Compass = 'compass',
  Smacna = 'smacna',
  Acs = 'acs',
  TeamsElite = 'teams-elite',
}
export default SiteTheme;
