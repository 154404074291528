import React, { useCallback } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import Cancel from '@mui/icons-material/Cancel';
import classNames from 'classnames';
import Spinner from 'components/shared/Spinner';
import { ModalProps } from 'store/types/ComponentProps';

import styles from './Modal.module.scss';

interface ModalComponentProps extends Pick<ModalProps, 'open'> {
  onClose?: () => void;
  title?: React.ReactNode;
  actions?: React.ReactNode;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  loading?: boolean;
  keepMounted?: boolean;
  disableBackdropClick?: boolean;
  paperClassName?: string;
  headerClassName?: string;
  contentClassName?: string;
  footerClassName?: string;
}

const Modal: React.FunctionComponent<ModalComponentProps> = ({
  open,
  title = null,
  loading = false,
  onClose,
  children,
  actions,
  keepMounted = false,
  disableBackdropClick = true,
  maxWidth = 'sm',
  paperClassName = '',
  headerClassName = '',
  contentClassName = '',
  footerClassName = '',
}) => {
  const handleClose = useCallback(
    (event: React.MouseEvent<HTMLElement>, reason: string) => {
      if (onClose && (reason !== 'backdropClick' || !disableBackdropClick)) {
        onClose();
      }
    },
    [disableBackdropClick, onClose]
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classNames(styles.paper, paperClassName) }}
      keepMounted={keepMounted}
      maxWidth={maxWidth}
      componentsProps={{ backdrop: { ['data-testid']: 'backdrop' } as any }}
      data-testid={'modal'}
    >
      {(title || onClose) && (
        <DialogTitle className={classNames(styles.title, headerClassName)} data-testid={'modal-header'}>
          <div>
            <h3>{title}</h3>
          </div>
          {onClose && (
            <IconButton
              className={styles.closeButton}
              onClick={onClose}
              disabled={loading}
              color={'primary'}
              data-testid={'modal-close'}
            >
              <Cancel />
            </IconButton>
          )}
        </DialogTitle>
      )}
      <form className={styles.form}>
        <Spinner loading={loading} className={styles.spinner}>
          <DialogContent className={classNames(styles.content, contentClassName)} data-testid={'modal-content'}>
            {children}
          </DialogContent>
          {actions && (
            <DialogActions className={classNames(styles.footer, footerClassName)} data-testid={'modal-footer'}>
              <Grid container={true} justifyContent={'space-between'} className={styles.footerRow}>
                {actions}
              </Grid>
            </DialogActions>
          )}
        </Spinner>
      </form>
    </Dialog>
  );
};
export default Modal;
