import React, { useCallback, useEffect, useState } from 'react';
import { Button, ButtonGroup, Grid } from '@mui/material';
import EventSessionsTable from 'components/events/EventSessionsTable';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import CountBadge from 'components/shared/CountBadge';
import EventsCartItem from 'store/types/events/EventsCartItem';
import { EventSessionView } from 'store/types/events/Event';
import EventStatus from 'store/enums/events/EventStatus';
import { getEventPriceLevel } from 'util/Event';

import commonStyles from 'styles/common.module.scss';
import styles from './ManageGroupEventSessionsSection.module.scss';

interface ManageGroupEventSessionsSectionProps {
  sessions: EventSessionView[];
  cartItems: EventsCartItem[];
  onChange: (updatedRegistrant: EventsCartItem) => void;
}

const ManageGroupEventSessionsSection: React.FunctionComponent<ManageGroupEventSessionsSectionProps> = ({
  sessions,
  cartItems,
  onChange,
}) => {
  const [selectedRegistrant, setSelectedRegistrant] = useState<EventsCartItem>(cartItems[0]);
  const [initialSessions, setInitialSessions] = useState<EventSessionView[]>([]);

  useEffect(() => {
    const filteredSessions: EventSessionView[] = sessions.map((session) => ({
      ...session,
      price: getEventPriceLevel(session.prices, selectedRegistrant.priceLevelId),
      status: selectedRegistrant.sessions?.find((id) => id === session.id) ? EventStatus.Selected : session.status,
    }));

    setInitialSessions(filteredSessions);
  }, [selectedRegistrant, sessions]);

  const handleSelectRegistrant = useCallback(
    (newItem: EventsCartItem) => () => {
      setSelectedRegistrant(newItem);
    },
    []
  );

  const handleSessionsChange = useCallback(
    (newSelectedSessions: EventSessionView[] = []) => {
      const updatedRegistrant: EventsCartItem = {
        ...selectedRegistrant,
        sessions: newSelectedSessions.map(({ id }) => id),
      };

      onChange(updatedRegistrant);
    },
    [onChange, selectedRegistrant]
  );

  return (
    <Grid {...defaultGridContainerProps} spacing={2}>
      <Grid {...defaultGridItemProps} md={3}>
        <h4 className={commonStyles.subTitle}>{'Registrants:'}</h4>
        <ButtonGroup orientation={'vertical'} fullWidth={true}>
          {cartItems.map((item) => (
            <Button
              key={`employee-${item.registrantId}`}
              color={'secondary'}
              variant={selectedRegistrant?.registrantId === item.registrantId ? 'contained' : 'outlined'}
              onClick={handleSelectRegistrant(item)}
              className={styles.button}
            >
              <div className={styles.title}>
                <span>{item.customerName}</span>
                <span className={styles.helperText}>{`Member Type: ${item.memberTypeName}`}</span>
              </div>
              {!!item.sessions?.length && <CountBadge>{item.sessions?.length}</CountBadge>}
            </Button>
          ))}
        </ButtonGroup>
      </Grid>
      <Grid {...defaultGridItemProps} md={9}>
        <EventSessionsTable data={initialSessions} onChange={handleSessionsChange} isGuestSelection={true} />
      </Grid>
    </Grid>
  );
};
export default ManageGroupEventSessionsSection;
