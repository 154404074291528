import NodeProcess from './store/types/NodeProcess';
import TenantConfig from './store/types/TenantConfig';
import SiteTheme from './store/enums/SiteTheme';
import routes from './store/configs/Routes';

declare const process: NodeProcess;

// This is the only non-react-app env variable supported.
const isProduction: boolean = process.env.NODE_ENV === 'production';

// Environment variables besides NODE_ENV must begin with REACT_APP_
//   to be compiled into the webpack for static deploys.
export const buildVersion = process.env.REACT_APP_BUILD_VERSION || 'Number.Hash';

export const theme = process.env.REACT_APP_THEME || 'default';

export const INTERNAL_IDP_TYPE = 'internal';
export const EXTERNAL_IDP_TYPE = 'external';

export const tenantConfig: TenantConfig = {
  id: process.env.REACT_APP_TENANT || '2',
  theme: process.env.REACT_APP_THEME || SiteTheme.Smacna,
  clientId: process.env.REACT_APP_IDS_CLIENT_ID || '',
  paymentToken: process.env.REACT_APP_PAYMENT_TOKEN || 'U3cW87-8UMsj7-68283w-j3xVBZ',
  environment: process.env.REACT_APP_ENVIRONMENT || '',
  appType: process.env.REACT_APP_TYPE || '',
  idpType: process.env.REACT_APP_IDP_TYPE || INTERNAL_IDP_TYPE,
  idsUrl: process.env.REACT_APP_IDS_URL || '',
  appTitle: process.env.REACT_APP_TITLE || 'MemberPlex',
  addNccerCardNumberUrl:
    process.env.REACT_APP_IDS_URL && process.env.REACT_APP_ADD_NCCER_CARD_NUMBER_PATH
      ? `${process.env.REACT_APP_IDS_URL}${process.env.REACT_APP_ADD_NCCER_CARD_NUMBER_PATH}`
      : '',
  editProfileUrl:
    process.env.REACT_APP_IDS_URL && process.env.REACT_APP_EDIT_PROFILE_PATH
      ? `${process.env.REACT_APP_IDS_URL}${process.env.REACT_APP_EDIT_PROFILE_PATH}`
      : '',
  impersonationUrl:
    process.env.REACT_APP_IDS_URL && process.env.REACT_APP_IMPERSONATION_PATH
      ? `${process.env.REACT_APP_IDS_URL}${process.env.REACT_APP_IMPERSONATION_PATH}`
      : '',
  loginUrl:
    process.env.REACT_APP_IDS_URL && process.env.REACT_APP_LOGIN_PATH
      ? `${process.env.REACT_APP_IDS_URL}${process.env.REACT_APP_LOGIN_PATH}`
      : '',
  reLoginUrl:
    process.env.REACT_APP_IDS_URL && process.env.REACT_APP_RELOGIN_PATH
      ? `${process.env.REACT_APP_IDS_URL}${process.env.REACT_APP_RELOGIN_PATH}`
      : '',
  logoutUrl:
    process.env.REACT_APP_IDS_URL && process.env.REACT_APP_LOGOUT_PATH
      ? `${process.env.REACT_APP_IDS_URL}${process.env.REACT_APP_LOGOUT_PATH}`
      : routes.logout,
  changePasswordUrl:
    process.env.REACT_APP_IDS_URL && process.env.REACT_APP_CHANGE_PASSWORD_PATH
      ? `${process.env.REACT_APP_IDS_URL}${process.env.REACT_APP_CHANGE_PASSWORD_PATH}`
      : '',
  changeEmailUrl:
    process.env.REACT_APP_IDS_URL && process.env.REACT_APP_CHANGE_EMAIL_PATH
      ? `${process.env.REACT_APP_IDS_URL}${process.env.REACT_APP_CHANGE_EMAIL_PATH}`
      : '',
  logoutDonationUrl:
    process.env.REACT_APP_IDS_URL && process.env.REACT_APP_LOGOUT_DONATION_PATH
      ? `${process.env.REACT_APP_IDS_URL}${process.env.REACT_APP_LOGOUT_DONATION_PATH}`
      : '',
  donationRegistrationUrl:
    process.env.REACT_APP_IDS_URL && process.env.REACT_APP_DONATION_REGISTRATION_PATH
      ? `${process.env.REACT_APP_IDS_URL}${process.env.REACT_APP_DONATION_REGISTRATION_PATH}`
      : '',
  forgotCardNumberUrl:
    process.env.REACT_APP_FORGOT_CARD_NUMBER_URL || 'https://test-registry.nccer.org/Account/ForgotCardNumber',
  checkSessionUrl:
    process.env.REACT_APP_IDS_URL && process.env.REACT_APP_CHECK_SESSION_PATH
      ? `${process.env.REACT_APP_IDS_URL}${process.env.REACT_APP_CHECK_SESSION_PATH}`
      : '',
};
// id default to 2 for test netsuite tenant

const secondsToMillis = (seconds: number): number => {
  return seconds * 1000;
};

export const conf = {
  isProduction: isProduction,
  useXHRLogger: false,
  buildVersion: buildVersion,
  pathRoot: '/',
  staticRoot: '/index.html',
  httpRetryInterval: secondsToMillis(3),
  httpRequestTimeout: secondsToMillis(300),
  httpRetryLimit: 0,
  httpRequestTimeoutRetryLimit: 5,
  defaultDownloadedFileName: 'download',
  defaultErrMessage: 'An error occurred. Please try again later.',
  filenameHeader: 'x-suggested-filename',
  defaultLanguage: 'en_US',
  api: {
    logs: '/api/logs',
  },
};

// export const AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL || 'http://localhost:4201';
// export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:4201';
// export const PROFILE_API_URL = process.env.REACT_APP_PROFILE_API_URL || 'http://localhost:4201';

export const AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL || 'http://localhost:8890';
export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8890';
export const PROFILE_API_URL = process.env.REACT_APP_PROFILE_API_URL || 'http://localhost:8891';

// export const AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL || 'https://api.qa.aura-systems.net/au';
// export const API_URL = process.env.REACT_APP_API_URL || 'https://api2.qa.aura-systems.net/stable/mp';
// export const PROFILE_API_URL =
//   process.env.REACT_APP_PROFILE_API_URL || 'https://api2.qa.aura-systems.net/stable/profile-api';
