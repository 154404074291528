import React, { useCallback, useContext } from 'react';
import { Grid, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { getMaxLengthValidationRule, getRequiredValidationRule, getValidationProps, FormProps } from 'util/Form';
import { defaultGridItemProps } from 'util/Layout';
import { UserFormValues } from 'store/types/FormValues';
import ComponentProps from 'store/types/ComponentProps';
import { ConfigContext } from 'components/ConfigGuard';

interface UserNameFormSectionProps extends FormProps, ComponentProps {
  middleNameVisible?: boolean;
  lastNameEnabled?: boolean;
  required?: boolean;
  disabled?: boolean;
  prefixAndSuffixVisible?: boolean;
  nicknameVisible?: boolean;
}

const NAME_MAX_LENGTH = 32;
const PREFIX_MAX_LENGTH = 15;
const SUFFIX_MAX_LENGTH = 50;

const UserNameFormSection: React.FunctionComponent<UserNameFormSectionProps> = ({
  rowProps = defaultGridItemProps,
  middleNameVisible = false,
  prefixAndSuffixVisible = true,
  nicknameVisible = false,
  lastNameEnabled = true,
  required = true,
  disabled = false,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<UserFormValues>();
  const { isNccerTheme } = useContext(ConfigContext);

  const validationProps = useCallback((label: keyof UserFormValues) => getValidationProps(label, errors), [errors]);

  return (
    <>
      {prefixAndSuffixVisible && (
        <Grid {...rowProps} md={2}>
          <Controller
            render={({ field }) => (
              <TextField {...field} {...validationProps('prefix')} label={'Prefix'} disabled={disabled} />
            )}
            name={'prefix'}
            control={control}
            rules={{
              maxLength: getMaxLengthValidationRule(PREFIX_MAX_LENGTH),
            }}
          />
        </Grid>
      )}
      <Grid
        {...rowProps}
        md={prefixAndSuffixVisible && middleNameVisible ? 3 : prefixAndSuffixVisible ? 4 : middleNameVisible ? 5 : 6}
      >
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              {...validationProps('firstName')}
              label={'First Name'}
              required={required}
              disabled={disabled}
            />
          )}
          name={'firstName'}
          control={control}
          rules={{
            required: getRequiredValidationRule('first name', false, required),
            maxLength: getMaxLengthValidationRule(NAME_MAX_LENGTH),
          }}
        />
      </Grid>
      {middleNameVisible && (
        <Grid {...rowProps} md={2}>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                {...validationProps('middleInitial')}
                label={isNccerTheme ? 'Middle Name' : 'M. I.'}
                disabled={disabled}
              />
            )}
            name={'middleInitial'}
            control={control}
            rules={{ maxLength: getMaxLengthValidationRule(NAME_MAX_LENGTH) }}
          />
        </Grid>
      )}
      <Grid
        {...rowProps}
        md={prefixAndSuffixVisible && middleNameVisible ? 3 : prefixAndSuffixVisible ? 4 : middleNameVisible ? 5 : 6}
      >
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              {...validationProps('lastName')}
              label={'Last Name'}
              required={required}
              disabled={!lastNameEnabled || disabled}
            />
          )}
          name={'lastName'}
          control={control}
          rules={{
            required: getRequiredValidationRule('last name', false, required),
            maxLength: getMaxLengthValidationRule(NAME_MAX_LENGTH),
          }}
        />
      </Grid>
      {prefixAndSuffixVisible && (
        <Grid {...rowProps} md={2}>
          <Controller
            render={({ field }) => (
              <TextField {...field} {...validationProps('suffix')} label={'Suffix'} disabled={disabled} />
            )}
            name={'suffix'}
            control={control}
            rules={{
              maxLength: getMaxLengthValidationRule(SUFFIX_MAX_LENGTH),
            }}
          />
        </Grid>
      )}
      {nicknameVisible && (
        <Grid {...rowProps} md={6}>
          <Controller
            render={({ field }) => (
              <TextField {...field} {...validationProps('nickname')} label={'Nickname'} disabled={disabled} />
            )}
            name={'nickname'}
            control={control}
            rules={{
              maxLength: getMaxLengthValidationRule(NAME_MAX_LENGTH),
            }}
          />
        </Grid>
      )}
    </>
  );
};
export default UserNameFormSection;
