import Skeleton from '@mui/material/Skeleton';
import React from 'react';
import { Avatar as MuiAvatar } from '@mui/material';
import Person from '@mui/icons-material/Person';
import classNames from 'classnames';
import ComponentProps from 'store/types/ComponentProps';

import styles from './Avatar.module.scss';

interface AvatarProps extends ComponentProps {
  src?: string;
  icon?: React.ReactNode;
  loading?: boolean;
  darkBackground?: boolean;
}

const Avatar: React.FunctionComponent<AvatarProps> = ({
  src = '',
  icon,
  className = '',
  loading = false,
  darkBackground = false,
}) =>
  loading ? (
    <Skeleton
      variant={'circular'}
      className={classNames(styles.skeleton, { [styles.lightSkeleton]: darkBackground })}
      data-testid={'avatar-skeleton'}
    />
  ) : (
    <MuiAvatar src={src} className={classNames(styles.avatar, className)} alt={'avatar'} data-testid={'avatar'}>
      {icon || <Person />}
    </MuiAvatar>
  );

export default Avatar;
