import Modal from 'components/shared/Modal';
import { Alert, Button, Grid } from '@mui/material';
import React, { useCallback, useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { ModalProps } from 'store/types/ComponentProps';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import UserEmployerService from 'services/api/UserEmployerService';
import { ParametersContext } from 'components/ParametersGuard';
import EmployerSearchItem from 'components/profile/UserEmployerPageView/EmployerSearchItem';
import CompanyAccessRequestStatus from 'store/enums/CompanyAccessRequestStatus';
import { UserEmployerAccessRequestsData } from 'store/types/UserEmployerAccessRequest';
import { UserContext } from 'components/UserGuard';

import commonStyles from 'styles/common.module.scss';

interface RequestEmployerModalProps extends ModalProps {
  refetch: () => void;
  userRequests: UserEmployerAccessRequestsData;
}

const isPendingRequestToCompanyExist = (id: string, { incoming, outgoing }: UserEmployerAccessRequestsData): boolean =>
  incoming.some(({ requesterId, status }) => requesterId === id && status === CompanyAccessRequestStatus.Pending) ||
  outgoing.some(({ approverId, status }) => approverId === id && status === CompanyAccessRequestStatus.Pending);

const RequestEmployerModal: React.FunctionComponent<RequestEmployerModalProps> = ({
  open,
  userRequests,
  onClose,
  refetch,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [isPendingRequestExist, setIsPendingRequestExist] = useState<boolean>(false);
  const [employerId, setEmployerId] = useState<string>('');
  const {
    profile: { updateEmployerModalHeader },
  } = useContext(ParametersContext);
  const currentUser = useContext(UserContext);
  const { parentCompanyId } = currentUser;

  const handleRequestSubmit = useCallback(() => {
    setLoading(true);
    UserEmployerService.requestEmployer(employerId)
      .then(() => {
        enqueueSnackbar('Request successfully added', { variant: 'success' });
        setLoading(false);
        onClose();
        refetch();
      })
      .catch((errorMessage) => {
        enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        setLoading(false);
      });
  }, [employerId, enqueueSnackbar, onClose, refetch]);

  const handleSelectEmployerId = useCallback(
    (id: string) => {
      setEmployerId(id);
      setIsPendingRequestExist(isPendingRequestToCompanyExist(id, userRequests));
    },
    [userRequests]
  );

  return (
    <Modal
      loading={loading}
      title={'Update Employer'}
      open={open}
      maxWidth={'md'}
      actions={
        <>
          <Button color={'secondary'} variant={'outlined'} onClick={onClose}>
            {'Cancel'}
          </Button>
          <Button
            type={'submit'}
            variant={'contained'}
            onClick={handleRequestSubmit}
            disabled={loading || !employerId || isPendingRequestExist}
          >
            {'Request'}
          </Button>
        </>
      }
    >
      <Grid {...defaultGridContainerProps}>
        <Grid {...defaultGridItemProps}>
          {isPendingRequestExist && (
            <Alert severity={'error'} className={commonStyles.alert}>
              {'You already have a pending request to/from the same company.'}
            </Alert>
          )}
          {updateEmployerModalHeader && <p dangerouslySetInnerHTML={{ __html: updateEmployerModalHeader }} />}
          <h5 className={commonStyles.subTitle}>{'Enter Company name to search'}</h5>
          <EmployerSearchItem onSelectEmployerId={handleSelectEmployerId} companyId={parentCompanyId} />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default RequestEmployerModal;
