import { Grid } from '@mui/material';
import Table from 'components/shared/Table';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SortConfig, TableColumn } from 'store/types/Table';
import { defaultGridItemProps } from 'util/Layout';
import { NotifyingCompany } from 'store/types/Company';
import { sorter } from 'util/Table';

import styles from './NotifyingCompaniesTable.module.scss';

interface OrderHistoryTableProps {
  data?: NotifyingCompany[];
  loading?: boolean;
}

const OrderHistoryTable: React.FunctionComponent<OrderHistoryTableProps> = ({ data = [], loading = false }) => {
  const [sort, setSort] = useState<SortConfig<NotifyingCompany>>();
  const [list, setList] = useState<NotifyingCompany[]>([]);

  useEffect(() => {
    if (data.length) {
      setList(data);
    }
  }, [data]);

  const handleSortChange = useCallback((newSort: SortConfig) => {
    setSort(newSort);
    setList((prevState) =>
      prevState.length ? [...prevState].sort(sorter<NotifyingCompany>(newSort)) : [...prevState]
    );
  }, []);

  const columns: Array<TableColumn<NotifyingCompany>> = useMemo(
    () => [
      {
        dataIndex: 'name',
        label: 'Organization Name',
        sortable: true,
      },
      {
        dataIndex: 'contactType',
        label: 'My ContactType',
        sortable: true,
        align: 'center',
      },
    ],
    []
  );

  return (
    <Grid container={true} spacing={2}>
      <Grid {...defaultGridItemProps}>
        <Table
          cellClassName={styles.cells}
          itemsPerPage={10}
          loading={loading}
          columns={columns}
          list={list}
          sort={sort}
          onSortChange={handleSortChange}
          showPagination={true}
        />
      </Grid>
    </Grid>
  );
};
export default OrderHistoryTable;
