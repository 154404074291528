import { Button, Grid } from '@mui/material';
import classNames from 'classnames';
import { ConfigContext } from 'components/ConfigGuard';
import PaymentFormSection from 'components/payments/PaymentFormSection';
import FormFooter from 'components/shared/FormFooter';
import Spinner from 'components/shared/Spinner';
import Stepper from 'components/shared/Stepper';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import PaymentService, { BasePaymentData, EnrollSubscriptionPaymentData } from 'services/api/PaymentService';
import CollectJSResponse from 'store/types/CollectJSResponse';
import { PaymentFormValues, PaymentItemsFormValues } from 'store/types/FormValues';
import Payment from 'store/types/Payment';
import { SubscriptionPlanView } from 'store/types/Subscription';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import { getDonationsPaymentData, getSubscriptionSelectOptions, getSubscriptionSummaryList } from 'util/Membership';
import * as MUIcon from '@mui/icons-material';
import {
  defaultPaymentFormValues,
  donationsAmountSelected,
  getPaymentData,
  getSummaryTotalPrice,
  paymentFormInvalid,
  shouldValidatePaymentMethod,
} from 'util/Payment';
import { getISODateString } from 'util/Format';
import { PaymentMethodsContext } from 'components/PaymentMethodsContextWrapper';
import { DEFAULT_ERROR_MESSAGE, getAddressFormValues } from 'util/Form';
import useRequest from 'hooks/useRequest';
import CompanyService from 'services/api/CompanyService';
import { CompanyPaymentDetails } from 'store/types/Company';
import SubscriptionDonationStep from './SubscriptionDonationStep';
import SubscriptionPlanStep from './SubscriptionPlanStep';
import CompanySelect from 'components/company/CompanySelect';
import { CompanySelectOption } from 'util/Company';
import { CompaniesContext } from 'components/CompaniesContextWrapper';
import { SiteConfigView } from 'store/types/SiteConfig';

import commonStyles from 'styles/common.module.scss';
import styles from './EnrollSubscriptionPageView.module.scss';

interface EnrollSubscriptionPageViewProps {
  onPaymentCompleted: (newPayment: Payment) => void;
}

interface EnrollmentStepConfigItem {
  value: EnrollmentStep;
  label: string;
  icon: keyof typeof MUIcon;
}

enum EnrollmentStep {
  SelectCompany = 1,
  SelectPlan = 2,
  SelectDonation = 3,
  Payment = 4,
}

const defaultCompanyAddress = getAddressFormValues();

const EnrollSubscriptionPageView: React.FunctionComponent<EnrollSubscriptionPageViewProps> = ({
  onPaymentCompleted,
}) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { refetch: refetchPaymentMethods } = useContext(PaymentMethodsContext);
  const {
    modulesSettings: {
      subscriptions: { enableDonations },
      payments: { invoices },
    },
  }: SiteConfigView = useContext(ConfigContext);
  const [companyId, setCompanyId] = useState<string>('');
  const [subscriptionPlan, setSubscriptionPlan] = useState<SubscriptionPlanView | undefined>(undefined);
  const [donations, setDonations] = useState<PaymentItemsFormValues>({});
  const [paymentForm, setPaymentForm] = useState<PaymentFormValues>({
    ...defaultPaymentFormValues,
  });
  const { data: companyData = [] } = useContext(CompaniesContext);
  const options: CompanySelectOption[] = useMemo(() => getSubscriptionSelectOptions(companyData, false), [companyData]);
  const [step, setStep] = useState<EnrollmentStep>(EnrollmentStep.SelectCompany);
  const [loading, setLoading] = useState<boolean>(false);
  const companyDetailsRequest = useMemo(
    () => (companyId ? () => CompanyService.getCompanyPaymentDetails(companyId) : undefined),
    [companyId]
  );
  const { data, loading: companyDetailsLoading } = useRequest<CompanyPaymentDetails>(companyDetailsRequest);
  const companyAddress = useMemo(() => (data ? data.address : defaultCompanyAddress), [data]);

  const stepsConfig: EnrollmentStepConfigItem[] = useMemo(() => {
    const result: EnrollmentStepConfigItem[] = [
      {
        value: EnrollmentStep.SelectCompany,
        label: 'Select an Organization',
        icon: 'AddBusiness',
      },
      {
        value: EnrollmentStep.SelectPlan,
        label: 'Select a Subscription',
        icon: 'BookmarkAdd',
      },
      {
        value: EnrollmentStep.Payment,
        label: 'Complete Payment',
        icon: 'AttachMoney',
      },
    ];
    if (enableDonations) {
      result.splice(-1, 0, {
        value: EnrollmentStep.SelectDonation,
        label: 'Optional Donation',
        icon: 'VolunteerActivism',
      });
    }
    return result;
  }, [enableDonations]);
  const currentStepConfigIndex: number = useMemo(
    () => stepsConfig.findIndex(({ value }) => step === value),
    [step, stepsConfig]
  );
  const submitButtonDisabled: boolean = useMemo(() => {
    switch (step) {
      case EnrollmentStep.SelectCompany:
        return !companyId;
      case EnrollmentStep.SelectPlan:
        return !subscriptionPlan;
      case EnrollmentStep.SelectDonation:
        return !donationsAmountSelected(donations);
      case EnrollmentStep.Payment:
        return paymentFormInvalid(paymentForm);
      default:
        return true;
    }
  }, [step, companyId, subscriptionPlan, donations, paymentForm]);
  const { summaryList, totalPrice } = paymentForm;

  useEffect(() => {
    if (data && data.poNumber) {
      setPaymentForm((prevState) => ({
        ...prevState,
        poNumber: data.poNumber,
      }));
    }
  }, [data]);

  useEffect(() => {
    setStep(stepsConfig[0].value);
  }, [stepsConfig]);

  useEffect(() => {
    setPaymentForm((prevState) => ({
      ...prevState,
      totalPrice: getSummaryTotalPrice(summaryList),
    }));
  }, [summaryList]);

  useEffect(() => {
    setPaymentForm((prevState) => ({
      ...prevState,
      summaryList: getSubscriptionSummaryList(
        donations,
        subscriptionPlan,
        subscriptionPlan ? subscriptionPlan.usersAmount : 1
      ),
    }));
  }, [donations, subscriptionPlan]);

  const handlePlanSelected = useCallback((newPlan: SubscriptionPlanView) => {
    setSubscriptionPlan(newPlan);
  }, []);

  const handleDonationSelected = useCallback((newDonations: PaymentItemsFormValues) => {
    setDonations(newDonations);
  }, []);

  const handlePaymentChange = useCallback((formValues: Partial<PaymentFormValues>) => {
    setPaymentForm((prevForm) => ({ ...prevForm, ...formValues }));
  }, []);

  const handleCompanyChange = useCallback((newCompanyId: string) => {
    setCompanyId(newCompanyId);
  }, []);

  const enrollSubscription = useCallback(
    (collectJSResponse?: CollectJSResponse) => {
      if (subscriptionPlan) {
        const donationsData = getDonationsPaymentData(donations);
        const basePaymentData: BasePaymentData = getPaymentData(paymentForm, collectJSResponse);
        const startDate: string | null = subscriptionPlan.customStartDate
          ? getISODateString(subscriptionPlan.startDate)
          : null;
        const paymentData: EnrollSubscriptionPaymentData = {
          ...basePaymentData,
          planId: subscriptionPlan.id,
          companyId,
          donations: donationsData,
          autoRenewal: paymentForm.autoRenewal || false,
          quantity: subscriptionPlan.usersAmount,
          startDate: startDate || undefined,
        };

        PaymentService.makeSubscriptionPayment(paymentData)
          .then((newPayment: Payment) => {
            setLoading(false);
            refetchPaymentMethods();
            onPaymentCompleted(newPayment);
            enqueueSnackbar('Payment successfully completed', { variant: 'success' });
          })
          .catch((error: string) => {
            setLoading(false);
            enqueueSnackbar(error, defaultSnackbarErrorProps);
          });
      } else {
        enqueueSnackbar(DEFAULT_ERROR_MESSAGE, defaultSnackbarErrorProps);
      }
    },
    [paymentForm, subscriptionPlan, companyId, donations, enqueueSnackbar, onPaymentCompleted, refetchPaymentMethods]
  );

  const handlePaymentComplete = useCallback(
    (response: CollectJSResponse) => {
      enrollSubscription(response);
    },
    [enrollSubscription]
  );

  const handleNextStep = useCallback(() => {
    const newStepIndex: number = currentStepConfigIndex + 1;

    if (currentStepConfigIndex >= 0) {
      const nextStep: EnrollmentStep = stepsConfig[newStepIndex].value;

      if (nextStep === EnrollmentStep.Payment && totalPrice === 0) {
        (window as any).CollectJS.clearInputs();
      }
      setStep(nextStep);
    }
  }, [currentStepConfigIndex, totalPrice, stepsConfig]);

  const handlePrevStep = useCallback(() => {
    const newStepIndex: number = currentStepConfigIndex - 1;

    if (currentStepConfigIndex > 0) {
      const prevStep: EnrollmentStep = stepsConfig[newStepIndex].value;

      setStep(prevStep);
    } else if (currentStepConfigIndex === 0) {
      history.goBack();
    }
  }, [currentStepConfigIndex, history, stepsConfig]);

  const handlePaymentSubmit = useCallback(() => {
    setLoading(true);
    if (shouldValidatePaymentMethod(paymentForm)) {
      (window as any).CollectJS.startPaymentRequest();
    } else {
      enrollSubscription();
    }
  }, [paymentForm, enrollSubscription]);

  return (
    <Spinner loading={loading}>
      <form className={styles.container}>
        <Grid {...defaultGridContainerProps}>
          {step === EnrollmentStep.SelectPlan && (
            <Grid {...defaultGridItemProps}>
              <p className={commonStyles.text}>
                Please note that the Business and Industry Subscription covers a trainee for an entire year and can be
                used for more than one course during that year. A High School Subscription covers a trainee from August
                1 to June 30 and can be used for more than one course during that year. International customers, with
                the exception of U.S. Territories, are not eligible for subscriptions. A High school subscription
                purchased after May 1 of the current year will be allocated to the academic year beginning August 1 of
                the current year.
              </p>
              <br />
              <p className={commonStyles.text}>
                <b>All subscription sales are final.</b> Please use discretion when ordering subscriptions as no refunds
                or credits will be issued for unused subscriptions. Additional subscriptions can be ordered as needed.
              </p>
            </Grid>
          )}
          <Grid {...defaultGridItemProps}>
            <Stepper activeStep={step} config={stepsConfig} />
          </Grid>
          {stepsConfig.map(({ value }) => (
            <Grid
              {...defaultGridItemProps}
              key={value}
              md={12}
              className={classNames({ [commonStyles.hidden]: step !== value })}
            >
              {value === EnrollmentStep.SelectCompany && (
                <CompanySelect options={options} onChange={handleCompanyChange} />
              )}
              {value === EnrollmentStep.SelectPlan && (
                <SubscriptionPlanStep onChange={handlePlanSelected} value={subscriptionPlan} />
              )}
              {value === EnrollmentStep.SelectDonation && (
                <SubscriptionDonationStep value={donations} onChange={handleDonationSelected} />
              )}
              {value === EnrollmentStep.Payment && (
                <Spinner loading={companyDetailsLoading} transparent={false}>
                  <PaymentFormSection
                    form={paymentForm}
                    onChange={handlePaymentChange}
                    onPaymentComplete={handlePaymentComplete}
                    postponePaymentMethodVisible={true}
                    defaultAddress={companyAddress}
                    poNumberEnabled={true}
                    paymentConfig={invoices}
                    // TODO: apply after BE is ready
                    // discountEnabled={true}
                  />
                </Spinner>
              )}
            </Grid>
          ))}
        </Grid>
        <FormFooter
          onSubmit={step === EnrollmentStep.Payment ? handlePaymentSubmit : handleNextStep}
          submitButtonName={step === EnrollmentStep.Payment ? 'Submit' : 'Next'}
          submitButtonDisabled={submitButtonDisabled}
        >
          <Button color={'secondary'} variant={'outlined'} onClick={handlePrevStep}>
            {'Back'}
          </Button>
        </FormFooter>
      </form>
    </Spinner>
  );
};
export default EnrollSubscriptionPageView;
