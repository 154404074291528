import React from 'react';
import classNames from 'classnames';
import ComponentProps from 'store/types/ComponentProps';

import styles from './CountBadge.module.scss';

interface CountBadgeProps extends ComponentProps {
  darkBackground?: boolean;
}

const CountBadge: React.FunctionComponent<CountBadgeProps> = ({ className = '', darkBackground = false, children }) => (
  <span className={classNames(styles.badge, { [styles.light]: darkBackground }, className)} data-testid={'count-badge'}>
    {children}
  </span>
);

export default CountBadge;
