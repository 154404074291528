import { Button, Dialog, IconButton } from '@mui/material';
import FormFooter, { FormFooterProps } from 'components/shared/FormFooter';
import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import ApplicationContent, { ApplicationContentSectionView } from 'store/types/ApplicationContent';
import { getFormattedSections, getReviewSectionId, getSectionId } from 'util/Application';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import classNames from 'classnames';

import styles from './ApplicationPageLayout.module.scss';

interface ApplicationPageLayoutProps {
  data?: ApplicationContent;
  footerProps?: PropsWithChildren<FormFooterProps>;
  isReviewForm?: boolean;
  isPrintActive?: boolean;
}

const ApplicationPageLayout: React.FunctionComponent<ApplicationPageLayoutProps> = ({
  data,
  footerProps,
  children,
  isPrintActive,
}) => {
  const [sections, setSections] = useState<ApplicationContentSectionView[]>([]);
  const [collapsed, setCollapsed] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      setSections(getFormattedSections(data.sections));
    }
  }, [data]);

  const handleMenuButtonClick = () => {
    setCollapsed(!collapsed);
  };

  const handleLinkClick = useCallback(
    (index: number) => () => {
      const sectionElement: HTMLElement | null = document.getElementById(getSectionId(index));
      const reviewSectionElement: HTMLElement | null = document.getElementById(getReviewSectionId(index));

      if (sectionElement) {
        sectionElement.scrollIntoView();
      }
      if (reviewSectionElement) {
        reviewSectionElement.scrollIntoView();
      }
    },
    []
  );

  return (
    <>
      <Dialog open={true} fullScreen={true} className={classNames({ [styles.collapsed]: collapsed })}>
        {!isPrintActive && (
          <aside className={styles.fullPageSidebar}>
            <ul>
              {sections.map((section, index) => (
                <li key={`${section.title}${index}-icon`} className={styles.link} onClick={handleLinkClick(index)}>
                  <IconButton classes={{ root: styles.linkIcon }} color={'primary'}>
                    {++index}
                  </IconButton>
                  <span className={styles.linkLabel}>{section.title}</span>
                </li>
              ))}
            </ul>
            <Button variant={'text'} onClick={handleMenuButtonClick} className={styles.menuButton}>
              {collapsed ? <KeyboardDoubleArrowRightIcon /> : <KeyboardDoubleArrowLeftIcon />}
            </Button>
          </aside>
        )}
        <div className={classNames({ [styles.fullPageContent]: !isPrintActive })}>{children}</div>
        {!isPrintActive && <FormFooter {...footerProps} className={styles.fullPageFormFooter} />}
      </Dialog>
    </>
  );
};
export default ApplicationPageLayout;
