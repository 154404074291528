import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Grid, TextField, Button, InputAdornment } from '@mui/material';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import MakePaymentComponentProps from 'store/types/MakePaymentComponentProps';
import { CREDITS_LABEL, getSummaryTotalPrice } from 'util/Payment';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import classNames from 'classnames';
import PaymentSummaryItem from 'store/types/PaymentSummaryItem';

import styles from './PaymentCreditsSection.module.scss';
import commonStyles from 'styles/common.module.scss';

interface PaymentCreditMemoSectionProps extends Pick<MakePaymentComponentProps, 'form' | 'onChange'> {
  creditMemoBalance?: number;
}

const PaymentCreditsSection: React.FunctionComponent<PaymentCreditMemoSectionProps> = ({
  form,
  onChange,
  creditMemoBalance,
}) => {
  const [creditsValue, setCreditsValue] = useState<number>(0);
  const [errorText, setErrorText] = useState<string>('');
  const { summaryList, totalPrice = 0, credits = 0 } = form;

  useEffect(() => {
    if (!summaryList?.length) {
      setCreditsValue(0);
    } else {
      const newSummaryList: PaymentSummaryItem[] = [
        ...(summaryList?.filter((item) => item.name !== CREDITS_LABEL) || []),
      ];
      if (credits > getSummaryTotalPrice(newSummaryList)) {
        setCreditsValue(0);
        onChange({
          summaryList: newSummaryList,
          totalPrice: getSummaryTotalPrice(newSummaryList),
          credits: 0,
        });
        setErrorText('Please enter a valid credits value.');
      }
    }
  }, [credits, onChange, summaryList]);

  const handleCreditsChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value: number = parseFloat(e.target.value);
      setCreditsValue(value);
      if (creditMemoBalance) {
        if (value > creditMemoBalance || value > totalPrice + credits) {
          setErrorText('Please enter a valid credits value.');
        } else {
          setErrorText('');
        }
      }
    },
    [creditMemoBalance, totalPrice, credits]
  );

  const handleResetCredits = useCallback(() => {
    const newSummaryList: PaymentSummaryItem[] = [
      ...(summaryList?.filter((item) => item.name !== CREDITS_LABEL) || []),
    ];

    onChange({
      summaryList: newSummaryList,
      totalPrice: getSummaryTotalPrice(newSummaryList),
      credits: 0,
    });
    setCreditsValue(0);
    setErrorText('');
  }, [summaryList, onChange]);

  const handleSubmit = useCallback(() => {
    const newSummaryList: PaymentSummaryItem[] = [
      ...(summaryList?.filter((item) => item.name !== CREDITS_LABEL) || []),
      { name: CREDITS_LABEL, price: creditsValue },
    ];

    onChange({
      summaryList: newSummaryList,
      totalPrice: getSummaryTotalPrice(newSummaryList),
      credits: creditsValue,
    });
  }, [onChange, creditsValue, summaryList]);

  return (
    <Grid {...defaultGridContainerProps} spacing={2}>
      <Grid {...defaultGridItemProps} xs={8}>
        <TextField
          label={'Credits'}
          value={creditsValue}
          onChange={handleCreditsChange}
          disabled={!creditMemoBalance}
          type={'number'}
          helperText={errorText}
          error={!!errorText}
          InputProps={{
            inputProps: { min: 0 },
            startAdornment: <InputAdornment position={'start'}>$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid {...defaultGridItemProps} className={styles.buttonsWrapper} xs={4}>
        <Button
          fullWidth={true}
          variant={'outlined'}
          className={styles.applyButton}
          onClick={handleSubmit}
          disabled={!creditsValue || !!errorText}
        >
          {'Apply'}
        </Button>
        <Button
          variant={'outlined'}
          className={classNames(commonStyles.dangerButtonOutlined, styles.deleteButton)}
          onClick={handleResetCredits}
          disabled={!credits}
        >
          <DeleteOutlined />
        </Button>
      </Grid>
    </Grid>
  );
};
export default PaymentCreditsSection;
