import { Button, Grid } from '@mui/material';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Modal from 'components/shared/Modal';
import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { ModalProps } from 'store/types/ComponentProps';
import { BasePayment } from 'store/types/Payment';
import { CurrentUser } from 'store/types/User';
import { getFormattedDate } from 'util/Format';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { UserContext } from 'components/UserGuard';
import routes from 'store/configs/Routes';
import { getHashRouteUrl } from 'util/Route';

import styles from './ApplicationConfirmationModal.module.scss';

interface ApplicationPaymentDetailsModalProps extends Pick<ModalProps, 'open'> {
  payment?: BasePayment;
  showPaymentLink?: boolean;
}

const ApplicationConfirmationModal: React.FunctionComponent<ApplicationPaymentDetailsModalProps> = ({
  open,
  payment,
  showPaymentLink = false,
}) => {
  const { applicationId = '' } = useParams<{ applicationId: string }>();
  const currentUser: CurrentUser = useContext(UserContext);

  return (
    <Modal
      open={open}
      maxWidth={'xs'}
      title={
        <div className={styles.title}>
          <CheckCircle className={styles.headerIcon} />
          {'Application Submitted'}
        </div>
      }
      actions={
        showPaymentLink ? (
          <Button
            color={'secondary'}
            variant={'contained'}
            href={getHashRouteUrl(`${routes.payApplication}/${applicationId}`)}
            className={styles.button}
          >
            {'Go to Payment'}
          </Button>
        ) : (
          <Button
            color={'secondary'}
            variant={'contained'}
            href={getHashRouteUrl(routes.applications)}
            className={styles.button}
          >
            {'Go to Applications'}
          </Button>
        )
      }
    >
      <p className={styles.description}>
        {payment ? (
          <>
            {'Thank you, your payment has been successfully processed. A confirmation email has been sent to '}
            <span className={styles.email}>{currentUser.email}</span>
          </>
        ) : (
          'Thank you, your application has been submitted.'
        )}
      </p>
      <Grid {...defaultGridContainerProps} className={styles.paymentInfo}>
        <Grid {...defaultGridItemProps} className={styles.paymentInfoSection}>
          {applicationId && (
            <div className={styles.row}>
              <span className={styles.label}>Application Number:</span>
              <span className={styles.value}>{applicationId}</span>
            </div>
          )}
          <div className={styles.row}>
            <span className={styles.label}>Date:</span>
            <span className={styles.value}>
              {getFormattedDate(payment ? payment.orderDate : new Date().toDateString())}
            </span>
          </div>
        </Grid>
      </Grid>
    </Modal>
  );
};
export default ApplicationConfirmationModal;
