import { Button, Container, Grid, GridProps, Link, MenuItem, TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import Pagination from '@mui/material/Pagination';
import Skeleton from '@mui/material/Skeleton';
import React, { ChangeEvent, Reducer, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import useRequest from 'hooks/useRequest';
import { getPagesCount, sorter } from 'util/Table';
import { defaultGridContainerProps, defaultGridItemProps, getInputLoadingProps } from 'util/Layout';
import DonationFund, { DonationFundItem } from 'store/types/DonationFund';
import SearchInput from 'components/shared/SearchInput';
import CreateCampaignModal from 'components/donation/CreateCampaignModal';
import DonationService from 'services/api/DonationService';
import { ConfigContext } from 'components/ConfigGuard';
import SiteModule from 'store/enums/SiteModule';
import DonationListItem from './DonationListItem';
import { UserContext } from 'components/UserGuard';
import { getHashRouteUrl } from 'util/Route';
import routes from 'store/configs/Routes';
import ArrowBack from '@mui/icons-material/ArrowBack';
import classNames from 'classnames';
import DonationWarningModal from 'components/donation/DonationPaymentPageView/DonationWarningModal';
import { useHistory, useLocation } from 'react-router';
import reducer, {
  ALL_CUSTOMERS_FIELD_NAME,
  initialState,
  ITEMS_PER_PAGE,
  SelectDonationPageAction,
  SelectDonationPageActionType,
  SelectDonationPageState,
} from './SelectDonationPageReducer';
import Donation from 'store/types/Donation';
import DonationCampaignStatus from 'store/enums/DonationCampaignStatus';
import GamsdLogo from 'assets/images/gamsd/logo.png';

import commonStyles from 'styles/common.module.scss';
import styles from './SelectDonationPage.module.scss';

const listItemRowProps: GridProps = { sm: 6, md: 3 };

const getFormattedList = (list: DonationFund[] = []): DonationFundItem[] =>
  list
    .filter(({ campaign }) => campaign.status === DonationCampaignStatus.Active)
    .map(({ donation, campaign }) => ({
      ...donation,
      name: donation.name,
      partner: campaign.partner.name,
    }))
    .sort(sorter<Donation>({ column: 'name', direction: 'asc' }));

const getPartnersList = (list: DonationFund[] = []): string[] =>
  list.length
    ? Array.from(
        new Set(
          list
            .filter<Required<DonationFund>>(
              (donationFund): donationFund is Required<DonationFund> =>
                !!donationFund.campaign.partner.name && donationFund.campaign.status === DonationCampaignStatus.Active
            )
            .map(({ campaign }) => campaign.partner.name)
        )
      ).sort()
    : [];

const SelectDonationPage: React.FunctionComponent = () => {
  const [{ list = [], pageList = [], page = 1, warningModalOpen, createCampaignModalOpen, filter }, dispatch] =
    useReducer<Reducer<SelectDonationPageState, SelectDonationPageAction>>(reducer, initialState);
  const { pathname, search } = useLocation();
  const history = useHistory();
  const { enabledModules = [], isCompassTheme, isGamsdTheme } = useContext(ConfigContext);
  const currentUser = useContext(UserContext);
  const { data = [], error, loading } = useRequest<DonationFund[]>(DonationService.getDonationFunds);
  const pagesCount: number = useMemo(() => getPagesCount(list.length, ITEMS_PER_PAGE), [list]);
  const hasAuthorizedUser: boolean = useMemo(() => !!currentUser.id, [currentUser]);
  const partnersList: string[] = useMemo(() => getPartnersList(data), [data]);

  useEffect(() => {
    if (data?.length) {
      dispatch({
        type: SelectDonationPageActionType.SetInitialList,
        payload: { initialList: getFormattedList(data), filter },
      });
    }
  }, [data, filter]);

  useEffect(() => {
    const params: URLSearchParams = new URLSearchParams(search);
    const partner: string = params.get('partner') || ALL_CUSTOMERS_FIELD_NAME;

    dispatch({
      type: SelectDonationPageActionType.UpdateFilter,
      payload: { filter: { partner } },
    });
  }, [search]);

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: SelectDonationPageActionType.UpdateFilter,
      payload: { filter: { search: e.target.value } },
    });
  }, []);

  const handlePageChange = useCallback((_: any, newPage: number) => {
    window.scrollTo(0, 0);
    dispatch({
      type: SelectDonationPageActionType.UpdatePage,
      payload: { page: newPage },
    });
  }, []);

  const handleCreateCampaignClick = useCallback(() => {
    dispatch({
      type: hasAuthorizedUser
        ? SelectDonationPageActionType.OpenCreateCampaignModal
        : SelectDonationPageActionType.OpenWarningModal,
      payload: {},
    });
  }, [hasAuthorizedUser]);

  const handleCampaignModalClose = useCallback(() => {
    dispatch({
      type: SelectDonationPageActionType.CloseCreateCampaignModal,
      payload: {},
    });
  }, []);

  const handleWarningModalClose = useCallback(() => {
    dispatch({
      type: SelectDonationPageActionType.CloseWarningModal,
      payload: {},
    });
  }, []);

  const handleFilterChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value: string = e.target.value;
      const newParams: URLSearchParams = new URLSearchParams();
      if (value) {
        newParams.set('partner', value.toString());
      }
      history.replace({ pathname, search: `?${newParams.toString()}` });
    },
    [pathname, history]
  );

  return (
    <>
      <section>
        <Container maxWidth={'xl'} className={classNames(styles.sectionContent, styles.header)}>
          {enabledModules.includes(SiteModule.DonationsPremium) && hasAuthorizedUser && (
            <Link href={getHashRouteUrl(routes.manageDonations)} className={styles.backLink} color={'textPrimary'}>
              <ArrowBack className={styles.backIcon} />
              {'Back'}
            </Link>
          )}
          {isGamsdTheme && (
            <Link href={'http://www.medicalservicedogs.org'} className={styles.logo} target={'_blank'}>
              <img src={GamsdLogo} alt={'logo'} />
            </Link>
          )}
        </Container>
      </section>
      <section>
        <Container maxWidth={'xl'} className={styles.sectionContent}>
          <Grid {...defaultGridContainerProps}>
            <Grid {...defaultGridItemProps} sm={true}>
              <h1 className={styles.pageTitle}>Donate Today</h1>
            </Grid>
            {enabledModules.includes(SiteModule.DonationsPremium) && !isCompassTheme && (
              <Grid {...defaultGridItemProps} xs={'auto'} className={styles.createCampaignButtonWrapper}>
                <Button color={'secondary'} variant={'contained'} onClick={handleCreateCampaignClick}>
                  {'Create a Campaign'}
                </Button>
              </Grid>
            )}
            <Grid {...defaultGridItemProps}>
              <p className={commonStyles.text}>
                {isGamsdTheme
                  ? 'Together, We can Unleash the Power to Heal.'
                  : 'Our organization is very grateful that you have decided to donate to funds and causes we support.'}
              </p>
            </Grid>
            <Grid {...defaultGridItemProps} xs={12} md={8}>
              <SearchInput value={filter.search} onChange={handleSearchChange} loading={loading} />
            </Grid>
            <Grid {...defaultGridItemProps} xs={4} className={commonStyles.hidden}>
              <TextField
                disabled={loading}
                InputProps={getInputLoadingProps(loading)}
                select={true}
                size={'small'}
                value={filter.partner}
                onChange={handleFilterChange}
              >
                <MenuItem value={ALL_CUSTOMERS_FIELD_NAME}>{ALL_CUSTOMERS_FIELD_NAME}</MenuItem>
                {partnersList.map((partner) => (
                  <MenuItem value={partner} key={`donation-${partner}`}>
                    {partner}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section className={styles.darkSection}>
        <Container maxWidth={'xl'} className={styles.sectionContent}>
          {error ? (
            <Alert severity={'error'}>{error}</Alert>
          ) : (
            <Grid {...defaultGridContainerProps}>
              {loading ? (
                <>
                  <Grid {...defaultGridItemProps} {...listItemRowProps}>
                    <Skeleton className={styles.skeletonCard} variant={'rectangular'} />
                  </Grid>
                  <Grid {...defaultGridItemProps} {...listItemRowProps}>
                    <Skeleton className={styles.skeletonCard} variant={'rectangular'} />
                  </Grid>
                  <Grid {...defaultGridItemProps} {...listItemRowProps}>
                    <Skeleton className={styles.skeletonCard} variant={'rectangular'} />
                  </Grid>
                  <Grid {...defaultGridItemProps} {...listItemRowProps}>
                    <Skeleton className={styles.skeletonCard} variant={'rectangular'} />
                  </Grid>
                </>
              ) : pageList.length ? (
                pageList.map((donationFund) => (
                  <Grid key={`select-donation-${donationFund.id}`} {...defaultGridItemProps} {...listItemRowProps}>
                    <DonationListItem donationFund={donationFund} />
                  </Grid>
                ))
              ) : (
                <Grid {...defaultGridItemProps} className={styles.notFoundSection}>
                  {!partnersList.includes(filter.partner) && filter.partner !== ALL_CUSTOMERS_FIELD_NAME ? (
                    <Alert severity={'warning'} className={commonStyles.alert}>
                      The web URL appears to be incorrect.
                    </Alert>
                  ) : (
                    <p>No donations found</p>
                  )}
                </Grid>
              )}
            </Grid>
          )}
        </Container>
      </section>
      <section>
        <Container maxWidth={'xl'} className={styles.sectionContent}>
          <div className={styles.paginationWrapper}>
            <Pagination
              disabled={loading}
              showFirstButton={true}
              showLastButton={true}
              count={pagesCount}
              page={page}
              onChange={handlePageChange}
            />
          </div>
        </Container>
      </section>
      <CreateCampaignModal
        open={createCampaignModalOpen}
        onClose={handleCampaignModalClose}
        donations={data?.map(({ campaign }) => campaign)}
      />
      <DonationWarningModal
        onClose={handleWarningModalClose}
        open={warningModalOpen}
        description={
          'Sign In to create your personal donation campaign and view your donation history. If this is your first time logging in, create an account to complete your donation campaign setup.'
        }
        buttonName={'Sign In or Create Account'}
      />
    </>
  );
};
export default SelectDonationPage;
