import React, { useCallback, useContext } from 'react';
import { CommunityDetails } from 'store/types/Community';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { Divider, Grid } from '@mui/material';
import CommitteeService from 'services/api/CommitteeService';
import useRequest from 'hooks/useRequest';
import Alert from '@mui/material/Alert';
import { UserContext } from 'components/UserGuard';
import Spinner from 'components/shared/Spinner';
import { useParams } from 'react-router';
import CommunityMembersSection from 'components/communities/CommunityMembersSection';
import commonStyles from 'styles/common.module.scss';
import styles from './/PublicCommitteeDetailsPage.module.scss';

const PublicCommitteeDetailsPage: React.FunctionComponent = () => {
  const { id = '' } = useParams<{ id: string }>();
  const { userLoading = false } = useContext(UserContext);
  const committeeDetailsRequest = useCallback(() => CommitteeService.getCommitteeDetails(id), [id]);
  const committeeDetailsRequestData = useRequest<CommunityDetails>(committeeDetailsRequest);
  const { data, loading, error } = committeeDetailsRequestData;

  return (
    <Spinner loading={userLoading || loading} transparent={false}>
      {error ? (
        <Alert severity={'error'} className={commonStyles.alert}>
          {error}
        </Alert>
      ) : (
        data && (
          <Grid {...defaultGridContainerProps} spacing={3}>
            <Grid {...defaultGridItemProps}>
              <h2 className={styles.title}>{data.name}</h2>
              <Divider className={styles.divider} />
            </Grid>
            {data.description && (
              <Grid {...defaultGridItemProps}>
                <p className={commonStyles.text}>{data.description}</p>
              </Grid>
            )}
            <Grid {...defaultGridItemProps}>
              <CommunityMembersSection
                {...committeeDetailsRequestData}
                data={data.members}
                communityName={data.name}
                roles={[]}
                communityId={id}
                description={'The list of committee members.'}
              />
            </Grid>
          </Grid>
        )
      )}
    </Spinner>
  );
};
export default PublicCommitteeDetailsPage;
