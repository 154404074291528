import { ConfigSelectOption } from 'store/types/SelectOption';
import ApplicationStatus from 'store/enums/ApplicationStatus';

const applicationStatusConfig: Record<ApplicationStatus, ConfigSelectOption> = {
  [ApplicationStatus.Draft]: {
    name: 'Draft',
    theme: 'grey',
  },
  [ApplicationStatus.PendingPayment]: {
    name: 'Pending Payment',
    theme: 'yellow',
  },
  [ApplicationStatus.InformationRequired]: {
    name: 'More Information Needed',
    theme: 'dark-yellow',
  },
  [ApplicationStatus.Submitted]: {
    name: 'Submitted',
    theme: 'blue',
  },
  [ApplicationStatus.UnderReview]: {
    name: 'Under Review',
    theme: 'violet',
  },
  [ApplicationStatus.Approved]: {
    name: 'Approved',
    theme: 'green',
  },
  [ApplicationStatus.Denied]: {
    name: 'Denied',
    theme: 'red',
  },
};

export default applicationStatusConfig;
