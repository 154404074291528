import React, { useContext } from 'react';
import MembershipInfoPage from 'components/subscriptions/MembershipInfoPage';
import { MembershipContext } from 'components/MembershipGuard';
import { UserContext } from 'components/UserGuard';
import Spinner from 'components/shared/Spinner';
import SiteModule from 'store/enums/SiteModule';
import { getPageTitle } from 'util/Layout';
import { ConfigContext } from 'components/ConfigGuard';

import commonStyles from 'styles/common.module.scss';

const MembershipPage: React.FunctionComponent = () => {
  const { sidebar } = useContext(ConfigContext);
  const { membershipLoading = false } = useContext(MembershipContext);
  const { userLoading = false } = useContext(UserContext);

  return (
    <>
      <h1 className={commonStyles.pageTitle}>{getPageTitle(sidebar, SiteModule.Membership) || 'Membership'}</h1>
      <Spinner loading={userLoading || membershipLoading} transparent={false}>
        <MembershipInfoPage />
      </Spinner>
    </>
  );
};
export default MembershipPage;
