import { Button, Container, IconButton } from '@mui/material';
import MemberplexLogo from 'assets/images/default/logo-black.svg';
import SmacnaLogo from 'assets/images/smacna/logo.png';
import classNames from 'classnames';
import { ConfigContext } from 'components/ConfigGuard';
import EventCartIcon from './EventCartIcon';
import { UserContext } from 'components/UserGuard';
import React, { useContext, useMemo } from 'react';
import routes from 'store/configs/Routes';
import { getAppMenuConfig, getHashRouteUrl, getLoginPath } from 'util/Route';
import MenuConfigItem from 'store/types/MenuConfigItem';
import { useLocation } from 'react-router';
import { MenuOutlined } from '@mui/icons-material';
import { useWindowSize } from 'util/Window';
import ActionsMenu from 'components/shared/ActionsMenu';
import commonStyles from 'styles/common.module.scss';

import styles from './EventsPageHeader.module.scss';

const EventsPageHeader: React.FunctionComponent = () => {
  const currentUser = useContext(UserContext);
  const { id, userLoading } = currentUser;
  const { isMobile } = useWindowSize();
  const { isSmacnaTheme, sidebar } = useContext(ConfigContext);
  const hasAuthorizedUser = !!id;
  const menuConfig: MenuConfigItem[] = useMemo(() => getAppMenuConfig(currentUser, sidebar), [currentUser, sidebar]);

  const { pathname } = useLocation();

  return (
    <header className={styles.header}>
      <Container maxWidth={'lg'} className={styles.content}>
        <a href={'/'} className={styles.logoWrapper}>
          <img
            src={isSmacnaTheme ? SmacnaLogo : MemberplexLogo}
            alt={'logo'}
            className={classNames(styles.logo, { [styles.smacnaLogo]: isSmacnaTheme })}
          />
        </a>
        {hasAuthorizedUser ? (
          <div>
            <IconButton
              className={classNames(styles.actionButton, styles.cartButton)}
              href={getHashRouteUrl(routes.eventCart)}
              disabled={userLoading}
            >
              <EventCartIcon className={styles.actionIcon} />
            </IconButton>
            <ActionsMenu
              menuConfig={menuConfig}
              buttonIcon={<MenuOutlined className={styles.actionIcon} />}
              disabled={userLoading}
              buttonClassName={classNames(styles.actionButton, { [commonStyles.hidden]: !isMobile })}
            />
          </div>
        ) : (
          <Button
            color={'secondary'}
            variant={'contained'}
            className={styles.loginButton}
            href={getLoginPath(pathname)}
          >
            {'Sign In'}
          </Button>
        )}
      </Container>
    </header>
  );
};
export default EventsPageHeader;
