import React, { useContext } from 'react';
import { Button, Divider } from '@mui/material';
import classNames from 'classnames';
import StatusLabel from 'components/shared/StatusLabel';
import { MembershipContext } from 'components/MembershipGuard';
import { CurrentMembership } from 'store/types/Membership';
import routes from 'store/configs/Routes';
import { getHashRouteUrl } from 'util/Route';
import { getFormattedDate } from 'util/Format';
import Spinner from 'components/shared/Spinner';
import SubscriptionStatus from 'store/enums/SubscriptionStatus';
import {
  hasMembershipDateExpired,
  hasGracePeriodExpired,
  isJoinMembershipPage,
  isRenewMembershipPage,
  isReJoinMembershipPage,
} from 'util/Membership';
import Card from 'components/shared/Card';
import membershipStatusConfig from 'store/configs/MembershipStatusConfig';
import MembershipWalletButton from './MembershipWalletButton';

import commonStyles from 'styles/common.module.scss';
import styles from './MembershipStatusCard.module.scss';

const MembershipStatusCard: React.FunctionComponent = () => {
  const currentMembership: CurrentMembership = useContext(MembershipContext);
  const membershipDateExpired = hasMembershipDateExpired(currentMembership.endDate);
  const gracePeriodExpired = hasGracePeriodExpired(currentMembership);
  const renewText: React.ReactNode = currentMembership.endDate
    ? membershipDateExpired && !gracePeriodExpired
      ? `You have been granted a ${currentMembership.gracePeriod} day grace period. All dues must be paid immediately to retain your membership.`
      : getFormattedDate(currentMembership.endDate)
    : null;
  const statusConfig = membershipStatusConfig[currentMembership.status];

  return (
    <Card className={styles.card} contentClassName={styles.content}>
      {currentMembership.status === SubscriptionStatus.Pending ? (
        <div className={styles.pendingSection}>
          <div className={styles.spinnerWrapper}>
            <Spinner loading={true} />
          </div>
          <div className={styles.descriptionWrapper}>
            <p className={commonStyles.text}>
              Your membership card will be available momentarily. Thank you for your patience.
            </p>
          </div>
        </div>
      ) : (
        <>
          <section>
            <div className={styles.row}>
              <h4 className={styles.title}>Status:</h4>
              {statusConfig && (
                <StatusLabel
                  theme={statusConfig.theme}
                  status={statusConfig.name}
                  className={classNames(styles.value, styles.status)}
                />
              )}
            </div>
            {currentMembership.memberType && (
              <div className={styles.row}>
                <h4 className={styles.title}>Member Type:</h4>
                <StatusLabel theme={'yellow'} status={currentMembership.memberType} className={styles.value} />
              </div>
            )}
            {currentMembership.startDate && (
              <div className={styles.row}>
                <h4 className={styles.title}>Joined:</h4>
                <span className={styles.value}>{getFormattedDate(currentMembership.startDate)}</span>
              </div>
            )}
            {currentMembership.endDate && (
              <div className={styles.row}>
                <h4 className={styles.title}>{gracePeriodExpired ? 'Expired on:' : 'Renew by:'}</h4>
                <span className={styles.value}>{renewText}</span>
              </div>
            )}
            <div className={styles.row}>
              <MembershipWalletButton className={styles.value} />
            </div>
          </section>
          <Divider className={styles.divider} />
          {currentMembership.description && (
            <section className={styles.benefitsSection}>
              <h4 className={styles.title}>Membership Benefits:</h4>
              <div className={styles.benefits} dangerouslySetInnerHTML={{ __html: currentMembership.description }} />
            </section>
          )}
          <div className={styles.buttonRow}>
            {isJoinMembershipPage(currentMembership) ? (
              <Button variant={'contained'} color={'secondary'} href={getHashRouteUrl(routes.enrollMembership)}>
                Join Now
              </Button>
            ) : isReJoinMembershipPage(currentMembership) ? (
              <Button variant={'contained'} color={'secondary'} href={getHashRouteUrl(routes.enrollMembership)}>
                Re-join Now
              </Button>
            ) : isRenewMembershipPage(currentMembership) ? (
              <Button variant={'contained'} color={'secondary'} href={getHashRouteUrl(routes.enrollMembership)}>
                Renew Now
              </Button>
            ) : null}
          </div>
        </>
      )}
    </Card>
  );
};
export default MembershipStatusCard;
