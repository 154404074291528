import { Button, Link } from '@mui/material';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import Alert from '@mui/material/Alert';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import React, { Reducer, useCallback, useEffect, useReducer, useState } from 'react';
import Table from 'components/shared/Table';
import { SortConfig, TableColumn } from 'store/types/Table';
import { getFormattedDate, NUMBER_DATE_FORMAT } from 'util/Format';
import Card from 'components/shared/Card';
import Modal from 'components/shared/Modal';
import FileInfo from 'store/types/FileInfo';
import { DEFAULT_ERROR_MESSAGE } from 'util/Form';
import UseRequestData from 'store/types/UseRequestData';
import reducer, {
  initialState,
  CommunityFilesSectionAction,
  CommunityFilesSectionState,
  CommunityFilesSectionActionType,
} from './CommunityFilesSectionReducer';
import CloudUpload from '@mui/icons-material/CloudUpload';
import { defaultSnackbarErrorProps } from 'util/Layout';

import commonStyles from 'styles/common.module.scss';
import pageStyles from '../CommunityDetailsSection.module.scss';
import styles from './CommunityFilesSection.module.scss';

interface CommunityFilesSectionRequests {
  uploadFileRequest: (communityId: string, newFile: File) => Promise<null>;
  downloadFileRequest: (communityId: string, file: FileInfo) => Promise<null>;
  deleteFileRequest?: (communityId: string, fileId: string) => Promise<null>;
}

interface CommunityFilesSectionProps extends UseRequestData<FileInfo[]> {
  communityId: string;
  description?: string;
  editEnabled?: boolean;
  requests: CommunityFilesSectionRequests;
}

const uploadInputId = 'community-files-upload-input';

const CommunityFilesSection: React.FunctionComponent<CommunityFilesSectionProps> = ({
  communityId,
  editEnabled = false,
  description = '',
  requests,
  data,
  loading,
  error,
  refetch,
}) => {
  const { uploadFileRequest, downloadFileRequest, deleteFileRequest } = requests;
  const { enqueueSnackbar } = useSnackbar();
  const [uploadedFile, setUploadedFile] = useState<File | undefined>(undefined);
  const [{ sort, list, selectedItem, submitLoading }, dispatch] = useReducer<
    Reducer<CommunityFilesSectionState, CommunityFilesSectionAction>
  >(reducer, initialState);

  useEffect(() => {
    if (data) {
      dispatch({
        type: CommunityFilesSectionActionType.SetInitialList,
        payload: { initialList: data },
      });
    }
  }, [data]);

  useEffect(() => {
    if (uploadedFile) {
      dispatch({
        type: CommunityFilesSectionActionType.SetSubmitLoading,
        payload: { submitLoading: true },
      });
      uploadFileRequest(communityId, uploadedFile)
        .then(() => {
          dispatch({
            type: CommunityFilesSectionActionType.SetSubmitLoading,
            payload: { submitLoading: false },
          });
          enqueueSnackbar('File successfully uploaded', { variant: 'success' });
          refetch();
        })
        .catch((errorMessage: string) => {
          dispatch({
            type: CommunityFilesSectionActionType.SetSubmitLoading,
            payload: { submitLoading: false },
          });
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        })
        .finally(() => {
          setUploadedFile(undefined);
          (document as any).getElementById(uploadInputId).value = '';
        });
    }
  }, [uploadedFile, communityId, enqueueSnackbar, refetch, uploadFileRequest]);

  const handleFileLinkClick = useCallback(
    (record: FileInfo) => () => {
      dispatch({
        type: CommunityFilesSectionActionType.SetSubmitLoading,
        payload: { submitLoading: true },
      });
      downloadFileRequest(communityId, record)
        .then(() => {
          dispatch({
            type: CommunityFilesSectionActionType.SetSubmitLoading,
            payload: { submitLoading: false },
          });
        })
        .catch((errorMessage: string) => {
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
          dispatch({
            type: CommunityFilesSectionActionType.SetSubmitLoading,
            payload: { submitLoading: false },
          });
        });
    },
    [enqueueSnackbar, communityId, downloadFileRequest]
  );

  const handleSortChange = useCallback(
    (newSort: SortConfig) => {
      dispatch({
        type: CommunityFilesSectionActionType.UpdateSort,
        payload: { sort: newSort },
      });
    },
    [dispatch]
  );

  const handleUploadInputChange = useCallback(() => {
    const selectedFile: File = (document as any).getElementById(uploadInputId).files[0];

    if (selectedFile.size > 2000000) {
      enqueueSnackbar('Document should be less than 2MB', defaultSnackbarErrorProps);
    } else {
      setUploadedFile(selectedFile);
    }
  }, [enqueueSnackbar]);

  const handleDeleteButtonClick = useCallback(
    (record: FileInfo) => () => {
      dispatch({
        type: CommunityFilesSectionActionType.OpenConfirmationModal,
        payload: { selectedItem: record },
      });
    },
    []
  );

  const handleConfirmationModalClose = useCallback(() => {
    dispatch({ type: CommunityFilesSectionActionType.CloseConfirmationModal, payload: {} });
  }, []);

  const handleDeleteConfirm = useCallback(() => {
    if (selectedItem && deleteFileRequest) {
      dispatch({
        type: CommunityFilesSectionActionType.SetSubmitLoading,
        payload: { submitLoading: true },
      });

      deleteFileRequest(communityId, selectedItem.id)
        .then(() => {
          dispatch({
            type: CommunityFilesSectionActionType.CloseConfirmationModal,
            payload: {},
          });
          enqueueSnackbar(`File successfully deleted`, { variant: 'success' });
          refetch();
        })
        .catch((errorMessage: string) => {
          dispatch({
            type: CommunityFilesSectionActionType.SetSubmitLoading,
            payload: { submitLoading: false },
          });
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        });
    } else {
      enqueueSnackbar(DEFAULT_ERROR_MESSAGE, defaultSnackbarErrorProps);
    }
  }, [selectedItem, enqueueSnackbar, refetch, communityId, deleteFileRequest]);

  const columns: Array<TableColumn<FileInfo>> = [
    {
      dataIndex: 'name',
      label: 'Name',
      sortable: true,
      render: (name: string, record: FileInfo) => (
        <Link onClick={handleFileLinkClick(record)} className={styles.link}>
          {name}
        </Link>
      ),
    },
    {
      dataIndex: 'created',
      label: 'Date',
      sortable: true,
      render: (created: string) => getFormattedDate(created, NUMBER_DATE_FORMAT),
    },
  ];

  if (editEnabled) {
    columns.push({
      key: 'action',
      label: 'Action',
      align: 'center',
      render: (_: any, record: FileInfo) => (
        <Button
          size={'small'}
          onClick={handleDeleteButtonClick(record)}
          variant={'outlined'}
          className={classNames(commonStyles.dangerButtonOutlined, pageStyles.sectionTableActionButton)}
        >
          <DeleteOutlined className={styles.icon} />
        </Button>
      ),
    });
  }

  return (
    <>
      <Card
        loading={submitLoading}
        bordered={true}
        headerAction={
          editEnabled && (
            <>
              <input
                type={'file'}
                id={uploadInputId}
                className={styles.uploadInput}
                onChange={handleUploadInputChange}
              />
              <label htmlFor={uploadInputId}>
                <Button
                  color={'primary'}
                  variant={'contained'}
                  startIcon={<CloudUpload />}
                  className={pageStyles.sectionCardActionButton}
                  size={'small'}
                  component={'span'}
                >
                  Upload Files
                </Button>
              </label>
            </>
          )
        }
        title={<h3>Files</h3>}
        headerClassName={pageStyles.sectionHeader}
      >
        {description && <p className={pageStyles.sectionDescription}>{description}</p>}
        {error ? (
          <Alert severity={'error'} className={commonStyles.alert}>
            {error}
          </Alert>
        ) : (
          <Table
            columns={columns}
            list={list}
            sort={sort}
            onSortChange={handleSortChange}
            className={classNames(pageStyles.sectionTable, styles.table)}
            loading={loading}
          />
        )}
      </Card>
      <Modal
        title={'Delete File?'}
        open={!!selectedItem}
        maxWidth={'xs'}
        loading={submitLoading}
        actions={
          <>
            <Button
              color={'secondary'}
              variant={'outlined'}
              onClick={handleConfirmationModalClose}
              disabled={submitLoading}
            >
              Cancel
            </Button>
            <Button
              type={'submit'}
              variant={'contained'}
              disabled={submitLoading}
              onClick={handleDeleteConfirm}
              className={commonStyles.dangerButtonContained}
            >
              Delete
            </Button>
          </>
        }
      >
        <p className={commonStyles.text}>This cannot be undone.</p>
      </Modal>
    </>
  );
};
export default CommunityFilesSection;
