import Alert from '@mui/material/Alert';
import React, { useEffect, useMemo, useState } from 'react';
import PreferencesService from 'services/api/PreferencesService';
import useRequest from 'hooks/useRequest';
import Spinner from 'components/shared/Spinner';
import { PreferenceQuestionView, PrivatePreferences } from 'store/types/PreferenceQuestion';
import { getPreferencesQuestionsView } from 'util/Preferences';
import EditPreferencesPageView from 'components/profile/EditPreferencesPage/EditPreferencesPageView';
import { useLocation, useParams } from 'react-router';
import { Container } from '@mui/material';
import routes from 'store/configs/Routes';
import EventService from 'services/api/EventService';

import commonStyles from 'styles/common.module.scss';
import styles from './PreferencesPrivatePage.module.scss';

const PreferencesPrivatePage: React.FunctionComponent = () => {
  const { alternateId = '' } = useParams<{ alternateId: string }>();
  const { pathname } = useLocation();
  const preferencesRequest = useMemo(
    () =>
      alternateId
        ? pathname.includes(routes.viewPrivatePreferences)
          ? () => PreferencesService.getPreferenceQuestionsByAlternateId(alternateId)
          : () => EventService.getPreferenceQuestionsByAlternateId(alternateId)
        : undefined,
    [alternateId, pathname]
  );
  const { data, error, loading } = useRequest<PrivatePreferences>(preferencesRequest);
  const [questions, setQuestions] = useState<PreferenceQuestionView[]>([]);

  useEffect(() => {
    if (data?.preferences?.length) {
      setQuestions(getPreferencesQuestionsView(data.preferences));
    }
  }, [data]);

  return (
    <Container maxWidth={'xl'} className={styles.container}>
      <Spinner loading={loading}>
        {questions.length ? (
          <>
            <Alert severity={'info'} className={styles.alert}>
              {data?.eventTitle
                ? `You are updating the preferences for ${data.eventTitle} for ${data?.customerName}`
                : `You are updating the preferences for ${data?.customerName}`}
            </Alert>
            {error && (
              <Alert severity={'error'} className={commonStyles.alert}>
                {error}
              </Alert>
            )}
            {!!questions.length && <EditPreferencesPageView data={questions} alternateId={alternateId} />}
          </>
        ) : (
          <Alert severity={'warning'} className={commonStyles.alert}>
            {'There are no preferences'}
          </Alert>
        )}
      </Spinner>
    </Container>
  );
};

export default PreferencesPrivatePage;
