import { MenuItem, TextField } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormProps, getRequiredValidationRule, getValidationProps } from 'util/Form';
import { getSelectPlaceholder } from 'util/Layout';
import SelectOption from 'store/types/SelectOption';

const FIELD_NAME = 'distance';

interface DistanceFormItemProps extends FormProps {
  required?: boolean;
}

const options: SelectOption[] = [
  {
    id: '10',
    name: '10 miles',
  },
  {
    id: '20',
    name: '20 miles',
  },
  {
    id: '50',
    name: '50 miles',
  },
  {
    id: '100',
    name: '100 miles',
  },
];

const DistanceFormItem: React.FunctionComponent<DistanceFormItemProps> = ({
  fieldName = FIELD_NAME,
  required = false,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      render={({ field }) => (
        <TextField
          {...field}
          select={true}
          {...getValidationProps(fieldName, errors)}
          required={required}
          SelectProps={{
            displayEmpty: true,
            renderValue: (selected: any) => getSelectPlaceholder(selected || [], options, 'Select'),
          }}
        >
          {options.map(({ id, name }: SelectOption) => (
            <MenuItem value={id} key={`distance-${id}`}>
              {name}
            </MenuItem>
          ))}
        </TextField>
      )}
      name={'distance'}
      control={control}
      rules={{ required: getRequiredValidationRule('distance', true, required) }}
    />
  );
};
export default DistanceFormItem;
