import { Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import User, { CurrentUser, SetCurrentUserValue } from 'store/types/User';
import UserService from 'services/api/UserService';
import useRequest from 'hooks/useRequest';
import { emptyUserFormValues } from 'util/User';
import Spinner from 'components/shared/Spinner';
import authClient from 'services/AuthService';
import { HttpError, HttpUtils } from 'services/HttpService';
import routes from 'store/configs/Routes';
import RequestErrorCode from 'store/enums/RequestErrorCode';
import { getHashRouteUrl } from 'util/Route';
import { UserJobFunction } from 'store/types/JobFunction';
import { sorter } from 'util/Table';
import SiteModule from 'store/enums/SiteModule';
import SessionChecker from './SessionChecker';
import { NUMBER_DATE_FORMAT } from 'util/Format';
import { ConfigContext } from './ConfigGuard';
import UserPermissions from 'store/types/UserPermissions';
import { defaultSnackbarErrorProps } from 'util/Layout';

import commonStyles from 'styles/common.module.scss';

const getBirthDate = (initialDate?: string): string =>
  initialDate ? moment.utc(initialDate).format(NUMBER_DATE_FORMAT) : '';

const getDefaultUserPermissionData = (data?: UserPermissions): UserPermissions =>
  data ? data : { modules: [], sections: [] };

export const UserContext = React.createContext<CurrentUser>({} as CurrentUser);

const UNAUTHORIZED_ERROR_CODE = 401;

//TODO: Should be refactored along with the function to use the application without authorization

const UserGuard: React.FunctionComponent = ({ children }) => {
  const { pathname, search } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { enabledModules } = useContext(ConfigContext);
  const {
    data: userPermissionsData,
    loading: userPermissionsLoading,
    error: userPermissionsError,
  } = useRequest<UserPermissions>(UserService.getUserPermissions);
  const { data, loading, refetch, firstLoading, error } = useRequest<User, HttpError>(
    pathname.includes(routes.redirect) ? undefined : UserService.getCurrentUser
  );
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [user, setUser] = useState<User>({ ...emptyUserFormValues, id: '', priceLevelId: '', entityId: '' });
  const logoutRedirectUrl: string = useMemo(
    () =>
      getHashRouteUrl(
        pathname.includes(routes.donations) && enabledModules.includes(SiteModule.DonationsPremium)
          ? `${pathname}${search}`
          : pathname.includes(routes.eventsCatalog) && enabledModules.includes(SiteModule.Events)
          ? `${pathname}${search}`
          : ''
      ),
    [pathname, enabledModules, search]
  );

  useEffect(() => {
    if (userPermissionsError) {
      authClient.logout(logoutRedirectUrl);
      enqueueSnackbar(userPermissionsError, defaultSnackbarErrorProps);
    }
  }, [enqueueSnackbar, logoutRedirectUrl, userPermissionsError]);

  useEffect(() => {
    if (error) {
      const newErrorMessage: string = HttpUtils.getErrorMessage(error);
      const errorCode = HttpUtils.getErrorCode(error);

      if (errorCode === RequestErrorCode.ProfileNotFound || errorCode === RequestErrorCode.ProfileNotValid) {
        setErrorMessage(newErrorMessage);
      } else {
        if (error.code !== UNAUTHORIZED_ERROR_CODE) {
          enqueueSnackbar(newErrorMessage, defaultSnackbarErrorProps);
        }
        authClient.logout(logoutRedirectUrl);
      }
    } else {
      setErrorMessage('');
    }
  }, [enqueueSnackbar, error, logoutRedirectUrl]);

  useEffect(() => {
    if (data) {
      setUser({
        ...data,
        birthDate: getBirthDate(data.birthDate),
        functions: (data.functions || []).sort(sorter<UserJobFunction>({ column: 'companyName', direction: 'asc' })),
      });
    }
  }, [data]);

  const setCurrentUser = useCallback((newValue: SetCurrentUserValue) => {
    setUser((prevState: User) => {
      const newState = { ...prevState, ...newValue };

      return { ...newState, birthDate: getBirthDate(newState.birthDate) };
    });
  }, []);

  const refetchUser = useCallback(() => {
    refetch();
  }, [refetch]);

  const handleLogoutClick = useCallback(() => {
    authClient.logout();
  }, []);

  return firstLoading ? (
    <Spinner loading={true} fullPage={true} transparent={false} />
  ) : errorMessage ? (
    <Alert
      severity={'error'}
      className={commonStyles.alert}
      action={
        <Button color={'inherit'} size={'small'} className={commonStyles.uppercase} onClick={handleLogoutClick}>
          {'Logout'}
        </Button>
      }
    >
      {errorMessage}
    </Alert>
  ) : (
    <UserContext.Provider
      value={{
        ...user,
        setCurrentUser,
        refetchUser,
        userPermissions: getDefaultUserPermissionData(userPermissionsData),
        userLoading: loading || userPermissionsLoading,
      }}
    >
      <SessionChecker />
      {children}
    </UserContext.Provider>
  );
};
export default UserGuard;
