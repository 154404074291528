import classNames from 'classnames';
import React from 'react';
import StatusLabelTheme from 'store/configs/StatusLabelTheme';
import Chip from '@mui/material/Chip';
import ComponentProps from 'store/types/ComponentProps';

import styles from './StatusLabel.module.scss';

interface StatusLabelProps extends ComponentProps {
  theme?: StatusLabelTheme;
  variant?: 'filled' | 'outlined';
  status: string;
}

const StatusLabel: React.FunctionComponent<StatusLabelProps> = ({
  variant = 'filled',
  theme = 'primary-theme',
  status,
  className,
}) => (
  <Chip
    variant={variant}
    label={status}
    className={classNames(styles.label, styles[theme], className, {
      [styles.filled]: variant === 'filled',
      [styles.outlined]: variant === 'outlined',
    })}
    data-testid={'status-label'}
  />
);

export default StatusLabel;
