import { Grid, MenuItem, TextField } from '@mui/material';
import React, { ChangeEvent, useCallback, useContext, useMemo, useState } from 'react';
import UseRequestData from 'store/types/UseRequestData';
import { OrderPayment } from 'store/types/Payment';
import Alert from '@mui/material/Alert';
import { FilterConfig } from 'store/types/Table';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import SearchInput from 'components/shared/SearchInput';
import StaticSelectOption from 'store/types/StaticSelectOption';
import OrderHistoryTable from './OrderHistoryTable';
import Company from 'store/types/Company';
import { getNumberDaysBetweenDates } from 'util/Format';
import { ALL_PROGRAMS_OPTION } from 'util/Company';
import { UserPrograms } from 'store/types/User';
import { ParametersContext } from 'components/ParametersGuard';
import routes from 'store/configs/Routes';
import { useLocation } from 'react-router';

import commonStyles from 'styles/common.module.scss';
import styles from './OrderHistoryPageView.module.scss';
import { ConfigContext } from 'components/ConfigGuard';

interface OrderHistoryPageViewProps extends Pick<UseRequestData<OrderPayment[]>, 'data' | 'loading' | 'error'> {
  makePaymentRoute?: string;
  ownerColumnEnabled?: boolean;
  companyColumnEnabled?: boolean;
  openOrdersEnabled?: boolean;
  companyId?: Company['id'];
  programId?: string;
  programsList?: UserPrograms[];
  onProgramChange?: (value: string) => void;
}

const yearToDate = getNumberDaysBetweenDates(new Date(), new Date(new Date().getFullYear(), 0, 1));

const orderDateFilterConfig: StaticSelectOption[] = [
  { value: '0', text: 'All Transactions' },
  { value: '30', text: 'Last 30 Days' },
  { value: '183', text: 'Last 6 Months' },
  { value: yearToDate, text: 'Year to Date' },
];

const OrderHistoryPageView: React.FunctionComponent<OrderHistoryPageViewProps> = ({
  data = [],
  loading,
  error,
  makePaymentRoute,
  openOrdersEnabled,
  ownerColumnEnabled = false,
  companyId,
  onProgramChange,
  programId,
  programsList,
}) => {
  const [filter, setFilter] = useState<FilterConfig<OrderPayment>>({
    search: '',
    orderDate: 0,
  });
  const orderHistoryTableProps = {
    data,
    loading,
    filter,
    makePaymentRoute,
    ownerColumnEnabled,
    companyId,
  };
  const { pathname } = useLocation();
  const {
    modulesSettings: {
      personalPayments: { enablePaySalesOrders: enablePersonalPaySalesOrders },
      companyPayments: { enablePaySalesOrders: enableCompanyPaySalesOrders },
    },
  } = useContext(ConfigContext);
  const showOpenSalesOrders = useMemo(
    () => (companyId ? enableCompanyPaySalesOrders : enablePersonalPaySalesOrders),
    [companyId, enableCompanyPaySalesOrders, enablePersonalPaySalesOrders]
  );
  const { companyPayments, personalPayments, groupPayments } = useContext(ParametersContext);
  const handleNumberSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setFilter((prevState) => ({ ...prevState, search: e.target.value }));
  }, []);

  const handleDateFilterChange = useCallback((e: ChangeEvent<{ name?: string; value: string }>) => {
    setFilter((prevState) => ({ ...prevState, orderDate: parseInt(e.target.value, 10) }));
  }, []);

  const handleProgramSelect = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (onProgramChange) {
        onProgramChange(e.target.value);
      }
    },
    [onProgramChange]
  );

  return error ? (
    <Alert severity={'error'} className={commonStyles.alert}>
      {error}
    </Alert>
  ) : (
    <>
      <Grid {...defaultGridContainerProps}>
        <Grid {...defaultGridItemProps} sm={'auto'}>
          <div className={styles.select}>
            <span className={styles.selectLabel}>{'Show'}</span>
            <TextField
              select={true}
              variant={'outlined'}
              size={'small'}
              disabled={loading}
              value={filter.orderDate}
              onChange={handleDateFilterChange}
            >
              {orderDateFilterConfig.map(({ value, text }) => (
                <MenuItem value={value} key={`order-date-filter-${value}`}>
                  {text}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </Grid>
        {!!programsList?.length && companyId && (
          <Grid {...defaultGridItemProps} sm={'auto'}>
            <div className={styles.select}>
              <span className={styles.selectLabel}>{'Program'}</span>
              <TextField
                select={true}
                variant={'outlined'}
                size={'small'}
                disabled={loading}
                value={programId}
                onChange={handleProgramSelect}
              >
                <MenuItem value={ALL_PROGRAMS_OPTION}>{ALL_PROGRAMS_OPTION}</MenuItem>
                {programsList.map(({ id, name }) => (
                  <MenuItem value={id} key={`program-${id}`}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Grid>
        )}
        <Grid {...defaultGridItemProps} sm={7} className={styles.search}>
          <SearchInput loading={loading} value={filter.search} onChange={handleNumberSearchChange} />
        </Grid>
        {showOpenSalesOrders && (
          <Grid {...defaultGridItemProps}>
            <h2 className={styles.tableTitle}>{'Open Sales Orders'}</h2>
            <OrderHistoryTable
              {...orderHistoryTableProps}
              openOrders={true}
              salesOrders={true}
              makePaymentRoute={companyId ? routes.makeCompanySalesPayment : routes.makePersonalSalesPayment}
            />
          </Grid>
        )}
        {openOrdersEnabled && (
          <Grid {...defaultGridItemProps}>
            <h2 className={styles.tableTitle}>{'Open Invoices'}</h2>
            {(companyPayments.openInvoicesTableHeader ||
              personalPayments.openInvoicesTableHeader ||
              groupPayments.openInvoicesTableHeader) && (
              <p
                dangerouslySetInnerHTML={{
                  __html: pathname.includes(routes.companyPayments)
                    ? companyPayments.openInvoicesTableHeader
                    : pathname.includes(routes.personalPayments)
                    ? personalPayments.openInvoicesTableHeader
                    : groupPayments.openInvoicesTableHeader,
                }}
              />
            )}
            <OrderHistoryTable {...orderHistoryTableProps} openOrders={true} />
          </Grid>
        )}
        <Grid {...defaultGridItemProps}>
          <h2 className={styles.tableTitle}>{'Billing History'}</h2>
          {(companyPayments.billingHistoryTableHeader ||
            personalPayments.billingHistoryTableHeader ||
            groupPayments.billingHistoryTableHeader) && (
            <p
              dangerouslySetInnerHTML={{
                __html: pathname.includes(routes.companyPayments)
                  ? companyPayments.billingHistoryTableHeader
                  : pathname.includes(routes.personalPayments)
                  ? personalPayments.billingHistoryTableHeader
                  : groupPayments.billingHistoryTableHeader,
              }}
            />
          )}
          <OrderHistoryTable {...orderHistoryTableProps} />
        </Grid>
      </Grid>
    </>
  );
};
export default OrderHistoryPageView;
