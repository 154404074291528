import { HttpService } from 'services/HttpService';
import { API_URL, conf, tenantConfig } from 'config';
import AuthService from 'services/AuthService';
import { httpRequestWrapper } from 'util/Request';
import AppEvent, { AppEventData } from 'store/types/AppEvent';
import FileInfo from 'store/types/FileInfo';
import { PublicUser } from 'store/types/User';
import { JoinCommitteeFormValues } from 'store/types/FormValues';
import { Community, CommunityDetails } from 'store/types/Community';

type CommitteesResponseData = Community[];
type CommitteeEventsResponseData = AppEvent[];

class CommitteeService {
  public static getCommittees(isPublic?: boolean): Promise<CommitteesResponseData> {
    return new Promise<CommitteesResponseData>(
      (resolve: (data: CommitteesResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<CommitteesResponseData>(
          HttpService.get(`${API_URL}/committees`, {
            queryParams: { isPublic },
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getCommitteeDetails(committeeId: string): Promise<CommunityDetails> {
    return new Promise<CommunityDetails>(
      (resolve: (data: CommunityDetails) => void, reject: (error: string) => void) => {
        httpRequestWrapper<CommunityDetails>(
          HttpService.get(`${API_URL}/committees/${committeeId}`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getCommitteeEventFile(eventId: string): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.getFileDownload(`${API_URL}/committees/events/${eventId}/download`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
            [conf.filenameHeader]: `committee-event-${eventId}.ics`,
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getCommitteeMember(committeeId: string, userId: string): Promise<PublicUser> {
    return new Promise<PublicUser>((resolve: (data: any) => void, reject: (error: string) => void) => {
      httpRequestWrapper<PublicUser>(
        HttpService.get(`${API_URL}/committees/${committeeId}/members/${userId}`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getCommitteeMembersRoster(
    committeeId: string,
    roles: string[] = [],
    committeeName: string
  ): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.getFileDownload(`${API_URL}/committees/${committeeId}/members/download`, {
          queryParams: { role: roles },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
            [conf.filenameHeader]: `${committeeName}.csv`,
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getCommitteeEvents(id: string): Promise<CommitteeEventsResponseData> {
    return new Promise<CommitteeEventsResponseData>(
      (resolve: (data: CommitteeEventsResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<CommitteeEventsResponseData>(
          HttpService.get(`${API_URL}/committees/${id}/events`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getCommitteeFile(committeeId: string, { id, name }: FileInfo): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.getFileDownload(`${API_URL}/committees/${committeeId}/files/${id}`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
            [conf.filenameHeader]: name,
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static deleteCommitteeFile(committeeId: string, fileId: string): Promise<null> {
    return new Promise((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.delete(`${API_URL}/committees/${committeeId}/files/${fileId}`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static uploadCommitteeFile(committeeId: string, file: File): Promise<null> {
    const uploadedData = new FormData();

    uploadedData.append('file', file);
    return new Promise((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.post(`${API_URL}/committees/${committeeId}/files`, {
          data: uploadedData,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static joinCommittee(data: JoinCommitteeFormValues): Promise<null> {
    const { committeeId, reason, role, experience, isRequirementsMet } = data;

    return new Promise((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.post(`${API_URL}/committees/${committeeId}/join`, {
          data: { reason, role, experience, isRequirementsMet },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static createCommitteeEvent(committeeId: string, data: AppEventData): Promise<null> {
    return new Promise((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.post(`${API_URL}/committees/${committeeId}/events`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static updateCommitteeEvent(committeeId: string, eventId: string, data: AppEventData): Promise<null> {
    return new Promise((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.put(`${API_URL}/committees/${committeeId}/events/${eventId}`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static deleteCommitteeEvent(committeeId: string, eventId: string): Promise<null> {
    return new Promise((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.delete(`${API_URL}/committees/${committeeId}/events/${eventId}`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }
}
export default CommitteeService;
