import Add from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import React, { Reducer, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import Table from 'components/shared/Table';
import { getFormattedDate, NUMBER_DATE_FORMAT } from 'util/Format';
import { SortConfig, TableColumn } from 'store/types/Table';
import UseRequestData from 'store/types/UseRequestData';
import Modal from 'components/shared/Modal';
import Subscription from 'store/types/Subscription';
import SubscriptionService from 'services/api/SubscriptionService';
import routes from 'store/configs/Routes';
import SubscriptionStatus from 'store/enums/SubscriptionStatus';
import MenuConfigItem from 'store/types/MenuConfigItem';
import { getHashRouteUrl } from 'util/Route';
import ActionsMenu from 'components/shared/ActionsMenu';
import AddSubscriptionUsersModal from 'components/subscriptions/AddSubscriptionUsersModal';
import subscriptionStatusConfig from 'store/configs/SubscriptionStatusConfig';
import StatusLabel from 'components/shared/StatusLabel';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import SelectOption from 'store/types/SelectOption';
import { ConfigContext } from 'components/ConfigGuard';
import { CompaniesContext } from 'components/CompaniesContextWrapper';
import reducer, {
  initialState,
  UserSubscriptionsTableAction,
  UserSubscriptionsTableActionType,
  UserSubscriptionsTableState,
} from './UserSubscriptionsTableReducer';
import { Button, Grid } from '@mui/material';
import CompanySelect from 'components/company/CompanySelect';
import { getSubscriptionSelectOptions } from 'util/Membership';
import { CompanySelectOption } from 'util/Company';

import commonStyles from 'styles/common.module.scss';
import styles from './UserSubscriptionsTable.module.scss';

const getStatusLabel = (status: SubscriptionStatus): React.ReactNode => {
  const { theme = 'grey', name = '' } = subscriptionStatusConfig[status];

  return <StatusLabel theme={theme} status={name} />;
};

const UserSubscriptionsTable: React.FunctionComponent<UseRequestData<Subscription[]>> = ({
  data,
  loading,
  refetch,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data: companyData = [] } = useContext(CompaniesContext);
  const options: CompanySelectOption[] = useMemo(() => getSubscriptionSelectOptions(companyData, true), [companyData]);
  const { isNccerTheme } = useContext(ConfigContext);
  const [{ sort, list = [], clickedItem, renewalModalOpen, usersModalOpen }, dispatch] = useReducer<
    Reducer<UserSubscriptionsTableState, UserSubscriptionsTableAction>
  >(reducer, initialState);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  // const renewColumnVisible: boolean = useMemo(() => list.some(({ canRenew }) => !!canRenew), [list]);

  useEffect(() => {
    if (data) {
      dispatch({
        type: UserSubscriptionsTableActionType.SetInitialList,
        payload: { initialList: data },
      });
    }
  }, [data]);

  const handleSortChange = useCallback((newSort: SortConfig) => {
    dispatch({ type: UserSubscriptionsTableActionType.UpdateSort, payload: { sort: newSort } });
  }, []);

  const handleRenewalConfirm = () => {
    if (clickedItem) {
      setSubmitLoading(true);
      SubscriptionService.updateAutoRenewal(clickedItem.id, !clickedItem.autoRenewal)
        .then(() => {
          dispatch({
            type: UserSubscriptionsTableActionType.CloseRenewalModal,
            payload: {},
          });
          setSubmitLoading(false);
          refetch();
        })
        .catch((errorMessage: string) => {
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
          setSubmitLoading(false);
        });
    }
  };

  const handleCompanyFilterChange = useCallback(
    (newCompanyId: string) => {
      const foundCompany: SelectOption | undefined = (companyData || []).find(({ id }) => id === newCompanyId);

      dispatch({
        type: UserSubscriptionsTableActionType.UpdateFilter,
        payload: {
          filter: { customerId: foundCompany ? foundCompany.id : '' },
        },
      });
    },
    [companyData]
  );

  const handleRenewalConfirmModalClose = useCallback(() => {
    dispatch({
      type: UserSubscriptionsTableActionType.CloseRenewalModal,
      payload: {},
    });
  }, []);

  const handleUsersModalClose = useCallback(() => {
    dispatch({
      type: UserSubscriptionsTableActionType.CloseUsersModal,
      payload: {},
    });
  }, []);

  const getActionsMenuConfig = useCallback(
    (record: Subscription): MenuConfigItem[] => [
      {
        title: 'Add Subscriptions',
        key: 'addSubscriptions',
        onClick: () => {
          dispatch({
            type: UserSubscriptionsTableActionType.OpenUsersModal,
            payload: { clickedItem: record },
          });
        },
      },
      // NOTE: will be used later
      // {
      //   label: `Turn ${record.autoRenewal ? 'off' : 'on'} Auto-Renewal`,
      //   key: 'autoRenewal',
      //   onClick: () => {
      //     dispatch({
      //       type: UserSubscriptionsTableActionType.OpenRenewalModal,
      //       payload: { clickedItem: record },
      //     });
      //   },
      // },
    ],
    []
  );

  const columns: Array<TableColumn<Subscription>> = useMemo(() => {
    const result: Array<TableColumn<Subscription>> = [
      {
        dataIndex: 'status',
        label: 'Status',
        sortable: true,
        render: (status: SubscriptionStatus) => getStatusLabel(status),
      },
      { dataIndex: 'customer', label: 'Organization', sortable: true },
      { dataIndex: 'name', label: 'Name', sortable: true },
      {
        dataIndex: 'startDate',
        label: 'Start Date',
        render: (nextBillingDate: string) => <span>{getFormattedDate(nextBillingDate, NUMBER_DATE_FORMAT)}</span>,
        sortable: true,
      },
      {
        dataIndex: 'endDate',
        label: 'End Date',
        render: (nextBillingDate: string) => <span>{getFormattedDate(nextBillingDate, NUMBER_DATE_FORMAT)}</span>,
        sortable: true,
      },
      // NOTE: will be used later
      // {
      //   dataIndex: 'autoRenewal',
      //   label: 'Auto-Renewal',
      //   render: (autoRenewal: boolean) => <span className={commonStyles.uppercase}>{autoRenewal ? 'On' : 'Off'}</span>,
      //   sortable: true,
      // },
      { dataIndex: 'quantity', label: '# of Subscriptions', sortable: true },
    ];
    // if (renewColumnVisible) {
    //   result.splice(-1, 0, {
    //     key: 'renew',
    //     label: '',
    //     render: (_: any, { id, canRenew }: Subscription) =>
    //       canRenew ? (
    //         <Button
    //           variant={'contained'}
    //           size={'small'}
    //           color={'secondary'}
    //           className={styles.renewButton}
    //           href={getHashRouteUrl(`${routes.renewSubscription}/${id}`)}
    //         >
    //           {'Renew'}
    //         </Button>
    //       ) : null,
    //   });
    // }
    !isNccerTheme &&
      result.splice(6, 0, {
        key: 'action',
        label: '',
        render: (_: any, record: Subscription) => (
          <ActionsMenu
            disabled={record.status !== SubscriptionStatus.Active}
            menuConfig={record.status === SubscriptionStatus.Active ? getActionsMenuConfig(record) : []}
          />
        ),
      });
    return result;
  }, [getActionsMenuConfig, isNccerTheme]);

  return (
    <>
      <Grid {...defaultGridContainerProps} alignItems={'center'} justifyContent={'space-between'}>
        <Grid {...defaultGridItemProps} xs={true}>
          <CompanySelect
            options={options}
            onChange={handleCompanyFilterChange}
            showAllCompaniesMenuItem={true}
            size={'small'}
          />
        </Grid>
        <Grid {...defaultGridItemProps} xs={'auto'} className={styles.addButtonWrapper}>
          <Button
            color={'secondary'}
            variant={'contained'}
            startIcon={<Add />}
            className={commonStyles.uppercase}
            href={getHashRouteUrl(routes.enrollSubscription)}
          >
            {'Buy Now'}
          </Button>
        </Grid>
        <Grid {...defaultGridItemProps}>
          <Table columns={columns} list={list} sort={sort} onSortChange={handleSortChange} loading={loading} />
        </Grid>
      </Grid>
      {clickedItem && (
        <>
          <Modal
            title={`Turn ${clickedItem.autoRenewal ? 'off' : 'on'} Auto-Renewal`}
            open={renewalModalOpen}
            maxWidth={'xs'}
            loading={submitLoading}
            actions={
              <>
                <Button
                  color={'secondary'}
                  variant={'outlined'}
                  onClick={handleRenewalConfirmModalClose}
                  disabled={submitLoading}
                >
                  {'Cancel'}
                </Button>
                <Button
                  type={'submit'}
                  disabled={submitLoading}
                  onClick={handleRenewalConfirm}
                  variant={'contained'}
                  className={commonStyles.dangerButtonContained}
                >
                  {`Confirm`}
                </Button>
              </>
            }
          >
            <p className={commonStyles.text}>{`Are you sure you want to turn ${
              clickedItem.autoRenewal ? 'off' : 'on'
            } auto-renewal for the subscription?`}</p>
          </Modal>
          <AddSubscriptionUsersModal data={clickedItem} open={usersModalOpen} onClose={handleUsersModalClose} />
        </>
      )}
    </>
  );
};
export default UserSubscriptionsTable;
