import { MenuItem, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { getRequiredValidationRule, getValidationProps } from 'util/Form';
import SelectOption from 'store/types/SelectOption';

const fieldName = 'role';

interface CommitteeNameFormItemProps {
  data?: SelectOption[];
}

const CommitteeNameFormItem: React.FunctionComponent<CommitteeNameFormItemProps> = ({ data }) => {
  const [options, setOptions] = useState<SelectOption[]>([]);
  const {
    control,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    if (data?.length) {
      setOptions(data);
    }
  }, [data]);

  return (
    <Controller
      render={({ field }) => (
        <TextField {...field} {...getValidationProps(fieldName, errors)} disabled={!data?.length} select={true}>
          {options.map(({ id, name }: SelectOption) => (
            <MenuItem value={name} key={`committee-role-${id}`}>
              {name}
            </MenuItem>
          ))}
        </TextField>
      )}
      name={fieldName}
      control={control}
      rules={{ required: getRequiredValidationRule('role', true, true) }}
    />
  );
};
export default CommitteeNameFormItem;
