import React, { useCallback, useEffect, useState } from 'react';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import SubscriptionService from 'services/api/SubscriptionService';
import SubscriptionPlanCard from 'components/subscriptions/SubscriptionPlanCard';
import Spinner from 'components/shared/Spinner';
import Alert from '@mui/material/Alert';
import useRequest from 'hooks/useRequest';
import { SubscriptionPlan, SubscriptionPlanView } from 'store/types/Subscription';
import { Grid } from '@mui/material';

import commonStyles from 'styles/common.module.scss';

interface SubscriptionPlansSectionProps {
  value?: SubscriptionPlanView;
  onChange: (newValue: SubscriptionPlanView) => void;
  companyId?: string;
}

const SubscriptionPlanStep: React.FunctionComponent<SubscriptionPlansSectionProps> = ({ value, onChange }) => {
  const { data, loading, error } = useRequest<SubscriptionPlan[]>(SubscriptionService.getSubscriptionPlans);
  const [plans, setPlans] = useState<SubscriptionPlanView[]>([]);

  useEffect(() => {
    if (data && data.length) {
      setPlans(data.map((item: SubscriptionPlan) => ({ ...item, usersAmount: 1 })));
    }
  }, [data]);

  const handleChange = useCallback(
    (newPlan: SubscriptionPlanView) => {
      setPlans((prevState: SubscriptionPlanView[]) =>
        prevState.map((plan) => (newPlan.id === plan.id ? newPlan : plan))
      );
      onChange(newPlan);
    },
    [onChange]
  );

  return (
    <Spinner loading={loading} transparent={false}>
      {error ? (
        <Alert severity={'error'} className={commonStyles.alert}>
          {error}
        </Alert>
      ) : plans.length ? (
        <Grid {...defaultGridContainerProps}>
          {plans.map((plan: SubscriptionPlanView) => (
            <Grid {...defaultGridItemProps} key={plan.id}>
              <SubscriptionPlanCard data={plan} onSelect={handleChange} selected={value && value.id === plan.id} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <p className={commonStyles.text}>{'No plans available.'}</p>
      )}
    </Spinner>
  );
};
export default SubscriptionPlanStep;
