import Modal from 'components/shared/Modal';
import { Alert, Button, Checkbox, Divider, FormControlLabel, FormGroup, Grid, TextField } from '@mui/material';
import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { ModalProps } from 'store/types/ComponentProps';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import { ParametersContext } from 'components/ParametersGuard';
import { UserContext } from 'components/UserGuard';
import AdvocacyService from 'services/api/AdvocacyService';
import { Home } from '@mui/icons-material';
import useRequest from 'hooks/useRequest';
import CompanyService from 'services/api/CompanyService';
import { PriorApprovalForm } from 'store/types/PriorApproval';
import { defaultFormProps, getRequiredValidationRule, getValidationProps } from 'util/Form';
import Domain from '@mui/icons-material/Domain';
import { getAddressInfo } from 'util/Format';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { CompanyPaymentDetails } from 'store/types/Company';
import SelectOption from 'store/types/SelectOption';

import styles from './RequestEmployerModal.module.scss';
import commonStyles from 'styles/common.module.scss';

interface RequestApprovalModalProps extends ModalProps {
  refetch: () => void;
}

interface PriorApprovalFormValues extends Omit<PriorApprovalForm, 'companyId' | 'years'> {
  years: Array<SelectOption & { value?: boolean }>;
}

const RequestApprovalModal: React.FunctionComponent<RequestApprovalModalProps> = ({ open, onClose, refetch }) => {
  const { parentCompanyId, title } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<PriorApprovalFormValues>({
    ...defaultFormProps,
  });
  const yearsValues = useWatch({ control, name: 'years' });
  const submitButtonDisabled: boolean = useMemo(
    () => !isValid || !isDirty || !parentCompanyId || !yearsValues.some(({ value }) => value),
    [isDirty, isValid, parentCompanyId, yearsValues]
  );
  const companyDetailsRequest = useMemo(
    () => (parentCompanyId ? () => CompanyService.getCompanyPaymentDetails(parentCompanyId) : undefined),
    [parentCompanyId]
  );
  const { fields } = useFieldArray({ control, name: 'years' });
  const {
    data: companyDetailsData,
    loading: companyDetailsLoading,
    error: companyDetailsError,
  } = useRequest<CompanyPaymentDetails>(companyDetailsRequest);
  const {
    data: priorApprovalsYearsData,
    loading: priorApprovalsYearsLoading,
    error: priorApprovalsYearsError,
  } = useRequest<SelectOption[]>(AdvocacyService.getPriorApprovalsYears);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const {
    profile: {
      submitPriorApprovalModalHeader,
      titleAdvocacyHeader,
      authorizedAdvocacyYearsHeader,
      digitalSignatureAdvocacyHeader,
    },
  } = useContext(ParametersContext);

  useEffect(() => {
    if (priorApprovalsYearsData?.length) {
      const sortedValues = priorApprovalsYearsData.sort(
        (a: SelectOption, b: SelectOption) => Number(a.name) - Number(b.name)
      );
      setValue('years', sortedValues);
    }
  }, [priorApprovalsYearsData, setValue]);

  const handleRequestSubmit = useCallback(
    (formValues: PriorApprovalFormValues) => {
      setSubmitLoading(true);
      if (parentCompanyId) {
        const data: PriorApprovalForm = {
          ...formValues,
          companyId: parentCompanyId,
          years: formValues.years.filter(({ value }) => value).map(({ id }) => id),
        };

        AdvocacyService.submitPriorApproval(data)
          .then(() => {
            enqueueSnackbar('Approval successfully submitted', { variant: 'success' });
            setSubmitLoading(false);
            onClose();
            refetch();
          })
          .catch((errorMessage) => {
            enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
            setSubmitLoading(false);
          });
      }
    },
    [enqueueSnackbar, onClose, parentCompanyId, refetch]
  );

  const handleChange = useCallback((onChange) => (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.checked), []);

  return (
    <Modal
      loading={submitLoading || companyDetailsLoading || priorApprovalsYearsLoading}
      title={'Prior Approval Form'}
      open={open}
      onClose={onClose}
      maxWidth={'md'}
      actions={
        <>
          <Button color={'secondary'} variant={'outlined'} onClick={onClose}>
            {'Cancel'}
          </Button>
          <Button
            type={'submit'}
            variant={'contained'}
            onClick={handleSubmit(handleRequestSubmit)}
            disabled={submitButtonDisabled}
          >
            {'Submit'}
          </Button>
        </>
      }
    >
      {parentCompanyId ? (
        priorApprovalsYearsError || companyDetailsError ? (
          <Alert severity={'error'} className={commonStyles.alert}>
            {priorApprovalsYearsError || companyDetailsError}
          </Alert>
        ) : (
          <Grid {...defaultGridContainerProps}>
            {submitPriorApprovalModalHeader && (
              <Grid {...defaultGridItemProps}>
                <p dangerouslySetInnerHTML={{ __html: submitPriorApprovalModalHeader }} />
              </Grid>
            )}
            {companyDetailsData && (
              <Grid {...defaultGridItemProps} className={styles.informationWrapper}>
                <h5 className={commonStyles.subTitle}>{'Company Information'}</h5>
                <Divider className={styles.divider} />
                <div className={styles.row}>
                  <Domain className={styles.icon} />
                  {companyDetailsData.name}
                </div>
                {companyDetailsData.address && (
                  <div className={styles.row}>
                    <Home className={styles.icon} />
                    <div>{getAddressInfo(companyDetailsData.address, { className: styles.location })}</div>
                  </div>
                )}
              </Grid>
            )}
            <Grid {...defaultGridItemProps}>
              <h5 className={commonStyles.subTitle}>{'Title'}</h5>
              <p dangerouslySetInnerHTML={{ __html: titleAdvocacyHeader }} />
              <Controller
                render={({ field }) => (
                  <TextField {...field} {...getValidationProps('title', errors)} placeholder={'Title'} />
                )}
                defaultValue={title}
                name={'title'}
                control={control}
              />
            </Grid>
            <Grid {...defaultGridItemProps}>
              <h5 className={commonStyles.subTitle}>{'Authorized Advocacy Years*'}</h5>
              <p dangerouslySetInnerHTML={{ __html: authorizedAdvocacyYearsHeader }} />
              <FormGroup>
                {fields.map(({ id, name }, index) => (
                  <FormControlLabel
                    key={id}
                    label={name}
                    className={styles.checkbox}
                    control={
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <Checkbox
                            color={'primary'}
                            size={'small'}
                            checked={value}
                            value={value}
                            onChange={handleChange(onChange)}
                          />
                        )}
                        defaultValue={false}
                        control={control}
                        name={`years.${index}.value`}
                      />
                    }
                  />
                ))}
              </FormGroup>
            </Grid>
            <Grid {...defaultGridItemProps}>
              <h5 className={commonStyles.subTitle}>{'Signatory Digital Signature*'}</h5>
              <p dangerouslySetInnerHTML={{ __html: digitalSignatureAdvocacyHeader }} />
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    {...getValidationProps('signatory', errors)}
                    placeholder={'Signatory Digital Signature'}
                    required={true}
                  />
                )}
                defaultValue={''}
                name={'signatory'}
                control={control}
                rules={{ required: getRequiredValidationRule('signatory') }}
              />
            </Grid>
          </Grid>
        )
      ) : (
        <Alert severity={'info'} className={commonStyles.alert}>
          {'You need to be an employee of a company to access this form'}
        </Alert>
      )}
    </Modal>
  );
};

export default RequestApprovalModal;
