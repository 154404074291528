import React, { Reducer, useCallback, useEffect, useMemo, useReducer } from 'react';
import Table from 'components/shared/Table';
import { FilterConfig, SortConfig, TableColumn } from 'store/types/Table';
import reducer, {
  initialState,
  ITEMS_PER_PAGE,
  SupportCasesTableAction,
  SupportCasesTableActionType,
  SupportCasesTableState,
} from './SupportCasesTableReducer';
import { getPagesCount } from 'util/Table';
import { SupportCaseView } from 'store/types/SupportCase';
import { Button, Grid } from '@mui/material';
import SupportCaseStatus from 'store/enums/SupportCaseStatus';
import { getFormattedDate, NUMBER_DATE_FORMAT } from 'util/Format';
import Pagination from '@mui/material/Pagination';
import supportCaseStatusConfig from 'store/configs/StatusCaseStatusConfig';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import StatusLabel from 'components/shared/StatusLabel';
import ReviewSupportCaseModal from 'components/support/ReviewSupportCaseModal';

import styles from './SupportCasesTable.module.scss';

export const getSupportCaseStatusLabel = (status: SupportCaseStatus): React.ReactNode => {
  const { theme = 'grey', name = '' } = supportCaseStatusConfig[status];

  return <StatusLabel theme={theme} status={name} className={styles.status} />;
};

interface SupportCasesTableProp {
  loading?: boolean;
  data?: SupportCaseView[];
  filter: FilterConfig<SupportCaseView>;
}

const SupportCasesTable: React.FunctionComponent<SupportCasesTableProp> = ({ data = [], loading, filter }) => {
  const [{ sort, list = [], pageList, page, selectedItem }, dispatch] = useReducer<
    Reducer<SupportCasesTableState, SupportCasesTableAction>
  >(reducer, initialState);
  const pagesCount: number = useMemo(() => getPagesCount(list.length, ITEMS_PER_PAGE), [list]);

  const handleReviewModalOpen = useCallback(
    (record: SupportCaseView) => () => {
      dispatch({
        type: SupportCasesTableActionType.OpenReviewModal,
        payload: { selectedItem: record },
      });
    },
    []
  );

  const handleReviewModalClose = useCallback(() => {
    dispatch({
      type: SupportCasesTableActionType.CloseReviewModal,
      payload: {},
    });
  }, []);

  useEffect(() => {
    if (data?.length) {
      dispatch({
        type: SupportCasesTableActionType.SetInitialList,
        payload: { initialList: data },
      });
    }
  }, [data]);

  useEffect(() => {
    dispatch({
      type: SupportCasesTableActionType.UpdateFilter,
      payload: { filter },
    });
  }, [filter]);

  const handlePageChange = useCallback((_: any, newPage: number) => {
    window.scrollTo(0, 0);
    dispatch({
      type: SupportCasesTableActionType.UpdatePage,
      payload: { page: newPage },
    });
  }, []);

  const handleSortChange = useCallback((newSort: SortConfig) => {
    dispatch({ type: SupportCasesTableActionType.UpdateSort, payload: { sort: newSort } });
  }, []);

  const columns: Array<TableColumn<SupportCaseView>> = useMemo(
    () => [
      {
        dataIndex: 'status',
        label: 'Status',
        align: 'center',
        sortable: true,
        render: (status: SupportCaseStatus) => getSupportCaseStatusLabel(status),
      },
      { dataIndex: 'subject', label: 'Subject', sortable: true },
      { dataIndex: 'priority', label: 'Priority', sortable: true, align: 'center' },
      {
        dataIndex: 'issueDate',
        label: 'Incident Date',
        align: 'center',
        sortable: true,
        render: (date: string) => <span>{getFormattedDate(date, NUMBER_DATE_FORMAT)}</span>,
      },
      {
        key: 'action',
        label: 'Action',
        align: 'center',
        render: (_: any, record: SupportCaseView) => (
          <Button
            variant={'outlined'}
            size={'small'}
            color={'primary'}
            className={styles.actionButton}
            onClick={handleReviewModalOpen(record)}
          >
            {'View'}
            <span className={styles.nameLabel}>{'Details'}</span>
          </Button>
        ),
      },
    ],
    [handleReviewModalOpen]
  );

  return (
    <>
      <Grid {...defaultGridContainerProps} justifyContent={'center'}>
        <Grid {...defaultGridItemProps}>
          <Table columns={columns} list={pageList} sort={sort} onSortChange={handleSortChange} loading={loading} />
        </Grid>
        <Grid {...defaultGridItemProps} sm={'auto'}>
          <Pagination
            disabled={loading}
            showFirstButton={true}
            showLastButton={true}
            count={pagesCount}
            page={page}
            onChange={handlePageChange}
          />
        </Grid>
      </Grid>
      {selectedItem && (
        <ReviewSupportCaseModal open={!!selectedItem} onClose={handleReviewModalClose} data={selectedItem} />
      )}
    </>
  );
};
export default SupportCasesTable;
