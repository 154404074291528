import React from 'react';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import MembershipPlanCard from './MembershipPlanCard';
import Spinner from 'components/shared/Spinner';
import Alert from '@mui/material/Alert';
import MembershipService from 'services/api/MembershipService';
import useRequest from 'hooks/useRequest';
import { SubscriptionPlan } from 'store/types/Subscription';
import { Grid } from '@mui/material';

import commonStyles from 'styles/common.module.scss';

interface MembershipPlansSectionProps {
  value?: SubscriptionPlan;
  onChange?: (newValue: SubscriptionPlan) => void;
}

const MembershipPlansSection: React.FunctionComponent<MembershipPlansSectionProps> = ({ value, onChange }) => {
  const { data, loading, error } = useRequest<SubscriptionPlan[]>(MembershipService.getMembershipPlans);
  const editable = !!onChange;

  return editable ? (
    <Spinner loading={loading}>
      {error ? (
        <Alert severity={'error'} className={commonStyles.alert}>
          {error}
        </Alert>
      ) : (
        <Grid {...defaultGridContainerProps}>
          {(data || []).map((item: SubscriptionPlan) => (
            <Grid {...defaultGridItemProps} key={item.id} md={4}>
              <MembershipPlanCard data={item} onClick={onChange} selected={value && value.id === item.id} />
            </Grid>
          ))}
        </Grid>
      )}
    </Spinner>
  ) : value ? (
    <MembershipPlanCard data={value} selected={true} customSelectedLabel={'Your Membership'} />
  ) : null;
};
export default MembershipPlansSection;
