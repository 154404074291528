import { Grid, TextField } from '@mui/material';
import React, { useCallback } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { getRequiredInputMessage, getValidationProps } from 'util/Form';
import { defaultGridItemProps } from 'util/Layout';
import { UserFormValues } from 'store/types/FormValues';
import EmailFormItem from 'components/profile/EmailFormItem';
import UserNameFormSection from 'components/profile/UserNameFormSection';
import { CONFIRM_EMAIL_FIELD_NAME, EMAIL_FIELD_NAME } from 'store/configs/FormFieldNames';

const ExpressPaymentUserSection: React.FunctionComponent = () => {
  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext<UserFormValues>();
  const emailValue: string = useWatch({ control, name: EMAIL_FIELD_NAME });

  const validateField = useCallback(
    (value?: string) => {
      if (!value) {
        return getRequiredInputMessage('email confirm');
      } else {
        return getValues(EMAIL_FIELD_NAME) === value || 'Emails must match';
      }
    },
    [getValues]
  );

  return (
    <>
      <UserNameFormSection />
      <Grid {...defaultGridItemProps} md={6}>
        <EmailFormItem />
      </Grid>
      <Grid {...defaultGridItemProps} md={6}>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              {...getValidationProps(CONFIRM_EMAIL_FIELD_NAME, errors)}
              label={'Confirm Email Address'}
              required={true}
              disabled={!emailValue.length}
            />
          )}
          name={CONFIRM_EMAIL_FIELD_NAME}
          control={control}
          rules={{ validate: validateField }}
        />
      </Grid>
    </>
  );
};
export default ExpressPaymentUserSection;
