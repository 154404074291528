import { ConfigSelectOption } from 'store/types/SelectOption';
import ApplicationReviewStatus from 'store/enums/ApplicationReviewStatus';

const applicationReviewStatusConfig: Record<ApplicationReviewStatus, ConfigSelectOption> = {
  [ApplicationReviewStatus.PendingInfo]: {
    name: 'Pending Info',
    theme: 'navy',
  },
  [ApplicationReviewStatus.PendingReview]: {
    name: 'Pending Review',
    theme: 'yellow',
  },
  [ApplicationReviewStatus.Terminated]: {
    name: 'Terminated',
    theme: 'grey',
  },
  [ApplicationReviewStatus.Completed]: {
    name: 'Completed',
    theme: 'green',
  },
  [ApplicationReviewStatus.WaitingForReview]: {
    name: 'Waiting For Review',
    theme: 'violet',
  },
  [ApplicationReviewStatus.NeedMoreInformation]: {
    name: 'Need More Information',
    theme: 'dark-yellow',
  },
  [ApplicationReviewStatus.Submitted]: {
    name: 'Submitted',
    theme: 'blue',
  },
};

export default applicationReviewStatusConfig;
