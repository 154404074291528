import { HttpService } from 'services/HttpService';
import { API_URL, conf, tenantConfig } from 'config';
import AuthService from 'services/AuthService';
import Donation from 'store/types/Donation';
import Membership from 'store/types/Membership';
import { SubscriptionPlan } from 'store/types/Subscription';
import { httpRequestWrapper } from 'util/Request';

type MembershipTypesResponseData = SubscriptionPlan[];
type SubscriptionDonationsResponseData = Donation[];

class MembershipService {
  public static getMembership(): Promise<Membership> {
    return new Promise<Membership>((resolve: (data: Membership) => void, reject: (error: string) => void) => {
      httpRequestWrapper<Membership>(
        HttpService.get(`${API_URL}/membership`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject,
        false
      );
    });
  }

  public static getMembershipPlans(): Promise<MembershipTypesResponseData> {
    return new Promise<MembershipTypesResponseData>(
      (resolve: (data: MembershipTypesResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<MembershipTypesResponseData>(
          HttpService.get(`${API_URL}/membership/plans`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getDonations(): Promise<SubscriptionDonationsResponseData> {
    return new Promise<SubscriptionDonationsResponseData>(
      (resolve: (data: SubscriptionDonationsResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<SubscriptionDonationsResponseData>(
          HttpService.get(`${API_URL}/membership/donations`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getMembershipRenewalPlan(): Promise<SubscriptionPlan> {
    return new Promise<SubscriptionPlan>(
      (resolve: (data: SubscriptionPlan) => void, reject: (error: string) => void) => {
        httpRequestWrapper<SubscriptionPlan>(
          HttpService.get(`${API_URL}/membership/renewal`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject,
          false
        );
      }
    );
  }

  public static changeAutoRenewal(isEnabled: boolean): Promise<SubscriptionPlan> {
    return new Promise<SubscriptionPlan>(
      (resolve: (data: SubscriptionPlan) => void, reject: (error: string) => void) => {
        httpRequestWrapper<SubscriptionPlan>(
          HttpService.put(`${API_URL}/membership/autorenew`, {
            data: { isEnabled },
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getAppleWalletFile(): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.getFileDownload(`${API_URL}/membership/card`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
            [conf.filenameHeader]: 'wallet.pkpass',
          },
        }),
        resolve,
        reject
      );
    });
  }
}
export default MembershipService;
