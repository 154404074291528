import SelectOption from 'store/types/SelectOption';
import ApplicationReviewDecisionValue from 'store/enums/ApplicationReviewDecisionValue';

const applicationReviewDecisionConfig: Record<ApplicationReviewDecisionValue, Pick<SelectOption, 'name'>> = {
  [ApplicationReviewDecisionValue.Approve]: {
    name: 'Approve',
  },
  [ApplicationReviewDecisionValue.Reject]: {
    name: 'Reject',
  },
  [ApplicationReviewDecisionValue.RequestInformation]: {
    name: 'Request Information',
  },
};

export default applicationReviewDecisionConfig;
