import { Divider, Grid } from '@mui/material';
import Alert from '@mui/material/Alert';
import PaymentFormSection from 'components/payments/PaymentFormSection';
import FormFooter from 'components/shared/FormFooter';
import Spinner from 'components/shared/Spinner';
import SubscriptionDonationSection from 'components/subscriptions/SubscriptionDonationSection';
import SubscriptionPlanCard from 'components/subscriptions/SubscriptionPlanCard';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import PaymentService, { BasePaymentData, EnrollSubscriptionPaymentData } from 'services/api/PaymentService';
import CollectJSResponse from 'store/types/CollectJSResponse';
import { PaymentFormValues, PaymentItemsFormValues, SubscriptionPaymentFormValues } from 'store/types/FormValues';
import Payment from 'store/types/Payment';
import Subscription from 'store/types/Subscription';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import { getDonationsPaymentData } from 'util/Membership';
import {
  defaultPaymentFormValues,
  donationsAmountSelected,
  getPaymentData,
  getSummaryTotalPrice,
  paymentFormInvalid,
  shouldValidatePaymentMethod,
} from 'util/Payment';
import SubscriptionService from 'services/api/SubscriptionService';
import Donation from 'store/types/Donation';
import useRequest from 'hooks/useRequest';
import { PaymentMethodsContext } from 'components/PaymentMethodsContextWrapper';
import { DEFAULT_ERROR_MESSAGE } from 'util/Form';
import { SiteConfigView } from 'store/types/SiteConfig';
import { ConfigContext } from 'components/ConfigGuard';

import commonStyles from 'styles/common.module.scss';

interface RenewSubscriptionPageViewProps {
  data: Subscription[];
  onPaymentCompleted: (newPayment: Payment) => void;
}

const getSubmitButtonDisabled = (paymentForm: PaymentFormValues, donations: PaymentItemsFormValues): boolean => {
  return !donationsAmountSelected(donations) || paymentFormInvalid(paymentForm);
};

const RenewSubscriptionPageView: React.FunctionComponent<RenewSubscriptionPageViewProps> = ({
  data,
  onPaymentCompleted,
}) => {
  const {
    modulesSettings: {
      payments: { invoices },
    },
  }: SiteConfigView = useContext(ConfigContext);
  const { enqueueSnackbar } = useSnackbar();
  const { subscriptionId = '' } = useParams<{ subscriptionId: string }>();
  const { refetch: refetchPaymentMethods } = useContext(PaymentMethodsContext);
  const donationsRequest = useRequest<Donation[]>(SubscriptionService.getDonations);
  const [donations, setDonations] = useState<PaymentItemsFormValues>({});
  const [paymentForm, setPaymentForm] = useState<SubscriptionPaymentFormValues>({
    ...defaultPaymentFormValues,
    quantity: 0,
  });
  const [subscription, setSubscription] = useState<Subscription | undefined>(undefined);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const submitButtonDisabled = getSubmitButtonDisabled(paymentForm, donations);
  const { summaryList, quantity } = paymentForm;

  useEffect(() => {
    if (data && data.length && subscriptionId) {
      const foundSubscription: Subscription | undefined = data.find(({ id }) => id === subscriptionId);

      if (foundSubscription && foundSubscription.canRenew) {
        setSubscription(foundSubscription);
      }
    }
  }, [data, subscriptionId]);

  useEffect(() => {
    setPaymentForm((prevState) => ({
      ...prevState,
      totalPrice: getSummaryTotalPrice(summaryList),
    }));
  }, [summaryList]);

  useEffect(() => {
    setPaymentForm((prevState) => {
      const newQuantity = subscription && prevState.quantity === 0 ? subscription.quantity || 1 : prevState.quantity;

      return {
        ...prevState,
        quantity: newQuantity,
        // summaryList: getSubscriptionSummaryList(donations, subscription, newQuantity),
        summaryList: [],
      };
    });
  }, [donations, subscription]);

  const handleUsersAmountChange = useCallback(
    (usersAmount) => {
      setPaymentForm((prevState) => ({
        ...prevState,
        quantity: usersAmount,
        // summaryList: getSubscriptionSummaryList(donations, subscription, usersAmount),
        summaryList: [],
      }));
    },
    // [donations, subscription]
    []
  );

  const handleDonationSelected = useCallback((newDonations: PaymentItemsFormValues) => {
    setDonations(newDonations);
  }, []);

  const handlePaymentChange = useCallback((formValues: Partial<PaymentFormValues>) => {
    setPaymentForm((prevForm) => ({ ...prevForm, ...formValues }));
  }, []);

  const handleSubmit = () => {
    setSubmitLoading(true);
    if (shouldValidatePaymentMethod(paymentForm)) {
      (window as any).CollectJS.startPaymentRequest();
    } else {
      renewSubscription();
    }
  };

  const handlePaymentComplete = (response: CollectJSResponse) => {
    renewSubscription(response);
  };

  const renewSubscription = useCallback(
    (collectJSResponse?: CollectJSResponse) => {
      if (subscription) {
        const donationsData = getDonationsPaymentData(donations);
        const basePaymentData: BasePaymentData = getPaymentData(paymentForm, collectJSResponse);
        const paymentData: EnrollSubscriptionPaymentData = {
          ...basePaymentData,
          poNumber: paymentForm.poNumber,
          planId: subscriptionId,
          donations: donationsData,
          autoRenewal: paymentForm.autoRenewal || false,
          quantity: subscription.quantity || 1,
          renew: true,
        };

        PaymentService.makeSubscriptionPayment(paymentData)
          .then((newPayment: Payment) => {
            refetchPaymentMethods();
            enqueueSnackbar('Subscription successfully updated', { variant: 'success' });
            onPaymentCompleted(newPayment);
            setSubmitLoading(false);
          })
          .catch((errorMessage: string) => {
            setSubmitLoading(false);
            enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
          });
      } else {
        enqueueSnackbar(DEFAULT_ERROR_MESSAGE, defaultSnackbarErrorProps);
      }
    },
    [subscription, paymentForm, donations, enqueueSnackbar, subscriptionId, onPaymentCompleted, refetchPaymentMethods]
  );

  return subscription && subscription.id ? (
    <Spinner loading={submitLoading}>
      <form>
        <Grid {...defaultGridContainerProps}>
          <Grid {...defaultGridItemProps}>
            <SubscriptionPlanCard
              data={{
                ...subscription,
                price: 0,
                usersAmount: quantity,
                startDate: subscription.startDate ? moment(subscription.startDate) : undefined,
              }}
              selected={true}
              customSelectedLabel={'Your Subscription'}
              onUsersAmountChange={handleUsersAmountChange}
            />
          </Grid>
          <Grid {...defaultGridItemProps}>
            <SubscriptionDonationSection {...donationsRequest} value={donations} onChange={handleDonationSelected} />
            <Divider className={commonStyles.gridItemDivider} />
          </Grid>
          <Grid {...defaultGridItemProps}>
            <PaymentFormSection
              form={paymentForm}
              onChange={handlePaymentChange}
              onPaymentComplete={handlePaymentComplete}
              postponePaymentMethodVisible={true}
              autoRenewalVisible={true}
              paymentConfig={invoices}
            />
          </Grid>
        </Grid>
        <FormFooter
          onSubmit={handleSubmit}
          submitButtonName={'Complete Payment'}
          submitButtonDisabled={submitButtonDisabled}
        />
      </form>
    </Spinner>
  ) : (
    <Alert severity={'error'} className={commonStyles.alert}>
      {'Subscription not found'}
    </Alert>
  );
};
export default RenewSubscriptionPageView;
