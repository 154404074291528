import React, { ChangeEvent, useCallback, useContext, useState } from 'react';
import useRequest from 'hooks/useRequest';
import UserRelationshipTable from './UserRelationshipTable';
import UserRelationshipService from 'services/api/UserRelationshipService';
import { UserRelationship } from 'store/types/UserRelationship';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { Button, Grid } from '@mui/material';
import Add from '@mui/icons-material/Add';
import SearchInput from 'components/shared/SearchInput';
import { FilterConfig } from 'store/types/Table';
import AddNewUserRelationshipModal from './AddNewUserRelationshipModal';
import { Alert } from '@mui/lab';
import LinkUserRelationshipModal from './LinkUserRelationshipModal';
import SelectOption from 'store/types/SelectOption';
import Spinner from 'components/shared/Spinner';
import { UserContext } from 'components/UserGuard';

import commonStyles from 'styles/common.module.scss';
import styles from './UserRelationshipPageView.module.scss';

interface UserRelationshipPageViewProps {
  onSelectUserId?: (userId: string) => void;
  showRadioButtons?: boolean;
  customerId?: string;
  eventId?: string;
}

const UserRelationshipPageView: React.FunctionComponent<UserRelationshipPageViewProps> = ({
  onSelectUserId,
  showRadioButtons,
  eventId,
  customerId,
}) => {
  const { id } = useContext(UserContext);
  const userRelationshipRequest = useCallback(
    () => UserRelationshipService.getUserRelationship(customerId || id, eventId),
    [customerId, eventId, id]
  );
  const userRelationshipRequestData = useRequest<UserRelationship[]>(userRelationshipRequest);
  const [filter, setFilter] = useState<FilterConfig<UserRelationship>>({ search: '' });
  const { data: relationshipsList, refetch } = userRelationshipRequestData;
  const {
    data: relationshipsType,
    error,
    loading,
  } = useRequest<SelectOption[]>(UserRelationshipService.getUserRelationshipTypes);
  const [addAccountModalOpen, setAddAccountModalOpen] = useState<boolean>(false);
  const [linkAccountModalOpen, setLinkAccountModalOpen] = useState<boolean>(false);
  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setFilter((prevState) => ({ ...prevState, search: e.target.value }));
  }, []);

  const handleAddAccountModalOpen = useCallback(() => {
    setAddAccountModalOpen(true);
  }, []);

  const handleAddAccountModalClose = useCallback(() => {
    setAddAccountModalOpen(false);
    setLinkAccountModalOpen(false);
  }, []);

  const handleLinkAccountModalOpen = useCallback(() => {
    setLinkAccountModalOpen(true);
  }, []);

  const handleLinkAccountModalClose = useCallback(() => {
    setLinkAccountModalOpen(false);
  }, []);

  return (
    <Spinner loading={loading}>
      {error ? (
        <Alert severity={'error'} className={commonStyles.alert}>
          {error}
        </Alert>
      ) : (
        <Grid {...defaultGridContainerProps}>
          <Grid {...defaultGridItemProps} xs={'auto'}>
            <Button
              color={'secondary'}
              variant={'contained'}
              startIcon={<Add />}
              className={commonStyles.uppercase}
              onClick={handleLinkAccountModalOpen}
            >
              {'Add New Person'}
            </Button>
          </Grid>
          {!!relationshipsList?.length && (
            <Grid {...defaultGridItemProps} lg={5} className={styles.search}>
              <SearchInput loading={loading} value={filter.search} onChange={handleSearchChange} />
            </Grid>
          )}
          <Grid {...defaultGridItemProps}>
            <UserRelationshipTable
              {...userRelationshipRequestData}
              types={relationshipsType}
              filter={filter}
              onSelectUserId={onSelectUserId}
              showRadioButtons={showRadioButtons}
              customerId={customerId}
            />
          </Grid>
        </Grid>
      )}
      {addAccountModalOpen && (
        <AddNewUserRelationshipModal
          onClose={handleAddAccountModalClose}
          open={addAccountModalOpen}
          types={relationshipsType || []}
          customerId={customerId}
          refetch={refetch}
        />
      )}
      {linkAccountModalOpen && (
        <LinkUserRelationshipModal
          customerId={customerId}
          onClose={handleLinkAccountModalClose}
          onAddPersonModalOpen={handleAddAccountModalOpen}
          open={linkAccountModalOpen}
          types={relationshipsType || []}
          refetch={refetch}
        />
      )}
    </Spinner>
  );
};

export default UserRelationshipPageView;
