import { Grid } from '@mui/material';
import Table from 'components/shared/Table';
import React, { ChangeEvent, Reducer, useCallback, useEffect, useReducer } from 'react';
import { SortConfig, TableColumn } from 'store/types/Table';
import SearchInput from 'components/shared/SearchInput';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import StatusLabel from 'components/shared/StatusLabel';
import { getStringValue, NUMBER_DATE_FORMAT } from 'util/Format';
import applicationReviewStatusConfig from 'store/configs/ApplicationReviewStatusConfig';
import ApplicationReviewStatus from 'store/enums/ApplicationReviewStatus';
import ApplicationReview from 'store/types/ApplicationReview';
import TableCountLabel from 'components/shared/TableCountLabel';
import routes from 'store/configs/Routes';
import { getHashRouteUrl } from 'util/Route';
import ApplicationsTableActionButton from 'components/applications/ApplicationsTableActionButton';
import { isReviewEditable } from 'util/Application';
import reducer, {
  initialState,
  ApplicationReviewsTableAction,
  ApplicationReviewsTableActionType,
  ApplicationReviewsTableState,
} from './ApplicationReviewsTableReducer';

import styles from './ApplicationReviewsTable.module.scss';

interface ApplicationReviewsTableProps {
  data?: ApplicationReview[];
}

const getStatusLabel = (status: ApplicationReviewStatus): React.ReactNode => {
  const statusConfig = applicationReviewStatusConfig[status];

  return (
    <StatusLabel
      theme={statusConfig?.theme || 'grey'}
      status={statusConfig?.name || status}
      className={styles.status}
    />
  );
};

const getActions = ({ status, id }: ApplicationReview): React.ReactNode =>
  Object.values(ApplicationReviewStatus).includes(status as ApplicationReviewStatus) &&
  (isReviewEditable(status as ApplicationReviewStatus) ? (
    <ApplicationsTableActionButton
      href={getHashRouteUrl(`${routes.editApplicationReview}/${id}`)}
      actionLabel={'Edit'}
      nameLabel={'Review'}
    />
  ) : (
    <ApplicationsTableActionButton
      href={getHashRouteUrl(`${routes.viewApplicationReview}/${id}`)}
      actionLabel={'View'}
      nameLabel={'Review'}
    />
  ));

const ApplicationReviewsTable: React.FunctionComponent<ApplicationReviewsTableProps> = ({ data = [] }) => {
  const [{ sort, filter, list = [], initialList = [] }, dispatch] = useReducer<
    Reducer<ApplicationReviewsTableState, ApplicationReviewsTableAction>
  >(reducer, initialState);

  useEffect(() => {
    if (data.length) {
      dispatch({
        type: ApplicationReviewsTableActionType.SetInitialList,
        payload: { initialList: data },
      });
    }
  }, [data]);

  const handleSortChange = useCallback((newSort: SortConfig) => {
    dispatch({ type: ApplicationReviewsTableActionType.UpdateSort, payload: { sort: newSort } });
  }, []);

  const columns: Array<TableColumn<ApplicationReview>> = [
    {
      dataIndex: 'status',
      label: 'Status',
      sortable: true,
      align: 'center',
      render: (status: ApplicationReviewStatus) => getStatusLabel(status),
    },
    {
      dataIndex: 'applicationId',
      label: 'Application #',
      sortable: true,
    },
    {
      dataIndex: 'name',
      label: 'Name',
      sortable: true,
    },
    {
      dataIndex: 'submittedDate',
      label: 'Submitted',
      sortable: true,
      render: (date: string) => getStringValue(date, NUMBER_DATE_FORMAT),
    },
    {
      dataIndex: 'dueDate',
      label: 'Due Date',
      sortable: true,
      align: 'center',
      render: (date?: string) => getStringValue(date, NUMBER_DATE_FORMAT),
    },
    {
      key: 'actions',
      label: 'Actions',
      align: 'center',
      render: (_: any, record: ApplicationReview) => getActions(record),
    },
  ];

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: ApplicationReviewsTableActionType.UpdateFilter,
      payload: { filter: { search: e.target.value } },
    });
  }, []);

  return (
    <>
      <Grid {...defaultGridContainerProps} justifyContent={'flex-end'}>
        <Grid {...defaultGridItemProps} sm={7}>
          <SearchInput value={filter.search} onChange={handleSearchChange} />
        </Grid>
        <Grid {...defaultGridItemProps}>
          <Grid container={true} spacing={2}>
            <Grid {...defaultGridItemProps}>
              <TableCountLabel viewCount={list.length} totalCount={initialList.length} />
            </Grid>
            <Grid {...defaultGridItemProps}>
              <Table columns={columns} list={list} sort={sort} onSortChange={handleSortChange} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default ApplicationReviewsTable;
