import { sorter } from 'util/Table';
import { FilterConfig, SortConfig } from 'store/types/Table';
import { UserRelationship } from 'store/types/UserRelationship';

export enum UserRelationshipTableActionType {
  SetInitialList = 'SetInitialList',
  UpdateSort = 'UpdateSort',
  UpdateFilter = 'UpdateFilter',
  OpenDeleteModal = 'OpenDeleteModal',
  CloseDeleteModal = 'CloseDeleteModal',
}

export type TableRelationshipUser = Omit<UserRelationship, 'typeId' | 'firstName' | 'lastName' | 'middleInitial'> & {
  userFullName: string;
  typeName: string;
};

export interface UserRelationshipTableState {
  initialList: TableRelationshipUser[];
  list: TableRelationshipUser[];
  sort: SortConfig<TableRelationshipUser>;
  selectedItem?: TableRelationshipUser;
  filter: FilterConfig<TableRelationshipUser>;
}

export interface UserRelationshipTableAction {
  type: UserRelationshipTableActionType;
  payload: Partial<UserRelationshipTableState>;
}

const getSortedList = (
  list: TableRelationshipUser[] = [],
  sort: SortConfig<TableRelationshipUser>
): TableRelationshipUser[] => {
  return list.length ? [...list].sort(sorter<TableRelationshipUser>(sort)) : [...list];
};

const getFilteredList = (list: TableRelationshipUser[] = [], filter: FilterConfig<TableRelationshipUser>) => {
  let result = [...list];
  const searchValue = (filter.search || '').toLowerCase();

  if (searchValue) {
    result = result.filter(
      ({ userFullName, email, typeName }: TableRelationshipUser) =>
        userFullName.toLowerCase().includes(searchValue) ||
        email.toLowerCase().includes(searchValue) ||
        typeName.toLowerCase().includes(searchValue)
    );
  }
  return result;
};

export const initialState: UserRelationshipTableState = {
  initialList: [],
  list: [],
  sort: { direction: 'asc', column: 'userFullName' },
  selectedItem: undefined,
  filter: { search: '' },
};

const reducer = (
  state: UserRelationshipTableState,
  { type, payload }: UserRelationshipTableAction
): UserRelationshipTableState => {
  if (type === UserRelationshipTableActionType.SetInitialList) {
    const { initialList = [] } = payload;

    return { ...state, initialList, list: getSortedList(initialList, state.sort) };
  }

  if (type === UserRelationshipTableActionType.UpdateSort) {
    const { sort = {} } = payload;
    const newList = getSortedList(state.initialList, sort);

    return { ...state, sort, list: newList };
  }

  if (type === UserRelationshipTableActionType.OpenDeleteModal) {
    const { selectedItem } = payload;

    return { ...state, selectedItem };
  }

  if (type === UserRelationshipTableActionType.CloseDeleteModal) {
    return { ...state, selectedItem: undefined };
  }

  if (type === UserRelationshipTableActionType.UpdateFilter) {
    const { filter = {} } = payload;
    const newFilter = { ...state.filter, ...filter };

    return {
      ...state,
      filter: newFilter,
      list: getFilteredList(getSortedList(state.initialList, state.sort), newFilter),
    };
  }

  return state;
};

export default reducer;
