import React from 'react';
import classNames from 'classnames';
import { Community } from 'store/types/Community';
import Card from 'components/shared/Card';
import { Button, Divider, Grid } from '@mui/material';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import ShowMoreText from 'components/shared/ShowMoreText';

import commonStyles from 'styles/common.module.scss';
import styles from './PublicCommitteeItem.module.scss';
import { getHashRouteUrl } from 'util/Route';
import routes from 'store/configs/Routes';

interface PublicCommitteeItemProps {
  data: Community;
}

const PublicCommitteeItem: React.FunctionComponent<PublicCommitteeItemProps> = ({ data }) => {
  const { name, description, id } = data;

  return (
    <Card>
      <Grid {...defaultGridContainerProps} spacing={2}>
        <Grid {...defaultGridItemProps} className={styles.cardHeader}>
          <h2 className={styles.title}>{name}</h2>
          <Button
            color={'primary'}
            variant={'outlined'}
            href={getHashRouteUrl(`${routes.publicCommitteesDetails}/${id}`)}
          >
            {'View Details'}
          </Button>
        </Grid>
        <Divider className={styles.divider} />
        <Grid {...defaultGridItemProps}>
          {description && (
            <p className={classNames(commonStyles.text, styles.description)}>
              <ShowMoreText maxLength={50}>{description}</ShowMoreText>
            </p>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};
export default PublicCommitteeItem;
