import { AutocompleteRenderInputParams, Grid, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import Alert from '@mui/material/Alert';
import React, { useContext, useEffect, useState } from 'react';
import { defaultGridContainerProps, defaultGridItemProps, getInputLoadingProps } from 'util/Layout';
import { UserContext } from 'components/UserGuard';
import { ALL_COMPANIES_DROPDOWN_KEY, CompanySelectOption } from 'util/Company';
import Autocomplete from '@mui/material/Autocomplete';
import Search from '@mui/icons-material/Search';
import { useLocation } from 'react-router';
import { preselectedOrdersStatePropName } from 'util/Payment';

interface CompanySelectProps extends Pick<TextFieldProps, 'size'> {
  options: CompanySelectOption[];
  label?: string;
  value?: string;
  onChange: (newValue: string) => void;
  adminCompaniesOnly?: boolean;
  showAllCompaniesMenuItem?: boolean;
}

const CompanySelect: React.FunctionComponent<CompanySelectProps> = ({
  onChange,
  options,
  size,
  showAllCompaniesMenuItem,
  label,
}) => {
  const { userLoading } = useContext(UserContext);
  const [selectedOption, setSelectedOptions] = useState<CompanySelectOption>({ label: '', id: '' });
  const { state } = useLocation<{ [preselectedOrdersStatePropName]?: string[] }>();

  useEffect(() => {
    if (selectedOption.id === ALL_COMPANIES_DROPDOWN_KEY && !showAllCompaniesMenuItem) {
      onChange(options[0].id);
      setSelectedOptions(options[0]);
    }
  }, [onChange, options, selectedOption, showAllCompaniesMenuItem]);

  useEffect(() => {
    if (options.length && !selectedOption.id) {
      onChange(options[0].id);
      setSelectedOptions(options[0]);
    }
  }, [options, onChange, selectedOption, showAllCompaniesMenuItem]);

  useEffect(() => {
    if (options.length && showAllCompaniesMenuItem && !state) {
      onChange(options[0].id);
      setSelectedOptions(options[0]);
    }
  }, [options, showAllCompaniesMenuItem, onChange, state]);
  const searchInputRender = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      size={size}
      disabled={userLoading}
      label={label}
      InputProps={{
        ...params.InputProps,
        ...getInputLoadingProps(userLoading),
        startAdornment: (
          <InputAdornment position={'start'}>
            <Search color={'inherit'} />
          </InputAdornment>
        ),
      }}
      placeholder={'Start typing to search...'}
    />
  );

  return (
    <Grid {...defaultGridContainerProps}>
      {userLoading || options.length || selectedOption.id ? (
        <>
          <Grid {...defaultGridItemProps}>
            <Autocomplete
              renderInput={searchInputRender}
              options={options}
              value={selectedOption}
              disableClearable={true}
              renderOption={(props, option) => (
                <li {...props} key={props.id}>
                  {option.label}
                </li>
              )}
              onChange={(_: any, value: CompanySelectOption) => {
                onChange(value.id);
                setSelectedOptions(value);
              }}
            />
          </Grid>
        </>
      ) : (
        <Grid {...defaultGridItemProps} md={12}>
          <Alert severity={'error'}>{'No companies available'}</Alert>
        </Grid>
      )}
    </Grid>
  );
};
export default CompanySelect;
