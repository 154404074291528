import { ConfigSelectOption } from 'store/types/SelectOption';
import SupportCaseStatus from '../enums/SupportCaseStatus';

const supportCaseStatusConfig: Record<SupportCaseStatus, ConfigSelectOption> = {
  [SupportCaseStatus.Closed]: {
    name: 'Closed',
    theme: 'green',
  },
  [SupportCaseStatus.InProgress]: {
    name: 'In Progress',
    theme: 'navy',
  },
  [SupportCaseStatus.NotStarted]: {
    name: 'Not Started',
    theme: 'dark-yellow',
  },
  [SupportCaseStatus.ReOpened]: {
    name: 'Re-Opened',
    theme: 'red',
  },
  [SupportCaseStatus.Escalated]: {
    name: 'Escalated',
    theme: 'violet',
  },
};

export default supportCaseStatusConfig;
