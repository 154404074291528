import { Button } from '@mui/material';
import classNames from 'classnames';
import PaymentFormSection from 'components/payments/PaymentFormSection';
import PaymentDetailsModal from 'components/payments/PaymentDetailsModal';
import FormFooter from 'components/shared/FormFooter';
import Spinner from 'components/shared/Spinner';
import { useSnackbar } from 'notistack';
import React, { Reducer, useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { PaymentFormValues } from 'store/types/FormValues';
import Payment, { OrderPayment } from 'store/types/Payment';
import { getFormattedPayment, getSummaryTotalPrice } from 'util/Payment';
import PaymentSummaryItem from 'store/types/PaymentSummaryItem';
import CollectJSResponse from 'store/types/CollectJSResponse';
import { getFormattedOrdersList, getPaymentData, paymentFormInvalid, shouldValidatePaymentMethod } from 'util/Payment';
import PaymentService, { BasePaymentData, OrderPaymentData } from 'services/api/PaymentService';
import InvoicesTable from 'components/payments/InvoicesTable';
import Stepper from 'components/shared/Stepper';
import PaymentUser, { PaymentUserIdList } from 'store/types/PaymentUser';
import UseRequestData from 'store/types/UseRequestData';
import { getHashRouteUrl } from 'util/Route';
import { PaymentMethodsContext } from 'components/PaymentMethodsContextWrapper';
import reducer, {
  initialState,
  MakeGroupPaymentPageViewAction,
  MakeGroupPaymentPageViewActionType,
  MakeGroupPaymentPageViewState,
} from './MakeGroupPaymentPageViewReducer';
import PaymentUsersTable from './PaymentUsersTable';
import routes from 'store/configs/Routes';
import * as MUIcon from '@mui/icons-material';
import { ParametersContext } from 'components/ParametersGuard';
import { ConfigContext } from 'components/ConfigGuard';
import { SiteConfigView } from 'store/types/SiteConfig';
import { defaultSnackbarErrorProps } from 'util/Layout';

import commonStyles from 'styles/common.module.scss';
import styles from './MakeGroupPaymentPageView.module.scss';

interface GroupPaymentStepConfigItem {
  label: string;
  icon: keyof typeof MUIcon;
}

const stepsConfig: GroupPaymentStepConfigItem[] = [
  {
    label: 'Select User(s)',
    icon: 'GroupAdd',
  },
  {
    label: 'Select Invoice(s)',
    icon: 'Receipt',
  },
  {
    label: 'Complete Payment',
    icon: 'AttachMoney',
  },
];

const getSubmitButtonDisabled = (
  step = 0,
  paymentForm: PaymentFormValues,
  selectedUsers: PaymentUserIdList = [],
  selectedOrders: OrderPayment[] = []
): boolean => {
  const firstStepDisabled = !selectedUsers.length;
  const secondStepDisabled = firstStepDisabled || !selectedOrders.length;
  const thirdStepDisabled = firstStepDisabled || secondStepDisabled || paymentFormInvalid(paymentForm);

  if (step === 0) {
    return firstStepDisabled;
  } else if (step === 1) {
    return secondStepDisabled;
  } else {
    return thirdStepDisabled;
  }
};

const MakeGroupPaymentPageView: React.FunctionComponent<UseRequestData<PaymentUser[]>> = (usersRequest) => {
  const {
    modulesSettings: {
      payments: { invoices },
    },
  }: SiteConfigView = useContext(ConfigContext);
  const history = useHistory();
  const { pathname } = useLocation();
  const {
    groupPayments: { payMyInvoicesTabHeader },
  } = useContext(ParametersContext);
  const { enqueueSnackbar } = useSnackbar();
  const { userId = '' } = useParams<{ userId: string }>();
  const { refetch: refetchPaymentMethods } = useContext(PaymentMethodsContext);
  const [{ paymentForm, payment, submitLoading, ordersLoading, ordersList, selectedOrders, selectedUsers }, dispatch] =
    useReducer<Reducer<MakeGroupPaymentPageViewState, MakeGroupPaymentPageViewAction>>(reducer, initialState);
  const [step, setStep] = useState<number>(0);

  const submitButtonDisabled = getSubmitButtonDisabled(step, paymentForm, selectedUsers, selectedOrders);

  useEffect(() => {
    const newSummaryList: PaymentSummaryItem[] = selectedOrders.map(({ totalAmount, invoiceName, orderNumber }) => ({
      name: invoiceName || orderNumber,
      price: totalAmount,
    }));

    dispatch({
      type: MakeGroupPaymentPageViewActionType.UpdatePaymentForm,
      payload: {
        paymentForm: {
          summaryList: newSummaryList,
          totalPrice: getSummaryTotalPrice(newSummaryList),
        },
      },
    });
  }, [selectedOrders]);

  useEffect(() => {
    if (pathname.includes(routes.groupResponseApprove)) {
      PaymentService.approvePaymentAccessRequest(userId)
        .then(() => {
          enqueueSnackbar('Response to access inquiry sent successfully.', { variant: 'success' });
          history.push(routes.groupPayments);
        })
        .catch((errorMessage: string) => {
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        });
    } else if (pathname.includes(routes.groupResponseReject)) {
      PaymentService.rejectPaymentAccessRequest(userId)
        .then(() => {
          enqueueSnackbar('Response to access inquiry sent successfully.', { variant: 'success' });
          history.push(routes.groupPayments);
        })
        .catch((errorMessage: string) => {
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        });
    }
  }, [pathname, history, enqueueSnackbar, userId]);

  const handlePaymentChange = useCallback((formValues: Partial<PaymentFormValues>) => {
    dispatch({
      type: MakeGroupPaymentPageViewActionType.UpdatePaymentForm,
      payload: { paymentForm: formValues },
    });
  }, []);

  const handleOrdersSelected = useCallback((selectedItems: OrderPayment[]) => {
    dispatch({
      type: MakeGroupPaymentPageViewActionType.UpdateSelectedOrdersList,
      payload: { selectedOrders: selectedItems },
    });
  }, []);

  const handlePaymentComplete = (response: CollectJSResponse) => {
    makePayment(response);
  };

  const loadUserOrders = useCallback(() => {
    dispatch({ type: MakeGroupPaymentPageViewActionType.SetOrdersLoading, payload: { ordersLoading: true } });
    PaymentService.getGroupPaymentOrdersList(selectedUsers)
      .then((orders: OrderPayment[] = []) => {
        dispatch({
          type: MakeGroupPaymentPageViewActionType.SetOrdersList,
          payload: { ordersList: getFormattedOrdersList(orders, true) },
        });
      })
      .catch((errorMessage: string) => {
        dispatch({ type: MakeGroupPaymentPageViewActionType.SetOrdersLoading, payload: { ordersLoading: false } });
        enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
      });
  }, [selectedUsers, enqueueSnackbar]);

  const makePayment = useCallback(
    (collectJSResponse?: CollectJSResponse) => {
      const basePaymentData: BasePaymentData = getPaymentData(paymentForm, collectJSResponse);
      const paymentData: OrderPaymentData = {
        ...basePaymentData,
        invoices: selectedOrders.map(({ id }) => id),
      };

      PaymentService.makeGroupPayment(paymentData)
        .then((newPayment: Payment) => {
          refetchPaymentMethods();
          enqueueSnackbar('Payment successfully confirmed', { variant: 'success' });
          dispatch({
            type: MakeGroupPaymentPageViewActionType.OpenPaymentConfirmation,
            payload: {
              payment: getFormattedPayment(newPayment),
            },
          });
        })
        .catch((errorMessage: string) => {
          dispatch({ type: MakeGroupPaymentPageViewActionType.SetSubmitLoading, payload: { submitLoading: false } });
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        });
    },
    [paymentForm, enqueueSnackbar, selectedOrders, refetchPaymentMethods]
  );

  const handleNextStep = () => {
    if (submitButtonDisabled) {
      enqueueSnackbar('Please fill the form', defaultSnackbarErrorProps);
    } else {
      if (step < 2) {
        const newStep = step + 1;

        if (step === 0) {
          loadUserOrders();
        }
        if (newStep === 2 && paymentForm.totalPrice === 0) {
          (window as any).CollectJS.clearInputs();
        }
        setStep(newStep);
      } else {
        dispatch({ type: MakeGroupPaymentPageViewActionType.SetSubmitLoading, payload: { submitLoading: true } });
        if (shouldValidatePaymentMethod(paymentForm)) {
          (window as any).CollectJS.startPaymentRequest();
        } else {
          makePayment();
        }
      }
    }
  };

  const handlePrevStep = useCallback(() => {
    setStep((prevState) => prevState - 1);
  }, []);

  const handleUsersSelected = useCallback((selectedItems: PaymentUserIdList = []) => {
    dispatch({
      type: MakeGroupPaymentPageViewActionType.UpdateSelectedUsersList,
      payload: { selectedUsers: selectedItems },
    });
  }, []);

  return (
    <>
      <Spinner loading={submitLoading}>
        {payMyInvoicesTabHeader && <p dangerouslySetInnerHTML={{ __html: payMyInvoicesTabHeader }} />}
        <form className={styles.container}>
          <Stepper
            activeStep={step}
            config={stepsConfig.map(({ label, icon }, index) => ({ label, icon, value: index }))}
          />
          <section className={classNames(styles.section, { [commonStyles.hidden]: step !== 0 })}>
            <PaymentUsersTable selectedIds={selectedUsers} onIdsSelected={handleUsersSelected} {...usersRequest} />
          </section>
          <section className={classNames(styles.section, { [commonStyles.hidden]: step !== 1 })}>
            <InvoicesTable
              data={ordersList}
              selectedItems={selectedOrders}
              onItemsSelected={handleOrdersSelected}
              loading={ordersLoading}
            />
          </section>
          <section className={classNames(styles.section, { [commonStyles.hidden]: step !== 2 })}>
            <PaymentFormSection
              form={paymentForm}
              onChange={handlePaymentChange}
              onPaymentComplete={handlePaymentComplete}
              checkPaymentMethodVisible={true}
              paymentConfig={invoices}
            />
          </section>
          <FormFooter
            onSubmit={handleNextStep}
            submitButtonName={step === 2 ? 'Submit' : 'Next'}
            submitButtonDisabled={submitButtonDisabled}
          >
            <Button color={'secondary'} variant={'outlined'} disabled={step === 0} onClick={handlePrevStep}>
              Back
            </Button>
          </FormFooter>
        </form>
      </Spinner>
      {!!payment && (
        <PaymentDetailsModal
          open={!!payment}
          payment={payment}
          title={'Payment Confirmation'}
          isOrderPaymentConfirmation={true}
          actions={
            <Button color={'secondary'} variant={'contained'} href={getHashRouteUrl(routes.groupPayments)}>
              {'Go to Billing History'}
            </Button>
          }
        />
      )}
    </>
  );
};
export default MakeGroupPaymentPageView;
