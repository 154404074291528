import { SortConfig } from 'store/types/Table';
import { sorter } from 'util/Table';
import { PaymentMethodView } from 'store/types/PaymentMethod';

export enum PaymentMethodsTableActionType {
  SetInitialList = 'SetInitialList',
  SetSubmitLoading = 'SetSubmitLoading',
  UpdateSort = 'UpdateSort',
  OpenEditModal = 'OpenEditModal',
  CloseEditModal = 'CloseEditModal',
  OpenConfirmationModal = 'OpenConfirmationModal',
  CloseConfirmationModal = 'CloseConfirmationModal',
  SetDeletePaymentMethodDisabled = 'SetDeletePaymentMethodDisabled',
}

export interface PaymentMethodsTableState {
  initialList: PaymentMethodView[];
  list: PaymentMethodView[];
  sort: SortConfig<PaymentMethodView>;
  selectedItem?: PaymentMethodView;
  submitLoading?: boolean;
  editModalOpen?: boolean;
  deleteModalOpen?: boolean;
  deletePaymentMethodDisabled?: boolean;
}

export interface PaymentMethodsTableAction {
  type: PaymentMethodsTableActionType;
  payload: Partial<PaymentMethodsTableState>;
}

const getSortedList = (list: PaymentMethodView[] = [], sort: SortConfig<PaymentMethodView>): PaymentMethodView[] =>
  list.length ? [...list].sort(sorter<PaymentMethodView>(sort)) : [...list];

export const initialState: PaymentMethodsTableState = {
  initialList: [],
  list: [],
  sort: { direction: 'desc', column: 'status' },
};

const reducer = (
  state: PaymentMethodsTableState,
  { type, payload }: PaymentMethodsTableAction
): PaymentMethodsTableState => {
  if (type === PaymentMethodsTableActionType.SetInitialList) {
    const { initialList = [] } = payload;
    const list = getSortedList(initialList, state.sort);

    return { ...state, initialList, list };
  }

  if (type === PaymentMethodsTableActionType.SetSubmitLoading) {
    const { submitLoading = false } = payload;

    return { ...state, submitLoading };
  }

  if (type === PaymentMethodsTableActionType.SetDeletePaymentMethodDisabled) {
    const { deletePaymentMethodDisabled = false, deleteModalOpen } = payload;

    return { ...state, deletePaymentMethodDisabled, deleteModalOpen };
  }

  if (type === PaymentMethodsTableActionType.UpdateSort) {
    const { sort = {} } = payload;

    return {
      ...state,
      sort,
      list: getSortedList(state.initialList, sort),
    };
  }

  if (type === PaymentMethodsTableActionType.OpenEditModal) {
    const { selectedItem } = payload;

    return { ...state, editModalOpen: true, selectedItem };
  }

  if (type === PaymentMethodsTableActionType.CloseEditModal) {
    return { ...state, editModalOpen: false, selectedItem: undefined };
  }

  if (type === PaymentMethodsTableActionType.OpenConfirmationModal) {
    const { selectedItem } = payload;

    return selectedItem
      ? {
          ...state,
          deleteModalOpen: true,
          selectedItem,
        }
      : {
          ...state,
          deleteModalOpen: true,
        };
  }

  if (type === PaymentMethodsTableActionType.CloseConfirmationModal) {
    return { ...state, deleteModalOpen: false, editModalOpen: false, submitLoading: false };
  }

  return state;
};

export default reducer;
