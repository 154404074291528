import { Button, Checkbox, FormControlLabel, Grid, FormHelperText } from '@mui/material';
import DonationPriceForm from 'components/donation/DonationPriceForm';
import Card from 'components/shared/Card';
import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useParams } from 'react-router';
import { DonationInfoFormValues } from 'store/types/FormValues';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import DonationRecurringPeriod from 'store/enums/DonationRecurringPeriod';
import { ConfigContext } from 'components/ConfigGuard';
import DonationFund, { DonationCampaign } from 'store/types/DonationFund';
import SiteModule from 'store/enums/SiteModule';
import DonationType from 'store/enums/DonationType';
import { getRequiredValidationRule, getValidationProps } from 'util/Form';
import classNames from 'classnames';

import commonStyles from 'styles/common.module.scss';
import pageStyles from '../DonationPaymentPageView.module.scss';

const defaultDonationValues: Pick<
  DonationCampaign,
  'isRecurringDonationAvailable' | 'isSingleDonationAvailable' | 'defaultDonation'
> = {
  isRecurringDonationAvailable: true,
  isSingleDonationAvailable: true,
  defaultDonation: DonationType.Single,
};

interface DonationPriceSectionProps {
  donationFund: DonationFund;
}

const recurringPeriodButtonConfig: DonationRecurringPeriod[] = [
  DonationRecurringPeriod.Weekly,
  DonationRecurringPeriod.Monthly,
  DonationRecurringPeriod.Quarterly,
  DonationRecurringPeriod.Annually,
];

const recurrentPeriodFieldName = 'recurrentPeriod';
const donationTypeFieldName = 'donationType';

const DonationPriceSection: React.FunctionComponent<DonationPriceSectionProps> = ({ donationFund }) => {
  const { donationId = '' } = useParams<{ donationId: string }>();
  const {
    enabledModules,
    modulesSettings: {
      donations: { defaultFeePercent, predefinedAmounts },
    },
  } = useContext(ConfigContext);
  const { processingFeePercent = defaultFeePercent, campaign } = donationFund;
  const { isSingleDonationAvailable, isRecurringDonationAvailable, defaultDonation } = useMemo(
    () =>
      !campaign || (!campaign.isSingleDonationAvailable && !campaign.isRecurringDonationAvailable)
        ? defaultDonationValues
        : campaign,
    [campaign]
  );
  const allDonationAvailable = useMemo(
    () => !isSingleDonationAvailable && !isRecurringDonationAvailable,
    [isSingleDonationAvailable, isRecurringDonationAvailable]
  );
  const {
    setValue,
    control,
    formState: { errors },
  } = useFormContext<DonationInfoFormValues>();
  const validationAmountProps = getValidationProps('amount', errors);
  const recurringPeriodError: string = errors[recurrentPeriodFieldName]?.message || '';
  const [recurrentPeriod, donationType] = useWatch({
    control,
    name: [recurrentPeriodFieldName, donationTypeFieldName],
  });
  const amountItems: number[] | undefined = useMemo(
    () =>
      campaign?.preDefinedDonationAmount
        ? Object.values(campaign.preDefinedDonationAmount)
        : predefinedAmounts?.length
        ? predefinedAmounts
        : undefined,
    [campaign, predefinedAmounts]
  );

  useEffect(() => {
    const value =
      defaultDonation === DonationType.Recurring && isRecurringDonationAvailable
        ? defaultDonation
        : isSingleDonationAvailable
        ? DonationType.Single
        : DonationType.Recurring;
    setValue(donationTypeFieldName, value);
  }, [defaultDonation, setValue, isRecurringDonationAvailable, isSingleDonationAvailable]);

  useEffect(() => {
    setValue(
      recurrentPeriodFieldName,
      donationType === DonationType.Recurring ? recurrentPeriod || DonationRecurringPeriod.None : undefined
    );
  }, [donationType, recurrentPeriod, setValue]);

  const handlePriceChange = useCallback(
    (onChange) => (amount: number) => {
      onChange(amount);
      setValue('amount', amount);
    },
    [setValue]
  );

  const handleTypeChange = useCallback(
    (newType: DonationType) => () => {
      setValue(donationTypeFieldName, newType);
    },
    [setValue]
  );

  const handleRecurringPeriodChange = useCallback(
    (newPeriod: DonationRecurringPeriod) => () => {
      setValue(recurrentPeriodFieldName, newPeriod);
    },
    [setValue]
  );

  const validateField = useCallback((value: number) => {
    if (!value) {
      return 'Please enter valid amount';
    }
  }, []);

  const handleChange = useCallback((onChange) => (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.checked), []);

  return (
    <>
      <Card
        contentClassName={pageStyles.cardContent}
        headerClassName={pageStyles.cardHeader}
        title={<h2>Donation Details</h2>}
      >
        {donationId ? (
          <Grid {...defaultGridContainerProps}>
            <Grid {...defaultGridItemProps}>
              <Grid {...defaultGridContainerProps}>
                {(isSingleDonationAvailable || allDonationAvailable) && (
                  <Controller
                    render={() => (
                      <Grid {...defaultGridItemProps} sm={6}>
                        <Button
                          fullWidth={true}
                          color={'primary'}
                          variant={donationType === DonationType.Single ? 'contained' : 'outlined'}
                          onClick={handleTypeChange(DonationType.Single)}
                        >
                          {'Single Donation'}
                        </Button>
                      </Grid>
                    )}
                    name={donationTypeFieldName}
                    control={control}
                  />
                )}
                {(isRecurringDonationAvailable || allDonationAvailable) && (
                  <Controller
                    render={() => (
                      <Grid {...defaultGridItemProps} sm={6}>
                        {enabledModules.includes(SiteModule.DonationsPremium) && (
                          <Button
                            fullWidth={true}
                            color={'primary'}
                            variant={donationType === DonationType.Recurring ? 'contained' : 'outlined'}
                            onClick={handleTypeChange(DonationType.Recurring)}
                          >
                            {'Recurring Donation'}
                          </Button>
                        )}
                      </Grid>
                    )}
                    name={donationTypeFieldName}
                    control={control}
                  />
                )}
              </Grid>
            </Grid>
            <Grid {...defaultGridItemProps}>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <DonationPriceForm
                    onChange={handlePriceChange(onChange)}
                    value={value}
                    amountItems={amountItems}
                    title={<h3 className={commonStyles.text}>Amount</h3>}
                    validationProps={validationAmountProps}
                    isOtherDonationAmountAvailable={campaign.isOtherDonationAmountAvailable}
                  />
                )}
                name={'amount'}
                control={control}
                rules={{
                  validate: validateField,
                  required: getRequiredValidationRule('amount', false, true),
                }}
              />
            </Grid>
            {donationType === DonationType.Recurring && (
              <Grid {...defaultGridItemProps}>
                <Grid {...defaultGridContainerProps}>
                  <Grid {...defaultGridItemProps}>
                    <h3 className={commonStyles.text}>Frequency</h3>
                  </Grid>
                  {recurringPeriodButtonConfig.map((value) => (
                    <Grid {...defaultGridItemProps} sm={3} key={`recurring-button-${value}`}>
                      <Button
                        className={classNames({ [commonStyles.dangerButtonOutlined]: !!recurringPeriodError })}
                        fullWidth={true}
                        color={'primary'}
                        variant={recurrentPeriod === value ? 'contained' : 'outlined'}
                        onClick={handleRecurringPeriodChange(value)}
                      >
                        {value}
                      </Button>
                    </Grid>
                  ))}
                  <FormHelperText error={true} className={pageStyles.helperText}>
                    {recurringPeriodError}
                  </FormHelperText>
                </Grid>
              </Grid>
            )}
            {!!processingFeePercent && (
              <Grid {...defaultGridItemProps}>
                <FormControlLabel
                  label={'I choose to cover the transaction fee for this donation.'}
                  classes={{
                    label: pageStyles.boldLabel,
                  }}
                  control={
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <Checkbox color={'primary'} onChange={handleChange(onChange)} checked={value} />
                      )}
                      control={control}
                      name={'payProcessingFee'}
                    />
                  }
                />
              </Grid>
            )}
          </Grid>
        ) : (
          <p>No donation selected</p>
        )}
      </Card>
    </>
  );
};
export default DonationPriceSection;
