import { Checkbox, ListItemText, MenuItem, TextField } from '@mui/material';
import { Alert } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormProps, getRequiredValidationRule, getValidationProps, multipleSelectProps } from 'util/Form';
import useRequest from 'hooks/useRequest';
import { getInputLoadingProps } from 'util/Layout';
import SelectOption from 'store/types/SelectOption';
import EventRegistrationService from 'services/api/EventRegistrationService';

import commonStyles from 'styles/common.module.scss';

interface CreditTypeFormItemProps extends FormProps {
  multiple?: boolean;
}

const CreditTypeFormItem: React.FunctionComponent<CreditTypeFormItemProps> = ({
  fieldName = '',
  multiple = false,
  required = true,
}) => {
  const { data, loading, error } = useRequest<SelectOption[]>(EventRegistrationService.getCreditCategories);
  const [options, setOptions] = useState<SelectOption[]>([]);
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const value = useWatch({ control, name: fieldName });

  useEffect(() => {
    if (data?.length) {
      setOptions(data);
    }
  }, [data, setValue]);

  return error ? (
    <Alert severity={'error'} className={commonStyles.alert}>
      {error}
    </Alert>
  ) : (
    <Controller
      render={({ field }) => (
        <TextField
          {...field}
          {...getValidationProps(fieldName, errors)}
          {...multipleSelectProps(options, 'Select Credit Types')}
          InputProps={getInputLoadingProps(loading)}
          select={true}
          disabled={loading}
          required={required}
          label={!multiple ? 'Credit Type' : undefined}
        >
          {options.map(({ id, name }: SelectOption) => (
            <MenuItem value={id} key={`creditType-${id}`}>
              {!multiple ? (
                name
              ) : (
                <>
                  <Checkbox checked={value.includes(id)} color={'primary'} size={'small'} />
                  <ListItemText primary={name} />
                </>
              )}
            </MenuItem>
          ))}
        </TextField>
      )}
      name={fieldName}
      control={control}
      rules={{ required: getRequiredValidationRule('credit type', true, required) }}
    />
  );
};
export default CreditTypeFormItem;
