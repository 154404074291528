import { Checkbox, FormControlLabel, Grid, MenuItem, TextField } from '@mui/material';
import classNames from 'classnames';
import EmailFormItem from 'components/profile/EmailFormItem';
import React, { ChangeEvent, useCallback, useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import DonationActionType from 'store/enums/DonationActionType';
import { DonationInfoFormValues } from 'store/types/FormValues';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { getDonationActionTypeLabel } from 'util/Format';
import Card from 'components/shared/Card';
import DonationMessageFormItem from './DonationMessageFormItem';
import DonationNameFormItem from './DonationNameFormItem';
import { getGiftDonationFormValues } from 'util/Form';

import commonStyles from 'styles/common.module.scss';
import pageStyles from '../DonationPaymentPageView.module.scss';

const actionSelectOptionConfig: DonationActionType[] = [
  DonationActionType.Honor,
  DonationActionType.Memory,
  DonationActionType.Support,
];

const DonationDetailsSection: React.FunctionComponent = () => {
  const { control, reset, getValues, setValue } = useFormContext<DonationInfoFormValues>();
  const [hasAction, hasEmail, hasMessage, isAnonymousGift] = useWatch({
    control,
    name: ['hasAction', 'hasEmail', 'hasMessage', 'isAnonymousGift'],
  });

  useEffect(() => {
    //workaround to trigger required gift fields validation without showing error
    if (hasAction || hasMessage || hasEmail) {
      reset({ ...getValues() });
    }
    if (!hasMessage) {
      setValue('message', '');
    } else if (!hasAction) {
      reset({ ...getValues(), ...getGiftDonationFormValues() });
    } else if (!hasEmail) {
      reset({
        ...getValues(),
        hasEmail: false,
        hasMessage: false,
        isAnonymousGift: false,
        message: '',
        notifyEmail: '',
      });
    }
  }, [hasAction, hasMessage, hasEmail, isAnonymousGift, setValue, reset, getValues]);

  const handleChange = useCallback((onChange) => (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.checked), []);

  return (
    <Card contentClassName={pageStyles.cardContent}>
      <Grid {...defaultGridContainerProps}>
        <Grid {...defaultGridItemProps}>
          <FormControlLabel
            label={'This gift is in honor, memory, or support of someone.'}
            control={
              <Controller
                render={({ field: { onChange, value } }) => (
                  <Checkbox color={'primary'} onChange={handleChange(onChange)} checked={value} />
                )}
                control={control}
                name={'hasAction'}
              />
            }
          />
        </Grid>
        <Grid {...defaultGridItemProps} className={classNames({ [commonStyles.hidden]: !hasAction })}>
          <Grid {...defaultGridContainerProps}>
            <Grid {...defaultGridItemProps}>
              <Grid {...defaultGridContainerProps}>
                <Grid {...defaultGridItemProps} sm={5}>
                  <Controller
                    render={({ field }) => (
                      <TextField {...field} select={true} disabled={!hasAction}>
                        {actionSelectOptionConfig.map((value: DonationActionType) => (
                          <MenuItem value={value} key={`action-${value}`}>
                            {getDonationActionTypeLabel(value)}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    name={'actionType'}
                    defaultValue={DonationActionType.Honor}
                    control={control}
                  />
                </Grid>
                <Grid {...defaultGridItemProps} sm={7}>
                  <DonationNameFormItem optional={!hasAction} />
                </Grid>
              </Grid>
            </Grid>
            <Grid {...defaultGridItemProps}>
              <FormControlLabel
                label={'Please notify the following person of my gift:'}
                control={
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <Checkbox color={'primary'} onChange={handleChange(onChange)} checked={value} />
                    )}
                    control={control}
                    name={'hasEmail'}
                  />
                }
              />
            </Grid>
            <Grid {...defaultGridItemProps}>
              <EmailFormItem disabled={!hasEmail} optional={!hasEmail} fieldName={'notifyEmail'} />
            </Grid>
            <Grid {...defaultGridItemProps} className={classNames({ [commonStyles.hidden]: !hasEmail })}>
              <Grid {...defaultGridContainerProps}>
                <Grid {...defaultGridItemProps}>
                  <FormControlLabel
                    label={'Include a custom message:'}
                    control={
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <Checkbox color={'primary'} onChange={handleChange(onChange)} checked={value} />
                        )}
                        control={control}
                        name={'hasMessage'}
                      />
                    }
                  />
                </Grid>
                <Grid {...defaultGridItemProps}>
                  <DonationMessageFormItem optional={!hasMessage} disabled={!hasMessage} />
                </Grid>
                <Grid {...defaultGridItemProps} className={classNames({ [commonStyles.hidden]: !hasEmail })}>
                  <FormControlLabel
                    label={'Please make my gift anonymous.'}
                    control={
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <Checkbox color={'primary'} onChange={handleChange(onChange)} checked={value} />
                        )}
                        control={control}
                        name={'isAnonymousGift'}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
export default DonationDetailsSection;
