import classNames from 'classnames';
import React, { useCallback } from 'react';
import { DropzoneArea } from 'mui-file-dropzone';
import { Controller, useFormContext } from 'react-hook-form';
import { FormHelperText } from '@mui/material';

import styles from './UploadImageFormItem.module.scss';

interface UploadImageFormItem {
  fieldName: string;
  label: string;
  helperText?: string;
}

const UploadImageFormItem: React.FunctionComponent<UploadImageFormItem> = ({ fieldName, label, helperText }) => {
  const { control, setValue } = useFormContext();

  const handleDelete = useCallback(() => {
    setValue(fieldName, undefined);
  }, [fieldName, setValue]);

  return (
    <Controller
      render={({ field: { onChange, value } }) => (
        <>
          <DropzoneArea
            acceptedFiles={['.jpg', '.png', '.jpeg']}
            maxFileSize={2000000}
            filesLimit={1}
            fileObjects={value}
            onChange={onChange}
            onDelete={handleDelete}
            showAlerts={['error']}
            clearOnUnmount={true}
            showPreviews={true}
            showPreviewsInDropzone={false}
            showFileNamesInPreview={true}
            useChipsForPreview={false}
            dropzoneText={label}
            classes={{
              root: classNames(styles.dropzone),
              textContainer: styles.dropzoneTextContainer,
              text: styles.dropzoneText,
              icon: styles.dropzoneIcon,
            }}
            alertSnackbarProps={{
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
            }}
          />
          {helperText && <FormHelperText className={styles.helperText}>{helperText}</FormHelperText>}
        </>
      )}
      name={fieldName}
      control={control}
    />
  );
};
export default UploadImageFormItem;
