import React, { useCallback, useContext, useMemo } from 'react';
import { Tab, Tabs } from '@mui/material';
import { useHistory, useLocation } from 'react-router';
import { groupPaymentRoutes } from 'store/configs/Routes';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import Spinner from 'components/shared/Spinner';
import { UserContext } from 'components/UserGuard';
import MakeGroupPaymentPageView from 'components/payments/MakeGroupPaymentPageView';
import { getActiveTabRoute, getActiveTabValue } from 'util/Route';
import OrderHistoryPageView from 'components/payments/OrderHistoryPageView';
import PaymentService from 'services/api/PaymentService';
import { OrderPayment } from 'store/types/Payment';
import useRequest from 'hooks/useRequest';
import PaymentUser from 'store/types/PaymentUser';
import { getUserFullName } from 'util/Format';
import { ConfigContext } from 'components/ConfigGuard';
import SiteModule from 'store/enums/SiteModule';
import { getPageTitle } from 'util/Layout';
import ManageAccessSettingsPageView from 'components/payments/ManageAccessSettingsPageView';
import { ParametersContext } from 'components/ParametersGuard';

import commonStyles from 'styles/common.module.scss';

const GroupPaymentsPage: React.FunctionComponent = () => {
  const location = useLocation();
  const history = useHistory();
  const {
    groupPayments: { billingHistoryTabHeader },
  } = useContext(ParametersContext);
  const { isNccerTheme, sidebar } = useContext(ConfigContext);
  const { userLoading = false } = useContext(UserContext);
  const ordersRequest = useRequest<OrderPayment[]>(PaymentService.getGroupPaymentOrdersList);
  const usersRequest = useRequest<PaymentUser[]>(PaymentService.getGroupPaymentUsers);
  const activeTab: string = useMemo(
    () => getActiveTabValue(location.pathname, groupPaymentRoutes),
    [location.pathname]
  );
  const orderHistoryLoading: boolean = useMemo(
    () => ordersRequest.loading || usersRequest.loading,
    [ordersRequest.loading, usersRequest.loading]
  );

  const orderHistoryData: OrderPayment[] = useMemo(
    () =>
      ordersRequest.data && ordersRequest.data.length
        ? ordersRequest.data.map((order: OrderPayment) => {
            let result: OrderPayment = { ...order };

            if (order.ownerId) {
              const foundUser: PaymentUser | undefined = (usersRequest.data || []).find(
                ({ profileId }) => profileId === order.ownerId
              );

              if (foundUser) {
                result = { ...result, ownerName: getUserFullName(foundUser) };
              }
            }
            return result;
          })
        : [],
    [ordersRequest.data, usersRequest.data]
  );

  const handleTabChange = useCallback(
    (_: any, value = '1') => {
      history.replace(getActiveTabRoute(value, groupPaymentRoutes));
    },
    [history]
  );

  return (
    <>
      <h1 className={commonStyles.pageTitle}>
        {getPageTitle(sidebar, SiteModule.GroupPayments) || 'Group Billing & Invoices'}
      </h1>
      <TabContext value={activeTab}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor={'primary'}
          textColor={'primary'}
          variant={'scrollable'}
        >
          <Tab value={'1'} label={'Billing History'} className={commonStyles.tab} />
          <Tab value={'2'} label={'Pay Invoices'} className={commonStyles.tab} />
          <Tab value={'3'} label={'Manage Access'} className={commonStyles.tab} />
        </Tabs>
        <TabPanel className={commonStyles.tabPanel} value={'1'}>
          <Spinner loading={userLoading} transparent={false}>
            {billingHistoryTabHeader && <p dangerouslySetInnerHTML={{ __html: billingHistoryTabHeader }} />}
            <OrderHistoryPageView
              {...ordersRequest}
              loading={orderHistoryLoading}
              data={orderHistoryData}
              ownerColumnEnabled={true}
              companyColumnEnabled={isNccerTheme}
              openOrdersEnabled={true}
            />
          </Spinner>
        </TabPanel>
        <TabPanel value={'2'} className={commonStyles.tabPanel}>
          <Spinner loading={userLoading} transparent={false}>
            <MakeGroupPaymentPageView {...usersRequest} />
          </Spinner>
        </TabPanel>
        <TabPanel value={'3'} className={commonStyles.tabPanel}>
          <Spinner loading={userLoading} transparent={false}>
            <ManageAccessSettingsPageView />
          </Spinner>
        </TabPanel>
      </TabContext>
    </>
  );
};
export default GroupPaymentsPage;
