import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import CommitteeDetailsCard from 'components/communities/CommitteeDetailsCard';
import { Community, JoinedCommunity } from 'store/types/Community';
import { FormControl, Grid, MenuItem, Tab, Tabs, TextField } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import CommunityListItem from 'components/communities/CommunityListItem';
import Card from 'components/shared/Card';
import { preselectedCommunityStatePropName } from 'util/Route';
import CommunityMemberStatus from 'store/enums/CommunityMemberStatus';
import { ParametersContext } from 'components/ParametersGuard';
import CommunityMember from 'store/types/CommunityMember';

import commonStyles from 'styles/common.module.scss';
import styles from './CommitteesPageView.module.scss';

interface CommitteesPageViewProps {
  data: Community[];
}

const getUserCommittees = (list: Community[]): [JoinedCommunity[], Community[], Community[]] => {
  const activeCommittees: JoinedCommunity[] = [];
  const pastCommittees: Community[] = [];
  const pendingCommittees: Community[] = [];

  list.forEach((committee: Community) => {
    const pastMemberHistory: CommunityMember[] = [];
    const pendingMemberHistory: CommunityMember[] = [];

    committee.memberHistory?.forEach((member) => {
      const { status } = member;

      switch (status) {
        case CommunityMemberStatus.Approved:
          activeCommittees.push({ ...committee, member, memberHistory: [member] });
          break;
        case CommunityMemberStatus.Inactive:
          pastMemberHistory.push(member);
          break;
        case CommunityMemberStatus.Pending:
        case CommunityMemberStatus.Rejected:
          pendingMemberHistory.push(member);
          break;
      }
    });

    pastMemberHistory.length && pastCommittees.push({ ...committee, memberHistory: pastMemberHistory });
    pendingMemberHistory.length && pendingCommittees.push({ ...committee, memberHistory: pendingMemberHistory });
  });

  return [activeCommittees, pendingCommittees, pastCommittees];
};

const emptySection = (
  <div className={styles.noData}>
    <span>{'No committees to display'}</span>
  </div>
);

const CommitteesPageView: React.FunctionComponent<CommitteesPageViewProps> = ({ data }) => {
  const history = useHistory();
  const { state, pathname } = useLocation<{ [preselectedCommunityStatePropName]?: string }>();
  const [activeTab, setActiveTab] = useState<string>('1');
  const [selectedId, setSelectedId] = useState<string>('');
  const {
    committees: { myCommitteesTabHeader, pendingCommitteesTabHeader, pastCommitteesTabHeader },
  } = useContext(ParametersContext);
  const [activeCommittees, pendingCommittees, pastCommittees] = useMemo(() => getUserCommittees(data), [data]);
  const selectedCommittee: JoinedCommunity | undefined = useMemo(
    () => activeCommittees.find(({ id }) => id === selectedId),
    [selectedId, activeCommittees]
  );

  useEffect(() => {
    if (state && state[preselectedCommunityStatePropName]) {
      setSelectedId(state[preselectedCommunityStatePropName] || '');
      history.replace({ pathname, state: null });
    } else if (!selectedId && activeCommittees.length) {
      setSelectedId(activeCommittees[0].id);
    }
  }, [selectedId, activeCommittees, state, pathname, history]);

  const handleTabChange = useCallback((_: any, value = '1') => {
    setActiveTab(value);
  }, []);

  const handleCommitteeSelected = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSelectedId(`${event.target.value}`);
  }, []);

  const committeeSelect: React.ReactNode = (
    <>
      <h3 className={styles.selectTitle}>View Committee Page</h3>
      <FormControl>
        <TextField select={true} value={selectedId} onChange={handleCommitteeSelected}>
          {activeCommittees.map(({ id, name }) => (
            <MenuItem value={id} key={`select-committee-${name}`}>
              {name}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    </>
  );

  return (
    <>
      <Grid {...defaultGridItemProps}>
        <Card contentClassName={styles.content}>
          <TabContext value={activeTab}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              indicatorColor={'primary'}
              textColor={'primary'}
              variant={'scrollable'}
            >
              <Tab value={'1'} label={'My Committees'} className={commonStyles.tab} />
              <Tab value={'2'} label={'Pending Committees'} className={commonStyles.tab} />
              <Tab value={'3'} label={'Past Committees'} className={commonStyles.tab} />
            </Tabs>
            <TabPanel value={'1'} className={classNames(commonStyles.tabPanel, styles.container)}>
              {myCommitteesTabHeader && <p dangerouslySetInnerHTML={{ __html: myCommitteesTabHeader }} />}
              <Grid {...defaultGridContainerProps}>
                {activeCommittees.length ? (
                  <>
                    {activeCommittees.map((item: Community) => (
                      <Grid {...defaultGridItemProps} key={item.id}>
                        <CommunityListItem item={item} active={true} />
                      </Grid>
                    ))}
                    <Grid {...defaultGridItemProps}>
                      <Grid {...defaultGridContainerProps}>
                        <Grid {...defaultGridItemProps} md={5}>
                          {committeeSelect}
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  emptySection
                )}
              </Grid>
            </TabPanel>
            <TabPanel value={'2'} className={classNames(commonStyles.tabPanel, styles.container)}>
              {pendingCommitteesTabHeader && <p dangerouslySetInnerHTML={{ __html: pendingCommitteesTabHeader }} />}
              <Grid {...defaultGridContainerProps}>
                {pendingCommittees.length
                  ? pendingCommittees.map((item: Community) => (
                      <Grid {...defaultGridItemProps} key={item.id}>
                        <CommunityListItem item={item} showDateRequestedField={true} />
                      </Grid>
                    ))
                  : emptySection}
              </Grid>
            </TabPanel>
            <TabPanel value={'3'} className={classNames(commonStyles.tabPanel, styles.container)}>
              {pastCommitteesTabHeader && <p dangerouslySetInnerHTML={{ __html: pastCommitteesTabHeader }} />}
              <Grid {...defaultGridContainerProps}>
                {pastCommittees.length
                  ? pastCommittees.map((item: Community) => (
                      <Grid {...defaultGridItemProps} key={item.id}>
                        <CommunityListItem item={item} />
                      </Grid>
                    ))
                  : emptySection}
              </Grid>
            </TabPanel>
          </TabContext>
        </Card>
      </Grid>
      {selectedCommittee && (
        <Grid {...defaultGridItemProps} className={classNames({ [commonStyles.hidden]: activeTab !== '1' })}>
          <CommitteeDetailsCard data={selectedCommittee} />
        </Grid>
      )}
    </>
  );
};
export default CommitteesPageView;
