import { Button } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import React, { useCallback, useContext, useState } from 'react';
import { MembershipContext } from 'components/MembershipGuard';
import { UserContext } from 'components/UserGuard';
import Spinner from 'components/shared/Spinner';
import { CurrentMembership } from 'store/types/Membership';
import { CurrentUser } from 'store/types/User';
import { isJoinMembershipPage, isReJoinMembershipPage, isRenewMembershipPage } from 'util/Membership';
import NotFoundPage from 'components/layout/NotFoundPage';
import CreateMembershipPageView from 'components/subscriptions/CreateMembershipPageView';
import RenewMembershipPageView from 'components/subscriptions/RenewMembershipPageView';
import Payment from 'store/types/Payment';
import PaymentDetailsModal from 'components/payments/PaymentDetailsModal';
import { getFormattedPayment } from 'util/Payment';
import { getHashRouteUrl } from 'util/Route';
import routes from 'store/configs/Routes';

import commonStyles from 'styles/common.module.scss';
import styles from './EnrollMembershipPage.module.scss';

const EnrollMembershipPage: React.FunctionComponent = () => {
  const currentMembership: CurrentMembership = useContext(MembershipContext);
  const currentUser: CurrentUser = useContext(UserContext);
  const [payment, setPayment] = useState<Payment | undefined>(undefined);
  const isRenewPage: boolean = isRenewMembershipPage(currentMembership);
  const isCreatePage: boolean = isReJoinMembershipPage(currentMembership) || isJoinMembershipPage(currentMembership);

  const handlePaymentCompleted = useCallback((newPayment: Payment) => {
    setPayment(getFormattedPayment(newPayment));
  }, []);

  return (
    <>
      {currentMembership.membershipLoading ? (
        <Skeleton width={300} height={40} variant={'text'} />
      ) : isRenewPage || isCreatePage ? (
        <h1 className={commonStyles.pageTitle}>{isRenewPage ? 'Membership Renewal' : 'Become a Member'}</h1>
      ) : null}
      <Spinner loading={currentUser.userLoading || currentMembership.membershipLoading} transparent={false}>
        {isRenewPage ? (
          <RenewMembershipPageView onPaymentCompleted={handlePaymentCompleted} />
        ) : isCreatePage ? (
          <CreateMembershipPageView onPaymentCompleted={handlePaymentCompleted} />
        ) : (
          <NotFoundPage />
        )}
      </Spinner>
      {!!payment && (
        <PaymentDetailsModal
          open={!!payment}
          title={isCreatePage ? 'Payment Confirmed. Welcome to MemberPlex!' : 'Payment Confirmed'}
          description={
            <p>
              {
                'Thank you for being a valued member. Your payment has been successfully processed. A confirmation email has been sent to '
              }
              <span className={styles.email}>{currentUser.email}</span>
            </p>
          }
          payment={payment}
          actions={
            <Button color={'secondary'} variant={'contained'} href={getHashRouteUrl(routes.home)}>
              {'Go to Dashboard'}
            </Button>
          }
        />
      )}
    </>
  );
};
export default EnrollMembershipPage;
