import { Button, ButtonGroup, Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import classNames from 'classnames';
import PaymentCardImages from 'components/payments/PaymentCardImages';
import PaymentMethodForm from 'components/payments/PaymentMethodForm';
import Modal from 'components/shared/Modal';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, SyntheticEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PaymentMethodService from 'services/api/PaymentMethodService';
import PaymentMethodType from 'store/enums/PaymentMethodType';
import CollectJSResponse from 'store/types/CollectJSResponse';
import { ModalProps } from 'store/types/ComponentProps';
import { AchPaymentMethodDetails, CardPaymentMethodDetails, PaymentMethodView } from 'store/types/PaymentMethod';
import { NewPaymentMethodData } from 'store/types/PaymentMethodsData';
import { defaultFormProps, getRequiredValidationRule, getValidationProps } from 'util/Form';
import {
  defaultGridContainerProps,
  defaultGridItemProps,
  defaultSnackbarErrorProps,
  getButtonLoadingProps,
} from 'util/Layout';
import { getAchPaymentData, getCardPaymentData } from 'util/Payment';
import { ConfigContext } from 'components/ConfigGuard';

import commonStyles from 'styles/common.module.scss';
import styles from './EditPaymentMethodModal.module.scss';

interface EditPaymentMethodModalProps extends ModalProps {
  data?: PaymentMethodView;
  onSubmit: () => void;
  onDelete?: () => void;
  deleteButtonLoading?: boolean;
  makeDefaultPreselected?: boolean;
}

interface FormValues {
  name: string;
  isDefault: boolean;
}

const defaultValues: FormValues = { name: '', isDefault: false };

const getDetailsForm = ({ type, details }: PaymentMethodView): React.ReactNode => {
  const cardDetails = details as CardPaymentMethodDetails;
  const achDetails = details as AchPaymentMethodDetails;

  return details ? (
    type === PaymentMethodType.Card ? (
      <>
        <Grid {...defaultGridItemProps}>
          <TextField disabled={true} label={'Card Number'} value={cardDetails.cardId || 'XXXX XXXX XXXX XXXX'} />
        </Grid>
        <Grid {...defaultGridItemProps} sm={8}>
          <TextField disabled={true} label={'Expiration Date'} value={cardDetails.cardExpDate || 'XX/XXXX'} />
        </Grid>
        <Grid {...defaultGridItemProps} sm={4}>
          <TextField disabled={true} label={'CVV'} value={'XXX'} />
        </Grid>
      </>
    ) : (
      <>
        <Grid {...defaultGridItemProps}>
          <TextField disabled={true} label={'Name on Account'} value={achDetails.accountName || ''} />
        </Grid>
        <Grid {...defaultGridItemProps}>
          <TextField disabled={true} label={'Routing Code'} value={achDetails.routingCode || 'XXXXXXXXX'} />
        </Grid>
        <Grid {...defaultGridItemProps}>
          <TextField disabled={true} label={'Account Number'} value={achDetails.accountNumber || 'XXXXXXXXXX'} />
        </Grid>
      </>
    )
  ) : null;
};

const EditPaymentMethodModal: React.FunctionComponent<EditPaymentMethodModalProps> = ({
  data,
  open,
  onClose,
  onSubmit,
  onDelete,
  makeDefaultPreselected = false,
  deleteButtonLoading = false,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isNccerTheme, isGamsdTheme, isCompassTheme } = useContext(ConfigContext);
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = useForm<FormValues>({
    ...defaultFormProps,
    defaultValues,
  });
  const [paymentMethodType, setPaymentMethodType] = useState<PaymentMethodType>(PaymentMethodType.Card);
  const [cardValid, setCardValid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const isCardTypeSelected = useMemo(() => paymentMethodType === PaymentMethodType.Card, [paymentMethodType]);
  const submitButtonDisabled: boolean = useMemo(() => !isValid || !cardValid || loading, [isValid, cardValid, loading]);
  const makeDefaultDisabled: boolean = useMemo(
    () => makeDefaultPreselected || !!(data && data.isDefault),
    [makeDefaultPreselected, data]
  );
  const achMethodsEnabled = useMemo(
    () => !isNccerTheme && !isGamsdTheme && !isCompassTheme,
    [isNccerTheme, isGamsdTheme, isCompassTheme]
  );

  useEffect(() => {
    setPaymentMethodType(data ? data.type : PaymentMethodType.Card);
    setCardValid(!!data);
    reset({ name: data ? data.name : '', isDefault: data ? data.isDefault : makeDefaultPreselected });
  }, [data, reset, makeDefaultPreselected]);

  const handleUpdateMethod = useCallback(
    ({ name, isDefault }: FormValues, id: string) => {
      const paymentMethodData: NewPaymentMethodData = { name, isDefault };
      PaymentMethodService.updatePaymentMethod(id, paymentMethodData)
        .then(() => {
          setLoading(false);
          enqueueSnackbar('Payment method successfully updated', { variant: 'success' });
          onSubmit();
        })
        .catch((error: string) => {
          setLoading(false);
          enqueueSnackbar(error, defaultSnackbarErrorProps);
        });
    },
    [enqueueSnackbar, onSubmit]
  );

  const handleFormSubmit = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      setLoading(true);
      if (!data) {
        (window as any).CollectJS.startPaymentRequest();
      } else {
        handleSubmit(({ name, isDefault }: FormValues) => handleUpdateMethod({ name, isDefault }, data.id))();
      }
    },
    [data, handleSubmit, handleUpdateMethod]
  );

  const handleAddMethod = useCallback(
    (response: CollectJSResponse, { name, isDefault }: FormValues) => {
      let paymentMethodData: NewPaymentMethodData = { name, isDefault };

      if (paymentMethodType === PaymentMethodType.Card) {
        paymentMethodData = { ...paymentMethodData, creditCard: getCardPaymentData(response) };
      } else if (paymentMethodType === PaymentMethodType.Ach) {
        paymentMethodData = {
          ...paymentMethodData,
          ach: getAchPaymentData(response),
        };
      }

      PaymentMethodService.addPaymentMethod(paymentMethodData)
        .then(() => {
          setLoading(false);
          enqueueSnackbar('Payment method successfully added', { variant: 'success' });
          onSubmit();
        })
        .catch((error: string) => {
          setLoading(false);
          enqueueSnackbar(error, defaultSnackbarErrorProps);
        });
    },
    [enqueueSnackbar, onSubmit, paymentMethodType]
  );

  const handlePaymentComplete = useCallback(
    (response: CollectJSResponse) => {
      handleSubmit(({ name, isDefault }: FormValues) => handleAddMethod(response, { name, isDefault }))();
    },
    [handleAddMethod, handleSubmit]
  );

  const handleCardValidChange = useCallback((valid: boolean) => {
    setCardValid(valid);
  }, []);

  const handleMakeDefaultChange = useCallback(
    (onChange) => (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.checked),
    []
  );

  const getPaymentMethodTypeButton = useCallback(
    (type: PaymentMethodType, label: string): React.ReactNode => {
      const handleTypeSelected = () => {
        if (type !== paymentMethodType) {
          (window as any).CollectJS.clearInputs();
          setPaymentMethodType(type);
        }
      };

      return (
        <Button
          fullWidth={true}
          color={'primary'}
          variant={paymentMethodType === type ? 'contained' : 'outlined'}
          onClick={handleTypeSelected}
        >
          {label}
        </Button>
      );
    },
    [paymentMethodType]
  );

  return (
    <Modal
      loading={loading}
      open={open}
      title={data ? 'Edit Saved Payment Method' : 'Add New Payment Method'}
      onClose={onClose}
      actions={
        <>
          <Button color={'secondary'} variant={'outlined'} onClick={onClose}>
            Cancel
          </Button>
          {data && (
            <Button
              onClick={onDelete}
              disabled={loading || (data && data.isDefault) || deleteButtonLoading}
              variant={'outlined'}
              {...getButtonLoadingProps(deleteButtonLoading)}
              className={classNames(commonStyles.dangerButtonOutlined, styles.deleteButton)}
            >
              Delete
            </Button>
          )}
          <Button
            color={'secondary'}
            variant={'contained'}
            type={'submit'}
            onClick={handleFormSubmit}
            disabled={submitButtonDisabled}
          >
            Save
          </Button>
        </>
      }
    >
      <Grid {...defaultGridContainerProps}>
        {!data && achMethodsEnabled && (
          <Grid {...defaultGridItemProps}>
            <ButtonGroup color={'primary'} fullWidth={true}>
              {getPaymentMethodTypeButton(PaymentMethodType.Card, 'Credit Card')}
              {getPaymentMethodTypeButton(PaymentMethodType.Ach, 'ACH')}
            </ButtonGroup>
          </Grid>
        )}
        {isCardTypeSelected && <PaymentCardImages />}
        <Grid {...defaultGridItemProps}>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                {...getValidationProps('name', errors)}
                label={`${isCardTypeSelected ? 'Card' : 'Method'} Nickname`}
                required={true}
                placeholder={'My Card Default Save Card - Business'}
              />
            )}
            name={'name'}
            control={control}
            rules={{
              required: getRequiredValidationRule('payment method name'),
            }}
          />
        </Grid>
        {data ? (
          getDetailsForm(data)
        ) : (
          <Grid {...defaultGridItemProps}>
            <PaymentMethodForm
              onPaymentComplete={handlePaymentComplete}
              onMethodValidChange={handleCardValidChange}
              type={paymentMethodType}
            />
          </Grid>
        )}
        <Grid {...defaultGridItemProps}>
          <FormControlLabel
            label={data && data.isDefault ? 'Default Payment Method' : 'Make This My Default Payment Method'}
            disabled={makeDefaultDisabled}
            control={
              <Controller
                render={({ field: { onChange, value } }) => (
                  <Checkbox
                    color={'primary'}
                    onChange={handleMakeDefaultChange(onChange)}
                    checked={value}
                    disabled={makeDefaultDisabled}
                  />
                )}
                control={control}
                name={'isDefault'}
              />
            }
          />
        </Grid>
      </Grid>
    </Modal>
  );
};
export default EditPaymentMethodModal;
