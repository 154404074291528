import { Button, Checkbox, Container, Grid, ListItemText, MenuItem, TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { defaultGridContainerProps, defaultGridItemProps, getButtonLoadingProps } from 'util/Layout';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { multipleSelectProps } from 'util/Form';
import SelectOption from 'store/types/SelectOption';
import { MemberDirectorySearchFilter } from 'store/types/MemberDirectory';
import { PreferenceQuestionView } from 'store/types/PreferenceQuestion';
import { getPreferencesQuestionsView } from 'util/Preferences';
import MemberDirectoryCustomerType from 'store/enums/MemberDirectoryCustomerType';

import styles from './MemberDirectorySearchFilterBar.module.scss';
import IndustriesFormItem from './IndustriesFormItem';
import { ConfigContext } from 'components/ConfigGuard';

interface MemberDirectorySearchFilterBarProps {
  data: MemberDirectorySearchFilter[];
  onSubmit: () => void;
  loading?: boolean;
  submitButtonDisabled?: boolean;
}

const MemberDirectorySearchFilterBar: React.FunctionComponent<MemberDirectorySearchFilterBarProps> = ({
  loading,
  data,
  onSubmit,
  submitButtonDisabled,
}) => {
  const {
    modulesSettings: {
      memberDirectory: { enableMarketSegments },
    },
  } = useContext(ConfigContext);
  const { control } = useFormContext();
  const filter: Array<string> = useWatch({ control, name: 'filters' }) || [];
  const type: MemberDirectoryCustomerType = useWatch({ control, name: 'type' });
  const [questions, setQuestions] = useState<PreferenceQuestionView[]>([]);

  useEffect(() => {
    if (data?.length) {
      setQuestions(getPreferencesQuestionsView(data));
    }
  }, [data]);

  return (
    <aside className={styles.filterBar}>
      <Container maxWidth={'lg'}>
        <Grid {...defaultGridContainerProps} alignItems={'center'}>
          <Grid {...defaultGridItemProps} md={'auto'}>
            <Button
              {...getButtonLoadingProps(loading)}
              variant={'contained'}
              color={'primary'}
              size={'large'}
              onClick={onSubmit}
              disabled={submitButtonDisabled}
            >
              {'Apply Filters'}
            </Button>
          </Grid>
          {enableMarketSegments && type === MemberDirectoryCustomerType.Company && (
            <Grid {...defaultGridItemProps} sm={'auto'}>
              <IndustriesFormItem />
            </Grid>
          )}
          {questions.map(({ id, name, options = [] }) => (
            <Grid {...defaultGridItemProps} sm={'auto'} key={id}>
              <Controller
                render={({ field: { value, onChange } }) => (
                  <TextField
                    {...multipleSelectProps(options, `Select ${name}`)}
                    value={value}
                    onChange={onChange}
                    className={styles.input}
                    label={name}
                    size={'medium'}
                    select={true}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {options.map(({ id, name }: SelectOption) => (
                      <MenuItem value={id} key={`location-${id}`}>
                        <Checkbox checked={filter.includes(id)} color={'primary'} size={'small'} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                name={'filters'}
                defaultValue={[]}
                control={control}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </aside>
  );
};
export default MemberDirectorySearchFilterBar;
