import React, { useCallback, useMemo, useState } from 'react';
import Modal from 'components/shared/Modal';
import { Button, Divider, Grid, TextField } from '@mui/material';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import { defaultFormProps, getMaxLengthValidationRule, getRequiredValidationRule, getValidationProps } from 'util/Form';
import { EventSessionView, MainEventView } from 'store/types/events/Event';
import { ModalProps } from 'store/types/ComponentProps';
import EventService from 'services/api/EventService';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';

import commonStyles from 'styles/common.module.scss';
import styles from './AddToWaitListModal.module.scss';

interface AddToWaitListModalProps extends ModalProps {
  data: MainEventView | EventSessionView;
}

interface AddToWaitListModalFormValues {
  reason: string;
  numberOfRegistrants: number;
}

const GOAL_REGEXP = /^[0-9]+(\.[0-9])?([0-9])?$/;

const AddToWaitListModal: React.FunctionComponent<AddToWaitListModalProps> = ({ data, open, onClose }) => {
  const { id, joinWaitListDisclaimer, title } = data;
  const parentEventName = useMemo(() => 'parent' in data && data.parent?.name, [data]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const {
    control,
    formState: { errors, isValid },
    reset,
    handleSubmit,
  } = useForm<AddToWaitListModalFormValues>({
    ...defaultFormProps,
  });

  const handleClose = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  const handleFormSubmit = useCallback(
    ({ reason, numberOfRegistrants }: AddToWaitListModalFormValues) => {
      setLoading(true);
      EventService.addEventToWaitlist(id, numberOfRegistrants, reason)
        .then(() => {
          enqueueSnackbar('You have been successfully added to the waitlist', { variant: 'success' });
          handleClose();
          setLoading(false);
        })
        .catch((errorMessage: string) => {
          setLoading(false);
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        });
    },
    [enqueueSnackbar, handleClose, id]
  );

  return (
    <Modal
      title={'Join Waitlist'}
      open={open}
      onClose={handleClose}
      maxWidth={'sm'}
      loading={loading}
      actions={
        <>
          <Button color={'secondary'} variant={'outlined'} onClick={handleClose} disabled={loading}>
            {'Cancel'}
          </Button>
          <Button
            color={'secondary'}
            variant={'contained'}
            type={'submit'}
            disabled={loading || !isValid}
            onClick={handleSubmit(handleFormSubmit)}
          >
            {'Join Now'}
          </Button>
        </>
      }
    >
      {joinWaitListDisclaimer && (
        <p
          dangerouslySetInnerHTML={{
            __html: joinWaitListDisclaimer,
          }}
        />
      )}
      <h2 className={styles.title}>{`${parentEventName ? `${parentEventName}: ` : ''} ${title}`}</h2>
      <Divider className={styles.divider} />
      <Grid {...defaultGridContainerProps}>
        <Grid {...defaultGridItemProps} md={5}>
          <h5 className={commonStyles.subTitle}>{'Number of Registrants'}</h5>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                {...getValidationProps('numberOfRegistrants', errors)}
                type={'number'}
                required={true}
                placeholder={'0'}
              />
            )}
            name={'numberOfRegistrants'}
            defaultValue={1}
            control={control}
            rules={{
              required: getRequiredValidationRule('valid number'),
              maxLength: getMaxLengthValidationRule(50),
              min: {
                value: 1,
                message: 'The minimum value is 1',
              },
              max: {
                value: 50,
                message: 'The maximum value is 50',
              },
              pattern: {
                value: GOAL_REGEXP,
                message: 'Please input valid number',
              },
            }}
          />
        </Grid>
        <Grid {...defaultGridItemProps}>
          <h5 className={commonStyles.subTitle}>{'Additional Information'}</h5>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                {...getValidationProps('type', errors)}
                label={'Write your answer here...'}
                multiline={true}
                minRows={3}
              />
            )}
            control={control}
            name={'reason'}
            defaultValue={''}
            rules={{
              maxLength: getMaxLengthValidationRule(200),
            }}
          />
          <span className={styles.helperText}>{'200 characters limit'}</span>
        </Grid>
      </Grid>
    </Modal>
  );
};
export default AddToWaitListModal;
