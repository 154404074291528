import { Button, Container, Divider, Grid } from '@mui/material';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Spinner from 'components/shared/Spinner';
import Add from '@mui/icons-material/Add';
import { DonationCampaign } from 'store/types/DonationFund';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import PageCard from 'components/shared/PageCard';
import { getPrice } from 'util/Payment';
import Pagination from '@mui/material/Pagination';
import { getListByPage, getPagesCount } from 'util/Table';
import CreateCampaignModal from 'components/donation/CreateCampaignModal';
import { getDonationStatusLabel } from 'util/Donations';
import { getFormattedDate, STRING_DATE_FORMAT } from 'util/Format';
import { BarChart, TrackChanges, History } from '@mui/icons-material';
import { ParametersContext } from 'components/ParametersGuard';

import commonStyles from 'styles/common.module.scss';
import styles from './PartnerDashBoardPageView.module.scss';

export const ITEMS_PER_PAGE = 10;

interface PartnerDashBoardPageViewProps {
  campaigns: DonationCampaign[];
  loading?: boolean;
  onSelectedCampaignIdChange: (campaignId: string) => void;
  applicationId: string;
  onCreateApplicationSubmit: (applicationId: string) => void;
  refetchCampaigns: () => void;
}

const PartnerDashBoardPageView: React.FunctionComponent<PartnerDashBoardPageViewProps> = ({
  campaigns,
  loading,
  onSelectedCampaignIdChange,
  onCreateApplicationSubmit,
  refetchCampaigns,
  applicationId,
}) => {
  const [createCampaignModalOpen, setCreateCampaignModalOpen] = useState<boolean>(false);
  const {
    partner: { campaignsSectionHeader },
  } = useContext(ParametersContext);
  const [list, setList] = useState<DonationCampaign[]>([]);
  const [initialList, setInitialList] = useState<DonationCampaign[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const pagesCount: number = useMemo(() => getPagesCount(initialList.length, ITEMS_PER_PAGE), [initialList.length]);

  useEffect(() => {
    if (campaigns?.length) {
      setInitialList(campaigns);
      setList(getListByPage(initialList, ITEMS_PER_PAGE, pageNumber));
    }
  }, [campaigns, initialList, pageNumber]);

  const handleButtonClick = useCallback(
    (applicationId: string) => () => {
      onCreateApplicationSubmit(applicationId);
    },
    [onCreateApplicationSubmit]
  );

  const handlePageChange = useCallback(
    (_: any, newPage: number) => {
      setPageNumber(newPage);
      setList(getListByPage(initialList, ITEMS_PER_PAGE, newPage));
    },
    [initialList]
  );

  const handleCampaignModalOpen = useCallback(() => {
    setCreateCampaignModalOpen(true);
  }, []);

  const handleCampaignModalClose = useCallback(() => {
    setCreateCampaignModalOpen(false);
  }, []);

  const handleSelectedCampaignIdChange = useCallback(
    (id: string) => () => {
      onSelectedCampaignIdChange(id);
    },
    [onSelectedCampaignIdChange]
  );

  return (
    <Spinner loading={loading} transparent={false}>
      <Grid {...defaultGridContainerProps}>
        <Grid {...defaultGridItemProps} md={'auto'}>
          <Button
            color={'primary'}
            variant={'contained'}
            startIcon={<Add />}
            className={commonStyles.uppercase}
            onClick={handleCampaignModalOpen}
          >
            {'New Campaign Request'}
          </Button>
        </Grid>
        <Grid {...defaultGridItemProps} md={'auto'}>
          {applicationId && (
            <Button
              color={'secondary'}
              variant={'contained'}
              startIcon={<Add />}
              className={commonStyles.uppercase}
              onClick={handleButtonClick(applicationId)}
            >
              {'New Fund Reconciliation Report'}
            </Button>
          )}
        </Grid>
        <Grid {...defaultGridItemProps} xs={12}>
          {campaignsSectionHeader && <p dangerouslySetInnerHTML={{ __html: campaignsSectionHeader }} />}
          <PageCard title={'Campaigns'}>
            {list.length ? (
              list.map(({ raised, title, id, goal, status, targetDate }: DonationCampaign) => (
                <Grid {...defaultGridContainerProps} key={id} spacing={1}>
                  <Grid {...defaultGridItemProps}>
                    <div className={styles.title}>
                      <span>{title}</span>
                      <span className={styles.status}>{getDonationStatusLabel(status)}</span>
                      <Button
                        variant={'outlined'}
                        size={'small'}
                        color={'primary'}
                        className={styles.actionButton}
                        onClick={handleSelectedCampaignIdChange(id)}
                      >
                        {'View'}
                        <span className={styles.nameLabel}>{'Details'}</span>
                      </Button>
                    </div>
                  </Grid>
                  <Grid {...defaultGridItemProps}>
                    <div className={styles.descriptionRow}>
                      <History className={styles.icon} />
                      <p className={commonStyles.text}>
                        {getFormattedDate(targetDate, STRING_DATE_FORMAT)}
                        <span className={styles.hint}>{'Target Date'}</span>
                      </p>
                    </div>
                  </Grid>
                  <Grid {...defaultGridItemProps}>
                    <div className={styles.descriptionRow}>
                      <TrackChanges className={styles.icon} />
                      <p className={commonStyles.text}>
                        {getPrice(goal)}
                        <span className={styles.hint}>{'Goal'}</span>
                      </p>
                    </div>
                  </Grid>
                  <Grid {...defaultGridItemProps}>
                    <div className={styles.descriptionRow}>
                      <BarChart className={styles.icon} />
                      <p className={commonStyles.text}>
                        {getPrice(raised)}
                        <span className={styles.hint}>{'Raised So Far'}</span>
                      </p>
                    </div>
                  </Grid>
                  <Divider className={styles.divider} />
                </Grid>
              ))
            ) : (
              <p>{'You have no campaigns.'}</p>
            )}
            <Container maxWidth={'xl'} className={styles.sectionContent}>
              <div className={styles.paginationWrapper}>
                <Pagination
                  disabled={loading}
                  showFirstButton={true}
                  showLastButton={true}
                  count={pagesCount}
                  page={pageNumber}
                  onChange={handlePageChange}
                />
              </div>
            </Container>
          </PageCard>
        </Grid>
      </Grid>
      <CreateCampaignModal
        open={createCampaignModalOpen}
        onClose={handleCampaignModalClose}
        donations={campaigns}
        refetch={refetchCampaigns}
      />
    </Spinner>
  );
};
export default PartnerDashBoardPageView;
