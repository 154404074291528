import { Button, ButtonGroup, Checkbox, FormControlLabel, Grid, Link, MenuItem, TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import classNames from 'classnames';
import PaymentCardImages from 'components/payments/PaymentCardImages';
import PaymentMethodForm from 'components/payments/PaymentMethodForm';
import Spinner from 'components/shared/Spinner';
import { UserContext } from 'components/UserGuard';
import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PaymentMethodType from 'store/enums/PaymentMethodType';
import { PaymentFormValues } from 'store/types/FormValues';
import MakePaymentComponentProps from 'store/types/MakePaymentComponentProps';
import PaymentMethod from 'store/types/PaymentMethod';
import { CurrentUser } from 'store/types/User';
import { ValidationProps } from 'util/Form';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { PaymentMethodsContext } from 'components/PaymentMethodsContextWrapper';
import { ConfigContext } from 'components/ConfigGuard';
import { useWindowSize } from 'util/Window';
import { PaymentConfigItem, SiteConfigView, SiteParameters } from 'store/types/SiteConfig';
import { getPaymentMethodStatus } from 'util/Payment';
import PaymentMethodStatus from 'store/enums/PaymentMethodStatus';
import { ParametersContext } from 'components/ParametersGuard';

import commonStyles from 'styles/common.module.scss';
import styles from './PaymentMethodSection.module.scss';

type PaymentMethodSectionProps = Omit<MakePaymentComponentProps, 'summaryList'> & {
  onChange: (values: OnChangeValues) => void;
  saveMethodCheckboxDisabled?: boolean;
  saveMethodCheckboxVisible?: boolean;
  showRequiredError?: boolean;
  paymentConfig: PaymentConfigItem;
};
type OnChangeValues = Partial<
  Pick<PaymentFormValues, 'paymentMethodType' | 'saveMethod' | 'paymentMethodValid' | 'paymentMethodId'>
>;

interface PaymentMethodConfigItem {
  label: string;
  savedMethodsList: PaymentMethod[];
  visible?: boolean;
  hasInput?: boolean;
  hasSaveCheckbox?: boolean;
  poNumberEnabled?: Pick<MakePaymentComponentProps, 'poNumberEnabled'>;
}

type PaymentMethodsConfig = Record<PaymentMethodType, PaymentMethodConfigItem>;

const getValidationError = (showError: boolean): ValidationProps =>
  showError
    ? {
        error: true,
        helperText: 'Please select payment method',
      }
    : {
        error: false,
        helperText: undefined,
      };

const checkPaymentMethodText: React.ReactNode = (
  <>
    <p>
      {
        "Please make your check, money order or cashier's check payable to MemberPlex, and mail it to the following address: "
      }
      <br />
      {'Attn: Accounting Department'}
      <br />
      {'223 W Jackson Blvd., Suite 975'}
      <br />
      {'Chicago, IL 60606'}
    </p>
    <p>{'The payment must be received within the next 14 days. Once processed, your order status will be updated.'}</p>
  </>
);
const postponePaymentMethodText: React.ReactNode = <p>{"Your order will appear on 'Billing & Invoices' page."}</p>;

const getPaymentMethodsConfig = (
  savedMethods: PaymentMethod[] = [],
  { isNccerTheme }: SiteConfigView,
  { payments: { amountLimitCC, amountLimitACH } }: SiteParameters,
  { isAchEnabled, isCreditCardEnabled }: PaymentConfigItem,
  checkPaymentMethodVisible?: boolean,
  postponePaymentMethodVisible?: boolean,
  totalPrice = 0
): PaymentMethodsConfig => {
  let result: PaymentMethodsConfig = {
    [PaymentMethodType.Card]: {
      label: 'Credit Card',
      visible: isCreditCardEnabled && (amountLimitCC ? amountLimitCC > totalPrice : true),
      hasInput: true,
      hasSaveCheckbox: true,
      savedMethodsList: [],
    },
    [PaymentMethodType.Ach]: {
      label: 'ACH',
      visible: isAchEnabled && (amountLimitACH ? amountLimitACH > totalPrice : true),
      hasInput: true,
      hasSaveCheckbox: !isNccerTheme,
      savedMethodsList: [],
    },
    [PaymentMethodType.Check]: {
      label: 'Check',
      visible: checkPaymentMethodVisible,
      savedMethodsList: [],
    },
    [PaymentMethodType.Postpone]: {
      label: 'Bill Me',
      visible: postponePaymentMethodVisible,
      savedMethodsList: [],
    },
  };
  const methods = savedMethods.filter(
    (method: PaymentMethod) => getPaymentMethodStatus(method) !== PaymentMethodStatus.Expired
  );

  if (methods.length) {
    const achSavedMethodsList: PaymentMethod[] = [];
    const cardSavedMethodsList: PaymentMethod[] = [];

    methods.forEach((method) => {
      if (method.type === PaymentMethodType.Card) {
        cardSavedMethodsList.push(method);
      } else if (method.type === PaymentMethodType.Ach && !isNccerTheme) {
        achSavedMethodsList.push(method);
      }
    });
    result = {
      ...result,
      [PaymentMethodType.Card]: { ...result[PaymentMethodType.Card], savedMethodsList: cardSavedMethodsList },
      [PaymentMethodType.Ach]: { ...result[PaymentMethodType.Ach], savedMethodsList: achSavedMethodsList },
    };
  }
  return result;
};

const PaymentMethodSection: React.FunctionComponent<PaymentMethodSectionProps> = ({
  form,
  onChange,
  onPaymentComplete,
  saveMethodCheckboxDisabled = false,
  saveMethodCheckboxVisible = true,
  checkPaymentMethodVisible = false,
  postponePaymentMethodVisible = false,
  autoRenewalVisible = false,
  poNumberEnabled = false,
  showRequiredError = false,
  paymentConfig,
}) => {
  const { isMobile } = useWindowSize();
  const siteConfig = useContext(ConfigContext);
  const { isCreditCardEnabled, isAchEnabled } = paymentConfig;
  const { id = '' }: CurrentUser = useContext(UserContext);
  const {
    autoRenewal,
    saveMethod,
    paymentMethodId,
    paymentMethodType,
    totalPrice = 0,
    poNumber = '',
    credits = 0,
  } = form;
  const { data, loading, error } = useContext(PaymentMethodsContext);
  const [newMethodSelected, setNewMethodSelected] = useState<boolean>(true);
  const siteParameters = useContext(ParametersContext);
  const paymentMethodsConfig: PaymentMethodsConfig = useMemo(
    () =>
      getPaymentMethodsConfig(
        data,
        siteConfig,
        siteParameters,
        paymentConfig,
        checkPaymentMethodVisible,
        postponePaymentMethodVisible,
        totalPrice
      ),
    [
      data,
      siteConfig,
      siteParameters,
      paymentConfig,
      checkPaymentMethodVisible,
      postponePaymentMethodVisible,
      totalPrice,
    ]
  );
  const {
    hasInput: paymentMethodHasInput = false,
    hasSaveCheckbox: paymentMethodHasSaveCheckbox = false,
    savedMethodsList = [],
  }: PaymentMethodConfigItem = useMemo(
    () =>
      paymentMethodType
        ? paymentMethodsConfig[paymentMethodType]
        : {
            label: '',
            savedMethodsList: [],
          },
    [paymentMethodType, paymentMethodsConfig]
  );
  const isCardFormType: boolean = useMemo(() => paymentMethodType === PaymentMethodType.Card, [paymentMethodType]);
  const paymentSectionDisabled: boolean = useMemo(
    () => totalPrice === 0 || totalPrice + credits === credits,
    [totalPrice, credits]
  );
  const isSaveCheckboxDisplayed: boolean = useMemo(
    () => saveMethodCheckboxVisible && paymentMethodHasSaveCheckbox && newMethodSelected,
    [newMethodSelected, paymentMethodHasSaveCheckbox, saveMethodCheckboxVisible]
  );

  useEffect(() => {
    if (
      paymentMethodsConfig &&
      (!paymentMethodType || (paymentMethodType && !paymentMethodsConfig[paymentMethodType].visible))
    ) {
      onChange({
        paymentMethodType: paymentMethodsConfig.CreditCard.visible
          ? PaymentMethodType.Card
          : paymentMethodsConfig.Ach.visible
          ? PaymentMethodType.Ach
          : paymentMethodsConfig.Check.visible
          ? PaymentMethodType.Check
          : paymentMethodsConfig.Postpone.visible
          ? PaymentMethodType.Postpone
          : undefined,
        paymentMethodId: undefined,
        paymentMethodValid: paymentMethodsConfig.Check.visible || paymentMethodsConfig.Postpone.visible,
      });
    }
  }, [onChange, paymentMethodType, paymentMethodsConfig]);

  useEffect(() => {
    setNewMethodSelected(!savedMethodsList.length);
  }, [savedMethodsList]);

  useEffect(() => {
    if (!isSaveCheckboxDisplayed && saveMethod && !saveMethodCheckboxDisabled) {
      onChange({ saveMethod: false });
    }
  }, [onChange, isSaveCheckboxDisplayed, saveMethod, saveMethodCheckboxDisabled]);

  useEffect(() => {
    if (isSaveCheckboxDisplayed && !paymentSectionDisabled && !!autoRenewal) {
      onChange({ saveMethod: true });
    }
  }, [onChange, autoRenewal, paymentSectionDisabled, isSaveCheckboxDisplayed]);

  const handleSavedCardSelected = useCallback(
    (e: ChangeEvent<{ name?: string; value: unknown }>) => {
      onChange({ paymentMethodId: `${e.target.value}`, paymentMethodValid: true });
    },
    [onChange]
  );

  const handlePoNumberChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange({ poNumber: e.target.value });
    },
    [onChange]
  );

  const handleSaveMethodChange = useCallback(
    (_: any, checked: boolean) => {
      onChange({ saveMethod: checked });
    },
    [onChange]
  );

  const handleMethodValidChange = useCallback(
    (valid: boolean) => {
      if (paymentMethodHasInput) {
        onChange({ paymentMethodValid: valid });
      }
    },
    [onChange, paymentMethodHasInput]
  );

  const toggleNewMethodSelected = useCallback(() => {
    setNewMethodSelected((prevState) => {
      if (prevState) {
        (window as any).CollectJS.clearInputs();
      }
      return !prevState;
    });
    onChange({ paymentMethodId: undefined, paymentMethodValid: false });
  }, [onChange]);

  const getPaymentMethodTypeButton = useCallback(
    (type: PaymentMethodType, label: string): React.ReactNode => {
      const handlePaymentMethodTypeSelected = () => {
        if (type !== paymentMethodType) {
          (window as any).CollectJS.clearInputs();
          onChange({
            paymentMethodType: type,
            paymentMethodId: undefined,
            paymentMethodValid: type === PaymentMethodType.Check || type === PaymentMethodType.Postpone,
          });
        }
      };

      return (
        <Button
          key={type}
          fullWidth={true}
          disabled={paymentSectionDisabled}
          color={'primary'}
          variant={paymentMethodType === type ? 'contained' : 'outlined'}
          onClick={handlePaymentMethodTypeSelected}
        >
          {label}
        </Button>
      );
    },
    [paymentSectionDisabled, paymentMethodType, onChange]
  );

  const savedCardsSelect: React.ReactNode = (
    <TextField
      select={true}
      {...getValidationError(showRequiredError)}
      label={'Select from saved payment methods'}
      value={paymentMethodId || ''}
      onChange={handleSavedCardSelected}
      disabled={paymentSectionDisabled}
    >
      {savedMethodsList.map((method: PaymentMethod) => (
        <MenuItem value={method.id} key={`savedCard-${method.id}`}>
          {method.name}
        </MenuItem>
      ))}
    </TextField>
  );

  const toggleCardFormLink: React.ReactNode = (
    <Link
      className={classNames(commonStyles.link, styles.link, {
        [styles.disabled]: paymentSectionDisabled,
      })}
      onClick={toggleNewMethodSelected}
    >
      {newMethodSelected
        ? `Use Previously Saved ${isCardFormType ? 'Card' : 'Method'}`
        : `Use New ${isCardFormType ? 'Card' : 'Method'}`}
    </Link>
  );

  const savePaymentMethodCheckbox: React.ReactNode = (
    <FormControlLabel
      checked={saveMethod}
      control={<Checkbox color={'primary'} onChange={handleSaveMethodChange} disabled={saveMethodCheckboxDisabled} />}
      label={`Save Payment Method ${
        saveMethodCheckboxDisabled && autoRenewalVisible ? '(required for auto-renewal)' : ''
      }`}
    />
  );

  return (
    <Spinner loading={loading} transparent={false}>
      {error && !!id && !loading ? (
        <Alert severity={'error'} className={commonStyles.alert}>
          {error}
        </Alert>
      ) : (
        <Grid {...defaultGridContainerProps}>
          {Object.values(paymentMethodsConfig).some(({ visible }) => visible) ? (
            <>
              {poNumberEnabled && (
                <Grid {...defaultGridItemProps}>
                  <TextField label={'PO Number'} value={poNumber} onChange={handlePoNumberChange} />
                </Grid>
              )}
              <Grid {...defaultGridItemProps}>
                <ButtonGroup color={'primary'} fullWidth={true} orientation={isMobile ? 'vertical' : 'horizontal'}>
                  {Object.keys(paymentMethodsConfig).map((type: string) => {
                    const { visible = false, label } = paymentMethodsConfig[type as PaymentMethodType];

                    return visible ? getPaymentMethodTypeButton(type as PaymentMethodType, label) : null;
                  })}
                </ButtonGroup>
              </Grid>
              <Grid
                {...defaultGridItemProps}
                className={classNames({
                  [commonStyles.hidden]: !paymentMethodHasInput,
                })}
              >
                <Grid {...defaultGridContainerProps}>
                  <Grid
                    {...defaultGridItemProps}
                    className={classNames({
                      [commonStyles.hidden]: newMethodSelected,
                    })}
                  >
                    {savedCardsSelect}
                  </Grid>
                  <PaymentCardImages
                    className={classNames({
                      [commonStyles.hidden]: !(paymentMethodType === PaymentMethodType.Card) || !newMethodSelected,
                    })}
                  />
                  <Grid
                    {...defaultGridItemProps}
                    className={classNames({
                      [commonStyles.hidden]: !newMethodSelected,
                    })}
                  >
                    <PaymentMethodForm
                      disabled={paymentSectionDisabled}
                      onPaymentComplete={onPaymentComplete}
                      onMethodValidChange={handleMethodValidChange}
                      type={paymentMethodType}
                    />
                  </Grid>
                  <Grid
                    {...defaultGridItemProps}
                    className={classNames({ [commonStyles.hidden]: !isSaveCheckboxDisplayed })}
                  >
                    {savePaymentMethodCheckbox}
                  </Grid>
                  <Grid
                    {...defaultGridItemProps}
                    className={classNames({ [commonStyles.hidden]: !savedMethodsList.length })}
                  >
                    {toggleCardFormLink}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                {...defaultGridItemProps}
                className={classNames({ [commonStyles.hidden]: paymentMethodType !== PaymentMethodType.Check })}
              >
                {checkPaymentMethodText}
              </Grid>
              <Grid
                {...defaultGridItemProps}
                className={classNames({ [commonStyles.hidden]: paymentMethodType !== PaymentMethodType.Postpone })}
              >
                <Grid {...defaultGridContainerProps}>
                  <Grid {...defaultGridItemProps}>{postponePaymentMethodText}</Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid {...defaultGridItemProps}>
              <Alert severity={'info'} className={commonStyles.alert}>
                {'Payment options unavailable. '}
                {(isCreditCardEnabled || isAchEnabled) && 'Maximum threshold for this payment has been reached.'}
              </Alert>
            </Grid>
          )}
        </Grid>
      )}
    </Spinner>
  );
};
export default PaymentMethodSection;
