import React from 'react';
import classNames from 'classnames';
import ComponentProps from 'store/types/ComponentProps';
import Card from 'components/shared/Card';

import styles from './Tile.module.scss';

interface TileClasses {
  value?: string;
  label?: string;
}

interface TileProps extends ComponentProps {
  theme?: 'primary' | 'dark-red' | 'dark-grey';
  loading?: boolean;
  value?: React.ReactNode;
  label?: React.ReactNode;
  classes?: TileClasses;
}

const Tile: React.FunctionComponent<TileProps> = ({
  value,
  label,
  children,
  className = '',
  loading = false,
  theme = 'primary',
  classes,
}) => (
  <Card
    className={classNames(styles.card, styles[theme], className)}
    loading={loading}
    contentClassName={styles.cardContent}
    data-testid={'tile'}
  >
    {value && (
      <span className={classNames(styles.value, (classes && classes.value) || '')} data-testid={'tile-value'}>
        {value}
      </span>
    )}
    {label && (
      <span className={classNames(styles.label, (classes && classes.label) || '')} data-testid={'tile-label'}>
        {label}
      </span>
    )}
    {children}
  </Card>
);

export default Tile;
