import { ConfigSelectOption } from 'store/types/SelectOption';
import DonationCampaignStatus from 'store/enums/DonationCampaignStatus';

const donationStatusConfig: Record<DonationCampaignStatus, ConfigSelectOption> = {
  [DonationCampaignStatus.Active]: {
    name: 'Active',
    theme: 'green',
  },
  [DonationCampaignStatus.Approved]: {
    name: 'Approved - Pending Activation',
    theme: 'yellow',
  },
  [DonationCampaignStatus.Submitted]: {
    name: 'Submitted - Pending Review',
    theme: 'blue',
  },
  [DonationCampaignStatus.Closed]: {
    name: 'Closed',
    theme: 'grey',
  },
  [DonationCampaignStatus.Expired]: {
    name: 'Expired',
    theme: 'grey',
  },
  [DonationCampaignStatus.Rejected]: {
    name: 'Rejected',
    theme: 'red',
  },
};

export default donationStatusConfig;
