import { ExpenseReport } from 'store/types/Expense';
import { SortConfig } from 'store/types/Table';
import { sorter } from 'util/Table';
import ExpenseReportStatus from 'store/enums/ExpenseReportStatus';

export enum ExpenseReportTableActionType {
  SetInitialList = 'SetInitialList',
  SetSelectedReportId = 'SetSelectedReportId',
  SetPrintedReport = 'SetPrintedReport',
  UpdateSort = 'UpdateSort',
  UpdateFilter = 'UpdateFilter',
  ToggleModal = 'ToggleModal',
}

export interface ExpenseReportTableState {
  selectedReportId: string;
  printedReport?: ExpenseReport | null;
  initialList: ExpenseReport[];
  list: ExpenseReport[];
  listDraftStatus: ExpenseReport[];
  listSubmittedStatus: ExpenseReport[];
  initialListSubmittedStatus: ExpenseReport[];
  sort: SortConfig<ExpenseReport>;
  statuses: string[];
  selectedStatusFilters: string[];
  modalOpen: boolean;
  loading: boolean;
}

export interface ExpenseReportTableAction {
  type: ExpenseReportTableActionType;
  payload: Partial<ExpenseReportTableState>;
}

const getSortedList = (list: ExpenseReport[] = [], sort: SortConfig<ExpenseReport>): ExpenseReport[] =>
  list.length ? [...list].sort(sorter<ExpenseReport>(sort)) : [...list];

export const initialState: ExpenseReportTableState = {
  selectedReportId: '',
  initialList: [],
  list: [],
  listDraftStatus: [],
  listSubmittedStatus: [],
  initialListSubmittedStatus: [],
  sort: { column: 'transactionNumber', direction: 'desc' },
  statuses: [],
  selectedStatusFilters: [],
  modalOpen: false,
  loading: false,
};

const getFilteredList = (list: ExpenseReport[] = [], filterStatuses: string[]) => {
  return filterStatuses.length > 0 ? list.filter((item) => filterStatuses.includes(item.status)) : list;
};

const getFilterStatuses = (expenseReports: ExpenseReport[]) => {
  return expenseReports
    .reduce((acc: string[], currentValue: ExpenseReport) => {
      if (!acc.find((item) => item === currentValue.status)) {
        acc.push(currentValue.status);
      }
      return acc;
    }, [])
    .sort();
};

const reducer = (
  state: ExpenseReportTableState,
  { type, payload }: ExpenseReportTableAction
): ExpenseReportTableState => {
  if (type === ExpenseReportTableActionType.SetInitialList) {
    const { initialList = [] } = payload;

    const listDraftStatus = initialList?.filter((item) => item.status === ExpenseReportStatus.Draft) ?? [];
    const listSubmittedStatus = initialList?.filter((item) => item.status !== ExpenseReportStatus.Draft) ?? [];

    return {
      ...state,
      initialList,
      list: initialList,
      listDraftStatus,
      listSubmittedStatus,
      initialListSubmittedStatus: listSubmittedStatus,
      statuses: getFilterStatuses(listSubmittedStatus),
    };
  }

  if (type === ExpenseReportTableActionType.UpdateSort) {
    const { sort = {} } = payload;

    return {
      ...state,
      sort,
      list: getSortedList(state.initialList, sort),
    };
  }

  if (type === ExpenseReportTableActionType.UpdateFilter) {
    const { selectedStatusFilters = [] } = payload;
    const filteredList = getFilteredList(state.initialListSubmittedStatus, selectedStatusFilters);

    return {
      ...state,
      selectedStatusFilters,
      listSubmittedStatus: filteredList,
    };
  }

  if (type === ExpenseReportTableActionType.ToggleModal) {
    return { ...state, modalOpen: !state.modalOpen };
  }

  if (type === ExpenseReportTableActionType.SetPrintedReport) {
    const { printedReport = null } = payload;

    return { ...state, printedReport: printedReport };
  }

  if (type === ExpenseReportTableActionType.SetSelectedReportId) {
    const { selectedReportId = '' } = payload;

    return { ...state, selectedReportId };
  }

  return state;
};

export default reducer;
