import { ConfigSelectOption } from 'store/types/SelectOption';
import PriorApprovalStatus from 'store/enums/PriorApprovalStatus';

const priorApprovalStatusConfig: Record<PriorApprovalStatus, ConfigSelectOption> = {
  [PriorApprovalStatus.Pending]: {
    name: 'Pending',
    theme: 'yellow',
  },
  [PriorApprovalStatus.Rejected]: {
    name: 'Rejected',
    theme: 'red',
  },
  [PriorApprovalStatus.Approved]: {
    name: 'Approved',
    theme: 'green',
  },
};

export default priorApprovalStatusConfig;
