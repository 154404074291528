import { SortDirection } from '@mui/material';
import { SortConfig } from 'store/types/Table';
import moment from 'moment-timezone';
import SupportCasePriority from 'store/enums/SupportCasePriority';

export const userFullNameSortLabel = 'userFullName';

export const prioritySortLabel = 'priority';

const stringComparator = (a: string, b: string, direction: SortDirection): number => {
  const ascResult = a.toLowerCase().localeCompare(b.toLowerCase());

  return direction === 'asc' ? ascResult : -ascResult;
};

const numberComparator = (a: number, b: number, direction: SortDirection): number =>
  direction === 'asc' ? a - b : b - a;

const booleanComparator = (a: boolean, b: boolean, direction: SortDirection): number => {
  const ascResult = a === b ? 0 : a ? -1 : 1;

  return direction === 'asc' ? ascResult : -ascResult;
};

const userFullNameComparator = (a: any, b: any, direction: SortDirection): number => {
  const firstNameAValue = a?.firstName ? a.firstName : '';
  const lastNameAValue = a?.lastName ? a.lastName : '';
  const firstNameBValue = b?.firstName ? b.firstName : '';
  const lastNameBValue = b?.lastName ? b.lastName : '';
  const ascLastNameResult = lastNameAValue.toLowerCase().localeCompare(lastNameBValue.toLowerCase());
  const ascFirstNameResult = firstNameAValue.toLowerCase().localeCompare(firstNameBValue.toLowerCase());

  if (ascFirstNameResult !== 0) {
    return direction === 'asc' ? ascFirstNameResult : -ascFirstNameResult;
  } else {
    return direction === 'asc' ? ascLastNameResult : -ascLastNameResult;
  }
};

const priorityComparator = (a: any, b: any, direction: SortDirection): number => {
  const priorities = Object.values(SupportCasePriority);
  const result = priorities.indexOf(a.priority) - priorities.indexOf(b.priority);

  return direction === 'asc' ? result : -result;
};

export const sorter =
  <T>({ column, direction }: SortConfig<T>) =>
  (a: T, b: T): number => {
    if (column && direction) {
      const aValue: any = a[column];
      const bValue: any = b[column];
      const formattedAValue = `${aValue}`;
      const formattedBValue = `${bValue}`;

      if (column === userFullNameSortLabel) {
        return userFullNameComparator(a, b, direction);
      }
      if (column === prioritySortLabel) {
        return priorityComparator(a, b, direction);
      }

      if (moment.isMoment(aValue)) {
        return numberComparator(aValue.clone().unix(), moment.isMoment(bValue) ? bValue.clone().unix() : 0, direction);
      } else if (typeof aValue === 'string' || typeof bValue === 'string') {
        return stringComparator(formattedAValue, formattedBValue, direction);
      } else if (typeof aValue === 'boolean') {
        return booleanComparator(aValue, !!bValue, direction);
      } else {
        // allows 2 decimal places
        return numberComparator(
          Math.round(parseFloat(formattedAValue) * 100) / 100,
          Math.round(parseFloat(formattedBValue) * 100) / 100,
          direction
        );
      }
    } else {
      return 0;
    }
  };

export const getListByPage = <T = unknown>(initialList: T[] = [], itemsPerPage: number, page = 1): T[] => {
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = page * itemsPerPage;

  return initialList.slice(startIndex, endIndex);
};

export const getPagesCount = (listLength: number, itemsPerPage: number): number =>
  Math.ceil(listLength / itemsPerPage) || 1;
