import React, { useEffect, useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import Chip from '@mui/material/Chip';
import classNames from 'classnames';
import filterStyles from 'components/shared/FilterToggleGroup/FilterToggleGroup.module.scss';
import statusLabelStyles from 'components/shared/StatusLabel/StatusLabel.module.scss';
import StatusLabelTheme from 'store/configs/StatusLabelTheme';
import ComponentProps from 'store/types/ComponentProps';

type StatusLabelThemeEx = StatusLabelTheme & 'primary-theme';

export interface FilterToggleGroupProps extends ComponentProps {
  items: string[];
  defaultItems?: string[];
  theme?: StatusLabelThemeEx | StatusLabelTheme;
  onChange: (filters: string[]) => void;
}

/**
 * Renders a toggle button group. Selected items are passed to the callback function as a string array.
 * @param {FilterToggleGroupProps} props
 * @param {function} props.items - Array of filter options
 * @param {function} props.defaultItems - Optional array of default filter options
 * @param {function} props.theme - Optional theme
 * @param {function} props.className - Optional class name
 * @param {function} props.onChange - Callback function to handle filter selections
 * @example
 * <FilterToggleGroup items={items} onChange={handleFilter} />
 * <FilterToggleGroup items={items} defaultItems={[items[0]]} theme={'blue'} onChange={handleFilter} />
 */
const FilterToggleGroup: React.FunctionComponent<FilterToggleGroupProps> = ({
  items,
  defaultItems,
  theme = 'primary-theme' as StatusLabelThemeEx,
  className,
  onChange,
}: FilterToggleGroupProps) => {
  const [filteredItems, setFilteredItems] = useState<string[]>([]);
  const [defaultFilteredItems, setDefaultFilteredItems] = useState<string[]>([]);

  useEffect(() => {
    if (defaultItems && defaultFilteredItems?.length === 0) {
      setFilteredItems(defaultItems);
      setDefaultFilteredItems(defaultItems);
    }
  }, [defaultFilteredItems, defaultItems]);

  const handleFilter = (_: React.MouseEvent<HTMLElement>, newSelectedItems: string[]) => {
    setFilteredItems(newSelectedItems);
    onChange(newSelectedItems);
  };

  return (
    <ToggleButtonGroup
      value={filteredItems}
      className={classNames(filterStyles.buttonGroup, className)}
      onChange={handleFilter}
      aria-label={'filter'}
      data-testid={'toggle-button-group'}
    >
      {items.map((item, index) => {
        return (
          <ToggleButton
            key={`toggle-button-item-${index}`}
            value={item}
            className={filterStyles.button}
            aria-label={item}
            data-testid={`toggle-button-item-${index}`}
            disableRipple={true}
          >
            <Chip
              label={item}
              className={classNames(
                filteredItems?.includes(item) ? statusLabelStyles.filled : statusLabelStyles.outlined,
                statusLabelStyles[theme],
                statusLabelStyles.label,
                filterStyles.label,
                className
              )}
              data-testid={`toggle-chip-${index}`}
            />
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};

export default FilterToggleGroup;
