import React, { useMemo } from 'react';
import Modal from 'components/shared/Modal';
import { ModalProps } from 'store/types/ComponentProps';
import { Button, Grid } from '@mui/material';
import routes from 'store/configs/Routes';
import { getLoginPath } from 'util/Route';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';

import commonStyles from 'styles/common.module.scss';

interface DonationWarningModalProps extends ModalProps {
  donationId?: string;
  description: string;
  buttonName: string;
}

const DonationWarningModal: React.FunctionComponent<DonationWarningModalProps> = ({
  open,
  onClose,
  donationId,
  description,
  buttonName,
}) => {
  const buttonHref: string = useMemo(
    () => getLoginPath(donationId ? `${routes.makeDonation}/${donationId}` : routes.donations),
    [donationId]
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={'Warning'}
      maxWidth={'xs'}
      actions={
        <Grid {...defaultGridContainerProps} justifyContent={'flex-end'}>
          <Grid {...defaultGridItemProps} sm={'auto'}>
            <Button variant={'contained'} color={'secondary'} href={buttonHref}>
              {buttonName}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <p className={commonStyles.text}>{description}</p>
    </Modal>
  );
};
export default DonationWarningModal;
