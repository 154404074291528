import { FilterConfig } from 'store/types/Table';
import { getListByPage } from 'util/Table';
import Donation from 'store/types/Donation';
import { DonationFundItem } from 'store/types/DonationFund';

export enum SelectDonationPageActionType {
  SetInitialList = 'SetInitialList',
  UpdateFilter = 'UpdateFilter',
  UpdatePage = 'UpdatePage',
  OpenCreateCampaignModal = 'OpenCreateCampaignModal',
  CloseCreateCampaignModal = 'CloseCreateCampaignModal',
  OpenWarningModal = 'OpenWarningModal',
  CloseWarningModal = 'CloseWarningModal',
}

export interface SelectDonationPageState {
  initialList: DonationFundItem[];
  list: DonationFundItem[];
  pageList: DonationFundItem[];
  filter: FilterConfig<DonationFundItem>;
  page: number;
  warningModalOpen: boolean;
  createCampaignModalOpen: boolean;
}

export interface SelectDonationPageAction {
  type: SelectDonationPageActionType;
  payload: Partial<SelectDonationPageState>;
}

export const ITEMS_PER_PAGE = 12;
export const ALL_CUSTOMERS_FIELD_NAME = 'All Partners';

const getFilteredList = (list: DonationFundItem[] = [], filter: FilterConfig<DonationFundItem>): DonationFundItem[] => {
  let newFilteredList: DonationFundItem[] = [...list];
  const searchValue = (filter.search || '').toLowerCase();
  const selectedPartner = filter.partner || '';

  if (selectedPartner) {
    newFilteredList =
      selectedPartner === ALL_CUSTOMERS_FIELD_NAME
        ? newFilteredList
        : newFilteredList.filter(({ partner }) => partner === selectedPartner);
  }

  if (searchValue) {
    newFilteredList = newFilteredList.filter(({ name }: Donation) =>
      name.toLowerCase().includes(searchValue.toLowerCase())
    );
  }
  return newFilteredList;
};

export const initialState: SelectDonationPageState = {
  initialList: [],
  list: [],
  pageList: [],
  page: 1,
  filter: { search: '', partner: '' },
  warningModalOpen: false,
  createCampaignModalOpen: false,
};

const reducer = (
  state: SelectDonationPageState,
  { type, payload }: SelectDonationPageAction
): SelectDonationPageState => {
  if (type === SelectDonationPageActionType.SetInitialList) {
    const { initialList = [], filter } = payload;
    const newFilter = { ...state.filter, ...filter };
    const list = getFilteredList(initialList, newFilter);
    const pageList = getListByPage(list, ITEMS_PER_PAGE, 1);

    return { ...state, initialList, list: initialList, pageList };
  }

  if (type === SelectDonationPageActionType.UpdatePage) {
    const { page = 1 } = payload;

    return {
      ...state,
      page,
      pageList: getListByPage(state.list, ITEMS_PER_PAGE, page),
    };
  }

  if (type === SelectDonationPageActionType.OpenCreateCampaignModal) {
    return { ...state, createCampaignModalOpen: true };
  }

  if (type === SelectDonationPageActionType.CloseCreateCampaignModal) {
    return { ...state, createCampaignModalOpen: false };
  }

  if (type === SelectDonationPageActionType.OpenWarningModal) {
    return { ...state, warningModalOpen: true };
  }

  if (type === SelectDonationPageActionType.CloseWarningModal) {
    return { ...state, warningModalOpen: false };
  }

  if (type === SelectDonationPageActionType.UpdateFilter) {
    const { filter = {} } = payload;
    const newFilter = { ...state.filter, ...filter };
    const list = getFilteredList(state.initialList, newFilter);
    const pageList = getListByPage(list, ITEMS_PER_PAGE, 1);

    return {
      ...state,
      filter: newFilter,
      list,
      pageList,
      page: 1,
    };
  }

  return state;
};

export default reducer;
