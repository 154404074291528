import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import Spinner from 'components/shared/Spinner';
import { UserContext } from 'components/UserGuard';
import { defaultGridContainerProps, defaultGridItemProps, getPageTitle } from 'util/Layout';
import { ConfigContext } from 'components/ConfigGuard';
import SiteModule from 'store/enums/SiteModule';
import SupportPageView from 'components/support/SupportPageView';

import commonStyles from 'styles/common.module.scss';

const SupportPage: React.FunctionComponent = () => {
  const { sidebar } = useContext(ConfigContext);
  const { userLoading = false } = useContext(UserContext);

  return (
    <>
      <h1 className={commonStyles.pageTitle}>{getPageTitle(sidebar, SiteModule.Support)}</h1>

      <Spinner loading={userLoading} transparent={false}>
        <Grid {...defaultGridContainerProps}>
          <Grid {...defaultGridItemProps} sm={true}>
            <SupportPageView />
          </Grid>
        </Grid>
      </Spinner>
    </>
  );
};
export default SupportPage;
