import { PaymentItemsFormValues } from 'store/types/FormValues';
import PaymentSummaryItem from 'store/types/PaymentSummaryItem';
import Membership, { CurrentMembership } from 'store/types/Membership';
import SubscriptionStatus from 'store/enums/SubscriptionStatus';
import { SubscriptionPlan } from 'store/types/Subscription';
import { ALL_COMPANIES_DROPDOWN_KEY, CompanySelectOption } from './Company';
import SelectOption from 'store/types/SelectOption';

export const getMembershipSummaryList = (
  donations: PaymentItemsFormValues,
  membershipType?: SubscriptionPlan
): PaymentSummaryItem[] => {
  let result: PaymentSummaryItem[] = [];

  if (membershipType) {
    result = [...result, { name: membershipType.name, price: membershipType.price }];
  }
  if (donations) {
    Object.keys(donations).forEach((key) => {
      const { name, price = 0 } = donations[key];

      if (price) {
        result = [...result, { name, price }];
      }
    });
  }
  return result;
};

export const getDonationsPaymentData = (donations: PaymentItemsFormValues): Record<string, number> => {
  let result: Record<string, number> = {};

  Object.keys(donations).forEach((id: string) => {
    result = { ...result, [id]: donations[id].price || 0 };
  });
  return result;
};

export const hasMembershipDateExpired = (endDate = ''): boolean => new Date(endDate).getTime() - Date.now() < 0;

export const hasGracePeriodExpired = ({ gracePeriod = 0, endDate }: Membership): boolean =>
  hasMembershipDateExpired(endDate) && gracePeriod === 0;

export const isRenewMembershipPage = (membership: CurrentMembership): boolean => {
  if (membership) {
    const { status, renewalPlan, autoRenewal } = membership;

    return (
      !!renewalPlan &&
      ((status === SubscriptionStatus.Active && !autoRenewal) ||
        (status === SubscriptionStatus.Expired && !hasGracePeriodExpired(membership)))
    );
  } else {
    return false;
  }
};

export const isReJoinMembershipPage = (membership: CurrentMembership): boolean =>
  !!membership && membership.status === SubscriptionStatus.Expired && hasGracePeriodExpired(membership);

export const isJoinMembershipPage = (membership: CurrentMembership): boolean =>
  !!membership && membership.status === SubscriptionStatus.NonMember;

export const getSubscriptionSummaryList = (
  donations: PaymentItemsFormValues,
  subscription?: SubscriptionPlan,
  quantity = 1
): PaymentSummaryItem[] => {
  let result: PaymentSummaryItem[] = [];

  if (subscription) {
    const { name, price } = subscription;

    result = [
      ...result,
      { name: `${name} (${quantity} subscription${quantity > 1 ? 's' : ''})`, price: price * quantity },
    ];
  }
  if (donations) {
    Object.keys(donations).forEach((key) => {
      const { name, price = 0 } = donations[key];

      if (price) {
        result = [...result, { name, price }];
      }
    });
  }
  return result;
};

export const getSubscriptionSelectOptions = (
  list: SelectOption[],
  showAllCompaniesMenuItem?: boolean
): CompanySelectOption[] => {
  const newList = list.map(({ name, id }) => ({ label: name, id }));
  if (showAllCompaniesMenuItem) {
    newList.unshift({ id: ALL_COMPANIES_DROPDOWN_KEY, label: 'All Companies' });
  }

  return newList;
};
