import { ConfigSelectOption } from 'store/types/SelectOption';
import PaymentStatus from 'store/enums/PaymentStatus';

const paymentStatusConfig: Record<PaymentStatus, ConfigSelectOption> = {
  [PaymentStatus.Paid]: {
    name: 'Paid',
    theme: 'green',
  },
  [PaymentStatus.Unpaid]: {
    name: 'Open',
    theme: 'yellow',
  },
  [PaymentStatus.Overdue]: {
    name: 'Overdue',
    theme: 'red',
  },
  [PaymentStatus.Processing]: {
    name: 'Processing',
    theme: 'grey',
  },
  [PaymentStatus.PendingFulfillment]: {
    name: 'Pending Fulfillment',
    theme: 'blue',
  },
  [PaymentStatus.FullyBilled]: {
    name: 'Billed',
    theme: 'green',
  },
  [PaymentStatus.PendingBilling]: {
    name: 'Pending Billing',
    theme: 'yellow',
  },
};

export default paymentStatusConfig;
