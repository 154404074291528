import React, { useCallback, useRef, useState } from 'react';
import { Button, ButtonGroup, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList } from '@mui/material';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { getButtonLoadingProps } from 'util/Layout';

import styles from './SplitButton.module.scss';
import commonStyles from 'styles/common.module.scss';

interface SplitButtonOption {
  label: React.ReactNode;
  onClick: () => void;
}

interface SplitButtonProps {
  options: SplitButtonOption[];
  loading?: boolean;
  isSelect?: boolean;
  showDefaultValue?: boolean;
  placeholder?: string;
}

const SplitButton: React.FunctionComponent<SplitButtonProps> = ({
  options = [],
  loading = false,
  isSelect,
  showDefaultValue = true,
  placeholder,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(showDefaultValue ? 0 : undefined);

  const handleClick = () => {
    if (typeof selectedIndex === 'number') {
      options[selectedIndex].onClick();
    }
  };

  const handleMenuItemClick = useCallback(
    (index: number) => () => {
      setSelectedIndex(index);
      setOpen(false);
      if (isSelect) {
        options[index].onClick();
      }
    },
    [isSelect, options]
  );

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleClose = useCallback((event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  }, []);

  return (
    <>
      <ButtonGroup
        variant={'outlined'}
        color={'primary'}
        ref={anchorRef}
        fullWidth={true}
        disabled={loading}
        data-testid={'split-button'}
      >
        <Button onClick={handleClick} {...getButtonLoadingProps(loading)} data-testid={'button'}>
          {typeof selectedIndex === 'number' ? (
            options[selectedIndex]?.label
          ) : (
            <span className={commonStyles.placeholder}>{placeholder}</span>
          )}
        </Button>
        <Button size={'small'} onClick={handleToggle} className={styles.buttonArrow} data-testid={'toggle-button'}>
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition={true}
        disablePortal={true}
        className={styles.popper}
        data-testid={'popper'}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {options.map(({ label }, index) => (
                    <MenuItem
                      data-testid={'menu-item'}
                      key={`split-button-item-${index}`}
                      selected={index === selectedIndex}
                      onClick={handleMenuItemClick(index)}
                    >
                      {label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default SplitButton;
