import { CardActionArea, Container, Grid } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import Add from '@mui/icons-material/Add';
import { BillingAddress } from 'store/types/Address';
import Card from 'components/shared/Card';
import EditAddressModal from 'components/addresses/EditAddressModal';
import AddressCard from 'components/addresses/AddressesPageView/AddressListItem';
import { getListByPage, getPagesCount } from 'util/Table';
import Pagination from '@mui/material/Pagination';
import { getSortedAddressList } from 'util/Form';
import { CompanySelectOption } from 'util/Company';

import styles from './AddressesPageView.module.scss';

const ITEMS_PER_PAGE = 11;

interface AddressesPageViewProps {
  data: BillingAddress[];
  selectedCompany?: CompanySelectOption;
  refetch: () => void;
}

const AddressesPageView: React.FunctionComponent<AddressesPageViewProps> = ({ data, refetch, selectedCompany }) => {
  const [selectedAddress, setSelectedAddress] = useState<BillingAddress | undefined>(undefined);
  const [editAddressModalOpen, setEditAddressModalOpen] = useState<boolean>(false);
  const [filteredList, setFilteredList] = useState<BillingAddress[]>([]);
  const [page, setPage] = useState<number>(1);
  const isFirstAddress: boolean = useMemo(() => !data?.length, [data]);
  const pagesCount: number = getPagesCount(filteredList.length, ITEMS_PER_PAGE);
  const pageList: BillingAddress[] = getListByPage(getSortedAddressList(filteredList), ITEMS_PER_PAGE, page);

  useEffect(() => {
    setFilteredList(getSortedAddressList(data));
  }, [data]);

  const handleEditAddressModalClose = useCallback(() => {
    setEditAddressModalOpen(false);
    setSelectedAddress(undefined);
  }, []);

  const handleEditAddressModalOpen = useCallback(() => {
    setEditAddressModalOpen(true);
  }, []);

  const handleEditAddressSubmit = useCallback(() => {
    refetch();
    handleEditAddressModalClose();
  }, [handleEditAddressModalClose, refetch]);

  const handleEditAddress = useCallback(
    (billingAddress: BillingAddress) => () => {
      setSelectedAddress(billingAddress);
      setEditAddressModalOpen(true);
    },
    []
  );

  const handlePageChange = useCallback((_: any, newPage: number) => {
    window.scrollTo(0, 0);
    setPage(newPage);
  }, []);

  return (
    <>
      <Grid {...defaultGridContainerProps} justifyContent={'flex-start'}>
        <Grid {...defaultGridItemProps} sm={12} md={4} lg={3}>
          <Card
            className={styles.card}
            onClick={handleEditAddressModalOpen}
            contentClassName={styles.content}
            spinnerClassName={styles.spinner}
          >
            <CardActionArea className={styles.actionArea}>
              <div className={styles.icon}>
                <Add />
              </div>
              <div className={styles.title}>{'Add Address'}</div>
            </CardActionArea>
          </Card>
        </Grid>
        {pageList?.map((billingAddress, index) => (
          <Grid key={`address-${billingAddress.label + index}`} {...defaultGridItemProps} sm={12} md={4} lg={3}>
            <AddressCard data={billingAddress} onClick={handleEditAddress(billingAddress)} />
          </Grid>
        ))}
      </Grid>
      <section>
        <Container maxWidth={'xl'} className={styles.sectionContent}>
          <div className={styles.paginationWrapper}>
            <Pagination
              showFirstButton={true}
              showLastButton={true}
              count={pagesCount}
              page={page}
              onChange={handlePageChange}
            />
          </div>
        </Container>
      </section>
      {editAddressModalOpen && (
        <EditAddressModal
          data={selectedAddress}
          onClose={handleEditAddressModalClose}
          open={editAddressModalOpen}
          onSubmit={handleEditAddressSubmit}
          isFirstAddress={isFirstAddress}
          selectedCompany={selectedCompany}
        />
      )}
    </>
  );
};
export default AddressesPageView;
