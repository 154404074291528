import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import {
  Button,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { getRequiredValidationRule } from 'util/Form';
import { Controller, FormProvider, useFieldArray, useFormContext } from 'react-hook-form';
import DateFormItem from 'components/shared/DateFormItem';
import moment, { isMoment } from 'moment-timezone';
import EditPreferencesForm from 'components/profile/EditPreferencesPage/EditPreferencesForm';
import { EventHotelPreferencesFormValues } from 'store/types/events/EventHotelPreferences';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import classNames from 'classnames';
import { HotelConfirmStatus } from 'store/enums/events/HotelConfirmStatus';
import { getDefaultRoomValues, MAX_GUEST_ROOMS, numberOfGuestsConfig } from 'util/EventHotelPreferences';
import Add from '@mui/icons-material/Add';
import { PREFERENCES_FIELD_NAME } from 'util/Preferences';
import { UserContext } from 'components/UserGuard';
import { getAddressInfo, getUserFullName } from 'util/Format';
import { Moment } from 'moment/moment';
import Card from 'components/shared/Card';
import { getFormattedEventDate, getFormattedEventTime } from 'util/Event';
import { ParametersContext } from 'components/ParametersGuard';

import styles from './EventHotelPreferencesForm.module.scss';
import commonStyles from 'styles/common.module.scss';

interface EventHotelPreferencesFormProps {
  hotelSectionDisabled?: boolean;
  preferencesSectionDisabled?: boolean;
  availabilityDisclaimer?: string;
  eventRegistrationDisclaimer?: string;
  additionalInformation?: string;
}

const EventHotelPreferencesForm: React.FunctionComponent<EventHotelPreferencesFormProps> = ({
  hotelSectionDisabled,
  availabilityDisclaimer,
  preferencesSectionDisabled,
  eventRegistrationDisclaimer,
  additionalInformation,
}) => {
  const {
    myEvents: { registrationCutoffMessage },
  } = useContext(ParametersContext);
  const form = useFormContext<EventHotelPreferencesFormValues>();
  const currentUser = useContext(UserContext);
  const { control, watch, getValues, setValue } = form;
  const { fields, append, update, remove } = useFieldArray({ control, name: 'rooms' });
  const {
    hotelConfirmStatus,
    accessibilities,
    roomTypes,
    preferencesQuestionsList,
    hotelName,
    isHotelInformationCollected,
    guestId,
    isPreferencesCollected,
    defaultHotelCheckOutDate,
    defaultHotelCheckInDate,
    guestName,
    isPreferencesCollectedForGuest,
    isHotelInformationCollectedForGuest,
    eventTitle,
    summaryDescription,
    startDate,
    endDate,
    location,
    address,
    timeZoneName,
  } = watch();
  const formattedEndDate: Moment = useMemo(() => moment.utc(endDate), [endDate]);
  const formattedStartDate: Moment = useMemo(() => moment.utc(startDate), [startDate]);
  const showTime: boolean = useMemo(
    () => formattedEndDate && formattedStartDate.isSame(formattedEndDate, 'days'),
    [formattedEndDate, formattedStartDate]
  );

  useEffect(() => {
    if (hotelSectionDisabled || hotelConfirmStatus === HotelConfirmStatus.No) {
      setValue('hotelConfirmStatus', HotelConfirmStatus.No, {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: true,
      });
    }
  }, [hotelConfirmStatus, hotelSectionDisabled, setValue]);

  const handleRoomViewExpand = useCallback(
    (index: number, viewExpanded: boolean) => () => {
      const fieldValues = getValues().rooms[index];
      update(index, { ...fieldValues, viewExpanded });
    },
    [getValues, update]
  );

  const handleDeleteRoom = useCallback(
    (index: number) => () => {
      remove(index);
    },
    [remove]
  );
  const handleAddRoom = useCallback(() => {
    append(getDefaultRoomValues(undefined, defaultHotelCheckInDate, defaultHotelCheckOutDate));
  }, [append, defaultHotelCheckInDate, defaultHotelCheckOutDate]);

  const handleCheckInDateChange = useCallback(
    (index: number) => (value: Moment | string | null) => {
      const fieldValues = getValues().rooms[index];

      if (isMoment(value) && value.isValid()) {
        update(index, { ...fieldValues, checkInDate: value.format() || '' });
      }
    },
    [getValues, update]
  );

  const handleCheckOutDateChange = useCallback(
    (index: number) => (value: any) => {
      const fieldValues = getValues().rooms[index];

      if (isMoment(value) && value.isValid()) {
        update(index, { ...fieldValues, checkOutDate: value.format() || '' });
      }
    },
    [getValues, update]
  );

  return (
    <FormProvider {...form}>
      <Grid {...defaultGridContainerProps}>
        <Grid {...defaultGridItemProps}>
          <h1 className={styles.title}>{`${eventTitle} (${guestName || getUserFullName(currentUser)})`}</h1>
          <Divider className={styles.divider} />
        </Grid>
        <Grid {...defaultGridItemProps} xs={6}>
          <Card>
            <p>{summaryDescription}</p>
          </Card>
        </Grid>
        <Grid {...defaultGridItemProps} xs={6}>
          <Card>
            <Grid {...defaultGridContainerProps} spacing={1} alignItems={'flex-start'}>
              <Grid {...defaultGridItemProps} xs={3} className={styles.label}>
                {'Date:'}
              </Grid>
              <Grid {...defaultGridItemProps} xs={9} className={styles.value}>
                {getFormattedEventDate(formattedStartDate, formattedEndDate)}
              </Grid>
              {showTime && (
                <>
                  <Grid {...defaultGridItemProps} xs={3} className={styles.label}>
                    {'Time:'}
                  </Grid>
                  <Grid {...defaultGridItemProps} xs={9} className={styles.value}>
                    {getFormattedEventTime(formattedStartDate, formattedEndDate, timeZoneName)}
                  </Grid>
                </>
              )}
              <Grid {...defaultGridItemProps} xs={3} className={styles.label}>
                {'Location:'}
              </Grid>
              <Grid {...defaultGridItemProps} xs={9} className={styles.value}>
                <span>{location}</span>
                {address && <span>{getAddressInfo(address)}</span>}
              </Grid>
            </Grid>
          </Card>
        </Grid>
        {eventRegistrationDisclaimer && (
          <Grid {...defaultGridItemProps}>
            <p
              dangerouslySetInnerHTML={{
                __html: eventRegistrationDisclaimer,
              }}
            />
          </Grid>
        )}
      </Grid>
      {isHotelInformationCollected && (guestId ? isHotelInformationCollectedForGuest : true) && (
        <>
          <Grid {...defaultGridContainerProps} className={styles.section}>
            <Grid {...defaultGridItemProps}>
              <h1 className={styles.title}>{`Hotel Information (${guestName || getUserFullName(currentUser)})`}</h1>
              {hotelSectionDisabled && registrationCutoffMessage && (
                <p dangerouslySetInnerHTML={{ __html: registrationCutoffMessage }} />
              )}
              <Divider className={styles.divider} />
              <p className={styles.hotelName}>{hotelName}</p>
            </Grid>
            {additionalInformation && (
              <Grid {...defaultGridItemProps}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: additionalInformation,
                  }}
                />
              </Grid>
            )}
            <Grid {...defaultGridItemProps}>
              <h3 className={styles.subTitle}>{'Do you need a Hotel reservation?'}</h3>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <RadioGroup onChange={onChange} value={value} row={true}>
                    <FormControlLabel
                      disabled={hotelSectionDisabled}
                      value={HotelConfirmStatus.Yes}
                      control={<Radio color={'primary'} />}
                      label={HotelConfirmStatus.Yes}
                    />
                    <FormControlLabel
                      disabled={hotelSectionDisabled}
                      value={HotelConfirmStatus.No}
                      control={<Radio color={'primary'} />}
                      label={HotelConfirmStatus.No}
                    />
                  </RadioGroup>
                )}
                defaultValue={HotelConfirmStatus.Yes}
                name={'hotelConfirmStatus'}
                control={control}
              />
            </Grid>
            {availabilityDisclaimer && hotelConfirmStatus === HotelConfirmStatus.Yes && (
              <Grid {...defaultGridItemProps}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: availabilityDisclaimer,
                  }}
                />
              </Grid>
            )}
          </Grid>
          {hotelConfirmStatus === HotelConfirmStatus.Yes && (
            <>
              {fields.map(({ id, checkInDate, checkOutDate, viewExpanded }, index) => (
                <React.Fragment key={id}>
                  <Grid {...defaultGridItemProps}>
                    <h1 className={styles.roomTitle}>{`Room ${index + 1} (${
                      guestName || getUserFullName(currentUser)
                    })`}</h1>
                    <div className={styles.roomHeader}>
                      <Divider className={styles.divider} />
                      <Button className={styles.expandButton} onClick={handleRoomViewExpand(index, !viewExpanded)}>
                        {viewExpanded ? <ExpandLess /> : <ExpandMore />}
                      </Button>
                    </div>
                  </Grid>
                  <Collapse in={viewExpanded}>
                    <Grid {...defaultGridContainerProps} className={styles.section}>
                      <Grid {...defaultGridItemProps} md={6}>
                        <DateFormItem
                          onChange={handleCheckInDateChange(index)}
                          disabled={hotelSectionDisabled}
                          fieldName={`rooms.${index}.checkInDate`}
                          label={'Check-in Date'}
                          minDate={moment(new Date()).startOf('day')}
                          maxDate={checkOutDate ? moment(checkOutDate).subtract(1, 'day') : undefined}
                        />
                      </Grid>
                      <Grid {...defaultGridItemProps} md={6}>
                        <DateFormItem
                          onChange={handleCheckOutDateChange(index)}
                          disabled={hotelSectionDisabled}
                          fieldName={`rooms.${index}.checkOutDate`}
                          label={'Check-Out Date'}
                          minDate={
                            checkInDate
                              ? moment(checkInDate).add(1, 'day')
                              : moment(new Date()).startOf('day').add(1, 'day')
                          }
                        />
                      </Grid>
                      <Grid {...defaultGridItemProps} md={3}>
                        <Controller
                          render={({ field }) => (
                            <TextField
                              {...field}
                              select={true}
                              required={true}
                              label={'Room Type'}
                              disabled={hotelSectionDisabled}
                            >
                              {roomTypes.map(({ id, name }) => (
                                <MenuItem key={id} value={id}>
                                  {name}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                          name={`rooms.${index}.roomTypeId`}
                          control={control}
                          rules={{
                            required: getRequiredValidationRule('your answer', true),
                          }}
                        />
                      </Grid>
                      <Grid {...defaultGridItemProps} md={3}>
                        <Controller
                          render={({ field }) => (
                            <TextField
                              {...field}
                              select={true}
                              required={true}
                              label={'# of Occupants'}
                              disabled={hotelSectionDisabled}
                            >
                              {numberOfGuestsConfig.map((value) => (
                                <MenuItem key={value} value={value}>
                                  {value}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                          name={`rooms.${index}.numberOfGuests`}
                          control={control}
                          rules={{
                            required: getRequiredValidationRule('your answer', true),
                          }}
                        />
                      </Grid>
                      <Grid {...defaultGridItemProps}>
                        <Controller
                          render={({ field }) => (
                            <TextField {...field} label={'Sharing Hotel With:'} disabled={hotelSectionDisabled} />
                          )}
                          name={`rooms.${index}.guestName`}
                          control={control}
                        />
                      </Grid>
                      <Grid {...defaultGridItemProps}>
                        <Controller
                          render={({ field }) => (
                            <TextField {...field} select={true} label={'Accessibility'} disabled={hotelSectionDisabled}>
                              {(accessibilities || []).map(({ name, id }) => (
                                <MenuItem key={id} value={id}>
                                  {name}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                          name={`rooms.${index}.accessibilityId`}
                          control={control}
                        />
                      </Grid>
                      <Grid {...defaultGridItemProps}>
                        <Controller
                          render={({ field }) => (
                            <TextField {...field} label={'Any Special Requests'} disabled={hotelSectionDisabled} />
                          )}
                          name={`rooms.${index}.specialRequest`}
                          control={control}
                        />
                      </Grid>
                      <Grid {...defaultGridItemProps}>
                        <Controller
                          render={({ field }) => (
                            <TextField {...field} label={'Hotel Rewards Number'} disabled={hotelSectionDisabled} />
                          )}
                          name={`rooms.${index}.hotelRewards`}
                          control={control}
                        />
                      </Grid>
                      {fields.length > 1 && (
                        <Grid {...defaultGridItemProps} md={'auto'} className={styles.deleteButton}>
                          <Button
                            variant={'outlined'}
                            disabled={hotelSectionDisabled}
                            className={classNames(commonStyles.dangerButtonOutlined, styles.deleteButton)}
                            onClick={handleDeleteRoom(index)}
                          >
                            {'Remove Room'}
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Collapse>
                </React.Fragment>
              ))}
              {fields.length < MAX_GUEST_ROOMS && (
                <Grid {...defaultGridContainerProps} justifyContent={'flex-end'} className={styles.section}>
                  <Grid {...defaultGridItemProps} md={'auto'}>
                    <Button
                      variant={'outlined'}
                      startIcon={<Add />}
                      onClick={handleAddRoom}
                      disabled={hotelSectionDisabled}
                    >
                      {'Add Another Room'}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </>
      )}
      {isPreferencesCollected && (guestId ? isPreferencesCollectedForGuest : true) && (
        <Grid {...defaultGridContainerProps} className={styles.section}>
          <Grid {...defaultGridItemProps}>
            <h1 className={styles.title}>{`Preferences (${guestName || getUserFullName(currentUser)})`}</h1>
            {!preferencesSectionDisabled && registrationCutoffMessage && (
              <p dangerouslySetInnerHTML={{ __html: registrationCutoffMessage }} />
            )}
            <Divider className={styles.divider} />
            <EditPreferencesForm
              data={preferencesQuestionsList}
              parentFieldName={PREFERENCES_FIELD_NAME}
              disabled={preferencesSectionDisabled}
            />
          </Grid>
        </Grid>
      )}
    </FormProvider>
  );
};

export default EventHotelPreferencesForm;
