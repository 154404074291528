import Payment, { OrderPayment } from 'store/types/Payment';
import { PaymentFormValues } from 'store/types/FormValues';
import { defaultPaymentFormValues } from 'util/Payment';

export enum ExpressPaymentPageActionType {
  UpdatePaymentForm = 'UpdatePaymentForm',
  SetLoading = 'SetLoading',
  UpdateSelectedOrder = 'UpdateSelectedOrdersList',
  OpenPaymentConfirmation = 'OpenPaymentConfirmation',
  ClosePaymentConfirmation = 'ClosePaymentConfirmation',
}

export interface ExpressPaymentPageState {
  paymentForm: PaymentFormValues;
  selectedOrder?: OrderPayment;
  submitLoading?: boolean;
  payment?: Payment;
}

export interface ExpressPaymentPageAction {
  type: ExpressPaymentPageActionType;
  payload: Partial<Omit<ExpressPaymentPageState, 'paymentForm'>> & {
    paymentForm?: Partial<PaymentFormValues>;
  };
}

export const initialState: ExpressPaymentPageState = {
  paymentForm: defaultPaymentFormValues,
  selectedOrder: undefined,
  payment: undefined,
};

const reducer = (
  state: ExpressPaymentPageState,
  { type, payload }: ExpressPaymentPageAction
): ExpressPaymentPageState => {
  if (type === ExpressPaymentPageActionType.SetLoading) {
    const { submitLoading = false } = payload;

    return { ...state, submitLoading };
  }

  if (type === ExpressPaymentPageActionType.UpdatePaymentForm) {
    const { paymentForm = {} } = payload;

    return { ...state, paymentForm: { ...state.paymentForm, ...paymentForm } };
  }

  if (type === ExpressPaymentPageActionType.UpdateSelectedOrder) {
    const { selectedOrder } = payload;

    return { ...state, selectedOrder };
  }

  if (type === ExpressPaymentPageActionType.OpenPaymentConfirmation) {
    const { payment } = payload;

    return { ...state, submitLoading: false, payment };
  }

  if (type === ExpressPaymentPageActionType.ClosePaymentConfirmation) {
    return { ...state, payment: undefined };
  }

  return state;
};

export default reducer;
