import React from 'react';
import { getStringValue, STRING_DATE_FORMAT } from 'util/Format';
import Card from 'components/shared/Card';
import { JoinedCommunity } from 'store/types/Community';

import styles from './CommunityInfoSection.module.scss';

interface CommunityInfoSectionProps {
  data: JoinedCommunity;
}

const CommunityInfoSection: React.FunctionComponent<CommunityInfoSectionProps> = ({ data }) => {
  const {
    member: { role, startDate },
    description,
  } = data;

  return (
    <Card bordered={true}>
      <section>
        <div className={styles.row}>
          <span className={styles.label}>{'About:'}</span>
          <span className={styles.value}>{getStringValue(description)}</span>
        </div>
        <div className={styles.row}>
          <span className={styles.label}>{'Role:'}</span>
          <span className={styles.value}>{role}</span>
        </div>
        <div className={styles.row}>
          <span className={styles.label}>{'Joined:'}</span>
          <span className={styles.value}>{getStringValue(startDate, STRING_DATE_FORMAT)}</span>
        </div>
      </section>
    </Card>
  );
};
export default CommunityInfoSection;
