import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import { Alert, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { ConfigContext } from 'components/ConfigGuard';
import AchForm from 'components/expense/ExpenseProfilePage/AchForm';
import AddressForm from 'components/expense/ExpenseProfilePage/AddressForm';
import { ParametersContext } from 'components/ParametersGuard';
import Spinner from 'components/shared/Spinner';
import useRequest from 'hooks/useRequest';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import ExpenseService from 'services/api/ExpenseService';
import PaymentMethodType from 'store/enums/PaymentMethodType';
import { ExpenseProfile } from 'store/types/Expense';
import { PaymentFormValues } from 'store/types/FormValues';
import { defaultFormProps } from 'util/Form';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { defaultPaymentFormValues } from 'util/Payment';

import commonStyles from 'styles/common.module.scss';
import styles from 'components/expense/Expense.module.scss';

const ExpenseProfilePage: React.FunctionComponent = () => {
  const {
    modulesSettings: {
      expenseProfile: { profileTitle, profilePaymentOptions },
    },
  } = useContext(ConfigContext);

  const {
    expenseProfile: { profilePageHeader, profilePaymentSelectionHeader },
  } = useContext(ParametersContext);

  const { control, reset } = useForm({ ...defaultFormProps });
  const { enqueueSnackbar } = useSnackbar();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [spinnerLabel, setSpinnerLabel] = useState('');
  const [paymentForm, setPaymentForm] = useState<PaymentFormValues>({
    ...defaultPaymentFormValues,
    useHomeAddress: true,
  });

  const profileRequest = useCallback(async () => {
    const responseData = await ExpenseService.getExpenseProfile();
    if (responseData) {
      return responseData;
    }
    setSpinnerLabel('Building your Expense Reimbursement Profile ...');
    await ExpenseService.createVendor();
    setSpinnerLabel('');
    return await ExpenseService.getExpenseProfile();
  }, []);

  const { data, error, loading, refetch } = useRequest<ExpenseProfile>(profileRequest);

  useEffect(() => {
    if (data) {
      reset(data);
      setPaymentForm((prevState) => ({
        ...prevState,
        paymentMethodType: PaymentMethodType[data.paymentMethodType as keyof typeof PaymentMethodType],
      }));
    }
  }, [data, reset]);

  const handlePaymentMethodChange = useCallback(
    (onChange) => (e: ChangeEvent<HTMLInputElement>) => {
      const paymentMethodType = e.target.value;
      onChange(paymentMethodType);
      setSubmitLoading(true);

      ExpenseService.updatePaymentMethodType(paymentMethodType)
        .then(() => {
          refetch();
          setSubmitLoading(false);
          enqueueSnackbar('Payment option successfully updated', { variant: 'success' });
        })
        .catch((error: string) => {
          setSubmitLoading(false);
          enqueueSnackbar(error, { variant: 'error' });
        });
    },
    [enqueueSnackbar, refetch]
  );

  return error ? (
    <Alert severity={'error'} className={commonStyles.alert}>
      {error}
    </Alert>
  ) : (
    <>
      {profileTitle && <h1 className={commonStyles.pageTitle}>{profileTitle}</h1>}

      {profilePageHeader && (
        <div className={styles.contentBlock} dangerouslySetInnerHTML={{ __html: profilePageHeader }} />
      )}

      <div className={styles.content}>
        {profilePaymentOptions && <h2>{profilePaymentOptions}</h2>}

        {profilePaymentSelectionHeader && (
          <div className={styles.contentBlock} dangerouslySetInnerHTML={{ __html: profilePaymentSelectionHeader }} />
        )}

        <Spinner loading={loading || submitLoading} transparent={true} label={spinnerLabel}>
          {error ? (
            <Alert severity={'error'} className={commonStyles.alert}>
              {error}
            </Alert>
          ) : (
            <>
              <FormControl component={'fieldset'} fullWidth={true} required={true}>
                <Controller
                  name={'paymentMethodType'}
                  control={control}
                  defaultValue={paymentForm.paymentMethodType}
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      value={value}
                      onChange={handlePaymentMethodChange(onChange)}
                      className={styles.radioGroup}
                    >
                      <FormControlLabel
                        key={PaymentMethodType.Check}
                        label={PaymentMethodType.Check}
                        value={PaymentMethodType.Check}
                        control={<Radio color={'primary'} />}
                      />
                      <FormControlLabel
                        key={PaymentMethodType.Ach}
                        label={PaymentMethodType.Ach.toUpperCase()}
                        value={PaymentMethodType.Ach}
                        control={<Radio color={'primary'} />}
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>

              {paymentForm.paymentMethodType === PaymentMethodType.Check && <AddressForm />}

              {paymentForm.paymentMethodType === PaymentMethodType.Ach && data && (
                <Grid {...defaultGridContainerProps}>
                  <Grid {...defaultGridItemProps}>
                    <AchForm data={data?.achPayment} />
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Spinner>
      </div>
    </>
  );
};

export default ExpenseProfilePage;
