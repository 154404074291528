import { FormControlLabel } from '@mui/material';
import React, { SyntheticEvent, useCallback, useState } from 'react';
import { Skeleton } from '@mui/material';
import Switch from 'components/shared/Switch';
import { CompanyPaymentDetails } from 'store/types/Company';
import CompanyService from 'services/api/CompanyService';
import { useSnackbar } from 'notistack';
import UseRequestData from 'store/types/UseRequestData';
import { defaultSnackbarErrorProps } from 'util/Layout';

import styles from './ApplyCreditsToggle.module.scss';

interface ApplyCreditsToggleProps extends UseRequestData<CompanyPaymentDetails> {
  id: string;
}

const ApplyCreditsToggle: React.FunctionComponent<ApplyCreditsToggleProps> = ({ id, data, refetch, loading }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [updateFlagLoading, setUpdateFlagLoading] = useState<boolean>(false);
  const updateApplyCreditsFlag = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      setUpdateFlagLoading(true);
      CompanyService.updateCompanyPaymentDetails(id, !data?.applyCredits)
        .then(() => {
          enqueueSnackbar('Apply Credits toggle successfully updated', { variant: 'success' });
          refetch();
          setUpdateFlagLoading(false);
        })
        .catch((errorMessage) => {
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
          setUpdateFlagLoading(false);
        });
    },
    [id, data, enqueueSnackbar, refetch]
  );

  return (
    <>
      {loading || updateFlagLoading ? (
        <Skeleton variant={'text'} width={205} height={38} />
      ) : (
        <FormControlLabel
          className={styles.toggle}
          onClick={updateApplyCreditsFlag}
          checked={data?.applyCredits}
          labelPlacement={'start'}
          label={'Apply Credits'}
          control={<Switch color={'primary'} />}
        />
      )}
    </>
  );
};

export default ApplyCreditsToggle;
