import classNames from 'classnames';
import React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import ComponentProps from 'store/types/ComponentProps';

import styles from './PageCard.module.scss';

interface PageCardProps extends ComponentProps {
  title?: React.ReactNode;
  action?: React.ReactNode;
  headerClassName?: string;
}

const PageCard: React.FunctionComponent<PageCardProps> = ({
  children,
  title = '',
  action,
  headerClassName = '',
  className = '',
}) => {
  return (
    <Card className={classNames(styles.card, className)} data-testid={'page-card'}>
      <CardHeader
        title={title}
        action={action}
        disableTypography={true}
        className={classNames(styles.cardHeader, headerClassName)}
        classes={{ content: styles.cardTitle, action: styles.cardHeaderAction }}
        data-testid={'page-card-header'}
      />
      <CardContent className={styles.cardContent}>{children}</CardContent>
    </Card>
  );
};
export default PageCard;
