import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { Alert } from '@mui/material';
import classNames from 'classnames';
import Modal from 'components/shared/Modal';
import Table from 'components/shared/Table';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, Reducer, useCallback, useEffect, useReducer } from 'react';
import PaymentService, { UpdateAccessLevelData } from 'services/api/PaymentService';
import paymentAccessLevelConfig from 'store/configs/PaymentAccessLevelConfig';
import PaymentUserAccessLevel from 'store/enums/PaymentUserAccessLevel';
import PaymentUser from 'store/types/PaymentUser';
import { SortConfig, TableColumn } from 'store/types/Table';
import { getEmailLink, getUserFullName } from 'util/Format';
import { defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import useRequest from 'hooks/useRequest';
import { userFullNameSortLabel } from 'util/Table';
import reducer, {
  initialState,
  PaymentAccessTableAction,
  PaymentAccessTableActionType,
  PaymentAccessTableState,
  TablePaymentUser,
} from './PaymentAccessTableReducer';

import commonStyles from 'styles/common.module.scss';
import styles from './PaymentAccessTable.module.scss';

const PaymentAccessTable: React.FunctionComponent = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, loading, error, refetch } = useRequest<PaymentUser[]>(PaymentService.getPaymentAccessList);
  const [{ sort, list = [], rejectModalOpen = false, removeModalOpen = false, submitLoading, selectedItem }, dispatch] =
    useReducer<Reducer<PaymentAccessTableState, PaymentAccessTableAction>>(reducer, initialState);

  useEffect(() => {
    if (data) {
      dispatch({
        type: PaymentAccessTableActionType.SetInitialList,
        payload: { initialList: data },
      });
    }
  }, [data]);

  const handleSortChange = useCallback((newSort: SortConfig) => {
    dispatch({ type: PaymentAccessTableActionType.UpdateSort, payload: { sort: newSort } });
  }, []);

  const confirmRequest = useCallback(
    (userId: PaymentUser['profileId'], confirm: boolean) => {
      const request = confirm ? PaymentService.approvePaymentAccessRequest : PaymentService.rejectPaymentAccessRequest;

      dispatch({
        type: PaymentAccessTableActionType.SetSubmitLoading,
        payload: { submitLoading: true },
      });

      request(userId)
        .then(() => {
          dispatch({
            type: PaymentAccessTableActionType.CloseConfirmationModal,
            payload: {},
          });
          enqueueSnackbar(`Request successfully ${confirm ? 'approved' : 'rejected'}`, { variant: 'success' });
          refetch();
        })
        .catch((errorMessage: string) => {
          dispatch({
            type: PaymentAccessTableActionType.SetSubmitLoading,
            payload: { submitLoading: false },
          });
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        });
    },
    [enqueueSnackbar, refetch]
  );

  const updateAccessLevel = useCallback(
    (payerId: PaymentUser['profileId'], newAccessLevel: PaymentUserAccessLevel = PaymentUserAccessLevel.None) => {
      const accessData: UpdateAccessLevelData[] = [{ payerId, accessLevel: newAccessLevel }];

      dispatch({
        type: PaymentAccessTableActionType.SetSubmitLoading,
        payload: { submitLoading: true },
      });
      PaymentService.updatePaymentAccess(accessData)
        .then(() => {
          dispatch({
            type: PaymentAccessTableActionType.CloseConfirmationModal,
            payload: {},
          });
          enqueueSnackbar(`Access level successfully updated`, {
            variant: 'success',
          });
          refetch();
        })
        .catch((errorMessage: string) => {
          dispatch({
            type: PaymentAccessTableActionType.SetSubmitLoading,
            payload: { submitLoading: false },
          });
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        });
    },
    [enqueueSnackbar, refetch]
  );

  const removeAccessLevel = useCallback(
    (payerId: PaymentUser['profileId']) => {
      dispatch({
        type: PaymentAccessTableActionType.SetSubmitLoading,
        payload: { submitLoading: true },
      });
      PaymentService.removePaymentAccess(payerId)
        .then(() => {
          dispatch({
            type: PaymentAccessTableActionType.CloseConfirmationModal,
            payload: {},
          });
          enqueueSnackbar(`Access level successfully removed`, {
            variant: 'success',
          });
          refetch();
        })
        .catch((errorMessage: string) => {
          dispatch({
            type: PaymentAccessTableActionType.SetSubmitLoading,
            payload: { submitLoading: false },
          });
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        });
    },
    [enqueueSnackbar, refetch]
  );

  const handleRejectButtonClick = useCallback(
    (item: PaymentUser) => () => {
      dispatch({
        type: PaymentAccessTableActionType.OpenConfirmationModal,
        payload: { rejectModalOpen: true, selectedItem: item },
      });
    },
    []
  );

  const handleRemoveButtonClick = useCallback(
    (item: PaymentUser) => () => {
      dispatch({
        type: PaymentAccessTableActionType.OpenConfirmationModal,
        payload: { removeModalOpen: true, selectedItem: item },
      });
    },
    []
  );

  const handleApproveButtonClick = useCallback(
    ({ profileId }: PaymentUser) =>
      () => {
        confirmRequest(profileId, true);
      },
    [confirmRequest]
  );

  const handleConfirmationModalClose = useCallback(() => {
    dispatch({ type: PaymentAccessTableActionType.CloseConfirmationModal, payload: {} });
  }, []);

  const handleAccessLevelChange = useCallback(
    ({ profileId }: PaymentUser) =>
      (e: ChangeEvent<HTMLInputElement>) => {
        updateAccessLevel(profileId, e.target.value as PaymentUserAccessLevel);
      },
    [updateAccessLevel]
  );

  const handleRejectRequest = useCallback(() => {
    if (selectedItem) {
      confirmRequest(selectedItem.profileId, false);
    }
  }, [selectedItem, confirmRequest]);

  const handleRemoveAccess = useCallback(() => {
    if (selectedItem) {
      removeAccessLevel(selectedItem.profileId);
    }
  }, [selectedItem, removeAccessLevel]);

  const columns: Array<TableColumn<TablePaymentUser>> = [
    {
      dataIndex: userFullNameSortLabel,
      label: 'Name',
      sortable: true,
      render: (_: any, record: PaymentUser) => getUserFullName(record),
    },
    {
      dataIndex: 'email',
      label: 'Email',
      sortable: true,
      render: (email: string) => getEmailLink(email),
    },
    {
      dataIndex: 'accessLevel',
      label: 'Manage Access Level',
      render: (accessLevel: PaymentUserAccessLevel, record: PaymentUser) => (
        <TextField
          value={accessLevel}
          onChange={handleAccessLevelChange(record)}
          select={true}
          label={'Requested Access Level'}
          disabled={!record.isApproved}
        >
          {paymentAccessLevelConfig.map(({ value, text }) => (
            <MenuItem value={value} key={`accessLevel-${value}`}>
              {text}
            </MenuItem>
          ))}
        </TextField>
      ),
    },
    {
      key: 'action',
      label: 'Action',
      align: 'center',
      render: (_: any, record: PaymentUser) =>
        record.isApproved ? (
          <Button
            size={'small'}
            variant={'outlined'}
            className={classNames(commonStyles.dangerButtonOutlined, styles.button)}
            onClick={handleRemoveButtonClick(record)}
          >
            Remove Access
          </Button>
        ) : (
          <Grid container={true} spacing={2} justifyContent={'center'}>
            <Grid {...defaultGridItemProps} sm={'auto'}>
              <Button
                size={'small'}
                color={'primary'}
                variant={'outlined'}
                fullWidth={true}
                className={styles.button}
                onClick={handleApproveButtonClick(record)}
              >
                Approve
              </Button>
            </Grid>
            <Grid {...defaultGridItemProps} sm={'auto'}>
              <Button
                size={'small'}
                variant={'outlined'}
                fullWidth={true}
                className={classNames(commonStyles.dangerButtonOutlined, styles.button)}
                onClick={handleRejectButtonClick(record)}
              >
                Reject
              </Button>
            </Grid>
          </Grid>
        ),
    },
  ];

  return (
    <>
      {error ? (
        <Alert severity={'error'} className={commonStyles.alert}>
          {error}
        </Alert>
      ) : (
        <Table
          columns={columns}
          list={list}
          sort={sort}
          onSortChange={handleSortChange}
          loading={loading}
          submitLoading={submitLoading}
        />
      )}
      <Modal
        title={rejectModalOpen ? 'Reject Request?' : 'Remove User Access?'}
        open={rejectModalOpen || removeModalOpen}
        maxWidth={'xs'}
        loading={submitLoading}
        actions={
          <>
            <Button
              color={'primary'}
              variant={'outlined'}
              onClick={handleConfirmationModalClose}
              disabled={submitLoading}
            >
              Cancel
            </Button>
            <Button
              className={commonStyles.dangerButtonContained}
              variant={'contained'}
              type={'submit'}
              disabled={submitLoading}
              onClick={rejectModalOpen ? handleRejectRequest : handleRemoveAccess}
            >
              {rejectModalOpen ? 'Reject' : 'Remove Access'}
            </Button>
          </>
        }
      >
        <p className={commonStyles.text}>This cannot be undone.</p>
      </Modal>
    </>
  );
};
export default PaymentAccessTable;
