import { Button, Checkbox, Grid } from '@mui/material';
import Add from '@mui/icons-material/Add';
import { Alert } from '@mui/material';
import Table from 'components/shared/Table';
import React, { ChangeEvent, Reducer, useCallback, useEffect, useMemo, useReducer } from 'react';
import PaymentUser, { PaymentUserIdList } from 'store/types/PaymentUser';
import { SortConfig, TableColumn } from 'store/types/Table';
import { getEmailLink, getUserFullName } from 'util/Format';
import PaymentUserAccessLevel from 'store/enums/PaymentUserAccessLevel';
import UseRequestData from 'store/types/UseRequestData';
import SearchInput from 'components/shared/SearchInput';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import LinkPaymentUserModal from 'components/payments/LinkPaymentUserModal';
import { userFullNameSortLabel } from 'util/Table';
import TableCountLabel from 'components/shared/TableCountLabel';
import reducer, {
  initialState,
  PaymentUsersTableAction,
  PaymentUsersTableActionType,
  PaymentUsersTableState,
  TablePaymentUser,
} from './PaymentUsersTableReducer';

import commonStyles from 'styles/common.module.scss';

interface PaymentUsersTableProps extends UseRequestData<PaymentUser[]> {
  selectedIds: PaymentUserIdList;
  onIdsSelected: (selectedIds: PaymentUserIdList) => void;
}

const getSelectedIds = (initialArray: PaymentUserIdList = [], selectedItem: PaymentUser): PaymentUserIdList => {
  let result = [...initialArray];
  const foundItemIndex: number = initialArray.indexOf(selectedItem.profileId);

  if (foundItemIndex >= 0) {
    result.splice(foundItemIndex, 1);
  } else {
    result = [...result, selectedItem.profileId];
  }
  return result;
};

const getAccessLevelLabel = ({ accessLevel, isApproved }: PaymentUser): string => {
  if (!isApproved) {
    return 'Pending';
  } else {
    switch (accessLevel) {
      case PaymentUserAccessLevel.Invoices:
        return 'Invoices Only';
      case PaymentUserAccessLevel.Membership:
        return 'Membership Only';
      case PaymentUserAccessLevel.Full:
        return 'Membership and Invoices';
      default:
        return '';
    }
  }
};

const PaymentUsersTable: React.FunctionComponent<PaymentUsersTableProps> = ({
  onIdsSelected,
  selectedIds = [],
  data = [],
  loading = false,
  error,
  refetch,
}) => {
  const [{ sort, filter, list = [], initialList = [], requestModalOpen = false }, dispatch] = useReducer<
    Reducer<PaymentUsersTableState, PaymentUsersTableAction>
  >(reducer, initialState);
  const availableUserList = useMemo(() => list.filter(({ isApproved }) => !!isApproved), [list]);
  const allItemsSelected = !!list.length && selectedIds.length === availableUserList.length;

  useEffect(() => {
    if (data) {
      dispatch({
        type: PaymentUsersTableActionType.SetInitialList,
        payload: {
          initialList: data.filter(
            ({ accessLevel, isApproved }) => !(isApproved && accessLevel === PaymentUserAccessLevel.None)
          ),
        },
      });
    }
  }, [data]);

  const isRowSelected = useCallback((record: PaymentUser) => selectedIds.indexOf(record.profileId) >= 0, [selectedIds]);

  const handleSortChange = useCallback((newSort: SortConfig) => {
    dispatch({ type: PaymentUsersTableActionType.UpdateSort, payload: { sort: newSort } });
  }, []);

  const handleCheckboxClick = useCallback(
    (selectedItem: PaymentUser) => () => {
      const newSelectedIds: PaymentUserIdList = getSelectedIds(selectedIds, selectedItem);

      onIdsSelected(newSelectedIds);
    },
    [onIdsSelected, selectedIds]
  );

  const handleSelectAllCheckbox = useCallback(() => {
    onIdsSelected(allItemsSelected ? [] : availableUserList.map(({ profileId }) => profileId));
  }, [onIdsSelected, availableUserList, allItemsSelected]);

  const columns: Array<TableColumn<TablePaymentUser>> = [
    {
      key: 'checkbox',
      label: (
        <Checkbox
          color={'primary'}
          checked={allItemsSelected}
          onChange={handleSelectAllCheckbox}
          disabled={!list.length}
        />
      ),
      render: (_: any, record: PaymentUser) => (
        <Checkbox
          color={'primary'}
          checked={isRowSelected(record)}
          onChange={handleCheckboxClick(record)}
          disabled={!record.isApproved}
        />
      ),
    },
    {
      dataIndex: userFullNameSortLabel,
      label: 'Name',
      sortable: true,
      render: (_: any, record: PaymentUser) => getUserFullName(record, true),
    },
    {
      dataIndex: 'email',
      label: 'Email',
      sortable: true,
      render: (email: string) => getEmailLink(email),
    },
    {
      dataIndex: 'accessLevel',
      label: 'Access Level',
      sortable: true,
      render: (_: any, record: PaymentUser) => getAccessLevelLabel(record),
    },
  ];

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: PaymentUsersTableActionType.UpdateFilter,
      payload: { filter: { search: e.target.value } },
    });
  }, []);

  const handleRequestModalSubmit = useCallback(() => {
    dispatch({
      type: PaymentUsersTableActionType.ShowRequestModal,
      payload: {},
    });
    refetch();
  }, [refetch]);

  const handleRequestModalOpen = useCallback(() => {
    dispatch({
      type: PaymentUsersTableActionType.ShowRequestModal,
      payload: { requestModalOpen: true },
    });
  }, []);

  const handleRequestModalClose = useCallback(() => {
    dispatch({
      type: PaymentUsersTableActionType.ShowRequestModal,
      payload: {},
    });
  }, []);

  return (
    <>
      {error ? (
        <Alert severity={'error'} className={commonStyles.alert}>
          {error}
        </Alert>
      ) : (
        <Grid {...defaultGridContainerProps} justifyContent={'space-between'}>
          <Grid {...defaultGridItemProps} sm={7}>
            <SearchInput value={filter.search} onChange={handleSearchChange} loading={loading} />
          </Grid>
          <Grid {...defaultGridItemProps} sm={'auto'}>
            <Button
              color={'secondary'}
              variant={'contained'}
              startIcon={<Add />}
              className={commonStyles.uppercase}
              onClick={handleRequestModalOpen}
              disabled={loading}
            >
              Request New
            </Button>
          </Grid>
          <Grid {...defaultGridItemProps}>
            <Grid container={true} spacing={2}>
              <Grid {...defaultGridItemProps}>
                <TableCountLabel viewCount={list.length} totalCount={initialList.length} loading={loading} />
              </Grid>
              <Grid {...defaultGridItemProps}>
                <Table
                  columns={columns}
                  list={list}
                  sort={sort}
                  onSortChange={handleSortChange}
                  rowProps={{ selected: isRowSelected }}
                  loading={loading}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <LinkPaymentUserModal
        onSubmit={handleRequestModalSubmit}
        open={requestModalOpen}
        onClose={handleRequestModalClose}
      />
    </>
  );
};
export default PaymentUsersTable;
