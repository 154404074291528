import classNames from 'classnames';
import React from 'react';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import ComponentProps from 'store/types/ComponentProps';
import { MainEventView } from 'store/types/events/Event';
import { getFormattedEventDate } from 'util/Event';
import { MainEventRegistrationView } from 'store/types/events/EventRegistration';

import rowStyles from '../EventInfoRow.module.scss';

interface EventCalendarIconProps extends ComponentProps {
  data: MainEventView | MainEventRegistrationView;
}

const EventDateRow: React.FunctionComponent<EventCalendarIconProps> = ({
  data: { startDate, endDate },
  className = '',
}) => (
  <div className={classNames(rowStyles.row, className)}>
    <CalendarMonth className={rowStyles.icon} />
    {getFormattedEventDate(startDate, endDate)}
  </div>
);

export default EventDateRow;
