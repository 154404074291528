import Pagination from '@mui/material/Pagination';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ChapterDetailsCard from 'components/communities/ChapterDetailsCard';
import { useHistory, useLocation } from 'react-router';
import { JoinedChapter } from 'store/types/Community';
import { Button, Grid, Tab, Tabs } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import People from '@mui/icons-material/People';
import classNames from 'classnames';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import CommunityListItem from 'components/communities/CommunityListItem';
import Spinner from 'components/shared/Spinner';
import Card from 'components/shared/Card';
import { preselectedCommunityStatePropName } from 'util/Route';
import { getListByPage } from 'util/Table';
import Avatar from 'components/shared/Avatar';
import { ParametersContext } from 'components/ParametersGuard';

import commonStyles from 'styles/common.module.scss';
import styles from './ChaptersPageView.module.scss';

interface ChaptersPageViewProps {
  data?: JoinedChapter[];
  loading?: boolean;
}

const getUserChapters = (list: JoinedChapter[]): [JoinedChapter[], JoinedChapter[]] => {
  const activeChapters: JoinedChapter[] = [];
  const inactiveChapters: JoinedChapter[] = [];

  list.forEach((item) => {
    const { member } = item;

    if (member.isActive) {
      activeChapters.push(item);
    } else {
      inactiveChapters.push(item);
    }
  });
  return [activeChapters, inactiveChapters];
};

const emptySection = (
  <div className={styles.noData}>
    <span>No chapters to display</span>
  </div>
);
const itemsPerPage = 6;

const ChaptersPageView: React.FunctionComponent<ChaptersPageViewProps> = ({ data = [], loading = false }) => {
  const history = useHistory();
  const { state, pathname } = useLocation<{ [preselectedCommunityStatePropName]?: string }>();
  const [activeTab, setActiveTab] = useState<string>('1');
  const [selectedId, setSelectedId] = useState<string>('');
  const {
    chapters: { currentChaptersTabHeader, pastChaptersTabHeader },
  } = useContext(ParametersContext);
  const [activeChapters, inactiveChapters] = useMemo(() => getUserChapters(data), [data]);
  const [page, setPage] = useState<number>(1);
  const pagesCount = useMemo(() => Math.ceil(activeChapters.length / itemsPerPage), [activeChapters.length]);
  const pageList = useMemo(() => getListByPage(activeChapters, itemsPerPage, page), [activeChapters, page]);
  const selectedChapter: JoinedChapter | undefined = useMemo(
    () => activeChapters.find(({ id }) => id === selectedId),
    [selectedId, activeChapters]
  );

  useEffect(() => {
    if (state && state[preselectedCommunityStatePropName]) {
      setSelectedId(state[preselectedCommunityStatePropName] || '');
      history.replace({ pathname, state: null });
    } else if (!selectedId && activeChapters.length) {
      setSelectedId(activeChapters[0].id);
    }
  }, [selectedId, activeChapters, state, pathname, history]);

  const handleTabChange = useCallback((_: any, value = '1') => {
    setActiveTab(value);
  }, []);

  const handleChapterSelected = useCallback(
    (id: string) => () => {
      setSelectedId(id);
    },
    []
  );

  const handlePageChange = useCallback((_: any, newPage: number) => {
    setPage(newPage);
  }, []);

  return (
    <Grid {...defaultGridContainerProps}>
      <Grid {...defaultGridItemProps}>
        <Card contentClassName={styles.content}>
          <TabContext value={activeTab}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              indicatorColor={'primary'}
              textColor={'primary'}
              variant={'scrollable'}
            >
              <Tab value={'1'} label={'Current Chapters'} className={commonStyles.tab} />
              <Tab value={'2'} label={'Past Chapters'} className={commonStyles.tab} />
            </Tabs>
            <TabPanel value={'1'} className={classNames(commonStyles.tabPanel, styles.container)}>
              <Spinner loading={loading} transparent={false}>
                {currentChaptersTabHeader && <p dangerouslySetInnerHTML={{ __html: currentChaptersTabHeader }} />}
                <Grid {...defaultGridContainerProps}>
                  {activeChapters.length ? (
                    <>
                      {pageList.map(({ logoUrl, name, id }: JoinedChapter) => {
                        const chapterSelected = id === selectedId;
                        return (
                          <Grid {...defaultGridItemProps} key={id} sm={6} md={4}>
                            <Button
                              color={chapterSelected ? 'inherit' : 'primary'}
                              variant={chapterSelected ? 'contained' : 'outlined'}
                              fullWidth={true}
                              onClick={handleChapterSelected(id)}
                              className={styles.button}
                              startIcon={
                                <Avatar
                                  src={logoUrl}
                                  icon={<People className={styles.avatarIcon} />}
                                  className={styles.avatar}
                                />
                              }
                            >
                              {name}
                            </Button>
                          </Grid>
                        );
                      })}
                      <Grid {...defaultGridItemProps} className={styles.paginationContainer}>
                        <Pagination
                          showFirstButton={true}
                          showLastButton={true}
                          count={pagesCount}
                          page={page}
                          onChange={handlePageChange}
                        />
                      </Grid>
                    </>
                  ) : (
                    emptySection
                  )}
                </Grid>
              </Spinner>
            </TabPanel>
            <TabPanel value={'2'} className={classNames(commonStyles.tabPanel, styles.container)}>
              <Spinner loading={loading} transparent={false}>
                {pastChaptersTabHeader && <p dangerouslySetInnerHTML={{ __html: pastChaptersTabHeader }} />}
                <Grid {...defaultGridContainerProps}>
                  {inactiveChapters.length
                    ? inactiveChapters.map((item: JoinedChapter) => (
                        <Grid {...defaultGridItemProps} key={item.id} md={6}>
                          <CommunityListItem item={item} />
                        </Grid>
                      ))
                    : emptySection}
                </Grid>
              </Spinner>
            </TabPanel>
          </TabContext>
        </Card>
      </Grid>
      {selectedChapter && (
        <Grid {...defaultGridItemProps} className={classNames({ [commonStyles.hidden]: activeTab !== '1' })}>
          <ChapterDetailsCard data={selectedChapter} />
        </Grid>
      )}
    </Grid>
  );
};
export default ChaptersPageView;
