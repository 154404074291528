import { Button, Grid } from '@mui/material';
import Add from '@mui/icons-material/Add';
import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import useRequest from 'hooks/useRequest';
import Alert from '@mui/material/Alert';
import { FilterConfig } from 'store/types/Table';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import SearchInput from 'components/shared/SearchInput';
import ApplicationsService from 'services/api/ApplicationsService';
import routes from 'store/configs/Routes';
import Application from 'store/types/Application';
import { getHashRouteUrl } from 'util/Route';
import UserApplicationsTable from 'components/applications/UserApplicationsTable';
import { ParametersContext } from 'components/ParametersGuard';
import { getFormattedApplicationStatus } from 'util/Application';

import commonStyles from 'styles/common.module.scss';
import styles from './UserApplicationsPageView.module.scss';

const UserApplicationsPageView: React.FunctionComponent = () => {
  const [applications, setApplications] = useState<Application[]>([]);
  const { data, loading, error, refetch } = useRequest<Application[]>(ApplicationsService.getUserApplications);
  const {
    applications: { submittedApplicationsTableHeader, pendingActionsTableHeader },
  } = useContext(ParametersContext);
  const [filter, setFilter] = useState<FilterConfig<Application>>({ search: '' });

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setFilter((prevState) => ({ ...prevState, search: e.target.value }));
  }, []);

  useEffect(() => {
    if (data) {
      setApplications(
        data.map((application) => ({ ...application, status: getFormattedApplicationStatus(application.status) }))
      );
    }
  }, [data]);

  return error ? (
    <Alert severity={'error'} className={commonStyles.alert}>
      {error}
    </Alert>
  ) : (
    <>
      <Grid {...defaultGridContainerProps} justifyContent={'space-between'}>
        <Grid {...defaultGridItemProps} sm={'auto'}>
          <Button
            color={'secondary'}
            variant={'contained'}
            startIcon={<Add />}
            className={commonStyles.uppercase}
            href={getHashRouteUrl(routes.createApplication)}
          >
            {'New Application'}
          </Button>
        </Grid>
        <Grid {...defaultGridItemProps} sm={7}>
          <SearchInput value={filter.search} onChange={handleSearchChange} loading={loading} />
        </Grid>
        <Grid {...defaultGridItemProps}>
          <h2 className={styles.tableTitle}>{'Pending Actions'}</h2>
          {pendingActionsTableHeader && <p dangerouslySetInnerHTML={{ __html: pendingActionsTableHeader }} />}
          <UserApplicationsTable
            data={applications}
            loading={loading}
            filter={filter}
            openApplications={true}
            refetch={refetch}
          />
        </Grid>
        <Grid {...defaultGridItemProps}>
          <h2 className={styles.tableTitle}>{'Submitted Applications'}</h2>
          {submittedApplicationsTableHeader && (
            <p dangerouslySetInnerHTML={{ __html: submittedApplicationsTableHeader }} />
          )}
          <UserApplicationsTable data={applications} loading={loading} filter={filter} refetch={refetch} />
        </Grid>
      </Grid>
    </>
  );
};
export default UserApplicationsPageView;
