import { Avatar, Grid } from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import React, { useCallback, useEffect, useState } from 'react';
import Spinner from 'components/shared/Spinner';
import Alert from '@mui/material/Alert';
import Donation from 'store/types/Donation';
import UseRequestData from 'store/types/UseRequestData';
import DonationPriceForm from 'components/donation/DonationPriceForm';
import { PaymentItemsFormValues } from 'store/types/FormValues';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';

import styles from './SubscriptionDonationSection.module.scss';
import commonStyles from 'styles/common.module.scss';
import DonationCampaignStatus from '../../../store/enums/DonationCampaignStatus';

interface SubscriptionDonationSectionProps extends UseRequestData<Donation[]> {
  value: PaymentItemsFormValues;
  onChange: (value: PaymentItemsFormValues) => void;
}

const SubscriptionDonationSection: React.FunctionComponent<SubscriptionDonationSectionProps> = ({
  data,
  loading,
  error,
  value,
  onChange,
}) => {
  const [list, setList] = useState<Donation[]>([]);

  useEffect(() => {
    if (data && data.length) {
      const newList = data.filter(({ status }) => status === DonationCampaignStatus.Active);
      const defaultValue: PaymentItemsFormValues = {};

      newList.forEach(({ id, name }: Donation) => {
        defaultValue[id] = { name, price: undefined };
      });
      onChange(defaultValue);
      setList(newList);
    }
  }, [data, onChange]);

  const handlePriceChange = useCallback(
    ({ id, name }: Donation) =>
      (price: number) => {
        onChange({ ...value, [id]: { name, price } });
      },
    [onChange, value]
  );

  return (
    <Grid {...defaultGridContainerProps}>
      <Grid {...defaultGridItemProps}>
        <h3 className={commonStyles.subTitle}>{'Donation'}</h3>
        <p className={commonStyles.text}>{'Would you like to donate to our Foundations?'}</p>
      </Grid>
      <Grid {...defaultGridItemProps}>
        <Spinner loading={loading}>
          {error ? (
            <Alert severity={'error'} className={commonStyles.alert}>
              {error}
            </Alert>
          ) : (
            <Grid {...defaultGridContainerProps}>
              {list?.length ? (
                list.map((donation: Donation) => {
                  const { id, name, logoUrl, isOtherDonationAmountAvailable } = donation;

                  return (
                    <Grid
                      {...defaultGridItemProps}
                      md={6}
                      lg={list.length < 3 ? 5 : 4}
                      key={`donation-${id}`}
                      className={styles.donationItem}
                    >
                      <Avatar src={logoUrl} variant={'square'} className={styles.logo}>
                        <AccountBalanceIcon className={styles.logoIcon} />
                      </Avatar>
                      <h4>{name}</h4>
                      <DonationPriceForm
                        value={value[id] ? value[id].price : undefined}
                        onChange={handlePriceChange(donation)}
                        showZeroPrice={true}
                        className={styles.donationPriceSection}
                        isOtherDonationAmountAvailable={isOtherDonationAmountAvailable}
                      />
                    </Grid>
                  );
                })
              ) : (
                <Grid {...defaultGridItemProps}>
                  <p className={commonStyles.text}>{'No donations found'}</p>
                </Grid>
              )}
            </Grid>
          )}
        </Spinner>
      </Grid>
    </Grid>
  );
};
export default SubscriptionDonationSection;
