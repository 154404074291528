import React, { useCallback, useEffect, useState } from 'react';
import Membership, { CurrentMembership } from 'store/types/Membership';
import MembershipService from 'services/api/MembershipService';
import SubscriptionStatus from 'store/enums/SubscriptionStatus';
import useRequest from 'hooks/useRequest';
import { SubscriptionPlan } from 'store/types/Subscription';
import Spinner from 'components/shared/Spinner';
import routes from 'store/configs/Routes';
import { useLocation } from 'react-router';

const MEMBERSHIP_POLLING_INTERVAL = 10000;

export const MembershipContext = React.createContext<CurrentMembership>({} as CurrentMembership);

const MembershipGuard: React.FunctionComponent = ({ children }) => {
  const { pathname } = useLocation();
  const {
    data: membershipData,
    loading: membershipLoading,
    firstLoading: membershipFirstLoading,
    refetch: membershipRefetch,
    startPolling: membershipStartPolling,
    stopPolling: membershipStopPolling,
    polling: membershipPolling,
  } = useRequest<Membership>(pathname.includes(routes.redirect) ? undefined : MembershipService.getMembership);
  const {
    data: renewalData,
    loading: renewalLoading,
    firstLoading: renewalFirstLoading,
    refetch: renewalRefetch,
  } = useRequest<SubscriptionPlan>(undefined);
  const [membership, setMembership] = useState<Membership>({} as CurrentMembership);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(membershipLoading || renewalLoading);
  }, [membershipLoading, renewalLoading, loading]);

  useEffect(() => {
    if (renewalData) {
      setMembership((prevState) => ({ ...prevState, renewalPlan: renewalData }));
    }
  }, [renewalData]);

  useEffect(() => {
    const data = membershipData;

    if (data) {
      setMembership((prevState) => ({ ...prevState, ...data }));
    }
  }, [membershipData]);

  useEffect(() => {
    if (membership && membership.status === SubscriptionStatus.Pending && !membershipPolling) {
      membershipStartPolling(MEMBERSHIP_POLLING_INTERVAL);
    } else if (membershipPolling && membership && membership.status !== SubscriptionStatus.Pending) {
      membershipStopPolling();
    }
  }, [membership, membershipStartPolling, membershipStopPolling, membershipPolling]);

  const setCurrentMembership = useCallback((newValue: Partial<Membership>) => {
    setMembership((prevState) => ({
      ...prevState,
      ...newValue,
    }));
  }, []);

  const refetchMembership = useCallback(() => {
    membershipRefetch();
    renewalRefetch();
  }, [membershipRefetch, renewalRefetch]);

  return membershipFirstLoading || renewalFirstLoading ? (
    <Spinner loading={true} fullPage={true} transparent={false} />
  ) : (
    <MembershipContext.Provider
      value={{ ...membership, setCurrentMembership, refetchMembership, membershipLoading: loading }}
    >
      {children}
    </MembershipContext.Provider>
  );
};
export default MembershipGuard;
