import React from 'react';
import SubscriptionService from 'services/api/SubscriptionService';
import useRequest from 'hooks/useRequest';
import Donation from 'store/types/Donation';
import { PaymentItemsFormValues } from 'store/types/FormValues';
import SubscriptionDonationSection from 'components/subscriptions/SubscriptionDonationSection';

interface SubscriptionPlansSectionProps {
  value: PaymentItemsFormValues;
  onChange: (value: PaymentItemsFormValues) => void;
}

const SubscriptionDonationStep: React.FunctionComponent<SubscriptionPlansSectionProps> = ({ value, onChange }) => {
  const donationsRequest = useRequest<Donation[]>(SubscriptionService.getDonations);

  return <SubscriptionDonationSection {...donationsRequest} value={value} onChange={onChange} />;
};
export default SubscriptionDonationStep;
