import React, { useCallback } from 'react';
import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { FormProps, getRequiredInputMessage, getValidationProps } from 'util/Form';

interface DonationMessageFormItemProps extends FormProps {
  optional?: boolean;
  disabled?: boolean;
}

const FIELD_NAME = 'message';

const DonationMessageFormItem: React.FunctionComponent<DonationMessageFormItemProps> = ({
  optional = false,
  disabled = false,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const validationProps = !disabled ? getValidationProps(FIELD_NAME, errors) : {};

  const validateField = useCallback(
    (value: string) => {
      if (disabled) {
        return true;
      }
      return !!value || optional || getRequiredInputMessage('your message');
    },
    [disabled, optional]
  );

  return (
    <Controller
      render={({ field }) => (
        <TextField
          {...field}
          {...validationProps}
          label={'Your Message'}
          rows={4}
          multiline={true}
          required={!optional}
          disabled={disabled}
        />
      )}
      name={FIELD_NAME}
      control={control}
      rules={{ validate: validateField }}
    />
  );
};
export default DonationMessageFormItem;
