import { Button, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { ProductCartContext } from 'components/ProductCartGuard';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import { getPrice } from 'util/Payment';
import Card from 'components/shared/Card';
import ProductsService from 'services/api/ProductsService';
import { getProductPriceLevel } from 'util/Product';
import { UserContext } from 'components/UserGuard';
import { SelectedProduct } from 'store/types/Products';

import commonStyles from 'styles/common.module.scss';
import styles from './ProductsCartListItem.module.scss';

interface ProductsCartListItemProps {
  data: SelectedProduct;
  showButtons?: boolean;
}

const quantityOptions = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10+' },
];

const ProductsCartListItem: React.FunctionComponent<ProductsCartListItemProps> = ({ data, showButtons = true }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { refetchCart } = useContext(ProductCartContext);
  const { priceLevelId } = useContext(UserContext);
  const { id, title, prices, quantity } = data;
  const price: number = useMemo(() => getProductPriceLevel(prices, priceLevelId, false), [priceLevelId, prices]);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [quantityInput, setQuantityInput] = useState<string>(quantity.toString());

  useEffect(() => {
    setQuantityInput(quantity.toString());
  }, [quantity]);

  const handleRemoveClick = useCallback(() => {
    setSubmitLoading(true);
    ProductsService.removeFromCart(id)
      .then(() => {
        setSubmitLoading(false);
        refetchCart();
      })
      .catch((errorMessage: string) => {
        setSubmitLoading(false);
        enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
      });
  }, [id, refetchCart, enqueueSnackbar]);

  const handleQuantityInputChange = useCallback(
    (onChange) => (e: ChangeEvent<HTMLInputElement>) => {
      onChange(parseInt(e.target.value) || '');
    },
    []
  );

  const updateQuantity = useCallback(() => {
    setSubmitLoading(true);
    const newQuantity = parseInt(quantityInput) || 0;
    if (newQuantity <= 0) {
      ProductsService.removeFromCart(id)
        .then(() => {
          setSubmitLoading(false);
          refetchCart();
        })
        .catch((errorMessage: string) => {
          setSubmitLoading(false);
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        });
    } else {
      ProductsService.addToCart([{ productId: id, quantity: newQuantity }])
        .then(() => {
          setSubmitLoading(false);
          refetchCart();
        })
        .catch((errorMessage: string) => {
          setSubmitLoading(false);
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        });
    }
  }, [id, quantityInput, refetchCart, enqueueSnackbar]);

  const handleQuantitySelectChange = useCallback(
    (onChange) => (e: SelectChangeEvent) => {
      const newValue = parseInt(e.target.value);
      if (newValue > 0) {
        setSubmitLoading(true);
        onChange(newValue);
        ProductsService.addToCart([{ productId: id, quantity: newValue }])
          .then(() => {
            setSubmitLoading(false);
            refetchCart();
          })
          .catch((errorMessage: string) => {
            setSubmitLoading(false);
            enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
          });
      }
    },
    [enqueueSnackbar, id, refetchCart]
  );

  return (
    <>
      <Card className={styles.card} contentClassName={styles.cardContent} loading={submitLoading}>
        <Grid {...defaultGridContainerProps} justifyContent={'space-between'}>
          <Grid {...defaultGridItemProps} md={9}>
            <h3 className={styles.title}>{title}</h3>
          </Grid>
          <Grid {...defaultGridItemProps} md={3} className={styles.rightSection}>
            <Grid {...defaultGridContainerProps}>
              <Grid {...defaultGridItemProps} md={6} className={styles.priceCol}>
                <span className={styles.price}>{getPrice(quantity * price)}</span>
              </Grid>
              {showButtons && (
                <Grid {...defaultGridItemProps} md={6}>
                  <Button
                    size={'small'}
                    variant={'outlined'}
                    className={commonStyles.dangerButtonOutlined}
                    fullWidth={true}
                    onClick={handleRemoveClick}
                  >
                    {'Remove'}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid {...defaultGridItemProps} md={6}>
            <Grid {...defaultGridContainerProps}>
              {quantity >= 10 ? (
                <>
                  <Grid {...defaultGridItemProps} md={4}>
                    <TextField
                      size={'small'}
                      type={'number'}
                      InputProps={{
                        inputProps: {
                          max: 100,
                          min: 1,
                        },
                      }}
                      label={'Quantity'}
                      onChange={handleQuantityInputChange(setQuantityInput)}
                      value={quantityInput}
                      disabled={!showButtons}
                    />
                  </Grid>
                  {showButtons && (
                    <Grid {...defaultGridItemProps} md={3}>
                      <Button
                        variant={'contained'}
                        size={'small'}
                        color={'primary'}
                        fullWidth={true}
                        className={styles.addGuestButton}
                        onClick={updateQuantity}
                        disabled={!quantityInput}
                      >
                        {'Update'}
                      </Button>
                    </Grid>
                  )}
                </>
              ) : (
                <Grid {...defaultGridItemProps} md={4}>
                  <FormControl fullWidth disabled={!showButtons}>
                    <InputLabel id={`${id}-quantity-select-label`}>Quantity</InputLabel>
                    <Select
                      size={'small'}
                      labelId={`${id}-quantity-select-label`}
                      value={`${quantity}`}
                      label="Quantity"
                      onChange={handleQuantitySelectChange(setQuantityInput)}
                    >
                      {quantityOptions.map(({ value, label }, index) => (
                        <MenuItem key={`quantity-option-${id}-${index}`} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid {...defaultGridItemProps} md={`auto`}>
                <span className={styles.price}>{`${getPrice(price)}`}</span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};
export default ProductsCartListItem;
