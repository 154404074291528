import React, { SyntheticEvent, useCallback, useState } from 'react';
import { DropzoneArea } from 'mui-file-dropzone';
import { Button } from '@mui/material';
import Modal from 'components/shared/Modal';
import { ModalProps } from 'store/types/ComponentProps';

import styles from './UploadImageModal.module.scss';

interface UploadImageModalProps extends ModalProps {
  loading?: boolean;
  onSubmit: (files: File[]) => void;
}

const UploadImageModal: React.FunctionComponent<UploadImageModalProps> = ({
  open,
  onClose,
  onSubmit,
  loading = false,
}) => {
  const [files, setFiles] = useState<File[]>([]);

  const handleChange = useCallback((newFiles: File[]) => {
    setFiles(newFiles);
  }, []);

  const handleSubmit = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      onSubmit(files);
    },
    [onSubmit, files]
  );

  const getErrorMessage = useCallback(() => {
    return 'File type not supported. File is too big. Size limit is 2 megabytes.';
  }, []);

  return (
    <Modal
      open={open}
      onClose={onClose}
      loading={loading}
      title={'Upload Image'}
      actions={
        <>
          <Button onClick={onClose} disabled={loading} data-testid={'modal-close-button'}>
            Cancel
          </Button>
          <Button
            color={'secondary'}
            variant={'contained'}
            type={'submit'}
            disabled={loading || !files.length}
            onClick={handleSubmit}
            data-testid={'modal-submit-button'}
          >
            Save
          </Button>
        </>
      }
    >
      <div className={styles.content}>
        <div className={styles.dropzoneWrapper}>
          <DropzoneArea
            acceptedFiles={['.jpg', '.png', '.jpeg']}
            maxFileSize={2000000}
            filesLimit={1}
            fileObjects={undefined}
            onDelete={undefined}
            clearOnUnmount={true}
            showPreviews={true}
            getDropRejectMessage={getErrorMessage}
            getFileLimitExceedMessage={getErrorMessage}
            showPreviewsInDropzone={false}
            showFileNamesInPreview={false}
            previewText={''}
            onChange={handleChange}
            classes={{
              root: styles.dropzone,
              text: styles.dropzoneText,
            }}
            previewGridProps={{
              container: { spacing: 0 },
              item: { xs: 12 },
            }}
            previewGridClasses={{
              container: styles.previewContainer,
              item: styles.previewImageContainer,
            }}
            alertSnackbarProps={{
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
            }}
            inputProps={
              {
                ['data-testid']: 'upload-input',
              } as any
            }
          />
        </div>
      </div>
    </Modal>
  );
};
export default UploadImageModal;
