import { Divider, Link } from '@mui/material';
import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';
import classNames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import PageCard from 'components/shared/PageCard';
import NewsService from 'services/api/NewsService';
import Announcement from 'store/types/Announcement';
import useRequest from 'hooks/useRequest';
import AnnouncementListItem from 'components/home/AnnouncementListItem';
import AnnouncementsModal from 'components/home/AnnouncementsModal';
import CountBadge from 'components/shared/CountBadge';

import pageStyles from '../HomePage.module.scss';

const HomeAnnouncementsCard: React.FunctionComponent = () => {
  const { data, loading, error } = useRequest<Announcement[]>(NewsService.getAnnouncements);
  const [listModalOpen, setListModalOpen] = useState<boolean>(false);
  const announcements: Announcement[] = useMemo(() => (data && data.length ? data : []), [data]);

  const handleViewAllClick = useCallback(() => {
    setListModalOpen(true);
  }, []);

  const handleListModalClose = useCallback(() => {
    setListModalOpen(false);
  }, []);

  return (
    <>
      <PageCard
        title={
          <>
            <p>Announcements</p>
            {!!announcements.length && <CountBadge>{announcements.length}</CountBadge>}
          </>
        }
        action={
          !!announcements.length && (
            <Link
              className={classNames(pageStyles.viewAllButton, { [pageStyles.disabled]: loading })}
              onClick={handleViewAllClick}
            >
              {'View all'}
            </Link>
          )
        }
      >
        {loading ? (
          <>
            <Skeleton width={50} variant={'text'} />
            <Skeleton width={'100%'} height={40} variant={'text'} />
            <Skeleton width={'100%'} variant={'text'} />
          </>
        ) : error ? (
          <Alert severity={'error'}>{error}</Alert>
        ) : (
          <div className={pageStyles.cardContent}>
            {!announcements.length ? (
              <p>{'No new announcements yet.'}</p>
            ) : (
              announcements.slice(0, 2).map((announcement: Announcement, index: number, arr: Announcement[]) => (
                <React.Fragment key={`home-announcement-${announcement.id}`}>
                  <AnnouncementListItem announcement={announcement} truncateText={true} />
                  {index < arr.length - 1 && <Divider className={pageStyles.divider} />}
                </React.Fragment>
              ))
            )}
          </div>
        )}
      </PageCard>
      <AnnouncementsModal list={announcements} open={listModalOpen} onClose={handleListModalClose} />
    </>
  );
};
export default HomeAnnouncementsCard;
