import classNames from 'classnames';
import Header from 'components/layout/Header';
import Sidebar from 'components/layout/Sidebar';
import React, { useContext } from 'react';
import { useLocation } from 'react-router';
import routes from 'store/configs/Routes';
import { useWindowSize } from 'util/Window';
import { ConfigContext } from 'components/ConfigGuard';
import AppProtectedRoutes from 'components/AppProtectedRoutes';
import { Alert, AlertTitle } from '@mui/material';
import { getLocalStorageValue, IMPERSONATION_FLAG_LS_LABEL } from 'util/LocalStorage';
import SmacnaFooter from 'components/layout/SmacnaFooter';

import styles from './MainPageLayout.module.scss';

const MainPageLayout: React.FunctionComponent = () => {
  const { isDefaultTheme, isNccerBillingApp, isSmacnaTheme, isNccerTheme } = useContext(ConfigContext);
  const { isMobile } = useWindowSize();
  const { pathname } = useLocation();
  const isImpersonation = !!getLocalStorageValue(IMPERSONATION_FLAG_LS_LABEL);

  const header: React.ReactNode = !isDefaultTheme ? (
    <Header collapsedView={!pathname.includes(routes.home) || isNccerTheme} />
  ) : null;

  return (
    <>
      {isImpersonation && (
        <Alert severity={'warning'} className={styles.alert}>
          <AlertTitle>{'You are in impersonation mode.'}</AlertTitle>
          {isNccerBillingApp
            ? 'Close window and switch back to SSO portal to complete impersonation mode'
            : 'Click sign out on the left navigation panel to turn off impersonation mode and switch to your own account'}
        </Alert>
      )}
      {!isMobile && header}
      <div
        className={classNames(styles.container, { [styles.desktopContainer]: !isMobile })}
        data-testid={'main-layout-container'}
        id={'main-page-layout'}
      >
        <Sidebar />
        <div className={styles.page}>
          {isMobile && header}
          <div className={styles.content}>
            <AppProtectedRoutes />
          </div>
        </div>
      </div>
      {isSmacnaTheme && <SmacnaFooter />}
    </>
  );
};
export default MainPageLayout;
