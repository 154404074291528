import { DonationCampaign } from 'store/types/DonationFund';
import DonationCampaignStatus from 'store/enums/DonationCampaignStatus';
import React from 'react';
import StatusLabel from 'components/shared/StatusLabel';
import donationStatusConfig from 'store/configs/DonationStatusConfig';

export const isCampaignParent = ({ allowPersonalizedCampaign, status }: DonationCampaign): boolean =>
  status === DonationCampaignStatus.Active && !!allowPersonalizedCampaign;

export const getDonationStatusLabel = (
  status: DonationCampaignStatus = DonationCampaignStatus.Active
): React.ReactNode => {
  const { theme = 'grey', name = '' } = donationStatusConfig[status];

  return <StatusLabel theme={theme} status={name} />;
};
