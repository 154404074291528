import { Button, CircularProgress, CircularProgressProps, Grid, MenuItem, TextField } from '@mui/material';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import Add from '@mui/icons-material/Add';
import useRequest from 'hooks/useRequest';
import PartnerService from 'services/api/PartnerServices';
import { DonationCampaign } from 'store/types/DonationFund';
import {
  defaultGridContainerProps,
  defaultGridItemProps,
  defaultSnackbarErrorProps,
  getButtonLoadingProps,
} from 'util/Layout';
import { PartnerDetails, PartnerDonor } from 'store/types/Partner';
import Alert from '@mui/material/Alert';
import { getPrice } from 'util/Payment';
import { getDonationStatusLabel } from 'util/Donations';
import classNames from 'classnames';
import Card from 'components/shared/Card';
import PartnerCampaignDetailsTable from './PartnerCampaignDetailsTable/PartnerCampaignDetailsTable';
import Spinner from 'components/shared/Spinner';
import { getStringValue, NUMBER_DATE_FORMAT } from 'util/Format';
import SocialShareButtons from 'components/shared/SocialShareButtons';
import routes from 'store/configs/Routes';
import { useSnackbar } from 'notistack';

import commonStyles from 'styles/common.module.scss';
import styles from './PartnerCampaignDetailsPageView.module.scss';

interface PartnerCampaignDetailsPageViewProps {
  campaigns: DonationCampaign[];
  loading?: boolean;
  selectedCampaignId: string;
  onSelectedCampaignIdChange: (campaignId: string) => void;
  applicationId: string;
  onCreateApplicationSubmit: (applicationId: string) => void;
}

const progressBarProps: CircularProgressProps = {
  variant: 'determinate',
  size: 200,
  thickness: 6,
  color: 'primary',
};

const getPartnerDetails = (data?: PartnerDetails): PartnerDetails => ({
  totalRaised: data?.totalRaised || 0,
  totalDistributed: data?.totalDistributed || 0,
  paidDonations: data?.paidDonations || 0,
  pendingDonations: data?.pendingDonations || 0,
  goalAchieved: data?.goalAchieved || '',
  goalRemaining: data?.goalRemaining || 0,
  fundsAvailableForDistribution: data?.fundsAvailableForDistribution || 0,
});

const PartnerCampaignDetailsPageView: React.FunctionComponent<PartnerCampaignDetailsPageViewProps> = ({
  campaigns,
  loading,
  selectedCampaignId,
  onSelectedCampaignIdChange,
  applicationId,
  onCreateApplicationSubmit,
}) => {
  const [submitLoading, setSubmitLoading] = useState<boolean>();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedCampaign, setSelectedCampaign] = useState<DonationCampaign>();
  const donationSegmentId: string | undefined = useMemo(() => selectedCampaign?.donationSegmentId, [selectedCampaign]);
  const campaignDetailsRequest = useMemo(
    () => (donationSegmentId ? () => PartnerService.getPartnerDetails(donationSegmentId) : undefined),
    [donationSegmentId]
  );
  const partnerDonationsRequest = useMemo(
    () => (donationSegmentId ? () => PartnerService.getPartnerDonations(donationSegmentId) : undefined),
    [donationSegmentId]
  );
  const {
    data: partnerDetailsData,
    error: campaignDetailsError,
    loading: campaignDetailsLoading,
  } = useRequest<PartnerDetails>(campaignDetailsRequest);
  const {
    data: partnerDonationsData = [],
    error: partnerDonationsError,
    loading: partnerDonationsLoading,
  } = useRequest<PartnerDonor[]>(partnerDonationsRequest);
  const {
    goalRemaining,
    goalAchieved,
    pendingDonations,
    totalRaised,
    totalDistributed,
    fundsAvailableForDistribution,
  } = useMemo(() => getPartnerDetails(partnerDetailsData), [partnerDetailsData]);
  const makeDonationLink: string = useMemo(
    () => `${window.location.origin}/#${routes.makeDonation}/${selectedCampaign?.id}`,
    [selectedCampaign]
  );

  useEffect(() => {
    if (campaigns.length) {
      setSelectedCampaign(campaigns[0]);
    }
  }, [campaigns]);

  useEffect(() => {
    if (selectedCampaignId) {
      const selectedCampaign = campaigns.find(({ id }) => id === selectedCampaignId);
      setSelectedCampaign(selectedCampaign);
    }
  }, [campaigns, selectedCampaignId]);

  const handleSelectChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const selectedCampaign = campaigns.find(({ id }) => id === e.target.value);

      if (selectedCampaign) {
        onSelectedCampaignIdChange(selectedCampaign.id);
        setSelectedCampaign(selectedCampaign);
      }
    },
    [campaigns, onSelectedCampaignIdChange]
  );

  const handleAddFundRequest = useCallback(
    (applicationId: string) => () => {
      onCreateApplicationSubmit(applicationId);
    },
    [onCreateApplicationSubmit]
  );

  const handleDownloadDonorList = useCallback(() => {
    setSubmitLoading(true);
    PartnerService.getPartnerDonorLost(selectedCampaignId)
      .then(() => {
        setSubmitLoading(false);
      })
      .catch((errorMessage: string) => {
        setSubmitLoading(false);
        enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
      });
  }, [enqueueSnackbar, selectedCampaignId]);

  return (
    <Spinner loading={loading}>
      <Grid {...defaultGridContainerProps} justifyContent={'flex-end'}>
        <Grid {...defaultGridItemProps}>
          {campaignDetailsError ? (
            <Alert severity={'error'} className={commonStyles.alert}>
              {campaignDetailsError}
            </Alert>
          ) : (
            <>
              <Grid {...defaultGridItemProps} className={styles.socialButtons}>
                <SocialShareButtons shareLink={makeDonationLink} size={'medium'} />
              </Grid>
              <Card contentClassName={styles.cardContent} loading={campaignDetailsLoading}>
                {!campaigns.length ? (
                  <p>{'You have no campaigns'}</p>
                ) : (
                  <Grid {...defaultGridContainerProps} justifyContent={'space-between'}>
                    <Grid {...defaultGridItemProps} lg={4}>
                      <TextField
                        select={true}
                        label={'Parent Campaign'}
                        size={'small'}
                        onChange={handleSelectChange}
                        value={selectedCampaign?.id || ''}
                      >
                        {campaigns.map(({ id, title }: DonationCampaign) => (
                          <MenuItem value={id} key={`partner-${id}`}>
                            {title}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid {...defaultGridItemProps} xs={'auto'}>
                      {getDonationStatusLabel(selectedCampaign?.status)}
                    </Grid>
                    <Grid {...defaultGridItemProps} lg={8}>
                      <div className={styles.nameWrapper}>
                        <h3 className={styles.name}>{selectedCampaign?.title}</h3>
                      </div>
                      <Grid {...defaultGridContainerProps} spacing={1} className={styles.infoWrapper}>
                        <Grid {...defaultGridItemProps}>
                          <span className={styles.label}>{'Goal: '}</span>
                          <span>{getPrice(selectedCampaign?.goal)}</span>
                        </Grid>
                        <Grid {...defaultGridItemProps}>
                          <span className={styles.label}>{'Funds Raised: '}</span>
                          <span>{getPrice(totalRaised)}</span>
                        </Grid>
                        <Grid {...defaultGridItemProps}>
                          <span className={styles.label}>{'Funds Available for Distribution: '}</span>
                          <span>{getPrice(fundsAvailableForDistribution)}</span>
                        </Grid>
                        <Grid {...defaultGridItemProps}>
                          <span className={styles.label}>{'Total Distributed: '}</span>
                          <span>{getPrice(totalDistributed)}</span>
                        </Grid>
                        <Grid {...defaultGridItemProps}>
                          <span className={styles.label}>{'Campaign Start: '}</span>
                          <span>{getStringValue(selectedCampaign?.startDate, NUMBER_DATE_FORMAT)}</span>
                        </Grid>
                        <Grid {...defaultGridItemProps}>
                          <span className={styles.label}>{'Campaign Ends: '}</span>
                          <span>{getStringValue(selectedCampaign?.endDate, NUMBER_DATE_FORMAT)}</span>
                        </Grid>
                      </Grid>
                      {applicationId && (
                        <Button
                          color={'secondary'}
                          variant={'contained'}
                          startIcon={<Add />}
                          className={commonStyles.uppercase}
                          onClick={handleAddFundRequest(applicationId)}
                        >
                          {'New Fund Request'}
                        </Button>
                      )}
                    </Grid>
                    <Grid {...defaultGridItemProps} lg={'auto'} className={styles.wrapper}>
                      <div className={styles.chartWrapper}>
                        <CircularProgress
                          {...progressBarProps}
                          value={100}
                          className={classNames(styles.progress, styles.background)}
                        />
                        <CircularProgress
                          {...progressBarProps}
                          value={parseInt(goalAchieved) || 0}
                          className={classNames(styles.progress, styles.value)}
                        />
                        <div className={styles.label}>
                          <span className={styles.labelCount}>{`${parseInt(goalAchieved) || 0}%`}</span>
                          {`${getPrice(goalRemaining)} remaining to hit goal`}
                        </div>
                      </div>
                      <div className={styles.legendWrapper}>
                        <div className={styles.label}>
                          <span className={styles.labelCount}>{pendingDonations}</span>
                          {'Pending Donations'}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                )}
              </Card>
            </>
          )}
        </Grid>
        {selectedCampaign && (
          <>
            <Grid {...defaultGridItemProps} xs={'auto'}>
              <Button
                {...getButtonLoadingProps(submitLoading)}
                color={'secondary'}
                variant={'contained'}
                startIcon={<Add />}
                className={commonStyles.uppercase}
                onClick={handleDownloadDonorList}
              >
                {'Download Donor List'}
              </Button>
            </Grid>
            <Grid {...defaultGridItemProps}>
              {partnerDonationsError ? (
                <Alert severity={'error'} className={commonStyles.alert}>
                  {partnerDonationsError}
                </Alert>
              ) : (
                <PartnerCampaignDetailsTable
                  partnerDonations={partnerDonationsData}
                  loading={partnerDonationsLoading}
                />
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Spinner>
  );
};

export default PartnerCampaignDetailsPageView;
