import { useLayoutEffect, useState } from 'react';

export const MOBILE_WINDOW_WIDTH = 768;

export const useWindowSize = (): { width: number; height: number; isMobile: boolean } => {
  const [size, setSize] = useState<[number, number]>([window.innerWidth, window.innerHeight]);

  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return {
    width: size[0],
    height: size[1],
    isMobile: size[0] <= MOBILE_WINDOW_WIDTH,
  };
};

export const printDocument = (
  printContentId: string,
  printFrameId: string,
  onClose: () => void,
  margin?: number,
  size = 'auto'
): void => {
  const content = document.getElementById(printContentId) as HTMLElement;
  const printScope = (document.getElementById(printFrameId) as HTMLIFrameElement).contentWindow as WindowProxy;
  const style = `<style>@page { size: ${size}; margin: ${Number(margin) >= 0 ? margin : 30}px; }</style>`;

  printScope.document.open();
  printScope.document.write(content.innerHTML + style);
  printScope.document.close();
  setTimeout(() => {
    printScope.focus();
    printScope.print();
    if (onClose) {
      onClose();
    }
  }, 1000);
};
