import { Checkbox, ListItemText, MenuItem, TextField } from '@mui/material';
import { Alert } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormProps, getRequiredValidationRule, getValidationProps, multipleSelectProps } from 'util/Form';
import useRequest from 'hooks/useRequest';
import { getInputLoadingProps } from 'util/Layout';
import SelectOption from 'store/types/SelectOption';

import commonStyles from 'styles/common.module.scss';
import MemberDirectoryService from 'services/api/MemberDirectoryService';

import styles from './IndustriesFormItem.module.scss';

interface IndustriesFormItemProps extends FormProps {
  required?: boolean;
}

const IndustriesFormItem: React.FunctionComponent<IndustriesFormItemProps> = ({
  fieldName = 'industries',
  required = false,
}) => {
  const { data, loading, error } = useRequest<SelectOption[]>(MemberDirectoryService.getIndustries);
  const [options, setOptions] = useState<SelectOption[]>([]);
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const value = useWatch({ control, name: fieldName });

  useEffect(() => {
    if (data?.length) {
      setOptions(data);
    }
  }, [data, setValue]);

  return error ? (
    <Alert severity={'error'} className={commonStyles.alert}>
      {error}
    </Alert>
  ) : (
    <Controller
      render={({ field }) => (
        <TextField
          {...field}
          {...getValidationProps(fieldName, errors)}
          {...multipleSelectProps(options, 'Select Industries')}
          InputProps={getInputLoadingProps(loading)}
          className={styles.input}
          disabled={loading}
          required={required}
          select={true}
          label={'Industries'}
          InputLabelProps={{
            shrink: true,
          }}
        >
          {options.map(({ id, name }: SelectOption) => (
            <MenuItem value={name} key={`industry-${id}`}>
              <Checkbox checked={value.includes(name)} color={'primary'} size={'small'} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </TextField>
      )}
      name={fieldName}
      control={control}
      rules={{ required: getRequiredValidationRule('industries', true, required) }}
    />
  );
};
export default IndustriesFormItem;
