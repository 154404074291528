import SchoolOutlined from '@mui/icons-material/SchoolOutlined';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { MainEventView } from 'store/types/events/Event';
import ComponentProps from 'store/types/ComponentProps';
import { Tooltip } from '@mui/material';
import { getTotalCredits } from 'util/Event';

import rowStyles from '../EventInfoRow.module.scss';

interface EventCreditsRowProps extends ComponentProps {
  data: MainEventView;
}

const EventCreditsRow: React.FunctionComponent<EventCreditsRowProps> = ({ data, className = '' }) => {
  const totalCredits = useMemo(() => getTotalCredits(data), [data]);
  const creditsAmount: number = useMemo(
    () => totalCredits?.reduce((prevValue, { count }) => count + prevValue, 0) || 0,
    [totalCredits]
  );

  return (
    <Tooltip
      className={classNames(rowStyles.row, className)}
      title={`${totalCredits.map(({ name, count = 0 }) => `${count} ${name}`).join(', ')} Credit(s)`}
      arrow={true}
      placement={'top'}
      hidden={!creditsAmount}
    >
      <span>
        <SchoolOutlined className={rowStyles.icon} />
        {`${creditsAmount} credit(s)`}
      </span>
    </Tooltip>
  );
};
export default EventCreditsRow;
