import React, { useEffect } from 'react';
import { TextField, MenuItem, Grid } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { PreferenceQuestionView } from 'store/types/PreferenceQuestion';
import { FormProps, getRequiredValidationRule, getValidationProps } from 'util/Form';

interface DropdownQuestionFormItemProps extends FormProps {
  question: PreferenceQuestionView;
}

const DropdownQuestionFormItem: React.FunctionComponent<DropdownQuestionFormItemProps> = ({ question, disabled }) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { fieldName, options = [], required = false } = question;

  useEffect(() => {
    const selectedOption = options.find(({ isSelected }) => isSelected);

    if (selectedOption) {
      const { id } = selectedOption;

      setValue(fieldName, id);
    }
  }, [options, setValue, fieldName]);

  return (
    <Grid {...defaultGridContainerProps}>
      <Grid {...defaultGridItemProps}>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              {...getValidationProps(fieldName, errors)}
              select={true}
              required={required}
              disabled={disabled}
            >
              {options.map(({ name, id }) => (
                <MenuItem value={id} key={`${fieldName}-option-${id}`}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
          )}
          name={fieldName}
          control={control}
          rules={{
            required: getRequiredValidationRule('your answer', true, required),
          }}
        />
      </Grid>
    </Grid>
  );
};
export default DropdownQuestionFormItem;
