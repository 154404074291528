import { ConfigSelectOption } from 'store/types/SelectOption';
import SubscriptionStatus from 'store/enums/SubscriptionStatus';

const subscriptionStatusConfig: Record<SubscriptionStatus, ConfigSelectOption> = {
  [SubscriptionStatus.Active]: {
    name: 'Active',
    theme: 'green',
  },
  [SubscriptionStatus.Expired]: {
    name: 'Expired',
    theme: 'red',
  },
  [SubscriptionStatus.NonMember]: {
    name: 'None',
    theme: 'grey',
  },
  [SubscriptionStatus.Pending]: {
    name: 'Pending',
    theme: 'yellow',
  },
  [SubscriptionStatus.Terminated]: {
    name: 'Terminated',
    theme: 'grey',
  },
  [SubscriptionStatus.Unknown]: {
    name: 'Unknown',
    theme: 'grey',
  },
};

export default subscriptionStatusConfig;
