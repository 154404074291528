import { Grid, Link } from '@mui/material';
import classNames from 'classnames';
import EventLocationRow from 'components/events/EventInfoRow/EventLocationRow';
import EventTimeRow from 'components/events/EventInfoRow/EventTimeRow';
import Card from 'components/shared/Card';
import EventDateRow from 'components/events/EventInfoRow/EventCalendarIcon';
import React, { useContext } from 'react';
import eventModeConfig from 'store/configs/EventModeConfig';
import { getEventModeIcon } from 'util/Event';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { MainEventView } from 'store/types/events/Event';
import routes from 'store/configs/Routes';
import { getHashRouteUrl } from 'util/Route';
import EventCreditsRow from 'components/events/EventInfoRow/EventCreditsRow';
import ShowMoreText from 'components/shared/ShowMoreText';
import StatusLabel from 'components/shared/StatusLabel';
import { ParametersContext } from 'components/ParametersGuard';
import { Anchorme, LinkComponentProps } from 'react-anchorme';

import commonStyles from 'styles/common.module.scss';
import styles from './EventCatalogCard.module.scss';

interface EventCatalogCardProps {
  data: MainEventView;
}

const MAX_SUMMARY_LENGTH = 200;

const EventCatalogCard: React.FunctionComponent<EventCatalogCardProps> = ({ data }) => {
  const { id, title, mode, startDate, endDate, summaryDescription, type, sessions, externalUrl } = data;
  const {
    events: { multiSessionLabelName },
  } = useContext(ParametersContext);

  const externalLinkComponent = (props: LinkComponentProps) => {
    return (
      <a {...props} className={styles.title}>
        {title}
      </a>
    );
  };

  return (
    <Card className={styles.card} contentClassName={styles.cardContent}>
      <section className={styles.section}>
        <Grid {...defaultGridContainerProps} spacing={2}>
          <Grid {...defaultGridItemProps} className={styles.topInfo}>
            <div className={styles.typeLabel}>
              {getEventModeIcon(mode, styles.typeIcon)}
              {`${eventModeConfig[mode]?.name} | ${type}`}
            </div>
            {!!sessions?.length && <StatusLabel status={multiSessionLabelName}></StatusLabel>}
          </Grid>
          <Grid {...defaultGridItemProps}>
            {externalUrl ? (
              <Anchorme target={'_blank'} linkComponent={externalLinkComponent}>
                {externalUrl}
              </Anchorme>
            ) : (
              <Link href={getHashRouteUrl(`${routes.eventDetails}/${id}`)}>
                <h2 className={styles.title}>{title}</h2>
              </Link>
            )}
            {summaryDescription && (
              <p className={classNames(commonStyles.text, styles.description)}>
                <ShowMoreText maxLength={MAX_SUMMARY_LENGTH}>{summaryDescription}</ShowMoreText>
              </p>
            )}
          </Grid>
        </Grid>
      </section>
      <section className={classNames(styles.section, styles.footer)}>
        <Grid {...defaultGridContainerProps} spacing={2} alignItems={'center'}>
          <Grid {...defaultGridItemProps} xs={'auto'}>
            <EventDateRow data={data} className={styles.infoSectionRow} />
          </Grid>
          {endDate && startDate.isSame(endDate, 'days') && (
            <Grid {...defaultGridItemProps} sm={'auto'}>
              <EventTimeRow data={data} className={styles.infoSectionRow} />
            </Grid>
          )}
          <Grid {...defaultGridItemProps} sm={'auto'}>
            <EventLocationRow data={data} className={styles.infoSectionRow} />
          </Grid>
          <Grid {...defaultGridItemProps} sm={'auto'}>
            <EventCreditsRow data={data} className={styles.infoSectionRow} />
          </Grid>
        </Grid>
      </section>
    </Card>
  );
};
export default EventCatalogCard;
