import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Modal from 'components/shared/Modal';
import { Button, Grid } from '@mui/material';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { defaultFormProps } from 'util/Form';
import { FormProvider, useForm } from 'react-hook-form';
import Stepper from 'components/shared/Stepper';
import { ModalProps } from 'store/types/ComponentProps';
import { EventHotelPreferences, EventHotelPreferencesFormValues } from 'store/types/events/EventHotelPreferences';
import EventHotelPreferencesForm from 'components/events/EventHotelPreferencesForm';
import { MainEventView } from 'store/types/events/Event';
import EventStatus from 'store/enums/events/EventStatus';
import { UserContext } from 'components/UserGuard';
import { isSubListQuestionsValid } from 'util/Preferences';
import { EventSessionView } from 'store/types/events/Event';
import { getDefaultFormValues, getStepsConfig } from 'util/EventHotelPreferences';

import styles from './EventHotelPreferencesModal.module.scss';
import { getUserFullName } from 'util/Format';

interface EventHotelPreferencesProps extends ModalProps {
  data: EventHotelPreferences[];
  selectedEvents: MainEventView[];
  storedData?: EventHotelPreferencesFormValues[];
  onSubmit: (hotelPreferences: EventHotelPreferencesFormValues[]) => void;
}

const EventHotelPreferencesModal: React.FunctionComponent<EventHotelPreferencesProps> = ({
  open,
  onClose,
  data,
  onSubmit,
  selectedEvents,
  storedData,
}) => {
  const [step, setStep] = useState<number>(1);
  const [eventRegistrationList, setEventRegistrationList] = useState<EventHotelPreferences[]>([]);
  const [eventRegistrationData, setEventRegistrationData] = useState<EventHotelPreferencesFormValues[]>([]);
  const form = useForm<EventHotelPreferencesFormValues>({
    ...defaultFormProps,
    defaultValues: getDefaultFormValues(),
    shouldUnregister: false,
  });
  const {
    setValue,
    reset,
    watch,
    getValues,
    formState: { isValid },
  } = form;
  const values = watch();
  const submitButtonDisabled = useMemo(
    () => !isValid || !isSubListQuestionsValid(values?.preferencesQuestionsList, values),
    [isValid, values]
  );
  const currentUser = useContext(UserContext);
  const stepsConfig = useMemo(
    () => getStepsConfig(eventRegistrationList, currentUser),
    [currentUser, eventRegistrationList]
  );
  const currentStepConfigIndex: number = useMemo(
    () => stepsConfig.findIndex(({ value }) => step === value),
    [step, stepsConfig]
  );
  const currentEventRegistration = eventRegistrationList[currentStepConfigIndex];

  useEffect(() => {
    if (data && selectedEvents) {
      const result: EventHotelPreferences[] = [];

      const findRegistration = ({
        id,
        customerName,
        registrantId,
        companyId,
        companyName,
        registrantName,
        guestId,
        defaultHotelCheckOutDate,
        defaultHotelCheckInDate,
      }: MainEventView | EventSessionView) => {
        const foundedRegistration = data.find(({ eventId }) => eventId === id);
        const userId: string = guestId || registrantId || '';

        const customerLabelName =
          companyId && !guestId
            ? `${companyName}: ${customerName}`
            : registrantName
            ? `${registrantName}'s Guest: ${customerName}`
            : `${getUserFullName(currentUser, true)}`;

        if (
          foundedRegistration &&
          (guestId
            ? foundedRegistration.isHotelInformationCollectedForGuest ||
              foundedRegistration.isPreferencesCollectedForGuest
            : true)
        ) {
          result.push({
            ...foundedRegistration,
            userId,
            guestId,
            registrantId,
            customerName: customerLabelName,
            defaultHotelCheckOutDate,
            defaultHotelCheckInDate,
          });
        }
      };

      selectedEvents.forEach((event) => {
        findRegistration(event);
        if (event.sessions?.length) {
          event.sessions
            .filter(({ status }) => status === EventStatus.Selected)
            .forEach((event) => {
              findRegistration(event);
            });
        }
      });

      setEventRegistrationList([...result]);
    }
  }, [currentUser, data, selectedEvents, storedData]);

  useEffect(() => {
    if (eventRegistrationList?.length) {
      const result: EventHotelPreferencesFormValues[] = [];

      eventRegistrationList.forEach((listEvent, index) => {
        if (storedData) {
          const storedEvent = storedData?.find(({ eventId, registrantId, guestId }) => {
            return (
              Number(listEvent.eventId) === Number(eventId) &&
              Number(listEvent.registrantId) === Number(registrantId) &&
              Number(listEvent.guestId) === Number(guestId)
            );
          });

          if (storedEvent) {
            result.push({ ...storedEvent, stepNumber: index + 1 });
          }
        }
      });

      setEventRegistrationData(result);
    }
  }, [eventRegistrationList, storedData]);

  useEffect(() => {
    if (eventRegistrationList?.length) {
      const currentEvent: EventHotelPreferences = eventRegistrationList[step - 1];
      const previousEvent: EventHotelPreferencesFormValues | undefined = eventRegistrationData.find(
        ({ stepNumber }) => stepNumber === step
      );

      if (previousEvent) {
        reset(previousEvent, { keepDefaultValues: true });
      } else {
        reset(getDefaultFormValues(currentEvent));
      }
    }
  }, [eventRegistrationList, eventRegistrationData, reset, setValue, step, storedData]);

  const handleNextStep = useCallback(() => {
    const newStepIndex: number = currentStepConfigIndex + 1;
    const nextStep = stepsConfig[newStepIndex].value;
    const currentEvent = { ...getValues(), stepNumber: step };

    setEventRegistrationData((prevState) => [
      ...prevState.filter(({ stepNumber }) => stepNumber !== step),
      currentEvent,
    ]);
    setStep(nextStep);

    setTimeout(() => {
      document.getElementById('hotelStepperContainer')?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  }, [currentStepConfigIndex, getValues, step, stepsConfig]);

  const handlePrevStep = useCallback(() => {
    document.getElementById('hotelStepperContainer')?.scrollIntoView({ behavior: 'smooth' });
    const newStepIndex: number = currentStepConfigIndex - 1;
    const prevStep = stepsConfig[newStepIndex].value;

    setStep(prevStep);

    setTimeout(() => {
      document.getElementById('hotelStepperContainer')?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  }, [currentStepConfigIndex, stepsConfig]);

  const handleClose = useCallback(() => {
    onClose();
    setStep(1);
  }, [onClose]);

  const handleSave = useCallback(() => {
    onSubmit([...eventRegistrationData.filter(({ stepNumber }) => stepNumber !== step), getValues()]);
    handleClose();
  }, [eventRegistrationData, getValues, handleClose, onSubmit, step]);

  return (
    <Modal
      title={'Event Details'}
      open={open}
      onClose={handleClose}
      maxWidth={'lg'}
      actions={
        <>
          <Button color={'secondary'} variant={'outlined'} onClick={step === 1 ? handleClose : handlePrevStep}>
            {step === 1 ? 'Close' : 'Back'}
          </Button>
          <Button
            onClick={stepsConfig.length === step ? handleSave : handleNextStep}
            variant={'contained'}
            className={styles.button}
            disabled={submitButtonDisabled}
          >
            {stepsConfig.length === step ? 'Save' : 'Next'}
          </Button>
        </>
      }
    >
      <Grid {...defaultGridContainerProps} id="hotelStepperContainer">
        <Grid {...defaultGridItemProps} className={styles.stepper}>
          <Stepper activeStep={step} config={stepsConfig} />
        </Grid>
      </Grid>
      <FormProvider {...form}>
        <EventHotelPreferencesForm
          additionalInformation={currentEventRegistration?.additionalInformation}
          eventRegistrationDisclaimer={currentEventRegistration?.eventRegistrationDisclaimer}
          availabilityDisclaimer={eventRegistrationList[currentStepConfigIndex]?.availabilityDisclaimer}
        />
      </FormProvider>
    </Modal>
  );
};

export default EventHotelPreferencesModal;
