import { Button, Grid, Hidden, IconButton, TextField } from '@mui/material';
import AddCircle from '@mui/icons-material/AddCircle';
import HighlightOff from '@mui/icons-material/HighlightOff';
import classNames from 'classnames';
import Modal from 'components/shared/Modal';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import { Controller, FieldArrayWithId, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { OrderPayment } from 'store/types/Payment';
import { ModalProps } from 'store/types/ComponentProps';
import { defaultFormProps, getArrayFieldValidationProps, validateEmailValue } from 'util/Form';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import PaymentService from 'services/api/PaymentService';
import Company from 'store/types/Company';
import OrderType from 'store/enums/OrderType';

import commonStyles from 'styles/common.module.scss';
import styles from './SendPaymentModal.module.scss';

interface SendPaymentModalProps extends ModalProps {
  invoiceId: OrderPayment['id'];
  companyId?: Company['id'];
  orderType: OrderType;
}

const EMAILS_ARRAY_FIELD_NAME = 'emails';

type FieldType = { value: string };

type SendEmailsFormValues = {
  [EMAILS_ARRAY_FIELD_NAME]: FieldType[];
};

const EMAILS_MAX_COUNT = 3;

const SendPaymentModal: React.FunctionComponent<SendPaymentModalProps> = ({
  open,
  onClose,
  invoiceId,
  orderType,
  companyId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<SendEmailsFormValues>({
    ...defaultFormProps,
    defaultValues: {
      [EMAILS_ARRAY_FIELD_NAME]: [{ value: '' }],
    },
  });
  const [loading, setLoading] = useState<boolean>(false);
  const {
    formState: { isValid, isDirty, errors },
    handleSubmit,
    control,
  } = form;
  const { fields, append, remove } = useFieldArray({ control, name: EMAILS_ARRAY_FIELD_NAME });
  const submitButtonDisabled = useMemo(() => !isValid || !isDirty || loading, [isValid, isDirty, loading]);

  const handleFormSubmit = useCallback(
    ({ emails = [] }: SendEmailsFormValues) => {
      const emailsData: string[] = emails.map(({ value }) => value);
      setLoading(true);
      PaymentService.sendOrderConfirmation(emailsData, invoiceId, orderType, companyId)
        .then(() => {
          setLoading(false);
          enqueueSnackbar(`Emails successfully sent`, { variant: 'success' });
          onClose();
        })
        .catch((error: string) => {
          enqueueSnackbar(error, defaultSnackbarErrorProps);
          setLoading(false);
        });
    },
    [invoiceId, orderType, companyId, enqueueSnackbar, onClose]
  );

  const handleFieldAdd = useCallback(() => {
    append([{ value: '' }]);
  }, [append]);

  const handleFieldDelete = useCallback(
    (index: number) => () => {
      remove(index);
    },
    [remove]
  );

  const validateField = useCallback((value: string) => validateEmailValue(value), []);

  const getInput = (field: Partial<FieldArrayWithId>, index: number, withLabel = false): React.ReactNode => (
    <Controller
      render={({ field }) => (
        <TextField
          {...field}
          {...getArrayFieldValidationProps<SendEmailsFormValues>(EMAILS_ARRAY_FIELD_NAME, index, errors)}
          type={'email'}
          label={withLabel ? 'Email Address' : ''}
          required={true}
          disabled={loading}
          autoComplete={'off'}
        />
      )}
      name={`${EMAILS_ARRAY_FIELD_NAME}.${index}.value`}
      control={control}
      rules={{ validate: validateField }}
    />
  );

  const getDeleteButton = (index: number, arr: Array<Partial<FieldArrayWithId>> = []): React.ReactNode => (
    <IconButton
      className={classNames(commonStyles.dangerIconButton, styles.deleteButton)}
      onClick={handleFieldDelete(index)}
      disabled={loading || arr.length <= 1}
    >
      <HighlightOff />
    </IconButton>
  );

  return (
    <FormProvider {...form}>
      <Modal
        open={open}
        onClose={onClose}
        title={'Email Confirmation'}
        actions={
          <>
            <Button color={'secondary'} variant={'outlined'} onClick={onClose} disabled={loading}>
              {'Cancel'}
            </Button>
            <Button
              color={'secondary'}
              variant={'contained'}
              type={'submit'}
              disabled={submitButtonDisabled}
              onClick={handleSubmit(handleFormSubmit)}
            >
              {'Send'}
            </Button>
          </>
        }
      >
        <Grid {...defaultGridContainerProps}>
          {fields.map((field: Partial<FieldArrayWithId>, index: number, arr: Array<Partial<FieldArrayWithId>> = []) => {
            return (
              <Grid {...defaultGridItemProps} key={field.id}>
                <Grid {...defaultGridContainerProps} alignItems={'center'}>
                  <Hidden only={'xs'}>
                    <Grid item={true} xs={'auto'} className={styles.labelWrapper}>
                      <label className={styles.label} htmlFor={`send-email-input-${field.id}`}>
                        {'Email Address'}
                      </label>
                    </Grid>
                    <Grid item={true} xs={true}>
                      {getInput(field, index)}
                    </Grid>
                    <Grid item={true} xs={'auto'}>
                      {getDeleteButton(index, arr)}
                    </Grid>
                  </Hidden>
                  <Hidden smUp={true}>
                    <Grid item={true} xs={true}>
                      {getInput(field, index, true)}
                    </Grid>
                    <Grid item={true}>{getDeleteButton(index, arr)}</Grid>
                  </Hidden>
                </Grid>
              </Grid>
            );
          })}
          <Hidden only={'xs'}>
            <Grid item={true} xs={'auto'} className={styles.labelWrapper} />
          </Hidden>
          <Grid item={true} xs={true}>
            <Button
              color={'primary'}
              variant={'text'}
              onClick={handleFieldAdd}
              disabled={loading || fields.length >= EMAILS_MAX_COUNT}
              startIcon={<AddCircle />}
            >
              {`Add Recipient ${fields.length >= EMAILS_MAX_COUNT ? '(maximum 3 emails)' : ''}`}
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </FormProvider>
  );
};
export default SendPaymentModal;
