import { Button, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { defaultFormProps } from 'util/Form';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import { UserContext } from 'components/UserGuard';
import EmailFormItem from 'components/profile/EmailFormItem';
import { UserFormValues } from 'store/types/FormValues';
import UserService from 'services/api/UserService';
import Spinner from 'components/shared/Spinner';

import pageStyles from '../EditSettingsPageView.module.scss';

type FormValues = Pick<UserFormValues, 'email'>;

const EditEmailSection: React.FunctionComponent = () => {
  const { email = '', setCurrentUser } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const form = useForm<FormValues>({
    ...defaultFormProps,
    defaultValues: {
      email: '',
    },
  });
  const { handleSubmit, formState, reset } = form;

  useEffect(() => {
    if (email) {
      reset({ email });
    }
  }, [email, reset]);

  const handleFormSubmit = useCallback(
    (formValues: FormValues) => {
      setLoading(true);
      UserService.updateCurrentUserEmail(formValues.email)
        .then((responseEmail) => {
          setCurrentUser({ email: responseEmail });
          setLoading(false);
          enqueueSnackbar('Email successfully updated', { variant: 'success' });
          reset(formValues);
        })
        .catch((error: string) => {
          setLoading(false);
          enqueueSnackbar(error, defaultSnackbarErrorProps);
        });
    },
    [enqueueSnackbar, reset, setCurrentUser]
  );

  return (
    <Spinner loading={loading}>
      <form>
        <Grid {...defaultGridContainerProps}>
          <Grid {...defaultGridItemProps} lg={8}>
            <FormProvider {...form}>
              <EmailFormItem rowProps={defaultGridItemProps} />
            </FormProvider>
          </Grid>
          <Grid {...defaultGridItemProps} lg={4}>
            <Button
              color={'secondary'}
              variant={'contained'}
              type={'submit'}
              size={'large'}
              className={pageStyles.button}
              disabled={!formState.isValid || !formState.isDirty || loading}
              onClick={handleSubmit(handleFormSubmit)}
            >
              {'Save Email'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Spinner>
  );
};
export default EditEmailSection;
