import { ExpenseItem } from 'store/types/Expense';
import { SortConfig } from 'store/types/Table';
import { sorter } from 'util/Table';

export enum ExpenseItemTableActionType {
  SetInitialList = 'SetInitialList',
  UpdateSort = 'UpdateSort',
}

export interface ExpenseItemTableState {
  initialList: ExpenseItem[];
  list: ExpenseItem[];
  sort: SortConfig<ExpenseItem>;
}

export interface ExpenseItemTableAction {
  type: ExpenseItemTableActionType;
  payload: Partial<ExpenseItemTableState>;
}

const getSortedList = (list: ExpenseItem[] = [], sort: SortConfig<ExpenseItem>): ExpenseItem[] =>
  list.length ? [...list].sort(sorter<ExpenseItem>(sort)) : [...list];

export const initialState: ExpenseItemTableState = {
  initialList: [],
  list: [],
  sort: { column: 'id', direction: 'asc' },
};

const reducer = (state: ExpenseItemTableState, { type, payload }: ExpenseItemTableAction): ExpenseItemTableState => {
  if (type === ExpenseItemTableActionType.SetInitialList) {
    const { initialList = [] } = payload;
    const list = initialList;

    return { ...state, initialList, list };
  }

  if (type === ExpenseItemTableActionType.UpdateSort) {
    const { sort = {} } = payload;

    return {
      ...state,
      sort,
      list: getSortedList(state.initialList, sort),
    };
  }

  return state;
};

export default reducer;
