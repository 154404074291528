import { sorter } from 'util/Table';
import { SortConfig } from 'store/types/Table';
import CompanyEmployeeAccessRequest from 'store/types/CompanyEmployeeAccessRequest';

export enum ManageEmployeeAccessTableActionType {
  SetInitialList = 'SetInitialList',
  UpdateSort = 'UpdateSort',
  UpdateFilter = 'UpdateFilter',
  OpenRejectModal = 'OpenRejectModal',
  CloseRejectModal = 'CloseRejectModal',
  OpenApproveModal = 'OpenApproveModal',
  CloseApproveModal = 'CloseApproveModal',
  OpenCancelModal = 'OpenCancelModal',
  CloseCancelModal = 'CloseCancelModal',
}

export interface ManageEmployeeAccessTableState {
  approveModalOpen: boolean;
  rejectModalOpen: boolean;
  cancelModalOpen: boolean;
  initialList: CompanyEmployeeAccessRequest[];
  list: CompanyEmployeeAccessRequest[];
  sort: SortConfig<CompanyEmployeeAccessRequest>;
  selectedItem?: CompanyEmployeeAccessRequest;
}

export interface ManageEmployeeAccessTableAction {
  type: ManageEmployeeAccessTableActionType;
  payload: Partial<ManageEmployeeAccessTableState>;
}

const getSortedList = (
  list: CompanyEmployeeAccessRequest[] = [],
  sort: SortConfig<CompanyEmployeeAccessRequest>
): CompanyEmployeeAccessRequest[] => {
  return list.length ? [...list].sort(sorter<CompanyEmployeeAccessRequest>(sort)) : [...list];
};

export const initialState: ManageEmployeeAccessTableState = {
  rejectModalOpen: false,
  cancelModalOpen: false,
  approveModalOpen: false,
  initialList: [],
  list: [],
  sort: { direction: 'asc', column: 'name' },
  selectedItem: undefined,
};

const reducer = (
  state: ManageEmployeeAccessTableState,
  { type, payload }: ManageEmployeeAccessTableAction
): ManageEmployeeAccessTableState => {
  if (type === ManageEmployeeAccessTableActionType.SetInitialList) {
    const { initialList = [] } = payload;

    return { ...state, initialList, list: getSortedList(initialList, state.sort) };
  }

  if (type === ManageEmployeeAccessTableActionType.UpdateSort) {
    const { sort = {} } = payload;
    const newList = getSortedList(state.initialList, sort);

    return { ...state, sort, list: newList };
  }

  if (type === ManageEmployeeAccessTableActionType.OpenRejectModal) {
    const { selectedItem } = payload;

    return { ...state, selectedItem, rejectModalOpen: true };
  }

  if (type === ManageEmployeeAccessTableActionType.CloseRejectModal) {
    return { ...state, selectedItem: undefined, rejectModalOpen: false };
  }

  if (type === ManageEmployeeAccessTableActionType.OpenApproveModal) {
    const { selectedItem } = payload;

    return { ...state, selectedItem, approveModalOpen: true };
  }

  if (type === ManageEmployeeAccessTableActionType.CloseApproveModal) {
    return { ...state, selectedItem: undefined, approveModalOpen: false };
  }

  if (type === ManageEmployeeAccessTableActionType.OpenCancelModal) {
    const { selectedItem } = payload;

    return { ...state, selectedItem, cancelModalOpen: true };
  }

  if (type === ManageEmployeeAccessTableActionType.CloseCancelModal) {
    return { ...state, selectedItem: undefined, cancelModalOpen: false };
  }

  return state;
};

export default reducer;
