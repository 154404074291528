import { FilterConfig, SortConfig } from 'store/types/Table';
import { OrderPayment } from 'store/types/Payment';
import { sorter } from 'util/Table';
import moment from 'moment-timezone';

export enum OrderHistoryTableActionType {
  SetInitialList = 'SetInitialList',
  UpdateSort = 'UpdateSort',
  UpdateFilter = 'UpdateFilter',
  OpenDetails = 'OpenDetails',
  CloseDetails = 'CloseDetails',
  OpenPrint = 'OpenPrint',
  ClosePrint = 'ClosePrint',
  OpenEmailModal = 'OpenEmailModal',
  CloseEmailModal = 'CloseEmailModal',
}

export interface OrderHistoryTableState {
  initialList: OrderPayment[];
  list: OrderPayment[];
  sort: SortConfig<OrderPayment>;
  filter: FilterConfig<OrderPayment>;
  clickedItem?: OrderPayment;
  printedItem?: OrderPayment;
  emailInvoiceId?: OrderPayment['id'];
  orderType?: OrderPayment['type'];
}

export interface OrderHistoryTableAction {
  type: OrderHistoryTableActionType;
  payload: Partial<OrderHistoryTableState>;
}

const getSortedList = (list: OrderPayment[] = [], sort: SortConfig<OrderPayment>): OrderPayment[] =>
  list.length ? [...list].sort(sorter<OrderPayment>(sort)) : [...list];

const getFilteredList = (list: OrderPayment[] = [], filter: FilterConfig<OrderPayment>) => {
  const search: string = (filter.search || '').toLowerCase();
  let result = [...list];

  if (filter.orderDate) {
    const filterDate = moment().startOf('day').subtract(filter.orderDate, 'days');

    result = result.filter(({ orderDate }: OrderPayment) => new Date(orderDate).getTime() > filterDate.valueOf());
  }
  if (filter.ownerId) {
    result = result.filter(
      ({ ownerId }: OrderPayment) => ownerId && ownerId.toLowerCase() === filter.ownerId.toLowerCase()
    );
  }
  if (search) {
    result = result.filter(
      ({ orderNumber, ownerName, organization }: OrderPayment) =>
        orderNumber.toLowerCase().includes(search) ||
        (ownerName && ownerName.toLowerCase().includes(search)) ||
        (organization && organization.toLowerCase().includes(search))
    );
  }
  return result;
};

export const initialState: OrderHistoryTableState = {
  initialList: [],
  list: [],
  sort: { direction: 'desc', column: 'status' },
  filter: { orderNumber: '', orderDate: 0 },
  clickedItem: undefined,
};

const reducer = (state: OrderHistoryTableState, { type, payload }: OrderHistoryTableAction): OrderHistoryTableState => {
  if (type === OrderHistoryTableActionType.SetInitialList) {
    const { initialList = [] } = payload;
    const dateSortedInitialList = getSortedList(initialList, { column: 'orderDate', direction: 'desc' });

    return { ...state, initialList: dateSortedInitialList, list: getSortedList(dateSortedInitialList, state.sort) };
  }

  if (type === OrderHistoryTableActionType.UpdateSort) {
    const { sort = {} } = payload;

    return {
      ...state,
      sort,
      list: getFilteredList(getSortedList(state.initialList, sort), state.filter),
    };
  }

  if (type === OrderHistoryTableActionType.UpdateFilter) {
    const { filter = {} } = payload;
    const newFilter = { ...state.filter, ...filter };

    return {
      ...state,
      filter: newFilter,
      list: getFilteredList(getSortedList(state.initialList, state.sort), newFilter),
    };
  }

  if (type === OrderHistoryTableActionType.OpenDetails) {
    const { clickedItem } = payload;

    return { ...state, clickedItem };
  }

  if (type === OrderHistoryTableActionType.CloseDetails) {
    return { ...state, clickedItem: undefined };
  }

  if (type === OrderHistoryTableActionType.OpenPrint) {
    const { printedItem } = payload;

    return { ...state, printedItem };
  }

  if (type === OrderHistoryTableActionType.ClosePrint) {
    return { ...state, printedItem: undefined };
  }

  if (type === OrderHistoryTableActionType.OpenEmailModal) {
    const { emailInvoiceId, orderType } = payload;

    return { ...state, emailInvoiceId, orderType };
  }

  if (type === OrderHistoryTableActionType.CloseEmailModal) {
    return { ...state, emailInvoiceId: undefined, orderType: undefined };
  }

  return state;
};

export default reducer;
