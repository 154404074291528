import { Button, Grid } from '@mui/material';
import React, { ChangeEvent, useCallback, useContext, useState } from 'react';
import { OrderPayment } from 'store/types/Payment';
import Alert from '@mui/material/Alert';
import { FilterConfig } from 'store/types/Table';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import SearchInput from 'components/shared/SearchInput';
import SupportCasesTable from 'components/support/SupportPageView/SupportCasesTable';
import useRequest from 'hooks/useRequest';
import SupportService from 'services/api/SupportService';
import { SupportCaseView } from 'store/types/SupportCase';
import Add from '@mui/icons-material/Add';
import AddSupportCaseModal from 'components/support/AddSupportCaseModal';
import { ParametersContext } from 'components/ParametersGuard';

import styles from './SupportPageView.module.scss';
import commonStyles from 'styles/common.module.scss';

const SupportPageView: React.FunctionComponent = () => {
  const [filter, setFilter] = useState<FilterConfig<OrderPayment>>({ search: '' });
  const [addSupportCaseModalOpen, setAddSupportCaseModalOpen] = useState<boolean>(false);
  const supportCasesRequest = useRequest<SupportCaseView[]>(SupportService.getSupportCases);
  const { error, refetch } = supportCasesRequest;
  const {
    support: { submittedSupportCasesTableHeader },
  } = useContext(ParametersContext);
  const handleNumberSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setFilter((prevState) => ({ ...prevState, search: e.target.value }));
  }, []);

  const handleAddSupportCaseModalClose = useCallback(() => {
    setAddSupportCaseModalOpen(false);
  }, []);

  const handleAddSupportCaseModalOpen = useCallback(() => {
    setAddSupportCaseModalOpen(true);
  }, []);

  const handleAddSupportCaseSubmit = useCallback(() => {
    refetch();
    setAddSupportCaseModalOpen(false);
  }, [refetch]);

  return error ? (
    <Alert severity={'error'} className={commonStyles.alert}>
      {error}
    </Alert>
  ) : (
    <>
      <Grid {...defaultGridContainerProps} justifyContent={'space-between'}>
        <Grid {...defaultGridItemProps} sm={'auto'}>
          <Button
            color={'secondary'}
            variant={'contained'}
            startIcon={<Add />}
            className={commonStyles.uppercase}
            onClick={handleAddSupportCaseModalOpen}
          >
            {'Submit New Case'}
          </Button>
        </Grid>
        <Grid {...defaultGridItemProps} sm={5}>
          <SearchInput value={filter.search} onChange={handleNumberSearchChange} />
        </Grid>
        <Grid {...defaultGridItemProps}>
          <h2 className={styles.tableTitle}>{'Submitted Support Cases'}</h2>
          {submittedSupportCasesTableHeader && (
            <p dangerouslySetInnerHTML={{ __html: submittedSupportCasesTableHeader }} />
          )}
          <SupportCasesTable {...supportCasesRequest} filter={filter} />
        </Grid>
      </Grid>
      <AddSupportCaseModal
        open={addSupportCaseModalOpen}
        onClose={handleAddSupportCaseModalClose}
        onSubmit={handleAddSupportCaseSubmit}
      />
    </>
  );
};
export default SupportPageView;
