import LocationOnOutlined from '@mui/icons-material/LocationOnOutlined';
import classNames from 'classnames';
import React from 'react';
import { MainEventView } from 'store/types/events/Event';
import { getAddressInfo } from 'util/Format';
import ComponentProps from 'store/types/ComponentProps';
import { MainEventRegistrationView } from 'store/types/events/EventRegistration';

import rowStyles from '../EventInfoRow.module.scss';

interface EventLocationRowProps extends ComponentProps {
  data: MainEventView | MainEventRegistrationView;
  fullLocation?: boolean;
}

const EventLocationRow: React.FunctionComponent<EventLocationRowProps> = ({
  data: { location, address, email, phone },
  className = '',
  fullLocation,
}) => (
  <div className={classNames(rowStyles.row, className)}>
    <LocationOnOutlined className={rowStyles.icon} />
    {!fullLocation && <span>{getAddressInfo({ ...address, label: location })}</span>}
    {fullLocation && (
      <>
        <span>{location}</span>
        <span>{getAddressInfo(address)}</span>
        {email && <span>{email}</span>}
        {phone && <span>{phone}</span>}
      </>
    )}
  </div>
);

export default EventLocationRow;
