import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router';
import { useFormContext } from 'react-hook-form';

interface UseUnsavedChangesPromptData {
  removeUnsavedChangesPrompt: () => void;
  prompt: React.ReactNode;
}

const useUnsavedChangesPrompt = (promptMessage: string): UseUnsavedChangesPromptData => {
  const { formState, getValues } = useFormContext();
  const [showUnsavedChangesPrompt, setShowUnsavedChangesPrompt] = useState<boolean>(false);

  useEffect(() => {
    const dirtyFields = formState.dirtyFields;

    if (dirtyFields.files) {
      const { files } = getValues();

      const nonEmptyFileUploadFields = Object.values(files).some((x) => {
        return Array.isArray(x) && x.length;
      });

      if (!nonEmptyFileUploadFields) {
        delete dirtyFields.files;
      }
    }

    setShowUnsavedChangesPrompt(!!Object.keys(dirtyFields).length);
  }, [formState, getValues]);

  useEffect(() => {
    window.onbeforeunload = showUnsavedChangesPrompt ? () => true : null;
  });

  useEffect(
    () => () => {
      window.onbeforeunload = null;
    },
    []
  );

  const removeUnsavedChangesPrompt = () => {
    setShowUnsavedChangesPrompt(false);
  };

  return {
    removeUnsavedChangesPrompt,
    prompt: <Prompt when={showUnsavedChangesPrompt} message={promptMessage} />,
  };
};

export default useUnsavedChangesPrompt;
