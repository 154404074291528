import { Button, Grid } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { defaultGridContainerProps, defaultGridItemProps, getButtonLoadingProps } from 'util/Layout';
import useRequest from 'hooks/useRequest';
import NotifyingCompaniesTable from './NotifyingCompaniesTable';
import { NotifyingCompaniesData, NotifyingCompany } from 'store/types/Company';
import EmailNotificationsModal from './EmailNotificationsModal';
import UserService from 'services/api/UserService';

import styles from './EmailNotificationsSettingsPageView.module.scss';

const EmailNotificationsSettingsPageView: React.FunctionComponent = () => {
  const { data, loading, refetch } = useRequest<NotifyingCompaniesData>(UserService.getNotifyingCompanies);
  const [emailNotificationModalOpen, setEmailNotificationModalOpen] = useState<boolean>(false);
  const companiesApplyToggle: NotifyingCompany[] = useMemo(() => data?.companyIn || [], [data]);
  const companiesNotApplyToggle: NotifyingCompany[] = useMemo(() => data?.companyOut || [], [data]);
  const buttonDisabled: boolean = useMemo(() => !companiesApplyToggle.length, [companiesApplyToggle]);

  const handleModalOpen = useCallback(() => {
    setEmailNotificationModalOpen(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setEmailNotificationModalOpen(false);
  }, []);

  const handleModalSubmit = useCallback(() => {
    setEmailNotificationModalOpen(false);
    refetch();
  }, [refetch]);

  return (
    <>
      <Grid {...defaultGridContainerProps}>
        <Grid {...defaultGridItemProps}>
          <>
            <Button
              color={'secondary'}
              variant={'contained'}
              className={styles.button}
              {...getButtonLoadingProps(loading)}
              disabled={buttonDisabled}
              onClick={handleModalOpen}
            >
              {'Opt Out Of Emails'}
            </Button>
            <p className={styles.helperToggleText}>
              This will opt out your email notifications from all companies you are assigned as a billing contact or
              administrator.
            </p>
          </>
        </Grid>
        <Grid {...defaultGridItemProps}>
          <h2 className={styles.tableTitle}>{'Opt Out Applies To All Of These Accounts'}</h2>
          <NotifyingCompaniesTable data={companiesApplyToggle} loading={loading} />
        </Grid>
        <Grid {...defaultGridItemProps}>
          <h2 className={styles.tableTitle}>
            {'To Opt Out Of Emails For These Accounts Please Add a Billing Contact'}
          </h2>
          <NotifyingCompaniesTable data={companiesNotApplyToggle} loading={loading} />
        </Grid>
      </Grid>
      <EmailNotificationsModal
        onSubmit={handleModalSubmit}
        open={emailNotificationModalOpen}
        onClose={handleModalClose}
      />
    </>
  );
};
export default EmailNotificationsSettingsPageView;
