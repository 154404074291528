import React, { Reducer, useCallback, useContext, useEffect, useReducer } from 'react';
import { Alert, Button, Grid } from '@mui/material';
import { ConfigContext } from 'components/ConfigGuard';
import DeleteReportModal from 'components/expense/ExpenseReportPage/DeleteReportModal';
import ExpenseReportTable from 'components/expense/ExpenseReportTable';
import reducer, {
  ExpenseReportTableAction,
  ExpenseReportTableActionType,
  ExpenseReportTableState,
  initialState,
} from 'components/expense/ExpenseReportTable/ExpenseReportTableReducer';
import useExpenseReportPrint from 'components/expense/PrintExpenseReportHook';
import { ParametersContext } from 'components/ParametersGuard';
import FilterToggleGroup from 'components/shared/FilterToggleGroup';
import Spinner from 'components/shared/Spinner';
import useRequest from 'hooks/useRequest';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import ExpenseService from 'services/api/ExpenseService';
import routes from 'store/configs/Routes';
import ExpenseReportTableType from 'store/enums/ExpenseReportTableType';
import PaymentMethodType from 'store/enums/PaymentMethodType';
import { ExpenseReport } from 'store/types/Expense';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';

import commonStyles from 'styles/common.module.scss';
import styles from 'components/expense/Expense.module.scss';

const ExpenseReportCollectionPage: React.FunctionComponent = () => {
  const [
    {
      listDraftStatus,
      listSubmittedStatus,
      loading: tableLoading,
      modalOpen,
      printedReport,
      selectedReportId,
      statuses,
    },
    dispatch,
  ] = useReducer<Reducer<ExpenseReportTableState, ExpenseReportTableAction>>(reducer, initialState);

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const {
    modulesSettings: {
      expenseReport: { reportsTitle },
    },
  } = useContext(ConfigContext);

  const {
    expenseReport: { reportsTableHeader, reportsTableHeaderDraftStatus },
  } = useContext(ParametersContext);

  const handlePrintFinished = useCallback(() => {
    dispatch({
      type: ExpenseReportTableActionType.SetPrintedReport,
      payload: {},
    });
  }, []);

  const [printExpenseReport, printExpenseReportComponent] = useExpenseReportPrint(printedReport, handlePrintFinished);

  useEffect(() => {
    if (printedReport) {
      printExpenseReport();
    }
  }, [printExpenseReport, printedReport]);

  const handlePrint = useCallback(
    (expenseReportId: string) => {
      ExpenseService.getSingleExpenseReport(expenseReportId)
        .then((report: ExpenseReport) => {
          dispatch({
            type: ExpenseReportTableActionType.SetPrintedReport,
            payload: { printedReport: report },
          });
        })
        .catch((errorMessage: string) => {
          dispatch({
            type: ExpenseReportTableActionType.SetPrintedReport,
            payload: {},
          });
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        });
    },
    [enqueueSnackbar]
  );

  const handleAddReport = useCallback(() => {
    history.push(`${routes.expenseReport}/new`);
  }, [history]);

  const expenseReportsRequest = useCallback(async () => {
    const expenseReportsData = await ExpenseService.getAllExpenseReports();
    if (expenseReportsData?.length > 0) {
      return expenseReportsData;
    }
    const profileData = await ExpenseService.getExpenseProfile();
    const expenseReportsRequest = ExpenseService.getAllExpenseReports();
    if (
      profileData?.paymentMethodType == PaymentMethodType.Check ||
      (profileData?.paymentMethodType == PaymentMethodType.Ach &&
        profileData?.achPayment?.accountNumber &&
        profileData?.achPayment?.routingCode)
    ) {
      return expenseReportsRequest;
    }
    history.push(routes.expenseProfile);
    return await expenseReportsRequest;
  }, [history]);

  const { data, error, loading, refetch } = useRequest<ExpenseReport[]>(expenseReportsRequest);

  useEffect(() => {
    dispatch({
      type: ExpenseReportTableActionType.SetInitialList,
      payload: { initialList: data },
    });
  }, [data]);

  const handleConfirmDeleteModalOpen = useCallback((expenseReportId: string) => {
    dispatch({
      type: ExpenseReportTableActionType.SetSelectedReportId,
      payload: { selectedReportId: expenseReportId },
    });
    dispatch({
      type: ExpenseReportTableActionType.ToggleModal,
      payload: {},
    });
  }, []);

  const handleConfirmDeleteModalClose = useCallback(() => {
    dispatch({
      type: ExpenseReportTableActionType.ToggleModal,
      payload: {},
    });
  }, []);

  const handleDeleteReportModalSubmit = useCallback(() => {
    dispatch({
      type: ExpenseReportTableActionType.SetSelectedReportId,
      payload: { selectedReportId: '' },
    });
    dispatch({
      type: ExpenseReportTableActionType.ToggleModal,
      payload: {},
    });
    refetch();
  }, [refetch]);

  const handleFilter = useCallback((selectedStatusFilters: string[]) => {
    dispatch({
      type: ExpenseReportTableActionType.UpdateFilter,
      payload: { selectedStatusFilters: selectedStatusFilters },
    });
  }, []);

  return error ? (
    <Alert severity={'error'} className={commonStyles.alert}>
      {error}
    </Alert>
  ) : (
    <Spinner loading={loading || tableLoading} transparent={true}>
      <Grid {...defaultGridContainerProps} spacing={0} className={styles.pageTitleSection}>
        <Grid {...defaultGridItemProps} md={6}>
          {reportsTitle && <h1 className={commonStyles.pageTitle}>{reportsTitle}</h1>}
        </Grid>
        <Grid {...defaultGridItemProps} md={6} className={styles.pullRight}>
          <Button color={'primary'} variant={'contained'} onClick={handleAddReport}>
            {'Create an Expense Report'}
          </Button>
        </Grid>
      </Grid>

      <h2 className={styles.tableTitle}>Draft Expense Reports</h2>

      {reportsTableHeaderDraftStatus && (
        <div className={styles.contentBlock} dangerouslySetInnerHTML={{ __html: reportsTableHeaderDraftStatus }} />
      )}

      <div className={styles.content}>
        <ExpenseReportTable
          data={listDraftStatus}
          tableType={ExpenseReportTableType.ReportDraft}
          onModalOpen={handleConfirmDeleteModalOpen}
        />
      </div>

      <h2 className={styles.tableTitle}>Submitted Expense Reports</h2>

      {reportsTableHeader && (
        <div className={styles.contentBlock} dangerouslySetInnerHTML={{ __html: reportsTableHeader }} />
      )}

      {statuses?.length > 1 && (
        <div className={styles.contentBlock}>
          <FilterToggleGroup items={statuses} onChange={handleFilter} />
        </div>
      )}

      <div className={styles.content}>
        <ExpenseReportTable
          data={listSubmittedStatus}
          tableType={ExpenseReportTableType.ReportSubmitted}
          onPrint={handlePrint}
        />
      </div>

      <DeleteReportModal
        expenseReportId={selectedReportId}
        open={modalOpen}
        onSubmit={handleDeleteReportModalSubmit}
        onClose={handleConfirmDeleteModalClose}
      />

      {printExpenseReportComponent}
    </Spinner>
  );
};

export default ExpenseReportCollectionPage;
