import { Link } from '@mui/material';
import Alert from '@mui/material/Alert';
import classNames from 'classnames';
import PageCard from 'components/shared/PageCard';
import Spinner from 'components/shared/Spinner';
import React, { useEffect, useState } from 'react';
import routes from 'store/configs/Routes';
import { OrderPayment } from 'store/types/Payment';
import { getFormattedDate, getStringValue, STRING_DATE_FORMAT } from 'util/Format';
import { getFormattedOrdersList, getPrice } from 'util/Payment';
import UseRequestData from 'store/types/UseRequestData';
import { getHashRouteUrl } from 'util/Route';
import { sorter } from 'util/Table';

import pageStyles from '../HomePage.module.scss';
import styles from './HomeOrdersCard.module.scss';

const HomeOrdersCard: React.FunctionComponent<UseRequestData<OrderPayment[]>> = ({ data, loading, error }) => {
  const [orders, setOrders] = useState<OrderPayment[]>([]);

  useEffect(() => {
    if (data && data.length) {
      const formattedList = getFormattedOrdersList(data);

      setOrders(formattedList.sort(sorter({ column: 'orderDate', direction: 'desc' })).slice(0, 3));
    }
  }, [data]);

  return (
    <PageCard
      title={'Recent invoices'}
      action={
        !!orders.length && (
          <Link
            className={classNames(pageStyles.viewAllButton, { [pageStyles.disabled]: loading })}
            href={getHashRouteUrl(routes.personalPayments)}
          >
            {'View all'}
          </Link>
        )
      }
    >
      <Spinner loading={loading} transparent={false}>
        {error ? (
          <Alert severity={'error'}>{error}</Alert>
        ) : (
          <div className={pageStyles.cardContent}>
            {!orders.length ? (
              <p>{'You have no invoces yet.'}</p>
            ) : (
              <ul>
                {orders.map(({ orderDate, invoiceName, id, totalAmount }: OrderPayment) => (
                  <li key={`home-order-${id}`} className={styles.item}>
                    <div className={styles.info}>
                      <span className={styles.date}>{getFormattedDate(orderDate, STRING_DATE_FORMAT)}</span>
                      <span className={styles.title}>{getStringValue(invoiceName)}</span>
                    </div>
                    <span className={styles.price}>{getPrice(totalAmount)}</span>
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </Spinner>
    </PageCard>
  );
};
export default HomeOrdersCard;
