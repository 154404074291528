import { Container } from '@mui/material';
import NccerLogo from 'assets/images/nccer/logo-black.png';
import GamsdLogo from 'assets/images/gamsd/logo.png';
import CompassLogo from 'assets/images/compass/logo.png';
import MemberplexLogo from 'assets/images/default/logo-black.svg';
import SnaLogo from 'assets/images/sna/logo.png';
import SmacnaLogo from 'assets/images/smacna/logo.png';
import TeamsEliteLogo from 'assets/images/teamsElite/logo.png';
import AcsLogo from 'assets/images/acs/logo.png';
import { ConfigContext } from 'components/ConfigGuard';
import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';
import { getAppMenuConfig } from 'util/Route';
import ActionsMenu from 'components/shared/ActionsMenu';
import { MenuOutlined } from '@mui/icons-material';
import { useWindowSize } from 'util/Window';
import MenuConfigItem from 'store/types/MenuConfigItem';
import { UserContext } from 'components/UserGuard';

import commonStyles from 'styles/common.module.scss';
import styles from './PublicHeader.module.scss';

const PublicHeader: React.FunctionComponent = () => {
  const {
    isNccerTheme,
    isGamsdTheme,
    isCompassTheme,
    isSnaTheme,
    isSmacnaTheme,
    isAcsTheme,
    isTeamsEliteTheme,
    sidebar,
  } = useContext(ConfigContext);
  const currentUser = useContext(UserContext);
  const { userLoading } = currentUser;
  const { isMobile } = useWindowSize();
  const menuConfig: MenuConfigItem[] = useMemo(() => getAppMenuConfig(currentUser, sidebar), [currentUser, sidebar]);

  return (
    <header className={styles.header}>
      <Container maxWidth={'lg'} className={styles.content}>
        <a href={'/'} className={styles.logo}>
          <img
            src={
              isNccerTheme
                ? NccerLogo
                : isGamsdTheme
                ? GamsdLogo
                : isCompassTheme
                ? CompassLogo
                : isSnaTheme
                ? SnaLogo
                : isSmacnaTheme
                ? SmacnaLogo
                : isAcsTheme
                ? AcsLogo
                : isTeamsEliteTheme
                ? TeamsEliteLogo
                : MemberplexLogo
            }
            alt={'logo'}
          />
        </a>
        <div>
          <ActionsMenu
            menuConfig={menuConfig}
            buttonIcon={<MenuOutlined className={styles.actionIcon} />}
            disabled={userLoading}
            buttonClassName={classNames(styles.actionButton, { [commonStyles.hidden]: !isMobile })}
          />
        </div>
      </Container>
    </header>
  );
};
export default PublicHeader;
