import React, { useContext } from 'react';
import classNames from 'classnames';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { CompanyEmployeeAccessRequestsData } from 'store/types/CompanyEmployeeAccessRequest';
import { ParametersContext } from 'components/ParametersGuard';
import ManageEmployeeAccessTable from '../CompanyUsersSection/ManageEmployeeAccessTable';
import { Grid } from '@mui/material';

import commonStyles from 'styles/common.module.scss';
import pageStyles from '../CompanyPageView/CompanyPageView.module.scss';

interface CompanyRequestsPageViewProps {
  companyId: string;
  data: CompanyEmployeeAccessRequestsData;
  refetch: () => void;
}

const CompanyRequestsPageView: React.FunctionComponent<CompanyRequestsPageViewProps> = ({
  companyId,
  data,
  refetch,
}) => {
  const {
    companyManagement: { outgoingEmployeeAccessTableHeader, incomingEmployeeAccessTableHeader },
  } = useContext(ParametersContext);

  return (
    <Grid {...defaultGridContainerProps}>
      <Grid {...defaultGridItemProps} xs={true} className={pageStyles.wrapper}>
        <h2 className={classNames(commonStyles.subTitle, commonStyles.uppercase, pageStyles.title)}>
          {'Employee Requests to Join'}
        </h2>
      </Grid>
      <Grid {...defaultGridItemProps}>
        {incomingEmployeeAccessTableHeader && (
          <p dangerouslySetInnerHTML={{ __html: incomingEmployeeAccessTableHeader }} />
        )}
        <ManageEmployeeAccessTable companyId={companyId} data={data.incoming} refetch={refetch} />
      </Grid>
      <Grid {...defaultGridItemProps} xs={true} className={pageStyles.wrapper}>
        <h2 className={classNames(commonStyles.subTitle, commonStyles.uppercase, pageStyles.title)}>
          {'Employee Requests Sent'}
        </h2>
      </Grid>
      <Grid {...defaultGridItemProps}>
        {outgoingEmployeeAccessTableHeader && (
          <p dangerouslySetInnerHTML={{ __html: outgoingEmployeeAccessTableHeader }} />
        )}
        <ManageEmployeeAccessTable
          companyId={companyId}
          data={data.outgoing}
          refetch={refetch}
          companyRequests={true}
        />
      </Grid>
    </Grid>
  );
};

export default CompanyRequestsPageView;
