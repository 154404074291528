import { HttpService } from 'services/HttpService';
import { API_URL, tenantConfig } from 'config';
import AuthService from 'services/AuthService';
import { httpRequestWrapper } from 'util/Request';

class AdminService {
  public static resetCache(): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.delete(`${API_URL}/caching`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }
}
export default AdminService;
