import React, { useCallback, useEffect, useState } from 'react';
import useRequest from 'hooks/useRequest';
import Spinner from 'components/shared/Spinner';
import ProductsService from 'services/api/ProductsService';
import ProductsCartItem from 'store/types/ProductsCartItem';
import { useLocation } from 'react-router';
import routes from 'store/configs/Routes';

export interface CurrentProductsCart {
  cart: ProductsCartItem[];
  refetchCart: () => void;
  cartLoading?: boolean;
}

export const ProductCartContext = React.createContext<CurrentProductsCart>({} as CurrentProductsCart);

const ProductCartGuard: React.FunctionComponent = ({ children }) => {
  const { pathname } = useLocation();
  const { data, loading, refetch, firstLoading } = useRequest<ProductsCartItem[]>(
    pathname.includes(routes.redirect) ? undefined : ProductsService.getCart
  );
  const [cart, setCart] = useState<ProductsCartItem[] | undefined>(undefined);

  useEffect(() => {
    if (data) {
      setCart(data || []);
    }
  }, [data]);

  const refetchCart = useCallback(() => {
    refetch();
  }, [refetch]);

  return firstLoading ? (
    <Spinner loading={true} fullPage={true} transparent={false} />
  ) : (
    <ProductCartContext.Provider value={{ cart: cart || [], refetchCart, cartLoading: loading }}>
      {children}
    </ProductCartContext.Provider>
  );
};
export default ProductCartGuard;
