import React, { useContext } from 'react';
import { useLocation, useParams } from 'react-router';
import routes from 'store/configs/Routes';
import Spinner from 'components/shared/Spinner';
import { CompaniesContext } from 'components/CompaniesContextWrapper';
import SiteModule from 'store/enums/SiteModule';
import { getPageTitle } from 'util/Layout';
import { ConfigContext } from 'components/ConfigGuard';
import SubscriptionsPageView from './SubscriptionsPageView';

import commonStyles from 'styles/common.module.scss';

const SubscriptionsPage: React.FunctionComponent = () => {
  const { pathname } = useLocation();
  const { subscriptionId = '' } = useParams<{ subscriptionId: string }>();
  const { sidebar } = useContext(ConfigContext);
  const { loading: companiesLoading } = useContext(CompaniesContext);
  const isRenewalPage = !!subscriptionId;
  const isEnrollPage = pathname === routes.enrollSubscription;

  return (
    <>
      <h1 className={commonStyles.pageTitle}>
        {isRenewalPage
          ? 'Subscription Renewal'
          : isEnrollPage
          ? 'Add Subscription'
          : getPageTitle(sidebar, SiteModule.Subscriptions) || 'Subscriptions'}
      </h1>
      {companiesLoading ? <Spinner loading={companiesLoading} transparent={false} /> : <SubscriptionsPageView />}
    </>
  );
};
export default SubscriptionsPage;
