import {
  ApplicationContentSection,
  ApplicationContentSectionView,
  ApplicationContentView,
} from 'store/types/ApplicationContent';
import QuestionType from 'store/enums/QuestionType';
import ApplicationReviewStatus from 'store/enums/ApplicationReviewStatus';
import ApplicationQuestion, { ApplicationQuestionView, SublistQuestion } from 'store/types/ApplicationQuestion';
import ApplicationAnswer, { SublistAnswer } from 'store/types/ApplicationAnswer';
import React from 'react';
import StatusLabel from 'components/shared/StatusLabel';
import ApplicationStatus from 'store/enums/ApplicationStatus';
import applicationStatusConfig from 'store/configs/ApplicationStatusConfig';
import { getISODateString } from 'util/Format';
import moment from 'moment/moment';

export const getQuestionFieldName = (questionId: string): string => `question-${questionId}`;

export const getQuestionId = (questionFieldName: string): string => {
  const matches: RegExpMatchArray | null = questionFieldName.match(/question-(\d+)$/);
  return matches ? matches[1] : '';
};

export const getExplanationFieldName = (parentFieldName: string): string => `${parentFieldName}-explanation`;

export const getCheckboxFieldName = (parentFieldName: string): string => `${parentFieldName}-checkbox`;

export const getSectionId = (index: number): string => `app-section-${index}`;

export const getReviewSectionId = (index: number): string => `app-review-section-${index}`;

export const getFormattedSections = (initialList: ApplicationContentSection[] = []): ApplicationContentSectionView[] =>
  getSorterSequenceList(initialList).map((section) => ({
    ...section,
    questions: getSorterSequenceList(section.questions || []).map((question) => ({
      ...question,
      sublistQuestions: question.sublistQuestions && getSorterSequenceList(question.sublistQuestions),
      fieldName: getQuestionFieldName(question.id),
    })),
  }));

const getSublistAnswerValues = (questions: SublistQuestion[]): Array<SublistAnswer[]> => {
  const result: Array<SublistAnswer[]> = [];

  questions?.forEach(({ answers, id: sublistQuestionId, content, type }) =>
    answers.forEach(({ referenceKey, answer, answerId }) => {
      const foundIndex = result.findIndex(
        (answerArray) => !!answerArray.find((answer) => answer.referenceKey === referenceKey)
      );
      const newValue = {
        referenceKey,
        answer: answer,
        answerId,
        type,
        sublistQuestionId,
        content,
      };

      if (foundIndex !== -1) {
        result[foundIndex].push(newValue);
      } else {
        result.push([newValue]);
      }
    })
  );

  return result;
};

const getSorterSequenceList = <T extends { sequence?: number }>(list: T[]) =>
  list.sort((a, b) =>
    !a.sequence && a.sequence !== 0 ? 1 : !b.sequence && b.sequence !== 0 ? -1 : a.sequence - b.sequence
  );

export const isReviewEditable = (status?: ApplicationReviewStatus): boolean =>
  !status ||
  status === ApplicationReviewStatus.PendingInfo ||
  status === ApplicationReviewStatus.PendingReview ||
  status === ApplicationReviewStatus.WaitingForReview;

export const getQuestionsDefaultValues = ({ sections = [] }: ApplicationContentView) => {
  let result = {};

  sections.forEach(({ questions = [] }: ApplicationContentSectionView) => {
    questions.forEach(({ type, fieldName, options = [], answer, sublistQuestions }: ApplicationQuestionView) => {
      switch (type) {
        case QuestionType.Checkbox:
          result = {
            ...result,
            [fieldName]: options.map(({ value = '', name, isSelected, includeExplanation, explanation }) => ({
              id: value,
              name,
              value: isSelected,
              includeExplanation,
              explanation,
            })),
          };
          break;
        case QuestionType.File:
          result = { ...result, [fieldName]: [] };
          break;
        case QuestionType.SubList:
          result = {
            ...result,
            [fieldName]: getSublistAnswerValues(sublistQuestions || []),
          };
          break;
        case QuestionType.Date:
          result = { ...result, [fieldName]: answer ? moment(answer) : '' };
          break;
        case QuestionType.Text:
        case QuestionType.MultilineText:
        case QuestionType.DropdownCustomList:
        case QuestionType.Radio:
        case QuestionType.Dropdown:
          result = { ...result, [fieldName]: answer || '' };
          break;
      }
    });
  });
  return result;
};

const getApplicationQuestionType = (
  questionId: ApplicationQuestion['id'],
  questions: ApplicationQuestion[] = []
): QuestionType => {
  const foundQuestion: ApplicationQuestion | undefined = questions.find(({ id }) => id === questionId);

  return foundQuestion ? foundQuestion.type : QuestionType.Text;
};

export const getAnswersData = (formValues: any, questionsList: ApplicationQuestion[] = []): ApplicationAnswer[] => {
  const result: ApplicationAnswer[] = [];
  Object.keys(formValues).forEach((key: string) => {
    const questionId: string = getQuestionId(key);
    if (questionId) {
      const formValue = formValues[key];
      console.log(formValue);
      const explanationFormValue: string | undefined = formValues[getExplanationFieldName(key)];
      const questionType: QuestionType = getApplicationQuestionType(questionId, questionsList);

      switch (questionType) {
        case QuestionType.Text:
        case QuestionType.MultilineText:
          result.push({ questionId, answer: formValue });
          break;
        case QuestionType.Checkbox:
          result.push({
            questionId,
            selections: (formValue || [])
              .filter(({ value }: any) => !!value)
              .map(({ id, explanation }: any) => ({ value: id, explanation })),
          });
          break;
        case QuestionType.File:
          result.push({ questionId, files: formValue });
          break;
        case QuestionType.Date:
          result.push({ questionId, answer: getISODateString(formValue) || '' });
          break;
        case QuestionType.SubList:
          result.push({ questionId, sublistAnswers: formValue });
          break;
        case QuestionType.DropdownCustomList:
          result.push({ questionId, answer: formValue });
          break;
        case QuestionType.Dropdown:
        case QuestionType.Radio:
          if (formValue) {
            result.push({ questionId, selections: [{ value: formValue, explanation: explanationFormValue }] });
          }
          break;
      }
    }
  });
  return result;
};

export const getApplicationStatusLabel = (status: string): React.ReactNode => {
  const statusConfig = applicationStatusConfig[status as ApplicationStatus];

  return <StatusLabel theme={statusConfig?.theme || 'grey'} status={statusConfig?.name || status} />;
};

export const getFormattedApplicationStatus = (status: string): string | ApplicationStatus => {
  const formattedStatus: string = status.replace(/\s/g, '').toLowerCase();

  return Object.values(ApplicationStatus).includes(formattedStatus as ApplicationStatus) ? formattedStatus : status;
};

export const getFormattedApplicationReviewStatus = (status: string): string | ApplicationReviewStatus => {
  const formattedStatus: string = status.replace(/\s/g, '').toLowerCase();

  return Object.values(ApplicationReviewStatus).includes(formattedStatus as ApplicationReviewStatus)
    ? formattedStatus
    : status;
};
