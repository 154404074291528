import React, { useCallback, useContext, useState } from 'react';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { Button, Grid } from '@mui/material';
import { ParametersContext } from 'components/ParametersGuard';
import { PriorApproval } from 'store/types/PriorApproval';
import PriorApprovalsTable from './PriorApprovalsTable';
import RequestApprovalModal from './RequestApprovalModal';

import commonStyles from 'styles/common.module.scss';

interface AdvocacyPageViewProps {
  data: PriorApproval[];
  refetch: () => void;
}

const AdvocacyPageView: React.FunctionComponent<AdvocacyPageViewProps> = ({ data, refetch }) => {
  const [submitApprovalModalOpen, setSubmitApprovalModalOpen] = useState<boolean>(false);
  const {
    profile: { priorApprovalsTableHeader },
  } = useContext(ParametersContext);

  const handleSubmitApprovalModalOpen = useCallback(() => {
    setSubmitApprovalModalOpen(true);
  }, []);

  const handleSubmitApprovalModalClose = useCallback(() => {
    setSubmitApprovalModalOpen(false);
  }, []);

  return (
    <>
      <Grid {...defaultGridContainerProps} justifyContent={'flex-end'}>
        <Grid {...defaultGridItemProps} xs={'auto'}>
          <Button
            color={'secondary'}
            variant={'contained'}
            className={commonStyles.uppercase}
            onClick={handleSubmitApprovalModalOpen}
          >
            {'Submit For Approval'}
          </Button>
        </Grid>
        <Grid {...defaultGridItemProps}>
          <h3>{'Prior Approvals'}</h3>
          {priorApprovalsTableHeader && <p dangerouslySetInnerHTML={{ __html: priorApprovalsTableHeader }} />}
          <PriorApprovalsTable data={data} showPagination={true} />
        </Grid>
      </Grid>
      {submitApprovalModalOpen && (
        <RequestApprovalModal
          open={submitApprovalModalOpen}
          onClose={handleSubmitApprovalModalClose}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default AdvocacyPageView;
