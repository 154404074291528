import { Link } from '@mui/material';
import React, { useState } from 'react';
import ComponentProps from 'store/types/ComponentProps';

import styles from './ShowMoreText.module.scss';

interface ShowMoreTextProps extends ComponentProps {
  children?: string;
  maxLength?: number;
  isHTML?: boolean;
}

const ShowMoreText: React.FunctionComponent<ShowMoreTextProps> = ({
  children = '',
  maxLength = 200,
  isHTML = false,
  className = '',
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleButtonClick = () => {
    setExpanded((prevState) => !prevState);
  };

  const actionButton = (
    <Link onClick={handleButtonClick} className={styles.button} data-testid={'show-more-text-button'}>
      {expanded ? 'show less' : 'show more'}
    </Link>
  );
  const shortText = children.length > maxLength ? `${children.substring(0, maxLength)}...` : children;

  return (
    <>
      {isHTML ? (
        <span
          className={className}
          dangerouslySetInnerHTML={{ __html: expanded ? children : shortText }}
          data-testid={'show-more-text-html'}
        />
      ) : (
        <span className={className} data-testid={'show-more-text'}>
          {expanded ? children : shortText}
        </span>
      )}
      {children.length > maxLength && actionButton}
    </>
  );
};
export default ShowMoreText;
