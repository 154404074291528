enum PaymentStatus {
  Paid = 'Paid',
  Unpaid = 'Open',
  Overdue = 'Overdue',
  Processing = 'Processing',
  PendingFulfillment = 'PendingFulfillment',
  FullyBilled = 'FullyBilled',
  PendingBilling = 'PendingBilling',
}
export default PaymentStatus;
