import React, { useCallback, useState } from 'react';
import { BasePayment } from 'store/types/Payment';
import { printDocument } from 'util/Window';
import PaymentDetailsPrintSection from './PaymentDetailsPrintSection';
import Spinner from 'components/shared/Spinner';

const printFrameId = 'payment-print-frame';
const printContentId = 'payment-print-content';

const usePaymentDetailsPrint = (
  payment?: BasePayment,
  isOrderPaymentConfirmation?: boolean,
  printCallback?: () => void,
  description?: string | React.ReactNode,
  poNumber?: string
): [() => void, React.ReactNode] => {
  const [loading, setLoading] = useState<boolean>(false);

  const handlePrintFinished = useCallback(() => {
    setLoading(false);
    if (printCallback) {
      printCallback();
    }
  }, [printCallback]);

  const printPayment = useCallback(() => {
    setLoading(true);
    printDocument(printContentId, printFrameId, handlePrintFinished);
  }, [handlePrintFinished]);

  const printComponent = (
    <>
      <Spinner loading={loading} fullPage={true} />
      {payment ? (
        <PaymentDetailsPrintSection
          poNumber={poNumber}
          description={description}
          payment={payment}
          printFrameId={printFrameId}
          printContentId={printContentId}
          isOrderPaymentConfirmation={isOrderPaymentConfirmation}
        />
      ) : null}
    </>
  );

  return [printPayment, printComponent];
};
export default usePaymentDetailsPrint;
