import { httpRequestWrapper } from 'util/Request';
import { HttpService } from 'services/HttpService';
import { API_URL, conf, tenantConfig } from 'config';
import AuthService from 'services/AuthService';
import { DonationCampaign } from 'store/types/DonationFund';
import { PartnerDetails, PartnerDonor } from 'store/types/Partner';
import moment from 'moment-timezone';

type PartnersResponseData = DonationCampaign[];
type PartnerDonationsResponseData = PartnerDonor[];

class PartnerService {
  public static getPartners(): Promise<PartnersResponseData> {
    return new Promise<PartnersResponseData>(
      (resolve: (data: PartnersResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<PartnersResponseData>(
          HttpService.get(`${API_URL}/partner/campaigns`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getPartnerDetails(id: string): Promise<PartnerDetails> {
    return new Promise<PartnerDetails>((resolve: (data: PartnerDetails) => void, reject: (error: string) => void) => {
      httpRequestWrapper<PartnerDetails>(
        HttpService.get(`${API_URL}/partner/campaign/${id}/summary`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getPartnerDonations(id: string): Promise<PartnerDonationsResponseData> {
    return new Promise<PartnerDonationsResponseData>(
      (resolve: (data: PartnerDonationsResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<PartnerDonationsResponseData>(
          HttpService.get(`${API_URL}/partner/campaign/${id}`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getPartnerDonorLost(id: string): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.getFileDownload(`${API_URL}/partner/campaign/${id}/download`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
            [conf.filenameHeader]: `Donor List ${moment().format()}.csv`,
          },
        }),
        resolve,
        reject
      );
    });
  }
}
export default PartnerService;
