import { Button, Grid } from '@mui/material';
import React, { useContext } from 'react';
import { UserContext } from 'components/UserGuard';
import EditEmailSection from './EditEmailSection';
import EditPasswordSection from './EditPasswordSection';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { tenantConfig } from 'config';
import { isInternalIdpType } from 'util/Route';
import { ParametersContext } from 'components/ParametersGuard';

import commonStyles from 'styles/common.module.scss';
import pageStyles from './EditSettingsPageView.module.scss';

const EditSettingsPageView: React.FunctionComponent = () => {
  const { email = '' } = useContext(UserContext);
  const {
    settings: { accountSettingsTabHeader },
  } = useContext(ParametersContext);

  const isInternalIdp = isInternalIdpType(tenantConfig);
  const { changeEmailUrl = '', changePasswordUrl = '' } = tenantConfig;

  return (
    <>
      {isInternalIdp ? (
        <Grid {...defaultGridContainerProps}>
          <Grid {...defaultGridItemProps}>
            {accountSettingsTabHeader && <p dangerouslySetInnerHTML={{ __html: accountSettingsTabHeader }} />}
          </Grid>
          {changeEmailUrl ? (
            <Grid {...defaultGridItemProps} sm={'auto'}>
              <Button
                color={'secondary'}
                variant={'contained'}
                type={'submit'}
                className={pageStyles.button}
                href={changeEmailUrl + encodeURIComponent(email)}
              >
                {'Change Email'}
              </Button>
            </Grid>
          ) : (
            <Grid {...defaultGridItemProps}>
              <EditEmailSection />
            </Grid>
          )}
          {changePasswordUrl ? (
            <Grid {...defaultGridItemProps} sm={'auto'}>
              <Button
                color={'secondary'}
                variant={'contained'}
                type={'submit'}
                className={pageStyles.button}
                href={changePasswordUrl + encodeURIComponent(email)}
              >
                {'Change Password'}
              </Button>
            </Grid>
          ) : (
            <Grid {...defaultGridItemProps}>
              <EditPasswordSection />
            </Grid>
          )}
        </Grid>
      ) : (
        <p className={commonStyles.text}>
          {
            'Account details are handled by your Identity Provider. Contact them if you have any questions concerning your account.'
          }
        </p>
      )}
    </>
  );
};

export default EditSettingsPageView;
