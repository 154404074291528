import Alert from '@mui/material/Alert';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import useRequest from 'hooks/useRequest';
import DonationService from 'services/api/DonationService';
import Spinner from 'components/shared/Spinner';
import DonationFund from 'store/types/DonationFund';
import NotFoundPage from 'components/layout/NotFoundPage';
import DonationPaymentPageView from 'components/donation/DonationPaymentPageView';
import DonationCampaignStatus from 'store/enums/DonationCampaignStatus';

import commonStyles from 'styles/common.module.scss';

const DonationPaymentPage: React.FunctionComponent = () => {
  const { donationId = '' } = useParams<{ donationId: string }>();
  const { loading, data = [], error } = useRequest<DonationFund[]>(DonationService.getDonationFunds);
  const [donationFund, setDonationFund] = useState<DonationFund | undefined>(undefined);

  useEffect(() => {
    if (data && data.length) {
      setDonationFund(data.find(({ donation }) => donation.id.toString() === donationId));
    }
  }, [data, donationId]);

  return loading ? (
    <Spinner loading={loading} fullPage={true} transparent={false} />
  ) : (
    <>
      {error ? (
        <Alert severity={'error'} className={commonStyles.alert}>
          {error}
        </Alert>
      ) : donationFund ? (
        donationFund.campaign?.status === DonationCampaignStatus.Active ? (
          <DonationPaymentPageView donationFund={donationFund} data={data?.map(({ campaign }) => campaign)} />
        ) : (
          <h4>{'This campaign is inactive and currently not accepting donations'}</h4>
        )
      ) : (
        <NotFoundPage />
      )}
    </>
  );
};
export default DonationPaymentPage;
