import { Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import ApplicationPageLayout from 'components/applications/ApplicationPageLayout';
import ApplicationQuestionsFormSection from 'components/applications/ApplicationQuestionsFormSection';
import Spinner from 'components/shared/Spinner';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import ApplicationsService from 'services/api/ApplicationsService';
import routes from 'store/configs/Routes';
import { ApplicationContentView } from 'store/types/ApplicationContent';
import ApplicationQuestion from 'store/types/ApplicationQuestion';
import { getExplanationFieldName, getQuestionFieldName, getQuestionsDefaultValues } from 'util/Application';
import { defaultFormProps, getUnsavedChangesPromptMessage } from 'util/Form';
import { useWindowSize } from 'util/Window';
import ApplicationReviewAnswerValue from 'store/enums/ApplicationReviewAnswerValue';
import ApplicationReviewAnswer from 'store/types/ApplicationReviewAnswer';
import ApplicationReviewDecisionValue from 'store/enums/ApplicationReviewDecisionValue';
import { ApplicationReviewDecisionFormValues } from 'store/types/FormValues';
import useUnsavedChangesPrompt from 'hooks/useUnsavedChangesPrompt';
import ApplicationReviewDecisionModal from '../ApplicationReviewDecisionModal';
import ApplicationReviewHeader from '../ApplicationReviewHeader';
import ApplicationReviewType from 'store/enums/ApplicationReviewType';
import { defaultSnackbarErrorProps } from 'util/Layout';

import commonStyles from 'styles/common.module.scss';
import pageStyles from '../ApplicationReviewPage.module.scss';

interface EditApplicationReviewPageViewProps {
  data: ApplicationContentView;
}

const defaultDecisionFormValues: ApplicationReviewDecisionFormValues = {
  decision: ApplicationReviewDecisionValue.Approve,
  comments: '',
};

const getAnswersData = (formValues: any, questionsList: ApplicationQuestion[] = []): ApplicationReviewAnswer[] => {
  const result: ApplicationReviewAnswer[] = [];

  questionsList.forEach(({ id, answerId = '', review }) => {
    const fieldName = getQuestionFieldName(id);
    const formValue: ApplicationReviewAnswerValue = formValues[fieldName] || ApplicationReviewAnswerValue.None;
    const comment: string | undefined = formValues[getExplanationFieldName(fieldName)];
    const reviewAnswerId: string | undefined = review ? review.id : undefined;

    if (formValue !== ApplicationReviewAnswerValue.None || reviewAnswerId) {
      result.push({
        id: reviewAnswerId,
        answerId,
        comment,
        response: formValue,
      });
    }
  });
  return result;
};

const EditApplicationReviewPageView: React.FunctionComponent<EditApplicationReviewPageViewProps> = ({ data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { height } = useWindowSize();
  const history = useHistory();
  const [displayValidationError, setDisplayValidationError] = useState<boolean>(false);
  const { reviewId = '' } = useParams<{ reviewId: string }>();
  const { removeUnsavedChangesPrompt, prompt } = useUnsavedChangesPrompt(getUnsavedChangesPromptMessage());
  const {
    trigger,
    getValues,
    formState: { isValid, isDirty },
  } = useFormContext();
  const applicationForm = useForm({ ...defaultFormProps, defaultValues: getQuestionsDefaultValues(data) });
  const decisionForm = useForm<ApplicationReviewDecisionFormValues>({
    ...defaultFormProps,
    defaultValues: defaultDecisionFormValues,
  });
  const [submitStarted, setSubmitStarted] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [decisionModalOpen, setDecisionModalOpen] = useState<boolean>(false);
  const questionsList: ApplicationQuestion[] = useMemo(() => {
    let result: ApplicationQuestion[] = [];

    if (data) {
      (data.sections || []).forEach(({ questions = [] }) => {
        result = result.concat(questions);
      });
    }
    return result;
  }, [data]);
  const hasContent: boolean = useMemo(() => !!(data?.sections && data?.sections.length), [data]);
  const submitButtonDisabled: boolean = useMemo(() => !hasContent || submitLoading, [hasContent, submitLoading]);

  useEffect(() => {
    setSubmitLoading(submitStarted);
  }, [submitStarted]);

  const handleFormSave = useCallback(() => {
    const formValues = getValues();
    const answersData: ApplicationReviewAnswer[] = getAnswersData(formValues, questionsList);

    setSubmitLoading(true);
    ApplicationsService.updateApplicationReview(reviewId, answersData, data.reviewType)
      .then(() => {
        setSubmitLoading(false);
        removeUnsavedChangesPrompt();
        enqueueSnackbar('Application successfully updated', { variant: 'success' });
      })
      .catch((error: string) => {
        setSubmitLoading(false);
        enqueueSnackbar(error, defaultSnackbarErrorProps);
      });
  }, [getValues, questionsList, reviewId, data.reviewType, removeUnsavedChangesPrompt, enqueueSnackbar]);

  const submitDecision = useCallback(() => {
    const decisionFormValues: ApplicationReviewDecisionFormValues = decisionForm.getValues();

    setSubmitLoading(true);
    ApplicationsService.submitApplicationReview(reviewId, decisionFormValues)
      .then(() => {
        enqueueSnackbar('Review successfully submitted', { variant: 'success' });
        history.push(routes.applicationReviews);
        setSubmitStarted(false);
      })
      .catch((error: string) => {
        setSubmitStarted(false);
        enqueueSnackbar(error, defaultSnackbarErrorProps);
      });
  }, [decisionForm, enqueueSnackbar, history, reviewId]);

  const handleFormSubmit = useCallback(() => {
    const answersData: ApplicationReviewAnswer[] = getAnswersData(getValues(), questionsList);

    setSubmitStarted(true);
    ApplicationsService.updateApplicationReview(reviewId, answersData, data.reviewType)
      .then(() => {
        removeUnsavedChangesPrompt();
        submitDecision();
      })
      .catch((error: string) => {
        setSubmitStarted(false);
        enqueueSnackbar(error, defaultSnackbarErrorProps);
      });
  }, [
    getValues,
    questionsList,
    reviewId,
    data.reviewType,
    removeUnsavedChangesPrompt,
    submitDecision,
    enqueueSnackbar,
  ]);

  const handleCancel = useCallback(() => {
    history.push(routes.applicationReviews);
  }, [history]);

  const handleDecisionModalOpen = useCallback(() => {
    if ((!hasContent || submitLoading || !isValid || !isDirty) && data.reviewType === ApplicationReviewType.Standard) {
      trigger();
      setDisplayValidationError(true);
    } else {
      setDecisionModalOpen(true);
      setDisplayValidationError(false);
    }
  }, [data, hasContent, isDirty, isValid, submitLoading, trigger]);

  const handleDecisionModalClose = useCallback(() => {
    setDecisionModalOpen(false);
  }, []);

  return (
    <>
      <ApplicationPageLayout
        data={data}
        isReviewForm={true}
        footerProps={{
          onSubmit: handleFormSave,
          submitButtonName: 'Save Changes',
          hideSubmitButton: data.reviewType === ApplicationReviewType.Basic,
          submitButtonDisabled,
          children: (
            <Button
              color={'secondary'}
              variant={'outlined'}
              onClick={handleCancel}
              className={pageStyles.cancelButton}
              disabled={submitLoading}
            >
              {'Go Back'}
            </Button>
          ),
        }}
      >
        <Spinner loading={submitLoading}>
          {data && (
            <>
              <ApplicationReviewHeader data={data} onDecisionButtonClick={handleDecisionModalOpen} />
              {hasContent ? (
                <div className={pageStyles.content} style={{ height }}>
                  <div className={pageStyles.formWrapper}>
                    {data.title && (
                      <h1 className={commonStyles.pageTitle}>
                        {data.campaignName ? `${data.title} (${data.campaignName})` : data.title}
                        {` (ID# ${data.id})`}
                      </h1>
                    )}
                    <FormProvider {...applicationForm}>
                      <ApplicationQuestionsFormSection
                        sections={data.sections}
                        disabled={true}
                        applicationId={data.id}
                      />
                    </FormProvider>
                  </div>
                  {data?.reviewType === ApplicationReviewType.Standard && (
                    <div className={pageStyles.formWrapper}>
                      <h1 className={commonStyles.pageTitle}>Review</h1>
                      {displayValidationError && (
                        <Alert severity={'error'}>
                          {'Your review cannot be completed. Please enter all required fields.'}
                        </Alert>
                      )}
                      <ApplicationQuestionsFormSection
                        sections={data.sections}
                        isReviewForm={true}
                        applicationId={data.id}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <Alert severity={'error'} className={commonStyles.alert}>
                  {'No content found'}
                </Alert>
              )}
            </>
          )}
        </Spinner>
      </ApplicationPageLayout>
      <FormProvider {...decisionForm}>
        <ApplicationReviewDecisionModal
          loading={submitLoading}
          onSubmit={handleFormSubmit}
          open={decisionModalOpen}
          onClose={handleDecisionModalClose}
        />
      </FormProvider>
      {prompt}
    </>
  );
};
export default EditApplicationReviewPageView;
