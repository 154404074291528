import { Button, Grid } from '@mui/material';
import Add from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';
import classNames from 'classnames';
import React, { useContext } from 'react';
import useRequest from 'hooks/useRequest';
import PaymentService from 'services/api/PaymentService';
import DonationPayment from 'store/types/DonationPayment';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import routes from 'store/configs/Routes';
import { getHashRouteUrl } from 'util/Route';
import PageCard from 'components/shared/PageCard';
import Spinner from 'components/shared/Spinner';
import DonationPaymentsTable from './DonationPaymentsTable';
import { ParametersContext } from 'components/ParametersGuard';

import commonStyles from 'styles/common.module.scss';
import styles from './ManageDonationsPageView.module.scss';

const ManageDonationsPageView: React.FunctionComponent = () => {
  const donationPaymentsRequest = useRequest<DonationPayment[]>(PaymentService.getDonationPaymentsList);
  const { firstLoading, data, error } = donationPaymentsRequest;
  const {
    donations: { donationsPageHeader },
  } = useContext(ParametersContext);

  const addButton: React.ReactNode = (
    <Button
      color={'secondary'}
      variant={'contained'}
      startIcon={<Add />}
      className={classNames(commonStyles.uppercase, styles.addButton)}
      href={getHashRouteUrl(routes.donations)}
    >
      {'Donate Now'}
    </Button>
  );

  return (
    <>
      {firstLoading ? (
        <Spinner loading={true} transparent={false} />
      ) : (
        <Grid {...defaultGridContainerProps} alignItems={'center'} justifyContent={'space-between'}>
          <Grid {...defaultGridItemProps} md={true}>
            {donationsPageHeader && <p dangerouslySetInnerHTML={{ __html: donationsPageHeader }} />}
          </Grid>
          <Grid {...defaultGridItemProps} sm={'auto'}>
            {addButton}
          </Grid>
          <Grid {...defaultGridItemProps}>
            {error ? (
              <Alert severity={'error'}>{error}</Alert>
            ) : data && data.length ? (
              <Grid {...defaultGridContainerProps}>
                <Grid {...defaultGridItemProps}>
                  <DonationPaymentsTable {...donationPaymentsRequest} />
                </Grid>
              </Grid>
            ) : (
              <PageCard title={'Recurring Donation'} className={styles.noDataCard}>
                <p>{'Support our cause by giving back on a recurring basis. Donate to sign up.'}</p>
              </PageCard>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default ManageDonationsPageView;
