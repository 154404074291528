import { ConfigSelectOption } from 'store/types/SelectOption';
import PaymentMethodStatus from 'store/enums/PaymentMethodStatus';

const paymentMethodStatusConfig: Record<PaymentMethodStatus, ConfigSelectOption> = {
  [PaymentMethodStatus.Default]: {
    name: 'Default',
    theme: 'navy',
  },
  [PaymentMethodStatus.Active]: {
    name: 'Current',
    theme: 'green',
  },
  [PaymentMethodStatus.Expired]: {
    name: 'Expired',
    theme: 'grey',
  },
};

export default paymentMethodStatusConfig;
