import { sorter, userFullNameSortLabel } from 'util/Table';
import { SortConfig } from 'store/types/Table';
import CompanyUser from 'store/types/CompanyUser';

export enum CompanyEmployeesTableActionType {
  SetInitialList = 'SetInitialList',
  UpdateSort = 'UpdateSort',
  UpdateFilter = 'UpdateFilter',
  OpenRemoveModal = 'OpenRemoveModal',
  CloseRemoveModal = 'CloseRemoveModal',
  OpenViewUserModal = 'OpenViewUserModal',
  CloseViewUserModal = 'CloseViewUserModal',
}

export type TableCompanyEmployee = CompanyUser & { [userFullNameSortLabel]?: string };

export interface CompanyEmployeesTableState {
  initialList: TableCompanyEmployee[];
  list: TableCompanyEmployee[];
  sort: SortConfig<TableCompanyEmployee>;
  clickedItem?: TableCompanyEmployee;
  selectedUser?: TableCompanyEmployee;
}

export interface CompanyEmployeesTableAction {
  type: CompanyEmployeesTableActionType;
  payload: Partial<Omit<CompanyEmployeesTableState, 'initialList'>> & {
    initialList?: CompanyUser[];
    selectedItem?: TableCompanyEmployee;
  };
}

const getSortedList = (
  list: TableCompanyEmployee[] = [],
  sort: SortConfig<TableCompanyEmployee>
): TableCompanyEmployee[] => {
  return list.length ? [...list].sort(sorter<TableCompanyEmployee>(sort)) : [...list];
};

export const initialState: CompanyEmployeesTableState = {
  initialList: [],
  list: [],
  sort: { direction: 'asc', column: userFullNameSortLabel },
  clickedItem: undefined,
};

const reducer = (
  state: CompanyEmployeesTableState,
  { type, payload }: CompanyEmployeesTableAction
): CompanyEmployeesTableState => {
  if (type === CompanyEmployeesTableActionType.SetInitialList) {
    const { initialList = [] } = payload;

    return { ...state, initialList, list: getSortedList(initialList, state.sort) };
  }

  if (type === CompanyEmployeesTableActionType.UpdateSort) {
    const { sort = {} } = payload;
    const newList = getSortedList(state.initialList, sort);

    return { ...state, sort, list: newList };
  }

  if (type === CompanyEmployeesTableActionType.OpenRemoveModal) {
    const { clickedItem } = payload;

    return { ...state, clickedItem };
  }

  if (type === CompanyEmployeesTableActionType.CloseRemoveModal) {
    return { ...state, clickedItem: undefined };
  }

  if (type === CompanyEmployeesTableActionType.OpenViewUserModal) {
    const { selectedUser } = payload;

    return { ...state, selectedUser };
  }

  if (type === CompanyEmployeesTableActionType.CloseViewUserModal) {
    return { ...state, selectedUser: undefined };
  }

  return state;
};

export default reducer;
