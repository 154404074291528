import { Divider, Grid, MenuItem, TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import classNames from 'classnames';
import PaymentFormSection from 'components/payments/PaymentFormSection';
import PaymentDetailsModal from 'components/payments/PaymentDetailsModal';
import FormFooter from 'components/shared/FormFooter';
import Spinner from 'components/shared/Spinner';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, Reducer, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import { useHistory, useLocation } from 'react-router';
import { PaymentFormValues } from 'store/types/FormValues';
import Payment, { OrderPayment } from 'store/types/Payment';
import UseRequestData from 'store/types/UseRequestData';
import PaymentSummaryItem from 'store/types/PaymentSummaryItem';
import CollectJSResponse from 'store/types/CollectJSResponse';
import {
  getFormattedOrdersList,
  getPaymentData,
  paymentFormInvalid,
  shouldValidatePaymentMethod,
  getSummaryTotalPrice,
  preselectedOrdersStatePropName,
  getFormattedPayment,
  getInvoicesData,
  CREDITS_LABEL,
} from 'util/Payment';
import PaymentService, { BasePaymentData, OrderPaymentData } from 'services/api/PaymentService';
import InvoicesTable from 'components/payments/InvoicesTable';
import { PaymentMethodsContext } from 'components/PaymentMethodsContextWrapper';
import { ALL_PROGRAMS_OPTION } from 'util/Company';
import reducer, {
  initialState,
  MakePaymentPageAction,
  MakePaymentPageActionType,
  MakePaymentPageState,
} from './MakePaymentPageViewReducer';
import { ALL_COMPANIES_DROPDOWN_KEY } from 'util/Company';
import { CurrentUser, UserPrograms } from 'store/types/User';
import { UserContext } from 'components/UserGuard';
import CompanyService from 'services/api/CompanyService';
import useRequest from 'hooks/useRequest';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import { CompanyPaymentDetails } from 'store/types/Company';
import { ParametersContext } from 'components/ParametersGuard';
import { SiteConfigView } from 'store/types/SiteConfig';
import { ConfigContext } from 'components/ConfigGuard';

import commonStyles from 'styles/common.module.scss';
import styles from './MakePaymentPageView.module.scss';

interface MakePaymentPageViewProps extends UseRequestData<OrderPayment[]> {
  companyId?: string;
  programId?: string;
  onProgramChange?: (value: string) => void;
  salesOrders?: boolean;
}

const MakePaymentPageView: React.FunctionComponent<MakePaymentPageViewProps> = ({
  data,
  refetch,
  loading,
  error,
  companyId = '',
  programId,
  onProgramChange,
  salesOrders,
}) => {
  const {
    modulesSettings: {
      payments: { invoices },
    },
  }: SiteConfigView = useContext(ConfigContext);
  const history = useHistory();
  const { id = '' }: CurrentUser = useContext(UserContext);
  const { state, pathname } = useLocation<{ [preselectedOrdersStatePropName]?: string[] }>();
  const { enqueueSnackbar } = useSnackbar();
  const { companyPayments, personalPayments } = useContext(ParametersContext);
  const { refetch: refetchPaymentMethods } = useContext(PaymentMethodsContext);
  const [{ paymentForm, payment, submitLoading, ordersList, selectedOrders, isFirstPayment }, dispatch] = useReducer<
    Reducer<MakePaymentPageState, MakePaymentPageAction>
  >(reducer, initialState);
  const submitButtonDisabled = useMemo(
    () => !selectedOrders.length || paymentFormInvalid(paymentForm),
    [selectedOrders.length, paymentForm]
  );
  const { credits = 0 } = paymentForm;
  const companyDetailsRequest = useMemo(
    () =>
      companyId === ALL_COMPANIES_DROPDOWN_KEY
        ? undefined
        : () => CompanyService.getCompanyPaymentDetails(companyId || id),
    [companyId, id]
  );
  const {
    data: companyDetailsData,
    loading: creditMemoLoading,
    refetch: refetechCreditMemo,
  } = useRequest<CompanyPaymentDetails>(companyDetailsRequest);
  const creditMemoBalance: number = useMemo(() => companyDetailsData?.creditMemoBalance || 0, [companyDetailsData]);
  const userPrograms: UserPrograms[] = useMemo(() => companyDetailsData?.programs || [], [companyDetailsData]);

  useEffect(() => {
    const newSummaryList: PaymentSummaryItem[] = selectedOrders.map(
      ({ amountRemaining, invoiceName, orderNumber, totalAmount }) => ({
        name: invoiceName || orderNumber,
        price: salesOrders ? totalAmount : amountRemaining,
      })
    );
    if (credits) {
      newSummaryList.push({
        name: CREDITS_LABEL,
        price: credits,
      });
    }
    dispatch({
      type: MakePaymentPageActionType.UpdatePaymentForm,
      payload: {
        paymentForm: {
          summaryList: newSummaryList,
          totalPrice: getSummaryTotalPrice(newSummaryList),
        },
      },
    });

    if (!selectedOrders.length) {
      dispatch({
        type: MakePaymentPageActionType.UpdatePaymentForm,
        payload: {
          paymentForm: {
            credits: 0,
          },
        },
      });
    }
  }, [selectedOrders, credits, salesOrders]);

  useEffect(() => {
    if (ordersList.length && state && state[preselectedOrdersStatePropName]) {
      const foundOrders: OrderPayment[] = [];

      ordersList.forEach((order) => {
        if ((state[preselectedOrdersStatePropName] || []).includes(order.id)) {
          foundOrders.push(order);
        }
      });
      if (foundOrders.length) {
        dispatch({
          type: MakePaymentPageActionType.UpdateSelectedOrdersList,
          payload: { selectedOrders: foundOrders },
        });
      }
    }
  }, [ordersList, state, pathname, history]);

  useEffect(() => {
    dispatch({
      type: MakePaymentPageActionType.SetOrdersList,
      payload: { isFirstPayment, ordersList: getFormattedOrdersList(data, true, salesOrders) },
    });
  }, [data, isFirstPayment, salesOrders]);

  const handlePaymentChange = useCallback((formValues: Partial<PaymentFormValues>) => {
    dispatch({
      type: MakePaymentPageActionType.UpdatePaymentForm,
      payload: { paymentForm: formValues },
    });
  }, []);

  const handleOrdersSelected = useCallback((selectedItems: OrderPayment[]) => {
    dispatch({
      type: MakePaymentPageActionType.UpdateSelectedOrdersList,
      payload: { selectedOrders: selectedItems },
    });
  }, []);

  const handleConfirmationModalClose = useCallback(() => {
    window.scrollTo(0, 0);
    dispatch({
      type: MakePaymentPageActionType.ClosePaymentConfirmation,
      payload: {},
    });
  }, []);

  const handleSubmit = () => {
    dispatch({ type: MakePaymentPageActionType.SetSubmitLoading, payload: { submitLoading: true } });
    if (shouldValidatePaymentMethod(paymentForm)) {
      (window as any).CollectJS.startPaymentRequest();
    } else {
      makePayment();
    }
  };

  const handlePaymentComplete = (response: CollectJSResponse) => {
    makePayment(response);
  };

  const makePayment = useCallback(
    (collectJSResponse?: CollectJSResponse) => {
      const basePaymentData: BasePaymentData = getPaymentData(paymentForm, collectJSResponse);
      const paymentData: OrderPaymentData = {
        ...basePaymentData,
        invoices: salesOrders ? undefined : getInvoicesData(selectedOrders, companyId === ALL_COMPANIES_DROPDOWN_KEY),
        salesOrders: !salesOrders
          ? undefined
          : getInvoicesData(selectedOrders, companyId === ALL_COMPANIES_DROPDOWN_KEY, salesOrders),
      };

      (companyId === ALL_COMPANIES_DROPDOWN_KEY
        ? salesOrders
          ? PaymentService.makeMultipleCompaniesSalesPayment(paymentData)
          : PaymentService.makeMultipleCompaniesPayment(paymentData)
        : companyId
        ? salesOrders
          ? PaymentService.makeCompanySalesPayment(paymentData, companyId)
          : PaymentService.makeCompanyPayment(paymentData, companyId)
        : salesOrders
        ? PaymentService.makeSalesPayment(paymentData)
        : PaymentService.makePayment(paymentData)
      )
        .then((newPayment: Payment) => {
          (window as any).CollectJS.clearInputs();
          refetchPaymentMethods();
          enqueueSnackbar('Payment successfully confirmed', { variant: 'success' });
          refetechCreditMemo();
          dispatch({
            type: MakePaymentPageActionType.OpenPaymentConfirmation,
            payload: {
              payment: getFormattedPayment(newPayment),
            },
          });
          dispatch({
            type: MakePaymentPageActionType.SetFirstPaymentFlag,
            payload: {
              isFirstPayment: false,
            },
          });
          dispatch({
            type: MakePaymentPageActionType.UpdatePaymentForm,
            payload: {
              isFirstPayment: false,
              paymentForm: {
                summaryList: [],
                credits: 0,
              },
            },
          });
          refetch();
        })
        .catch((errorMessage: string) => {
          dispatch({ type: MakePaymentPageActionType.SetSubmitLoading, payload: { submitLoading: false } });
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        });
    },
    [
      paymentForm,
      salesOrders,
      selectedOrders,
      companyId,
      refetchPaymentMethods,
      enqueueSnackbar,
      refetechCreditMemo,
      refetch,
    ]
  );

  const handleProgramSelect = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (onProgramChange) {
        onProgramChange(e.target.value);
      }
    },
    [onProgramChange]
  );

  return error ? (
    <Alert severity={'error'} className={commonStyles.alert}>
      {error}
    </Alert>
  ) : (
    <>
      <Grid {...defaultGridContainerProps}>
        {!!userPrograms?.length && companyId && (
          <Grid {...defaultGridItemProps} sm={'auto'}>
            <div className={styles.select}>
              <span className={styles.selectLabel}>{'Program'}</span>
              <TextField
                select={true}
                variant={'outlined'}
                size={'small'}
                disabled={loading}
                value={programId}
                onChange={handleProgramSelect}
              >
                <MenuItem value={ALL_PROGRAMS_OPTION}>{ALL_PROGRAMS_OPTION}</MenuItem>
                {userPrograms.map(({ id, name }) => (
                  <MenuItem value={id} key={`program-${id}`}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Grid>
        )}
        <Grid {...defaultGridItemProps}>
          <h3 className={commonStyles.subTitle}>{salesOrders ? 'Outstanding Sales Orders' : 'Outstanding Invoices'}</h3>
          {companyId
            ? companyPayments.outstandingInvoicesTableHeader && (
                <p dangerouslySetInnerHTML={{ __html: companyPayments.outstandingInvoicesTableHeader }} />
              )
            : personalPayments.outstandingInvoicesTableHeader && (
                <p dangerouslySetInnerHTML={{ __html: personalPayments.outstandingInvoicesTableHeader }} />
              )}
          <Spinner loading={submitLoading}>
            <form>
              <InvoicesTable
                data={ordersList}
                selectedItems={selectedOrders}
                onItemsSelected={handleOrdersSelected}
                loading={loading}
                isCompany={!!companyId}
                salesOrders={salesOrders}
              />
              <div className={classNames({ [commonStyles.hidden]: !selectedOrders.length })}>
                <Divider className={styles.divider} />
                <PaymentFormSection
                  onlySavedAddresses={true}
                  form={paymentForm}
                  onChange={handlePaymentChange}
                  onPaymentComplete={handlePaymentComplete}
                  id={companyId || id}
                  creditMemoBalance={creditMemoBalance}
                  loading={creditMemoLoading}
                  salesOrders={salesOrders}
                  paymentConfig={invoices}
                />
              </div>
              <FormFooter
                onSubmit={handleSubmit}
                submitButtonName={'Complete Payment'}
                submitButtonDisabled={submitButtonDisabled}
                className={classNames({ [commonStyles.hidden]: !selectedOrders.length })}
              />
            </form>
          </Spinner>
        </Grid>
      </Grid>
      {!!payment && (
        <PaymentDetailsModal
          title={'Payment Confirmation'}
          open={!!payment}
          companyPayment={!!companyId}
          payment={payment}
          onClose={handleConfirmationModalClose}
          isOrderPaymentConfirmation={true}
        />
      )}
    </>
  );
};
export default MakePaymentPageView;
