import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import Spinner from 'components/shared/Spinner';
import { UserContext } from 'components/UserGuard';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import ManageDonationsPageView from 'components/donation/ManageDonationsPageView';

import commonStyles from 'styles/common.module.scss';

const ManageDonationPage: React.FunctionComponent = () => {
  const { userLoading = false } = useContext(UserContext);

  return (
    <>
      <h1 className={commonStyles.pageTitle}>{'Recurring Donations'}</h1>

      <Spinner loading={userLoading} transparent={false}>
        <Grid {...defaultGridContainerProps}>
          <Grid {...defaultGridItemProps} sm={true}>
            <ManageDonationsPageView />
          </Grid>
        </Grid>
      </Spinner>
    </>
  );
};
export default ManageDonationPage;
