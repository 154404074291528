import { Badge, CircularProgress, SvgIconProps } from '@mui/material';
import ShoppingCartOutlined from '@mui/icons-material/ShoppingCartOutlined';
import classNames from 'classnames';
import React, { useContext } from 'react';
import { EventCartContext } from 'components/EventCartGuard';

import styles from './EventCartIcon.module.scss';

const EventCartIcon: React.FunctionComponent<SvgIconProps> = (props) => {
  const { cart = [], cartLoading } = useContext(EventCartContext);

  return (
    <Badge
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      badgeContent={cartLoading ? <CircularProgress size={8} /> : cart.length}
      showZero={true}
      classes={{ badge: classNames(styles.badge, { [styles.noItems]: !cart.length }) }}
    >
      <ShoppingCartOutlined {...props} />
    </Badge>
  );
};
export default EventCartIcon;
