import React from 'react';
import { DonationHeaderValues } from 'store/types/DonationFund';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { Avatar, Grid } from '@mui/material';
import { getPrice } from 'util/Payment';
import { getFormattedDate } from 'util/Format';

import styles from './DonationHeader.module.scss';

interface DonationHeaderProps {
  data: DonationHeaderValues;
}
const DonationHeader: React.FunctionComponent<DonationHeaderProps> = ({
  data: {
    backgroundColor,
    imageUrl,
    description,
    targetDate,
    partner,
    goal,
    raised,
    isDisplayProgress,
    logoUrl,
    name,
    displayTargetDate,
    displayGoal,
    fontColor,
  },
}) => (
  <section style={{ backgroundColor, color: fontColor }} className={styles.container}>
    <Grid {...defaultGridContainerProps} flexDirection={'row'} spacing={0}>
      {imageUrl && (
        <Grid {...defaultGridItemProps} sm={3}>
          <Avatar src={imageUrl} alt={'image'} variant={'square'} className={styles.image} />
        </Grid>
      )}
      <Grid {...defaultGridItemProps} sm={imageUrl ? 9 : 12}>
        <div className={styles.content}>
          <Avatar src={logoUrl} variant={'square'} className={styles.logo} />
          <div className={styles.wrapper}>
            <h1 className={styles.title} style={{ color: fontColor }}>
              {name}
            </h1>
            <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
          </div>
          <span>
            {`Presented by`} <b>{partner.name}</b>
          </span>
          {(displayGoal || displayTargetDate || isDisplayProgress) && (
            <Grid {...defaultGridContainerProps} spacing={0}>
              {displayGoal && (
                <Grid {...defaultGridItemProps} className={styles.box} xs={true}>
                  <span className={styles.label}>{'Goal'}</span>
                  <span className={styles.value}>{getPrice(goal)}</span>
                </Grid>
              )}
              {isDisplayProgress && (
                <Grid {...defaultGridItemProps} className={styles.box} xs={true}>
                  <span className={styles.label}>{'Currently'}</span>
                  <span className={styles.value}>{getPrice(raised)}</span>
                </Grid>
              )}
              {displayTargetDate && (
                <Grid {...defaultGridItemProps} className={styles.box} xs={true}>
                  <span className={styles.label}>{'Target Date'}</span>
                  <span className={styles.value}> {getFormattedDate(targetDate)}</span>
                </Grid>
              )}
            </Grid>
          )}
        </div>
      </Grid>
    </Grid>
  </section>
);
export default DonationHeader;
