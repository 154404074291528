import { Button, Grid, MenuItem, TextField } from '@mui/material';
import Modal from 'components/shared/Modal';
import { useSnackbar } from 'notistack';
import React, { useContext, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ModalProps } from 'store/types/ComponentProps';
import { DEFAULT_ERROR_MESSAGE, defaultFormProps, getRequiredValidationRule, getValidationProps } from 'util/Form';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import PaymentUserAccessLevel from 'store/enums/PaymentUserAccessLevel';
import PaymentService from 'services/api/PaymentService';
import paymentAccessLevelConfig from 'store/configs/PaymentAccessLevelConfig';
import SelectOption from 'store/types/SelectOption';
import { ConfigContext } from 'components/ConfigGuard';
import UserSearchFormItem from 'components/profile/UserSearchFormItem';

import commonStyles from 'styles/common.module.scss';

interface LinkPaymentUserModalProps extends ModalProps {
  onSubmit: () => void;
}

interface LinkPaymentUserFormValues {
  users: SelectOption[];
  accessLevel: PaymentUserAccessLevel;
}

const defaultValues: LinkPaymentUserFormValues = {
  users: [],
  accessLevel: PaymentUserAccessLevel.Full,
};

const LinkPaymentUserModal: React.FunctionComponent<LinkPaymentUserModalProps> = ({ open, onClose, onSubmit }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isNccerTheme } = useContext(ConfigContext);
  const form = useForm<LinkPaymentUserFormValues>({ ...defaultFormProps, defaultValues });
  const [loading, setLoading] = useState<boolean>(false);
  const {
    handleSubmit,
    formState: { isValid, isDirty, errors },
    control,
  } = form;

  const handleFormSubmit = ({ users = [], accessLevel }: LinkPaymentUserFormValues) => {
    const accountId: string = users.length ? users[0].id : '';

    if (accountId) {
      setLoading(true);
      PaymentService.linkGroupPaymentUser({ accountId, accessLevel })
        .then(() => {
          setLoading(false);
          enqueueSnackbar('Request successfully sent', { variant: 'success' });
          onSubmit();
        })
        .catch((errorMessage: string) => {
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
          setLoading(false);
        });
    } else {
      enqueueSnackbar(DEFAULT_ERROR_MESSAGE, defaultSnackbarErrorProps);
    }
  };

  return (
    <FormProvider {...form}>
      <Modal
        open={open}
        onClose={onClose}
        loading={loading}
        title={'Request Access'}
        actions={
          <>
            <Button color={'secondary'} variant={'outlined'} onClick={onClose}>
              Cancel
            </Button>
            <Button
              color={'secondary'}
              variant={'contained'}
              type={'submit'}
              disabled={!isValid || !isDirty}
              onClick={handleSubmit(handleFormSubmit)}
            >
              Request
            </Button>
          </>
        }
      >
        <Grid {...defaultGridContainerProps}>
          <Grid {...defaultGridItemProps}>
            <p className={commonStyles.text}>
              {`To request access to user’s membership dues and/or invoing, please search for them by their Email or a Card number associated with user’s ${
                isNccerTheme ? 'NCCER' : 'MemberPlex'
              } account. The user will be emailed to grant access to their account. Upon user’s confirmation, you will see their information on the Group Payment page, and will be able to make payments on their behalf.`}
            </p>
          </Grid>
          <Grid {...defaultGridItemProps}>
            <UserSearchFormItem />
          </Grid>
          <Grid {...defaultGridItemProps}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  select={true}
                  label={'Requested Access Level'}
                  required={true}
                  {...getValidationProps('accessLevel', errors)}
                >
                  {paymentAccessLevelConfig.map(({ value, text }) => (
                    <MenuItem value={value} key={`accessLevel-${value}`}>
                      {text}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              defaultValue={defaultValues.accessLevel}
              name={'accessLevel'}
              control={control}
              rules={{ required: getRequiredValidationRule('access level', true) }}
            />
          </Grid>
        </Grid>
      </Modal>
    </FormProvider>
  );
};
export default LinkPaymentUserModal;
