import { FilterConfig } from 'store/types/Table';
import { EventRegistrationViewStatus } from 'store/enums/events/EventRegistrationStatus';
import { getListByPage, sorter } from 'util/Table';
import { ALL_EVENT_MODES_VALUE } from 'util/Event';
import {
  EventSessionRegistration,
  EventSessionRegistrationView,
  MainEventRegistration,
  MainEventRegistrationView,
} from 'store/types/events/EventRegistration';
import moment from 'moment';

export const ITEMS_PER_PAGE = 5;

export enum EventRegistrationsPageViewActionType {
  SetInitialList = 'SetInitialList',
  UpdateFilter = 'UpdateFilter',
  UpdatePage = 'UpdatePage',
}

export interface EventRegistrationsPageViewState {
  initialList: MainEventRegistrationView[];
  list: MainEventRegistrationView[];
  pageList: MainEventRegistrationView[];
  filter: FilterConfig<MainEventRegistrationView>;
  page: number;
}

export interface EventRegistrationsPageViewAction {
  type: EventRegistrationsPageViewActionType;
  payload: Partial<Omit<EventRegistrationsPageViewState, 'initialList'>> & {
    initialList?: MainEventRegistration[];
  };
}

export const initialState: EventRegistrationsPageViewState = {
  initialList: [],
  list: [],
  pageList: [],
  page: 1,
  filter: { search: '', mode: ALL_EVENT_MODES_VALUE, viewStatus: EventRegistrationViewStatus.Upcoming },
};

const getFilteredList = (list: MainEventRegistrationView[] = [], filter: FilterConfig<MainEventRegistrationView>) => {
  const searchValue = (filter.search || '').toLowerCase();
  let result = list;

  if (searchValue) {
    result = result.filter(({ title }: MainEventRegistrationView) => title.toLowerCase().includes(searchValue));
  }

  return result;
};

const getMainEventRegistrationView = (data: MainEventRegistration): MainEventRegistrationView => ({
  ...data,
  startDate: moment.utc(data.startDate),
  endDate: moment.utc(data.endDate),
  guests: data.guests?.map((item: MainEventRegistration) => getMainEventRegistrationView(item)),
  sessions: data.sessions?.map((session) => getEventSessionRegistrationView(session)),
});

const getEventSessionRegistrationView = (data: EventSessionRegistration): EventSessionRegistrationView => ({
  ...data,
  startDate: moment.utc(data.startDate),
  endDate: moment.utc(data.endDate),
});

const reducer = (
  state: EventRegistrationsPageViewState,
  { type, payload }: EventRegistrationsPageViewAction
): EventRegistrationsPageViewState => {
  if (type === EventRegistrationsPageViewActionType.SetInitialList) {
    const { initialList = [] } = payload;
    const formattedInitialList: MainEventRegistrationView[] = [];

    initialList.sort(sorter({ column: 'startDate', direction: 'desc' })).forEach((item) => {
      formattedInitialList.push(getMainEventRegistrationView(item));
    });
    const list = getFilteredList(formattedInitialList, state.filter);

    return {
      ...state,
      initialList: formattedInitialList,
      list,
      page: 1,
      pageList: getListByPage(list, ITEMS_PER_PAGE),
    };
  }

  if (type === EventRegistrationsPageViewActionType.UpdateFilter) {
    const { filter = {} } = payload;
    const newFilter = { ...state.filter, ...filter };
    const list = getFilteredList(state.initialList, newFilter);

    return {
      ...state,
      filter: newFilter,
      list,
      pageList: getListByPage(list, ITEMS_PER_PAGE, state.page),
    };
  }

  if (type === EventRegistrationsPageViewActionType.UpdatePage) {
    const { page = 1 } = payload;

    return {
      ...state,
      page,
      pageList: getListByPage(state.list, ITEMS_PER_PAGE, page),
    };
  }

  return state;
};

export default reducer;
