import React, { ChangeEvent, Reducer, useCallback, useEffect, useMemo, useReducer } from 'react';
import reducer, {
  ALL_DONORS_VALUE,
  FilterFormProps,
  initialState,
  PartnerCampaignDetailsPageAction,
  PartnerCampaignDetailsPageActionType,
  PartnerCampaignDetailsPageState,
} from './PartnerCampaignDetailsTableReducer';
import { PartnerDonor } from 'store/types/Partner';
import { getPrice } from 'util/Payment';
import { getEmailLink, getFormattedDate, NUMBER_DATE_FORMAT } from 'util/Format';
import { SortConfig, TableColumn } from 'store/types/Table';
import Table from 'components/shared/Table';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import SearchInput from 'components/shared/SearchInput';
import { Grid, MenuItem, TextField } from '@mui/material';
import { defaultFormProps } from 'util/Form';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import DateFormItem from 'components/shared/DateFormItem';
import moment from 'moment-timezone';
import Spinner from 'components/shared/Spinner';

import styles from './PartnerCampaignDetailsTable.module.scss';

interface PartnerCampaignDetailsTableProps {
  partnerDonations: PartnerDonor[];
  loading: boolean;
}

const PartnerCampaignDetailsTable: React.FunctionComponent<PartnerCampaignDetailsTableProps> = ({
  partnerDonations,
  loading,
}) => {
  const [{ list, filter, sort }, dispatch] = useReducer<
    Reducer<PartnerCampaignDetailsPageState, PartnerCampaignDetailsPageAction>
  >(reducer, initialState);
  const form = useForm<FilterFormProps>({
    ...defaultFormProps,
    defaultValues: {
      startDate: '',
      endDate: '',
    },
  });
  const { control } = form;
  const [startDate, endDate] = useWatch({ control, name: ['startDate', 'endDate'] });
  const donorList: string[] = useMemo(
    () => Array.from(new Set(partnerDonations.map(({ donor }) => donor))),
    [partnerDonations]
  );

  useEffect(() => {
    dispatch({
      type: PartnerCampaignDetailsPageActionType.SetInitialList,
      payload: { initialList: partnerDonations },
    });
  }, [partnerDonations]);

  useEffect(() => {
    dispatch({
      type: PartnerCampaignDetailsPageActionType.UpdateFilter,
      payload: { filter: { startDate, endDate } },
    });
  }, [endDate, startDate]);

  const columns: Array<TableColumn<PartnerDonor>> = useMemo(
    () => [
      { dataIndex: 'donor', label: 'Donor', sortable: true },
      {
        dataIndex: 'email',
        label: 'Email',
        sortable: true,
        render: (email: string) => getEmailLink(email),
      },
      {
        dataIndex: 'date',
        label: 'Date',
        sortable: true,
        render: (date: string) => getFormattedDate(date, NUMBER_DATE_FORMAT),
      },
      {
        dataIndex: 'amount',
        label: 'Amount',
        sortable: true,
        render: (amount: number) => getPrice(amount),
      },
      {
        dataIndex: 'recurrentPeriod',
        label: 'Recurring Transaction',
        sortable: true,
      },
    ],
    []
  );

  const handleFilterChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: PartnerCampaignDetailsPageActionType.UpdateFilter,
      payload: { filter: { donor: e.target.value } },
    });
  }, []);

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: PartnerCampaignDetailsPageActionType.UpdateFilter,
      payload: { filter: { search: e.target.value } },
    });
  }, []);

  const handleSortChange = useCallback((newSort: SortConfig) => {
    dispatch({ type: PartnerCampaignDetailsPageActionType.UpdateSort, payload: { sort: newSort } });
  }, []);

  return (
    <Spinner loading={loading} transparent={true}>
      <FormProvider {...form}>
        <Grid {...defaultGridContainerProps} spacing={1}>
          <Grid {...defaultGridItemProps} xs={2}>
            <TextField select={true} size={'small'} value={filter.donor} onChange={handleFilterChange}>
              <MenuItem value={ALL_DONORS_VALUE} key={`partner-${ALL_DONORS_VALUE}`}>
                {ALL_DONORS_VALUE}
              </MenuItem>
              {donorList.map((donor) => (
                <MenuItem value={donor} key={`partner-${donor}`}>
                  {donor}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid {...defaultGridItemProps} xs={2}>
            <DateFormItem
              fieldName={'startDate'}
              size={'small'}
              label={'Start Date'}
              maxDate={endDate ? moment(endDate) : moment()}
              required={false}
            />
          </Grid>
          <Grid {...defaultGridItemProps} xs={2}>
            <DateFormItem
              fieldName={'endDate'}
              size={'small'}
              label={'End Date'}
              maxDate={moment()}
              minDate={startDate ? moment(startDate) : undefined}
              required={false}
            />
          </Grid>
          <Grid {...defaultGridItemProps} xs={5} className={styles.search}>
            <SearchInput value={filter.search} onChange={handleSearchChange} loading={loading} />
          </Grid>
          <Grid {...defaultGridItemProps} xs={true} className={styles.table}>
            <Table columns={columns} list={list} sort={sort} onSortChange={handleSortChange} showPagination={true} />
          </Grid>
        </Grid>
      </FormProvider>
    </Spinner>
  );
};
export default PartnerCampaignDetailsTable;
