import { Grid, Tab, Tabs } from '@mui/material';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import Alert from '@mui/material/Alert';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import useRequest from 'hooks/useRequest';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { getActiveTabRoute, getActiveTabValue } from 'util/Route';
import routes, { partnerRoutes } from 'store/configs/Routes';
import { useHistory, useLocation } from 'react-router';
import PartnerDashBoardPageView from 'components/partner/PartnerDashBoardPageView';
import { DonationCampaign } from 'store/types/DonationFund';
import PartnerService from 'services/api/PartnerServices';
import PartnerCampaignDetailsPageView from 'components/partner/PartnerCampaignDetailsPageView';
import ApplicationsService from 'services/api/ApplicationsService';
import Application from 'store/types/Application';
import Spinner from 'components/shared/Spinner';
import { useSnackbar } from 'notistack';
import { ParametersContext } from 'components/ParametersGuard';

import commonStyles from 'styles/common.module.scss';

const PartnerPageView: React.FunctionComponent = () => {
  const { error, loading, data = [], refetch } = useRequest<DonationCampaign[]>(PartnerService.getPartners);
  const { replace, push } = useHistory();
  const [submitLoading, setSubmitLoading] = useState<boolean>();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedCampaignId, setSelectedCampaignId] = useState<string>('');
  const { pathname } = useLocation();
  const activeTab: string = useMemo(() => getActiveTabValue(pathname, partnerRoutes), [pathname]);
  const {
    partner: {
      fundReconciliationReportApplicationId,
      fundRequestApplicationId,
      partnerDashboardTabHeader,
      campaignDetailsTabHeader,
    },
  } = useContext(ParametersContext);

  const handleTabChange = useCallback(
    (_: any, value = '1') => {
      replace(getActiveTabRoute(value, partnerRoutes));
    },
    [replace]
  );

  const handleSelectedCampaignChange = useCallback(
    (campaignId: string) => {
      setSelectedCampaignId(campaignId);
      replace(getActiveTabRoute('2', partnerRoutes));
    },
    [replace]
  );

  const handleCreateApplication = useCallback(
    (applicationId: string) => {
      setSubmitLoading(true);
      ApplicationsService.createApplication(applicationId, selectedCampaignId)
        .then(({ id }: Application) => {
          setSubmitLoading(false);
          enqueueSnackbar('Draft application successfully created', { variant: 'success' });
          push(`${routes.editApplication}/${id}`);
        })
        .catch((errorMessage: string) => {
          setSubmitLoading(false);
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        });
    },
    [enqueueSnackbar, push, selectedCampaignId]
  );

  return (
    <Spinner loading={submitLoading} fullPage={true}>
      {error ? (
        <Alert severity={'error'} className={commonStyles.alert}>
          {error}
        </Alert>
      ) : (
        <Grid {...defaultGridContainerProps} justifyContent={'space-between'}>
          <Grid {...defaultGridItemProps} xs={true}>
            <TabContext value={activeTab}>
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                indicatorColor={'primary'}
                textColor={'primary'}
                variant={'scrollable'}
              >
                <Tab value={'1'} label={'Partner DashBoard'} className={commonStyles.tab} />
                <Tab value={'2'} label={'Campaign Details'} className={commonStyles.tab} />
              </Tabs>
              <TabPanel value={'1'} className={commonStyles.tabPanel}>
                {partnerDashboardTabHeader && <p dangerouslySetInnerHTML={{ __html: partnerDashboardTabHeader }} />}
                <PartnerDashBoardPageView
                  loading={loading}
                  onSelectedCampaignIdChange={handleSelectedCampaignChange}
                  campaigns={data}
                  onCreateApplicationSubmit={handleCreateApplication}
                  applicationId={fundReconciliationReportApplicationId}
                  refetchCampaigns={refetch}
                />
              </TabPanel>
              <TabPanel value={'2'} className={commonStyles.tabPanel}>
                {campaignDetailsTabHeader && <p dangerouslySetInnerHTML={{ __html: campaignDetailsTabHeader }} />}
                <PartnerCampaignDetailsPageView
                  onSelectedCampaignIdChange={handleSelectedCampaignChange}
                  selectedCampaignId={selectedCampaignId}
                  loading={loading}
                  campaigns={data}
                  onCreateApplicationSubmit={handleCreateApplication}
                  applicationId={fundRequestApplicationId}
                />
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      )}
    </Spinner>
  );
};
export default PartnerPageView;
