import Alert from '@mui/material/Alert';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Grid, Link, TextField, Tooltip } from '@mui/material';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Modal from 'components/shared/Modal';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router';
import { ModalProps } from 'store/types/ComponentProps';
import { defaultFormProps, getRequiredValidationRule, getValidationProps, preventDecimalCharsInput } from 'util/Form';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import routes from 'store/configs/Routes';
import Subscription, { SubscriptionPlan } from 'store/types/Subscription';
import { getStringValue, NUMBER_DATE_FORMAT } from 'util/Format';
import { getPrice, preselectedOrdersStatePropName } from 'util/Payment';
import SubscriptionService from 'services/api/SubscriptionService';
import { getHashRouteUrl } from 'util/Route';
import PaymentService from 'services/api/PaymentService';
import { OrderPayment } from 'store/types/Payment';
import useRequest from 'hooks/useRequest';

import commonStyles from 'styles/common.module.scss';
import styles from './AddSubscriptionUsersModal.module.scss';

interface AddSubscriptionUsersModalProps extends ModalProps {
  data: Subscription;
}

const QUANTITY_FIELD_NAME = 'quantity';
const PO_NUMBER_FIELD_NAME = 'poNumber';
const QUANTITY_REGEXP = /^[0-9]+$/;
const invalidAmountMessage = 'Please input valid quantity';

interface AddSubscriptionUsersFormValues {
  [QUANTITY_FIELD_NAME]: number;
  [PO_NUMBER_FIELD_NAME]: string;
}

const defaultValues: AddSubscriptionUsersFormValues = {
  [QUANTITY_FIELD_NAME]: 1,
  [PO_NUMBER_FIELD_NAME]: '',
};

const AddSubscriptionUsersModal: React.FunctionComponent<AddSubscriptionUsersModalProps> = ({
  open,
  onClose,
  data,
}) => {
  const history = useHistory();
  const { name, endDate, id } = data;
  const subscriptionPlanRequestFn = useCallback(() => SubscriptionService.getSubscriptionPlan(id), [id]);
  const { data: planData, loading, error } = useRequest<SubscriptionPlan>(subscriptionPlanRequestFn);
  const { enqueueSnackbar } = useSnackbar();
  const [price, setPrice] = useState<number>(0);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const form = useForm<AddSubscriptionUsersFormValues>({
    ...defaultFormProps,
    defaultValues,
  });
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = form;
  const submitButtonDisabled: boolean = useMemo(
    () => !isValid || loading || submitLoading || !!error,
    [error, isValid, loading, submitLoading]
  );
  const quantityValue: number = useWatch({ control, name: QUANTITY_FIELD_NAME });
  const totalCostValue = useMemo(
    () => getPrice(quantityValue < 1 ? 0 : price * (quantityValue || 0)),
    [price, quantityValue]
  );

  useEffect(() => {
    if (planData) {
      setPrice(planData.price);
    }
  }, [planData]);

  const handleClose = useCallback(() => {
    reset();
    onClose();
  }, [reset, onClose]);

  const submitForm = useCallback(
    (formValues: AddSubscriptionUsersFormValues) => {
      setSubmitLoading(true);
      PaymentService.createSubscriptionOrder(id, { ...formValues })
        .then(({ id }: OrderPayment) => {
          setSubmitLoading(false);
          enqueueSnackbar('Subscription order successfully added', { variant: 'success' });
          history.push({
            pathname: routes.makePersonalPayment,
            state: { [preselectedOrdersStatePropName]: [id] },
          });
        })
        .catch((error: string) => {
          setSubmitLoading(false);
          enqueueSnackbar(error, defaultSnackbarErrorProps);
        });
    },
    [id, enqueueSnackbar, history]
  );

  const handleQuantityChange = useCallback(
    (onChange) => (e: ChangeEvent<HTMLInputElement>) => {
      onChange(parseInt((e.target.value || '1').replace(/\D/g, ''), 10));
    },
    []
  );

  return (
    <FormProvider {...form}>
      <Modal
        loading={loading || submitLoading}
        open={open}
        title={'Add Subscriptions'}
        maxWidth={'sm'}
        onClose={handleClose}
        actions={
          <>
            <Button color={'secondary'} variant={'outlined'} onClick={onClose} disabled={loading}>
              {'Cancel'}
            </Button>
            <Button
              color={'secondary'}
              variant={'contained'}
              className={styles.submitButton}
              onClick={handleSubmit(submitForm)}
              disabled={submitButtonDisabled}
            >
              {'Confirm Order'}
            </Button>
          </>
        }
      >
        {error ? (
          <Alert severity={'error'} className={commonStyles.alert}>
            {error}
          </Alert>
        ) : (
          <Grid {...defaultGridContainerProps} alignItems={'center'}>
            <Grid {...defaultGridItemProps} sm={4}>
              <label className={commonStyles.subTitle}>{'Select Quantity: '}</label>
            </Grid>
            <Grid {...defaultGridItemProps} sm={8}>
              <Controller
                render={({ field: { onChange, value, onBlur } }) => (
                  <TextField
                    type={'number'}
                    required={true}
                    value={value}
                    onChange={handleQuantityChange(onChange)}
                    onBlur={onBlur}
                    onKeyPress={preventDecimalCharsInput}
                    {...getValidationProps(QUANTITY_FIELD_NAME, errors)}
                  />
                )}
                name={QUANTITY_FIELD_NAME}
                control={control}
                rules={{
                  required: getRequiredValidationRule('new subscriptions quantity'),
                  min: {
                    value: 1,
                    message: invalidAmountMessage,
                  },
                  pattern: {
                    value: QUANTITY_REGEXP,
                    message: invalidAmountMessage,
                  },
                }}
              />
            </Grid>
            <Grid {...defaultGridItemProps} sm={4}>
              <label className={commonStyles.subTitle}>{'PO Number: '}</label>
            </Grid>
            <Grid {...defaultGridItemProps} sm={8}>
              <Controller
                render={({ field }) => <TextField {...field} {...getValidationProps(PO_NUMBER_FIELD_NAME, errors)} />}
                name={PO_NUMBER_FIELD_NAME}
                control={control}
              />
            </Grid>
            <Grid {...defaultGridItemProps} sm={4}>
              <label className={commonStyles.subTitle}>{'Subscription: '}</label>
            </Grid>
            <Grid {...defaultGridItemProps} sm={8}>
              <span className={styles.value}>{name}</span>
            </Grid>
            <Grid {...defaultGridItemProps} sm={4}>
              <label className={commonStyles.subTitle}>{'Subscription Term: '}</label>
            </Grid>
            <Grid {...defaultGridItemProps} sm={8}>
              <span className={styles.value}>{`${getStringValue(
                new Date().toString(),
                NUMBER_DATE_FORMAT
              )} - ${getStringValue(endDate, NUMBER_DATE_FORMAT)}`}</span>
            </Grid>
            <Grid {...defaultGridItemProps} sm={4}>
              <label className={commonStyles.subTitle}>{'Total Cost: '}</label>
            </Grid>
            <Grid {...defaultGridItemProps} sm={8}>
              <span className={styles.value}>
                {totalCostValue}
                <Tooltip
                  arrow={true}
                  title={"Subscriptions are prorated from today to current subscription's end date"}
                >
                  <InfoOutlined color={'secondary'} className={styles.infoIcon} />
                </Tooltip>
              </span>
            </Grid>
            <Grid {...defaultGridItemProps}>
              <p className={classNames(commonStyles.text, styles.terms)}>
                {'By proceeding, you agree to subscription '}
                <Link underline={'always'} target={'_blank'} href={getHashRouteUrl(routes.subscriptionTerms)}>
                  {'terms & conditions'}
                </Link>
                {'.'}
              </p>
            </Grid>
          </Grid>
        )}
      </Modal>
    </FormProvider>
  );
};
export default AddSubscriptionUsersModal;
