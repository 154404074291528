import { Button, Grid } from '@mui/material';
import classNames from 'classnames';
import Tile from 'components/shared/Tile';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import routes from 'store/configs/Routes';
import PaymentStatus from 'store/enums/PaymentStatus';
import { OrderPayment } from 'store/types/Payment';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { getFormattedOrdersList, preselectedOrdersStatePropName } from 'util/Payment';
import UseRequestData from 'store/types/UseRequestData';

import styles from './HomeOpenOrdersSection.module.scss';

const HomeOpenOrdersSection: React.FunctionComponent<UseRequestData<OrderPayment[]>> = ({ data, loading }) => {
  const { push } = useHistory();
  const [openOrders, setOpenOrders] = useState<OrderPayment[]>([]);
  const overdueOrders: OrderPayment[] = useMemo(
    () => openOrders.filter(({ status }: OrderPayment) => status === PaymentStatus.Overdue),
    [openOrders]
  );

  useEffect(() => {
    if (data && data.length) {
      setOpenOrders(getFormattedOrdersList(data, true));
    }
  }, [data]);

  const getOpenOrdersCard = useCallback(
    (overdueCard = false) => {
      const ordersList: OrderPayment[] = overdueCard ? overdueOrders : openOrders;
      const handlePayNowClick = () => {
        push({
          pathname: routes.makePersonalPayment,
          state:
            overdueCard && overdueOrders.length
              ? {
                  [preselectedOrdersStatePropName]: overdueOrders.map(({ id }) => id),
                }
              : null,
        });
      };

      return (
        <Tile
          theme={overdueCard ? 'dark-red' : undefined}
          className={classNames(styles.tile, { [styles.overdueCard]: overdueCard })}
          loading={loading}
          value={`${ordersList.length}`}
          label={`${overdueCard ? 'Overdue' : 'Open'} Orders`}
        >
          {!!ordersList.length && (
            <Button
              variant={'contained'}
              size={'small'}
              classes={{
                root: styles.cardButton,
              }}
              onClick={handlePayNowClick}
            >
              {'Pay Now'}
            </Button>
          )}
        </Tile>
      );
    },
    [openOrders, overdueOrders, loading, push]
  );

  return (
    <Grid {...defaultGridContainerProps}>
      {!!overdueOrders.length && (
        <Grid {...defaultGridItemProps} sm={6}>
          {getOpenOrdersCard(true)}
        </Grid>
      )}
      <Grid {...defaultGridItemProps} sm={overdueOrders.length ? 6 : 12}>
        {getOpenOrdersCard()}
      </Grid>
    </Grid>
  );
};
export default HomeOpenOrdersSection;
