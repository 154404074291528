import classNames from 'classnames';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormControlLabel, Grid, FormControl, RadioGroup, Radio, Button } from '@mui/material';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import MakePaymentComponentProps from 'store/types/MakePaymentComponentProps';
import { defaultFormProps, getAddressFormValues, getSortedAddressList } from 'util/Form';
import { defaultGridContainerProps } from 'util/Layout';
import AddressFormSection from 'components/shared/AddressFormSection';
import Address, { BillingAddress } from 'store/types/Address';
import { UserContext } from 'components/UserGuard';
import { BaseAddressFormValues } from 'store/types/FormValues';
import { getAddressInfo } from 'util/Format';
import { ConfigContext } from 'components/ConfigGuard';
import AddressesService from 'services/api/AddressesService';
import EditAddressModal from 'components/addresses/EditAddressModal';
import useRequest from 'hooks/useRequest';
import { Alert, Skeleton } from '@mui/lab';
import StatusLabel from 'components/shared/StatusLabel';

import commonStyles from 'styles/common.module.scss';
import styles from './PaymentAddressSection.module.scss';

type PaymentAddressSectionProps = Pick<MakePaymentComponentProps, 'defaultAddress' | 'onlySavedAddresses'> & {
  onChange: (values: Partial<Pick<BaseAddressFormValues, 'billingAddress' | 'useHomeAddress'>>) => void;
  form: BaseAddressFormValues;
  useShippingAddress?: boolean;
};

enum AddressType {
  Home = 'Home',
  Saved = 'Saved',
  Other = 'Other',
}

const defaultAddressFormValues = {
  address: getAddressFormValues({ country: 'US' }),
};

const PaymentAddressSection: React.FunctionComponent<PaymentAddressSectionProps> = ({
  form,
  onChange,
  defaultAddress,
  onlySavedAddresses,
  useShippingAddress,
}) => {
  const { billingAddress, useHomeAddress } = useMemo(() => form, [form]);
  const { address } = useContext(UserContext);
  const [addressType, setAddressType] = useState<AddressType>(AddressType.Other);
  const { isNccerTheme } = useContext(ConfigContext);
  const [addAddressModalOpen, setAddAddressModalOpen] = useState<boolean>(false);
  const addressesRequest = useRequest<BillingAddress[]>(AddressesService.getAddresses);
  const { error, data: addresses, refetch, loading } = addressesRequest;
  const isFirstAddress: boolean = useMemo(() => !addresses?.length, [addresses]);
  const homeAddress: Address = useMemo(
    () => getAddressFormValues(defaultAddress || address),
    [address, defaultAddress]
  );
  const addressForm = useForm<{ address: Address | BillingAddress }>({
    ...defaultFormProps,
    defaultValues: defaultAddressFormValues,
  });
  const sortedAddressList: BillingAddress[] = useMemo(() => getSortedAddressList(addresses), [addresses]);
  const defaultSelectedAddress: BillingAddress | undefined = useMemo(
    () =>
      useShippingAddress
        ? sortedAddressList?.filter((address) => address.isDefaultShipping)[0]
        : sortedAddressList?.filter((address) => address.isDefaultBilling)[0],
    [sortedAddressList, useShippingAddress]
  );
  const isHomeAddressHidden: boolean = useMemo(() => !homeAddress.street1, [homeAddress]);
  const {
    formState: { isValid, isDirty },
    control,
  } = addressForm;
  const addressFormValues: Address = useWatch({ control, name: 'address' });
  const billingAddressFormValues = useMemo(
    () => (isDirty && isValid ? addressFormValues : undefined),
    [isValid, isDirty, addressFormValues]
  );

  useEffect(() => {
    setAddressType(onlySavedAddresses ? AddressType.Saved : isHomeAddressHidden ? AddressType.Other : AddressType.Home);
  }, [isHomeAddressHidden, onlySavedAddresses]);

  useEffect(() => {
    if (defaultSelectedAddress && addressType === AddressType.Saved) {
      onChange({ billingAddress: defaultSelectedAddress, useHomeAddress: true });
    }
  }, [defaultSelectedAddress, onChange, addressType]);

  useEffect(() => {
    onChange({
      useHomeAddress: !isHomeAddressHidden,
    });
  }, [addressType, homeAddress, isHomeAddressHidden, onChange]);

  useEffect(() => {
    if (
      !useHomeAddress &&
      addressType !== AddressType.Saved &&
      JSON.stringify(billingAddress) !== JSON.stringify(billingAddressFormValues)
    ) {
      onChange({ billingAddress: billingAddressFormValues });
    }
  }, [onChange, billingAddress, useHomeAddress, billingAddressFormValues, addressType]);

  const handleSavedAddressChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
      const selectedAddress: BillingAddress | undefined = addresses?.filter((address) => address.id === id)[0];
      const selectedAddressIsDefault: boolean | undefined = useShippingAddress
        ? selectedAddress?.isDefaultShipping
        : selectedAddress?.isDefaultBilling;

      onChange({
        useHomeAddress: selectedAddressIsDefault,
        billingAddress: getAddressFormValues(selectedAddress),
      });
    },
    [addresses, onChange, useShippingAddress]
  );

  const handleAddressTypeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const useHomeAddressValue: boolean = event.target.value === AddressType.Home;
      const type: AddressType = event.target.value as AddressType;
      setAddressType(type);

      onChange({
        useHomeAddress: useHomeAddressValue,
        billingAddress: useHomeAddressValue || type === AddressType.Saved ? undefined : billingAddressFormValues,
      });
    },
    [onChange, billingAddressFormValues]
  );

  const handleAddAddressModalClose = useCallback(() => {
    setAddAddressModalOpen(false);
  }, []);

  const handleAddAddressModalOpen = useCallback(() => {
    setAddAddressModalOpen(true);
  }, []);

  const handleAddAddressSubmit = useCallback(() => {
    refetch();
    setAddAddressModalOpen(false);
  }, [refetch]);

  return (
    <>
      <h3 className={commonStyles.subTitle}>
        {useShippingAddress ? 'Confirm Shipping Address' : 'Confirm Payment Billing Address'}
      </h3>
      <p className={commonStyles.text}>
        {useShippingAddress
          ? 'This address will be used to ship your products.'
          : 'This address will be used to validate your credit card or ACH payment.'}
      </p>
      {!isHomeAddressHidden && (
        <FormControl component={'fieldset'} fullWidth={true}>
          <RadioGroup value={addressType} onChange={handleAddressTypeChange} className={styles.radioGroup}>
            {!onlySavedAddresses && (
              <>
                <FormControlLabel
                  value={AddressType.Home}
                  control={<Radio color={'primary'} />}
                  label={`Use ${isNccerTheme ? 'Billing' : 'Home'} Address`}
                />
                <FormControlLabel
                  value={AddressType.Other}
                  control={<Radio color={'primary'} />}
                  label={'Enter Different Billing Address'}
                />
              </>
            )}
          </RadioGroup>
        </FormControl>
      )}
      <div
        className={classNames(styles.address, {
          [commonStyles.hidden]: addressType !== AddressType.Home || onlySavedAddresses,
        })}
      >
        {getAddressInfo(homeAddress)}
      </div>
      <div className={classNames({ [commonStyles.hidden]: addressType !== AddressType.Saved || !onlySavedAddresses })}>
        {error ? (
          <Alert severity={'error'} className={commonStyles.alert}>
            {error}
          </Alert>
        ) : loading ? (
          <div className={styles.skeletonWrapper}>
            <Skeleton width={'70%'} variant={'text'} height={'30px'} />
            <Skeleton width={'70%'} variant={'text'} height={'30px'} />
            <Skeleton width={'70%'} variant={'text'} height={'30px'} />
            <Skeleton width={'70%'} variant={'text'} height={'30px'} />
          </div>
        ) : (
          <div className={styles.selectAddressWrapper}>
            <RadioGroup onChange={handleSavedAddressChange} defaultValue={defaultSelectedAddress?.id}>
              {sortedAddressList.map((address, index) => (
                <FormControlLabel
                  key={`${address.label}-${index}`}
                  value={address.id}
                  control={<Radio color={'primary'} />}
                  label={
                    <>
                      <span className={commonStyles.bold}>{`${address.label}, `}</span>
                      <span>{`${address.addressee}, `}</span>
                      {getAddressInfo({ ...address, label: '' })}
                      {address.isDefaultBilling && <StatusLabel status={'Default Billing'} className={styles.label} />}
                      {address.isDefaultShipping && (
                        <StatusLabel status={'Default Shipping'} className={styles.label} />
                      )}
                    </>
                  }
                />
              ))}
            </RadioGroup>
            <Button
              color={'secondary'}
              variant={'contained'}
              className={styles.addAddressButton}
              onClick={handleAddAddressModalOpen}
            >
              {'Add a New Address'}
            </Button>
          </div>
        )}
      </div>
      <Grid
        {...defaultGridContainerProps}
        className={classNames({ [commonStyles.hidden]: addressType !== AddressType.Other || onlySavedAddresses })}
      >
        <FormProvider {...addressForm}>
          <AddressFormSection />
        </FormProvider>
      </Grid>
      {addAddressModalOpen && (
        <EditAddressModal
          onClose={handleAddAddressModalClose}
          open={addAddressModalOpen}
          onSubmit={handleAddAddressSubmit}
          isFirstAddress={isFirstAddress}
        />
      )}
    </>
  );
};
export default PaymentAddressSection;
