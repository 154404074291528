import React, { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import { FormGroup, Checkbox, FormControlLabel, FormControl, FormHelperText } from '@mui/material';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { FormProps, getRequiredSelectMessage, getRequiredValidationRule, getValidationProps } from 'util/Form';
import { PreferenceQuestionOption, PreferenceQuestionView } from 'store/types/PreferenceQuestion';
import ExplanationQuestionFormItem from '../ExplanationQuestionFormItem';

import styles from './CheckboxQuestionFormItem.module.scss';

interface CheckboxQuestionFormItemProps extends FormProps {
  question: PreferenceQuestionView;
}

const CheckboxQuestionFormItem: React.FunctionComponent<CheckboxQuestionFormItemProps> = ({ question, disabled }) => {
  const {
    control,
    setError,
    clearErrors,
    getValues,
    formState: { errors },
  } = useFormContext<{ [fieldName: string]: PreferenceQuestionOption[] }>();
  const { fieldName, required = false } = question;
  const values = useWatch({ control, name: fieldName });
  const { fields, update } = useFieldArray({ control, name: fieldName });
  const { error = false } = getValidationProps(fieldName, errors);
  const controlFieldRequired: boolean = useMemo(
    () => required && values?.every(({ value }) => !value),
    [required, values]
  );

  useEffect(() => {
    if (required) {
      if (controlFieldRequired && error) {
        setError(fieldName, { message: getRequiredSelectMessage('your answer') });
      } else {
        clearErrors(fieldName);
      }
    }
  }, [clearErrors, controlFieldRequired, error, fieldName, required, setError, values]);

  const handleChange = useCallback(
    (onChange, index) => (e: ChangeEvent<HTMLInputElement>) => {
      const fieldValues = fieldName.split('.').reduce((nextObject: any, key: string) => nextObject[key], getValues())[
        index
      ];
      const value = e.target.checked;

      update(index, { ...fieldValues, value });
      onChange(value);
    },
    [fieldName, getValues, update]
  );

  return (
    <FormControl component={'fieldset'} required={required} error={error} fullWidth={true}>
      <FormGroup>
        {fields.map(({ id, name = '', explanation, includeExplanation, value }, index) => (
          <React.Fragment key={id}>
            <FormControlLabel
              label={name}
              control={
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <>
                      <Checkbox
                        disabled={disabled}
                        color={'primary'}
                        size={'small'}
                        checked={value}
                        value={value}
                        onChange={handleChange(onChange, index)}
                      />
                    </>
                  )}
                  control={control}
                  name={`${fieldName}[${index}].value`}
                  rules={{
                    required: getRequiredValidationRule('your answer', false, controlFieldRequired),
                  }}
                />
              }
            />
            {includeExplanation && value && (
              <div className={styles.explanationField}>
                <ExplanationQuestionFormItem
                  parentFieldName={fieldName}
                  checkboxField={`${fieldName}[${index}].explanation`}
                  required={false}
                  defaultValue={explanation}
                  disabled={disabled}
                />
              </div>
            )}
          </React.Fragment>
        ))}
      </FormGroup>
      {error && <FormHelperText>{getRequiredSelectMessage('your answer')}</FormHelperText>}
    </FormControl>
  );
};
export default CheckboxQuestionFormItem;
