import { Tab, Tabs } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { ConfigContext } from 'components/ConfigGuard';
import EditProfilePageView from 'components/profile/EditProfilePageView';
import Spinner from 'components/shared/Spinner';
import { UserContext } from 'components/UserGuard';
import React, { useCallback, useContext, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { profileRoutes } from 'store/configs/Routes';
import { getActiveTabRoute, getActiveTabValue } from 'util/Route';
import SiteModule from 'store/enums/SiteModule';
import UserRelationshipPageView from 'components/profile/UserRelationshipPageView';
import EditPreferencesPage from 'components/profile/EditPreferencesPage';
import AddressesPage from 'components/addresses/AddressesPage';
import { ParametersContext } from 'components/ParametersGuard';
import UserEmployerPageView from 'components/profile/UserEmployerPageView';
import AdvocacyPage from 'components/profile/AdvocacyPage';
import useRequest from 'hooks/useRequest';
import { CompanyRequestsInfo } from 'store/types/Company';
import UserService from 'services/api/UserService';
import CountBadge from 'components/shared/CountBadge';
import classNames from 'classnames';

import commonStyles from 'styles/common.module.scss';
import styles from './ProfilePage.module.scss';

const ProfilePage: React.FunctionComponent = () => {
  const location = useLocation();
  const history = useHistory();
  const {
    enabledModules,
    isNccerTheme,
    modulesSettings: {
      profile: { enableRelationship, enableMyEmployer, enablePreferences, enableAdvocacy },
    },
  } = useContext(ConfigContext);
  const { userLoading = false } = useContext(UserContext);
  const {
    profile: {
      profileInfoHeader,
      additionalInfoHeader,
      relationshipHeader,
      manageMyAddressHeader,
      myEmployerHeader,
      advocacyHeader,
    },
  } = useContext(ParametersContext);
  const { data, refetch } = useRequest<CompanyRequestsInfo>(UserService.getEmployerRequestsCount);
  const activeTab: string = useMemo(() => getActiveTabValue(location.pathname, profileRoutes), [location.pathname]);
  const manageAddressesModuleEnabled: boolean = useMemo(
    () => enabledModules.includes(SiteModule.Addresses),
    [enabledModules]
  );
  const handleTabChange = useCallback(
    (_: any, value = '1') => {
      history.replace(getActiveTabRoute(value, profileRoutes));
    },
    [history]
  );

  return (
    <TabContext value={activeTab}>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        indicatorColor={'primary'}
        textColor={'primary'}
        TabIndicatorProps={{ sx: { display: 'none' } }}
        sx={{
          '& .MuiTabs-flexContainer': {
            flexWrap: 'wrap',
          },
        }}
      >
        <Tab
          value={'1'}
          label={'Profile Information'}
          className={commonStyles.tab}
          classes={{ selected: commonStyles.activeTab }}
        />
        {enablePreferences && (
          <Tab
            value={'2'}
            label={'Additional Information'}
            className={commonStyles.tab}
            classes={{ selected: commonStyles.activeTab }}
          />
        )}
        {!isNccerTheme && manageAddressesModuleEnabled && (
          <Tab
            value={'3'}
            label={'Manage My Addresses'}
            className={commonStyles.tab}
            classes={{ selected: commonStyles.activeTab }}
          />
        )}
        {enableMyEmployer && (
          <Tab
            value={'4'}
            label={
              <>
                {'My Employer'}
                {!!data?.pending && <CountBadge className={styles.countBadge}>{data.pending}</CountBadge>}
              </>
            }
            className={classNames(commonStyles.tab, styles.tabWithCounter)}
            classes={{ selected: commonStyles.activeTab }}
          />
        )}
        {enableRelationship && (
          <Tab
            value={'5'}
            label={'My Family & Friends'}
            className={commonStyles.tab}
            classes={{ selected: commonStyles.activeTab }}
          />
        )}
        {enableAdvocacy && (
          <Tab
            value={'6'}
            label={'Advocacy'}
            className={commonStyles.tab}
            classes={{ selected: commonStyles.activeTab }}
          />
        )}
      </Tabs>
      <TabPanel value={'1'} className={commonStyles.tabPanel}>
        <Spinner loading={userLoading} transparent={false}>
          {profileInfoHeader && <p dangerouslySetInnerHTML={{ __html: profileInfoHeader }} />}
          <EditProfilePageView />
        </Spinner>
      </TabPanel>
      {enablePreferences && (
        <TabPanel value={'2'} className={commonStyles.tabPanel}>
          <Spinner loading={userLoading} transparent={false}>
            {additionalInfoHeader && <p dangerouslySetInnerHTML={{ __html: additionalInfoHeader }} />}
            <EditPreferencesPage />
          </Spinner>
        </TabPanel>
      )}
      {!isNccerTheme && manageAddressesModuleEnabled && (
        <TabPanel value={'3'} className={commonStyles.tabPanel}>
          {manageMyAddressHeader && <p dangerouslySetInnerHTML={{ __html: manageMyAddressHeader }} />}
          <AddressesPage />
        </TabPanel>
      )}
      {enableMyEmployer && (
        <TabPanel value={'4'} className={commonStyles.tabPanel}>
          {myEmployerHeader && <p dangerouslySetInnerHTML={{ __html: myEmployerHeader }} />}
          <UserEmployerPageView refetchCount={refetch} />
        </TabPanel>
      )}
      {enableRelationship && (
        <TabPanel value={'5'} className={commonStyles.tabPanel}>
          {relationshipHeader && <p dangerouslySetInnerHTML={{ __html: relationshipHeader }} />}
          <UserRelationshipPageView />
        </TabPanel>
      )}
      {enableAdvocacy && (
        <TabPanel value={'6'} className={commonStyles.tabPanel}>
          {advocacyHeader && <p dangerouslySetInnerHTML={{ __html: advocacyHeader }} />}
          <AdvocacyPage />
        </TabPanel>
      )}
    </TabContext>
  );
};
export default ProfilePage;
