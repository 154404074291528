import { Button, Divider, Grid } from '@mui/material';
import Modal from 'components/shared/Modal';
import React, { useMemo } from 'react';
import { ModalProps } from 'store/types/ComponentProps';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { getApplicationStatusLabel } from 'util/Application';
import { getFormattedDate } from 'util/Format';
import { getHashRouteUrl } from 'util/Route';
import routes from 'store/configs/Routes';
import ApplicationReview from 'store/types/ApplicationReview';
import ApplicationReviewStatus from 'store/enums/ApplicationReviewStatus';

import styles from './ApplicationDecisionModal.module.scss';

interface ApplicationDecisionModalProps extends ModalProps {
  review: ApplicationReview;
  applicationId?: string;
  applicationStatus: string;
}

const ApplicationDecisionModal: React.FunctionComponent<ApplicationDecisionModalProps> = ({
  open,
  review,
  onClose,
  applicationId,
  applicationStatus,
}) => {
  const openApplicationLink: string = useMemo(
    () =>
      review.status === ApplicationReviewStatus.NeedMoreInformation ? routes.editApplication : routes.viewApplication,
    [review.status]
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      maxWidth={'sm'}
      title={'Decision'}
      actions={
        <>
          <Button color={'secondary'} variant={'outlined'} onClick={onClose}>
            {'Close'}
          </Button>
          {applicationId && (
            <Button
              color={'secondary'}
              variant={'contained'}
              href={getHashRouteUrl(`${openApplicationLink}/${applicationId}`)}
            >
              {'Go to Application'}
            </Button>
          )}
        </>
      }
    >
      <Grid {...defaultGridContainerProps}>
        <Grid {...defaultGridItemProps}>
          <div className={styles.status}>
            <span className={styles.title}>{'Application Status: '}</span>
            <span className={styles.value}>{getApplicationStatusLabel(applicationStatus)}</span>
          </div>
          <div className={styles.status}>
            <span className={styles.title}>{'Date: '}</span>
            <span className={styles.value}>{getFormattedDate(review.decisionDate || '')}</span>
          </div>
          {review.decisionComment && (
            <>
              <span className={styles.comment}>{'Comment '}</span>
              <Divider className={styles.divider} />
              <span>{review.decisionComment}</span>
            </>
          )}
        </Grid>
      </Grid>
    </Modal>
  );
};
export default ApplicationDecisionModal;
